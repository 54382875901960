import React from 'react';

import Kpi from '@/components/atoms/HeaderKpi';
import { computeCurrency, computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { Title } from '@/components/atoms/Title';
import { theme } from '@/core/theme';
import { Text } from '@/components/atoms/Text';
import { TransferStatus } from '@/services/innovorder/io-pay/transfers/transfer.type';
import { Container, Chips, ChipsContainer, ChipsValue } from './TransferDetails.style';
import { FlexGrow, Header, TitleContainer, KpiContainer } from '../Transfers.style';
import { getTransferStatusColor } from '../utils';

type TransferDetailMainInfoProps = {
    configuration?: string;
    amount: number;
    transactionCount: number;
    status: TransferStatus;
    executedAt: string;
    reference: string;
};

const TransfersDetailMainInfo: React.FunctionComponent<React.PropsWithChildren<TransferDetailMainInfoProps>> = ({
    configuration,
    amount,
    transactionCount,
    status,
    executedAt,
    reference,
}) => {
    const executedAtDate = new Date(executedAt);
    const intl = useIntl();

    return (
        <>
            <Header>
                <TitleContainer>
                    <Title text="transfer.detail.title" />
                </TitleContainer>
                <FlexGrow />
                <ChipsContainer>
                    <Chips>
                        <Text text="transfer.field.ref" />
                        <ChipsValue>{reference}T15:03:30Z</ChipsValue>
                    </Chips>
                </ChipsContainer>
            </Header>
            <Container>
                <KpiContainer>
                    {configuration && <Kpi title="transfer.field.configurationName" text={configuration} />}
                    <Kpi title="transfer.field.amount" text={computeCurrency({ intl, amount })} />
                    <Kpi title="transfer.field.transactionCount" text={transactionCount.toString()} />
                    <Kpi
                        title="transfer.field.status"
                        text={status.toString()}
                        badge={{
                            text: computeText(intl, `transfer.status.${TransferStatus[status]}`),
                            backgroundColor: getTransferStatusColor(status, theme),
                        }}
                    />
                    <Kpi title="transfer.field.executedAt" text="transfer.kpi.date" values={{ date: executedAtDate }} />
                </KpiContainer>
            </Container>
        </>
    );
};
export default TransfersDetailMainInfo;
