import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PricingConfigurationSection from '@/pages/Customer/PricingConfigurationSection';
import { computeText } from '@/locales/utils';
import { Text } from '@/components/atoms/Text';
import TextFieldInput from '@/pages/Customer/CustomerFormInputs/TextFieldInput';
import PasswordInput from '@/pages/Customer/CustomerFormInputs/PasswordInput';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { ActiveStudentEntities } from '@/services/innovorder/brand/brand.type';
import { getUseCustomerFormAutoGenerateEmailAndPassword } from '@/redux/launchDarkly';
import { FlexContainer, GeneralInformationContainer } from './CreateCustomerForm.style';
import { isValidPassword } from './CreateCustomerForm.utils';
import { BaseCustomerAttributes } from '../CreateCustomerModal.types';

type CreateCustomerFormProps = {
    customerForm: UseFormReturn<BaseCustomerAttributes>;
    guestGroups: GuestGroupWithGuestsCount[];
    activeStudentEntities?: ActiveStudentEntities;
};

const CreateCustomerForm: React.FunctionComponent<React.PropsWithChildren<CreateCustomerFormProps>> = ({
    customerForm,
    guestGroups,
    activeStudentEntities,
}) => {
    const intl = useIntl();

    const customerFormAutoGenerateEmailAndPassword = useSelector(getUseCustomerFormAutoGenerateEmailAndPassword);

    const { watch } = customerForm;

    return (
        <FormProvider {...customerForm}>
            <form>
                <FlexContainer>
                    <Text text={'customer.form.clientInfoSection'} weight="bold" />
                    <GeneralInformationContainer>
                        <TextFieldInput
                            fieldName="firstName"
                            rules={{
                                required: computeText(intl, 'field.required'),
                            }}
                        />
                        <TextFieldInput
                            fieldName="lastName"
                            rules={{
                                required: computeText(intl, 'field.required'),
                            }}
                        />
                        <TextFieldInput
                            fieldName="badgeNumber"
                            rules={{
                                required: computeText(intl, 'field.required'),
                            }}
                            isTouched
                        />
                        <TextFieldInput
                            fieldName="email"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                pattern: {
                                    value: /^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i,
                                    message: computeText(intl, 'field.invalidEmail'),
                                },
                            }}
                            isTouched={customerFormAutoGenerateEmailAndPassword}
                        />
                        <PasswordInput
                            fieldName="password"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                validate: (password: string) => {
                                    if (!isValidPassword(password)) {
                                        return computeText(intl, 'field.invalidPassword');
                                    }
                                    return true;
                                },
                            }}
                            isTouched={customerFormAutoGenerateEmailAndPassword}
                        />
                        <PasswordInput
                            fieldName="confirmPassword"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                validate: (confirmPassword: string) => {
                                    if (watch('password') !== confirmPassword) {
                                        return computeText(intl, 'field.unmatchedPassword');
                                    }
                                    return true;
                                },
                            }}
                            isTouched={customerFormAutoGenerateEmailAndPassword}
                        />
                        <TextFieldInput fieldName="studentNumber" />
                    </GeneralInformationContainer>
                    <PricingConfigurationSection
                        guestGroups={guestGroups || []}
                        activeStudentEntities={activeStudentEntities}
                    />
                </FlexContainer>
            </form>
        </FormProvider>
    );
};

export default CreateCustomerForm;
