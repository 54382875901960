import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ENV } from '@/utils/env';
import { DocumentationButton, PanelSubTitle, PanelText, PanelTitle, TitleContainer } from './WebhookPanel.style';

const CONTACT_URL = `${ENV.STATIC_URL}/dashboard/webhook-notification-doc.pdf`;

const WebhookPanel: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <div>
            <TitleContainer>
                <PanelTitle text="webhook.title" />
            </TitleContainer>
            <PanelSubTitle text="webhook.firstpart.subtitle" weight="bold" />
            <PanelText text="webhook.firstpart.description" />
            <PanelSubTitle text="webhook.secondpart.subtitle" weight="bold" />
            <PanelText text="webhook.secondpart.description" />
            <a href={CONTACT_URL} target="_BLANK" rel="noopener noreferrer">
                <DocumentationButton buttonType="primary">
                    <FormattedMessage id="webhook.documentationLink" />
                </DocumentationButton>
            </a>
        </div>
    );
};

export default WebhookPanel;
