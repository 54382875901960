import { Input } from '@/components/atoms/Form/Input';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StepWrapper, TitleWrapper } from '../CreateBrandForm.style';
import { Title } from '@/components/atoms/Title';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';

const CreateUserStep = () => {
    const { control } = useFormContext();
    const intl = useIntl();
    return (
        <StepWrapper>
            <TitleWrapper>
                <Title text={computeText(intl, 'brands.form.selectUser')} />
            </TitleWrapper>
            <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <Input
                            name="firstName"
                            labelId="brands.form.firstName"
                            onChange={onChange}
                            value={value}
                            error={error?.message}
                        />
                    );
                }}
            />
            <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <Input
                            name="lastName"
                            labelId="brands.form.lastName"
                            onChange={onChange}
                            value={value}
                            error={error?.message}
                        />
                    );
                }}
            />
            <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <Input
                            name="phone"
                            labelId="brands.form.phoneNumber"
                            onChange={onChange}
                            value={value}
                            error={error?.message}
                        />
                    );
                }}
            />
            <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <Input
                            name="email"
                            labelId="brands.form.email"
                            onChange={onChange}
                            value={value}
                            error={error?.message}
                        />
                    );
                }}
            />
            <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <Input
                            name="password"
                            type="password"
                            labelId="brands.form.password"
                            onChange={onChange}
                            value={value}
                            error={error?.message}
                        />
                    );
                }}
            />
            <Controller
                name="confirmPassword"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <Input
                            name="confirmPassword"
                            type="password"
                            labelId="brands.form.confirmPassword"
                            onChange={onChange}
                            value={value}
                            error={error?.message}
                        />
                    );
                }}
            />
        </StepWrapper>
    );
};
export default CreateUserStep;
