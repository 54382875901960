import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import { GetAuditPayload, GetAuditResponse, GetHistoryListPayload, GetHistoryListResponse } from './history.type';

const historyApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getHistoryList: build.mutation<GetHistoryListResponse, ApiRequest<GetHistoryListPayload>>({
            query: ({ brandId, limit, offset, orderBy, search, filters }) => {
                return {
                    url: '/history',
                    method: 'GET',
                    params: { limit, offset, brandId, orderBy, search, ...filters },
                };
            },
            transformResponse: (response: ApiResponse<GetHistoryListResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getAudit: build.mutation<GetAuditResponse, ApiRequest<GetAuditPayload>>({
            query: ({ auditId }) => {
                return {
                    url: `/history/${auditId}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: ApiResponse<GetAuditResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetHistoryListMutation, useGetAuditMutation } = historyApi;
