import { ApiTagTypes, innovorderApi } from '../index';
import { ApiResponse, ApiRequest } from '../request.types';
import {
    DeleteAllExternalPosModulesPayload,
    DeleteAllExternalPosModulesResponse,
    DeleteExternalPosModulesPayload,
    DeleteExternalPosModulesResponse,
    GetExternalPosModulesPayload,
    GetExternalPosModulesResponse,
    PostExternalPosModulesPayload,
    PostExternalPosModulesResponse,
} from './moduleExternalPos.type';

const moduleExternalPosApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getExternalPosModules: build.query<GetExternalPosModulesResponse[], ApiRequest<GetExternalPosModulesPayload>>({
            query: ({ brandId, restaurantId, token }) => ({
                url: `/module_external_pos/brands/${brandId}/restaurants/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetExternalPosModulesResponse[]>) =>
                (response.data || []) as GetExternalPosModulesResponse[],
            extraOptions: { maxRetries: 3 },
        }),
        postExternalPosModule: build.mutation<
            PostExternalPosModulesResponse,
            ApiRequest<PostExternalPosModulesPayload>
        >({
            query: ({ brandId, restaurantId, modules, token }) => ({
                url: `/module_external_pos/brands/${brandId}/restaurants/${restaurantId}/deliverect`,
                method: 'POST',
                body: modules,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.ModuleExternalPos],
            transformResponse: (response: ApiResponse<PostExternalPosModulesResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteExternalPosModule: build.mutation<
            DeleteAllExternalPosModulesResponse,
            ApiRequest<DeleteAllExternalPosModulesPayload>
        >({
            query: ({ brandId, restaurantId, token }) => ({
                url: `/module_external_pos/brands/${brandId}/restaurants/${restaurantId}`,
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.ModuleExternalPos],
            transformResponse: (response: ApiResponse<DeleteAllExternalPosModulesResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteExternalPosModuleByChannel: build.mutation<
            DeleteExternalPosModulesResponse,
            ApiRequest<DeleteExternalPosModulesPayload>
        >({
            query: ({ brandId, restaurantId, channelId, token }) => ({
                url: `/module_external_pos/brands/${brandId}/restaurants/${restaurantId}/channels/${channelId}`,
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.ModuleExternalPos],
            transformResponse: (response: ApiResponse<DeleteExternalPosModulesResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetExternalPosModulesQuery,
    usePostExternalPosModuleMutation,
    useDeleteExternalPosModuleMutation,
    useDeleteExternalPosModuleByChannelMutation,
} = moduleExternalPosApi;
