import { computeText } from '@/locales/utils';
import { EntranceFee } from '@/services/innovorder/entranceFee/entranceFee.type';
import { Grant } from '@/services/innovorder/grant/grant.type';
import { PricingRule } from '@/services/innovorder/pricingRule/pricingRule.type';
import { IntlShape } from 'react-intl';

const formatBoolean = (value: boolean) => (value ? 'oui' : 'non');

export const getEntranceFeesHeader = ({ intl }: { intl: IntlShape }) => {
    return [
        computeText(intl, 'pricingRule.export.report.entranceFee.entranceFeeId'),
        computeText(intl, 'pricingRule.export.report.entranceFee.code'),
        computeText(intl, 'pricingRule.export.report.entranceFee.labelTicket'),
        computeText(intl, 'pricingRule.export.report.entranceFee.amount'),
        computeText(intl, 'pricingRule.export.report.entranceFee.dailyLimit'),
        computeText(intl, 'pricingRule.export.report.entranceFee.strategy'),
        computeText(intl, 'pricingRule.export.report.entranceFee.vat'),
        computeText(intl, 'pricingRule.export.report.entranceFee.type'),
        computeText(intl, 'pricingRule.export.report.entranceFee.active'),
    ];
};

export const getGrantsHeader = ({ intl }: { intl: IntlShape }) => {
    return [
        computeText(intl, 'pricingRule.export.report.grant.grantId'),
        computeText(intl, 'pricingRule.export.report.grant.code'),
        computeText(intl, 'pricingRule.export.report.grant.labelTicket'),
        computeText(intl, 'pricingRule.export.report.grant.threshold'),
        computeText(intl, 'pricingRule.export.report.grant.type'),
        computeText(intl, 'pricingRule.export.report.grant.ceiling'),
        computeText(intl, 'pricingRule.export.report.grant.amount'),
        computeText(intl, 'pricingRule.export.report.grant.dailyLimit'),
        computeText(intl, 'pricingRule.export.report.grant.strategy'),
        computeText(intl, 'pricingRule.export.report.grant.tag'),
        computeText(intl, 'pricingRule.export.report.grant.active'),
    ];
};

export const getPricingRulesHeader = ({ intl }: { intl: IntlShape }) => {
    return [
        computeText(intl, 'pricingRule.export.report.pricingRule.pricingRuleId'),
        computeText(intl, 'pricingRule.export.report.pricingRule.code'),
        computeText(intl, 'pricingRule.export.report.pricingRule.applyScope'),
        computeText(intl, 'pricingRule.export.report.pricingRule.canals'),
        computeText(intl, 'pricingRule.export.report.pricingRule.isUrssafEnabled'),
        computeText(intl, 'pricingRule.export.report.pricingRule.entranceFees'),
        computeText(intl, 'pricingRule.export.report.pricingRule.grants'),
        computeText(intl, 'pricingRule.export.report.pricingRule.includeCA'),
    ];
};

export const getSheetOptions = ({ intl }: { intl: IntlShape }) => {
    const sheetOptions = {
        entranceFee: {
            name: 'Admission',
            headers: getEntranceFeesHeader({ intl }),
            mapper: (data: EntranceFee) => [
                data.entranceFeeId,
                data.code,
                data.labelTicket,
                data.amount,
                data.dailyLimit,
                data.strategy,
                data.vat,
                computeText(intl, `pricingRule.export.report.entranceFee.type.${data.type.toLowerCase()}`),
                'oui',
            ],
        },
        grant: {
            name: 'Subvention',
            headers: getGrantsHeader({ intl }),
            mapper: (data: Grant) => [
                data.grantId,
                data.code,
                data.labelTicket,
                data.threshold || '',
                computeText(intl, `pricingRule.export.report.grant.type.${data.type.toLowerCase()}`),
                data.ceiling || '',
                data.amount,
                data.dailyLimit,
                data.strategy,
                data.tag || '',
                'oui',
            ],
        },
        pricingRule: {
            name: 'Régime tarifaire',
            headers: getPricingRulesHeader({ intl }),
            mapper: (data: PricingRule) => [
                data.pricingRuleId,
                data.code,
                data.applyScope
                    .map((scope) =>
                        computeText(intl, `pricingRule.export.report.pricingRule.applyScope.${scope.toLowerCase()}`),
                    )
                    .join(', '),
                'caisse, borne de rechargement, distribution automatique, borne, commande en ligne',
                formatBoolean(data.isUrssafEnabled),
                data.entranceFees.map(({ code }) => code).join(', '),
                data.grants.map(({ code }) => code).join(', '),
            ],
        },
    };

    return sheetOptions;
};
