import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    background: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[2]};
`;

export const StyledTitle = styled(Text)`
    ${({ theme }) => theme.font.caption.bold};
    line-height: 20px;
`;

export const StyledValue = styled(Text)`
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.size.huge};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    color: ${({ theme }) => theme.color.black};
`;

export const StyledDate = styled.div`
    margin-top: ${({ theme }) => theme.spacing[1]};
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[1]};
    ${({ theme }) => theme.font.text.bold};
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.tiny};
    text-transform: uppercase;
`;
