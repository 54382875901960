import { getUserToken } from '@/redux/user';
import { useSyncCrousCatalogMutation } from '@/services/innovorder/moduleCrous/moduleCrous.endpoints';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useSelector } from 'react-redux';

type ManualImportCrousVMProps = {
    onHide: () => void;
    restaurantId: string;
};

export const useManualImportCrousModalVm = ({
    restaurantId,
}: ManualImportCrousVMProps): {
    handleValidate: () => void;
    isSyncSuccess: boolean;
    isLoading: boolean;
    loadError: FetchBaseQueryError;
} => {
    const token = useSelector(getUserToken) as string;
    const [syncMenu, { isSuccess: isSyncSuccess, isLoading, error: loadError }] = useSyncCrousCatalogMutation();

    const handleValidate = async () => {
        await syncMenu({ restaurantId, token });
    };

    return { handleValidate, isSyncSuccess, isLoading, loadError: loadError as FetchBaseQueryError };
};
