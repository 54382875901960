import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import Wizard from '@/components/atoms/Wizard';
import { Input } from '@/components/atoms/Form/Input';
import RawModal from '@/components/atoms/RawModal';
import { stringifyError } from '@/utils/errors';
import { useCreateTableLayoutMutation } from '@/services/innovorder/tableLayout/tableLayout.endpoint';
import { TableLayoutModalInputContainer, TableLayoutModalTextInputLabel } from '../TableLayoutModal.style';

export type CreateTableLayoutModalProps = {
    restaurantId: number;
    onHide: () => void;
};

export const CreateTableLayoutModal: React.FunctionComponent<React.PropsWithChildren<CreateTableLayoutModalProps>> = ({
    restaurantId,
    onHide,
}) => {
    const intl = useIntl();
    const [postCreateTableLayout, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, error: createError }] =
        useCreateTableLayoutMutation();
    const { control, getValues, setError } = useForm<{ name: string | null }>({
        defaultValues: {
            name: null,
        },
    });

    const onCreateValidate = useCallback(() => {
        const { name } = getValues();

        if (!name) {
            return setError('name', { message: computeText(intl, 'tableLayout.modal.error.name') });
        }

        return postCreateTableLayout({
            restaurantId,
            name,
        });
    }, [setError, intl, getValues, postCreateTableLayout, restaurantId]);

    useEffect(() => {
        if (isCreateSuccess) {
            onHide();
        }
    }, [isCreateSuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={480}
                isLoading={isCreateLoading}
                steps={[
                    {
                        title: 'tableLayout.modal.create.title',
                        onCancel: onHide,
                        component: (
                            <TableLayoutModalInputContainer>
                                <TableLayoutModalTextInputLabel type="small" weight="medium" text="tableLayout.name" />
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Input
                                            data-testid="table-layout-modal-name-input"
                                            value={value ?? ''}
                                            isTouched={!!value && value.length > 0}
                                            onChange={onChange}
                                            error={error?.message}
                                        />
                                    )}
                                />
                            </TableLayoutModalInputContainer>
                        ),
                    },
                ]}
                errorMessage={stringifyError(createError)}
                onValidate={onCreateValidate}
            />
        </RawModal>
    );
};
