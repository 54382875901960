import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { CancelOrderReason } from '@/services/innovorder/orders/order.enum';
import Modal from '../../../../components/atoms/Modal';
import Select, { Option } from '../../../../components/atoms/Select';
import { Container } from './OrderDetailsCancelConfirmation.style';

type OrderDetailsCancelConfirmationProps = {
    onHide: () => void;
    onValidate: (reason: CancelOrderReason) => void;
};

const OrderDetailsCancelConfirmation: React.FunctionComponent<
    React.PropsWithChildren<OrderDetailsCancelConfirmationProps>
> = ({ onHide, onValidate }) => {
    const [cancelReason, setCancelReason] = useState<CancelOrderReason>();

    const CancelOrderReasons: Option<CancelOrderReason | undefined>[] = useMemo(
        () => [
            {
                label: `orderDetail.cancel.reason.${CancelOrderReason.CannotFulfillOrder}`,
                value: CancelOrderReason.CannotFulfillOrder,
            },
            {
                label: `orderDetail.cancel.reason.${CancelOrderReason.OrderingMistake}`,
                value: CancelOrderReason.OrderingMistake,
            },
            {
                label: `orderDetail.cancel.reason.${CancelOrderReason.UnsatisfiedCustomer}`,
                value: CancelOrderReason.UnsatisfiedCustomer,
            },
            {
                label: `orderDetail.cancel.reason.${CancelOrderReason.CustomerNoShow}`,
                value: CancelOrderReason.CustomerNoShow,
            },
            {
                label: `orderDetail.cancel.reason.${CancelOrderReason.CustomerLeaves}`,
                value: CancelOrderReason.CustomerLeaves,
            },
            {
                label: `orderDetail.cancel.reason.${CancelOrderReason.LearningMode}`,
                value: CancelOrderReason.LearningMode,
            },
            { label: `orderDetail.cancel.reason.${CancelOrderReason.Technical}`, value: CancelOrderReason.Technical },
            { label: `orderDetail.cancel.reason.${CancelOrderReason.Other}`, value: CancelOrderReason.Other },
        ],
        [],
    );
    const handleChange = useCallback((reason?: CancelOrderReason) => {
        setCancelReason(reason);
    }, []);

    const handleValidate = useCallback(() => {
        if (cancelReason) {
            onValidate(cancelReason);
        }
    }, [cancelReason, onValidate]);

    return (
        <Modal
            isOpen
            onHide={onHide}
            title="orderDetail.cancel.title"
            buttons={
                <Button disabled={!cancelReason} onClick={handleValidate}>
                    <FormattedMessage id="orderDetail.cancel.confirm" />
                </Button>
            }
        >
            <Container>
                <Text text="orderDetail.cancel.text1" />
                <Text text="orderDetail.cancel.text2" />
                <Text text="orderDetail.cancel.text3" />
                <Text text="orderDetail.cancel.text4" />
                <Text text="orderDetail.cancel.text5" />
            </Container>
            <Select
                value={cancelReason}
                options={CancelOrderReasons}
                onChange={handleChange}
                solidListbox
                placeholder="orderDetail.cancel.reason.placeholder"
            />
        </Modal>
    );
};

export default React.memo(OrderDetailsCancelConfirmation);
