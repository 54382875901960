import { innovorderApi } from '../../index';
import { ApiRequest, ApiResponse } from '../../request.types';
import {
    GetConfigPayload,
    PaygreenConfig,
    UpdateConfigPayload,
    DeleteConfigPayload,
    DeleteConfigResponse,
} from './configurations.type';

const configurationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getPaygreenConfigDeprecated: build.query<PaygreenConfig, ApiRequest<GetConfigPayload>>({
            query: ({ token, restaurantId }) => {
                return {
                    url: `/module_paygreens/restaurant/${restaurantId}`,
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    validateStatus: (response, result) => response.status < 400 || result.statusCode === 404,
                };
            },
            transformResponse: (response: ApiResponse<PaygreenConfig>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        updatePaygreenConfigDeprecated: build.mutation<PaygreenConfig, ApiRequest<UpdateConfigPayload>>({
            query: ({ token, modulePaygreenId, ...params }) => {
                return {
                    url: `/module_paygreens/${modulePaygreenId}`,
                    body: params,
                    method: 'PUT',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: PaygreenConfig) => response,
            extraOptions: { maxRetries: 3 },
        }),
        deletePaygreenConfigDeprecated: build.mutation<DeleteConfigResponse, ApiRequest<DeleteConfigPayload>>({
            query: ({ token, modulePaygreenId, restaurantId }) => {
                return {
                    url: `/module_paygreens/${modulePaygreenId}/restaurant/${restaurantId}`,
                    method: 'DELETE',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: DeleteConfigResponse) => response,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useLazyGetPaygreenConfigDeprecatedQuery,
    useGetPaygreenConfigDeprecatedQuery,
    useUpdatePaygreenConfigDeprecatedMutation,
    useDeletePaygreenConfigDeprecatedMutation,
} = configurationApi;
