import React from 'react';

import { Title } from '@/components/atoms/Title';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { ReactComponent as Illustration } from './error.svg';
import { Background, Container, StyledTitle } from './Error.style';

const supportMail = 'support@innovorder.fr';
const mailSubject = 'Erreur backoffice';

const Error: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <Background>
            <Container>
                <Illustration />
                <Title text="Ooops..." />
                <StyledTitle text="errorPage.message" weight="regular" />
                <a href={`mailto:${supportMail}?subject=${mailSubject}`}>
                    <Button buttonType="outlinedPrimary">
                        <Text text="errorPage.support" />
                    </Button>
                </a>
            </Container>
        </Background>
    );
};

export default Error;
