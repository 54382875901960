import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';

export const generateBadgeNumber = (): string => {
    return String(Math.floor(Math.random() * 100000000));
};

export const redirectToCustomerDetailsPage = (customerId: number): void => {
    const customerDetailsPagePath = `/home/customers/${customerId}/info`;
    redirectToBackofficeV1(customerDetailsPagePath);
};
