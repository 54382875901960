import styled from '@emotion/styled';

export const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 33vh;

    ${({ theme }) => theme.breakpoints.mobile} {
        height: 30vh;
    }
`;

export const Logo = styled.img`
    max-width: 250px;
    margin-bottom: 20px;

    ${({ theme }) => theme.breakpoints.mobile} {
        max-width: 200px;
    }
`;

export const ProductTitle = styled.h2`
    font-family: ${({ theme }): string => theme.font.family};
    font-size: ${({ theme }): string => theme.font.size.normal};
    color: ${({ theme }): string => theme.color.black200};
    letter-spacing: 5px;
    text-transform: uppercase;
`;
