export type Env = {
    BACKOFFICE_URL: string;
    DASHBOARD_ENV: AppEnv;
    API_URL: string;
    BILLING_REQUEST_GATEWAY_URL: string;
    LAUNCH_DARKLY_CLIENT_ID: string;
    SENTRY_DSN: string;
    STATIC_URL: string;
    GA_ID?: string;
    PAYMENT_SERVICE_URL: string;
    BEAMER_ID: string;
};

export enum AppEnv {
    Local = 'local',
    Dev = 'dev',
    Preprod = 'preprod',
    Production = 'production',
}

export type RecordUnknown = Record<PropertyKey, unknown>;

export const isRecordUnknown = (_: unknown): _ is RecordUnknown => _ != null && typeof _ === 'object';
