import { useMemo } from 'react';
import { InputSuggestions } from '@/components/atoms/Form/Input/Input';
import { ActiveStudentEntities } from '@/services/innovorder/brand/brand.type';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { EntranceFees } from '@/services/innovorder/entranceFee/entranceFee.type';
import { Grants } from '@/services/innovorder/grant/grant.type';
import { getSectionsByGuestGroupName, getSubSectionsBySectionName } from './PricingConfigurationSection.utils';

export const useGetSubSectionFieldSuggestions = (
    sectionValue: string,
    activeStudentEntities?: ActiveStudentEntities,
    selectedGuestGroup?: GuestGroupWithGuestsCount,
) => {
    return useMemo<InputSuggestions | undefined>(() => {
        const sectionsByGuestGroupName = getSectionsByGuestGroupName(activeStudentEntities, selectedGuestGroup?.name);
        const subSectionsBySectionName = getSubSectionsBySectionName(sectionsByGuestGroupName, sectionValue);
        const subSectionOptions = subSectionsBySectionName.map((section) => section.name as string);
        if (!subSectionOptions.length) {
            return undefined;
        }
        return {
            id: 'subSections',
            options: subSectionOptions,
        };
    }, [activeStudentEntities, sectionValue, selectedGuestGroup]);
};

export const useGetSectionFieldSuggestions = (
    activeStudentEntities?: ActiveStudentEntities,
    selectedGuestGroup?: GuestGroupWithGuestsCount,
) => {
    return useMemo<InputSuggestions | undefined>(() => {
        const sectionsByGuestGroupName = getSectionsByGuestGroupName(activeStudentEntities, selectedGuestGroup?.name);
        const sectionOptions = sectionsByGuestGroupName.map((section) => section.name as string);
        if (!sectionsByGuestGroupName.length) {
            return undefined;
        }
        return {
            id: 'sections',
            options: sectionOptions,
        };
    }, [activeStudentEntities, selectedGuestGroup]);
};

export const useGetEntranceFeeFieldSuggestions = (entranceFees?: EntranceFees) => {
    return useMemo<InputSuggestions | undefined>(() => {
        return {
            id: 'entranceFees',
            options: (entranceFees || []).map(({ code }) => code),
        };
    }, [entranceFees]);
};

export const useGetGrantFieldSuggestions = (grants?: Grants) => {
    return useMemo<InputSuggestions | undefined>(() => {
        return {
            id: 'grants',
            options: (grants || []).map(({ code }) => code),
        };
    }, [grants]);
};
