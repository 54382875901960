import { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { useSelector } from 'react-redux';
import { getUseSalesSummaryExportWithTaxExcluded } from '@/redux/launchDarkly';

export enum ReportType {
    SALES_SUMMARY = 'sales_summary',
    COLLECTIVE_CATERING_SALES_SUMMARY = 'collective_catering_sales_summary',
    COLLECTIVE_CATERING_SALES_SUMMARY_EXCLUDE_TAX = 'collective_catering_sales_summary_exclude_tax',
}

type ReportTypeSelectProps = {
    reportTypeOptions: SelectOptionInterface<ReportType>[];
    selectedReportType: SelectOptionInterface<ReportType>;
    handleSelectReportTypeChange: (option: SingleValue<SelectOptionInterface<ReportType>>) => void;
};

export const useReportTypeSelectProps = (): ReportTypeSelectProps => {
    const intl = useIntl();
    const collectiveCateringSalesSummaryReportTypeOption = {
        value: ReportType.COLLECTIVE_CATERING_SALES_SUMMARY,
        label: computeText(intl, 'salesSummary.form.reportType.collectiveCateringSalesSummary'),
    };
    const collectiveCateringSalesSummaryExclTaxReportTypeOption = {
        value: ReportType.COLLECTIVE_CATERING_SALES_SUMMARY_EXCLUDE_TAX,
        label: computeText(intl, 'salesSummary.form.reportType.collectiveCateringSalesSummaryExclTax'),
    };
    const salesSummaryReportTypeOption = {
        value: ReportType.SALES_SUMMARY,
        label: computeText(intl, 'salesSummary.form.reportType.salesSummary'),
    };
    const displaySalesSummaryExportWithTaxExcludedOption = useSelector(getUseSalesSummaryExportWithTaxExcluded);

    const reportTypeOptions = [collectiveCateringSalesSummaryReportTypeOption, salesSummaryReportTypeOption];
    if (displaySalesSummaryExportWithTaxExcludedOption)
        reportTypeOptions.push(collectiveCateringSalesSummaryExclTaxReportTypeOption);

    const [selectedReportType, setSelectedReportType] = useState<SelectOptionInterface<ReportType>>(
        collectiveCateringSalesSummaryReportTypeOption,
    );

    const handleSelectReportTypeChange = (option: SingleValue<SelectOptionInterface<ReportType>>) => {
        setSelectedReportType(option || collectiveCateringSalesSummaryReportTypeOption);
    };

    return {
        reportTypeOptions,
        selectedReportType,
        handleSelectReportTypeChange,
    };
};
