import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportFilterFormValues, ReportFilterToggleValues } from '@/pages/Reporting/common/types';
import {
    useDeleteUserReportFilterMutation,
    useGetReportFiltersQuery,
    useGetUserSavedReportFiltersQuery,
    useSaveUserReportFilterMutation,
} from '@/services/innovorder/report/report.endpoints';
import { FilterConfig, SavedReportFilter } from '@/services/innovorder/report/report.type';
import FiltersButton from '@/pages/Reporting/Report/ReportTable/FiltersButton';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getUserId } from '@/redux/user';
import SavedFiltersDropdown from '@/pages/Reporting/Report/ReportTable/SavedFiltersDropdown';
import FiltersModal from '@/pages/Reporting/Report/ReportTable/FiltersModal';

type HandleFiltersProps = { filtersUrl: string };

type FiltersHandlers = {
    filters: ReportFilterFormValues;
    filtersButton: React.ReactElement;
    savedFiltersDropdown?: React.ReactElement;
    filtersModal?: React.ReactElement;
};

const useGetFormattedSavedFilter = () => {
    const { reportName } = useParams<{ reportName: string }>();
    const userId = useSelector(getUserId);

    const { data: allSavedFilters } = useGetUserSavedReportFiltersQuery({ userId }, { skip: !userId });
    const savedFilters = useMemo(() => allSavedFilters?.[reportName], [allSavedFilters, reportName]);
    const savedFiltersMap = useMemo(
        () => Object.fromEntries(savedFilters?.map((filter) => [filter.reportFilterId, filter]) || []),
        [savedFilters],
    );

    return { savedFilters, savedFiltersMap };
};

function getDefaultFilters(filtersConfig: FilterConfig[]): Record<string, ReportFilterToggleValues> {
    return filtersConfig.reduce((prev, { type, key, options }) => {
        if (type === 'toggle') return { ...prev, [key]: options.default };
        return { ...prev };
    }, {});
}

const useFiltersState = ({ filtersConfig }: { filtersConfig?: FilterConfig[] }) => {
    const [filters, setFilters] = useState<ReportFilterFormValues>({});

    const filtersDefaultValue = useMemo(() => {
        if (filtersConfig) {
            return getDefaultFilters(filtersConfig);
        }
        return {};
    }, [filtersConfig]);

    const resetFilters = useCallback(() => {
        setFilters(filtersDefaultValue);
    }, [filtersDefaultValue]);

    useEffect(() => {
        resetFilters();
    }, [resetFilters]);

    return { filters, setFilters, resetFilters };
};

const useSaveUserReportFilter = () => {
    const [doSaveUserReportFilter, { data: createdReportFilter }] = useSaveUserReportFilterMutation();
    const { reportName } = useParams<{ reportName: string }>();
    const userId = useSelector(getUserId);

    const saveUserReportFilter = (filterName: string, selectedFilters: ReportFilterFormValues) => {
        doSaveUserReportFilter({
            userId,
            body: { userId, name: filterName, filtersJSON: selectedFilters, reportType: reportName },
        });
    };

    return {
        saveUserReportFilter,
        createdReportFilter,
    };
};

const useDeleteReportFilter = () => {
    const [doDeleteUserReportFilter] = useDeleteUserReportFilterMutation();
    const userId = useSelector(getUserId);

    const deleteUserReportFilter = (reportFilterId: number) => {
        doDeleteUserReportFilter({ userId, reportFilterId });
    };

    return { deleteUserReportFilter };
};

export const useHandleFilters = ({ filtersUrl }: HandleFiltersProps): FiltersHandlers => {
    const [selectedSavedFilter, setSelectedSavedFilter] = useState<SavedReportFilter | null>(null);
    const [isFiltersSelectorOpen, setIsFiltersSelectorOpen] = useState<boolean>(false);

    const { data: filtersConfig, isLoading: areFiltersLoading } = useGetReportFiltersQuery({ filtersUrl });
    const { savedFilters, savedFiltersMap } = useGetFormattedSavedFilter();
    const { saveUserReportFilter, createdReportFilter } = useSaveUserReportFilter();
    const { deleteUserReportFilter } = useDeleteReportFilter();

    const { filters, setFilters, resetFilters } = useFiltersState({ filtersConfig });

    const selectASavedReportFilter = (filterId: number) => {
        const selected = savedFiltersMap[filterId];
        if (selected) {
            setSelectedSavedFilter(savedFiltersMap[filterId]);
            setFilters(JSON.parse(savedFiltersMap[filterId].filtersJSON));
        }
    };

    const unselectASavedReportFilter = () => {
        setSelectedSavedFilter(null);
        resetFilters();
    };

    const openFiltersSelector = () => {
        setIsFiltersSelectorOpen(true);
    };

    const closeFiltersSelector = () => {
        setIsFiltersSelectorOpen(false);
    };

    const submitFilters = (selectedFilters: ReportFilterFormValues) => {
        setSelectedSavedFilter(null);
        setFilters(selectedFilters);
        closeFiltersSelector();
    };

    useEffect(() => {
        if (createdReportFilter) {
            setSelectedSavedFilter(createdReportFilter);
        }
    }, [createdReportFilter]);

    const saveFilterAs = (filterName: string, selectedFilters: ReportFilterFormValues) => {
        submitFilters(selectedFilters);
        saveUserReportFilter(filterName, selectedFilters);
    };

    const onSelectSavedReportFilter = (filterId?: number) => {
        if (filterId) {
            selectASavedReportFilter(filterId);
        } else {
            unselectASavedReportFilter();
        }
    };

    const onDeleteReportFilter = (reportFilterIdToDelete: number) => {
        deleteUserReportFilter(reportFilterIdToDelete);
        if (selectedSavedFilter?.reportFilterId === reportFilterIdToDelete) {
            unselectASavedReportFilter();
        }
    };

    const filtersButton = (
        <FiltersButton
            key="filters-button"
            openFiltersSelector={openFiltersSelector}
            areFiltersLoading={areFiltersLoading}
        />
    );
    const savedFiltersDropdown = savedFilters ? (
        <SavedFiltersDropdown
            key="saved-filters-dropdown"
            savedFilters={savedFilters}
            selectedSavedFilter={selectedSavedFilter}
            onSelectSavedReportFilter={onSelectSavedReportFilter}
            onDeleteReportFilter={onDeleteReportFilter}
        />
    ) : undefined;
    const filtersModal =
        isFiltersSelectorOpen && filtersConfig ? (
            <FiltersModal
                close={closeFiltersSelector}
                filtersConfig={filtersConfig}
                initialValues={filters}
                submitFilters={submitFilters}
                saveFilterAs={saveFilterAs}
            />
        ) : undefined;

    return {
        filters,
        filtersButton,
        savedFiltersDropdown,
        filtersModal,
    };
};
