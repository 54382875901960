import { useParams } from 'react-router';
import { useGetPosAccessUsersQuery } from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.endpoints';
import { useGetPosDevicesQuery } from '@/services/innovorder/posDevice/posDevice.endpoints';
import { useGetPosAccessGroupByIdQuery } from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.endpoints';
import { PostPosAccessGroupBody } from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.type';
import { useMemo } from 'react';

export type MenuRolesViewModel = {
    posGroup: PostPosAccessGroupBody | null;
    posUsers: { posUserId: number; name: string }[];
    posDevices: { posDeviceId: number; deviceName: string }[];
    isLoading: boolean;
};

type ParamsTypes = {
    restaurantId: string;
    brandId: string;
    groupId: string;
};

export function usePosGroupFormVM(): MenuRolesViewModel {
    const { restaurantId, brandId, groupId } = useParams<ParamsTypes>();
    const { data: group = null } = useGetPosAccessGroupByIdQuery({
        restaurantId: +restaurantId,
        groupId: +groupId,
    });
    const { data: posUsers = [], isLoading: isLoadingPosUsers } = useGetPosAccessUsersQuery({
        restaurantId: +restaurantId,
    });
    const { data: posDevices = [], isLoading: isLoadingPosDevices } = useGetPosDevicesQuery({
        restaurantId: +restaurantId,
        brandId: +brandId,
    });

    const isLoading = isLoadingPosUsers || isLoadingPosDevices;
    const posGroup: PostPosAccessGroupBody | null = useMemo(() => {
        return group
            ? {
                  name: group.name,
                  posUserIds: group.posUsers.map(({ posUserId }) => posUserId),
                  posDeviceIds: group.posDevices.map(({ posDeviceId }) => posDeviceId),
              }
            : null;
    }, [group]);
    return {
        posGroup,
        posUsers: posUsers?.map(({ name, posUserId }) => ({ name, posUserId })),
        posDevices: posDevices?.map(({ deviceName, posDeviceId }) => ({ posDeviceId, deviceName })),
        isLoading,
    };
}
