import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import { ENV } from '@/utils/env';
import { computeCookieDomain, COOKIE_PATH } from '@/pages/Login/Login';
import { SpotlightCookie } from '@/services/innovorder/spotlight/spotlight.type';

export const SPOTLIGHT_COOKIE_MAX_LENGTH = 40;
export const spotlightCookieLocation = `innovorder.spotlight-${ENV.DASHBOARD_ENV}`;
const ONE_YEAR_IN_SECONDS = 365 * 24 * 60 * 60;

export const useSpotlightCookieHandler = (): SpotlightCookie => {
    const { pathname: currentPathname } = useLocation();
    const [cookies, setCookies] = useCookies();
    const existingSpotlightHistoryJSON = cookies[spotlightCookieLocation];
    const [shouldRefreshSpotlightHistoryCookie, setShouldRefreshSpotlightHistoryCookie] = useState<boolean>(true);
    const [spotlightHistory, setSpotlightHistory] = useState<SpotlightCookie>(existingSpotlightHistoryJSON || {});

    useEffect(() => {
        setShouldRefreshSpotlightHistoryCookie(true);
    }, [currentPathname]);

    useEffect(() => {
        if (shouldRefreshSpotlightHistoryCookie) {
            setShouldRefreshSpotlightHistoryCookie(false);

            let existingSpotlightHistory: SpotlightCookie = existingSpotlightHistoryJSON || {};

            if (
                Object.keys(existingSpotlightHistory).length >= SPOTLIGHT_COOKIE_MAX_LENGTH &&
                !existingSpotlightHistory[currentPathname]
            ) {
                const spotlightHistoryEntries = Object.entries(existingSpotlightHistory);
                const sortedSpotlightHistoryEntries = spotlightHistoryEntries
                    .slice()
                    .sort((a, b) => b[1] - a[1])
                    .slice(0, SPOTLIGHT_COOKIE_MAX_LENGTH);
                existingSpotlightHistory = Object.fromEntries(sortedSpotlightHistoryEntries);
            }

            const newSpotlightHistory = {
                ...existingSpotlightHistory,
                [currentPathname]: existingSpotlightHistory[currentPathname]
                    ? existingSpotlightHistory[currentPathname] + 1
                    : 1,
            };

            setCookies(spotlightCookieLocation, JSON.stringify(newSpotlightHistory), {
                domain: computeCookieDomain(window.location.host),
                path: COOKIE_PATH,
                maxAge: ONE_YEAR_IN_SECONDS,
            });

            setSpotlightHistory(newSpotlightHistory);
        }
    }, [shouldRefreshSpotlightHistoryCookie, currentPathname, existingSpotlightHistoryJSON, setCookies]);

    return spotlightHistory;
};
