import React from 'react';
import ReactDOM from 'react-dom';

import { Container, Content, Overlay } from './RawModal.style';

/**
 * The 'modal-root' div allows the portal to append on it
 * https://fr.reactjs.org/docs/portals.html
 */
const getModalRoot = () => {
    let modalRoot = document.getElementById('modal-root');
    if (!modalRoot) {
        modalRoot = document.createElement('div');
        modalRoot.setAttribute('id', 'modal-root');
        document.body.appendChild(modalRoot);
    }
    return modalRoot;
};
const modalRoot = getModalRoot();

export type RawModalProps = {
    onHide?: () => void;
    isOpen?: boolean;
    width?: number;
    height?: number;
};

const RawModal: React.FunctionComponent<React.PropsWithChildren<RawModalProps>> = ({
    children,
    isOpen = true,
    onHide = () => {},
    width,
    height,
    ...otherProps
}) => {
    return !isOpen
        ? null
        : ReactDOM.createPortal(
              <Container onClick={onHide}>
                  <Overlay data-testid="modal-overlay" />
                  <Content
                      data-testid="modal-content"
                      width={width}
                      height={height}
                      onClick={(e) => e.stopPropagation()}
                      {...otherProps}
                  >
                      {children}
                  </Content>
              </Container>,
              modalRoot,
          );
};

export default RawModal;
