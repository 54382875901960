import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { ReadingConditionsFormValues } from '../FormModal.types';
import { ConvertionType, ReadingCondition } from '@/services/innovorder/badgeReader/badgeReader.type';

const useFormValues = () => {
    const { getValues } = useFormContext();

    return {
        ...useWatch(),
        ...getValues(),
    };
};

export const useReadingConditionsStepVm = () => {
    const intl = useIntl();
    const { readingConditions } = useFormValues();

    const { fields, remove, replace, insert } = useFieldArray<
        { readingConditions: ReadingConditionsFormValues },
        'readingConditions'
    >({
        name: 'readingConditions',
    });

    const orderedFields = useMemo(() => fields.sort((a, b) => a.order - b.order), [readingConditions]);

    const handlePersistArrayWithNewPosition = useCallback(
        (array: Partial<ReadingCondition>[]) => {
            const arrayWithRightPositions = array.map((field, index) => ({
                ...field,
                order: index + 1,
            }));
            replace(arrayWithRightPositions as ReadingCondition[]);
        },
        [replace],
    );

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            if (!Number.isNaN(result.destination?.index)) {
                const destinationId = Number(result.destination?.index);
                const sourceId = Number(result.source.index);

                const newData = [...readingConditions];
                const [movedRow] = newData.splice(sourceId, 1);
                newData.splice(destinationId, 0, movedRow);
                handlePersistArrayWithNewPosition(newData);
            }
        },
        [handlePersistArrayWithNewPosition, orderedFields],
    );

    const handleAddItem = useCallback(() => {
        handlePersistArrayWithNewPosition(readingConditions);
        insert(orderedFields.length, {
            order: orderedFields.length + 1,
            stringLength: 0,
            convertBefore: ConvertionType.None,
            substrPositionStart: 0,
            substrLength: 0,
            convertAfter: ConvertionType.None,
        });
    }, [insert, readingConditions, orderedFields.length]);

    return {
        intl,
        fields: orderedFields,
        handleDragEnd,
        handleAddItem,
        handleRemoveItem: remove,
    };
};
