import styled from '@emotion/styled';

export const Container = styled.div`
    overflow: auto;
    background-color: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.radius.main};

    div {
        border-bottom: 0px;
        border-left: 0px;
        border-right: 0px;
    }
`;
