import React from 'react';

import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import { Body, Container, Header, HeaderLeft, HeaderRight, Subtitle } from './Section.style';

export type SectionProps = {
    title: string;
    subtitle?: string;
    overflowBody?: boolean;
    actions?: React.ReactNode;
};

export const Section: React.FunctionComponent<React.PropsWithChildren<SectionProps>> = ({
    title,
    subtitle,
    actions,
    children,
    overflowBody = true,
}) => {
    return (
        <Container>
            <Header>
                <HeaderLeft>
                    <Title text={title} />
                    {subtitle && (
                        <Subtitle>
                            <Text text={subtitle} />
                        </Subtitle>
                    )}
                </HeaderLeft>
                {actions && <HeaderRight data-testid="section-right-header">{actions}</HeaderRight>}
            </Header>

            <Body overflowBody={overflowBody}>{children}</Body>
        </Container>
    );
};
