import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StepWrapper, SwitchGroup, TitleWrapper } from '../CreateBrandForm.style';
import { CreateBrandPayload } from '@/services/innovorder/brand/brand.type';
import { Switch } from '@/components/atoms/Switch';
import { Title } from '@/components/atoms/Title';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';

const DisplayedChannels = [
    {
        label: 'legend.WEB',
        value: 2,
    },
    {
        label: 'legend.KIOSK',
        value: 1,
    },
    {
        label: 'legend.RECEPTION',
        value: 4,
    },
    {
        label: 'legend.POS',
        value: 5,
    },
];
const ManageCanalsStep = () => {
    const { control } = useFormContext<CreateBrandPayload>();
    const intl = useIntl();
    return (
        <StepWrapper>
            <TitleWrapper>
                <Title text={computeText(intl, 'brands.form.selectUser')} />
            </TitleWrapper>
            <Controller
                control={control}
                name={'channels'}
                render={({ field: { value, onChange }, fieldState: {} }) => {
                    return (
                        <SwitchGroup>
                            {DisplayedChannels.map((channel) => {
                                return (
                                    <Switch
                                        value={value.includes(channel.value)}
                                        onChange={(e) => {
                                            const currentChannelIndex = value.indexOf(channel.value);
                                            const isActivating = currentChannelIndex === -1;
                                            if (isActivating) {
                                                onChange([...value, channel.value]);
                                            } else {
                                                value.splice(currentChannelIndex, 1);
                                                onChange(value);
                                            }
                                        }}
                                        labelId={channel.label}
                                    />
                                );
                            })}
                        </SwitchGroup>
                    );
                }}
            />
        </StepWrapper>
    );
};
export default ManageCanalsStep;
