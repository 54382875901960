import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const MenuModalInputContainer = styled.div`
    margin: 8px 0;
`;

export const MenuModalChannelsTitle = styled(Text)`
    padding-bottom: 8px;
    margin: 0 0 8px 10px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey300};
`;

export const MenuModalTextInputLabel = styled(Text)`
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.black300};
    margin: 10px;
`;

export const ModalSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    user-select: none;

    div > label {
        font-size: ${({ theme }) => theme.font.size.normal};
        font-weight: ${({ theme }) => theme.font.weight.regular};
        color: ${({ theme }) => theme.color.black200};
    }
`;
