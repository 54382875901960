import { useLocation, useRouteMatch } from 'react-router';

import { routes } from '@/core/router/routes';
import { Routes } from '@/core/router/routes.types';
import { getRouteAttributesFromPath } from '@/utils/routes';

export const useRoutesAttributes = (): {
    isChildren: boolean;
    actualRoute?: Routes;
    parent?: Routes;
    backToV1?: string;
} => {
    const { pathname } = useLocation();
    const match = useRouteMatch();
    const route = getRouteAttributesFromPath(match.path, routes);

    if (Object.values(Routes).includes(pathname as Routes) && !route) {
        throw new Error(`route : "${pathname}" is not defined in routes. You must register it.`);
    }

    if (!route) {
        return {
            isChildren: false,
            actualRoute: undefined,
            parent: undefined,
            backToV1: undefined,
        };
    }

    return {
        isChildren: !!route.parent,
        actualRoute: route.path ?? route.uri,
        parent: route.parent,
        backToV1: route.backToV1,
    };
};
