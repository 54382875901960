import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Redirect, useParams } from 'react-router';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import Loader from '@/components/atoms/Loader';
import { Routes } from '@/core/router/routes.types';
import { useGetTransferByIdQuery } from '@/services/innovorder/io-pay/transfers/transfer.endpoints';
import { getUserToken } from '@/redux/user';
import { Container, ContentContainer } from '../Transfers.style';
import TransferDetailsTransactions from './TransferDetailsTransactions';
import TransferDetailsMainInfo from './TransferDetailsMainInfo';

type Props = Record<string, never>;

const TransferDetails: React.FunctionComponent<React.PropsWithChildren<Props>> = () => {
    const { transferId } = useParams<{ transferId: string }>();
    const theme = useTheme();
    const token = useSelector(getUserToken);

    const { data: transfer, isLoading } = useGetTransferByIdQuery(
        transferId
            ? {
                  transferId: parseInt(transferId, 10),
                  token,
              }
            : skipToken,
        { refetchOnMountOrArgChange: true },
    );

    if (isLoading) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    if (!transfer) {
        return <Redirect to={Routes.NotFound} />;
    }

    return (
        <Container data-testid="transfer-detail-page">
            <ContentContainer>
                <TransferDetailsMainInfo
                    configuration={transfer.configuration?.name}
                    amount={transfer.amount}
                    transactionCount={transfer.transactions.length}
                    status={transfer.status}
                    executedAt={transfer.executedAt}
                    reference={transfer.reference}
                />
            </ContentContainer>
            <ContentContainer>
                <TransferDetailsTransactions transferId={transfer.id} />
            </ContentContainer>
        </Container>
    );
};

export default TransferDetails;
