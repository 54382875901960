import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
import { ApiRequest, ApiResponse } from '../../request.types';
import { GetSingleTransferPayload, GetTransfersPayload, GetTransfersResponse, TransferResponse } from './transfer.type';

const transactionApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getTransfers: build.mutation<GetTransfersResponse, ApiRequest<GetTransfersPayload>>({
            query: ({ restaurantIds, customerId, search, filters, order, ...otherParams }) => {
                return {
                    url: new URL('/v1/outgoing-transfers', ENV.PAYMENT_SERVICE_URL).href,
                    params: {
                        customerId,
                        restaurantIds,
                        search,
                        order,
                        ...filters,
                        ...otherParams,
                    },
                    method: 'GET',
                };
            },
            transformResponse: (response: ApiResponse<GetTransfersResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getTransferById: build.query<TransferResponse, ApiRequest<GetSingleTransferPayload>>({
            query: ({ transferId }) => {
                return {
                    url: new URL(`/v1/outgoing-transfers/${transferId}`, ENV.PAYMENT_SERVICE_URL).href,
                    method: 'GET',
                };
            },
            transformResponse: (response: ApiResponse<TransferResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetTransfersMutation, useGetTransferByIdQuery } = transactionApi;
