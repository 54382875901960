import styled from '@emotion/styled';

export const Container = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.color.white100};
    padding: 0 ${({ theme }) => theme.spacing[4]};
    padding-bottom: ${({ theme }) => theme.spacing[4]};
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;
