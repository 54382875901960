import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import { GenerationStatus, ReportGeneration } from '@/services/innovorder/reportGeneration/reportGeneration.type';
import { formatDate } from '@/utils/date';

const parseCreatedAtField = (createdAt: string): TableCell => {
    return {
        type: 'string',
        value: { text: formatDate(createdAt, 'DD/MM/YYYY HH:mm') },
    };
};

const parseFileNameField = (fileName: string): TableCell => {
    return {
        type: 'string',
        value: {
            text: fileName,
        },
    };
};

const parseReportTypeField = (intl: IntlShape, reportType: string): TableCell => {
    return {
        type: 'string',
        value: {
            text: computeText(intl, `reportGenerationsHistory.tableRow.reportType.${reportType}`),
        },
    };
};

const getStatusBadgeColor = (generationStatus: GenerationStatus): string => {
    switch (generationStatus) {
        case 'in_progress':
            return '#2880EB';
        case 'succeed':
            return '#3EC978';
        case 'failed':
            return '#F45959';
        default:
            return '#313443';
    }
};

const parseStatusField = (intl: IntlShape, generationStatus: GenerationStatus): TableCell => {
    const color = getStatusBadgeColor(generationStatus);
    return {
        type: 'badge',
        value: {
            backgroundColor: color,
            text: computeText(intl, `reportGenerationsHistory.tableRow.generationStatus.${generationStatus}`),
        },
    };
};

export const parseActionField = (
    intl: IntlShape,
    generationStatus: GenerationStatus,
    reportGenerationId: number,
    downloadReportGenerationFile: (reportGenerationId: number) => void,
): TableCell => {
    return generationStatus === 'succeed'
        ? {
              type: 'action',
              value: {
                  children: computeText(intl, `reportGenerationsHistory.tableRow.action.download`),
                  onClick: () => {
                      downloadReportGenerationFile(reportGenerationId);
                  },
              },
          }
        : { type: 'empty', value: '' };
};

const mapReportGenerationToTableData = (
    intl: IntlShape,
    reportGeneration: ReportGeneration,
    downloadReportGenerationFile: (reportGenerationId: number) => void,
): TableRow => ({
    createdAt: parseCreatedAtField(reportGeneration.createdAt),
    fileName: parseFileNameField(reportGeneration.name),
    reportType: parseReportTypeField(intl, reportGeneration.reportType),
    status: parseStatusField(intl, reportGeneration.status),
    action: parseActionField(
        intl,
        reportGeneration.status,
        reportGeneration.reportGenerationId,
        downloadReportGenerationFile,
    ),
});

export const getReportGenerationsHistoryTableRowsVM = (
    intl: IntlShape,
    reportGenerationsHistory: ReportGeneration[],
    downloadReportGenerationFile: (reportGenerationId: number) => void,
): TableRow[] => {
    return reportGenerationsHistory.map((reportGeneration) =>
        mapReportGenerationToTableData(intl, reportGeneration, downloadReportGenerationFile),
    );
};

export const getReportGenerationsHistoryTableColumns = (intl: IntlShape): Column<TableRow>[] => [
    {
        Header: computeText(intl, 'reportGenerationsHistory.tableHeader.createdAt'),
        accessor: 'createdAt',
    },
    {
        Header: computeText(intl, 'reportGenerationsHistory.tableHeader.fileName'),
        accessor: 'fileName',
    },
    {
        Header: computeText(intl, 'reportGenerationsHistory.tableHeader.reportType'),
        accessor: 'reportType',
    },
    {
        Header: computeText(intl, 'reportGenerationsHistory.tableHeader.status'),
        accessor: 'status',
    },
    {
        Header: computeText(intl, 'reportGenerationsHistory.tableHeader.action'),
        accessor: 'action',
        width: '1%',
        disableSortBy: true,
    },
];
