import * as React from 'react';

import { computeText } from '@/locales/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import { SvyButton } from '@/components/savory/SvyButton';
import { confirm } from '@/core/asyncActions/confirm';
import { useMakeEwalletTransactionMutation } from '@/services/innovorder/adminTools/adminTools.endpoints';
import { FormControl, FormControls, SuccessMessage } from '../AdminTools.style';
import { safeMutate } from '@/utils/errors';
import { ENV } from '@/utils/env';
import { Form } from '@/core/form/Form';
import { FileInput } from '@/components/form/FileInput';
import { Accept } from 'react-dropzone';
import {
    DescriptionList,
    DropzoneContainer,
    TextContainer,
} from '@/pages/MenuList/components/MenuModal/CreateMenuModal/ImportMenuStep/ImportMenuStep.style';

export type MakeEwalletTransactionInputs = {
    file: File;
};

const ALLOWED_FILE_TYPES: Accept = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
};

const MakeEwalletTransaction: React.FunctionComponent = () => {
    const intl = useIntl();

    const [makeEwalletTransaction, { isSuccess }] = useMakeEwalletTransactionMutation();

    const handleSubmitForm = async (file: MakeEwalletTransactionInputs) => {
        const hasConfirmed = await confirm(computeText(intl, 'adminTools.confirmModal.description'));
        if (hasConfirmed) {
            await safeMutate(makeEwalletTransaction(file));
        }
    };

    return (
        <Form<MakeEwalletTransactionInputs>>
            {({ handleSubmit }) => {
                return (
                    <>
                        <Text type="large" text="brand.adminTools.makeEwalletTransaction.title" />

                        <TextContainer>
                            <DescriptionList>
                                <FormattedMessage
                                    id={`brand.adminTools.makeEwalletTransaction.description`}
                                    values={{
                                        fileTemplate: (
                                            <a
                                                href={`${ENV.STATIC_URL}/dashboard/import/template_transaction_ewallet_import.xlsx`}
                                            >
                                                <FormattedMessage id="file.fileTemplate" />
                                            </a>
                                        ),
                                    }}
                                />
                            </DescriptionList>
                        </TextContainer>
                        <FormControls>
                            <FormControl>
                                <DropzoneContainer>
                                    <React.Suspense>
                                        <FileInput
                                            mode="compact"
                                            accept={ALLOWED_FILE_TYPES}
                                            name="file"
                                            transform={{
                                                output: (file) => file[0],
                                                input: (file: File) => (file ? [file] : []),
                                            }}
                                        />
                                    </React.Suspense>
                                </DropzoneContainer>
                            </FormControl>
                            <SvyButton onClick={handleSubmit(handleSubmitForm)}>
                                <FormattedMessage id="button.validate" />
                            </SvyButton>
                            {isSuccess && (
                                <SuccessMessage text="brand.adminTools.makeEwalletTransaction.form.success" />
                            )}
                        </FormControls>
                    </>
                );
            }}
        </Form>
    );
};

export default MakeEwalletTransaction;
