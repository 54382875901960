import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { Input } from '@/components/atoms/Form/Input';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import RadioButton from '@/components/atoms/RadioButton/RadioButton';
import {
    CostCompute,
    CostComputingMode,
    CostSlice,
    CostSliceMode,
} from '@/services/innovorder/autoBilling/autoBilling.type';
import { Form, HorizontalContainer, RadioButtons, VerticalContainer } from './BillingCostCompute.style';
import CostSliceEditorModal from './CostSliceEditorModal';
import CostSliceTable from './CostSliceTable';

type BillingCostComputeModeProps = {
    costCompute: CostCompute;
    onChange: (value: CostCompute) => void;
    onIsValidChange: (value: boolean) => void;
};

const BillingCostCompute: React.FunctionComponent<React.PropsWithChildren<BillingCostComputeModeProps>> = ({
    costCompute,
    onChange,
    onIsValidChange,
}) => {
    const intl = useIntl();
    const [showCostSliceEditorModal, setShowCostSliceEditorModal] = useState(false);
    const [sliceToEdit, setSliceToEdit] = useState({
        mode: CostSliceMode.CONSTANT_COST,
        value: 0,
        to: 0,
        from: 0,
    });

    const handleCostSliceChange = useCallback(
        (slice: CostSlice) => {
            const oldCostSlices = costCompute.costSlices ?? [];
            const newCosSlices = [...oldCostSlices];
            const sliceIndex = oldCostSlices.findIndex((aSlice) => aSlice === sliceToEdit);

            if (sliceIndex === -1) {
                newCosSlices.push(slice);
            } else {
                newCosSlices[sliceIndex] = slice;
            }
            onChange({ ...costCompute, costSlices: newCosSlices });
        },
        [costCompute, onChange, sliceToEdit],
    );

    const handleCostComputingModeChange = useCallback(
        (mode: CostComputingMode) => {
            onChange({ ...costCompute, mode });
        },
        [costCompute, onChange],
    );

    const onEditSliceHandler = useCallback((slice: CostSlice): void => {
        setSliceToEdit(slice);
        setShowCostSliceEditorModal(true);
    }, []);

    const onDeleteSliceHandler = useCallback(
        (slice: CostSlice): void => {
            const newSlices = costCompute.costSlices?.filter((aSlice) => aSlice !== slice);
            onChange({ ...costCompute, costSlices: newSlices });
        },
        [costCompute, onChange],
    );

    const {
        control,
        getValues,
        trigger,
        formState: { isValid },
    } = useForm<{ constCostAmount: number }>({
        mode: 'all',
        defaultValues: {
            constCostAmount: costCompute.constCostAmount ?? 0,
        },
    });

    useEffect(() => {
        if (costCompute?.mode === CostComputingMode.CONSTANT_COST) {
            const toucheForm = async () => {
                await trigger('constCostAmount');
            };
            toucheForm();
        }
    }, [costCompute, trigger]);

    useEffect(() => {
        onIsValidChange(isValid);
    }, [isValid, onIsValidChange]);

    const handleConstCostValueChange = useCallback(() => {
        if (costCompute?.mode === CostComputingMode.CONSTANT_COST && isValid) {
            onChange({ ...costCompute, constCostAmount: getValues().constCostAmount });
        }
    }, [costCompute, getValues, isValid, onChange]);

    return (
        <>
            <VerticalContainer>
                <HorizontalContainer>
                    <RadioButtons>
                        {Object.values(CostComputingMode)
                            .filter((mode) => mode !== CostComputingMode.PARTITIONED_COST_BY_GUEST)
                            .map((mode) => (
                                <RadioButton<CostComputingMode>
                                    key={mode}
                                    label={`auto.billing.referential.accountingCostCompute.${mode}`}
                                    value={mode}
                                    checked={mode === costCompute?.mode}
                                    onChange={handleCostComputingModeChange}
                                />
                            ))}
                    </RadioButtons>
                    {costCompute?.mode === CostComputingMode.PARTITIONED_CONSTANT_COST && (
                        <>
                            <Button onClick={() => setShowCostSliceEditorModal(true)} buttonType="primary">
                                <FormattedMessage id="auto.billing.referential.accountingCostCompute.addSlice" />
                            </Button>
                            {showCostSliceEditorModal && (
                                <CostSliceEditorModal
                                    slice={sliceToEdit}
                                    costSlices={costCompute.costSlices}
                                    onSliceChange={handleCostSliceChange}
                                    onHide={() => setShowCostSliceEditorModal(false)}
                                />
                            )}
                        </>
                    )}
                </HorizontalContainer>
                {costCompute?.mode === CostComputingMode.CONSTANT_COST && (
                    <Form onBlur={handleConstCostValueChange}>
                        <Controller
                            control={control}
                            name="constCostAmount"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                validate: (value) =>
                                    value <= 0
                                        ? computeText(
                                              intl,
                                              'auto.billing.referential.accountingCostCompute.modal.input.negative',
                                          )
                                        : undefined,
                            }}
                            render={({
                                field: { onChange: onConstCostValueChange, onBlur, value },
                                fieldState: { error },
                            }) => (
                                <VerticalContainer>
                                    <Text
                                        type="small"
                                        weight="medium"
                                        text="auto.billing.referential.accountingCostCompute.amount"
                                    />
                                    <Input
                                        data-testid="code"
                                        error={error?.message}
                                        onChange={onConstCostValueChange}
                                        onBlur={onBlur}
                                        value={value.toString()}
                                        isTouched={true}
                                    />
                                </VerticalContainer>
                            )}
                        />
                    </Form>
                )}
            </VerticalContainer>
            {costCompute?.mode === CostComputingMode.PARTITIONED_CONSTANT_COST && (
                <CostSliceTable
                    costSlices={costCompute.costSlices ?? []}
                    onEdit={onEditSliceHandler}
                    onDelete={onDeleteSliceHandler}
                />
            )}
        </>
    );
};

export default BillingCostCompute;
