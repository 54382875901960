export const downloadFile = (filename: string, data: string | ArrayBuffer, mime: string): void => {
    const file = new Blob([data], { type: mime });
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const downloadFileFromUrl = (url: string): void => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
