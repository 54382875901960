import { generateQueryStringFromParams } from '@/utils/history';
import { innovorderApi } from '../index';
import { ApiResponse } from '../request.types';
import { GetPosDevicesParams, PosDevice } from './posDevice.type';

const posDeviceApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getPosDevices: build.query<PosDevice[], GetPosDevicesParams>({
            query: ({ brandId, restaurantId }) => {
                const queryParameters = generateQueryStringFromParams({ restaurantId, brandId });

                return {
                    url: `/pos_devices?${queryParameters}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: ApiResponse<PosDevice[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetPosDevicesQuery } = posDeviceApi;
