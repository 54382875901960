import styled from '@emotion/styled';

export const StepWrapper = styled.div`
    width: 700px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.savory.spacing[2]};
`;
export const SwitchGroup = styled.div`
    padding: ${({ theme }) => theme.savory.spacing[2]};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.savory.spacing[2]};
`;
export const TitleWrapper = styled.div`
    margin: ${({ theme }) => theme.savory.spacing[1]} auto;
`;
