import React from 'react';
import { useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { Title } from '@/components/atoms/Title';
import { SvyButton } from '@/components/savory/SvyButton';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Table } from '@/components/atoms/Table';
import { Container, FlexGrow, Subtitle, TitleContainer, Header, ContentContainer } from './Configuration.style';
import IOPayTabs from '../IOPayTabs';
import { getConfigurationTableColumns, useConfigurationTableVM } from './Configuration.viewmodel';
import { ConfigurationModal } from './ConfigurationModal/ConfigurationModal';

type Props = Record<string, never>;

export const Configuration: React.FunctionComponent<React.PropsWithChildren<Props>> = () => {
    const theme = useTheme();
    const {
        rows,
        isLoading,
        canCreateLegalEntity,
        onClearAction,
        toggleCreateModal,
        action,
        unconfiguredRestaurants,
        hasEwalletConfigured,
    } = useConfigurationTableVM();
    const headers = getConfigurationTableColumns();

    return (
        <>
            <ConfigurationModal
                action={action.type}
                onHide={onClearAction}
                unconfiguredRestaurants={unconfiguredRestaurants}
                hasEwalletConfigured={hasEwalletConfigured}
            />
            <IOPayTabs>
                <Container>
                    <ContentContainer>
                        <Header data-testid="configuration-head">
                            <TitleContainer>
                                <Title text="configuration.iopay.title" />
                                <Subtitle text="configuration.iopay.subtitle" />
                            </TitleContainer>
                            <FlexGrow />
                            <SvyButton
                                type="button"
                                onClick={toggleCreateModal}
                                elementRight={<SVGIcon icon={SVGIcons.PLUS} color={theme.color.white100} size={20} />}
                                disabled={!canCreateLegalEntity}
                            >
                                <FormattedMessage id="legalEntity.create.title" />
                            </SvyButton>
                        </Header>
                        <Table
                            isSearchable
                            rows={rows}
                            emptyMessageId="table.noRowsAfterFilter"
                            columns={headers}
                            hasPagination
                            isFilterable
                            isSortable
                            isLoading={isLoading}
                        />
                    </ContentContainer>
                </Container>
            </IOPayTabs>
        </>
    );
};
