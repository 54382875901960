import { FieldNamesMarkedBoolean, Path, UseFormTrigger } from 'react-hook-form';

type FieldNamesBoolean<T> = { [key in keyof T]: boolean | undefined };

export const computeAndTriggerTouchedFields = <T extends Record<string, unknown>, P extends Record<string, unknown>>(
    initialData: T | undefined,
    touchedFields: FieldNamesMarkedBoolean<P>,
    trigger: UseFormTrigger<P>,
): FieldNamesMarkedBoolean<P> => {
    let finalTouchedFields = touchedFields as FieldNamesBoolean<P>;

    if (initialData) {
        finalTouchedFields = Object.entries(initialData).reduce(
            (result, [key, value]) =>
                (!Array.isArray(value) && value !== '' && value !== undefined && value !== null) ||
                (Array.isArray(value) && value.length > 0)
                    ? { ...result, [key]: true }
                    : result,
            touchedFields as FieldNamesBoolean<P>,
        );
    }

    const finalTouchedFieldsKeys = Object.keys(finalTouchedFields) as Path<P>[];
    finalTouchedFieldsKeys.map((field) => trigger(field));

    return finalTouchedFields as FieldNamesMarkedBoolean<P>;
};
