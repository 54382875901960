import { MenuAlign } from '@szhsin/react-menu';
import { useTheme } from '@emotion/react';
import React, { useCallback, useMemo } from 'react';
import { DropDown } from '@/components/atoms/DropDown/DropDown';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { UserRestaurant } from '@/services/innovorder/user/user.type';

type RestaurantSelectorProps = {
    restaurants: UserRestaurant[];
    selected: number;
    onChange: (restaurantId: number) => void;
    align?: MenuAlign;
    buttonLabel: string;
    headerLabel?: string;
};

const RestaurantSelector: React.FunctionComponent<React.PropsWithChildren<RestaurantSelectorProps>> = ({
    restaurants,
    selected,
    onChange,
    align = 'end',
    buttonLabel,
    headerLabel,
}) => {
    const handleChange = useCallback((id: number) => onChange(id), [onChange]);
    const theme = useTheme();

    const items = useMemo(
        () => restaurants.map((restaurant) => ({ value: restaurant.restaurantId, label: restaurant.name })),
        [restaurants],
    );

    return (
        <DropDown
            items={items}
            selected={selected}
            onChange={handleChange}
            align={align}
            button={
                <>
                    <SVGIcon icon={SVGIcons.BRAND} size={16} color={theme.color.bloodyMary} />
                    <p>{buttonLabel}</p>
                    <SVGIcon icon={SVGIcons.CHEVRON} size={16} color={theme.color.black200} />
                </>
            }
            headerLabel={headerLabel}
            filterPlaceholder="orderList.filters.restaurantPlaceholder"
            allEntriesLabel="orderList.filters.allRestaurants"
            allEntriesValue={0}
            type="radio"
        />
    );
};

export default RestaurantSelector;
