import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTheme, Theme } from '@emotion/react';
import { components, OptionProps, StylesConfig, InputProps, InputActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useIntl } from 'react-intl';

import { computeText } from '@/locales/utils';
import { ReactComponent as Add } from './add.svg';
import { OptionContainer, InputContainer, IconContainer, InputContent, Container, Content } from './SelectSearch.style';

const getCustomStyles = (theme: Theme): StylesConfig<SearchSelectOptionType, true> => ({
    container: (provided) => ({
        ...provided,
        minWidth: 250,
    }),
    control: (provided) => ({
        ...provided,
        border: 'none',
        cursor: 'pointer',
        boxShadow: 'none',
        outline: 'none',
        ':hover': {
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
        },
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: 40,
        padding: 0,
        backgroundColor: theme.color.grey100,
        border: `1px solid ${theme.color.grey200} !important`,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: state.selectProps.isMenuOpened ? 0 : 4,
        borderBottomRightRadius: state.selectProps.isMenuOpened ? 0 : 4,
        color: theme.color.black200,
        fontFamily: theme.font.family,
        fontSize: theme.font.size.small,
        fontWeight: theme.font.weight.medium,
        cursor: 'pointer',
    }),
    multiValue: (provided) => ({
        ...provided,
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        position: 'absolute',
        right: 0,
        height: '100%',
    }),
    option: (provided) => ({
        ...provided,
        cursor: 'pointer',
        height: 40,
        backgroundColor: theme.color.grey100,
        color: theme.color.black200,
        fontFamily: theme.font.family,
        fontSize: theme.font.size.small,
        fontWeight: theme.font.weight.medium,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        padding: 0,
        ':hover': {
            backgroundColor: theme.color.white100,
            borderRadius: 4,
        },
        ':active': {
            backgroundColor: theme.color.white100,
            borderRadius: 4,
        },
    }),
    menuList: (provided) => ({
        ...provided,
        backgroundColor: theme.color.grey100,
        padding: 0,
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: theme.color.grey100,
        boxShadow: 'none',
        outline: 'none',
        padding: 10,
        marginTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderBottom: `1px solid ${theme.color.grey200} !important`,
        borderLeft: `1px solid ${theme.color.grey200} !important`,
        borderRight: `1px solid ${theme.color.grey200} !important`,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: theme.color.black200,
        fontFamily: theme.font.family,
        fontSize: theme.font.size.small,
        fontWeight: theme.font.weight.medium,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        position: 'absolute',
        left: 42,
        height: 40,
        width: 210,
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        color: theme.color.black200,
        fontFamily: theme.font.family,
        fontSize: theme.font.size.small,
        fontWeight: theme.font.weight.medium,
    }),
});

export type SearchSelectOptionType = {
    value: number;
    label: string;
};

type SelectSearchProps = {
    options: SearchSelectOptionType[];
    onSelectOption: (option: SearchSelectOptionType) => void;
    placeholderId: string;
    noOptionsMessageId: string;
};

export const SelectSearch: FunctionComponent<React.PropsWithChildren<SelectSearchProps>> = ({
    options,
    onSelectOption,
    placeholderId,
    noOptionsMessageId,
}) => {
    const intl = useIntl();
    const theme = useTheme();
    const customStyles = useMemo(() => getCustomStyles(theme), [theme]);

    const [currentOptions, setCurrentOptions] = useState<SearchSelectOptionType[]>(options);
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    useEffect(() => {
        !isMenuOpened && setCurrentOptions(options);
    }, [options, isMenuOpened]);

    const handleOpenMenu = () => {
        setIsMenuOpened(true);
    };

    const handleCloseMenu = () => {
        setIsMenuOpened(false);
    };

    const OPTIONS_LIMIT = 50;

    const { Option, Input } = components;

    const SelectSearchOption = (props: OptionProps<SearchSelectOptionType, true>) => {
        const [isHovered, setIsHovered] = useState<boolean>(false);

        return (
            <Option {...props}>
                <OptionContainer
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => onSelectOption(props.data)}
                >
                    <div>{props.data.label}</div>
                    {isHovered && <Add width={16} height={16} />}
                </OptionContainer>
            </Option>
        );
    };

    const SelectSearchInput = (props: InputProps<SearchSelectOptionType, true>) => {
        if (props.isHidden) {
            return <Input {...props} />;
        }
        return (
            <InputContainer>
                <IconContainer>
                    <Add width={24} height={24} />
                </IconContainer>
                <InputContent>
                    <Input {...props} />
                </InputContent>
            </InputContainer>
        );
    };

    return (
        <Container>
            <Content>
                <AsyncSelect
                    key={JSON.stringify(currentOptions)}
                    loadOptions={(inputValue, callback) => {
                        callback(
                            currentOptions
                                .filter(
                                    (option) =>
                                        inputValue && option.label.toLowerCase().includes(inputValue.toLowerCase()),
                                )
                                .slice(0, OPTIONS_LIMIT),
                        );
                    }}
                    defaultOptions={currentOptions.slice(0, OPTIONS_LIMIT)}
                    onInputChange={(inputValue: string, { action, prevInputValue }: InputActionMeta) =>
                        action === 'set-value' ? prevInputValue : inputValue
                    }
                    hideSelectedOptions
                    styles={customStyles}
                    placeholder={computeText(intl, placeholderId)}
                    noOptionsMessage={() => computeText(intl, noOptionsMessageId)}
                    onMenuOpen={handleOpenMenu}
                    onMenuClose={handleCloseMenu}
                    components={{
                        Option: SelectSearchOption,
                        Input: SelectSearchInput,
                        DropdownIndicator: null,
                    }}
                    isMenuOpened={isMenuOpened}
                    menuIsOpen={isMenuOpened}
                    maxMenuHeight={130}
                    isMulti
                    isClearable={false}
                    isSearchable
                />
            </Content>
        </Container>
    );
};
