// NPM Modules
import { AppEnv, Env } from './types';
import { enumValues, throwIfFalsy } from './typescript';

// TODO: refacto validation with Joi.

const appEnv = import.meta.env.REACT_APP_DASHBOARD_ENV;

throwIfFalsy(import.meta.env.REACT_APP_API_URL, `'REACT_APP_API_URL' variable must be set`);
throwIfFalsy(
    import.meta.env.REACT_APP_BILLING_REQUEST_GATEWAY_URL,
    `'REACT_APP_BILLING_REQUEST_GATEWAY_URL' variable must be set`,
);
throwIfFalsy(import.meta.env.REACT_APP_BACKOFFICE_URL, `'REACT_APP_BACKOFFICE_URL' variable must be set`);
throwIfFalsy(appEnv, `'REACT_APP_DASHBOARD_ENV' variable must be set`);
throwIfFalsy(enumValues(AppEnv).includes(appEnv), `APP_ENV=${appEnv} is not allowed`);
throwIfFalsy(
    import.meta.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    `'REACT_APP_LAUNCH_DARKLY_CLIENT_ID' variable must be set`,
);
throwIfFalsy(import.meta.env.REACT_APP_ENV_SENTRY_DSN, `'REACT_APP_ENV_SENTRY_DSN' variable must be set`);
throwIfFalsy(import.meta.env.REACT_APP_STATIC_URL, `'REACT_APP_STATIC_URL' variable must be set`);
throwIfFalsy(import.meta.env.REACT_APP_BEAMER_ID, `'REACT_APP_BEAMER_ID' variable must be set`);

export const ENV: Env = {
    API_URL: import.meta.env.REACT_APP_API_URL,
    BILLING_REQUEST_GATEWAY_URL: import.meta.env.REACT_APP_BILLING_REQUEST_GATEWAY_URL,
    DASHBOARD_ENV: appEnv as AppEnv, // typsesafe l:22
    BACKOFFICE_URL: import.meta.env.REACT_APP_BACKOFFICE_URL,
    LAUNCH_DARKLY_CLIENT_ID: import.meta.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    SENTRY_DSN: import.meta.env.REACT_APP_ENV_SENTRY_DSN,
    STATIC_URL: import.meta.env.REACT_APP_STATIC_URL,
    GA_ID: import.meta.env.REACT_APP_ENV_GA_ID,
    PAYMENT_SERVICE_URL: import.meta.env.REACT_APP_PAYMENT_SERVICE_URL,
    BEAMER_ID: import.meta.env.REACT_APP_BEAMER_ID,
};
