import { RouteDeclaration, Routes } from '@/core/router/routes.types';
import { getRouteAttributesFromPath } from '@/utils/routes';

export const getRouteParentRecursively = (
    actualRoute: RouteDeclaration,
    routes: RouteDeclaration[],
): RouteDeclaration[] => {
    const { parent } = actualRoute;

    if (!parent) {
        return [];
    }
    const parentRoute = getRouteAttributesFromPath(parent, routes);

    if (!parentRoute) {
        return [];
    }

    const parents = getRouteParentRecursively(parentRoute, routes);
    return [parentRoute, ...parents];
};

export const generateBreadCrumbFromRoute = (route: Routes, routes: RouteDeclaration[]): RouteDeclaration[] => {
    const currentRoute = getRouteAttributesFromPath(route, routes);

    if (!currentRoute) {
        throw new Error(`${route} route doesn't seem to exist`);
    }

    const crumbs = getRouteParentRecursively(currentRoute, routes);

    return [...crumbs.reverse(), currentRoute];
};
