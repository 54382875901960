import React from 'react';
import { useIntl } from 'react-intl';
import { FormProvider } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import RawModal from '@/components/atoms/RawModal';
import { UserRestaurant } from '@/services/innovorder/user/user.type';
import { Container, WizardIoPayConfiguration } from './CreateLegalEntityModal.style';
import { useCreateConfigurationModalVM } from './CreateLegalEntityModal.viewmodel';

export type CreateLegalEntityModalProps = {
    onHide: () => void;
    legalEntityId?: string;
    unconfiguredRestaurants?: UserRestaurant[];
    hasEwalletConfigured?: boolean;
};

export const CreateLegalEntityModal: React.FunctionComponent<React.PropsWithChildren<CreateLegalEntityModalProps>> = ({
    onHide,
    unconfiguredRestaurants,
    hasEwalletConfigured,
    legalEntityId,
}) => {
    const intl = useIntl();
    const { form, currentStep, steps, isLoading, errorMessage, onFormValidate } = useCreateConfigurationModalVM({
        onHide,
        unconfiguredRestaurants,
        hasEwalletConfigured,
        legalEntityId,
    });

    return (
        <RawModal isOpen={true} data-testid="raw-modal">
            <FormProvider {...form}>
                <Container>
                    <WizardIoPayConfiguration
                        width={900}
                        selectedStep={currentStep}
                        steps={steps}
                        validateLabel={computeText(intl, 'button.save')}
                        onValidate={onFormValidate}
                        showStepper={true}
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                        isForm={true}
                    />
                </Container>
            </FormProvider>
        </RawModal>
    );
};
