import styled from '@emotion/styled';
import { StyledInput } from '../../DropDown/DropDown.style';

export const RangeContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const RangeInput = styled(StyledInput)`
    width: 100px;
`;

export const StyledHeader = styled.div`
    cursor: pointer;
`;
