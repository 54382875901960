import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 35vw;
    min-width: 480px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: #0f0f0f;
    color: ${({ theme }): string => theme.color.white100};
    border-color: ${({ theme }): string => theme.color.grey200};
    padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[4]};
    border-top-right-radius: ${({ theme }) => theme.radius.main};
    border-top-left-radius: ${({ theme }) => theme.radius.main};
    min-height: 100px;
`;

export const Context = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
`;

export const Search = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end;
`;

export const Content = styled.div`
    width: 100%;
    background-color: #333333;
    color: ${({ theme }): string => theme.color.white100};
    border-color: ${({ theme }): string => theme.color.grey200};
    padding: ${({ theme }) => theme.spacing[2]};
    border-bottom-right-radius: ${({ theme }) => theme.radius.main};
    border-bottom-left-radius: ${({ theme }) => theme.radius.main};
`;

export const Section = styled.div`
    color: ${({ theme }): string => theme.color.white100};
    ${({ theme }) => theme.font.caption.bold}
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    padding-left: ${({ theme }) => theme.spacing[2]};
`;

export const List = styled.div`
    color: ${({ theme }): string => theme.color.white100};
`;

export const Empty = styled.div`
    font-size: ${({ theme }) => theme.font.size.small};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    color: #999999 !important;
    font-style: italic;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    padding-left: ${({ theme }) => theme.spacing[2]};
`;
