import React from 'react';
import { Section } from '@/components/atoms/Section';
import { StyledText } from './SuccessStep.style';

export type SelectPosDeviceStepProps = {
    state?: 'SUCCESS' | 'WARNING' | 'ERROR';
    message: string;
};

export const SuccessStep: React.FunctionComponent<React.PropsWithChildren<SelectPosDeviceStepProps>> = ({
    message,
    state = 'SUCCESS',
}) => {
    return (
        <Section
            title="pricingRule.modal.import.report.result"
            subtitle={message}
            actions={
                <StyledText
                    state={state}
                    type="small"
                    text={
                        // eslint-disable-next-line no-nested-ternary
                        state === 'SUCCESS'
                            ? 'pricingRule.modal.import.report.success'
                            : state === 'ERROR'
                            ? 'pricingRule.modal.import.report.error'
                            : 'pricingRule.modal.import.report.warning'
                    }
                />
            }
        />
    );
};
