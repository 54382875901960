import React, { useEffect, useState } from 'react';
import { Text } from '@/components/atoms/Text';
import { Label } from '@/components/atoms/Label';
import styled from '@emotion/styled';
import ClickableIcon from '@/components/atoms/ClickableIcon';
import kioskLogo from '@/components/atoms/Icon/png/kioskLogo.png';
import webLogo from '@/components/atoms/Icon/png/webLogo.png';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';
import Loader from '@/components/atoms/Loader';
import { Input } from '@/components/atoms/Form/Input';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/atoms/Button';
import { stringifyError } from '@/utils/errors';
import {
    useDeleteExternalPosModuleByChannelMutation,
    useDeleteExternalPosModuleMutation,
    useGetExternalPosModulesQuery,
    usePostExternalPosModuleMutation,
} from '@/services/innovorder/moduleExternalPos/moduleExternalPos';
import { getUserToken } from '@/redux/user';

const Container = styled.div`
    background: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    line-height: 20px;
    height: fit-content;
`;

const PanelHeader = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    align-items: flex-end;
    & > div {
        flex: 1;
    }
`;

const Panel = styled.div`
    background: ${({ theme }) => theme.color.grey100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    &:last-child {
        margin-bottom: 0;
    }
`;

const ExternalPosChannelConfig = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

const Title = styled(Text)`
    color: ${({ theme }) => theme.color.black};
    line-height: 20px;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const availableExternalPosChannels = [
    {
        channelId: 1,
        name: 'kiosk',
        logo: kioskLogo,
    },
    {
        channelId: 2,
        name: 'web',
        logo: webLogo,
    },
];

type ExternalPosConfigurationPanelProps = {};

const ExternalPosConfigurationPanel: React.FunctionComponent<
    React.PropsWithChildren<ExternalPosConfigurationPanelProps>
> = () => {
    const theme = useTheme();
    const token = useSelector(getUserToken);
    const { brandId, restaurantId } = useParams<{ brandId: string; restaurantId: string }>();
    const {
        data: externalPosModules = [],
        isLoading: isDataLoading,
        refetch,
    } = useGetExternalPosModulesQuery({
        brandId,
        restaurantId,
        token,
    });
    const [inputExternalPosRef, setInputExternalPosRef] = useState('');
    const [postModuleExternalPos, { isLoading: isPostLoading, error: postError }] = usePostExternalPosModuleMutation();
    const [deleteAllExternalPosModule, { isLoading: isDeleteAllLoading }] = useDeleteExternalPosModuleMutation();
    const [deleteExternalPosModule, { isLoading: isDeleteLoading }] = useDeleteExternalPosModuleByChannelMutation();

    const moduleExternalPosRef = (externalPosModules[0] || {})?.externalPosRef;
    const isLoading = isPostLoading || isDeleteLoading || isDeleteAllLoading;
    const getChannelState = (channelId: number) => {
        const isChannelExists = externalPosModules.findIndex(
            ({ channelId: moduleChannelId }) => moduleChannelId === channelId,
        );
        return isChannelExists > -1 ? 'active' : 'inactive';
    };

    useEffect(() => {
        if (!inputExternalPosRef) {
            setInputExternalPosRef(moduleExternalPosRef);
        }
    }, [moduleExternalPosRef, inputExternalPosRef, externalPosModules]);

    const toggleModuleInit = async () => {
        if (moduleExternalPosRef) {
            await deleteAllExternalPosModule({ brandId, restaurantId, token });
        } else {
            await postModuleExternalPos({
                token,
                brandId,
                restaurantId,
                modules: availableExternalPosChannels.map(({ channelId }) => ({
                    channelId,
                    externalPosRef: inputExternalPosRef,
                })),
            });
        }
        refetch();
    };

    const toggleModuleChannel = async (channelId: number) => {
        const channelState = getChannelState(channelId);
        if (channelState === 'inactive') {
            await postModuleExternalPos({
                token,
                brandId,
                restaurantId,
                modules: [
                    {
                        channelId,
                        externalPosRef: inputExternalPosRef,
                    },
                ],
            });
        } else {
            await deleteExternalPosModule({ brandId, restaurantId, channelId, token });
        }
        refetch();
    };

    if (isDataLoading) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <Container>
            <PanelHeader>
                <Input
                    onChange={(e) => setInputExternalPosRef(e.target.value)}
                    disabled={!!moduleExternalPosRef}
                    labelId="external_pos.pos_ref.label"
                    placeholder="external_pos.pos_ref.placeholder"
                    error={stringifyError(postError)}
                    value={inputExternalPosRef}
                />
                <Button
                    buttonType={moduleExternalPosRef ? 'red' : 'primary'}
                    onClick={toggleModuleInit}
                    isLoading={isLoading}
                    disabled={isLoading}
                >
                    <FormattedMessage id={`button.${moduleExternalPosRef ? 'delete' : 'save'}`} />
                </Button>
            </PanelHeader>
            <Label labelId="external_pos.channels.title" />
            <Panel>
                <Title type="small" weight="bold" text={'external_pos.channels.subtitle'} />
                <ExternalPosChannelConfig>
                    {availableExternalPosChannels.map(({ channelId, logo }) => {
                        const channelState = !moduleExternalPosRef
                            ? 'disabled'
                            : isLoading
                            ? 'loading'
                            : getChannelState(channelId);

                        return (
                            <ClickableIcon
                                key={channelId}
                                value={channelId}
                                state={channelState}
                                label={`status.${channelState}`}
                                icon={logo}
                                onClick={() => toggleModuleChannel(channelId)}
                            />
                        );
                    })}
                </ExternalPosChannelConfig>
            </Panel>
        </Container>
    );
};
export default ExternalPosConfigurationPanel;
