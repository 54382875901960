import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isString } from 'lodash';

import { computeText } from '@/locales/utils';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { Button } from '@/components/atoms/Button';
import Modal from '@/components/atoms/Modal';
import { Badge, CreateBadgePayload } from '@/services/innovorder/customer/customer.types';
import { Input } from '@/components/form/Input';
import { Switch } from '@/components/form/Switch';
import { Label } from '@/components/atoms/Label';
import { StyledModalContainer, Row } from './Modal.style';

export type BadgeFormValues = Pick<CreateBadgePayload, 'value' | 'type' | 'isDefault' | 'isBlocked'> & {
    badgeId: number;
};

type BadgeModalProps = {
    title: string;
    isOpen: boolean;
    badge?: Badge;
    onSave: (data: BadgeFormValues) => void;
    onHide: () => void;
    isLoading: boolean;
    disableSwitch?: boolean;
    error?: string;
};

const BadgeModal: React.FunctionComponent<React.PropsWithChildren<BadgeModalProps>> = ({
    title,
    isOpen,
    badge,
    onSave,
    onHide,
    isLoading,
    disableSwitch,
    error,
}) => {
    const intl = useIntl();

    const badgeForm: UseFormReturn<BadgeFormValues> = useForm<BadgeFormValues>({
        defaultValues: badge,
        mode: 'all',
    });

    const handleSubmitForm = () => {
        onSave(badgeForm.getValues());
    };

    const saveButton = (
        <Button
            onClick={handleSubmitForm}
            buttonType="primary"
            disabled={!badgeForm.formState.isValid}
            isLoading={isLoading}
        >
            <FormattedMessage id="button.save" />
        </Button>
    );

    useEffect(() => {
        badgeForm.reset({
            badgeId: badge?.badgeId,
            value: isString(badge?.value) ? badge?.value : undefined,
            type: isString(badge?.type) ? badge?.type : undefined,
            isDefault: badge?.isDefault,
        });
    }, [badge]);

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            onHide={onHide}
            buttons={saveButton}
            error={error}
            shouldNotHideOnClickOutside
        >
            <StyledModalContainer>
                <FormProvider {...badgeForm}>
                    <Input
                        labelId="customer.student.info.badge.number"
                        name={'value'}
                        rules={{
                            required: computeText(intl, 'field.required'),
                        }}
                    />
                    <Input labelId="customer.student.info.account.type" name={'type'} />
                    <Row>
                        <Label labelId="customer.student.badge.form.default" />
                        <Switch disabled={disableSwitch} name={'isDefault'} />
                    </Row>
                </FormProvider>
            </StyledModalContainer>
        </Modal>
    );
};

export default BadgeModal;
