import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { setHasPermissionDenied } from '@/redux/app';

export const PERMISSION_DENIED_ERROR_CODE = 403;

export const checkPermissionDeniedMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (action?.payload && action?.payload?.data?.status === PERMISSION_DENIED_ERROR_CODE) {
        api.dispatch(setHasPermissionDenied(true));
    }

    return next(action);
};
