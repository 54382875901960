import React, { useMemo } from 'react';
import { Container, Header, Wrapper } from './CatalogHistory.style';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import { Table } from '@/components/atoms/Table';
import { useCatalogHistoryTableVM } from './CatalogHistory.viewmodel';
import { Title } from '@/components/atoms/Title';

const CatalogHistory: React.FunctionComponent = () => {
    const { headers, rows, isLoading } = useCatalogHistoryTableVM();
    const tabRoutes = useMemo(() => routes.filter(({ tab }) => tab === 'posDevice'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Wrapper>
                <Container>
                    <Header>
                        <Title text="posDevice.catalogHistory.title" />
                    </Header>
                    <Table
                        isSearchable
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={headers}
                        hasPagination
                        isLoading={isLoading}
                    />
                </Container>
            </Wrapper>
        </RouterNavigationTabs>
    );
};

export default CatalogHistory;
