import { FormatDateOptions, IntlShape } from 'react-intl';

export const computeText = (
    intl: IntlShape,
    sentenceOrId?: string,
    values?: Record<string, string | number | Date>,
): string => {
    const checkWordDotWord = /([a-z,A-Z])\w+\.(?=.[a-z,A-Z])(?!\w*(com|fr|io|org|uk)\b)\w+/g;
    if (!sentenceOrId || !sentenceOrId.match(checkWordDotWord)) {
        return sentenceOrId ?? '';
    }
    return intl.formatMessage({ id: sentenceOrId }, values);
};

export const computeCurrency = ({
    intl,
    amount,
    currency,
    isFromCents = true,
    maximumFractionDigits = 2,
}: {
    intl: IntlShape;
    amount: number;
    currency?: string;
    isFromCents?: boolean;
    maximumFractionDigits?: number;
}): string => {
    const DEFAULT_CURRENCY = 'EUR';
    const RATE_FROM_CENTS = 100;
    const finalAmount = isFromCents ? amount / RATE_FROM_CENTS : amount;
    return intl.formatNumber(finalAmount, {
        style: 'currency',
        currency: currency ?? DEFAULT_CURRENCY,
        maximumFractionDigits,
    });
};

export const computePercentage = ({ amount }: { amount: number }): string => `${amount.toFixed(2)} %`;

export const computeDate = (intl: IntlShape, eventDate: Date, customFormatOptions?: FormatDateOptions): string => {
    const dateFormatOptions: FormatDateOptions = {
        hourCycle: 'h24',
        timeStyle: 'short',
        dateStyle: 'short',
    };

    return intl.formatDate(eventDate, customFormatOptions || dateFormatOptions);
};
