import { computeText } from '@/locales/utils';
import {
    useLazyGetBrandsListV2Query,
    useToggleBrandFavoritesMutation,
} from '@/services/innovorder/brand/brand.endpoints';
import { formatDate } from '@/utils/date';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { TableColumn, TableRow, TableCell } from '@/components/atoms/Table/Table.type';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, fetchDataProps } from '../../components/atoms/Table/Table';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { Cell } from 'react-table';
import { BrandStatus, GetBrandsListResponse, GetBrandListV2Payload } from '../../services/innovorder/brand/brand.type';
import { convertTableFiltersToQueryFilters } from '../Order/OrderList/utils';
import { filterTypes } from '../../components/atoms/Table/Table.utils';
import { FavoriteIcon, NotFavoritedIconWrapper, FavoritedIconWrapper } from './BrandsList.style';
interface BrandsListViewModel {
    rows: TableRow[];
    columns: TableColumn[];
    isLoading: boolean;
    data: GetBrandsListResponse | undefined;
    handleRowClick: (
        cell: Cell<TableRow, TableCell<unknown>> | undefined,
        event?: React.MouseEvent<HTMLTableCellElement>,
    ) => void;
    fetchData: (fetchProps: fetchDataProps) => void;
    showModal: boolean;
    closeModal: () => void;
    openModal: () => void;
}

const useBrandsListVM = (): BrandsListViewModel => {
    const intl = useIntl();
    const [fetchBrandsList, { data, isLoading }] = useLazyGetBrandsListV2Query();
    const [toggleBrandFavorites] = useToggleBrandFavoritesMutation();
    const [fetchParams, setFetchParams] = useState<GetBrandListV2Payload | null>(null);

    const [showModal, setShowModal] = useState<boolean>(false);
    const closeModal = () => {
        setShowModal(false);
    };
    const openModal = () => {
        setShowModal(true);
    };

    const fetchData = useCallback(
        ({ pageIndex, pageSize, globalFilter, columnFilters, sortBy }: fetchDataProps) => {
            const filter = convertTableFiltersToQueryFilters(columnFilters);
            const latestFetchParams = {
                limit: pageSize ?? DEFAULT_PAGE_SIZE,
                offset: pageIndex === undefined || pageSize === undefined ? DEFAULT_PAGE_INDEX : pageIndex * pageSize,
                search: globalFilter,
                filter,
                sortBy,
            };
            setFetchParams(latestFetchParams);
            fetchBrandsList(latestFetchParams);
        },
        [fetchBrandsList],
    );

    const handleRowClick = useCallback(
        (cell: Cell<TableRow, TableCell<unknown>> | undefined, event?: React.MouseEvent<HTMLTableCellElement>) => {
            if (!cell) {
                return;
            }
            redirectToBackofficeV1(`/home/brands/${cell?.row.values.brandId.value.text}/info`, event?.ctrlKey);
        },
        [],
    );
    // @ts-ignore
    const columns: TableColumn[] = useMemo(
        () => [
            {
                Header: computeText(intl, 'name.name'),
                accessor: 'name',
                disableFilters: true,
                disableSortBy: false,
            },
            {
                Header: computeText(intl, 'date.created'),
                accessor: 'created_at',
                disableFilters: true,
                disableSortBy: false,
            },
            {
                Header: computeText(intl, 'brands.restaurantscount'),
                accessor: 'restaurantsCount',
                disableFilters: true,
                disableSortBy: false,
            },
            {
                Header: computeText(intl, 'status.status'),
                accessor: 'status',
                disableFilters: false,
                disableSortBy: true,
                filter: filterTypes.list,
                Filter: [
                    {
                        value: BrandStatus.CHURN,
                        label: BrandStatus.CHURN,
                    },
                    {
                        value: BrandStatus.ACTIVE,
                        label: BrandStatus.ACTIVE,
                    },
                    {
                        value: BrandStatus.INTERNAL,
                        label: BrandStatus.INTERNAL,
                    },
                    {
                        value: BrandStatus.SEASONAL_CHURN,
                        label: BrandStatus.SEASONAL_CHURN,
                    },
                    {
                        value: BrandStatus.TEST,
                        label: BrandStatus.TEST,
                    },
                ],
            },
            {
                Header: 'ID',
                accessor: 'brandId',
                disableFilters: true,
                disableSortBy: false,
            },
            {
                Header: computeText(intl, 'brands.favorite'),
                accessor: 'isFavorite',
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [intl],
    );

    const rows: TableRow[] = useMemo(() => {
        if (!data) return [];
        return data.brands.map((brand) => ({
            name: {
                type: 'string',
                value: {
                    text: brand.name,
                },
            },
            created_at: {
                type: 'string',
                value: {
                    text: formatDate(brand.created_at, 'DD/MM/YYYY HH:mm'),
                },
            },
            restaurantsCount: {
                type: 'number',
                value: brand.restaurantsCount,
            },
            status: {
                type: 'string',
                value: {
                    text: brand.status || null,
                },
            },
            brandId: {
                type: 'string',
                value: {
                    text: brand.brandId,
                },
            },

            isFavorite: {
                type: 'string',
                value: {
                    text: brand.isFavorite ? (
                        <FavoritedIconWrapper
                            onClick={(ev) => {
                                ev.stopPropagation();
                                toggleBrandFavorites({
                                    brandId: brand.brandId,
                                    status: !brand.isFavorite,
                                    latestFetchParams: fetchParams,
                                });
                            }}
                        >
                            <FavoriteIcon className={'ri-heart-fill '} />
                        </FavoritedIconWrapper>
                    ) : (
                        <NotFavoritedIconWrapper
                            onClick={(ev) => {
                                ev.stopPropagation();
                                toggleBrandFavorites({
                                    brandId: brand.brandId,
                                    status: !brand.isFavorite,
                                    latestFetchParams: fetchParams,
                                });
                            }}
                        >
                            <FavoriteIcon className={'ri-heart-line '} />
                        </NotFavoritedIconWrapper>
                    ),
                },
            },
        }));
    }, [data]);
    return { rows, columns, handleRowClick, fetchData, data, isLoading, showModal, closeModal, openModal };
};
export default useBrandsListVM;
