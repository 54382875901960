import { routes } from '@/core/router/routes';
import { getUserToken } from '@/redux/user';
import {
    useLazyGetBrandEntranceFeesQuery,
    useLazyGetBrandGrantsQuery,
} from '@/services/innovorder/brand/brand.endpoints';
import { useLazyGetMinimalPricingRulesQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { downloadFile } from '@/utils/file/utils';
import { XlsxFile } from '@/utils/xslx';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSheetOptions } from './utils';

interface ParamTypes {
    brandId: string;
}

export const useGrantsAndEntranceFeesVM = () => {
    const intl = useIntl();
    const { brandId } = useParams<ParamTypes>();

    const token = useSelector(getUserToken);

    const [getGrants] = useLazyGetBrandGrantsQuery();
    const [getEntranceFees] = useLazyGetBrandEntranceFeesQuery();
    const [getPricingRules] = useLazyGetMinimalPricingRulesQuery();

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);
    const sheetOptions = getSheetOptions({ intl });

    const [isOpen, setIsOpen] = useState(false);
    const handleHide = () => setIsOpen(false);
    const handleUnHide = () => setIsOpen(true);

    const onDownload = async () => {
        const grants = await getGrants({ brandId: Number(brandId), token }).unwrap();
        const entranceFees = await getEntranceFees({ brandId: Number(brandId), token }).unwrap();
        const pricingRules = await getPricingRules({ brandId: Number(brandId), token }).unwrap();

        const sheets = {
            [sheetOptions.entranceFee.name]: [
                sheetOptions.entranceFee.headers,
                ...entranceFees.map(sheetOptions.entranceFee.mapper),
            ],
            [sheetOptions.grant.name]: [sheetOptions.grant.headers, ...grants.map(sheetOptions.grant.mapper)],
            [sheetOptions.pricingRule.name]: [
                sheetOptions.pricingRule.headers,
                ...pricingRules.map(sheetOptions.pricingRule.mapper),
            ],
        };

        const xslx = new XlsxFile();
        Object.keys(sheets).forEach((key) => {
            xslx.addWorksheet(key, sheets[key]);
        });

        const data = xslx.toArrayBuffer();
        const filename = `export_${new Date().toISOString()}.xlsx`;
        downloadFile(filename, data, 'text/csv');
    };

    return {
        brandId,
        tabRoutes,
        isOpen,
        handleHide,
        handleUnHide,
        onDownload,
    };
};
