import * as React from 'react';

import { useIsMounted } from '@/hooks/useIsMounted';
import Modal from '@/components/atoms/Modal';
import { subscribe } from './prompt';
import { StateModal } from './PromptModals.interface';

export const PromptModals: React.FunctionComponent<React.PropsWithChildren> = () => {
    const isMounted = useIsMounted();

    const [modals, setModals] = React.useState<StateModal[]>([]);

    const handleResolve = React.useCallback(
        (modal: StateModal, response: unknown) => {
            if (isMounted.current) {
                setModals((prev) => prev.filter((el) => el.id !== modal.id));
            }

            modal.resolve(response);
        },
        [isMounted],
    );

    const handleHide = (modal: StateModal) => handleResolve(modal, undefined);

    React.useEffect(
        () =>
            subscribe(
                (props) =>
                    new Promise((resolve) =>
                        setModals((prev) => [
                            ...prev,
                            {
                                props,
                                resolve,
                                id: Math.random(),
                                isOpen: true,
                            },
                        ]),
                    ),
            ),
        [],
    );

    return (
        <>
            {modals.map((modal) => {
                const initialProps = modal.props({
                    onResolve: (response) => handleResolve(modal, response),
                    onHide: () => handleHide(modal),
                });

                const props = {
                    onHide: () => handleHide(modal),
                    ...initialProps,
                    key: modal.id,
                    isOpen: modal.isOpen,
                };

                return <Modal {...props} key={props.key} />;
            })}
        </>
    );
};
