import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { stringifyError } from '@/utils/errors';
import { Routes } from '@/core/router/routes.types';
import { PricingRuleForm } from '@/pages/Brand/PricingRule/PricingRuleForm';
import { usePostPricingRuleMutation } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { PricingRuleCreateAttributes } from '@/services/innovorder/pricingRule/pricingRule.type';
import { getUserToken } from '@/redux/user';
import {
    getComponentsWithoutTurnover,
    getEntranceFees,
    getGrants,
    getPricingRules,
    setComponentsWithoutTurnover,
    setEntranceFees,
    setGrants,
    setPricingRules,
} from '@/redux/pricingRuleManage';
import { CanBeBlockedChannels } from '@/pages/Brand/PricingRule/PricingRuleForm/utils';

interface ParamTypes {
    brandId: string;
}

const CreatePricingRule: React.FunctionComponent<React.PropsWithChildren> = () => {
    const dispatch = useDispatch();
    const token = useSelector(getUserToken);
    const { brandId } = useParams<ParamTypes>();
    const history = useHistory();

    useEffect(() => {
        dispatch(setGrants([]));
        dispatch(setEntranceFees([]));
        dispatch(setComponentsWithoutTurnover([]));
        dispatch(setPricingRules([]));
    }, [dispatch]);

    const [postPricingRule, { isSuccess, error, data, isLoading }] = usePostPricingRuleMutation();

    const finalGrants = useSelector(getGrants);
    const finalEntranceFees = useSelector(getEntranceFees);
    const finalComponentsWithoutTurnover = useSelector(getComponentsWithoutTurnover);
    const finalPricingRules = useSelector(getPricingRules);

    const create = (arg: PricingRuleCreateAttributes) => {
        postPricingRule({
            ...arg,
            token,
            brandId: parseInt(brandId, 10),
            grants: finalGrants,
            entranceFees: [...finalEntranceFees, ...finalComponentsWithoutTurnover],
            associatedPricingRules: finalPricingRules,
        });
    };

    if (isSuccess && data?.pricingRuleId) {
        history.push(generatePath(Routes.PricingRule, { brandId, pricingRuleId: data.pricingRuleId }));
    }

    const basePricingRule: PricingRuleCreateAttributes = React.useMemo(
        () => ({
            brandId: parseInt(brandId, 10),
            code: '',
            applyScope: [],
            blockedChannels: [
                {
                    channelId: CanBeBlockedChannels.VENDING_MACHINE,
                },
            ],
            version: null,
            isUrssafEnabled: false,
            grants: [],
            entranceFees: [],
            associatedPricingRules: [],
        }),
        [brandId],
    );

    return (
        <PricingRuleForm
            pricingRule={basePricingRule}
            error={stringifyError(error)}
            onSubmit={create}
            loading={isLoading}
            createMode={true}
        />
    );
};

export default CreatePricingRule;
