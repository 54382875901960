import * as React from 'react';

import { computeText } from '@/locales/utils';
import { Input } from '@/components/form/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import { SvyButton } from '@/components/savory/SvyButton';
import { confirm } from '@/core/asyncActions/confirm';
import { useDeleteUnassignedImagesMutation } from '@/services/innovorder/adminTools/adminTools.endpoints';
import { FormControl, FormControls, SuccessMessage } from '../AdminTools.style';
import { safeMutate } from '@/utils/errors';
import { Form } from '@/core/form/Form';
import { useParams } from 'react-router';

interface ParamTypes {
    brandId: string;
}
const DeleteUnassignedImages: React.FunctionComponent = () => {
    const intl = useIntl();

    const [deleteUnassignedImages, { isSuccess }] = useDeleteUnassignedImagesMutation();

    const { brandId } = useParams<ParamTypes>();

    const handleSubmitForm = async () => {
        const hasConfirmed = await confirm(computeText(intl, 'adminTools.confirmModal.description'));
        if (hasConfirmed) {
            await safeMutate(
                deleteUnassignedImages({
                    brandId: parseInt(brandId),
                }),
            );
        }
    };

    return (
        <Form>
            {({ handleSubmit }) => {
                return (
                    <>
                        <Text type="large" text="brand.adminTools.deleteUnassignedImages.title" />
                        <br />
                        <Text text="brand.adminTools.deleteUnassignedImages.description1" />
                        <Text text="brand.adminTools.deleteUnassignedImages.description2" />
                        <FormControls>
                            <SvyButton onClick={handleSubmit(handleSubmitForm)}>
                                <FormattedMessage id="button.validate" />
                            </SvyButton>
                            {isSuccess && (
                                <SuccessMessage text="brand.adminTools.deleteUnassignedImages.form.success" />
                            )}
                        </FormControls>
                    </>
                );
            }}
        </Form>
    );
};

export default DeleteUnassignedImages;
