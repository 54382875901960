import { ChannelId } from '../channel/channel.type';

export const CONSUMPTION_MODE_FLAGS = {
    FLAG_MODE_DELIVERY: 1,
    FLAG_MODE_TAKE_AWAY: 2,
    FLAG_MODE_SIT_IN: 4,
    FLAG_MODE_DRIVE: 8,
};

export enum ConsumptionModeTypes {
    MODE_SIT_IN = 'MODE_SIT_IN',
    MODE_TAKE_AWAY = 'MODE_TAKE_AWAY',
    MODE_DELIVERY = 'MODE_DELIVERY',
    MODE_DRIVE = 'MODE_DRIVE',
}

export type GetConsumptionModePayload = {
    channelId: ChannelId;
    restaurantId: string | number;
};

export type ConsumptionMode = {
    brandId: number;
    channelId: ChannelId;
    consumptionModeId: number;
    restaurantId: number;
    type: ConsumptionModeTypes;
    created_at: Date;
    updated_at: Date;
};
