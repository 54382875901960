/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Theme } from '@emotion/react';
import { IntlShape } from 'react-intl';
import { Filters } from 'react-table';
import { computeCurrency, computeText } from '@/locales/utils';
import { TableColumn, TableRow } from '@/components/atoms/Table/Table.type';
import { assertUnreachable } from '@/utils/typescript';
import { BrandType } from '@/services/innovorder/brand/brand.type';
import {
    GetTransactionsFilters,
    TransactionChannel,
    PaymentMethodTypes,
    TransactionResponse,
    TransactionStatus,
    TransactionTypes,
} from '@/services/innovorder/io-pay/transactions/transaction.type';
import { filterTypes } from '@/components/atoms/Table/Table.utils';
import { SvyTooltip } from '@/components/savory/SvyTooltip';
import { SvyIcon } from '@/components/savory/SvyIcon';

export const convertTableFiltersToQueryFilters = (columnFilters?: Filters<TableRow>): GetTransactionsFilters => {
    if (!columnFilters?.length) return {};
    const filters = columnFilters.reduce((acc: GetTransactionsFilters, column) => {
        const value = Array.isArray(column.value) ? column.value.join() : column.value;
        switch (column.id) {
            case 'paymentMethodType':
                acc[column.id] = value;
                return acc;
            default:
                throw new Error(`Filter column not managed: ${column.id}`);
        }
    }, {});
    return filters;
};

export const getTransactionTypeColor = (type: TransactionTypes, theme: Theme): string => {
    if (type === TransactionTypes.CAPTURE) return theme.color.emerald;
    if (type === TransactionTypes.REFUND) return theme.color.bloodyMary;
    return assertUnreachable(type);
};

export const getTransactionStatusColor = (status: TransactionStatus, theme: Theme): string => {
    if (status === TransactionStatus.DONE) return theme.color.emerald;
    if (status === TransactionStatus.ERROR) return theme.color.bloodyMary;
    if (status === TransactionStatus.FAILED) return theme.color.bloodyMary;
    if (status === TransactionStatus.PENDING) return theme.color.mint;
    if (status === TransactionStatus.PENDING_3DS) return theme.color.mint;
    return assertUnreachable(status);
};

export const getTransactionsTableColumnsVM = (
    intl: IntlShape,
    brandType?: BrandType,
    options?: { includeTransfer?: boolean },
): readonly TableColumn[] => [
    {
        Header: computeText(intl, 'transaction.field.id'),
        accessor: 'id',
        minWidth: 100,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'transaction.field.channel'),
        accessor: 'channel',
        disableSortBy: true,
        minWidth: 120,
        disableFilters: true,
    },
    {
        Header: computeText(intl, 'transaction.field.type'),
        accessor: 'type',
        minWidth: 100,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'order.field.status'),
        accessor: 'status',
        minWidth: 100,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: computeText(
            intl,
            brandType === 'MASS_CATERING' ? 'order.field.customerNameMassCatering' : 'order.field.customerName',
        ),
        accessor: 'customerName',
        minWidth: 180,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'transaction.field.customerEmail'),
        accessor: 'customerEmail',
        minWidth: 160,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'transaction.field.paymentMethod'),
        accessor: 'paymentMethodType',
        id: 'paymentMethodType',
        disableSortBy: true,
        minWidth: 120,
        disableFilters: false,
        filter: filterTypes.list,
        // @ts-ignore
        Filter: [
            {
                value: PaymentMethodTypes.BANK_CARD,
                label: computeText(intl, 'transaction.field.paymentMethod.BANK_CARD'),
            },
            {
                value: PaymentMethodTypes.MEAL_VOUCHER,
                label: computeText(intl, 'transaction.field.paymentMethod.MEAL_VOUCHER'),
            },
        ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
        Header: () => (
            <>
                {computeText(intl, 'transaction.field.paymentAmount')}
                <SvyTooltip tooltipContent={computeText(intl, 'transaction.field.paymentAmount.tooltip')}>
                    <SvyIcon icon="information-line" />
                </SvyTooltip>
            </>
        ),
        label: computeText(intl, 'transaction.field.paymentAmount'),
        accessor: 'paymentAmount',
        disableSortBy: true,
        minWidth: 120,
        disableFilters: true,
    },
    {
        Header: () => (
            <>
                {computeText(intl, 'transaction.field.reversalAmount')}
                <SvyTooltip tooltipContent={computeText(intl, 'transaction.field.reversalAmount.tooltip')}>
                    <SvyIcon icon="information-line" />
                </SvyTooltip>
            </>
        ),
        label: computeText(intl, 'transaction.field.reversalAmount'),
        accessor: 'reversalAmount',
        disableSortBy: true,
        minWidth: 120,
        disableFilters: true,
    },
    {
        Header: computeText(intl, 'transaction.field.fee'),
        accessor: 'fee',
        disableSortBy: true,
        minWidth: 120,
        disableFilters: true,
    },
    ...(options?.includeTransfer
        ? [
              {
                  Header: computeText(intl, 'transaction.field.transfer'),
                  accessor: 'transfer',
                  disableSortBy: true,
                  minWidth: 120,
                  maxWidth: 200,
                  disableFilters: true,
              },
          ]
        : []),
    {
        Header: computeText(intl, 'transaction.field.date'),
        accessor: 'date',
        disableSortBy: true,
        minWidth: 120,
        disableFilters: true,
    },
];

export const getTransactionsTableRowsVM = (
    transactions: TransactionResponse[] | undefined,
    intl: IntlShape,
    theme: Theme,
    options?: { includeTransfer?: boolean; onTransferClick: (transferId: number) => void },
): TableRow[] => {
    const dateFormatter = Intl.DateTimeFormat(intl.locale, { dateStyle: 'short', timeStyle: 'short' });
    return !transactions
        ? []
        : transactions.map((transaction) => {
              const { customer, payment, transfer, reversal } = transaction;
              return {
                  id: {
                      type: 'string',
                      value: {
                          isTruncated: true,
                          text: transaction.reference,
                      },
                  },
                  channel: {
                      type: 'string',
                      value: {
                          text: `transaction.channel.${TransactionChannel[transaction.channel]}`,
                      },
                  },
                  type: {
                      type: 'string',
                      value: {
                          text: `transaction.type.${TransactionTypes[transaction.type]}`,
                          backgroundColor: getTransactionTypeColor(transaction.type, theme),
                      },
                  },
                  paymentMethodType: {
                      type: 'string',
                      value: {
                          text: `transaction.field.paymentMethod.${PaymentMethodTypes[transaction.paymentMethodType]}`,
                      },
                  },
                  status: {
                      type: 'badge',
                      value: {
                          text: `transaction.status.${TransactionStatus[transaction.status]}`,
                          backgroundColor: getTransactionStatusColor(transaction.status, theme),
                      },
                  },
                  customerName: {
                      type: 'string',
                      value: {
                          text: `${customer?.name ?? ''}`,
                      },
                  },
                  customerEmail: {
                      type: 'string',
                      value: {
                          text: `${customer?.email ?? ''}`,
                      },
                  },
                  paymentAmount: {
                      type: 'string',
                      value: {
                          text: computeCurrency({ intl, amount: payment.amount, currency: payment.currency }),
                      },
                  },
                  reversalAmount: {
                      type: 'string',
                      value: {
                          text: computeCurrency({ intl, amount: reversal.amount, currency: payment.currency }),
                      },
                  },
                  fee: {
                      type: 'string',
                      value: {
                          text: computeCurrency({ intl, amount: payment.fee, currency: payment.currency }),
                      },
                  },
                  ...(options?.includeTransfer
                      ? {
                            transfer: {
                                ...(transfer?.id
                                    ? {
                                          type: 'action',
                                          value: {
                                              children: transfer.reference,
                                              buttonType: 'text',
                                              isTruncated: true,
                                              onClick: () => {
                                                  options.onTransferClick(transfer.id);
                                              },
                                          },
                                      }
                                    : {
                                          type: 'empty',
                                          value: '',
                                      }),
                            },
                        }
                      : {}),
                  date: {
                      type: 'string',
                      value: {
                          text: dateFormatter.format(new Date(transaction.createdAt)),
                      },
                  },
                  linkTo: {
                      type: 'linkTo',
                      value: { transactionId: String(transaction.id) },
                  },
              };
          });
};
