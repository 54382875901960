import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import { CatalogHistory, DownloadCatalogParams, GetCatalogHistoryParams } from './catalog.type';

const catalogApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getCatalogHistory: build.query<CatalogHistory[], ApiRequest<GetCatalogHistoryParams>>({
            query: ({ posDeviceId, token }) => ({
                url: `/catalogs/list/pos?posDeviceId=${posDeviceId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CatalogHistory[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        downloadCatalog: build.query<CatalogHistory, ApiRequest<DownloadCatalogParams>>({
            query: ({ posDeviceId, hash, token }) => ({
                url: `/catalogs/export/pos?posDeviceId=${posDeviceId}&hash=${hash}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CatalogHistory>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetCatalogHistoryQuery, useLazyDownloadCatalogQuery } = catalogApi;
