import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accept } from 'react-dropzone';
import { computeText } from '@/locales/utils';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { ENV } from '@/utils/env';
import { FileInput } from '@/components/form/FileInput';
import { SelectPosDevice } from '@/components/form/SelectPosDevice';
import {
    BackgroundContainer,
    ButtonsContainer,
    Container,
    DescriptionItem,
    DescriptionList,
    DropzoneContainer,
    TextContainer,
} from './ImportMenuStep.style';
import { ImportMenuStepProps } from './ImportMenuStep.interface';

const ALLOWED_FILE_TYPES: Accept = {
    'application/json': ['.json'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
};

enum ImportModes {
    EXCEL = 'excel',
    JSON = 'json',
    POS = 'pos',
}

const IMPORT_MODE_IMAGES: Record<ImportModes, string> = {
    [ImportModes.EXCEL]: 'dashboard/images/excel_modal_background.png',
    [ImportModes.JSON]: 'dashboard/images/json_modal_background.png',
    [ImportModes.POS]: 'dashboard/images/pos.jpg',
};

export const ImportMenuStep: React.FunctionComponent<ImportMenuStepProps> = ({ brandId, restaurantId }) => {
    const intl = useIntl();
    const [importMode, setImportMode] = useState<ImportModes>(ImportModes.EXCEL);

    return (
        <Container>
            <BackgroundContainer ressourceUrl={IMPORT_MODE_IMAGES[importMode]} alt={`${importMode}-background-img`} />
            <ButtonsContainer>
                {Object.values(ImportModes).map((fileType) => (
                    <Button
                        key={`import-menu-file-btn-${fileType}`}
                        onClick={() => setImportMode(fileType)}
                        buttonType={importMode === fileType ? 'primary' : 'secondary'}
                    >
                        <FormattedMessage id={`menu.modal.create.import.${fileType}.tab`} />
                    </Button>
                ))}
            </ButtonsContainer>
            <TextContainer>
                <Text text={`menu.modal.create.import.${importMode}.title`} type="large" weight="light" />
                <DescriptionList>
                    <DescriptionItem>
                        <FormattedMessage
                            id={`menu.modal.create.import.${importMode}.description.firstPart`}
                            values={{
                                fileTemplate: (
                                    <a href={`${ENV.STATIC_URL}/dashboard/import/menu_template_V4.xlsx`}>
                                        <FormattedMessage id="file.fileTemplate" />
                                    </a>
                                ),
                            }}
                        />
                    </DescriptionItem>
                    {importMode !== ImportModes.POS && (
                        <DescriptionItem>
                            <FormattedMessage id={`menu.modal.create.import.${importMode}.description.secondPart`} />
                        </DescriptionItem>
                    )}
                    {importMode === ImportModes.EXCEL && (
                        <DescriptionItem>
                            <FormattedMessage id={`menu.modal.create.import.${importMode}.description.thirdPart`} />
                        </DescriptionItem>
                    )}
                </DescriptionList>
            </TextContainer>
            {importMode !== ImportModes.POS ? (
                <DropzoneContainer>
                    <React.Suspense>
                        <FileInput
                            mode="compact"
                            accept={ALLOWED_FILE_TYPES}
                            name="file"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                validate: (value) => value.length > 0,
                            }}
                            transform={{
                                output: (file) => file[0],
                                input: (file: File) => (file ? [file] : []),
                            }}
                        />
                    </React.Suspense>
                </DropzoneContainer>
            ) : (
                <SelectPosDevice
                    brandId={brandId}
                    restaurantId={restaurantId}
                    name="sourcePosId"
                    transform={{
                        output: (devices) => devices[devices.length - 1]?.posDeviceId,
                        input: (posDeviceId) => (posDeviceId ? [{ posDeviceId, deviceName: '' }] : []),
                    }}
                />
            )}
        </Container>
    );
};
