import { useSelector } from 'react-redux';
import * as React from 'react';
import { RouteDeclaration } from '@/core/router/routes.types';
import { getLaunchDarklyState } from '@/redux/launchDarkly';
import { getUser } from '@/redux/user';
import { getBrand } from '@/redux/brand';
import { AccessFlags } from '@/services/innovorder/auth/auth.type';

/**
 * Filter routes based on user access flags, roles, feature flags and brand options
 */
export const useFilterRoutes = <Route extends RouteDeclaration<any>>(routes: Route[]): Route[] => {
    const featureFlags = useSelector(getLaunchDarklyState);
    const user = useSelector(getUser);
    const brand = useSelector(getBrand);

    return React.useMemo(() => {
        const userAccessFlags = user?.accessFlags ?? ({} as Record<AccessFlags, boolean>);
        let filteredRoutes = routes.filter(({ roles, accessFlags = [] }: RouteDeclaration) => {
            const hasRightAccessFlags = user ? accessFlags.every((accessFlag) => userAccessFlags[accessFlag]) : true;
            const userHasPermission = user && roles ? roles.includes(user.role) : true;
            return hasRightAccessFlags && userHasPermission;
        });

        if (featureFlags.isInitialized) {
            Object.entries(featureFlags).forEach(([featureFlag, status]) => {
                filteredRoutes = filteredRoutes.filter(({ enableFeatureFlag, disableFeatureFlag }) => {
                    if (featureFlag === enableFeatureFlag) {
                        return status;
                    }
                    if (featureFlag === disableFeatureFlag) {
                        return !status;
                    }
                    return true;
                });
            });
        }

        if (brand) {
            filteredRoutes = filteredRoutes.filter(({ brandOption }) => {
                if (!brandOption) return true;

                return brand.options?.[brandOption]?.isActivated;
            });
        }

        return filteredRoutes;
    }, [routes, featureFlags, user, brand]);
};
