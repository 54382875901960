import React from 'react';

import { Routes } from '@/core/router/routes.types';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import LanguagesList from './LanguagesList';
import { OverlayProps } from './overlay.type';
import RestaurantsList from './RestaurantsList';
import { LinkPayload } from '../DynamicMenu/dynamicMenu.type';

export type OverlayComponent = 'restaurants' | 'languages';

export const OverlayComponentCatalog: Record<
    OverlayComponent,
    React.FunctionComponent<React.PropsWithChildren<OverlayProps>>
> = {
    restaurants: RestaurantsList,
    languages: LanguagesList,
};

export type Overlay = {
    subMenu: LinkPayload[] | OverlayComponent;
    uri?: string;
    route?: Routes;
    parentName?: string;
    parentIcon?: SVGIcons;
};
