import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from '@/components/atoms/Table';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';

import { SvyButton } from '@/components/savory/SvyButton';
import { HeaderLeft, HeaderRight, Subtitle } from '@/components/atoms/Section/Section.style';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import { Container, Header, HeaderContainer } from './CrossSellingList.style';
import { useCrossSellingTableVM } from './CrossSellingList.viewmodel';
import { CrossSellingModal } from './CrossSellingModal/CrossSellingModal';

const CrossSellingList: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { restaurantId, rows, headers, action, brandId, clearAction, handleCreate, onRowClickPath } =
        useCrossSellingTableVM();
    const { type, crossSellingId, name } = action;

    const tabRoutes = React.useMemo(
        () => routes.filter(({ tab }) => tab === (restaurantId ? 'restaurants' : 'brands')),
        [restaurantId],
    );

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <CrossSellingModal
                action={type}
                brandId={brandId}
                restaurantId={restaurantId}
                crossSellingId={crossSellingId}
                crossSellingName={name}
                onHide={clearAction}
            />
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="crossSelling.page.title" />
                            <Subtitle>
                                <Text text="crossSelling.page.description" />
                            </Subtitle>
                        </HeaderLeft>
                        <HeaderRight>
                            <SvyButton size="s" onClick={handleCreate}>
                                <FormattedMessage id="crossSelling.action.newCrossSelling" />
                            </SvyButton>
                        </HeaderRight>
                    </Header>
                    <Table
                        isSearchable
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={headers}
                        hasPagination
                        hasUrlNavigation
                        isSortable
                        isLoading={false}
                        onRowClickPath={onRowClickPath}
                    />
                </HeaderContainer>
            </Container>
        </RouterNavigationTabs>
    );
};

export default CrossSellingList;
