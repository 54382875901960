import React from 'react';
import { useIntl } from 'react-intl';
import { ConsumptionMode } from '@/services/innovorder/consumptionMode/consumptionMode.type';
import { Module } from '@/services/innovorder/modules/modules.type';
import { PaymentMethod, PaymentMethodCode } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { Brand } from '@/services/innovorder/brand/brand.type';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { computeText } from '@/locales/utils';
import { IOPayConfig } from '@/services/innovorder/io-pay/configurations/configurations.type';
import { ENV } from '@/utils/env';
import { EdenredConfig } from '@/services/innovorder/edenred/configurations/configurations.type';
import { IntegrationConfig, Tab } from '../../components/Tab/Tab';
import { getPaymentMethod } from '../../components/Tab/Tab.utils';

export type WebProps = {
    channelId: ChannelId;
    consumptionModes: ConsumptionMode[];
    paymentMethods: PaymentMethod[];
    isLoadingConsumptionModes: boolean;
    isLoadingSave: boolean;
    ingenicoConfig?: Module;
    neptingConfig?: Module;
    stripeConfig?: Module;
    ioPayConfig?: IOPayConfig;
    edenredConfig?: EdenredConfig;
    paygreenConfig?: Module;
    brand?: Brand;
    onChange: (param: PaymentMethod[]) => void;
    onCancel: () => void;
    onSave: () => void;
};

const PaymentMethodWebTab: React.FunctionComponent<WebProps> = ({
    channelId,
    consumptionModes,
    isLoadingConsumptionModes,
    paymentMethods,
    isLoadingSave,
    stripeConfig,
    ioPayConfig,
    edenredConfig,
    paygreenConfig,
    brand,
    onChange,
    onCancel,
    onSave,
}) => {
    const intl = useIntl();

    const isStripeConfigured = !!stripeConfig?.moduleStripeId;
    const isIopayConfigured = !!ioPayConfig?.isEnabled;
    const isPaygreenConfigured = !!paygreenConfig?.modulePaygreenId;
    const isEdenredConfigured = !!edenredConfig?.isEnabled;
    const isEwalletConfigured = !!brand?.options?.EDUCATION_MODE?.isActivated;
    const isExternalIdActivated = !!brand?.options?.EXTERNAL_IDS_MAPPING?.isActivated;

    const integrations: IntegrationConfig[] = [
        {
            type: 'stripe',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/payment/stripe-payment.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.Stripe, channelId),
            paymentMethodCode: PaymentMethodCode.Stripe,
            showIntegration: true,
            showConfigButton: !isStripeConfigured && !isIopayConfigured,
            showConsumptionModes: isStripeConfigured,
            message: isIopayConfigured ? computeText(intl, 'configure.stripe.iopayConfigured') : undefined,
        },
        {
            type: 'iopay',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/payment/adyen.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.IOPay, channelId),
            paymentMethodCode: PaymentMethodCode.IOPay,
            showIntegration: true,
            showConfigButton: !isIopayConfigured && !isStripeConfigured,
            showConsumptionModes: isIopayConfigured,
            message: isStripeConfigured ? computeText(intl, 'configure.iopay.stripeConfigured') : undefined,
        },
        {
            type: 'paygreen',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/e-luncheon-vouchers.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.Paygreen, channelId),
            paymentMethodCode: PaymentMethodCode.Paygreen,
            showIntegration: true,
            showConfigButton: !isPaygreenConfigured,
            showConsumptionModes: isPaygreenConfigured,
        },
        {
            type: 'edenred',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/Edenred-Color-RGB.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.Edenred, channelId),
            paymentMethodCode: PaymentMethodCode.Edenred,
            showIntegration: true,
            showConfigButton: !isEdenredConfigured,
            showConsumptionModes: isEdenredConfigured,
        },
        {
            type: 'ewallet',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/payment/e-wallet-illustration.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.EWallet, channelId),
            paymentMethodCode: PaymentMethodCode.EWallet,
            showIntegration: isEwalletConfigured,
            showConfigButton: false,
            showConsumptionModes: isEwalletConfigured,
        },
    ];

    return (
        <Tab
            channelId={channelId}
            isLoadingConsumptionModes={isLoadingConsumptionModes}
            integrations={integrations}
            paymentMethods={paymentMethods}
            consumptionModes={consumptionModes}
            isLoadingSave={isLoadingSave}
            isExternalIdActivated={isExternalIdActivated}
            onChange={onChange}
            onSave={onSave}
            onCancel={onCancel}
        />
    );
};

export { PaymentMethodWebTab };
