import { matchPath } from 'react-router';
import { NavigationParametersEnum, RouteDeclarationWithParams, Routes } from '@/core/router/routes.types';
import {
    SpotlightCta,
    SpotlightCookie,
    SpotlightPage,
    SpotlightRouteDeclarationList,
    SpotlightContext,
} from '@/services/innovorder/spotlight/spotlight.type';
import { UserBrand, UserRestaurant } from '@/services/innovorder/user/user.type';

export const SPOTLIGHT_HISTORY_MAX_LENGTH = 4;

export const getBrandById = (brands: UserBrand[], brandId: number | undefined): UserBrand | undefined => {
    if (!brandId) {
        return undefined;
    }

    return brands.find((brand) => brandId === brand.brandId);
};

export const getRestaurantById = (
    restaurants: UserRestaurant[],
    restaurantId: number | undefined,
): UserRestaurant | undefined => {
    if (!restaurantId) {
        return undefined;
    }

    return restaurants.find((restaurant) => restaurantId === restaurant.restaurantId);
};

export const getSpotlightParentName = (matchedRoutesParent: Routes | undefined): string | undefined =>
    matchedRoutesParent
        ? SpotlightRouteDeclarationList.find(
              ({ path, uri }) => path === matchedRoutesParent || uri === matchedRoutesParent,
          )?.name
        : undefined;

export const getSpotlightPageCta = (matchedRoutesContext: NavigationParametersEnum[] | undefined): SpotlightCta => {
    if (matchedRoutesContext?.includes(NavigationParametersEnum.restaurantId)) {
        return 'restaurant';
    }

    return 'brand';
};

export const getSpotlightPageContext = (
    matchedRouteParams: Record<string, string>,
    currentBrandName: string | undefined,
    userRestaurants: UserRestaurant[] | undefined,
): SpotlightContext => {
    const restaurantId = matchedRouteParams[NavigationParametersEnum.restaurantId];
    const pricingRuleId = matchedRouteParams[NavigationParametersEnum.pricingRuleId];
    const entranceFeeId = matchedRouteParams[NavigationParametersEnum.entranceFeeId];
    const grantId = matchedRouteParams[NavigationParametersEnum.grantId];

    if (pricingRuleId) {
        return {
            pricingRule: pricingRuleId,
        };
    }

    if (entranceFeeId) {
        return {
            entranceFee: entranceFeeId,
        };
    }

    if (grantId) {
        return {
            grant: grantId,
        };
    }

    const currentRestaurant = getRestaurantById(userRestaurants || [], parseInt(restaurantId, 10));

    if (currentRestaurant) {
        return {
            restaurant: currentRestaurant?.name,
        };
    }

    return {
        brand: currentBrandName,
    };
};

export const computeSpotlightPageList = (
    userBrands: UserBrand[] | undefined,
    userRestaurants: UserRestaurant[] | undefined,
    spotlightHistory: SpotlightCookie,
): SpotlightPage[] => {
    const pageList = Object.keys(spotlightHistory).reduce<SpotlightPage[]>((result, pathname) => {
        const matchedRoute = SpotlightRouteDeclarationList.reduce<RouteDeclarationWithParams | undefined>(
            (finalRoute, routeToMatch) => {
                const match = matchPath(pathname, {
                    path: routeToMatch.path,
                    exact: true,
                    strict: false,
                });

                if (match) {
                    return {
                        ...routeToMatch,
                        params: match.params,
                    };
                }

                return finalRoute;
            },
            undefined,
        );

        if (matchedRoute) {
            const brandId = matchedRoute.params[NavigationParametersEnum.brandId];
            const currentBrand = getBrandById(userBrands || [], parseInt(brandId, 10));

            return [
                ...result,
                {
                    url: pathname,
                    name: matchedRoute.name,
                    parentName: getSpotlightParentName(matchedRoute.parent),
                    cta: getSpotlightPageCta(matchedRoute.context),
                    context: getSpotlightPageContext(matchedRoute.params, currentBrand?.name, userRestaurants),
                    parentContext: currentBrand?.name,
                },
            ];
        }

        return result;
    }, []);

    const sortedPageList = pageList.slice().sort((a, b) => spotlightHistory[b.url] - spotlightHistory[a.url]);

    return sortedPageList.slice(0, SPOTLIGHT_HISTORY_MAX_LENGTH);
};
