import { computeText } from '@/locales/utils';
import { IntlShape } from 'react-intl';
import { UploadPricingRuleResponse } from '@/services/innovorder/pricingRule/pricingRule.type';

export const formatMessage = (intl: IntlShape, data: UploadPricingRuleResponse['data']): string => {
    return Object.entries(data)
        .map(([key, { errors, created, updated }]) => {
            if (errors.length) {
                const groupMessage = computeText(intl, `pricingRule.modal.import.report.${key}`);
                const groupMessageErrors = errors
                    .map((error) => computeText(intl, 'pricingRule.modal.import.report.errorMessage', error))
                    .join('\n');

                return `${groupMessage}: \n${groupMessageErrors} \n`;
            }

            const groupMessage = computeText(intl, `pricingRule.modal.import.report.${key}`);
            const successMessage = computeText(intl, `pricingRule.modal.import.report.successMessage`, {
                created,
                updated,
            });

            return `${groupMessage}: \n${successMessage} \n`;
        })
        .join('\n');
};
