import React from 'react';

import { Text } from '@/components/atoms/Text';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Container } from './TextIconCell.style';

export type TextIconCellProps = {
    icon: SVGIcons;
    text: string;
};

export const TextIconCell: React.FunctionComponent<React.PropsWithChildren<TextIconCellProps>> = ({
    icon,
    text,
    ...otherProps
}) => {
    return (
        <Container {...otherProps}>
            <SVGIcon icon={icon} size={24} />
            <Text text={text} weight="medium" />
        </Container>
    );
};
