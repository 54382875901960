import styled from '@emotion/styled';

export const Container = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.color.bloodyMary};
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: ${({ theme }) => theme.spacing[6]};
    padding-right: ${({ theme }) => theme.spacing[6]};

    p {
        color: ${({ theme }) => theme.color.white100};
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    height: 16px;

    & > * {
        margin-right: ${({ theme }) => theme.spacing[1]};
    }
`;

export const DropDownButton = styled.div`
    border: none !important;
    margin: -${({ theme }) => theme.spacing[2]};
    color: white;
    height: 40px;
    padding: ${({ theme }) => theme.spacing[3]};
    border-radius: ${({ theme }) => theme.radius.main};
    display: flex;
    gap: ${({ theme }) => theme.spacing[3]};
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: ${({ theme }) => theme.font.size.normal};
    background-color: ${({ theme }): string => theme.color.primary};

    div {
        color: ${({ theme }): string => theme.color.white100};
    }
`;
