import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { computeText } from '@/locales/utils';
import { SpotlightSearchInput } from './SpotlightSearch.style';

export type SpotlightSearchProps = {
    value: string;
    placeholder: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SpotlightSearch = ({ value, placeholder, onChange }: SpotlightSearchProps): ReactElement => {
    const intl = useIntl();

    return <SpotlightSearchInput value={value} placeholder={computeText(intl, placeholder)} onChange={onChange} />;
};
