import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ENV } from '@/utils/env';
import { injectDataInUri } from '@/utils/routes';
import { Text } from '@/components/atoms/Text';
import { Button } from '@/components/atoms/Button';
import { cookieLocationGuest, useAuthCookieHandler } from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Routes } from '@/core/router/routes.types';
import { useLazyMeQuery, useLogoutMutation } from '@/services/innovorder/auth/auth.endpoints';
import { getUser, updateUser } from '@/redux/user';
import { Container, TextWrapper } from './AdminBanner.style';

export const AdminBanner: React.FunctionComponent<React.PropsWithChildren> = () => {
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const { removeCookie, token, isGuest } = useAuthCookieHandler();
    const [me, { data }] = useLazyMeQuery();
    const [postLogout, { isSuccess }] = useLogoutMutation();
    const logoutFromGuest = () => {
        postLogout({ token });
    };
    const updateUserAndRedirect = useCallback(async () => {
        if (isGuest) {
            return;
        }

        const guestBrandId = user?.brandId;
        data ? dispatch(updateUser({ ...data, isGuest, accessToken: token })) : await me({ token });
        const redirectTo = injectDataInUri({
            uri: Routes.BrandInfo,
            backofficeUrl: ENV.BACKOFFICE_URL,
            brandId: String(guestBrandId),
        });
        isSuccess && guestBrandId && window.location.replace(redirectTo);
    }, [token, isGuest, isSuccess, data]);

    useEffect(() => {
        if (isSuccess) {
            removeCookie(cookieLocationGuest);
        }
    }, [isSuccess]);
    useEffect(() => {
        updateUserAndRedirect();
    }, [token, isGuest]);
    return user && user.isGuest ? (
        <Container>
            <TextWrapper>
                <SVGIcon icon={SVGIcons.CLIENTS} size={16} color="white" />
                <Text text="adminBanner.message" />
                <Text text={user.email} weight="bold" noTranslation />
            </TextWrapper>
            <Button onClick={logoutFromGuest} buttonType="red">
                <Text text="adminBanner.backToAdmin"></Text>
            </Button>
        </Container>
    ) : null;
};
