import { useParams } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { mapWithDynamicPath, mapWithDynamicUri, WithResolvedPath, WithResolvedUri } from '@/utils/routes';
import { RouteDeclaration } from '@/core/router/routes.types';

type UseResolveRoutesPathAndUriFromParamsOptions = {
    otherParams?: Record<string, string>;
    isRelative?: boolean;
};
export const useResolveRoutesPathAndUriFromParams = <T extends RouteDeclaration<any>>(
    routes: T[],
    { otherParams, isRelative }: UseResolveRoutesPathAndUriFromParamsOptions = {},
): WithResolvedUri<WithResolvedPath<T>>[] => {
    const routeParams = useParams();
    const match = useRouteMatch();
    const routesWithUri = mapWithDynamicUri(routes, { ...routeParams, ...otherParams });
    return mapWithDynamicPath(routesWithUri, {
        parentPath: isRelative ? match.url : undefined,
        navigationContext: { ...routeParams, ...otherParams },
    });
};
