import React from 'react';
import { useHistory, useParams } from 'react-router';
import { HeaderLeft, HeaderRight, Subtitle } from '@/components/atoms/Section/Section.style';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import { SvyButton } from '@/components/savory/SvyButton';
import { FormattedMessage } from 'react-intl';
import { PosAccessBase } from '../../components/PosAcess';
import { PosGroupsListTable } from '../../components/PosGroups/tables/GroupsListTable';
import { Container, Header, HeaderContainer } from '../../components/PosGroups/tables/GroupsListTable.style';

type ParamTypes = {
    restaurantId: string;
    brandId: string;
};
const PosGroupsList: React.FunctionComponent<React.PropsWithChildren> = () => {
    const history = useHistory();
    const { brandId, restaurantId } = useParams<ParamTypes>();
    const handleOnCreate = () => {
        history.push(`/brands/${brandId}/restaurants/${restaurantId}/channels/pos/access/groups/new`);
    };
    return (
        <PosAccessBase>
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="pos.groups.page.title" />
                            <Subtitle>
                                <Text text="pos.groups.page.description" />
                            </Subtitle>
                        </HeaderLeft>
                        <HeaderRight>
                            <SvyButton style={{ backgroundColor: 'black' }} size="s" onClick={handleOnCreate}>
                                <FormattedMessage id="pos.groups.action.create" />
                            </SvyButton>
                        </HeaderRight>
                    </Header>
                </HeaderContainer>
                <PosGroupsListTable />
            </Container>
        </PosAccessBase>
    );
};

export { PosGroupsList };
