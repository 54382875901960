import { useEffect } from 'react';
import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
import { ApiRequest } from '../../request.types';
import {
    GetBrandConfigPayload,
    GetRestaurantConfigPayload,
    CreateConfigPayload,
    IOPayConfig,
    UpdateConfigPayload,
    DeleteConfigPayload,
    DeleteConfigResponse,
    ioPayParams,
    GET_BY,
    GetIOPayConfigResult,
} from './configurations.type';

const convertStringsToNumbers = (config: CreateConfigPayload | UpdateConfigPayload) => {
    return {
        ...config,
        fixOnlineCommissionAmount: Number(config.fixOnlineCommissionAmount),
        fixProximityCommissionAmount: Number(config.fixProximityCommissionAmount),
        variableOnlineCommissionPercent: Number(config.variableOnlineCommissionPercent),
        variableProximityCommissionPercent: Number(config.variableProximityCommissionPercent),
    };
};

const configurationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getBrandConfig: build.query<IOPayConfig, ApiRequest<GetBrandConfigPayload>>({
            query: ({ token, brandId }) => {
                return {
                    url: new URL(`/v1/adyen/configurations/brands/${brandId}`, ENV.PAYMENT_SERVICE_URL).href,
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    transformResponse: (response: IOPayConfig) => {
                        return response.id ? response : undefined;
                    },
                    validateStatus: (response, result) => response.status < 400 || result.statusCode === 404,
                };
            },
            extraOptions: { maxRetries: 3 },
        }),
        getRestaurantConfig: build.query<IOPayConfig, ApiRequest<GetRestaurantConfigPayload>>({
            query: ({ token, restaurantId }) => {
                return {
                    url: new URL(`/v1/adyen/configurations/restaurants/${restaurantId}`, ENV.PAYMENT_SERVICE_URL).href,
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    transformResponse: (response: IOPayConfig) => {
                        return response.id ? response : undefined;
                    },
                    validateStatus: (response, result) => response.status < 400 || result.statusCode === 404,
                };
            },
            extraOptions: { maxRetries: 3 },
        }),
        createConfig: build.mutation<IOPayConfig, ApiRequest<CreateConfigPayload>>({
            query: ({ token, ...params }) => {
                return {
                    url: new URL('/v1/adyen/configurations', ENV.PAYMENT_SERVICE_URL).href,
                    body: convertStringsToNumbers(params),
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: IOPayConfig) => response,
            extraOptions: { maxRetries: 3 },
        }),
        updateConfig: build.mutation<IOPayConfig, ApiRequest<UpdateConfigPayload>>({
            query: ({ token, configurationId, ...params }) => {
                return {
                    url: new URL(`/v1/adyen/configurations/${configurationId}`, ENV.PAYMENT_SERVICE_URL).href,
                    body: convertStringsToNumbers(params),
                    method: 'PATCH',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: IOPayConfig) => response,
            extraOptions: { maxRetries: 3 },
        }),
        deleteConfig: build.mutation<DeleteConfigResponse, ApiRequest<DeleteConfigPayload>>({
            query: ({ token, configurationId }) => {
                return {
                    url: new URL(`/v1/adyen/configurations/${configurationId}`, ENV.PAYMENT_SERVICE_URL).href,
                    method: 'DELETE',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: DeleteConfigResponse) => response,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useLazyGetBrandConfigQuery,
    useLazyGetRestaurantConfigQuery,
    useGetRestaurantConfigQuery,
    useCreateConfigMutation,
    useUpdateConfigMutation,
    useDeleteConfigMutation,
} = configurationApi;

export const useLazyGetIOPayConfigQuery = ({ token, restaurantId, brandId }: ioPayParams): GetIOPayConfigResult => {
    const [getBrandConfig, brandQuery] = useLazyGetBrandConfigQuery();
    const [getRestaurantConfig, restaurantConfigQuery] = useLazyGetRestaurantConfigQuery();

    const getBy = restaurantId === 0 ? GET_BY.BRAND : GET_BY.RESTAURANT;
    const params = getBy === GET_BY.BRAND ? { brandId } : { brandId, restaurantId };
    const query = getBy === GET_BY.BRAND ? brandQuery : restaurantConfigQuery;
    const getConfig = getBy === GET_BY.BRAND ? getBrandConfig : getRestaurantConfig;

    useEffect(() => {
        getConfig({ token, ...params });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { ...query, params, getConfig };
};
