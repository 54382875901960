import React from 'react';
import Wizard from '@/components/atoms/Wizard';
import RawModal from '@/components/atoms/RawModal';
import useCreateBrandViewModel from './CreateBrandForm.viewmodel';
import { FormProvider } from 'react-hook-form';

interface CreateBrandFormProps {
    isOpen: boolean;
    onHide: () => void;
}

const CreateBrandForm: React.FC<CreateBrandFormProps> = ({ isOpen, onHide }) => {
    const { steps, form, onSubmit, isLoading, selectedStep } = useCreateBrandViewModel();
    return (
        <RawModal onHide={onHide} isOpen={isOpen}>
            <FormProvider {...form}>
                <Wizard
                    steps={steps}
                    onValidate={form.handleSubmit(onSubmit)}
                    isLoading={isLoading}
                    selectedStep={selectedStep}
                />
            </FormProvider>
        </RawModal>
    );
};

export default CreateBrandForm;
