import { IntlShape } from 'react-intl';
import { computeText } from '@/locales/utils';
import { ValidateResult } from 'react-hook-form';

type ValidationFn<T> = (value: T) => ValidateResult;

type ValidationIntlFn = <T>(intl: IntlShape) => ValidationFn<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
type ValidationPipe = <T>(intl: IntlShape) => (...fns: Function[]) => ValidationFn<T>;

export const validationPipe: ValidationPipe =
    (intl) =>
    (...fns) =>
    (value) => {
        // for loop is better than an array.reduce here
        // eslint-disable-next-line no-restricted-syntax
        for (const fn of fns) {
            const result = fn(intl)(value);
            if (result) {
                return result;
            }
        }
        return undefined;
    };

export const optionalValidationPipe: ValidationPipe =
    (intl) =>
    (...fns) =>
    (value) =>
        value == null ? undefined : validationPipe(intl)(...fns)(value);

export const isInteger: ValidationIntlFn = (intl) => (value) =>
    !value || Number.isInteger(Number(value)) ? undefined : computeText(intl, 'form.validation.integer');

export const isPositive: ValidationIntlFn = (intl) => (value) =>
    !value || Number(value) > 0 ? undefined : computeText(intl, 'form.validation.positive');

export const isPositiveOrZero: ValidationIntlFn = (intl) => (value) =>
    !value || Number(value) >= 0 ? undefined : computeText(intl, 'form.validation.positiveOrZero');

export const isEmail = (intl: IntlShape) => (value: string) =>
    !value ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
    )
        ? undefined
        : computeText(intl, 'form.validation.email');

export const hasLength: (length: number) => ValidationIntlFn = (length: number) => (intl: IntlShape) => (value) => {
    if (!Number.isInteger(Number(length))) {
        return undefined;
    }
    if (typeof value === 'number' || typeof value === 'string') {
        return String(value).length === length ? undefined : computeText(intl, 'form.validation.length', { length });
    }
    return undefined;
};

export const mustMatchValue: (value1: string, value2: string) => ValidationIntlFn =
    (value1: string, value2: string) => (intl: IntlShape) => (value) => {
        if (value === value1 || value === value2) {
            return undefined;
        }
        return computeText(intl, 'form.validation.mustMatchValue', { value1, value2 });
    };

export const hasMaxLength: (length: number) => ValidationIntlFn = (length: number) => (intl: IntlShape) => (value) => {
    if (!Number.isInteger(Number(length))) {
        return undefined;
    }
    if (typeof value === 'number') {
        return value <= length * 10 ? undefined : computeText(intl, 'form.validation.maxLength');
    }
    if (typeof value === 'string') {
        return value.length <= length ? undefined : computeText(intl, 'form.validation.maxLength');
    }
    return undefined;
};

export const isValidSiret = (intl: IntlShape) => (value: string) => {
    if (value.length !== 14) return computeText(intl, 'form.validation.siret.length');

    const sum = Array.from(value).reduce((acc, cur, idx) => {
        const currentDigit = parseInt(cur, 10);

        if (idx % 2 === 1) return acc + currentDigit;
        if (currentDigit * 2 > 9) return acc + currentDigit * 2 - 9;
        return acc + currentDigit * 2;
    }, 0);

    return sum % 10 === 0 ? undefined : computeText(intl, 'form.validation.siret.invalid');
};
