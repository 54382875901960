import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@/components/atoms/Text';
import { computeText } from '@/locales/utils';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { GuestGroupWithGuestsCount, PricingRule } from '@/services/innovorder/guestGroup/guestGroup.type';
import { getUserBrandId } from '@/redux/user';
import SelectInput from '../CustomerFormInputs/SelectInput';
import {
    PRICING_CONFIGURATION_LINKS,
    getAccountPaymentTypeOptions,
    getGuestGroupOptions,
    getPricingRulesDefaultValues,
    getPricingRulesOptions,
    getRedirectionLink,
} from './PricingConfigurationSection.utils';
import { SectionTitle, StyledInfoParagraph } from './PricingConfigurationSection.style';
import TextFieldInput from '../CustomerFormInputs/TextFieldInput';
import { getUseNewGuestJourneyDesignWithSectionAndSubSection } from '@/redux/launchDarkly';
import { ActiveStudentEntities } from '@/services/innovorder/brand/brand.type';
import { useGetSectionFieldSuggestions, useGetSubSectionFieldSuggestions } from './hooks';

type PricingConfigurationSectionProps = {
    guestGroups: GuestGroupWithGuestsCount[];
    activeStudentEntities?: ActiveStudentEntities;
};

const PricingConfigurationSection: React.FunctionComponent<
    React.PropsWithChildren<PricingConfigurationSectionProps>
> = ({ guestGroups, activeStudentEntities }) => {
    const intl = useIntl();
    const brandId = useSelector(getUserBrandId);
    const shouldDisplaySectionAndSubSectionCustomerForm = useSelector(
        getUseNewGuestJourneyDesignWithSectionAndSubSection,
    );
    const [selectedGuestGroup, setSelectedGuestGroup] = useState<GuestGroupWithGuestsCount | undefined>(undefined);
    const history = useHistory();

    const { getValues, reset } = useFormContext();

    const [pricingRules, setPricingRules] = useState<PricingRule[]>(
        getPricingRulesDefaultValues(guestGroups, getValues('guestGroupId')),
    );

    const handleGuestGroupSelect = (selectedGuestGroupId: number) => {
        const guestGroup = guestGroups.find(({ guestGroupId }) => guestGroupId === selectedGuestGroupId);
        if (!guestGroup) {
            return;
        }
        const { pricingRules: guestGroupPricingRules = [] } = guestGroup;
        setSelectedGuestGroup(guestGroup);
        setPricingRules(guestGroupPricingRules);
        const selectedGroupHasOnlyOnePricingRule = guestGroup?.pricingRules.length === 1;
        let pricingRuleCode;
        if (selectedGroupHasOnlyOnePricingRule) {
            pricingRuleCode = guestGroup.pricingRules[0].code;
        }
        reset({ ...getValues(), pricingRuleCode });
    };

    const sectionsFieldSuggestions = useGetSectionFieldSuggestions(activeStudentEntities, selectedGuestGroup);

    const sectionValue = getValues('section');

    const subSectionFieldSuggestions = useGetSubSectionFieldSuggestions(
        sectionValue,
        activeStudentEntities,
        selectedGuestGroup,
    );

    const handleRedirectionLinkClick = (link: string) => {
        if (brandId) {
            history.push(getRedirectionLink(link, brandId));
        }
    };

    return (
        <>
            <SectionTitle>
                <Text text={'customer.form.pricingRuleSection'} weight="bold" />
            </SectionTitle>
            <SelectInput<string>
                fieldName={'accountPaymentType'}
                rules={{
                    required: computeText(intl, 'field.required'),
                }}
                options={getAccountPaymentTypeOptions(intl)}
                showErrorMessage={true}
            />
            <div>
                <SelectInput<number>
                    fieldName={'guestGroupId'}
                    rules={{
                        required: computeText(intl, 'field.required'),
                    }}
                    options={getGuestGroupOptions(guestGroups)}
                    handleChange={handleGuestGroupSelect}
                />
                <StyledInfoParagraph>
                    {computeText(intl, 'customer.form.guestGroupInfo')}
                    <span onClick={() => handleRedirectionLinkClick(PRICING_CONFIGURATION_LINKS.GUEST_GROUPS)}>
                        {computeText(intl, 'customer.form.here')}
                    </span>
                </StyledInfoParagraph>
            </div>
            {shouldDisplaySectionAndSubSectionCustomerForm && (
                <>
                    <TextFieldInput fieldName="section" suggestions={sectionsFieldSuggestions} />
                    <TextFieldInput fieldName="subSection" suggestions={subSectionFieldSuggestions} />
                </>
            )}
            <div>
                <SelectInput<string>
                    fieldName={'pricingRuleCode'}
                    rules={{
                        required: computeText(intl, 'field.required'),
                    }}
                    options={getPricingRulesOptions(pricingRules)}
                />
                <StyledInfoParagraph>
                    {computeText(intl, 'customer.form.pricingRuleInfo')}
                    <span onClick={() => handleRedirectionLinkClick(PRICING_CONFIGURATION_LINKS.PRICING_RULES)}>
                        {computeText(intl, 'customer.form.here')}
                    </span>
                </StyledInfoParagraph>
            </div>
        </>
    );
};

export default PricingConfigurationSection;
