import styled from '@emotion/styled';

export const RadioButtons = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[3]}};
    padding: ${({ theme }) => theme.spacing[5]} 0;
`;

export const Form = styled.form`
    width: 150px;
`;

export const HorizontalContainer = styled.div`
    display: flex;
    padding: 0 ${({ theme }) => theme.spacing[4]};
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]}};
`;
