import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing[4]} 0;
`;
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
`;
export const InputsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const MultiSelectContainer = styled.div`
    padding-top: ${({ theme }) => theme.spacing[4]};
    width: 50%;
`;
export const Container = styled.div`
    flex: 1;
    background-color: ${({ theme }): string => theme.color.grey300};
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing[3]}};
`;

export const FormText = styled(Text)`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;
