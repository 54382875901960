import { TransactionStatus, TransactionTypes } from '../transactions/transaction.type';
import { Paginated } from '../../request.types';

export interface GetTransfersResponse {
    total: number;
    transfers: TransferResponse[];
}

export enum TransferStatus {
    PENDING = 1,
    SUCCESS = 2,
    SENT_OUT = 3,
    FAILED = 4,
}

export type GetTransfersSort =
    | 'executedAt'
    | '-executedAt'
    | 'amount'
    | '-amount'
    | 'transactionCount'
    | '-transactionCount';

export interface TransferResponse {
    id: number;
    configurationId: number;
    reference: string;
    amount: number;
    status: number;

    configuration: {
        name: string;
    };

    transactions: TransferTransactionDetails[];

    executedAt: string;
    createdAt: string;
    updatedAt: string;
}

export interface TransferTransactionDetails {
    id: number;
    reference: string;
    type: TransactionTypes;
    status: TransactionStatus;
    brand: string;
    customerFirstName: string;
    customerLastName: string;
    amount: number;
}

export interface GetTransfersPayload extends Required<Paginated> {
    brandId?: number;
    restaurantIds?: number[];
    configurationId?: number;

    customerId?: number;
    search?: string;
    filters?: GetTransfersFilters;
    order?: GetTransfersSort[];
}

export interface GetSingleTransferPayload {
    transferId?: number;
}

export type GetTransfersFilters = Record<string, string>;
