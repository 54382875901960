import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { computeText } from '@/locales/utils';
import { PaymentMethod } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { ConsumptionMode } from '@/services/innovorder/consumptionMode/consumptionMode.type';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { DraggablePaymentMethodsRow } from './DraggablePaymentMethodsRow';
import { Section, TableContainer } from './DraggablePaymentMethods.style';
import { getBindedList, getUnbindedList, getNewOrder, ListTypes } from './DraggablePaymentMethods.utils';

type DraggableTableProps = {
    data: PaymentMethod[];
    channelId: ChannelId;
    consumptionModes: ConsumptionMode[];
    isExternalIdActivated: boolean;
    onChange: (param: PaymentMethod[]) => void;
};

export const DraggablePaymentMethods: React.FunctionComponent<React.PropsWithChildren<DraggableTableProps>> = ({
    data,
    channelId,
    consumptionModes,
    isExternalIdActivated,
    onChange,
}) => {
    const intl = useIntl();
    const lists = useMemo(
        () => ({
            [ListTypes.ACCEPTED]: getBindedList(data),
            [ListTypes.REFUSED]: getUnbindedList(data, channelId),
        }),
        [data, channelId],
    );

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        if (!destination) return;

        const orderedData = getNewOrder(lists, source, destination);
        const paymentMethods = data.reduce((result, item) => {
            const pm = result.find(({ code }) => code === item.code);
            if (!pm) return [...result, item];
            return result;
        }, orderedData);
        onChange(paymentMethods);
    };

    const onComsumptionModeChange = (newPaymentMethod: PaymentMethod) => {
        const updated = lists[ListTypes.ACCEPTED].map((pm) =>
            pm.code === newPaymentMethod.code ? newPaymentMethod : pm,
        );
        const paymentMethods = data.reduce(
            (result, item) => {
                const pm = result.find(({ code }) => code === item.code);
                if (!pm) return [...result, item];
                return result;
            },
            [...updated, ...lists[ListTypes.REFUSED]],
        );
        onChange(paymentMethods);
    };

    return (
        <TableContainer>
            <DragDropContext onDragEnd={onDragEnd}>
                <div>
                    <Section>
                        {computeText(intl, 'paymentMethods.tab.kiosk.payment')}{' '}
                        <strong>{computeText(intl, 'paymentMethods.tab.kiosk.payment.accepted')}</strong>
                    </Section>
                    <Droppable droppableId={ListTypes.ACCEPTED}>
                        {(provided) => (
                            <table ref={provided.innerRef} {...provided.droppableProps}>
                                <tbody>
                                    {lists[ListTypes.ACCEPTED].map((item, index) => (
                                        <DraggablePaymentMethodsRow
                                            key={`draggable:${item.code}/${index}`}
                                            index={index}
                                            item={item}
                                            consumptionModes={consumptionModes}
                                            isExternalIdActivated={isExternalIdActivated}
                                            onChange={onComsumptionModeChange}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </Droppable>
                    <br />
                    <Section>
                        {computeText(intl, 'paymentMethods.tab.kiosk.payment')}{' '}
                        <strong>{computeText(intl, 'paymentMethods.tab.kiosk.payment.refused')}</strong>
                    </Section>
                    <Droppable droppableId={ListTypes.REFUSED}>
                        {(provided) => (
                            <table ref={provided.innerRef} {...provided.droppableProps}>
                                <tbody>
                                    {lists[ListTypes.REFUSED].map((item, index) => (
                                        <DraggablePaymentMethodsRow
                                            isDisabled={true}
                                            key={`draggable:${item.code}/${index}`}
                                            index={index}
                                            item={item}
                                            consumptionModes={consumptionModes}
                                            isExternalIdActivated={isExternalIdActivated}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        </TableContainer>
    );
};
