import { Table } from '@/components/atoms/Table';
import React from 'react';
import { useRolesTableVM } from './PosRolesList.viewmodel';

const PosRolesListTable: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { headers, rows, isLoading, handleOnRowClick } = useRolesTableVM();

    return (
        <Table
            isSearchable
            rows={rows}
            emptyMessageId="table.noRowsAfterFilter"
            columns={headers}
            hasPagination
            hasUrlNavigation
            isSortable
            isLoading={isLoading}
            onRowClick={handleOnRowClick}
        />
    );
};

export { PosRolesListTable };
