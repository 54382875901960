import { ActionBar } from '@/components/atoms/ActionBar';
import { Button } from '@/components/atoms/Button';
import Select from '@/components/atoms/Select';
import { Title } from '@/components/atoms/Title';
import { computeText } from '@/locales/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { downloadFile } from '@/utils/file/utils';
import { useGenerateOutgoingTransfersReportMutation } from '@/services/innovorder/io-pay/reports/reports.endpoints';
import { getUserBrandId, getUserToken } from '@/redux/user';
import IOPayTabs from '../IOPayTabs';
import {
    Calendar,
    Container,
    ContentContainer,
    FormContainer,
    Header,
    InputText,
    ReportContainer,
    Subtitle,
    TitleContainer,
} from './Reports.style';

type RequestPeriod = {
    from: Date | undefined;
    to: Date | undefined;
};

const Reports: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const token = useSelector(getUserToken);
    const brandId = useSelector(getUserBrandId);
    const [error, setError] = useState<string | undefined>();
    const [selectedReportType, selectReportType] = useState<string | null>(null);
    const [selectedPeriod, setPeriod] = useState<RequestPeriod>({
        from: undefined,
        to: undefined,
    });

    const handleDatesChange = useCallback((dates: { startDate: Date | null; endDate: Date | null }) => {
        if (dates.startDate !== null && dates.endDate !== null) {
            setPeriod({
                from: dates.startDate,
                to: dates.endDate,
            });
        }
    }, []);

    const [downloadReport, { data, isLoading }] = useGenerateOutgoingTransfersReportMutation();

    useEffect(() => {
        setError(undefined);
        selectReportType(null);
        if (!data) {
            return;
        }

        const filename = `outgoing_transfers_${new Date().toISOString()}.csv`;
        downloadFile(filename, data, 'text/csv');
    }, [data]);

    const handleGenerate = async () => {
        if (!selectedPeriod.from || !selectedPeriod.to) {
            setError(computeText(intl, 'reports.error.missingDates'));
            return;
        }

        if (!selectedReportType) {
            setError(computeText(intl, 'reports.error.missingReportType'));
            return;
        }

        if (!brandId) {
            setError(computeText(intl, 'reports.error.missingBrand'));
            return;
        }

        downloadReport({ token, brandId, startDate: selectedPeriod.from, endDate: selectedPeriod.to });
    };

    return (
        <IOPayTabs>
            <Container>
                <ContentContainer>
                    <Header data-testid="reports-head">
                        <TitleContainer>
                            <Title text="reports.title" />
                            <Subtitle text="reports.subtitle" />
                        </TitleContainer>
                    </Header>
                    <FormContainer>
                        <InputText type="large" weight="medium" text="reports.choosePeriod" />
                        <Calendar
                            startDate={selectedPeriod.from}
                            endDate={selectedPeriod.to}
                            open={true}
                            monthShown={2}
                            inline={true}
                            onChange={handleDatesChange}
                            selectsRange={true}
                            disabled={false}
                        />

                        <InputText type="large" weight="medium" text="reports.chooseReport" />
                        <InputText type="small" weight="medium" text="reports.reportType" />
                        <ReportContainer>
                            <Select
                                placeholder="reports.chooseReport"
                                value={selectedReportType}
                                options={[{ label: 'reports.outgoingTransfers', value: 'outgoingTransfers' }]}
                                onChange={(value) => {
                                    selectReportType(value);
                                }}
                            />
                        </ReportContainer>

                        <ActionBar error={error}>
                            <Button buttonType="primary" onClick={handleGenerate} isLoading={isLoading}>
                                <FormattedMessage id="button.generate" />
                            </Button>
                        </ActionBar>
                    </FormContainer>
                </ContentContainer>
            </Container>
        </IOPayTabs>
    );
};

export default Reports;
