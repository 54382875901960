import { enumValues } from '@/utils/typescript';
import { User, UserRoles } from '@/services/innovorder/user/user.type';
import { LinkPayload, SideBarArea, SplitedSideBarItems } from './dynamicMenu.type';

export const filterItemsByRoleAndAccessFlags = (
    links: LinkPayload[],
    role: UserRoles | undefined,
    accessFlags?: User['accessFlags'],
): LinkPayload[] => {
    if (!role) return [];
    let filteredLinkPayloads = links.filter(
        accessFlags
            ? (link) => link.roles.includes(role) && link.accessFlags?.every((k) => accessFlags[k]) !== false
            : (link) => link.roles.includes(role) && !link.accessFlags?.length,
    );
    filteredLinkPayloads = filteredLinkPayloads.map((link) => {
        if (Array.isArray(link.subMenu) && link.subMenu.length > 0)
            return { ...link, subMenu: filterItemsByRoleAndAccessFlags(link.subMenu, role, accessFlags) };
        return link;
    });
    return filteredLinkPayloads;
};

export const filterItemsByFlag = (links: LinkPayload[], flags: Record<string, boolean>): LinkPayload[] => {
    let filteredLinkPayloads = links.filter((link) => {
        if (link.enabledBy && !flags[link.enabledBy]) return false;
        if (link.disabledBy && flags[link.disabledBy]) return false;
        return true;
    });
    filteredLinkPayloads = filteredLinkPayloads.map((link) => {
        if (Array.isArray(link.subMenu) && link.subMenu.length > 0)
            return { ...link, subMenu: filterItemsByFlag(link.subMenu, flags) };
        return link;
    });
    return filteredLinkPayloads;
};

export const splitSideBarItemsByArea = (items: LinkPayload[]): SplitedSideBarItems => {
    const emptySplittedMap = enumValues<SideBarArea>(SideBarArea).reduce<SplitedSideBarItems>(
        (map, sidebarArea) => ({ ...map, [sidebarArea]: [] }),
        {} as SplitedSideBarItems,
    );

    return items.reduce<SplitedSideBarItems>((map, item) => {
        const { layoutArea } = item;
        return {
            ...map,
            [layoutArea]: [...map[layoutArea], item],
        };
    }, emptySplittedMap);
};
