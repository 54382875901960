import React from 'react';
import Kpi from '@/components/atoms/HeaderKpi';
import { computeCurrency, computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { Title } from '@/components/atoms/Title';
import { theme } from '@/core/theme';
import { Text } from '@/components/atoms/Text';
import { TransactionStatus, TransactionTypes } from '@/services/innovorder/io-pay/transactions/transaction.type';
import { Container, Chips, ChipsContainer, ChipsValue } from './TransactionDetail.style';
import { FlexGrow, Header, TitleContainer, KpiContainer } from '../../Transfers/Transfers.style';
import { getTransactionStatusColor, getTransactionTypeColor } from '../utils';

type TransactionDetailsHeaderProps = {
    id: string;
    date: string;
    type: TransactionTypes;
    status: TransactionStatus;
    currency: string;
    amount: number;
};

const TransactionDetailsHeader: React.FunctionComponent<React.PropsWithChildren<TransactionDetailsHeaderProps>> = ({
    id,
    date,
    type,
    status,
    currency,
    amount,
}) => {
    const executedAtDate = new Date(date);
    const intl = useIntl();

    return (
        <>
            <Header>
                <TitleContainer>
                    <Title text="transactions.detail.title" />
                </TitleContainer>
                <FlexGrow />
                <ChipsContainer>
                    <Chips>
                        <Text text="transaction.field.currency" />
                        <ChipsValue>{currency}</ChipsValue>
                    </Chips>
                </ChipsContainer>
            </Header>
            <Container>
                <KpiContainer>
                    <Kpi title="transaction.field.id" text={id} />
                    <Kpi title="transaction.field.paymentAmount" text={computeCurrency({ intl, amount })} />
                    <Kpi
                        title="transaction.field.type"
                        text={type.toString()}
                        badge={{
                            text: computeText(intl, `transaction.type.${TransactionTypes[type]}`),
                            backgroundColor: getTransactionTypeColor(type, theme),
                        }}
                    />
                    <Kpi
                        title="transaction.field.status"
                        text={status.toString()}
                        badge={{
                            text: computeText(intl, `transaction.status.${TransactionStatus[status]}`),
                            backgroundColor: getTransactionStatusColor(status, theme),
                        }}
                    />
                    <Kpi title="transaction.field.date" text="transfer.kpi.date" values={{ date: executedAtDate }} />
                </KpiContainer>
            </Container>
        </>
    );
};
export default TransactionDetailsHeader;
