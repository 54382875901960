import { IntlShape } from 'react-intl';
import { intersection } from 'lodash';
import { BillingEntity, BillingReferential } from '@/services/innovorder/autoBilling/autoBilling.type';
import { computeText } from '@/locales/utils';

export const getErrorMessage = (
    billingReferential: BillingReferential,
    changedBillingEntity: BillingEntity,
    intl: IntlShape,
    ignoredBillingEntityCode?: string,
): string => {
    const billingEntities = billingReferential.entities.filter((entity) => entity.code !== ignoredBillingEntityCode);
    const existingEntity = billingEntities.find((entity) => entity.code === changedBillingEntity.code);
    if (existingEntity) {
        return computeText(intl, 'auto.billing.referential.billingEntities.errors.duplicate.code', {
            code: changedBillingEntity.code,
            entity: existingEntity.name,
        });
    }

    const duplicatedGroups = intersection(
        changedBillingEntity.groups,
        billingEntities.flatMap((entity) => entity.groups),
    );

    if (duplicatedGroups.length > 0) {
        const foundEntity = billingEntities.find(
            (entity) => intersection(duplicatedGroups, entity.groups).length > 0,
        ) as BillingEntity;

        return computeText(intl, 'auto.billing.referential.billingEntities.errors.duplicate.group', {
            groups: duplicatedGroups.join(', '),
            entity: foundEntity.name,
        });
    }
    return '';
};
