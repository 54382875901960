import React from 'react';
import { Link } from 'react-router-dom';

import { Title } from '@/components/atoms/Title';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { Routes } from '@/core/router/routes.types';
import { ReactComponent as Illustration } from './PermissionDenied.svg';
import { Container } from './PermissionDenied.style';

const PermissionDenied: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <Container>
            <Illustration width={390} />
            <Title text="permissionDenied.title" weight="bold" />
            <Title text="permissionDenied.message" weight="regular" />
            <Link to={Routes.Home}>
                <Button buttonType="outlinedPrimary">
                    <Text text="permissionDenied.backToHome" />
                </Button>
            </Link>
        </Container>
    );
};

export default PermissionDenied;
