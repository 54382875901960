import styled from '@emotion/styled';
import { Input } from '@/components/atoms/Form/Input';
import { StaticImage } from '@/components/atoms/StaticImage/StaticImage';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2;
    box-sizing: border-box;

    & > p {
        text-align: center;
    }
`;

export const DropzoneContainer = styled.div`
    &,
    > * {
        width: 100%;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-wrap: no-wrap;
    margin: 12px 0 24px 0;
`;

export const DescriptionList = styled.ol`
    font-family: ${({ theme }) => theme.font.family};
    padding: 24px;
    text-align: center;
    list-style-position: inside;
`;

export const DescriptionItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.black400};
`;

export const BackgroundContainer = styled(StaticImage)`
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 4px;
`;
