import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
import { ApiRequest } from '../../request.types';
import {
    CreateBillingConfigurationPayload,
    BillingConfiguration,
    UpdateBillingConfigurationPayload,
} from './configurations.type';

const configurationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        findBillingConfigurationById: build.query<BillingConfiguration, ApiRequest<number>>({
            query: (billingConfigurationId: number) => {
                return {
                    url: new URL(`/v1/billing/configurations/${billingConfigurationId}`, ENV.PAYMENT_SERVICE_URL).href,
                    method: 'GET',
                };
            },
            transformResponse: (response: BillingConfiguration) => response,
            extraOptions: { maxRetries: 3 },
        }),
        createBillingConfiguration: build.mutation<BillingConfiguration, ApiRequest<CreateBillingConfigurationPayload>>(
            {
                query: (params) => {
                    return {
                        url: new URL('/v1/billing/configurations', ENV.PAYMENT_SERVICE_URL).href,
                        body: params,
                        method: 'POST',
                    };
                },
                transformResponse: (response: BillingConfiguration) => response,
                extraOptions: { maxRetries: 3 },
            },
        ),
        updateBillingConfiguration: build.mutation<BillingConfiguration, ApiRequest<UpdateBillingConfigurationPayload>>(
            {
                query: ({ billingConfigurationId, ...params }) => {
                    return {
                        url: new URL(`/v1/billing/configurations/${billingConfigurationId}`, ENV.PAYMENT_SERVICE_URL)
                            .href,
                        body: params,
                        method: 'PATCH',
                    };
                },
                transformResponse: (response: BillingConfiguration) => response,
                extraOptions: { maxRetries: 3 },
            },
        ),
    }),
});

export const {
    useCreateBillingConfigurationMutation,
    useUpdateBillingConfigurationMutation,
    useLazyFindBillingConfigurationByIdQuery,
} = configurationApi;
