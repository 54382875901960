import React from 'react';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/atoms/Button';
import { useTheme } from '@emotion/react';
import Loader from '@/components/atoms/Loader';

interface FiltersButtonProps {
    handleExport: () => void;
    isTableLoading: boolean;
    isProcessingExport: boolean;
}

const ExportButton: React.FunctionComponent<FiltersButtonProps> = ({
    handleExport,
    isTableLoading,
    isProcessingExport,
}) => {
    const theme = useTheme();
    const isLoading = isTableLoading || isProcessingExport;

    return (
        <Button buttonType="primary" onClick={handleExport} disabled={isLoading}>
            <SVGIcon icon={SVGIcons.EXPORTS} size={16} color={theme.color.white100} />
            <span style={{ minWidth: 55 }}>
                {isLoading ? <Loader size={16} /> : <FormattedMessage id={'report.exportButton.text'} />}
            </span>
        </Button>
    );
};

export default ExportButton;
