import styled from '@emotion/styled';

export const Container = styled.div`
    border: none;
    flex: 1;
    margin: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[1]};
    font-family: ${({ theme }) => theme.font.family};
    display: flex;
    flex-direction: column;
`;
export const Header = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[4]};
    margin: ${({ theme }) => theme.spacing[3]};
    justify-content: space-between;
`;

export const HeaderCell = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[1]};
`;

export const HeaderElementTile = styled.div`
    font-weight: bold;
    font-size: ${({ theme }) => theme.font.size.small};
`;

export const HeaderElementValue = styled.div`
    font-size: ${({ theme }) => theme.font.size.normal};
    color: ${({ theme }) => theme.color.black200};
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing[1]};
`;

export const TableContainer = styled.div`
    overflow-y: scroll;
    max-height: 350px;
`;

export const InvoiceTotal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin: ${({ theme }) => theme.spacing[2]} 0;
`;

export const InvoiceTotalLine = styled.div<{ border?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-bottom: ${({ border, theme }) => (border ? `2px solid ${theme.color.black}` : 'none')};
    padding-bottom: ${({ border, theme }) => (border ? theme.spacing[2] : '0')};
`;

export const InvoiceTotalLineLeftElement = styled.div`
    width: 200px;
    font-weight: bold;
    font-size: ${({ theme }) => theme.font.size.small};
    text-align: left;
`;

export const InvoiceTotalLineRightElement = styled.div`
    text-align: right;
    width: 100px;
    font-size: ${({ theme }) => theme.font.size.normal};
    color: ${({ theme }) => theme.color.black200};
`;

export const ButtonsLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
