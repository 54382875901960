import styled from '@emotion/styled';
import { Label } from '@/components/atoms/Label';

export const Container = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const FormContainer = styled.div`
    flex: 1;
    padding: ${({ theme }) => theme.spacing[4]};
    width: 100%;
    background: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: ${({ theme }) => theme.radius.main};

    & > *:not(:last-of-type) {
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

export const SpacedFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[3]};
    & > * {
        flex: 1;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const Flex = styled.div`
    flex: 1;
`;

export const SelectLabel = styled(Label)`
    margin-bottom: 0;
`;
