import { Routes } from '@/core/router/routes.types';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { getErrorCode } from '@/utils/errors';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import {
    usePutGuestGroupMutation,
    useGetGuestGroupByIdQuery,
} from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { useGetMinimalPricingRulesQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { getUserToken } from '@/redux/user';
import { routes } from '@/core/router/routes';
import GroupForm from '../GroupForm';

type ParamTypes = {
    brandId: string;
    groupId: string;
};
const UpdateGroup: React.FunctionComponent<React.PropsWithChildren> = () => {
    const history = useHistory();
    const { brandId, groupId } = useParams<ParamTypes>();
    const token = useSelector(getUserToken);
    const { data: pricingRulesData } = useGetMinimalPricingRulesQuery({
        brandId: parseInt(brandId, 10),
        token,
    });

    const [putGroup, { isSuccess, error, data, isLoading }] = usePutGuestGroupMutation();
    const { data: guestGroup } = useGetGuestGroupByIdQuery({ groupId: parseInt(groupId, 10), token });
    if (isSuccess && data?.name) {
        history.push(generatePath(Routes.Groups, { brandId }));
    }

    const put = (name: string, pricingRules: number[]) => {
        putGroup({
            groupId: parseInt(groupId, 10),
            name,
            pricingRules,
            token,
        });
    };

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            {guestGroup && (
                <GroupForm
                    group={guestGroup}
                    error={getErrorCode(error)}
                    onSubmit={put}
                    loading={isLoading}
                    pricingRules={pricingRulesData || []}
                />
            )}
        </RouterNavigationTabs>
    );
};

export default UpdateGroup;
