import React from 'react';

import { DriverConfig } from '@/services/innovorder/badgeReader/badgeReader.type';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

type DriverConfigsProps = {
    data: DriverConfig | Record<string, never>;
};

export const DriverConfigs: React.FunctionComponent<DriverConfigsProps> = ({ data }) => {
    return (
        <>
            {isEmpty(data) ? (
                '-'
            ) : (
                <FormattedMessage
                    id="badgeReader.driverConfigs"
                    values={{
                        ...data,
                        br: <br />,
                        // @ts-expect-error
                        b: (chunks) => <strong>{chunks}</strong>,
                    }}
                />
            )}
        </>
    );
};
