export enum CancelOrderReason {
    CannotFulfillOrder = 'CANNOT_FULFILL_ORDER',
    OrderingMistake = 'ORDERING_MISTAKE',
    UnsatisfiedCustomer = 'UNSATISFIED_CUSTOMER',
    CustomerNoShow = 'CUSTOMER_NO_SHOW',
    CustomerLeaves = 'CUSTOMER_LEAVES',
    LearningMode = 'LEARNING_MODE',
    Technical = 'TECHNICAL',
    Other = 'OTHER',
}
