import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { SelectEntityFormValues } from './SelectEntityStep.types';
import {
    LegalEntitySelect,
    ModalConfigurationContainer,
    ModalInputFullContainer,
    ModalInputTitle,
    ModalTitleContainer,
    SelectEntityStepTitle,
    Subtitle,
    TitleContainer,
} from './SelectEntityStep.style';
import { useSelectEntityStepVM } from './SelectEntityStep.viewmodel';

type SelectEntityStepProps = {
    onNext?: () => void;
    isLoading?: boolean;
};

export const SelectEntityStep: React.FunctionComponent<React.PropsWithChildren<SelectEntityStepProps>> = ({
    isLoading,
}) => {
    const intl = useIntl();
    const { control } = useFormContext<SelectEntityFormValues>();
    const { moduleStripes } = useSelectEntityStepVM();

    return (
        <ModalConfigurationContainer>
            <TitleContainer>
                <SelectEntityStepTitle text="billing.editConfiguration.selectEntityStep.title" />
                <Subtitle text="billing.editConfiguration.selectEntityStep.subtitle" />
            </TitleContainer>
            <Controller
                control={control}
                name="stripeModuleId"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value) => !!value,
                }}
                render={({ field: { onChange, value } }) => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle text="billing.editConfiguration.selectEntityStep.legalEntity" />
                        </ModalTitleContainer>

                        <LegalEntitySelect
                            data-testid="legal-entity-select"
                            options={moduleStripes}
                            showValidation={false}
                            onChange={onChange}
                            value={value}
                            placeholder="billing.editConfiguration.selectEntityStep.legalEntity.placeHolder"
                            searchable={true}
                            solidListbox={true}
                            disabled={isLoading}
                        />
                    </ModalInputFullContainer>
                )}
            />
        </ModalConfigurationContainer>
    );
};
