import styled from '@emotion/styled';

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.desktop} {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
    gap: ${({ theme }) => theme.spacing[4]};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const ChipsContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
    flex-wrap: wrap;
    justify-content: right;
    ${({ theme }) => theme.breakpoints.mobile} {
        justify-content: space-between;
        max-width: 100%;
    }
`;

export const Chips = styled.div`
    display: flex;
    background: ${({ theme }) => theme.color.grey100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing[2]};
    gap: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.color.black200};
`;

export const ChipsValue = styled.span`
    font-size: ${({ theme }) => theme.font.size.normal};
    font-weight: ${({ theme }) => theme.font.weight.bold};
`;
