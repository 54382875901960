import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white100};
    padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
    box-sizing: border-box;
`;

export const Row = styled.div`
    margin-bottom: 32px;
`;
