import React, { useEffect, useState } from 'react';
import Wizard from '@/components/atoms/Wizard';
import { useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import RawModal from '@/components/atoms/RawModal';
import { computeText } from '@/locales/utils';
import { useDeleteRoomMutation } from '@/services/innovorder/room/room.endpoint';
import { StepContainer } from './DeleteRoomModal.style';

export type DeleteRoomModalProps = {
    roomId: string | null;
    roomName: string | null;
    onHide: () => void;
};

export const DeleteRoomModal: React.FunctionComponent<React.PropsWithChildren<DeleteRoomModalProps>> = ({
    onHide,
    roomId,
    roomName = '',
}) => {
    const intl = useIntl();
    const [deleteRoomById, { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading }] = useDeleteRoomMutation();
    const [name, setName] = useState(roomName);

    useEffect(() => {
        setName(name);
    }, [name, setName]);

    useEffect(() => {
        if (isDeleteSuccess) {
            onHide();
        }
    }, [isDeleteSuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={480}
                steps={[
                    {
                        title: 'room.modal.delete.title',
                        onCancel: onHide,
                        component: (
                            <StepContainer>
                                <Text
                                    type="normal"
                                    weight="regular"
                                    text={computeText(intl, 'room.modal.delete.description', {
                                        roomName: roomName ?? '',
                                    })}
                                />
                            </StepContainer>
                        ),
                    },
                ]}
                isLoading={isDeleteLoading}
                onValidate={() => {
                    if (roomId) {
                        deleteRoomById({ roomId });
                    }
                }}
            />
        </RawModal>
    );
};
