import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSidebareStatus, getSidebarOverlay, setSidebarOverlay, toggleSidebar } from '@/redux/app';
import { getRouterPathName } from '@/redux/router/router.selector';
import { useGetReportsMetaQuery } from '@/services/innovorder/report/report.endpoints';
import { updateMetadata } from '@/redux/report';
import DynamicMenu from './DynamicMenu';
import logo from './white-logo.svg';
import SideBarOverlay from './SideBarOverlay';
import { Container, Logo, Padded, SidebarToggleButton, SideHeader } from './SideBar.style';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { useTheme } from '@emotion/react';

const SideBar: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { subMenu, parentName, parentIcon, uri, route } = useSelector(getSidebarOverlay) || {};
    const pathname = useSelector(getRouterPathName);
    const sidebarStatus = useSelector(getSidebareStatus);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { data: reportMetaData } = useGetReportsMetaQuery({});

    const closeOverlay = () => {
        dispatch(setSidebarOverlay(undefined));
    };

    // close overlay on route change
    useEffect(() => {
        dispatch(setSidebarOverlay(undefined));
    }, [pathname, dispatch]);

    useEffect(() => {
        if (reportMetaData) {
            dispatch(updateMetadata(reportMetaData));
        }
    }, [dispatch, reportMetaData]);

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar(undefined));
    };

    return (
        <Container isSidebarCollapsed={sidebarStatus}>
            <SidebarToggleButton isSidebarCollapsed={sidebarStatus} onClick={handleToggleSidebar}>
                <SVGIcon testid="arrow-left" icon={SVGIcons.ARROW_RIGHT} size={20} color={theme.color.emerald} />
            </SidebarToggleButton>
            <SideHeader isSidebarCollapsed={sidebarStatus}>
                <Logo src={logo} alt="Innovorder Logo" />
            </SideHeader>
            <Padded>
                <DynamicMenu />
            </Padded>
            {subMenu && (
                <SideBarOverlay
                    onClose={closeOverlay}
                    subMenu={subMenu}
                    title={parentName || ''}
                    icon={parentIcon}
                    uri={uri}
                    route={route}
                />
            )}
        </Container>
    );
};

export default SideBar;
