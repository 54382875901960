import { OrderDetailsItem } from '@/services/innovorder/orders/order.type';

export type OrderDetailsItemWithCustomization = OrderDetailsItem & { customization: OrderDetailsItem[] };

export const unflattenItems = (items: OrderDetailsItem[]): OrderDetailsItemWithCustomization[] => {
    const outputItems: OrderDetailsItemWithCustomization[] = [];
    if (!items) return outputItems;

    items.forEach((item) => {
        if (item.itemType !== 'CUSTOMIZATION') {
            outputItems.push({ ...item, customization: [] });
        } else if (!item.cancelReason) {
            outputItems[outputItems.length - 1].customization.push({ ...item });
            if (!item.free && item.totalPriceWithTaxIncluded) {
                outputItems[outputItems.length - 1].totalPriceWithTaxIncluded += item.totalPriceWithTaxIncluded;
            }
        }
    });
    return outputItems.filter(({ cancelReason }) => !cancelReason);
};

export const calculateSum = (table: Array<{ amount: number }>): number =>
    table
        ? table.reduce((sum, line) => {
              sum += line.amount;
              return sum;
          }, 0)
        : 0;

export const getTaxLetter = (tax: number, taxes?: number[]): string => {
    if (!tax || !taxes || taxes.length < 2) return '';

    const taxIndex = taxes.findIndex((taxValue) => taxValue === tax);

    if (taxIndex < 0) return '';

    const taxLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (taxIndex >= taxLetters.length) return '*';

    return taxLetters[taxIndex];
};

export const computeProductItemPrice = (item: OrderDetailsItemWithCustomization): number => {
    if (!item.free) {
        return item.totalPriceWithTaxIncluded;
    }

    if (item.customization && item.customization.length > 0) {
        return item.totalPriceWithTaxIncluded;
    }

    return 0;
};
