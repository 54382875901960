import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@/components/atoms/Button';
import Modal from '@/components/atoms/Modal';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useUpdateCustomerMutation } from '@/services/innovorder/customer/customer.endpoints';
import { getUserToken } from '@/redux/user';
import { StyledClientUpdateForm, StyledModalContainer } from './UpdateCustomerModal.style';
import UpdateCustomerForm from './UpdateCustomerForm';
import { ICustomerAttributes, UpdateCustomerType } from '@/services/innovorder/customer/customer.types';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { getCustomerDefaultValues } from './UpdateCustomerForm/UpdateCustomerForm.utils';
import { prepareUpdateCustomerPayload, redirectToStudentDetailsPage } from './UpdateCustomerModal.utils';
import { getFormErrorInfo, UNKNOWN_ERROR_CODE } from '../../CustomerForm.utils';
import { ActiveStudentEntities } from '@/services/innovorder/brand/brand.type';

type UpdateCustomerModalProps = {
    title: string;
    isOpen: boolean;
    customer: ICustomerAttributes;
    guestGroups: GuestGroupWithGuestsCount[];
    activeStudentEntities?: ActiveStudentEntities;
    onHide: () => void;
};

const UpdateCustomerModal: React.FunctionComponent<React.PropsWithChildren<UpdateCustomerModalProps>> = ({
    title,
    isOpen,
    onHide,
    customer,
    guestGroups,
    activeStudentEntities,
}) => {
    const token = useSelector(getUserToken);
    const intl = useIntl();

    const [updateCustomer, { isSuccess, data, error }] = useUpdateCustomerMutation();

    const defaultValues = getCustomerDefaultValues(customer, guestGroups);
    const customerForm: UseFormReturn<UpdateCustomerType> = useForm<UpdateCustomerType>({
        defaultValues: {
            ...defaultValues,
        },
        mode: 'all',
    });

    useEffect(() => {
        if (error) {
            const formError = getFormErrorInfo(error as FetchBaseQueryError, intl);
            const validErrorCode = formError.name !== UNKNOWN_ERROR_CODE;
            if (validErrorCode) {
                customerForm.setError(
                    formError.name as keyof UpdateCustomerType,
                    { message: formError.message },
                    { shouldFocus: true },
                );
            }
        }
    }, [customerForm, error, intl]);

    if (isSuccess && data?.customerId) {
        const { customerId } = data;
        redirectToStudentDetailsPage(customerId);
    }

    const handleSubmitForm = () => {
        updateCustomer({
            ...prepareUpdateCustomerPayload(customer, customerForm.getValues()),
            token,
        });
    };
    const nextButton = (
        <Button onClick={handleSubmitForm} buttonType="primary" disabled={!customerForm.formState.isValid}>
            <FormattedMessage id="button.next" />
        </Button>
    );
    return (
        <Modal isOpen={isOpen} title={title} onHide={onHide} buttons={nextButton} shouldNotHideOnClickOutside>
            <StyledModalContainer>
                <FormattedMessage id="customer.form.modalDescription" />
                <StyledClientUpdateForm>
                    <UpdateCustomerForm
                        customerForm={customerForm}
                        guestGroups={guestGroups}
                        activeStudentEntities={activeStudentEntities}
                    />
                </StyledClientUpdateForm>
            </StyledModalContainer>
        </Modal>
    );
};

export default UpdateCustomerModal;
