import * as React from 'react';
import { theme } from '@/core/theme';
import { SVGIcon, SVGIcons } from '../Icon/SVGIcon';
import { Text } from '../Text';
import { CheckboxContainer, Container, HiddenInput, TextContainer } from './Checkbox.style';

export interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
    label?: string;
    disabled?: boolean;
    checked?: boolean;
    value?: boolean;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({ label, value, checked, disabled = false, ...otherProps }, ref) => {
        const isChecked = checked ?? Boolean(value);
        const id = React.useMemo(() => otherProps.id ?? `${Math.random()}`, [otherProps.id]);
        return (
            <Container disabled={disabled}>
                <HiddenInput
                    data-testid="checkbox-input"
                    type="checkbox"
                    {...otherProps}
                    ref={ref}
                    checked={isChecked}
                    id={id}
                />
                <CheckboxContainer data-testid="checkbox-container" checked={isChecked} htmlFor={id}>
                    {isChecked && <SVGIcon color={theme.color.grey500} icon={SVGIcons.CHECK} size={16} />}
                </CheckboxContainer>
                {label && (
                    <TextContainer htmlFor={id}>
                        <Text type="normal" text={label} />
                    </TextContainer>
                )}
            </Container>
        );
    },
);

Checkbox.displayName = 'Checkbox';
