import React from 'react';
import { useIntl } from 'react-intl';
import { FormProvider } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import Wizard from '@/components/atoms/Wizard';
import RawModal from '@/components/atoms/RawModal';
import { MenuChannel, MenuPosDevice } from '@/services/innovorder/menu/menu.type';
import { useCreateMenuModalVM } from './CreateMenuModal.viewmodel';
import { Container } from './CreateMenuModal.style';

type CreateMenuModalProps = {
    onHide: () => void;
    brandId: number;
    restaurantId?: number;
    menuId: number | null;
    channels: MenuChannel[];
    posDevices: MenuPosDevice[];
    isSyncFromPosEnabled: boolean;
    menuName: string | null;
};

export const CreateMenuModal: React.FunctionComponent<React.PropsWithChildren<CreateMenuModalProps>> = ({
    onHide,
    brandId,
    restaurantId,
    menuId,
    channels,
    posDevices,
    isSyncFromPosEnabled,
    menuName,
}) => {
    const intl = useIntl();
    const { form, currentStep, steps, errorMessage, isLoading, handleHide, onFormValidate } = useCreateMenuModalVM({
        onHide,
        brandId,
        restaurantId,
        menuId,
        channels,
        posDevices,
        isSyncFromPosEnabled,
        menuName,
    });

    return (
        <RawModal isOpen={true} onHide={handleHide}>
            <FormProvider {...form}>
                <Container>
                    <Wizard
                        width={600}
                        selectedStep={currentStep}
                        steps={steps}
                        validateLabel={computeText(intl, 'button.save')}
                        onValidate={onFormValidate}
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                    />
                </Container>
            </FormProvider>
        </RawModal>
    );
};
