import React from 'react';
import { useIntl } from 'react-intl';

import { computeText } from '@/locales/utils';
import { useSpotlightQueryHandler } from '@/hooks/useSpotlightQueryHandler/useSpotlightQueryHandler';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { SpotlightContext, SpotlightCta } from '@/services/innovorder/spotlight/spotlight.type';
import { Container, Content, LineText, PageText, ContextText, ParentText, Cta } from './SpotlightItem.style';

type SpotlightItemProps = {
    name: string;
    parentName?: string;
    cta: SpotlightCta;
    context?: SpotlightContext;
    parentContext?: string;
};

export const SpotlightItem: React.FunctionComponent<React.PropsWithChildren<SpotlightItemProps>> = ({
    name,
    parentName,
    cta,
    context,
    parentContext,
}) => {
    const intl = useIntl();
    const contextValue = useSpotlightQueryHandler(context);

    return (
        <Container data-testid="spotlight-item">
            <SVGIcon icon={SVGIcons.BUBBLES} size={12} />
            <Content>
                <div>
                    <LineText>
                        <PageText>{computeText(intl, name)}</PageText>
                        {contextValue && <ContextText>{`[${contextValue}]`}</ContextText>}
                    </LineText>
                    {parentName && (
                        <LineText>
                            <ParentText>{computeText(intl, parentName)}</ParentText>
                            {parentContext && <ContextText>{`[${parentContext}]`}</ContextText>}
                        </LineText>
                    )}
                </div>
                <Cta className="indicator">{computeText(intl, `spotlight.context.${cta}`)}</Cta>
            </Content>
        </Container>
    );
};
