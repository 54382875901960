import { computeText } from '@/locales/utils';
import { IntlShape } from 'react-intl';

export type GroupData = {
    group: string;
    section?: string;
    subsection?: string;
    pricingRules: string[];
};

export const getHeaders = ({ intl }: { intl: IntlShape }) => {
    return [
        computeText(intl, 'group.export.group'),
        computeText(intl, 'group.export.section'),
        computeText(intl, 'group.export.subsection'),
        computeText(intl, 'group.export.pricingRule'),
    ];
};

export const getSheetOptions = ({ intl }: { intl: IntlShape }) => {
    const sheetOptions = {
        group: {
            name: 'Groupe',
            headers: getHeaders({ intl }),
            mapper: (data: GroupData) => [data.group, data.section, data.subsection, data.pricingRules.join(', ')],
        },
    };

    return sheetOptions;
};
