import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
`;

export const HeaderContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
`;

export const HeaderTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 24px;
    ${({ theme }) => theme.font.text.bold};
    font-size: ${({ theme }) => theme.font.size.medium};
`;

export const TitleContainer = styled.div`
    color: ${({ theme }) => theme.color.black};
`;

export const SubtitleContainer = styled.div`
    color: ${({ theme }) => theme.color.black200};
`;

export const TicketContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
`;

export const ItemContainer = styled.div`
    width: 100%;
`;

export const MiscContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const CustomizationContainer = styled.div`
    ${({ theme }) => theme.font.text.regular};
    font-size: ${({ theme }) => theme.font.size.normal};
    color: ${({ theme }) => theme.color.black200};
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-left: ${({ theme }) => theme.spacing[6]};
    line-height: 18px;
`;
