import { Switch } from '@headlessui/react';
import styled from '@emotion/styled';
import * as React from 'react';

const switchWidth = '42px';
const switchTipHeight = '18px';

export const Container = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

export const ButtonStyle = styled((props: React.ComponentProps<typeof Switch>) => <Switch {...props} />)<{
    checked: boolean;
    disabled?: boolean;
}>`
    position: relative;
    background-color: ${({ theme, checked }) => (checked ? theme.color.emerald : theme.color.grey400)};
    box-shadow: inset 0px 0px 2.42308px rgba(0, 0, 0, 0.25);
    width: ${switchWidth};
    height: 22px;
    border: none;
    border-radius: 11px;
    cursor: pointer;
`;

export const SwitchTip = styled.span<{ active: boolean }>`
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: ${({ theme }) => theme.color.white100};
    height: ${switchTipHeight};
    width: ${switchTipHeight};
    border-radius: 50%;
    transition: transform 0.3s ease;
    transform: translateX(${({ active }) => (active ? `calc(${switchWidth} - ${switchTipHeight} - 4px)` : '0px')});
`;

export const Label = styled.label`
    flex: 1;
    margin-left: 10px;
    cursor: pointer;

    ${({ theme }) => theme.font.text.regular}
`;
