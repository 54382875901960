import styled from '@emotion/styled';
import { Input } from '@/components/atoms/Form/Input';
import { Text } from '@/components/atoms/Text';
import { withTheme, withTooltip } from '@innovorder/savory';

export const TextWithTooltip = withTheme(withTooltip(Text));

export const ModalConfigurationContainer = styled.div`
    height: 800px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    display: grid;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white100};
    padding: 0;
    box-sizing: border-box;
`;

export const ModalInputFullContainer = styled.div`
    grid-column: 1/3;
    margin-bottom: 32px;
`;

export const ModalInputContainer = styled.div`
    margin-bottom: 32px;
`;

export const ModalInputTitle = styled(TextWithTooltip)`
    text-transform: uppercase;
`;

export const ModalTitleContainer = styled.div`
    margin-bottom: 8px;
`;

export const ModalTextInput = styled(Input)`
    width: auto;
`;
