import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: 20px;

    overflow-y: auto;
    flex-grow: 1;

    h2 {
        margin-bottom: 10px;
    }
`;

export const Header = styled.div`
    display: flex;
    margin-bottom: 20px;

    ${({ theme }) => theme.breakpoints.mobile} {
        flex-direction: column;
    }
`;

export const HeaderLeft = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const HeaderRight = styled.div`
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: start;

    ${({ theme }) => theme.breakpoints.mobile} {
        margin-top: 20px;
        justify-content: start;
    }
`;

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.color.black200};
    font-size: ${({ theme }) => theme.font.size.small};
`;

export const Body = styled.div<{ overflowBody: boolean }>`
    overflow-y: ${({ overflowBody }) => (overflowBody ? 'auto' : 'unset')};
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.mobile} {
        flex-direction: column;
    }
`;
