import { TableUpdateAttributes } from '@/services/innovorder/table/table.type';

export const findLastTable = (newRoomTables: TableUpdateAttributes[]): TableUpdateAttributes | null => {
    if (!newRoomTables.length) {
        return null;
    }

    const maxPositionY = Math.max(...newRoomTables.map((o) => o.positionY));
    const tablesWithMaxPositionY = newRoomTables.filter(({ positionY }) => positionY === maxPositionY);
    const maxPositionX = Math.max(...tablesWithMaxPositionY.map((o) => o.positionX));

    return tablesWithMaxPositionY.find(({ positionX }) => positionX === maxPositionX) || null;
};

export const DEFAULT_TABLE_SIZE = 144;
export const DEFAULT_SPACE_BETWEEN_TABLE = 12;
export const TABLE_PER_ROW = 10;

export const computeAddOneTable = (
    roomId: string | null,
    newTableName: string | null,
    roomTables: TableUpdateAttributes[],
): TableUpdateAttributes[] | null => {
    let newRoomTables = [...roomTables];

    if (!newTableName) {
        return null;
    }

    const lastTable = findLastTable(newRoomTables);

    const lastPositionX = DEFAULT_SPACE_BETWEEN_TABLE;
    const lastPositionY = lastTable
        ? lastTable.positionY + DEFAULT_TABLE_SIZE + DEFAULT_SPACE_BETWEEN_TABLE
        : DEFAULT_SPACE_BETWEEN_TABLE;

    newRoomTables = [
        ...newRoomTables.filter(({ name }) => name !== newTableName),
        {
            name: newTableName,
            positionX: lastPositionX,
            positionY: lastPositionY,
            width: DEFAULT_TABLE_SIZE,
            height: DEFAULT_TABLE_SIZE,
            ...(roomId ? { roomId } : {}),
        } as TableUpdateAttributes,
    ];

    return newRoomTables;
};

export const computeAddMultipleTables = (
    roomId: string | null,
    start: number | null,
    end: number | null,
    roomTables: TableUpdateAttributes[],
): TableUpdateAttributes[] | null => {
    let newRoomTables = [...roomTables];

    if (!start || !end) {
        return null;
    }

    let previousRow = 0;
    let tableAdded = 0;

    const lastTable = findLastTable(newRoomTables);
    const lastPositionX = DEFAULT_SPACE_BETWEEN_TABLE;
    const lastPositionY = lastTable
        ? lastTable.positionY + DEFAULT_TABLE_SIZE + DEFAULT_SPACE_BETWEEN_TABLE
        : DEFAULT_SPACE_BETWEEN_TABLE;

    for (let index = start; index <= end; index++) {
        const itemNumber = index - start;

        const row = Math.floor(itemNumber / TABLE_PER_ROW);

        if (previousRow < row) {
            tableAdded = 0;
        }

        newRoomTables = [
            ...newRoomTables.filter(({ name }) => name !== String(index)),
            {
                name: String(index),
                positionX: lastPositionX + tableAdded * (DEFAULT_TABLE_SIZE + DEFAULT_SPACE_BETWEEN_TABLE),
                positionY: lastPositionY + row * (DEFAULT_TABLE_SIZE + DEFAULT_SPACE_BETWEEN_TABLE),
                width: DEFAULT_TABLE_SIZE,
                height: DEFAULT_TABLE_SIZE,
                ...(roomId ? { roomId } : {}),
            } as TableUpdateAttributes,
        ];

        tableAdded += 1;

        previousRow = row;
    }

    return newRoomTables;
};
