import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import React, { useMemo } from 'react';
import { routes } from '@/core/router/routes';
import { Container, PermissionWrapper, PermissionText, ButtonWrapper } from './AccessFlagsControl.style';
import { Switch } from '@/components/atoms/Switch';
import { computeText } from '@/locales/utils';

import { Button } from '../../components/atoms/Button/Button';
import useAccessFlagsControlVM from './AccessFlagsControl.viewmodel';
import { useIntl } from 'react-intl';

const AccessFlagsControl = () => {
    const tabRoutes = useMemo(() => routes.filter(({ tab }) => tab === 'user'), []);
    const intl = useIntl();
    const { handleSave, displayedFlags, handleSwitch, isButtonDisabled, isButtonLoading } = useAccessFlagsControlVM();

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                {displayedFlags &&
                    Object.keys(displayedFlags).map((accessFlag) => {
                        return (
                            <PermissionWrapper>
                                <Switch
                                    onChange={() => {
                                        handleSwitch(accessFlag);
                                    }}
                                    value={displayedFlags[accessFlag]}
                                />
                                <PermissionText>{computeText(intl, `accessFlags.${accessFlag}`)}</PermissionText>
                            </PermissionWrapper>
                        );
                    })}
                <ButtonWrapper>
                    <Button
                        disabled={isButtonDisabled}
                        isLoading={isButtonLoading}
                        fullWidth={false}
                        onClick={handleSave}
                    >
                        {computeText(intl, 'button.save')}
                    </Button>
                </ButtonWrapper>
            </Container>
        </RouterNavigationTabs>
    );
};
export default AccessFlagsControl;
