import styled from '@emotion/styled';

export const OptionsModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    flex: 1;
    padding: ${({ theme }) => theme.spacing[4]};
    white-space: pre-line;
`;
OptionsModalBody.displayName = 'ModalBody';
