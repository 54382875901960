import React from 'react';
import { IntlShape } from 'react-intl';
import { computeText } from '@/locales/utils';
import { Input } from '@/components/form/Input';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import { SvyButton } from '@/components/savory/SvyButton';
import { SvyIcon } from '@/components/savory/SvyIcon';
import { Container, Line, Row } from './ReadingConditionsStep.style';
import { isInteger, isPositiveOrZero, validationPipe } from '@/utils/form/validate';
import { ConvertionType } from '@/services/innovorder/badgeReader/badgeReader.type';
import { enumValues } from '@/utils/typescript';
import { Select } from '@/components/form/Select';
import { useReadingConditionsStepVm } from './ReadingConditionsStep.viewmodel';

type ReadingConditionsStepProps = {};

const convertionsOptions = (intl: IntlShape) =>
    enumValues(ConvertionType).map(
        (label) =>
            ({ label: computeText(intl, `badgeReader.form.convertion.${label}`), value: label } as {
                label: string;
                value: ConvertionType;
            }),
    );

export const ReadingConditionsStep: React.FunctionComponent<ReadingConditionsStepProps> = () => {
    const { intl, fields, handleDragEnd, handleAddItem, handleRemoveItem } = useReadingConditionsStepVm();

    return (
        <Container>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Row>
                    <Title text="badgeReader.step.readingCondition" type="small" />
                    <SvyButton type="button" size="s" onClick={handleAddItem}>
                        {computeText(intl, 'badgeReader.step.readingCondition.add')}
                    </SvyButton>
                </Row>
                <Droppable droppableId="stepProduct">
                    {(providedDrop) => (
                        <div ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                            {fields.length > 0 && (
                                <Line>
                                    <div />
                                    <Text text="badgeReader.form.readingCondition.order" />
                                    <Text text="badgeReader.form.readingCondition.stringLength" />
                                    <Text text="badgeReader.form.readingCondition.convertBefore" />
                                    <Text text="badgeReader.form.readingCondition.substrPositionStart" />
                                    <Text text="badgeReader.form.readingCondition.substrLength" />
                                    <Text text="badgeReader.form.readingCondition.convertAfter" />
                                </Line>
                            )}
                            {fields.map((field, index) => (
                                <Draggable index={index} key={field.id} draggableId={field.id}>
                                    {(providedDrag) => (
                                        <Line ref={providedDrag.innerRef} {...providedDrag.draggableProps}>
                                            <div {...providedDrag.dragHandleProps}>
                                                <i className="ri-draggable" />
                                            </div>
                                            <Text text={field.order} noTranslation />
                                            <Input
                                                rules={{ validate: validationPipe(intl)(isInteger, isPositiveOrZero) }}
                                                type="number"
                                                name={`readingConditions[${index}].stringLength`}
                                            />
                                            <Select
                                                name={`readingConditions[${index}].convertBefore`}
                                                rules={{ required: computeText(intl, 'field.required') }}
                                                options={convertionsOptions(intl)}
                                            />

                                            <Input
                                                rules={{ validate: validationPipe(intl)(isInteger, isPositiveOrZero) }}
                                                type="number"
                                                name={`readingConditions[${index}].substrPositionStart`}
                                            />
                                            <Input
                                                rules={{ validate: validationPipe(intl)(isInteger, isPositiveOrZero) }}
                                                type="number"
                                                name={`readingConditions[${index}].substrLength`}
                                            />
                                            <Select
                                                name={`readingConditions[${index}].convertAfter`}
                                                rules={{ required: computeText(intl, 'field.required') }}
                                                options={convertionsOptions(intl)}
                                            />
                                            <SvyButton
                                                type="button"
                                                variant="text"
                                                size="s"
                                                color="destructive"
                                                elementLeft={<SvyIcon icon="delete-bin-line" />}
                                                onClick={() => handleRemoveItem(index)}
                                            >
                                                {computeText(intl, 'button.delete')}
                                            </SvyButton>
                                        </Line>
                                    )}
                                </Draggable>
                            ))}
                            {providedDrop.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Container>
    );
};
