import { UseFormReturn, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { StepData } from '@/components/atoms/Wizard/Wizard';
import { computeText } from '@/locales/utils';
import {
    useCreateBillingConfigurationMutation,
    useLazyFindBillingConfigurationByIdQuery,
    useUpdateBillingConfigurationMutation,
} from '@/services/innovorder/billing/configurations/configurations.endpoints';
import {
    useLazyFindModuleStripeByIdQuery,
    useUpdateModuleStripeByIdMutation,
} from '@/services/innovorder/module_stripes/module_stripes.endpoints';
import { SelectEntityFormValues } from './SelectEntityStep/SelectEntityStep.types';
import { SelectEntityStep } from './SelectEntityStep/SelectEntityStep';
import { EditConfigurationModalProps } from './EditConfigurationModal';
import { ConfigureBillingStep } from './ConfigureBillingStep/ConfigureBillingStep';
import { ConfigureBillingFormValues } from './ConfigureBillingStep/ConfigureBillingStep.types';

type FormValues = SelectEntityFormValues & ConfigureBillingFormValues;

export const useEditConfigurationModalVM = ({ hide }: EditConfigurationModalProps) => {
    const intl = useIntl();
    const [findModuleStripeById] = useLazyFindModuleStripeByIdQuery();
    const [findBillingConfigurationById] = useLazyFindBillingConfigurationByIdQuery();
    const [createBillingConfiguration] = useCreateBillingConfigurationMutation();
    const [updateBillingConfiguration] = useUpdateBillingConfigurationMutation();
    const [updateModuleStripeById] = useUpdateModuleStripeByIdMutation();
    const form: UseFormReturn<FormValues> = useForm<FormValues>({
        mode: 'all',
    });

    const formValuesKeys: (keyof FormValues)[] = useMemo(
        () => [
            'stripeModuleId',
            'beneficiaryName',
            'beneficiaryEmail',
            'beneficiaryAddress',
            'beneficiaryAddress2',
            'beneficiaryZipCode',
            'beneficiaryCity',
            'recipientEmails',
        ],
        [],
    );
    const watchedFormValues = form.watch(formValuesKeys);
    const stripeModuleId = form.watch('stripeModuleId');
    const [formValues, setFormValues] = useState<typeof watchedFormValues>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const doHide = useCallback(() => {
        form.reset();
        hide();
        setErrorMessage('');
    }, [hide, form]);

    const [steps, setSteps] = useState<StepData[]>([
        {
            title: computeText(intl, 'billing.editConfiguration.selectEntityStep.title'),
            component: <SelectEntityStep />,
            showHeader: false,
            showNext: true,
            isNextDisabled: true,
            showValidate: false,
            onCancel: () => doHide(),
        },
        {
            title: computeText(intl, 'billing.editConfiguration.configureBillingStep.title'),
            component: <ConfigureBillingStep />,
            showValidate: true,
            showPrevious: false,
            showHeader: false,
            onCancel: () => doHide(),
        },
    ]);

    const handleGenericError = useCallback(() => {
        setErrorMessage(computeText(intl, 'billing.editConfiguration.configureBillingStep.genericErrorMessage'));
        setLoading(false);
        return false;
    }, [intl]);

    const onFormValidate = useCallback(async () => {
        setLoading(true);

        const { getValues } = form;
        const values = getValues();

        const { data: moduleStripe } = await findModuleStripeById({
            moduleStripeId: values.stripeModuleId,
        });

        if (!moduleStripe) {
            return handleGenericError();
        }

        const billingConfigurationId = moduleStripe?.billingConfigurationId;

        if (billingConfigurationId) {
            const response = await updateBillingConfiguration({
                ...values,
                billingConfigurationId,
            });

            if (!('data' in response)) {
                return handleGenericError();
            }
        } else {
            const createBillingConfigurationResponse = await createBillingConfiguration(values);

            if (!('data' in createBillingConfigurationResponse)) {
                return handleGenericError();
            }

            await updateModuleStripeById({
                moduleStripeId: values.stripeModuleId,
                moduleStripe: {
                    ...moduleStripe,
                    billingConfigurationId: createBillingConfigurationResponse.data.id,
                },
            });

            if (!('data' in createBillingConfigurationResponse)) {
                return handleGenericError();
            }
        }

        doHide();
        setLoading(false);
        return true;
    }, [
        form,
        doHide,
        findModuleStripeById,
        createBillingConfiguration,
        updateBillingConfiguration,
        updateModuleStripeById,
        handleGenericError,
    ]);

    useEffect(() => {
        const arrayCompare = (a: typeof formValues, b: typeof watchedFormValues) => {
            if (a.length !== b.length) {
                return false;
            }
            return a.every((el, i) => el === b[i]);
        };

        if (!arrayCompare(formValues, watchedFormValues)) {
            setSteps((prevSteps) => {
                const newSteps = [...prevSteps];
                newSteps[0].isNextDisabled = !form.getValues().stripeModuleId;
                return newSteps;
            });
            setFormValues(watchedFormValues);
        }
    }, [watchedFormValues, formValues, form]);

    const loadStripeModule = useCallback(async () => {
        const { data: moduleStripe } = await findModuleStripeById({ moduleStripeId: stripeModuleId });
        if (!moduleStripe || !moduleStripe.billingConfigurationId) return;

        const { data: billingConfiguration } = await findBillingConfigurationById(moduleStripe.billingConfigurationId);
        if (!billingConfiguration) return;

        form.setValue('beneficiaryName', billingConfiguration.beneficiaryName);
        form.setValue('beneficiaryEmail', billingConfiguration.beneficiaryEmail);
        form.setValue('beneficiaryAddress', billingConfiguration.beneficiaryAddress);
        form.setValue('beneficiaryAddress2', billingConfiguration.beneficiaryAddress2);
        form.setValue('beneficiaryZipCode', billingConfiguration.beneficiaryZipCode);
        form.setValue('beneficiaryCity', billingConfiguration.beneficiaryCity);
        form.setValue(
            'recipientEmails',
            billingConfiguration.recipients.map((recipient) => recipient.email),
        );
    }, [findModuleStripeById, findBillingConfigurationById, stripeModuleId, form]);

    useEffect(() => {
        if (!stripeModuleId) {
            return;
        }

        loadStripeModule();
    }, [stripeModuleId, loadStripeModule]);

    return {
        form,
        steps,
        currentStep: 0,
        isLoading,
        errorMessage,
        onFormValidate,
    };
};
