import { useParams } from 'react-router';
import { useGetPosAccessUserByIdQuery } from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.endpoints';
import { PostPosAccessUserBody } from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.type';
import { useGetPosAccessRolesQuery } from '@/services/innovorder/pos_access/pos_access_role/pos_access_role.endpoints';
import { PosAccessRole } from '@/services/innovorder/pos_access/pos_access_role/pos_access_role.type';
import { useMemo } from 'react';

export type MenuRolesViewModel = {
    posUser: PostPosAccessUserBody | null;
    posRoles: PosAccessRole[];
    isLoading: boolean;
};

type ParamsTypes = {
    restaurantId: string;
    brandId: string;
    userId: string;
};

export function usePosUserFormVM(): MenuRolesViewModel {
    const { restaurantId, userId } = useParams<ParamsTypes>();
    const { data: user = null, isLoading: isUserLoading } = userId
        ? useGetPosAccessUserByIdQuery({
              restaurantId: +restaurantId,
              posUserId: +userId,
          })
        : { data: null, isLoading: false };
    const { data: posRoles = [], isLoading: isRolesLoading } = useGetPosAccessRolesQuery({
        restaurantId: +restaurantId,
    });

    const isLoading = isUserLoading || isRolesLoading;
    const posUser = useMemo(() => {
        if (!user) return null;
        return {
            name: user.name,
            pinCode: user.pinCode,
            posRoleId: user.posRoleId,
            restaurantId: user.restaurantId,
        };
    }, [user]);
    return {
        posUser,
        posRoles,
        isLoading,
    };
}
