import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/components/atoms/Button';
import {
    MarketplaceChannelName,
    Location,
    MarketplaceChannel,
    MarketplaceChannelId,
} from '@/services/innovorder/marketplace/marketplace.type';
import MarketplaceItem from '../MarketplaceItem';
import {
    Container,
    Header,
    LocationHead,
    Title,
    LocationName,
    MarketplacesPanel,
    LocationVat,
    LocationBody,
} from './LocationPanel.style';

const channels: (MarketplaceChannel & { name: string })[] = [
    { id: MarketplaceChannelId.UberEats, enable: true, name: 'ubereats' },
    { id: MarketplaceChannelId.Deliveroo, enable: true, name: 'deliveroo' },
    { id: MarketplaceChannelId.JustEat, enable: true, name: 'justeat' },
];

type LocationPanelProps = {
    counter: number;
    location: Location;
    marketplace: MarketplaceChannelName;
    onClick: (locationId: string, id: MarketplaceChannelId) => void;
    onDelete: (locationId: string) => void;
};

const LocationPanel: React.FunctionComponent<React.PropsWithChildren<LocationPanelProps>> = ({
    location,
    marketplace,
    onClick,
    onDelete,
}) => {
    const getState = (channel: MarketplaceChannel) => {
        if (!channel.enable) return 'disabled';
        const locationMarketplace = location.marketplaces.find(({ id }) => channel.id === id);

        if (locationMarketplace && locationMarketplace.active) return 'active';
        return 'inactive';
    };
    const handleClick = useCallback(
        (marketplaceId: MarketplaceChannelId) => {
            onClick(location.id, marketplaceId);
        },
        [location.id, onClick],
    );
    const handleDelete = useCallback(() => {
        onDelete(location.id);
    }, [location.id, onDelete]);

    return (
        <Container>
            <Header>
                <LocationHead>
                    <Title type="small" weight="bold" text={location.name || location.id} />
                    <LocationBody>
                        <LocationName>{location.id}</LocationName>
                        {Number(location.vatDefault) > 0 && (
                            <LocationVat>
                                {location.vatDefault}% <FormattedMessage id="marketplace.vatdefault.message" />
                            </LocationVat>
                        )}
                    </LocationBody>
                </LocationHead>
                <Button buttonType="red" onClick={handleDelete}>
                    <FormattedMessage id="marketplace.location.delete" />
                </Button>
            </Header>
            <MarketplacesPanel>
                {channels
                    .filter((channel) => {
                        const channelNotIntegrator = channels.find((c) => c.name === marketplace);
                        return channelNotIntegrator ? channel.id === channelNotIntegrator.id : true;
                    })
                    .map((channel) => {
                        const state = getState(channel);
                        return <MarketplaceItem key={channel.id} onClick={handleClick} id={channel.id} state={state} />;
                    })}
            </MarketplacesPanel>
        </Container>
    );
};
export default LocationPanel;
