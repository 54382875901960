export const computeBreakpoint = (breakPoint: string): number => {
    const result = breakPoint.match(/\d+/);

    if (!result) {
        return 0;
    }

    return parseInt(result[0], 10);
};

export const getHexColorFromDecimal = (decimalColor: string): string => {
    let rgbColor = parseInt(decimalColor, 10);
    rgbColor &= 0x0000000000ffffff;

    return `#${rgbColor.toString(16).padStart(6, '0').toUpperCase()}`;
};

export const getDecimalColorFromHex = (hexColor: string): string => {
    const hexWithoutHash = hexColor.replace('#', ' ');
    let hexValue = parseInt(hexWithoutHash, 16);
    hexValue |= 0x00000000ff000000;

    return `${hexValue}`.toUpperCase();
};
