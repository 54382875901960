import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Section } from '@/components/atoms/Section';
import { Title } from '@/components/atoms/Title';
import { Table } from '@/components/atoms/Table/Table';
import useDownloadReportGenerationFile from '@/hooks/reportGenerationsHistory';
import { useGetReportGenerationsQuery } from '@/services/innovorder/reportGeneration/reportGeneration.endpoints';
import { getUserToken } from '@/redux/user';
import { Button } from '@/components/atoms/Button';
import { getReportGenerationsHistoryTableRowsVM, getReportGenerationsHistoryTableColumns } from './utils';
import { Container, Header, TitleContainer, Subtitle } from './ReportGenerationsHistory.style';

const ReportGenerationsHistory: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const token = useSelector(getUserToken);
    const downloadReportGenerationFile = useDownloadReportGenerationFile();

    const {
        data: reportGenerationsHistoryData,
        isLoading,
        refetch,
    } = useGetReportGenerationsQuery(
        { token },
        { refetchOnMountOrArgChange: true, pollingInterval: 30 * 1000 }, // automatic status refresh every 30s
    );

    const reportGenerationsHistoryTableRows = getReportGenerationsHistoryTableRowsVM(
        intl,
        reportGenerationsHistoryData ?? [],
        downloadReportGenerationFile,
    );

    const reportGenerationsHistoryTableColumns = getReportGenerationsHistoryTableColumns(intl);

    const refreshGenerationsData = () => {
        refetch();
    };

    return (
        <Container>
            <Header>
                <TitleContainer>
                    <Title text="reportGenerationsHistory.title" />
                    <Subtitle text="reportGenerationsHistory.subtitle" />
                </TitleContainer>
            </Header>
            <Section
                title={'reportGenerationsHistory.tableCard.title'}
                subtitle={'reportGenerationsHistory.tableCard.subtitle'}
                actions={
                    <Button buttonType="primary" onClick={refreshGenerationsData}>
                        <FormattedMessage id={'reportGenerationsHistory.tableCard.actionLabel'} />
                    </Button>
                }
            >
                <Table
                    rows={reportGenerationsHistoryTableRows}
                    columns={reportGenerationsHistoryTableColumns}
                    emptyMessageId="reportGenerationsHistory.emptyMessage"
                    isSortable={false}
                    hasPagination
                    hasUrlNavigation
                    hasFirstAndLastPage
                    isLoading={isLoading}
                />
            </Section>
        </Container>
    );
};

export default ReportGenerationsHistory;
