import { useState, useEffect, useCallback } from 'react';
import { useGetConsumptionModesQuery } from '@/services/innovorder/consumptionMode/consumptionMode.endpoints';
import {
    useGetPaymentMethodsQuery,
    useBulkUpdatePaymentMethodsMutation,
} from '@/services/innovorder/paymentMethod/paymentMethod.endpoints';
import { useGetRestaurantModulesQuery } from '@/services/innovorder/restaurant/restaurant.endpoints';
import { useLazyGetExternalIdsQuery } from '@/services/innovorder/externalIds/externalIds.endpoints';
import {
    PaymentMethod,
    CreatePaymentMethodPayload,
    UpdatePaymentMethodPayload,
    DeletePaymentMethodPayload,
} from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { isEmpty } from 'lodash';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { CONSUMPTION_MODE_FLAGS } from '@/services/innovorder/consumptionMode/consumptionMode.type';

export const usePaymentMethods = (channelId: ChannelId, restaurantId: string, brandId?: number) => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [bulkUpdatePaymentMethods, { isLoading: isLoadingSave }] = useBulkUpdatePaymentMethodsMutation();
    const { data: dataRestaurantModules, isFetching: isFetchingRestaurantModules } = useGetRestaurantModulesQuery({
        restaurantId,
    });
    const {
        data: dataPaymentMethods,
        isLoading: isLoadingPaymentMethods,
        refetch,
    } = useGetPaymentMethodsQuery({
        channelId,
        restaurantId,
    });
    const [getExternalIds, { data: dataExternalIds, isLoading: isLoadingExternalIds }] = useLazyGetExternalIdsQuery();
    const { data: dataConsumptionModes, isLoading: isLoadingConsumptionModes } = useGetConsumptionModesQuery({
        channelId,
        restaurantId,
    });

    const resetPaymentMethods = useCallback(() => {
        setPaymentMethods(dataPaymentMethods ?? []);
        getExternalIds({
            mappingKey: 'paymentMethodId',
            internalValues: (dataPaymentMethods ?? []).map(({ paymentMethodId }) => paymentMethodId).join(','),
        });
    }, [dataPaymentMethods, getExternalIds]);

    const enrichePaymentMethods = useCallback(() => {
        if (!isEmpty(dataExternalIds)) {
            const enrichedPaymentMethods = paymentMethods.map((pm) => {
                if (!pm?.paymentMethodId) return pm;
                return {
                    externalId: dataExternalIds[pm.paymentMethodId],
                    ...pm,
                };
            });
            setPaymentMethods(enrichedPaymentMethods);
        }
    }, [dataExternalIds, paymentMethods]);

    const onChange = (pm: any) => {
        setPaymentMethods(pm);
    };

    const paymentMethodFetched = JSON.stringify(dataPaymentMethods);

    const onSave = async () => {
        const updates: UpdatePaymentMethodPayload[] = paymentMethods
            .filter(({ action }) => action === 'update')
            .map((pm) => {
                const consumptionModesFlags = pm.consumptionModes.map(
                    ({ type }) => CONSUMPTION_MODE_FLAGS[`FLAG_${type}`],
                );
                return {
                    id: pm.paymentMethodId,
                    paymentMethod: pm.paymentMethod,
                    active: pm.active,
                    paymentMethodId: pm.paymentMethodId,
                    code: pm.code,
                    label: pm.label,
                    position: pm.position,
                    externalId: pm.externalId,
                    channelId,
                    brandId: brandId || 0,
                    restaurantId: parseInt(restaurantId, 10),
                    consumptionMode:
                        pm.consumptionModeSumFlag ||
                        consumptionModesFlags.reduce((accumulator, curr) => accumulator + curr, 0),
                    consumptionModes: pm.consumptionModesFlags || consumptionModesFlags,
                };
            });
        const creates: CreatePaymentMethodPayload[] = paymentMethods
            .filter(({ action }) => action === 'create')
            .map((pm) => {
                const consumptionModesFlags = pm.consumptionModes.map(
                    ({ type }) => CONSUMPTION_MODE_FLAGS[`FLAG_${type}`],
                );
                return {
                    code: pm.code,
                    label: pm.label,
                    position: pm.position,
                    externalId: pm.externalId,
                    changeNotAllowed: !!pm.changeNotAllowed,
                    creditNoteAllowed: !!pm.creditNoteAllowed,
                    directCollectionAllowed: !!pm.directCollectionAllowed,
                    openCashDrawer: !!pm.openCashDrawer,
                    defaultPayment: !!pm.defaultPayment,
                    consumptionMode:
                        pm.consumptionModeSumFlag ||
                        consumptionModesFlags.reduce((accumulator, curr) => accumulator + curr, 0),
                    consumptionModes: pm.consumptionModesFlags || consumptionModesFlags,
                };
            });
        const deletes: DeletePaymentMethodPayload[] = paymentMethods
            .filter(({ action }) => action === 'delete')
            .map((pm) => ({
                paymentMethodId: pm.paymentMethodId,
            }));

        await bulkUpdatePaymentMethods({
            channelId,
            restaurantId,
            creates,
            updates,
            deletes,
        });
        refetch();
        getExternalIds({
            mappingKey: 'paymentMethodId',
            internalValues: (dataPaymentMethods ?? []).map(({ paymentMethodId }) => paymentMethodId).join(','),
        });
    };

    useEffect(() => {
        paymentMethodFetched && resetPaymentMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethodFetched]);

    useEffect(() => {
        dataExternalIds && enrichePaymentMethods();
    }, [dataExternalIds, enrichePaymentMethods]);

    return {
        isLoading: isLoadingPaymentMethods || isFetchingRestaurantModules || isLoadingExternalIds,
        isLoadingConsumptionModes,
        paymentMethods,
        restaurantModules: dataRestaurantModules,
        consumptionModes: dataConsumptionModes ?? [],
        isLoadingSave,
        onChange,
        onSave,
        resetPaymentMethods,
    };
};
