import { useDispatch } from 'react-redux';
import * as React from 'react';
import { removePathName, setResolvedPathName } from '@/redux/app';

/**
 * Register a path name to the store to be used in the breadcrumbs
 * @param path route path defined in the config
 * @param name text to be displayed in the breadcrumbs
 */
export const useRegisterPathName = (path: string, name: string | undefined | null) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (name) {
            dispatch(setResolvedPathName({ path, name }));
        }
        return () => {
            if (name) {
                dispatch(removePathName(path));
            }
        };
    }, [dispatch, name, path]);
};
