import { useTheme } from '@emotion/react';
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { generatePath, useHistory } from 'react-router';
import {
    Cell,
    CellProps,
    Column,
    Filters,
    HeaderProps,
    IdType,
    PluginHook,
    Row,
    SortingRule,
    TableState,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';

import { getParamFromQueryString, replaceParamInUrl } from '@/utils/history';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { TableFooter } from './TableFooter';
import { DraggableTable } from './DraggableTable';
import { NormalTable } from './NormalTable';
import { TableContainer, TableEmptyState, TableLoader, TableWrap } from './Table.style';
import { TableCell, TableRow } from './Table.type';
import { TableHeader } from './TableHeader';
import { TablePagination } from './TablePagination';
import { TableToolbar } from './TableToolbar';
import { defaultColumn, handleTableFilter, sortTypes } from './Table.utils';
import { Checkbox, CheckboxProps } from '../Checkbox';

export type fetchDataProps = {
    pageIndex?: number;
    pageSize?: number;
    globalFilter?: string;
    sortBy?: SortingRule<TableRow>[];
    columnFilters: Filters<TableRow>;
};

export type TableProps = {
    rows: TableRow[];
    columns: readonly Column<TableRow>[];
    emptyMessageId: string;
    onRowClickPath?: string;
    onRowClick?: (cell?: Cell<TableRow, TableCell>, event?: React.MouseEvent<HTMLTableCellElement>) => void;
    reorderAfterDrag?: (sourceId: number, destinationId: number) => void;
    columnSelectorId?: string;
    defaultHiddenColumns?: string[];
    isSortable?: boolean;
    defaultSortBy?: SortingRule<TableRow>[];
    defaultSelectedRowIds?: Record<string, boolean>;
    isSearchable?: boolean;
    searchPlaceholder?: string;
    filters?: string[];
    isSelectable?: boolean;
    isFilterable?: boolean;
    toolbarButtons?: React.ReactElement[] | ((state: TableState) => React.ReactElement[]);
    hasPagination?: boolean;
    hasFirstAndLastPage?: boolean;
    hasUrlNavigation?: boolean;
    emptyTableChildren?: React.ReactNode;
    fetchData?: (props: fetchDataProps) => void;
    isLoading?: boolean;
    fetchDataTotalCount?: number;
    tableRef?: React.RefObject<TableState<TableRow>>;
    canSelectAll?: boolean;
    initialPageSize?: number;
    pageSizeOptions?: number[];
    hasFooter?: boolean;
    title?: string;
};

export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_SIZE = 10;

const EMPTY_ARRAY: string[] = [];
const EMPTY_OBJECT = {} as const;
const EMPTY_ROWS: TableRow[] = [];
const InnerTable: React.FunctionComponent<React.PropsWithChildren<TableProps>> = ({
    rows,
    columns,
    emptyMessageId,
    onRowClickPath,
    onRowClick,
    reorderAfterDrag,
    columnSelectorId,
    defaultHiddenColumns = EMPTY_ARRAY,
    canSelectAll = true,
    defaultSelectedRowIds = EMPTY_OBJECT,
    isSortable = true,
    defaultSortBy,
    isSearchable,
    searchPlaceholder,
    filters,
    isFilterable = false,
    toolbarButtons: _toolbarButtons,
    hasPagination,
    isSelectable = false,
    hasFirstAndLastPage = true,
    hasUrlNavigation = false,
    emptyTableChildren,
    fetchData,
    isLoading,
    fetchDataTotalCount = 0,
    tableRef,
    initialPageSize = DEFAULT_PAGE_SIZE,
    pageSizeOptions,
    hasFooter = false,
    title,
}) => {
    const theme = useTheme();
    const currentPageSize = React.useRef<number>(initialPageSize);
    const history = useHistory();

    const handleGlobalFilter = React.useCallback(
        (rows: Row<TableRow>[], _: IdType<TableRow>[], query: string) =>
            matchSorter(rows, query, {
                threshold: matchSorter.rankings.CONTAINS,
                keys: handleTableFilter(rows, filters),
                sorter: (rankedItems) => rankedItems,
            }),
        [filters],
    );

    // For fetched data, react-table cannot calculate pageCount internally
    const pageCount = React.useMemo(
        () => (fetchData ? Math.ceil(fetchDataTotalCount / (currentPageSize.current || DEFAULT_PAGE_SIZE)) : undefined),
        [fetchData, fetchDataTotalCount],
    );

    const defaultSelectedColumns = !defaultHiddenColumns?.length
        ? []
        : columns.flatMap((column) =>
              defaultHiddenColumns.includes(String(column.accessor)) ? [] : [String(column.accessor)],
          );
    const { value: selectedColumns, setInLocalStorage: setSelectedColumns } = useLocalStorage<string[]>(
        columnSelectorId || '',
        defaultSelectedColumns,
    );

    const displayedColumns = React.useMemo(() => {
        const filteredColumns = columns.filter(
            (column) =>
                typeof column.accessor !== 'string' ||
                !selectedColumns?.length ||
                selectedColumns.includes(column.accessor),
        );

        if (isSelectable) {
            const SelectCell = ({ row, getCheckboxProps = () => ({}) }: CellProps<any>) => {
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            {...(row.getToggleRowSelectedProps ? row.getToggleRowSelectedProps() : {})}
                            {...getCheckboxProps(row)}
                            disabled={row.original?.disabled?.value}
                        />
                    </div>
                );
            };
            const SelectHeader = ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) =>
                canSelectAll ? <Checkbox {...(getToggleAllRowsSelectedProps() as CheckboxProps)} /> : null;
            filteredColumns.unshift({
                id: 'selection',
                Header: SelectHeader,
                Cell: SelectCell,
                maxWidth: 20,
                disableSortBy: true,
            });
        }
        return filteredColumns;
    }, [canSelectAll, isSelectable, columns, selectedColumns]);

    const defaultPageIndex =
        (hasUrlNavigation && Number(getParamFromQueryString(history.location.search, 'page'))) || DEFAULT_PAGE_INDEX;
    const defaultPageSize =
        (hasUrlNavigation && Number(getParamFromQueryString(history.location.search, 'pagesize'))) ||
        currentPageSize.current;
    const urlSort = hasUrlNavigation && getParamFromQueryString(history.location.search, 'sort');
    const defaultSort = React.useMemo(
        () => (urlSort ? JSON.parse(String(urlSort)) : defaultSortBy || []),
        [urlSort, defaultSortBy],
    );
    const defaultGlobalFilter = React.useMemo(
        () => (hasUrlNavigation && getParamFromQueryString(history?.location?.search, 'gf')) || undefined,
        [history, hasUrlNavigation],
    );
    const defaultColumnFilter = React.useMemo(
        () => (hasUrlNavigation && getParamFromQueryString(history?.location?.search, 'cf')) || undefined,
        [history, hasUrlNavigation],
    );
    const getDefaultColumnFilter = React.useCallback(
        () => (defaultColumnFilter ? { filters: JSON.parse(defaultColumnFilter as string) } : undefined),
        [defaultColumnFilter],
    );

    const plugins: PluginHook<any>[] = React.useMemo(() => {
        const innerPlugins: PluginHook<any>[] = [useGlobalFilter, useFilters, useSortBy, usePagination];
        if (isSelectable) {
            innerPlugins.push(useRowSelect);
        }
        return innerPlugins;
    }, [isSelectable]);

    const initialState = React.useMemo(
        () => ({
            pageIndex: defaultPageIndex,
            pageSize: defaultPageSize,
            sortBy: defaultSort,
            globalFilter: defaultGlobalFilter,
            selectedRowIds: defaultSelectedRowIds,
            ...getDefaultColumnFilter(),
        }),
        [
            defaultGlobalFilter,
            defaultPageIndex,
            defaultPageSize,
            defaultSelectedRowIds,
            defaultSort,
            getDefaultColumnFilter,
        ],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        gotoPage,
        state,
        footerGroups,
    } = useTable(
        {
            columns: displayedColumns,
            defaultColumn,
            data: rows.length ? rows : EMPTY_ROWS,
            getRowId: (row, defaultId) =>
                `${Object.values(row).find((value) => value.type === 'identifier')?.value ?? defaultId}`,
            disableFilters: !isFilterable,
            globalFilter: handleGlobalFilter,
            initialState,
            sortTypes,
            autoResetGlobalFilter: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetPage: false,
            manualSortBy: Boolean(fetchData),
            manualPagination: Boolean(fetchData),
            manualGlobalFilter: Boolean(fetchData),
            manualFilters: Boolean(fetchData),
            // Bug if we pass pageCount: undefined => local pagination need 2 click to be effective
            // And if we pass -1, last page is not accessible anymore (fixed in react-table v8 ?)
            ...(pageCount === undefined ? undefined : { pageCount }),
        },
        ...plugins,
    );

    React.useImperativeHandle(tableRef, () => state, [state]);

    const { pageIndex, pageSize, globalFilter, sortBy, filters: columnFilters } = state;
    currentPageSize.current = pageSize;

    React.useEffect(() => {
        if (!hasUrlNavigation) return;
        replaceParamInUrl(history, 'page', String(pageIndex));
    }, [history, hasUrlNavigation, pageIndex]);

    React.useEffect(() => {
        if (!hasUrlNavigation) return;
        replaceParamInUrl(history, 'pagesize', String(pageSize));
    }, [history, hasUrlNavigation, pageSize]);

    React.useEffect(() => {
        if (!hasUrlNavigation) return;
        replaceParamInUrl(history, 'sort', JSON.stringify(sortBy));
    }, [history, hasUrlNavigation, sortBy]);

    React.useEffect(() => {
        if (!hasUrlNavigation) return;
        replaceParamInUrl(history, 'gf', globalFilter);
    }, [history, hasUrlNavigation, globalFilter]);

    React.useEffect(() => {
        if (!hasUrlNavigation) return;
        replaceParamInUrl(
            history,
            'cf',
            columnFilters && columnFilters.length ? JSON.stringify(columnFilters) : undefined,
        );
    }, [history, hasUrlNavigation, columnFilters]);

    const handleOnFilterChange = React.useCallback(
        (filter: string): void => {
            setGlobalFilter(filter || undefined);
            gotoPage(0);
        },
        [gotoPage, setGlobalFilter],
    );

    const handleClearFilters = React.useCallback((): void => {
        headerGroups.forEach((headerGroup) => headerGroup.headers.forEach((column) => column.setFilter(undefined)));
    }, [headerGroups]);

    React.useEffect(() => {
        if (fetchData) {
            fetchData({ pageSize, pageIndex, globalFilter, sortBy, columnFilters });
        }
    }, [pageSize, pageIndex, globalFilter, sortBy, columnFilters, fetchData]);

    const isCellClickable = (cell: Cell<TableRow, TableCell>): boolean => {
        if (onRowClick !== null) {
            return true;
        }
        return (
            !!onRowClickPath &&
            cell.value.type !== 'action' &&
            cell.value.type !== 'switch' &&
            cell.value.type !== 'dropdownText' &&
            !!cell.row.original.linkTo
        );
    };

    const handleOnCellClick = (
        cell: Cell<TableRow, TableCell>,
        event?: React.MouseEvent<HTMLTableCellElement>,
    ): void => {
        if (onRowClick != null) {
            return onRowClick(cell, event);
        }
        if (!onRowClickPath) return;
        const path = generatePath(onRowClickPath, cell.row.original.linkTo?.value as Record<string, string>);
        const shouldRedirect =
            cell.value?.type !== 'action' &&
            cell.value?.type !== 'switch' &&
            cell.value?.type !== 'dropdownText' &&
            !!cell.row.original.linkTo;

        if (shouldRedirect) event?.ctrlKey ? window.open(path) : history.push(path);
    };

    const handleDragEnd = (result: DropResult): void => {
        if (!reorderAfterDrag) return;

        const { source, destination } = result;

        if (!destination) return;

        reorderAfterDrag(source.index, destination.index);
    };

    const goFirstPage = () => gotoPage(0);

    const goLastPage = () => gotoPage(pageOptions.length - 1);

    const hasFilter = Boolean(globalFilter || columnFilters.length);
    const toolbarButtons = typeof _toolbarButtons === 'function' ? _toolbarButtons(state) : _toolbarButtons;
    const hasToolbar = isSearchable || Boolean(toolbarButtons?.length) || columnSelectorId || hasFilter || title;

    return (
        <TableContainer data-testid="table-container">
            {hasToolbar && (
                <TableToolbar
                    selectedLinesCount={isSelectable ? Object.keys(state.selectedRowIds).length : undefined}
                    onChange={isSearchable ? handleOnFilterChange : undefined}
                    defaultFilter={String(defaultGlobalFilter || '')}
                    placeholder={searchPlaceholder || 'table.search'}
                    buttons={toolbarButtons}
                    onClearFilters={hasFilter ? handleClearFilters : undefined}
                    onColumnDisplayChange={columnSelectorId ? setSelectedColumns : undefined}
                    columns={columns}
                    selectedColumns={selectedColumns || []}
                    title={title}
                />
            )}
            <TableWrap>
                <table {...getTableProps()}>
                    <TableHeader
                        isSortable={isSortable}
                        headerGroups={headerGroups}
                        reorderAfterDrag={reorderAfterDrag}
                    />
                    {!isLoading && Boolean(pageOptions?.length) && (
                        <>
                            {reorderAfterDrag ? (
                                <DraggableTable
                                    page={page}
                                    emptyMessageId={emptyMessageId}
                                    prepareRow={prepareRow}
                                    isCellClickable={isCellClickable}
                                    handleOnCellClick={handleOnCellClick}
                                    handleDragEnd={handleDragEnd}
                                />
                            ) : (
                                <NormalTable
                                    page={page}
                                    prepareRow={prepareRow}
                                    getTableBodyProps={getTableBodyProps}
                                    isCellClickable={isCellClickable}
                                    handleOnCellClick={handleOnCellClick}
                                />
                            )}
                            {hasFooter && <TableFooter footerGroups={footerGroups} />}
                        </>
                    )}
                </table>
                {isLoading && <TableLoader color={theme.color.primary} size={40} withContainer />}
                {!isLoading && Boolean(!pageOptions?.length) && (
                    <>
                        <TableEmptyState emptyMessageId={globalFilter ? 'table.noRowsAfterFilter' : emptyMessageId}>
                            {emptyTableChildren}
                        </TableEmptyState>
                    </>
                )}
            </TableWrap>
            {hasPagination && Boolean(pageOptions?.length) && (
                <TablePagination
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pageSizeOptions={pageSizeOptions}
                    pageOptions={pageOptions}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    goFirstPage={goFirstPage}
                    goLastPage={goLastPage}
                    setPageSize={setPageSize}
                    hasFirstAndLastPage={hasFirstAndLastPage}
                />
            )}
        </TableContainer>
    );
};
export const Table = React.memo(InnerTable);
