import 'react-datepicker/dist/react-datepicker.css';

import styled from '@emotion/styled';

export const CssOverrides = styled.div`
    // BODY
    .react-datepicker {
        display: flex;
        gap: ${({ theme }) => theme.spacing[4]};
        justify-content: space-around;
        background-color: ${({ theme }) => theme.color.white200};
        border: 1px solid ${({ theme }) => theme.color.grey200};

        &__month-container {
            border: none;
        }
    }

    // CELLS
    .react-datepicker__day {
        outline: none;
        border-radius: 0;
        margin: 1px;
        width: 40px;
        height: 40px;
        color: ${({ theme }) => theme.color.black300};
        font-weight: ${({ theme }) => theme.font.weight.bold};
        font-size: ${({ theme }) => theme.font.size.normal};
        color: ${({ theme }) => theme.color.black};
        line-height: 20px;

        &--in-range,
        &--selected,
        &--in-selecting-range {
            background-color: ${({ theme }) => theme.color.grey200};
        }

        &--selected,
        &--range-start,
        &--selecting-range-start,
        &--selecting-range-end,
        &--range-end,
        &:hover {
            background-color: ${({ theme }) => theme.color.slate300};
            color: ${({ theme }) => theme.color.white100};
        }

        &:hover {
            border-radius: 0;
        }

        &--outside-month {
            height: 0;
            opacity: 0;
        }

        &--excluded,
        &--disabled {
            opacity: 0.4;
        }

        &--range-start,
        &--selecting-range-start {
            border-top-left-radius: ${({ theme }) => theme.radius.main};
        }

        &--range-end,
        &--selecting-range-end {
            border-bottom-right-radius: ${({ theme }) => theme.radius.main};
        }

        &-names {
            margin-top: ${({ theme }) => theme.spacing[2]};
        }
        &-name {
            color: ${({ theme }) => theme.color.black300};
            font-size: ${({ theme }) => theme.font.size.small};
            width: 38px;
        }
    }

    // HEADER
    .react-datepicker__header {
        background-color: ${({ theme }) => theme.color.white200};
        font-family: ${({ theme }) => theme.font.family};
        text-align: left;
        border-bottom: none;
    }

    .react-datepicker__current-month {
        padding-left: ${({ theme }) => theme.spacing[3]};
        color: ${({ theme }) => theme.color.black};
        font-size: ${({ theme }) => theme.font.size.medium};
        font-weight: ${({ theme }) => theme.font.weight.medium};
        line-height: 24px;
        text-transform: capitalize;
    }

    .react-datepicker__day-name {
        ${({ theme }) => theme.font.caption.regular};
        line-height: 20px;
    }

    .react-datepicker__navigation {
        outline: none;

        &--previous {
            border-right-color: ${({ theme }) => theme.color.black};
            right: ${({ theme }) => theme.spacing[5]};
            left: initial;
        }

        &--next {
            border-left-color: ${({ theme }) => theme.color.black};
        }
    }
`;
