import React from 'react';
import Tabs from '@/components/organisms/Tabs/Tabs';
import Tab from '@/components/organisms/Tabs/Tab';
import { RouteDeclaration } from '@/core/router/routes.types';
import { Container } from './RouterNavigationTabs.style';
import { useResolveRoutesPathAndUriFromParams } from '@/hooks/routes/useResolveRoutesPathAndUriFromParams';
import { useFilterRoutes } from '@/hooks/routes/useFilterRoutes';

type RouterNavigationTabsProps = {
    routes: RouteDeclaration<any>[];
    /**
     * Allows to add additional params to the route if the `useParams`
     * hook is not enough to resolve params
     */
    additionalParams?: Record<string, string>;
    /**
     * Should be always true for new tabs
     */
    isRelative?: boolean;
};

const RouterNavigationTabs: React.FunctionComponent<React.PropsWithChildren<RouterNavigationTabsProps>> = ({
    routes,
    additionalParams,
    children,
    isRelative,
}) => {
    const resolvedTabRoutes = useResolveRoutesPathAndUriFromParams(routes, {
        isRelative,
        otherParams: additionalParams,
    });

    const filterRoutes = useFilterRoutes(resolvedTabRoutes);
    return (
        <Container>
            {/* Remount component as it doesn't handle when children are changed */}
            <Tabs key={filterRoutes.length}>
                {filterRoutes
                    .sort((a, b) => (a.tabIndex ?? Infinity) - (b.tabIndex ?? Infinity))
                    .map((route) => (
                        <Tab title={route.name} route={route} key={route.name} />
                    ))}
            </Tabs>
            {children}
        </Container>
    );
};

export default RouterNavigationTabs;
