import { generateQueryStringFromParams } from '@/utils/history';
import { ApiTagTypes, innovorderApi } from '../index';
import { ApiResponse } from '../request.types';
import {
    CreateTableLayoutPayload,
    CreateTableLayoutResponse,
    GetTableLayoutListPayload,
    GetTableLayoutListResponse,
    GetTableLayoutPayload,
    GetTableLayoutResponse,
    UpdateTableLayoutPayload,
    UpdateTableLayoutResponse,
    DeleteTableLayoutPayload,
    DeleteTableLayoutResponse,
} from './tableLayout.type';

const tableLayoutApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        createTableLayout: build.mutation<CreateTableLayoutResponse, CreateTableLayoutPayload>({
            query: ({ ...body }) => {
                return {
                    url: `/table-layouts`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [ApiTagTypes.TableLayout],
            transformResponse: (response: ApiResponse<CreateTableLayoutResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getTableLayoutList: build.query<GetTableLayoutListResponse, GetTableLayoutListPayload>({
            query: ({ restaurantId }) => {
                const queryParameters = generateQueryStringFromParams({ restaurantId });

                return {
                    url: `/table-layouts?${queryParameters}`,
                    method: 'GET',
                };
            },
            providesTags: [ApiTagTypes.TableLayout],
            transformResponse: (response: ApiResponse<GetTableLayoutListResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getTableLayout: build.query<GetTableLayoutResponse, GetTableLayoutPayload>({
            query: ({ tableLayoutId }) => ({
                url: `/table-layouts/${tableLayoutId}`,
                method: 'GET',
            }),
            providesTags: [ApiTagTypes.TableLayout, ApiTagTypes.Room],
            transformResponse: (response: ApiResponse<GetTableLayoutResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        updateTableLayout: build.mutation<UpdateTableLayoutResponse, UpdateTableLayoutPayload>({
            query: ({ tableLayoutId, ...body }) => {
                return {
                    url: `/table-layouts/${tableLayoutId}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: [ApiTagTypes.TableLayout],
            transformResponse: (response: ApiResponse<UpdateTableLayoutResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        deleteTableLayout: build.mutation<DeleteTableLayoutResponse, DeleteTableLayoutPayload>({
            query: ({ tableLayoutId }) => {
                return {
                    url: `/table-layouts/${tableLayoutId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [ApiTagTypes.TableLayout],
        }),

        publishTableLayout: build.mutation<DeleteTableLayoutResponse, DeleteTableLayoutPayload>({
            query: ({ tableLayoutId }) => {
                return {
                    url: `/table-layouts/${tableLayoutId}/publish`,
                    method: 'POST',
                };
            },
            invalidatesTags: [ApiTagTypes.TableLayout],
        }),
    }),
});

export const {
    useCreateTableLayoutMutation,
    useGetTableLayoutListQuery,
    useGetTableLayoutQuery,
    useUpdateTableLayoutMutation,
    useDeleteTableLayoutMutation,
    usePublishTableLayoutMutation,
} = tableLayoutApi;
