import React from 'react';
import { useIntl } from 'react-intl';

import { computeCurrency, computePercentage } from '@/locales/utils';
import { Title } from '@/components/atoms/Title';
import { OrderDetailsTax as Taxes } from '@/services/innovorder/orders/order.type';
import { getTaxLetter } from '../OrderDetailsTicket/utils';
import { Container } from './OrderDetailsTax.style';

type OrderDetailsTaxProps = {
    taxes: Taxes[];
};

const OrderDetailsTax: React.FunctionComponent<React.PropsWithChildren<OrderDetailsTaxProps>> = ({ taxes }) => {
    const intl = useIntl();

    const taxTable = taxes.map((tax) => tax.taxRate);
    return (
        <Container>
            <Title type="small" weight="bold" text="orderDetail.tax.taxrate" />
            <Title type="small" weight="bold" text="orderDetail.tax.totalExclTax" />
            <Title type="small" weight="bold" text="orderDetail.tax.totalTax" />
            <Title type="small" weight="bold" text="orderDetail.tax.totalInclTax" />
            {taxes.map((tax) => {
                const taxLetter = getTaxLetter(tax.taxRate, taxTable);

                return (
                    <React.Fragment key={tax.taxRate}>
                        <div>
                            {taxLetter && `(${taxLetter}) `}
                            {computePercentage({ amount: tax.taxRate / 100 })}
                        </div>
                        <div>{computeCurrency({ intl, amount: tax.totalExclTax })}</div>
                        <div>{computeCurrency({ intl, amount: tax.totalTax })}</div>
                        <div>{computeCurrency({ intl, amount: tax.totalInclTax })}</div>
                    </React.Fragment>
                );
            })}
        </Container>
    );
};

export default OrderDetailsTax;
