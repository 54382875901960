import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Routes } from '@/core/router/routes.types';
import { ENV } from '@/utils/env';
import { injectDataInUri } from '@/utils/routes';
import { computeText } from '@/locales/utils';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { useTheme } from '@emotion/react';
import { UserRestaurant } from '@/services/innovorder/user/user.type';
import { getUserRestaurants } from '@/redux/user';
import { OverlayProps } from './overlay.type';
import SideBarItem from '../SideBarItem';
import { Title, TitleContainer, Wrapper } from './overlay.style';

const fillUrlVariables = (url: Routes | string | undefined, restaurant: UserRestaurant) => {
    if (!url) return url;
    return injectDataInUri({
        uri: String(url),
        backofficeUrl: ENV.BACKOFFICE_URL,
        brandId: String(restaurant.brandId),
        restaurantId: String(restaurant.restaurantId),
    });
};

const RestaurantList: React.FunctionComponent<React.PropsWithChildren<OverlayProps>> = ({ route, uri }) => {
    const restaurants = useSelector(getUserRestaurants);
    const intl = useIntl();
    const theme = useTheme();

    return (
        <Wrapper>
            <TitleContainer>
                <SVGIcon icon={SVGIcons.BRAND} size={16} color={theme.color.white100} />

                <Title>{computeText(intl, 'route.brandRestaurants')}</Title>
            </TitleContainer>

            {restaurants?.map((restaurant) => (
                <SideBarItem
                    key={restaurant.restaurantId}
                    labelId={restaurant.name}
                    route={fillUrlVariables(route, restaurant) as Routes}
                    uri={fillUrlVariables(uri, restaurant)}
                    isSubMenu={true}
                />
            ))}
        </Wrapper>
    );
};

export default RestaurantList;
