import React, { useEffect, useState } from 'react';
import Wizard from '@/components/atoms/Wizard';
import { useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import RawModal from '@/components/atoms/RawModal';
import { computeText } from '@/locales/utils';
import { usePublishTableLayoutMutation } from '@/services/innovorder/tableLayout/tableLayout.endpoint';
import { Container } from './PublishTableLayoutModal.style';

export type PublishTableLayoutModalProps = {
    tableLayoutId: string | null;
    tableLayoutName: string | null;
    onHide: () => void;
};

export const PublishTableLayoutModal: React.FunctionComponent<
    React.PropsWithChildren<PublishTableLayoutModalProps>
> = ({ onHide, tableLayoutId, tableLayoutName = '' }) => {
    const intl = useIntl();
    const [publishTableLayoutById, { isSuccess: isPublishSuccess, isLoading: isPublishLoading }] =
        usePublishTableLayoutMutation();
    const [name, setName] = useState(tableLayoutName);

    useEffect(() => {
        setName(name);
    }, [name, setName]);

    useEffect(() => {
        if (isPublishSuccess) {
            onHide();
        }
    }, [isPublishSuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={480}
                steps={[
                    {
                        title: 'tableLayout.modal.publish.title',
                        onCancel: onHide,
                        component: (
                            <Container>
                                <Text
                                    type="normal"
                                    weight="regular"
                                    text={computeText(intl, 'tableLayout.modal.publish.description', {
                                        tableLayoutName: tableLayoutName ?? '',
                                    })}
                                />
                            </Container>
                        ),
                    },
                ]}
                isLoading={isPublishLoading}
                onValidate={() => {
                    if (tableLayoutId) {
                        publishTableLayoutById({ tableLayoutId });
                    }
                }}
            />
        </RawModal>
    );
};
