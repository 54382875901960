import * as React from 'react';
import { PaygreenConfig } from '@/services/innovorder/paygreen/configurations_deprecated/configurations.type';

import { TitleContainer, PanelTitle, PanelText, PanelWarning } from './PaygreenPanel.style';

interface PaygreenPanelProp {
    text: string;
    config?: PaygreenConfig;
}

const PaygreenPanel: React.FunctionComponent<PaygreenPanelProp> = ({ text, config }) => {
    return (
        <div>
            <TitleContainer>
                <PanelTitle text={text} />
            </TitleContainer>

            <PanelText text="paygreen.form.description" />

            {config?.enablePaygreenV3 ?? <PanelWarning text="paygreen.form.ongoing_v3_migration" />}
        </div>
    );
};

export default PaygreenPanel;
