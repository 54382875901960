import { useMemo } from 'react';
import dayjs from 'dayjs';

export const DEFAULT_MAX_DATE_RANGE = 31;

export const getDaysDifference = (startDate: Date, endDate: Date): number => {
    const startDateMoment = dayjs(startDate);
    const endDateDay = dayjs(endDate);
    return endDateDay.diff(startDateMoment, 'day');
};

type DateRangeValidationResult = {
    isDateRangeValid: boolean;
    shouldShowDateRangeErrorMessage: boolean;
};

export type DateRange = {
    startDate: Date | null;
    endDate: Date | null;
};
export const useDateRangeValidation = (
    dates: DateRange,
    maxDateRange: number = DEFAULT_MAX_DATE_RANGE,
): DateRangeValidationResult => {
    return useMemo(() => {
        const { startDate, endDate } = dates;
        if (startDate === null || endDate === null) {
            return { isDateRangeValid: false, shouldShowDateRangeErrorMessage: false };
        }
        const daysDifference = getDaysDifference(startDate, endDate);
        return {
            isDateRangeValid: daysDifference < maxDateRange,
            shouldShowDateRangeErrorMessage: daysDifference >= maxDateRange,
        };
    }, [dates, maxDateRange]);
};
