export enum AccountPaymentType {
    CASH = 'CASH',
    POST_Payment = 'POSTPAYMENT',
    PRE_Payment = 'PREPAYMENT',
}

export type AutoBillingRequestFiltersPayload = {
    entities: string[];
    restaurants: string[];
    pricingRules: string[];
    accountPaymentTypes: string[];
};

export type AutoBillingRequestPayload = {
    brandId: number;
    periodFrom: string;
    periodTo: string;
    filters: AutoBillingRequestFiltersPayload;
};

export type Item = {
    code: string;
    label: string;
    quantity: number;
    unitPriceWithTaxExcluded: number;
    totalTax: number;
    unitPriceWithTaxIncluded: number;
    totalPriceWithTaxIncluded: number;
    totalPriceWithTaxExcluded: number;
    type: 'LP' | 'LR' | 'LT';
};

export type Brand = {
    externalId: string;
    name: string;
    contactEmail: string;
};

export enum CostComputingMode {
    SUMMED_UP_BY_ADMISSION_FEES_AMOUNT = 'summedUpByAdmissionFeesAmount', // facturation au couvert
    PARTITIONED_CONSTANT_COST = 'partitionedConstantCost', // facturation par tranche
    CONSTANT_COST = 'constantCost', // facturation par masse de frais fixe
    PARTITIONED_COST_BY_GUEST = 'partitionedCostByGuest',
}

export enum CostSliceMode {
    CONSTANT_COST = 'constantCost',
    SUMMED_UP_BY_ADMISSION_FEES_AMOUNT = 'summedUpByAdmissionFeesAmount',
}

export type CostSlice = {
    from: number;
    to: number;
    value: number;
    mode: CostSliceMode;
};

export type CostCompute = {
    mode: CostComputingMode;
    costSlices?: CostSlice[];
    constCostAmount?: number;
};

export type BillingGroupingBy = 'PRICING_RULE' | 'BILLING_ENTITY' | 'ACCOUNT_PAYMENT_TYPE' | 'GROUP';
export const DefaultBillingGroupingBy = 'BILLING_ENTITY';

export type BillingEntity = {
    name: string;
    code: string;
    groupingBy: BillingGroupingBy;
    costCompute: CostCompute;
    groups: string[];
};

export type AutoBillingInvoiceGroupingInfo = {
    groupByValue: string;
    groupingBy: BillingGroupingBy;
};

export type AutoBillingInvoiceTotal = {
    totalTaxExcluded: number;
    totalTaxIncluded: number;
    totalTax: number;
};

export type AutoBillingInvoice = {
    uuid: string;
    brand: Brand;
    items: Item[];
    periodFrom: string;
    periodTo: string;
    client: BillingEntity;
    groupingInfo: AutoBillingInvoiceGroupingInfo;
    total: AutoBillingInvoiceTotal;
};

export type PerceivedAmountCode = {
    code: string;
    vat: number;
};
export type BillingServiceCodes = {
    perceivedAmountCodes?: PerceivedAmountCode[];
    admissionLevelCostCode?: string;
    fixedCostCode?: string;
};

export type BillingReferentialRequestPayload = {
    billingReferential: BillingReferential;
    brandId: number;
};

export type BillingReferential = {
    billingServiceCodes: BillingServiceCodes;
    entities: BillingEntity[];
};

export type BillingHistory = {
    date: number;
    user: string;
    brandId: number;
    billingRequest: AutoBillingRequestPayload;
    invoices: AutoBillingInvoice[];
};

export interface PageBillingHistory {
    count: number;
    content: BillingHistory[];
}
