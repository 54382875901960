import styled from '@emotion/styled';

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FormContainer = styled.form`
    padding: 25px;
    box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.1);
    border-radius: ${({ theme }) => theme.radius.main};
    background-color: ${({ theme }): string => theme.color.white100};
    width: 70%;
    max-width: 500px;

    ${({ theme }) => theme.breakpoints.mobile} {
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        max-width: none;
        border: none;
        padding-top: 0;
    }
`;

export const FormTitle = styled.div`
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
    font-size: ${({ theme }): string => theme.font.size.normal};
    font-family: ${({ theme }): string => theme.font.family};
    font-weight: ${({ theme }): string => theme.font.weight.medium.toString()};
`;

export const MarginTop = styled.div`
    margin-top: 40px;
`;

export const InputSpacer = styled.div`
    margin-bottom: 20px;
`;
