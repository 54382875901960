import * as React from 'react';
import { type SVGIconProps } from './SVGIcon.interface';

export { SVGIcons } from './SVGIcon.interface';

const InnerSVGIcon = React.lazy(() => import('./InnerSVGIcon'));
export const SVGIcon = (props: SVGIconProps) => {
    return (
        <React.Suspense>
            <InnerSVGIcon {...props} />
        </React.Suspense>
    );
};

export type IconProps = {
    size: number;
    color: string;
};
