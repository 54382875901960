import { WebhookConfig } from '@/pages/Config/Webhooks/types';
import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    GetWebhookPayload,
    PostWebhookPayload,
    PutWebhookPayload,
    DeleteWebhookPayload,
    WebhookResponseData,
} from './moduleExternalWebhooks.type';

const moduleExternalWebhooksApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getWebhook: build.query<WebhookConfig, ApiRequest<GetWebhookPayload>>({
            query: ({ token, webhookId }) => ({
                url: `/module_external_webhooks/${webhookId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<WebhookResponseData>): WebhookConfig => {
                const { data } = response;
                return {
                    ...data,
                    additionalHeaders: JSON.parse(data.additionalHeaders),
                };
            },
            extraOptions: { maxRetries: 3 },
        }),
        postWebhook: build.mutation<number, ApiRequest<PostWebhookPayload>>({
            query: ({ token, body }) => ({
                url: `/module_external_webhooks/`,
                method: 'POST',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<WebhookResponseData>): number => {
                const { data } = response;
                return data.moduleExternalWebhookId;
            },
            extraOptions: { maxRetries: 3 },
        }),
        putWebhook: build.mutation<WebhookConfig, ApiRequest<PutWebhookPayload>>({
            query: ({ token, webhookId, body }) => ({
                url: `/module_external_webhooks/${webhookId}`,
                method: 'PUT',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<WebhookResponseData>): WebhookConfig => {
                const { data } = response;
                return {
                    ...data,
                    additionalHeaders: JSON.parse(data.additionalHeaders),
                };
            },
            extraOptions: { maxRetries: 3 },
        }),
        deleteWebhook: build.mutation<boolean, ApiRequest<DeleteWebhookPayload>>({
            query: ({ token, webhookId }) => ({
                url: `/module_external_webhooks/${webhookId}`,
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<WebhookResponseData>): boolean => {
                const { status } = response;
                if (status === 200) {
                    return true;
                }
                return false;
            },
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetWebhookQuery, usePutWebhookMutation, usePostWebhookMutation, useDeleteWebhookMutation } =
    moduleExternalWebhooksApi;
