import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from '@/components/atoms/Table';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';

import { SvyButton } from '@/components/savory/SvyButton';
import { HeaderLeft, HeaderRight, Subtitle } from '@/components/atoms/Section/Section.style';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import { Container, Header, HeaderContainer } from './RoomList.style';
import { useRoomTableVM } from './RoomList.viewmodel';
import { RoomModal } from './RoomModal/RoomModal';

const RoomList: React.FunctionComponent<React.PropsWithChildren> = () => {
    const {
        rows,
        headers,
        action,
        tableLayoutName,
        canEdit,
        clearAction,
        handleCreate,
        handlePublish,
        handleRowClick,
    } = useRoomTableVM();
    const { type, roomId, name, tableLayoutId } = action;

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'pos'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <RoomModal
                action={type}
                roomId={roomId}
                roomName={name}
                tableLayoutId={tableLayoutId}
                tableLayoutName={tableLayoutName}
                onHide={clearAction}
            />
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="room.page.title" />
                            <Subtitle>
                                <Text text="room.page.description" />
                            </Subtitle>
                        </HeaderLeft>
                        {canEdit && (
                            <HeaderRight>
                                <SvyButton
                                    size="s"
                                    variant="outline"
                                    onClick={handlePublish}
                                    style={{ marginRight: '15px' }}
                                >
                                    <FormattedMessage id="room.action.publish" />
                                </SvyButton>
                                <SvyButton size="s" onClick={handleCreate}>
                                    <FormattedMessage id="room.action.newRoom" />
                                </SvyButton>
                            </HeaderRight>
                        )}
                    </Header>
                    <Table
                        isSearchable
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={headers}
                        hasPagination
                        hasUrlNavigation
                        isSortable
                        isLoading={false}
                        onRowClick={handleRowClick}
                    />
                </HeaderContainer>
            </Container>
        </RouterNavigationTabs>
    );
};

export default RoomList;
