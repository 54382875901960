import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { computeAndTriggerTouchedFields } from '@/utils/form/utils';
import { Input } from '@/components/atoms/Form/Input';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Label } from '@/components/atoms/Label';
import Modal from '@/components/atoms/Modal';
import MultiSelect from '@/components/atoms/MultiSelect';
import { SavingBar } from '@/components/atoms/SavingBar';
import { Option } from '@/components/atoms/Select';
import { Text } from '@/components/atoms/Text';
import { theme } from '@/core/theme';
import { computeText } from '@/locales/utils';
import { MargedTitle } from '@/pages/Brand/Grant/GrantForm/GrantForm.style';
import { BaseGuestGroupAttributes, PricingRule } from '@/services/innovorder/guestGroup/guestGroup.type';

import {
    ConfirmationBarContainer,
    Container,
    DuplicateNameWarningDescription,
    FormContainer,
    SpacedFlex,
} from './GroupForm.style';

type GroupFormProps = {
    group?: BaseGuestGroupAttributes;
    error?: string;
    onSubmit: (name: string, pricingRules: number[]) => void;
    loading: boolean;
    pricingRules: PricingRule[];
};

const GroupForm: React.FunctionComponent<React.PropsWithChildren<GroupFormProps>> = ({
    group,
    error,
    onSubmit,
    loading,
    pricingRules,
}) => {
    const intl = useIntl();
    const [displayWarningPopup, setDisplayWarningPopup] = useState(false);

    const mapToPricingRulesSelectOptions = (pricingRules: PricingRule[]): Option<number>[] => {
        if (!pricingRules) return [];
        return pricingRules.map(({ code, pricingRuleId }) => ({ label: code, value: pricingRuleId }));
    };

    const mapToPricingRulesSelectedValues = (selectedValues: number[]): Option<number>[] => {
        if (!pricingRules) return [];
        return pricingRules
            .filter((rule) => selectedValues.includes(rule.pricingRuleId))
            .map(({ code, pricingRuleId }) => ({ label: code, value: pricingRuleId }));
    };

    const defaultValues: BaseGuestGroupAttributes = group ?? { name: '', pricingRules: [] };
    const {
        handleSubmit,
        reset,
        getValues,
        control,
        setValue,
        formState: { touchedFields, dirtyFields, isSubmitSuccessful },
        trigger,
    } = useForm<BaseGuestGroupAttributes>({ defaultValues });

    const finalTouchedFields = computeAndTriggerTouchedFields({}, touchedFields, trigger);

    // FIXME: improve typing
    const handleMultiSelectChange = (onChange: (values: any) => void, values: any) => {
        onChange(values);
        setValue(
            'pricingRules',
            values.map(({ value }: { value: number }) => value),
        );
    };

    const hideWarningPopup = () => {
        setDisplayWarningPopup(false);
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(getValues());
        }
    }, [isSubmitSuccessful, getValues, reset]);

    useEffect(() => {
        if (error && typeof error === 'string') {
            setDisplayWarningPopup(true);
        }
    }, [error]);

    return (
        <>
            <Container>
                <div style={{ flex: 1 }}>
                    <MargedTitle text="group.tableCard.title" weight="medium" />
                    <Text text="group.tableCard.subtitle2" weight="light" />
                </div>
                <FormContainer data-testid="grant-form">
                    <SpacedFlex>
                        <MargedTitle text="group.tableCard.title" weight="medium" />
                        <Controller
                            control={control}
                            name="name"
                            rules={{ required: computeText(intl, 'field.required') }}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                    data-testid="groupForm-nameInput"
                                    error={error?.message}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    placeholder="group.name"
                                    labelId="group.name"
                                    isTouched={finalTouchedFields.name}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="pricingRules"
                            rules={{ required: computeText(intl, 'field.required') }}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <div style={{ width: '100%' }}>
                                    <Label labelId="group.pricingRules" inputId="group.pricingRules" />
                                    <MultiSelect<number>
                                        inputId="group.pricingRules"
                                        isMulti={true}
                                        options={mapToPricingRulesSelectOptions(pricingRules)}
                                        onChange={(values) => handleMultiSelectChange(onChange, values)}
                                        onBlur={onBlur}
                                        placeholder={computeText(intl, 'group.pricingRules')}
                                        value={mapToPricingRulesSelectedValues(value)}
                                        error={error}
                                        isTouched={finalTouchedFields.pricingRules as unknown as boolean}
                                    />
                                </div>
                            )}
                        />
                    </SpacedFlex>
                </FormContainer>
            </Container>
            <ConfirmationBarContainer>
                <SavingBar
                    onSave={handleSubmit(({ name, pricingRules }) => onSubmit(name, pricingRules))}
                    loading={loading}
                    changesCount={Object.keys(dirtyFields).length}
                    onCancel={() => reset(defaultValues)}
                />
            </ConfirmationBarContainer>
            <Modal
                isOpen={displayWarningPopup}
                onHide={hideWarningPopup}
                width={412}
                title={'group.modal.warning'}
                icon={SVGIcons.INFO}
                iconColor={theme.color.bloodyMary}
            >
                <DuplicateNameWarningDescription>
                    <Text text={`group.warning.${error}.message`} />
                    <Text text={`group.warning.${error}.description`} />
                </DuplicateNameWarningDescription>
            </Modal>
        </>
    );
};

export default GroupForm;
