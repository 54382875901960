import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[1]};
`;

export const StyledLabel = styled(Text)`
    ${({ theme }) => theme.font.caption.bold};
    line-height: 20px;
`;

export const StyledValue = styled.div`
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    color: ${({ theme }) => theme.color.black};
`;
