import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white100};
    box-sizing: border-box;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${({ theme }) => theme.spacing[4]};
`;

export const Line = styled.div`
    display: grid;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[2]};
    margin: ${({ theme }) => theme.spacing[3]} 0;
    padding: 0 ${({ theme }) => theme.spacing[2]};
    grid-template-columns: 40px 80px 100px 140px 100px 100px 140px 100px;
`;
