import { ReactElement, createElement, ButtonHTMLAttributes } from 'react';
import { CellProps, Column, FilterType, Row, SortByFn } from 'react-table';
import ActionList from '@/components/atoms/ActionList';
import { DropDown } from '@/components/atoms/DropDown';
import { DropDownText } from '@/components/atoms/DropDown/DropDown.type';
import RadioButton, { RadioButtonProps } from '@/components/atoms/RadioButton/RadioButton';

import { assertUnreachable } from '@/utils/typescript';
import { Badge } from '../Badge';
import type { BadgeProps } from '../Badge/Badge';
import { Button, ButtonProps } from '../Button';
import { Input, InputProps } from '../Form/Input';
import { List } from '../List';
import type { ListProps } from '../List/List';
import Select from '../Select';
import type { SelectProps } from '../Select/Select';
import { Switch } from '../Switch';
import type { SwitchProps } from '../Switch/Switch';
import { Text, TextProps } from '../Text/Text';
import type { TableCell, TableRow, ValueMap } from './Table.type';
import { TextIconCell } from './TextIconCell';
import type { TextIconCellProps } from './TextIconCell/TextIconCell';
import { TableImg } from './Table.style';
import BadgeList from '../BadgeList';

export type NumberFilterValue = {
    from?: string;
    to?: string;
};

export type DatePickerFilterValue = {
    startDate: Date | null;
    endDate: Date | null;
};

type FieldCatalogx = {
    [key in Exclude<keyof ValueMap, 'linkTo' | 'identifier' | 'disabled'>]: (
        value: ValueMap[key],
    ) => ReactElement | string;
};

export const FieldCatalog: FieldCatalogx = {
    image: (value) => (value?.src ? createElement(TableImg, value) : ''),
    number: (value) => String(value),
    computedNumber: (value) => `${value.text}`,
    list: (value) => createElement(List, { displayName: 'List', ...value } as ListProps),
    string: (value) => createElement(Text, { displayName: 'Text', ...value } as TextProps),
    action: (value) => createElement(Button, { displayName: 'Button', ...value } as ButtonProps),
    actionList: (values) =>
        createElement(
            ActionList,
            {},
            values.map((value, index) =>
                createElement(Button, { displayName: 'Button', key: index, ...value } as ButtonProps),
            ),
        ),
    switch: (value) => createElement(Switch, { displayName: 'Switch', ...value } as SwitchProps),
    input: (value) => createElement(Input, { displayName: 'Input', ...value } as InputProps),
    select: (value) => createElement(Select, { displayName: 'Select', ...value } as SelectProps<unknown>),
    badge: (value) => createElement(Badge, { displayName: 'Badge', ...value } as BadgeProps),
    badgeList: (values) =>
        createElement(
            BadgeList,
            {},
            values.map((badge, index) =>
                createElement(Badge, { displayName: 'Badge', key: index, ...badge } as BadgeProps),
            ),
        ),
    radio: (value) =>
        createElement(RadioButton, { displayName: 'RadioButton', ...value } as RadioButtonProps<
            Partial<ButtonHTMLAttributes<HTMLDivElement>>
        >),
    texticon: (value) => createElement(TextIconCell, { displayName: 'TextIconCell', ...value } as TextIconCellProps),
    dropdownText: (value) =>
        createElement(DropDown, { displayName: 'DropDownText', ...value } as DropDownText<unknown>),
    empty: () => String(''),
};

export const getCellValue = (cell: TableCell): unknown => {
    switch (cell.type) {
        case 'string':
            return cell.value.text;
        case 'number':
            return cell.value;
        case 'computedNumber':
            return cell.value.number;
        case 'list':
            return cell.value.listItems;
        case 'texticon':
            return cell.value.text;
        case 'empty':
            return '';
        case 'input':
            return cell.value.value;
        case 'select':
            return cell.value.value;
        case 'switch':
            return cell.value.value;
        case 'badge':
            return cell.value.text;
        case 'badgeList':
            return cell.value.map((badge) => badge.text);
        case 'image':
            return cell.value;
        case 'radio':
            return '';
        case 'action':
            return '';
        case 'actionList':
            return '';
        case 'linkTo':
            return '';
        case 'dropdownText':
            return '';
        case 'identifier':
            return '';
        case 'disabled':
            return '';
        default:
            return assertUnreachable(cell);
    }
};

export const sortTypes: Record<string, SortByFn<TableRow>> = {
    alphanumeric: (rowA: Row<TableRow>, rowB: Row<TableRow>, columnId: string) => {
        if (rowA.values[columnId].type === 'string') {
            const valueA = rowA.values[columnId].value;
            const valueB = rowB.values[columnId].value;

            if (typeof valueA.value === 'number' && typeof valueB.value === 'number') {
                return valueA.value - valueB.value;
            }

            return valueA.text ? valueA.text.localeCompare(valueB.text) : -1;
        }
        if (rowA.values[columnId].type === 'number') {
            return rowA.values[columnId].value - rowB.values[columnId].value;
        }
        if (rowA.values[columnId].type === 'computedNumber') {
            return rowA.values[columnId].value.number - rowB.values[columnId].value.number;
        }
        if (rowA.values[columnId].type === 'list') {
            return rowA.values[columnId].value.listItems
                .join()
                .localeCompare(rowB.values[columnId].value.listItems.join());
        }
        return 0;
    },
};

export const filterTypes: Record<string, FilterType<TableRow>> = {
    datePicker: (rows: Row<TableRow>[]): Row<TableRow>[] => {
        return rows;
    },
    string: (rows: Row<TableRow>[], columnIds: string | string[], filterValue?: string): Row<TableRow>[] => {
        if (columnIds.length === 0 || !filterValue) return rows;

        return rows.filter((row) => {
            const cell: TableCell = row.values[columnIds[0]];

            switch (cell.type) {
                // Select contain a defined value list, and is filtered by key
                case 'select':
                    return cell.value.value.toLowerCase().includes(filterValue.toLowerCase());

                // texticon and badge contain translation key
                // String, list and input can contain free text
                case 'texticon':
                case 'badge':
                case 'string':
                    return `${cell.value.text}`.toLowerCase().includes(filterValue.toLowerCase());

                case 'input':
                    return `${cell.value.value}`.toLowerCase().includes(filterValue.toLowerCase());

                case 'list':
                    return cell.value.listItems.join().toLowerCase().includes(filterValue.toLowerCase());

                // number contain numbers
                case 'number':
                    return cell.value === Number(filterValue);

                // computedNumber contain amount, stored in cents
                case 'computedNumber':
                    return cell.value.number === Number(filterValue) * 100;

                // Other columns cannot be filtered with an input text
                case 'switch':
                case 'radio':
                case 'action':
                case 'actionList':
                case 'badgeList':
                case 'empty':
                case 'linkTo':
                case 'dropdownText':
                case 'identifier':
                case 'image':
                case 'disabled':
                    return true;

                default:
                    return assertUnreachable(cell);
            }
        });
    },
    list: (rows: Row<TableRow>[], columnIds: string | string[], filterValue?: unknown[]): Row<TableRow>[] => {
        if (columnIds.length === 0 || !filterValue) return rows;

        return rows.filter((row) => {
            const cell: TableCell = row.values[columnIds[0]];

            switch (cell.type) {
                // Select contain a defined value list, and is filtered by key
                case 'select':
                    return filterValue.includes(cell.value.value);

                // texticon and badge contain translation key
                // String, list and input can contain free text
                case 'texticon':
                case 'badge':
                case 'string':
                    return filterValue.includes(cell.value.text);

                case 'input':
                    return filterValue.includes(cell.value.value);

                case 'list':
                    return filterValue.some((value) => cell.value.listItems.includes(String(value)));

                // number contain numbers
                case 'number':
                    return filterValue.includes(cell.value);

                // computedNumber contain amount, stored in cents
                case 'computedNumber':
                    return filterValue.includes(cell.value.number / 100);

                // switch contain booleans
                case 'switch':
                    return filterValue.includes(cell.value.value);

                // Other columns cannot be filtered with an input text
                case 'radio':
                case 'action':
                case 'actionList':
                case 'badgeList':
                case 'empty':
                case 'linkTo':
                case 'dropdownText':
                case 'identifier':
                case 'image':
                case 'disabled':
                    return true;

                default:
                    return assertUnreachable(cell);
            }
        });
    },
    number: (rows: Row<TableRow>[], columnIds: string | string[], filterValue?: NumberFilterValue): Row<TableRow>[] => {
        if (columnIds.length === 0 || !filterValue) return rows;

        const from = filterValue.from === undefined ? undefined : Number(filterValue.from);
        const to = filterValue.to === undefined ? undefined : Number(filterValue.to);
        if (from === undefined && to === undefined) return rows;

        return rows.filter((row) => {
            const cell: TableCell = row.values[columnIds[0]];

            switch (cell.type) {
                // number contain numbers
                case 'number':
                    return (from === undefined || cell.value >= from) && (to === undefined || cell.value <= to);

                // computedNumber contain amount, stored in cents
                case 'computedNumber': {
                    const computedValue = cell.value.number / 100;
                    return (from === undefined || computedValue >= from) && (to === undefined || computedValue <= to);
                }
                // Other columns cannot be filtered as a range of numbers
                case 'select':
                case 'texticon':
                case 'badge':
                case 'badgeList':
                case 'string':
                case 'input':
                case 'list':
                case 'switch':
                case 'radio':
                case 'action':
                case 'actionList':
                case 'empty':
                case 'linkTo':
                case 'dropdownText':
                case 'identifier':
                case 'image':
                case 'disabled':
                    return true;

                default:
                    return assertUnreachable(cell);
            }
        });
    },
};

export const handleTableFormatter = <T extends Record<string, unknown>>(
    props: CellProps<T, TableCell>,
): ReactElement | string => {
    const { value: cell } = props;

    // Can't make it an includes because of the type inference bellow
    if (cell.type === 'linkTo' || cell.type === 'identifier' || cell.type === 'disabled') {
        return '';
    }

    return FieldCatalog[cell.type](cell.value as never);
};

export const defaultColumn = {
    filter: filterTypes.string,
    Cell: handleTableFormatter,
} as Partial<Column<TableRow>>;

const FilterCatalog: {
    [key in Exclude<TableCell['type'], (typeof UNFILTERABLE_CELL_TYPES)[number]>]: (accessor: string) => string;
} = {
    string: (accessor) => `values.${accessor}.value.text`,
    number: (accessor) => `values.${accessor}.value`,
    computedNumber: (accessor) => `values.${accessor}.value.text`,
    list: (accessor) => `values.${accessor}.value.listItems`,
    input: (accessor) => `values.${accessor}.value.value`,
    badge: (accessor) => `values.${accessor}.value.text`,
    texticon: (accessor) => `values.${accessor}.value.text`,
};

const UNFILTERABLE_CELL_TYPES = [
    'linkTo',
    'switch',
    'action',
    'actionList',
    'badgeList',
    'empty',
    'select',
    'radio',
    'dropdownText',
    'identifier',
    'image',
    'disabled',
] as const;

type PrintableCell = TableCell & { type: Exclude<TableCell['type'], (typeof UNFILTERABLE_CELL_TYPES)[number]> };
type CellDef<Cell = TableCell> = { accessor: string; cell: Cell };

export const handleTableFilter = (rows: Row<TableRow>[], filters?: string[]): string[] => {
    if (!rows.length) {
        return [];
    }

    const values = rows[0]?.values;

    const filterCell = (cellDef: CellDef): cellDef is CellDef<PrintableCell> => {
        const { cell, accessor } = cellDef;
        return (
            !!cell &&
            !UNFILTERABLE_CELL_TYPES.some((unfilterableCellType) => unfilterableCellType === cell.type) &&
            (!filters || filters.includes(accessor))
        );
    };

    const getCellResolvedValue = ({ accessor, cell }: CellDef<PrintableCell>) => FilterCatalog[cell.type](accessor);

    const flatValues = Object.entries<TableCell>(values).map(([accessor, cell]) => ({
        accessor,
        cell,
    }));
    return (
        flatValues
            // Removes empty cells (like when selection is enabled)
            .filter(filterCell)
            .map(getCellResolvedValue)
    );
};
