import React from 'react';

import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { Button } from '@/components/atoms/Button';
import { FormattedMessage } from 'react-intl';
import { PricingRules } from './PricingRules';
import { EntranceFees } from './EntranceFees';
import { Grants } from './Grants';
import { Container, ActionsContainer } from './GrantsAndEntranceFees.style';
import { UploadModal } from './UploadModal/UploadModal';
import { useGrantsAndEntranceFeesVM } from './GrantsAndEntranceFees.viewmodel';

export const GrantsAndEntranceFees: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { brandId, tabRoutes, isOpen, handleHide, handleUnHide, onDownload } = useGrantsAndEntranceFeesVM();

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <UploadModal isOpen={isOpen} onHide={handleHide} brandId={brandId} />
            <Container>
                <ActionsContainer>
                    <Button buttonType="primary" onClick={handleUnHide}>
                        <FormattedMessage id="pricingRule.action.import" />
                    </Button>
                    <Button buttonType="primary" onClick={onDownload}>
                        <FormattedMessage id="pricingRule.action.export" />
                    </Button>
                </ActionsContainer>

                <PricingRules brandId={brandId} />
                <EntranceFees brandId={brandId} />
                <Grants brandId={brandId} />
            </Container>
        </RouterNavigationTabs>
    );
};
