import React, { useCallback, useEffect } from 'react';
import { Routes } from '@/core/router/routes.types';
import { useUpdatePosAccessGroupMutation } from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.endpoints';
import { PostPosAccessGroupBody } from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.type';
import { stringifyError } from '@/utils/errors';
import { useParams, useHistory, generatePath } from 'react-router';
import { PosAccessBase } from '../../components/PosAcess';
import PosGroupForm from '../../components/PosGroups/forms/PosGroupsForm';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
    groupId: string;
};

const EditPosAccessGroup: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { brandId, restaurantId, groupId } = useParams<ParamTypes>();
    const history = useHistory();

    const [UpdatePosAccessGroup, { isLoading, isSuccess, error }] = useUpdatePosAccessGroupMutation();

    const handleSuccess = useCallback(() => {
        if (isSuccess) history.push(generatePath(Routes.PosAccessGroups, { brandId, restaurantId }));
    }, [brandId, history, isSuccess, restaurantId]);

    useEffect(() => {
        handleSuccess();
    }, [handleSuccess]);

    const onSubmit = (data: PostPosAccessGroupBody) => {
        UpdatePosAccessGroup({
            ...data,
            restaurantId: Number.parseInt(restaurantId, 10),
            groupId: Number.parseInt(groupId, 10),
        });
    };
    return (
        <PosAccessBase>
            <PosGroupForm error={stringifyError(error)} loading={isLoading} onSubmit={onSubmit} />
        </PosAccessBase>
    );
};

export { EditPosAccessGroup };
