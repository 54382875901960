import styled from '@emotion/styled';

export const CalendarInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    padding: ${({ theme }): string => `${theme.spacing[2]} ${theme.spacing[3]}`};
    background: ${({ theme }): string => theme.color.white200};
    border: 1px solid ${({ theme }): string => theme.color.grey200};
    font-family: ${({ theme }): string => theme.font.family};
    font-size: ${({ theme }): string => theme.font.size.small};
    color: ${({ theme }): string => theme.color.black200};
    border-radius: ${({ theme }): string => theme.radius.main};
`;
