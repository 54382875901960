import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Input } from '@/components/atoms/Form/Input';
import { computeText } from '@/locales/utils';
import { Label } from '@/components/atoms/Label';
import {
    MarketplaceChannelName,
    Location,
    MarketplaceChannelId,
} from '@/services/innovorder/marketplace/marketplace.type';
import { useGetLocationDetailMutation } from '@/services/innovorder/marketplace/marketplace.endpoints';
import { getUserToken } from '@/redux/user';
import LocationPanel from '../LocationPanel';
import { Container, ActiveLocationsContainer, StyledEmptyState, AddButton } from './ConfigurationPanel.style';

type ConfigurationPanelProps = {
    locations: Location[];
    addLocation: (locationId: string, locationName: string, vatDefault?: number | null) => void;
    deleteLocation: (locationId: string) => void;
    toggleMarketplace: (locationId: string, marketplaceId: MarketplaceChannelId) => void;
    marketplace: MarketplaceChannelName;
    shouldDisplayVatDefault?: boolean;
};

const ConfigurationPanel: React.FunctionComponent<React.PropsWithChildren<ConfigurationPanelProps>> = ({
    locations,
    addLocation,
    deleteLocation,
    toggleMarketplace,
    marketplace,
    shouldDisplayVatDefault = false,
}) => {
    const intl = useIntl();

    const { control, getValues, setValue, setError, clearErrors } = useForm<{
        locationId: string;
        vatDefault: number | null;
    }>({
        defaultValues: {},
    });
    const token = useSelector(getUserToken);
    const [getLocationDetail, { data: locationDetail, isLoading, isSuccess, isError }] = useGetLocationDetailMutation();

    const addLocationCheck = () => {
        if (isLoading) return;

        const locationId = getValues('locationId');
        const location = locations.find(({ id }) => id === locationId);

        if (!location) {
            getLocationDetail({ storeReference: locationId, token, marketplace });
        } else {
            setError('locationId', {
                type: 'custom',
                message: computeText(intl, 'marketplace.storid.alreadyused', { name: location.name }),
            });
        }
    };

    useEffect(() => {
        if (isSuccess && locationDetail && locationDetail.storeReference) {
            const vatDefault = getValues('vatDefault');
            addLocation(locationDetail.storeReference, locationDetail.storeDisplayName, vatDefault);
            setValue('locationId', '');
        }
    }, [locationDetail, isSuccess, addLocation, setValue, getValues]);

    useEffect(() => {
        if (isError) {
            setError('locationId', {
                type: 'custom',
                message: computeText(intl, 'marketplace.storid.unknown'),
            });
        }
    }, [isError, intl, setError]);

    return (
        <Container>
            <Controller
                name="locationId"
                control={control}
                rules={{ required: computeText(intl, 'field.required') }}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                        onChange={(e) => {
                            clearErrors('locationId');
                            onChange(e.target.value);
                        }}
                        labelId="marketplace.storid.label"
                        placeholder="marketplace.storid.placeholder"
                        value={value}
                        error={error?.message}
                        onAdd={value && !shouldDisplayVatDefault && !error?.message ? addLocationCheck : undefined}
                    />
                )}
            />
            {shouldDisplayVatDefault && (
                <Controller
                    name="vatDefault"
                    control={control}
                    rules={{ required: computeText(intl, 'field.required') }}
                    defaultValue={null}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                            onChange={(e) => {
                                clearErrors('vatDefault');
                                onChange(Number(e.target.value));
                            }}
                            labelId="marketplace.vatdefault.placeholder"
                            placeholder="marketplace.vatdefault.placeholder"
                            value={Number(value) > 0 ? `${value}` : ''}
                            type="number"
                            error={error?.message}
                        />
                    )}
                />
            )}
            {shouldDisplayVatDefault && (
                <AddButton onClick={addLocationCheck}>
                    <FormattedMessage id="button.add" />
                </AddButton>
            )}
            <ActiveLocationsContainer>
                <Label labelId="marketplace.activestorids.label" />
                {!locations?.length && (
                    <StyledEmptyState size="medium" emptyMessageId="marketplace.activestorids.emptystate" />
                )}
                {Boolean(locations?.length) &&
                    locations.map((location, index) => (
                        <LocationPanel
                            key={location.id}
                            location={location}
                            counter={index + 1}
                            marketplace={marketplace}
                            onClick={toggleMarketplace}
                            onDelete={deleteLocation}
                        />
                    ))}
            </ActiveLocationsContainer>
        </Container>
    );
};
export default ConfigurationPanel;
