import { useTheme, Theme } from '@emotion/react';
import * as ReactSelect from 'react-select';
import * as React from 'react';
import { handleBorderColor } from './react-select.utils';

const getCustomStyles = (
    theme: Theme,
    customStyle?: {
        multiValue: ReactSelect.CSSObjectWithLabel;
        multiValueLabel: ReactSelect.CSSObjectWithLabel;
    },
): ReactSelect.StylesConfig<any, true, any> => ({
    container: (provided) => ({
        ...provided,
        minWidth: 210,
        minHeight: 40,
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: 4,
        border: `1px solid ${handleBorderColor(state, theme)}`,
        boxShadow: 'none',
        backgroundColor: theme.color.white200,
        ':hover': {
            border: `1px solid ${handleBorderColor(state, theme)}`,
        },
        minHeight: 40,
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: 66,
        padding: '4px 14px',
        overflow: 'scroll',
        justifyContent: 'revert',
        ':focus': {
            maxHeight: 'inherit',
        },
        '::-webkit-scrollbar': {
            display: 'none',
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        cursor: 'text',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: state.data.isFixed ? theme.color.grey500 : provided.backgroundColor,
        fontFamily: theme.font.family,
        ...customStyle?.multiValue,
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: state.data.isFixed ? theme.color.white100 : provided.color,
        paddingRight: 6,
        ...customStyle?.multiValueLabel,
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        display: state.data.isFixed ? 'none' : provided.display,
    }),
    option: (provided) => ({
        ...provided,
        fontFamily: theme.font.family,
        fontWeight: 100,
        color: theme.color.black200,
        fontSize: theme.font.size.small,
        backgroundColor: 'transparent',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        ':hover': {
            cursor: 'pointer',
            backgroundColor: theme.color.palePink100,
            color: theme.color.primary,
            fontWeight: 500,
        },
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: 0,
    }),
    menuList: (provided) => ({
        ...provided,
        backgroundColor: theme.color.white200,
        borderRadius: 0,
        padding: 0,
        marginTop: 0,
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontFamily: theme.font.family,
        fontSize: theme.font.size.small,
    }),
    input: (provided) => ({
        ...provided,
        fontFamily: theme.font.family,
        fontSize: theme.font.size.small,
        color: theme.color.black200,
    }),
    placeholder: (provided) => ({
        ...provided,
        fontFamily: theme.font.family,
        fontSize: theme.font.size.small,
        fontWeight: theme.font.weight.medium,
        color: theme.color.black300,
    }),
    singleValue: (provided) => ({
        ...provided,
        fontFamily: theme.font.family,
        fontSize: theme.font.size.small,
        color: theme.color.black200,
    }),
});

export type CustomStyle = {
    multiValue: ReactSelect.CSSObjectWithLabel;
    multiValueLabel: ReactSelect.CSSObjectWithLabel;
};

export const useReactSelectCustomStyles = <
    Option = unknown,
    IsMulti extends boolean = boolean,
    Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
    customStyle?: CustomStyle,
) => {
    const theme = useTheme();
    return React.useMemo(
        () => getCustomStyles(theme, customStyle) as ReactSelect.StylesConfig<Option, IsMulti, Group>,
        [customStyle, theme],
    );
};
