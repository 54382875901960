import { useCallback, useEffect, useState } from 'react';

export type LocalStorage<T> = { value: T | null; setInLocalStorage: (item: T) => void };

const retrieveFromLocalStorage = <T>(key: string): T | null => {
    try {
        const result = window.localStorage.getItem(key);

        if (result) {
            return JSON.parse(result) as T;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const useLocalStorage = <T>(key: string, defaultValue?: T | null): LocalStorage<T> => {
    const LSValue = retrieveFromLocalStorage<T>(key);
    const [value, setValue] = useState<T | null>(LSValue || defaultValue || null);

    useEffect(() => {
        const newValue = retrieveFromLocalStorage<T>(key);

        setValue((oldValue) => (JSON.stringify(newValue) === JSON.stringify(oldValue) ? oldValue : newValue));
    }, [key]);

    const setInLocalStorage = useCallback(
        (item: T) => {
            try {
                if (item === null) {
                    window.localStorage.removeItem(key);
                } else {
                    window.localStorage.setItem(key, JSON.stringify(item));
                }
                setValue(item);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(`Failed to save ${item} with key ${key} in local storage`);
            }
        },
        [key],
    );

    useEffect(() => {
        if (defaultValue && !LSValue) {
            setInLocalStorage(defaultValue);
        }
    }, [LSValue, defaultValue, key, setInLocalStorage]);

    return { value, setInLocalStorage };
};
