import styled from '@emotion/styled';
import { Text } from '../../atoms/Text';

export const ModalDeviceList = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 280px;
`;

export const ModalDisclaimerText = styled(Text)`
    padding: ${({ theme }) => theme.spacing[2]};
    background-color: ${({ theme }) => theme.color.grey100};
    border-radius: ${({ theme }) => theme.radius.main};
`;

export const PosLabel = styled(Text)`
    margin-top: 2px;
    font-style: italic;
    color: ${({ theme }) => theme.color.black300};
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const ModalButton = styled.div<{ disabled?: boolean }>`
    user-select: none;

    & > div {
        padding: 12px;
    }

    &:hover {
        background-color: ${({ theme, disabled }) => (disabled ? theme.color.white100 : theme.color.grey300)};
    }
`;
