import React, { useCallback, useEffect } from 'react';
import { useCreatePosAccessGroupMutation } from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.endpoints';
import { PostPosAccessGroupBody } from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.type';
import { stringifyError } from '@/utils/errors';
import { useParams, useHistory } from 'react-router';
import { PosAccessBase } from '../../components/PosAcess';
import PosGroupForm from '../../components/PosGroups/forms/PosGroupsForm';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
};

const CreatePosAccessGroup: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { brandId, restaurantId } = useParams<ParamTypes>();
    const history = useHistory();

    const [createPosAccessGroup, { isLoading, isSuccess, error }] = useCreatePosAccessGroupMutation();

    const handleSuccess = useCallback(() => {
        if (isSuccess) history.push(`/brands/${brandId}/restaurants/${restaurantId}/channels/pos/access/groups/`);
    }, [brandId, history, isSuccess, restaurantId]);

    useEffect(() => {
        handleSuccess();
    }, [handleSuccess]);

    const onSubmit = (data: PostPosAccessGroupBody) => {
        createPosAccessGroup({ ...data, restaurantId: Number.parseInt(restaurantId, 10) });
    };
    return (
        <PosAccessBase>
            <PosGroupForm error={stringifyError(error)} loading={isLoading} onSubmit={onSubmit} />
        </PosAccessBase>
    );
};

export { CreatePosAccessGroup };
