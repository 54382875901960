import React from 'react';

import isEmpty from 'lodash/isEmpty';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { useBadgeReaderVM } from './BadgeReader.viewmodel';
import Empty from './Empty';
import List from './List';
import { FormModal } from './Form/FormModal';

const BadgeReader: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { data, isLoading, formProps, hideForm, tabRoutes, onHelp, onCreate, onEdit, onDelete } = useBadgeReaderVM();

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            {isEmpty(data) && !isLoading && <Empty onHelp={onHelp} onCreate={onCreate} />}
            {!isEmpty(data) && (
                <List data={data || []} onCreate={onCreate} onEdit={onEdit} onDelete={onDelete} isLoading={isLoading} />
            )}
            {formProps.isOpen && <FormModal {...formProps} onHide={hideForm} />}
        </RouterNavigationTabs>
    );
};

export default BadgeReader;
