import React, { useMemo } from 'react';
import { Section } from '@/components/atoms/Section';
import { StyledText } from './SuccessStep.style';

export type SelectPosDeviceStepProps = {
    state?: 'SUCCESS' | 'WARNING' | 'ERROR';
    message: string;
};

export const SuccessStep: React.FunctionComponent<React.PropsWithChildren<SelectPosDeviceStepProps>> = ({
    message,
    state = 'SUCCESS',
}) => {
    const stateText = useMemo(() => {
        switch (state) {
            case 'SUCCESS':
                return 'pricingRule.modal.import.report.success';
            case 'ERROR':
                return 'pricingRule.modal.import.report.error';
            case 'WARNING':
                return 'pricingRule.modal.import.report.warning';
        }
    }, [state]);

    return (
        <Section
            title="pricingRule.modal.import.report.result"
            subtitle={message}
            actions={<StyledText state={state} type="small" text={stateText} />}
        />
    );
};
