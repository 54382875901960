import React from 'react';
import { Table } from '@/components/atoms/Table';

import { FormattedMessage } from 'react-intl';

import { Button } from '@/components/atoms/Button';

import { Section } from '@/components/atoms/Section';

import { TableRow } from '@/components/atoms/Table/Table.type';
import { Column } from 'react-table';

interface TableSectionProps {
    titleId: string;
    subtitleId: string;
    actionButtonId: string;
    onActionClick: () => void;
    tableProps: {
        rows: TableRow[];
        columns: Column<TableRow>[];
        isLoading: boolean;
        emptyMessageId: string;
        isSortable?: boolean;
        hasPagination?: boolean;
    };
}

export const GroupingSection: React.FC<TableSectionProps> = ({
    titleId,
    subtitleId,
    actionButtonId,
    onActionClick,
    tableProps,
}) => (
    <Section
        title={titleId}
        subtitle={subtitleId}
        actions={
            <Button buttonType="primary" onClick={onActionClick}>
                <FormattedMessage id={actionButtonId} />
            </Button>
        }
    >
        <Table
            rows={tableProps.rows}
            columns={tableProps.columns}
            emptyMessageId={tableProps.emptyMessageId}
            isSortable={tableProps.isSortable ?? false}
            hasPagination={tableProps.hasPagination ?? true}
            isLoading={tableProps.isLoading}
        />
    </Section>
);
