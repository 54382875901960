import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse, ApiResponseBase } from '../request.types';
import {
    BaseGuestGroupAttributes,
    CreateGuestGroupsPayload,
    DeleteGuestGroupPayload,
    GetGuestGroupByIdPayload,
    GetGuestGroupsPayload,
    GuestGroup,
    GuestGroupWithGuestsCount,
    RelatedGuestsCount,
    UpdateGuestGroupsPayload,
} from './guestGroup.type';

const guestGroupApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getGuestGroups: build.query<GuestGroupWithGuestsCount[], ApiRequest<GetGuestGroupsPayload>>({
            query: ({ brandId, token }) => ({
                url: `/guest_groups?brandId=${brandId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.GuestGroup],
            transformResponse: (response: ApiResponse<GuestGroupWithGuestsCount[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        postGuestGroup: build.mutation<GuestGroup, ApiRequest<CreateGuestGroupsPayload>>({
            query: ({ name, brandId, pricingRules, token }) => ({
                url: `/guest_groups`,
                method: 'POST',
                params: { brandId },
                body: {
                    name,
                    pricingRules,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.GuestGroup],
            transformResponse: (response: ApiResponse<GuestGroup>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        putGuestGroup: build.mutation<GuestGroup, ApiRequest<UpdateGuestGroupsPayload>>({
            query: ({ name, groupId, pricingRules, token }) => ({
                url: `/guest_groups`,
                method: 'PUT',
                params: { groupId },
                body: {
                    name,
                    pricingRules,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.GuestGroup],
            transformResponse: (response: ApiResponse<GuestGroup>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getGuestGroupById: build.query<BaseGuestGroupAttributes, ApiRequest<GetGuestGroupByIdPayload>>({
            query: ({ groupId, token }) => ({
                url: `/guest_groups/${groupId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.GuestGroup],
            transformResponse: (response: ApiResponse<GuestGroup>) => {
                const { name, pricingRules } = response.data;
                return {
                    name,
                    pricingRules: pricingRules.map(({ pricingRuleId }) => pricingRuleId),
                };
            },
            extraOptions: { maxRetries: 3 },
        }),
        deleteGuestGroup: build.mutation<ApiResponseBase, ApiRequest<DeleteGuestGroupPayload>>({
            query: ({ guestGroupId, token }) => ({
                url: `/guest_groups`,
                method: 'DELETE',
                params: { groupId: guestGroupId },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.GuestGroup],
            extraOptions: { maxRetries: 3 },
        }),
        getRelatedGuestsCount: build.mutation<RelatedGuestsCount, ApiRequest<GetGuestGroupByIdPayload>>({
            query: ({ groupId, token }) => ({
                url: `/guest_groups/${groupId}/count_related_guests`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.GuestGroup],
            extraOptions: { maxRetries: 3 },
            transformResponse: (response: ApiResponse<number>) => {
                const guestsCount = response.data;
                return {
                    count: guestsCount,
                };
            },
        }),
    }),
});

export const {
    useGetGuestGroupsQuery,
    useLazyGetGuestGroupsQuery,
    usePostGuestGroupMutation,
    usePutGuestGroupMutation,
    useGetGuestGroupByIdQuery,
    useDeleteGuestGroupMutation,
    useGetRelatedGuestsCountMutation,
} = guestGroupApi;
