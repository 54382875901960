import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IntlShape } from 'react-intl';
import { computeText } from '@/locales/utils';

export const UNKNOWN_ERROR_CODE = 'unknown_error_code';

export const getFieldNameFromErrorCode = (errorCode: string): string => {
    switch (errorCode) {
        case 'duplicate_email':
            return 'email';
        case 'duplicate_ine':
            return 'studentNumber';
        case 'duplicate_badge_number':
            return 'badgeNumber';
        case 'ewallet_is_not_null_to_postpayment':
            return 'accountPaymentType';
        default:
            return UNKNOWN_ERROR_CODE;
    }
};

type FormErrorData = {
    code: string;
    message: string;
};

export const getFormErrorInfo = (error: FetchBaseQueryError, intl: IntlShape) => {
    const errorData = error.data as FormErrorData;
    const fieldName = getFieldNameFromErrorCode(errorData.code);
    return {
        name: fieldName,
        message: computeText(intl, `field.${errorData.code}`),
    };
};
