import * as React from 'react';

import { computeText } from '@/locales/utils';
import { Input } from '@/components/form/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import { SvyButton } from '@/components/savory/SvyButton';
import { confirm } from '@/core/asyncActions/confirm';
import { useImportGeojsonPolygonMutation } from '@/services/innovorder/adminTools/adminTools.endpoints';
import { FormControl, FormControls, SuccessMessage } from '../AdminTools.style';
import { safeMutate } from '@/utils/errors';
import { Form } from '@/core/form/Form';

export type ImportGeojsonPolygonInputs = {
    restaurantId: string;
    areaName: string;
    geoJsonPolygon: string;
};

const ImportGeojsonPolygon: React.FunctionComponent = () => {
    const intl = useIntl();

    const [importGeojsonPolygon, { isSuccess }] = useImportGeojsonPolygonMutation();

    const handleSubmitForm = async ({ restaurantId, areaName, geoJsonPolygon }: ImportGeojsonPolygonInputs) => {
        const hasConfirmed = await confirm(computeText(intl, 'adminTools.confirmModal.description'));
        if (hasConfirmed) {
            await safeMutate(
                importGeojsonPolygon({
                    restaurantId,
                    areaName,
                    geoJsonPolygon,
                }),
            );
        }
    };

    return (
        <Form<ImportGeojsonPolygonInputs>>
            {({ handleSubmit }) => {
                return (
                    <>
                        <Text type="large" text="brand.adminTools.importGeojsonPolygon.title" />
                        <FormControls>
                            <FormControl>
                                <Input
                                    name="restaurantId"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    placeholder="brand.adminTools.importGeojsonPolygon.form.placeholder.restaurantId"
                                    labelId="brand.adminTools.importGeojsonPolygon.form.restaurantId"
                                />
                                <Input
                                    name="areaName"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    placeholder="brand.adminTools.importGeojsonPolygon.form.placeholder.areaName"
                                    labelId="brand.adminTools.importGeojsonPolygon.form.areaName"
                                />
                                <Input
                                    name="geoJsonPolygon"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    placeholder="brand.adminTools.importGeojsonPolygon.form.placeholder.geoJsonPolygon"
                                    labelId="brand.adminTools.importGeojsonPolygon.form.geoJsonPolygon"
                                />
                            </FormControl>
                            <SvyButton onClick={handleSubmit(handleSubmitForm)}>
                                <FormattedMessage id="button.validate" />
                            </SvyButton>
                            {isSuccess && <SuccessMessage text="brand.adminTools.importGeojsonPolygon.form.success" />}
                        </FormControls>
                    </>
                );
            }}
        </Form>
    );
};

export default ImportGeojsonPolygon;
