import React from 'react';
import { useIntl } from 'react-intl';
import { OnChangeValue } from 'react-select';
import MultiSelect, { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { computeText } from '@/locales/utils';
import { ConsumptionMode, CONSUMPTION_MODE_FLAGS } from '@/services/innovorder/consumptionMode/consumptionMode.type';
import { PaymentMethod } from '@/services/innovorder/paymentMethod/paymentMethod.type';

export type ConsumptionModesProps = {
    consumptionModes: ConsumptionMode[];
    paymentMethod: PaymentMethod;
    isLoading?: boolean;
    onChange?: (param: PaymentMethod) => void;
    inputId?: string;
    isDisabled?: boolean;
};

const ConsumptionModes: React.FunctionComponent<React.PropsWithChildren<ConsumptionModesProps>> = ({
    paymentMethod,
    isLoading,
    consumptionModes,
    onChange,
    inputId,
    isDisabled,
}) => {
    const intl = useIntl();
    const consumptionModeOptions = consumptionModes.map(({ type }) => ({
        label: computeText(intl, `consumptionMode.${type}`),
        value: CONSUMPTION_MODE_FLAGS[`FLAG_${type}`],
    }));

    const value = paymentMethod?.consumptionModesFlags
        ? consumptionModeOptions.filter(({ value }) => paymentMethod?.consumptionModesFlags?.includes(value))
        : consumptionModeOptions.filter(({ value }) =>
              paymentMethod?.consumptionModes.map(({ type }) => CONSUMPTION_MODE_FLAGS[`FLAG_${type}`]).includes(value),
          );

    const handleOnChange = (selected: OnChangeValue<SelectOptionInterface<number>, true>) => {
        const _consumptionModes = selected.map(({ value }) => value);
        onChange &&
            onChange({
                ...paymentMethod,
                action: paymentMethod.paymentMethodId ? (_consumptionModes.length > 0 ? 'update' : 'delete') : 'create',
                consumptionModeSumFlag: _consumptionModes.reduce((accumulator, curr) => accumulator + curr, 0),
                consumptionModesFlags: _consumptionModes,
            });
    };

    return (
        <MultiSelect
            isMulti={true}
            isDisabled={isDisabled}
            options={consumptionModeOptions}
            onChange={handleOnChange}
            value={value}
            placeholder={computeText(intl, 'paymentMethods.consumptionMode')}
            isLoading={isLoading}
            inputId={inputId}
        />
    );
};

export { ConsumptionModes };
