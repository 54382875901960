import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { Routes } from '@/core/router/routes.types';

interface GetRedirectToGenerationsHistoryPageHandler {
    redirectToGenerationsHistoryPage: () => void;
}

export const useGetRedirectToGenerationsHistoryPageHandler = (): GetRedirectToGenerationsHistoryPageHandler => {
    const history = useHistory();

    const redirectToGenerationsHistoryPage = useCallback(() => {
        history.push(Routes.ReportGenerationsHistory);
    }, [history]);

    return { redirectToGenerationsHistoryPage };
};
