import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse, TokenRequest } from '../request.types';
import {
    AuthResponseData,
    AuthUser,
    BrandGuestLoginPayload,
    LoginAsGuestPayload,
    LoginAsGuestResponse,
    LoginPayload,
    LoginResponse,
} from './auth.type';

const authApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<AuthResponseData, LoginPayload>({
            query: ({ email, password, rememberMe, locale }) => ({
                url: `oauth/login`,
                method: 'POST',
                body: {
                    grant_type: 'password',
                    username: email,
                    locale,
                    password,
                    rememberMe,
                },
            }),
            transformResponse: (response: LoginResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        me: build.query<AuthUser, TokenRequest>({
            query: ({ token }) => ({
                url: `/oauth/me`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<AuthUser>) => response.data,
            extraOptions: { maxRetries: 3 },
            providesTags: [ApiTagTypes.User],
        }),
        logout: build.mutation<AuthUser, TokenRequest>({
            query: ({ token }) => ({
                url: `/oauth/logout`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<AuthUser>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        loginAsGuest: build.query<LoginAsGuestResponse, LoginAsGuestPayload>({
            query: ({ userId }) => ({
                url: `oauth/admin`,
                params: { userId },
            }),
            transformResponse: (response: ApiResponse<LoginAsGuestResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        brandGuestLogin: build.query<AuthResponseData, ApiRequest<BrandGuestLoginPayload>>({
            query: ({ brandId, token }) => ({
                url: `brand_groups/${brandId}/user`,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: LoginResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useLoginMutation,
    useLazyMeQuery,
    useMeQuery,
    useLogoutMutation,
    useLazyLoginAsGuestQuery,
    useLazyBrandGuestLoginQuery,
} = authApi;
