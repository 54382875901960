import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Draggable from 'react-draggable';
import { Controller } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { Input } from '@/components/atoms/Form/Input';
import RawModal from '@/components/atoms/RawModal';
import { stringifyError } from '@/utils/errors';
import { SvyButton } from '@/components/savory/SvyButton';
import { RoomModalInputContainer, RoomModalTextInputLabel } from '../RoomModal.style';
import {
    DeleteItem,
    LineContainer,
    RoomWizard,
    TableContainer,
    TableInput,
    TableItem,
    TableItemContainer,
} from './CreateOrEditRoomModal.style';
import { useCreateOrEditRoomModalVM } from './CreateOrEditRoomModal.viewmodel';

export type CreateOrEditRoomModalProps = {
    onHide: () => void;
    roomId: string | null;
    roomName: string | null;
    tableLayoutId: string | null;
};

export const CreateOrEditRoomModal: React.FunctionComponent<React.PropsWithChildren<CreateOrEditRoomModalProps>> = (
    props,
) => {
    const {
        title,
        addTableError,
        intl,
        roomTables,
        isLoading,
        serverError,
        control,
        canEdit,
        onCreateValidate,
        handleOnChangeNewTableName,
        handleOnChangeStart,
        handleOnChangeEnd,
        handleDelete,
        handleAddOneTable,
        handleAddMultipleTables,
        handleDrag,
        onHide,
    } = useCreateOrEditRoomModalVM(props);

    return (
        <RawModal isOpen={true}>
            <RoomWizard
                isLoading={isLoading}
                steps={[
                    {
                        title,
                        onCancel: onHide,
                        component: (
                            <RoomModalInputContainer>
                                <RoomModalTextInputLabel type="small" weight="medium" text="room.name" />
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Input
                                            data-testid="room-modal-name-input"
                                            value={value ?? ''}
                                            isTouched={!!value && value.length > 0}
                                            onChange={onChange}
                                            error={error?.message}
                                            disabled={!canEdit}
                                        />
                                    )}
                                />

                                {canEdit && (
                                    <LineContainer>
                                        <TableInput>
                                            <RoomModalTextInputLabel
                                                type="small"
                                                weight="medium"
                                                text="room.modal.single"
                                            />
                                            <LineContainer>
                                                <Input
                                                    onChange={handleOnChangeNewTableName}
                                                    placeholder={computeText(intl, 'room.modal.single.placeholder')}
                                                    error={addTableError?.single}
                                                />
                                                <SvyButton type="button" size="s" onClick={handleAddOneTable}>
                                                    {computeText(intl, 'button.add')}
                                                </SvyButton>
                                            </LineContainer>
                                        </TableInput>
                                        <TableInput>
                                            <RoomModalTextInputLabel
                                                type="small"
                                                weight="medium"
                                                text="room.modal.multiple"
                                            />
                                            <LineContainer>
                                                <Input
                                                    placeholder={computeText(intl, 'room.modal.multiple.placeholder1')}
                                                    onChange={handleOnChangeStart}
                                                    type="number"
                                                    min={0}
                                                    error={addTableError?.multi}
                                                />
                                                <Input
                                                    placeholder={computeText(intl, 'room.modal.multiple.placeholder2')}
                                                    onChange={handleOnChangeEnd}
                                                    type="number"
                                                    min={0}
                                                    error={addTableError?.multi}
                                                />
                                                <SvyButton type="button" size="s" onClick={handleAddMultipleTables}>
                                                    {computeText(intl, 'button.add')}
                                                </SvyButton>
                                            </LineContainer>
                                        </TableInput>
                                    </LineContainer>
                                )}

                                <RoomModalTextInputLabel type="small" weight="medium" text="room.modal.layout" />
                                <TableContainer>
                                    <TableItemContainer>
                                        {roomTables.map(({ name, positionX, positionY, ...rest }, index) => (
                                            <Draggable
                                                key={index}
                                                onDrag={handleDrag(name)}
                                                position={{ x: positionX, y: positionY }}
                                                disabled={!canEdit}
                                            >
                                                <TableItem canEdit={canEdit} {...rest}>
                                                    {name}
                                                    {canEdit && (
                                                        <DeleteItem
                                                            icon="close-line"
                                                            onClick={() => handleDelete(name)}
                                                        />
                                                    )}
                                                </TableItem>
                                            </Draggable>
                                        ))}
                                    </TableItemContainer>
                                </TableContainer>
                            </RoomModalInputContainer>
                        ),
                    },
                ]}
                errorMessage={stringifyError(serverError)}
                onValidate={onCreateValidate}
                canValidate={canEdit}
            />
        </RawModal>
    );
};
