import React, { Fragment, ReactElement } from 'react';
import { Listbox, Transition } from '@headlessui/react';

import { Text } from '@/components/atoms/Text';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Label } from '@/components/atoms/Label';
import {
    ListBoxOptionsContainer,
    OptionItem,
    SelectContainer,
    SpacedLabel,
    StyledListBox,
    StyledListBoxButton,
    StyledListBoxOptions,
} from './Select.style';

export type Option<T> = {
    value: T;
    label: string;
};

const stateToOptions = <T extends unknown>(value: T, options: Option<T>[]): Option<T> | undefined =>
    options.find((opt) => opt.value === value);

export type SelectProps<T> = {
    value?: T;
    options: Option<T>[];
    onChange: (value: T) => void;
    onBlur?: () => void;
    labelId?: string;
    isTouched?: boolean;
    showValidation?: boolean;
    solidListbox?: boolean;
    placeholder?: string;
    searchable?: boolean;
    disabled?: boolean;
    required?: boolean;
};

const Select = <T extends unknown>({
    value: selectionValue,
    options,
    onChange,
    onBlur,
    labelId,
    isTouched,
    showValidation = true,
    solidListbox = false,
    placeholder = '',
    disabled,
    required,
}: SelectProps<T>): ReactElement => {
    const selectedOption =
        selectionValue !== null && selectionValue !== undefined ? stateToOptions(selectionValue, options) : undefined;

    const handleOnChange = (value: T): void => {
        onBlur && onBlur();
        onChange(value);
    };

    return (
        <SelectContainer>
            {labelId && <Label disabled={disabled} labelId={labelId} inputId={labelId} required={required} />}
            <StyledListBox
                value={selectedOption?.value}
                onChange={handleOnChange}
                data-testid="io-select"
                disabled={disabled}
            >
                <div>
                    <StyledListBoxButton
                        istouched={isTouched?.toString()}
                        showValidation={showValidation}
                        data-testid="io-select-button"
                    >
                        <SpacedLabel text={selectedOption?.label || placeholder} weight="medium" type="small" />
                        <SVGIcon icon={SVGIcons.CHEVRON} size={20} />
                    </StyledListBoxButton>
                    <Transition as={Fragment}>
                        <ListBoxOptionsContainer solidListbox={solidListbox}>
                            <StyledListBoxOptions>
                                {options.map((option, idx) => (
                                    <Listbox.Option key={idx} value={option.value}>
                                        <OptionItem>
                                            <Text text={option.label} weight="bold" type="small" />
                                        </OptionItem>
                                    </Listbox.Option>
                                ))}
                            </StyledListBoxOptions>
                        </ListBoxOptionsContainer>
                    </Transition>
                </div>
            </StyledListBox>
        </SelectContainer>
    );
};

export default Select;
