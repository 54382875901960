import React, { useCallback, useMemo } from 'react';
import Modal from '@/components/atoms/Modal';
import { Button } from '@/components/atoms/Button';
import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { FilterConfig } from '@/services/innovorder/report/report.type';
import { ReportFilterFormValues } from '@/pages/Reporting/common/types';
import SaveFilterAs from './SaveFilterAs';
import FilterInput from './FilterInput';
import { FiltersModalForm } from './FiltersModal.style';

interface FiltersModalProps {
    close: () => void;
    filtersConfig: FilterConfig[];
    initialValues: ReportFilterFormValues;
    submitFilters: (filters: ReportFilterFormValues) => void;
    saveFilterAs: (name: string, filters: ReportFilterFormValues) => void;
}

const FiltersModal: React.FunctionComponent<FiltersModalProps> = ({
    close,
    filtersConfig,
    submitFilters,
    initialValues,
    saveFilterAs,
}) => {
    const filtersForm: UseFormReturn<ReportFilterFormValues> = useForm<ReportFilterFormValues>({
        defaultValues: initialValues,
        mode: 'onChange',
    });

    const submitFilter = useCallback(() => {
        submitFilters(filtersForm.getValues());
        close();
    }, [close, filtersForm, submitFilters]);

    const submitButton = useMemo(
        () => (
            <Button key="submit-button" buttonType="primary" onClick={submitFilter}>
                <FormattedMessage id="report.filtersModal.submit" />
            </Button>
        ),
        [submitFilter],
    );

    const onSaveFilterAs = useCallback(
        (name: string) => {
            saveFilterAs(name, filtersForm.getValues());
        },
        [filtersForm, saveFilterAs],
    );

    const saveFilter = useMemo(
        () => <SaveFilterAs key="save-filter-as-button" onSaveFilterAs={onSaveFilterAs} />,
        [onSaveFilterAs],
    );

    return (
        <Modal isOpen width={800} onHide={close} title="report.filtersModal.title" buttons={[saveFilter, submitButton]}>
            <FormProvider {...filtersForm}>
                <FiltersModalForm>
                    {filtersConfig.map((filterConfig) => {
                        return <FilterInput key={filterConfig.key} filterConfig={filterConfig} />;
                    })}
                </FiltersModalForm>
            </FormProvider>
        </Modal>
    );
};

export default FiltersModal;
