import { Routes } from '@/core/router/routes.types';
import React from 'react';
import { injectDataInUri } from '@/utils/routes';
import { ENV } from '@/utils/env';
import { Container, FieldContainer, StyledLabel, StyledLinkValue } from '../TransactionDetail.style';

type TransactionDetailOrderDataProps = {
    orderId: number;
};

const OrderData: React.FunctionComponent<React.PropsWithChildren<TransactionDetailOrderDataProps>> = ({ orderId }) => {
    return (
        <Container>
            <FieldContainer>
                <StyledLabel text="transaction.field.orderId" />
                <a
                    href={`${injectDataInUri({
                        backofficeUrl: ENV.STATIC_URL,
                        uri: Routes.OrderDetails,
                        orderId: orderId.toString(),
                    })}`}
                >
                    <StyledLinkValue>{orderId}</StyledLinkValue>
                </a>
            </FieldContainer>
        </Container>
    );
};

export default OrderData;
