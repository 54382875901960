import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';

export const Container = styled.div`
    flex: 1;
    padding: ${({ theme }) => theme.spacing[4]};
    background-color: ${({ theme }): string => theme.color.grey300};
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    margin: ${({ theme }) => theme.spacing[8]} 0;

    h2 {
        margin-bottom: ${({ theme }) => theme.spacing[2]};
    }
`;

export const Header = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    flex-direction: row;

    ${({ theme }) => theme.breakpoints.tablet} {
        flex-direction: column;
    }
`;

export const FlexGrow = styled.div`
    flex-grow: 1;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    max-width: 50%;
`;

export const Subtitle = styled(Text)`
    line-height: 20px;
`;
