import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const DeviceContainer = styled.div`
    width: calc(50% - ${({ theme }) => theme.spacing[1]});
    ${({ theme }) => theme.breakpoints.mobile} {
        width: 100%;
    }
`;

export const StyledLabel = styled(Text)`
    ${({ theme }) => theme.font.caption.bold};
    line-height: 20px;
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

export const StyledValue = styled.span`
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    color: ${({ theme }) => theme.color.black};
`;

export const StyledLinkValue = styled(StyledValue)`
    text-decoration: underline;
`;
