import { routes } from '@/core/router/routes';
import { Routes } from '@/core/router/routes.types';
import { useCreateCrossSellingMutation } from '@/services/innovorder/crossSelling/crossSelling.endpoint';
import { CrossSellingWithProducts } from '@/services/innovorder/crossSelling/crossSelling.type';
import { useMemo, useCallback, useEffect } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { CrossSellingRoutes } from '../CrossSelling.interface';

export const useCreateCrossSellingVM = () => {
    const history = useHistory();
    const { params } = useRouteMatch<{ brandId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);

    const tabRoutes = useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    const [createCrossSelling, { isLoading, isSuccess, data }] = useCreateCrossSellingMutation();

    const handleCancel = () => {
        history.push(generatePath(Routes.BrandCrossSelling, { brandId }));
    };

    const handleSubmit = useCallback(
        async ({ name, products }: CrossSellingWithProducts) => {
            createCrossSelling({
                brandId,
                name,
                status: 'disabled',
                crossSellingProducts: products.map(({ productId, crossSellingProduct: { position } }) => ({
                    productId,
                    position,
                })),
            });
        },
        [brandId, createCrossSelling],
    );

    useEffect(() => {
        if (isSuccess && data) {
            history.push(
                generatePath(`${Routes.BrandCrossSelling}${CrossSellingRoutes.Edit}`, {
                    brandId,
                    crossSellingId: data.crossSellingId,
                }),
            );
        }
    }, [data, isSuccess, brandId, history]);

    return {
        tabRoutes,
        isLoading,
        handleSubmit,
        handleCancel,
    };
};
