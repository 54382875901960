import React from 'react';

import { injectDataInUri } from '@/utils/routes';
import { ENV } from '@/utils/env';
import { Routes } from '@/core/router/routes.types';
import { Container, DeviceContainer, StyledLabel, StyledLinkValue } from './OrderDetailsTransaction.style';

type OrderDetailsTransactionProps = {
    transactions: number[];
};

const OrderDetailsTransaction: React.FunctionComponent<React.PropsWithChildren<OrderDetailsTransactionProps>> = ({
    transactions,
}) => {
    return (
        <Container>
            <DeviceContainer>
                <StyledLabel text="orderDetail.transaction.transactionIds" />
                {transactions.map<React.ReactNode>((transaction, index) => (
                    <React.Fragment key={transaction}>
                        <a
                            href={`${injectDataInUri({
                                backofficeUrl: ENV.STATIC_URL,
                                uri: Routes.IOPayTransactionDetail,
                                transactionId: transaction.toString(),
                            })}`}
                            key={transaction}
                        >
                            <StyledLinkValue>{transaction}</StyledLinkValue>
                        </a>
                        {index < transactions.length - 1 && ', '}
                    </React.Fragment>
                ))}
            </DeviceContainer>
        </Container>
    );
};

export default OrderDetailsTransaction;
