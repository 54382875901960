import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import React from 'react';
import { Input } from '@/components/atoms/Form/Input';

type PasswordFieldValues = Record<string, string>;

type PasswordInputProps = {
    rules: RegisterOptions<PasswordFieldValues, keyof PasswordFieldValues>;
    fieldName: string;
    isTouched: boolean;
};

const PasswordInput: React.FunctionComponent<React.PropsWithChildren<PasswordInputProps>> = ({
    rules,
    fieldName,
    isTouched,
}) => {
    const {
        control,
        formState: { touchedFields },
    } = useFormContext();
    return (
        <Controller
            control={control}
            name={fieldName}
            rules={rules}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Input
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={`customer.form.${fieldName}`}
                    labelId={`customer.form.${fieldName}`}
                    isTouched={isTouched ?? touchedFields[fieldName]}
                    type="password"
                    ref={ref}
                />
            )}
        />
    );
};

export default PasswordInput;
