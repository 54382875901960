import { getUserBrandId, getUserRestaurants, getUserRole } from '@/redux/user';
import {
    useFindAllModuleStripesByBrandQuery,
    useFindModuleStripesByRestaurantsQuery,
} from '@/services/innovorder/module_stripes/module_stripes.endpoints';
import { UserRestaurant, UserRoles } from '@/services/innovorder/user/user.type';
import { useSelector } from 'react-redux';

export type StripeModule = {
    label: string;
    value: number;
};

export type SelectEntityStepViewModel = {
    moduleStripes: StripeModule[];
};

export function useSelectEntityStepVM(): SelectEntityStepViewModel {
    const brandId = useSelector(getUserBrandId) as number;
    const userRestaurants = useSelector(getUserRestaurants) as UserRestaurant[];
    const userRole = useSelector(getUserRole) as UserRoles;
    const { data: stripeModules } =
        userRole === UserRoles.brand
            ? useFindAllModuleStripesByBrandQuery({ brandId })
            : useFindModuleStripesByRestaurantsQuery({
                  restaurantIds: userRestaurants.map((restaurant) => restaurant.restaurantId).join(','),
              });

    return {
        moduleStripes:
            stripeModules?.map((stripeModule) => ({
                label: stripeModule.label || 'billing.editConfiguration.selectEntityStep.noLabel',
                value: stripeModule.moduleStripeId,
            })) || [],
    };
}
