import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    padding: 20px;

    > div {
        margin-bottom: 20px;
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
