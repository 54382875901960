import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Routes } from '@/core/router/routes.types';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { AccessFlags } from '@/services/innovorder/auth/auth.type';
import { ReportMetadata } from 'services/innovorder/report/report.type';
import { LinkPayload, SideBarArea } from './dynamicMenu.type';

const getReportsSubMenuItems = (reportMetaData: ReportMetadata[] | undefined): LinkPayload[] => {
    let reportSubMenuItems = [
        {
            labelId: 'sidebar.reportsSales',
            roles: [UserRoles.brand, UserRoles.restaurant, UserRoles.admin],
            layoutArea: SideBarArea.middle,
            uri: ':backofficeUrl/home/reports/reports_pos/daily_order',
        },
        {
            labelId: 'sidebar.reportsEwallets',
            roles: [UserRoles.brand, UserRoles.restaurant, UserRoles.admin],
            layoutArea: SideBarArea.middle,
            uri: ':backofficeUrl/home/reports/reports_ewallet/by_users',
        },
    ];

    if (reportMetaData) {
        const dynamicReportsSubMenuItems = reportMetaData.map(({ name }) => ({
            labelId: `sidebar.${name}`,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.middle,
            uri: `/report/${name}`,
            enabledBy: 'reports-v2-enabled',
        }));
        reportSubMenuItems = reportSubMenuItems.concat(dynamicReportsSubMenuItems);
    }

    return reportSubMenuItems;
};

export const dynamicMenuData = (reportMetaData?: ReportMetadata[]): LinkPayload[] => {
    const reportsSubMenuItems = getReportsSubMenuItems(reportMetaData);

    return [
        {
            labelId: 'sidebar.news',
            onClick: () => {},
            layoutArea: SideBarArea.bottom,
            icon: SVGIcons.MEGAPHONE,
            roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
            class: 'beamerButton',
        },
        {
            labelId: 'sidebar.overview',
            uri: ':backofficeUrl/home/analytics/overview',
            icon: SVGIcons.DASHBOARD,
            roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.top,
        },
        {
            labelId: 'sidebar.ioPay',
            icon: SVGIcons.PAYMENT,
            roles: [UserRoles.admin, UserRoles.brand, UserRoles.restaurant],
            accessFlags: [AccessFlags.CAN_ACCESS_IO_PAY],
            layoutArea: SideBarArea.top,
            route: Routes.IOPayTransactions,
        },
        {
            labelId: 'sidebar.orders',
            icon: SVGIcons.ORDERS,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.top,
            route: Routes.Orders,
        },
        {
            labelId: 'sidebar.brands',
            icon: SVGIcons.BRAND,
            roles: [UserRoles.admin],
            layoutArea: SideBarArea.top,
            route: Routes.BrandsV2,
            enabledBy: 'use-new-brands-page',
        },
        {
            labelId: 'sidebar.brands',
            icon: SVGIcons.BRAND,
            roles: [UserRoles.admin],
            layoutArea: SideBarArea.top,
            uri: ':backofficeUrl/home/brands',
            disabledBy: 'use-new-brands-page',
        },
        {
            labelId: 'sidebar.marketplaces',
            icon: SVGIcons.MARKETPLACE,
            roles: [UserRoles.admin],
            layoutArea: SideBarArea.top,
            uri: ':backofficeUrl/home/marketplaces',
        },
        {
            labelId: 'sidebar.urssaf',
            icon: SVGIcons.URSAFF,
            roles: [UserRoles.admin],
            layoutArea: SideBarArea.top,
            uri: ':backofficeUrl/home/minimum_urssaf',
        },
        {
            labelId: 'sidebar.customers',
            icon: SVGIcons.CLIENTS,
            roles: [UserRoles.brand, UserRoles.restaurant],
            accessFlags: [AccessFlags.CAN_ACCESS_CUSTOMERS_SECTION],
            layoutArea: SideBarArea.top,
            uri: ':backofficeUrl/home/customers',
        },
        {
            labelId: 'sidebar.analytics',
            icon: SVGIcons.DASHBOARD,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.top,
            route: Routes.Analytics,
        },
        {
            labelId: 'sidebar.brand',
            icon: SVGIcons.BRAND,
            roles: [UserRoles.brand],
            layoutArea: SideBarArea.middle,
            uri: Routes.BrandChannelsV2,
        },
        {
            labelId: 'sidebar.restaurants',
            icon: SVGIcons.BRAND,
            roles: [UserRoles.restaurant],
            layoutArea: SideBarArea.middle,
            uri: ':backofficeUrl/home/restaurants',
        },
        {
            labelId: 'sidebar.loyalty',
            icon: SVGIcons.LOYALTY,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.middle,
            uri: ':backofficeUrl/home/loyalty/royalty',
        },
        {
            labelId: 'sidebar.coupons',
            icon: SVGIcons.DISCOUNT,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.middle,
            uri: ':backofficeUrl/home/promocodes/promotion_codes',
        },
        {
            labelId: 'sidebar.paymentMeans',
            icon: SVGIcons.PAYMENTS,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.middle,
            subMenu: 'restaurants',
            subMenuUri: ':backofficeUrl/home/payment/:restaurantId',
            forward: true,
        },
        {
            labelId: 'sidebar.integrations',
            icon: SVGIcons.INTEGRATIONS,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.middle,
            subMenu: 'restaurants',
            subMenuRoute: Routes.ConfigUbereats,
            forward: true,
        },
        {
            labelId: 'sidebar.reports',
            icon: SVGIcons.REPORTS,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.middle,
            subMenu: reportsSubMenuItems,
            forward: true,
        },
        {
            labelId: 'sidebar.exports',
            icon: SVGIcons.EXPORTS,
            roles: [UserRoles.brand, UserRoles.restaurant],
            layoutArea: SideBarArea.middle,
            subMenu: [
                {
                    labelId: 'sidebar.exportsAccounting',
                    roles: [UserRoles.brand, UserRoles.restaurant],
                    layoutArea: SideBarArea.middle,
                    uri: ':backofficeUrl/home/exports/accounting_export',
                },
                {
                    labelId: 'sidebar.exportsEwallets',
                    roles: [UserRoles.brand, UserRoles.restaurant],
                    layoutArea: SideBarArea.middle,
                    uri: ':backofficeUrl/home/exports/ewallet_export',
                    enabledBy: 'ewallet-export-enabled',
                },
                {
                    labelId: 'sidebar.exportsSales',
                    roles: [UserRoles.brand],
                    layoutArea: SideBarArea.middle,
                    uri: ':backofficeUrl/home/exports/sales_summary_export',
                    enabledBy: 'old-sales-summary-enabled',
                },
                {
                    labelId: 'sidebar.salesSummary',
                    roles: [UserRoles.brand],
                    layoutArea: SideBarArea.middle,
                    route: Routes.SalesSummary,
                    enabledBy: 'sales-summary-enabled',
                },
                {
                    labelId: 'sidebar.exportsPreInvoicing',
                    roles: [UserRoles.brand],
                    layoutArea: SideBarArea.middle,
                    uri: ':backofficeUrl/home/exports/pre_invoicing_export',
                    enabledBy: 'education-mode-enabled',
                },
                {
                    labelId: 'sidebar.exportsBadge',
                    roles: [UserRoles.brand],
                    layoutArea: SideBarArea.middle,
                    uri: ':backofficeUrl/home/exports/badge_balance_export',
                    enabledBy: 'education-mode-enabled',
                },
                {
                    labelId: 'sidebar.reportGenerationsHistory',
                    roles: [UserRoles.brand, UserRoles.restaurant],
                    layoutArea: SideBarArea.middle,
                    route: Routes.ReportGenerationsHistory,
                },
            ],
            forward: true,
        },
        {
            labelId: 'sidebar.languages',
            icon: SVGIcons.LANGUAGES,
            roles: [UserRoles.brand, UserRoles.restaurant, UserRoles.admin],
            layoutArea: SideBarArea.bottom,
            subMenu: 'languages',
            forward: true,
        },
        {
            labelId: 'sidebar.account',
            icon: SVGIcons.ACCOUNT,
            roles: [UserRoles.brand, UserRoles.restaurant, UserRoles.admin],
            layoutArea: SideBarArea.bottom,
            subMenu: [
                {
                    labelId: 'sidebar.billing',
                    roles: [UserRoles.brand, UserRoles.restaurant],
                    layoutArea: SideBarArea.bottom,
                    route: Routes.Billing,
                    enabledBy: 'billing-space-enabled',
                },
                {
                    labelId: 'sidebar.password',
                    roles: [UserRoles.brand, UserRoles.restaurant, UserRoles.admin],
                    layoutArea: SideBarArea.bottom,
                    uri: ':backofficeUrl/home/my_account/password',
                },
                {
                    labelId: 'sidebar.logout',
                    roles: [UserRoles.brand, UserRoles.restaurant, UserRoles.admin],
                    layoutArea: SideBarArea.bottom,
                    route: Routes.Logout,
                },
            ],
            forward: true,
        },
        {
            labelId: 'sidebar.autoBilling',
            icon: SVGIcons.BUBBLES,
            roles: [UserRoles.brand],
            layoutArea: SideBarArea.middle,
            uri: '/brands/:brandId/auto_billing',
            enabledBy: 'auto-billing-enabled',
        },
    ];
};
