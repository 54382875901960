import { generateQueryStringFromParams } from '@/utils/history';
import { ApiTagTypes, innovorderApi } from '../index';
import { ApiResponse } from '../request.types';
import {
    CreateCrossSellingPayload,
    CreateCrossSellingResponse,
    DuplicateCrossSellingPayload,
    DuplicateCrossSellingResponse,
    GetCrossSellingListPayload,
    GetCrossSellingListResponse,
    GetProductListByBrandIdPayload,
    GetProductListByBrandIdResponse,
    GetCrossSellingPayload,
    GetCrossSellingResponse,
    UpdateCrossSellingPayload,
    UpdateCrossSellingResponse,
    UpdateCrossSellingStatusResponse,
    UpdateCrossSellingStatusPaylaod,
    DeleteCrossSellingPayload,
    DeleteCrossSellingResponse,
} from './crossSelling.type';

const crossSellingApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        createCrossSelling: build.mutation<CreateCrossSellingResponse, CreateCrossSellingPayload>({
            query: ({ ...body }) => {
                return {
                    url: `/cross-selling`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [ApiTagTypes.CrossSelling],
            transformResponse: (response: ApiResponse<CreateCrossSellingResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        duplicateCrossSelling: build.mutation<DuplicateCrossSellingResponse, DuplicateCrossSellingPayload>({
            query: ({ crossSellingId }) => {
                return {
                    url: `/cross-selling/${crossSellingId}/duplicate`,
                    method: 'POST',
                };
            },
            invalidatesTags: [ApiTagTypes.CrossSelling],
            transformResponse: (response: ApiResponse<DuplicateCrossSellingResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getCrossSellingList: build.query<GetCrossSellingListResponse, GetCrossSellingListPayload>({
            query: ({ brandId, restaurantId }) => {
                const queryParameters = generateQueryStringFromParams({ brandId, restaurantId });

                return {
                    url: `/cross-selling?${queryParameters}`,
                    method: 'GET',
                };
            },
            providesTags: [ApiTagTypes.CrossSelling],
            transformResponse: (response: ApiResponse<GetCrossSellingListResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getProductListByBrandId: build.query<GetProductListByBrandIdResponse, GetProductListByBrandIdPayload>({
            query: ({ brandId }) => {
                return {
                    url: `/cross-selling/brands/${brandId}/products`,
                    method: 'GET',
                };
            },
            providesTags: [ApiTagTypes.CrossSelling],
            transformResponse: (response: ApiResponse<GetProductListByBrandIdResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getCrossSelling: build.query<GetCrossSellingResponse, GetCrossSellingPayload>({
            query: ({ crossSellingId }) => ({
                url: `/cross-selling/${crossSellingId}`,
                method: 'GET',
            }),
            providesTags: [ApiTagTypes.CrossSelling],
            transformResponse: (response: ApiResponse<GetCrossSellingResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        updateCrossSelling: build.mutation<UpdateCrossSellingResponse, UpdateCrossSellingPayload>({
            query: ({ crossSellingId, ...body }) => {
                return {
                    url: `/cross-selling/${crossSellingId}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: [ApiTagTypes.CrossSelling],
            transformResponse: (response: ApiResponse<UpdateCrossSellingResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        updateCrossSellingStatus: build.mutation<UpdateCrossSellingStatusResponse, UpdateCrossSellingStatusPaylaod>({
            query: ({ crossSellingId }) => {
                return {
                    url: `/cross-selling/${crossSellingId}/status`,
                    method: 'PUT',
                };
            },
            invalidatesTags: [ApiTagTypes.CrossSelling],
            transformResponse: (response: ApiResponse<UpdateCrossSellingStatusResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        deleteCrossSelling: build.mutation<DeleteCrossSellingResponse, DeleteCrossSellingPayload>({
            query: ({ crossSellingId }) => {
                return {
                    url: `/cross-selling/${crossSellingId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [ApiTagTypes.CrossSelling],
        }),
    }),
});

export const {
    useCreateCrossSellingMutation,
    useDuplicateCrossSellingMutation,
    useGetCrossSellingListQuery,
    useGetProductListByBrandIdQuery,
    useGetCrossSellingQuery,
    useUpdateCrossSellingMutation,
    useUpdateCrossSellingStatusMutation,
    useDeleteCrossSellingMutation,
} = crossSellingApi;
