export enum ChannelId {
    KIOSK = 1,
    WEB = 2,
    RECEPTION = 4,
    POS = 5,
    LOYALTY = 6,
    EWALLET_KIOSK = 7,
    VENDING_MACHINE = 8,
    UBER_EATS = 9,
    DELIVEROO = 10,
    JUST_EAT = 11,
}

export type GetRestaurantChannelsPayload = {
    restaurantId: string;
};
export type GetBrandChannelsPayload = {
    brandId: string;
};

export type Channel = {
    channelId: ChannelId;
    name: string;
    is_enabled: boolean;
    isReportSource: boolean;
    canBeBlocked: boolean;
    restaurant_channel: RestaurantChannel;
    created_at: Date;
    updated_at: Date;
};

export type GetChannelsResponse = {
    [key: number]: Channel;
};

type RestaurantChannel = {
    channelId: ChannelId;
    restaurantId: number;
    created_at: Date;
    updated_at: Date;
};

export type ActivateChannelOnBrandPayload = {
    brandId: string;
    channelId: string;
};
export type ActivateChannelOnRestaurantPayload = {
    restaurantId: string;
    channelId: string;
};
export type GetKdsPayload = {
    restaurantId: string;
};

export type GetRestaurantKdsResponse = {
    kitchenDisplayId: number;
    isTagsWhiteListEnabled: boolean;
    isAssembleEnabled: boolean;
    isDisplayEnabled: boolean;
    isAutoDisplayEnabled: boolean;
    isDefaultWaitingModeEnabled: boolean;
    isGroupingByTableNameEnabled: boolean;
    tagsWhiteList: string;
    minutesBeforeEmergency: number;
    emergencyColor: string;
    minutesBeforeFirstRecall: number;
    firstRecallColor: string;
    minutesBeforeDisplayTakeAway: number;
    minutesBeforeDisplayDelivering: number;
    minutesBeforeDisplayEatIn: number;
    odsTitleColor: string;
    odsHeaderColor: string;
    odsBackgroundColor: string;
    odsLogoImage: string | null;
    odsBackgroundImage: string | null;
    brandId: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
};
