import * as React from 'react';
import ReactSelect, { OnChangeValue } from 'react-select';

import { useReactSelectCustomStyles } from '@/hooks/react-select/useReactSelectCustomStyles';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { StyledErrorMessage } from './MultiSelect.style';
import { SelectOptionInterface, MultiSelectProps } from './MultiSelect.interface';

const SELECT_ALL_VALUE = 'all';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiSelect = <T extends any>({
    options: optionsProp,
    onChange,
    placeholder,
    value,
    isLoading,
    getOptionValue,
    menuIsOpen,
    isMulti,
    components,
    inputId,
    defaultValue,
    error,
    isTouched,
    onBlur,
    customStyle,
    showErrorMessage,
    isDisabled,
    canSelectAll,
    ...otherProps
}: MultiSelectProps<T>): ReturnType<React.FC<MultiSelectProps<T>>> => {
    const intl = useIntl();
    const customStyles = useReactSelectCustomStyles<any, true, any>(customStyle);
    const handleOnChange = (onChangeOptions: OnChangeValue<SelectOptionInterface<T>, true>): void => {
        onBlur?.();
        if (onChange) {
            if (Array.isArray(onChangeOptions) && onChangeOptions.some((option) => option.value === SELECT_ALL_VALUE)) {
                return onChange(optionsProp);
            }
            return onChange(onChangeOptions);
        }
        return undefined;
    };

    const canShowErrorMessage = showErrorMessage && error?.message;

    const options = React.useMemo(() => {
        if (isMulti && canSelectAll && (value?.length ?? 0) < optionsProp.length) {
            return [{ value: SELECT_ALL_VALUE, label: computeText(intl, 'select.selectAll') }, ...optionsProp];
        }
        return optionsProp;
    }, [isMulti, canSelectAll, value?.length, optionsProp, intl]);

    return isMulti ? (
        <ReactSelect
            isMulti
            getOptionValue={getOptionValue}
            options={options}
            onChange={handleOnChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            isLoading={isLoading}
            styles={customStyles}
            menuIsOpen={menuIsOpen}
            components={components}
            inputId={inputId}
            defaultValue={defaultValue}
            error={error}
            isTouched={isTouched}
            isDisabled={isDisabled}
            isClearable={value?.some((v) => !v.isFixed)}
            {...otherProps}
        />
    ) : (
        <>
            <ReactSelect
                {...otherProps}
                getOptionValue={getOptionValue}
                options={options}
                onChange={handleOnChange}
                onBlur={onBlur}
                placeholder={placeholder}
                isLoading={isLoading}
                styles={customStyles}
                menuIsOpen={menuIsOpen}
                components={components}
                defaultValue={defaultValue}
                inputId={inputId}
                error={error}
                isTouched={isTouched}
                value={value}
                isDisabled={isDisabled}
            />
            {canShowErrorMessage && <StyledErrorMessage>{error?.message}</StyledErrorMessage>}
        </>
    );
};

export default MultiSelect;
