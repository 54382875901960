import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Table } from '@/components/atoms/Table';
import { Badge } from '@/services/innovorder/customer/customer.types';
import { getColumns, getRows } from './Table.utils';

export type BadgesProps = {
    badges: Badge[];
    onEdit?: (badge: Badge) => void;
    onBlock?: (badge: Badge) => void;
    onDelete?: (badge: Badge) => void;
};

const Student: React.FunctionComponent<React.PropsWithChildren<BadgesProps>> = ({
    badges,
    onEdit,
    onBlock,
    onDelete,
}) => {
    const intl = useIntl();

    const columns = useMemo(() => getColumns(intl), [intl]);
    const rows = useMemo(
        () => getRows(badges, intl, onDelete, onBlock, onEdit),
        [badges, intl, onDelete, onBlock, onEdit],
    );

    return <Table rows={rows} columns={columns} emptyMessageId="table.noRowsAfterFilter" />;
};

export default Student;
