export enum CrousList {
    AixMarseille = 13,
    Amiens = 80,
    AntillesGuyane = 971,
    BFC = 15,
    Besancon = 25,
    Bordeaux = 5,
    Caen = 14,
    ClermontFerrand = 63,
    Corte = 20,
    Creteil = 94,
    Dijon = 21,
    Grenoble = 38,
    LaReunion = 974,
    Lille = 59,
    Limoges = 87,
    Lyon = 69,
    Montpellier = 34,
    NancyMetz = 54,
    Nantes = 44,
    Nice = 6,
    Normandie = 90,
    OrleansTours = 45,
    Paris = 75,
    Poitiers = 86,
    Reims = 51,
    Rennes = 35,
    Rouen = 76,
    Strasbourg = 67,
    Toulouse = 31,
    Versailles = 78,
}

export const crousMap: { label: string; value: CrousList }[] = [
    { label: 'Crous de Aix-Marseille', value: CrousList.AixMarseille },
    { label: 'Crous de Amiens', value: CrousList.Amiens },
    { label: 'Crous de Antilles-Guyane', value: CrousList.AntillesGuyane },
    { label: 'Crous de BFC', value: CrousList.BFC },
    { label: 'Crous de Besançon', value: CrousList.Besancon },
    { label: 'Crous de Bordeaux', value: CrousList.Bordeaux },
    { label: 'Crous de Caen', value: CrousList.Caen },
    { label: 'Crous de Clermont-Ferrand', value: CrousList.ClermontFerrand },
    { label: 'Crous de Corte', value: CrousList.Corte },
    { label: 'Crous de Créteil', value: CrousList.Creteil },
    { label: 'Crous de Dijon', value: CrousList.Dijon },
    { label: 'Crous de Grenoble', value: CrousList.Grenoble },
    { label: 'Crous de La Réunion', value: CrousList.LaReunion },
    { label: 'Crous de Lille', value: CrousList.Lille },
    { label: 'Crous de Limoges', value: CrousList.Limoges },
    { label: 'Crous de Lyon', value: CrousList.Lyon },
    { label: 'Crous de Montpellier', value: CrousList.Montpellier },
    { label: 'Crous de Nancy-Metz', value: CrousList.NancyMetz },
    { label: 'Crous de Nantes', value: CrousList.Nantes },
    { label: 'Crous de Nice', value: CrousList.Nice },
    { label: 'Crous de Normandie', value: CrousList.Normandie },
    { label: 'Crous de Orléans-Tours', value: CrousList.OrleansTours },
    { label: 'Crous de Paris', value: CrousList.Paris },
    { label: 'Crous de Poitiers', value: CrousList.Poitiers },
    { label: 'Crous de Reims', value: CrousList.Reims },
    { label: 'Crous de Rennes', value: CrousList.Rennes },
    { label: 'Crous de Rouen', value: CrousList.Rouen },
    { label: 'Crous de Strasbourg', value: CrousList.Strasbourg },
    { label: 'Crous de Toulouse', value: CrousList.Toulouse },
    { label: 'Crous de Versailles', value: CrousList.Versailles },
];
