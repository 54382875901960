export enum PosDeviceRole {
    Master = 'master',
    Slave = 'slave',
    Independent = 'independent',
}

export interface PosDevice {
    posDeviceId: number;
    deviceName: string;
    supportPinCode: string;
    defaultLanguage: string | null;
    deviceId: string;
    deviceToken: string | null;
    backendId: string | null;
    menuId: number | null;
    legacyId: string | null;
    catalogId: string | null;
    lastKnownVersion: string | null;
    posDbVersion: number | null;
    role: PosDeviceRole;
    moduleRestoFlashId: number | null;
    modulePosDeviceId: number;
    brandId: number;
    restaurantId: number;
    ticketNumberPrefix: string;
    useStaticIpRabbitMQ: boolean;

    created_at: Date;
    updated_at: Date | null;
    deleted_at: Date | null;
}

export type GetPosDevicesParams = {
    brandId: number;
    restaurantId?: number;
};
