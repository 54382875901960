import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import { computeText } from '@/locales/utils';
import { Input } from '@/components/atoms/Form/Input';
import { Table } from '@/components/atoms/Table';
import { Switch } from '@/components/atoms/Switch';
import { SavingBar } from '@/components/atoms/SavingBar';
import { theme } from '@/core/theme';
import Loader from '@/components/atoms/Loader';
import PermissionDenied from '@/pages/PermissionDenied';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { useGetMenusQuery, usePostMenusRevaluationsMutation } from '@/services/innovorder/menu/menu.endpoints';
import { getUserToken } from '@/redux/user';
import { getUseOpsBulkTools } from '@/redux/launchDarkly';
import { ENV } from '@/utils/env';
import { routes } from '@/core/router/routes';
import {
    Container,
    HeaderContainer,
    Subtitle,
    Header,
    HeaderLeft,
    HeaderRight,
    CheckboxContainer,
} from './PricingRevaluation.style';
import { getMenusPricingRevaluationTableColumnsVM, getMenusPricingRevaluationTableRowsVM } from './utils';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
};

const PricingRevaluation: React.FunctionComponent<React.PropsWithChildren> = () => {
    const [selectedMenuIds, setSelectedMenuIds] = useState<number[]>([]);
    const shouldUseOpsBulkTool = useSelector(getUseOpsBulkTools);
    const { brandId, restaurantId: paramRestaurantId } = useParams<ParamTypes>();
    const restaurantId = parseInt(paramRestaurantId, 10);
    const token = useSelector(getUserToken);
    const intl = useIntl();

    const BOV1 = ENV.BACKOFFICE_URL;

    const { data: menus, isLoading } = useGetMenusQuery({ restaurantId });
    const [postMenuRevaluation, { isSuccess, isLoading: isPostLoading }] = usePostMenusRevaluationsMutation();

    type PricingRevaluationInputs = {
        revaluationRate: number;
        useOptions: boolean;
        useExceedings: boolean;
    };
    const { control, handleSubmit, clearErrors, trigger } = useForm<PricingRevaluationInputs>({
        defaultValues: {
            revaluationRate: 0,
            useOptions: false,
            useExceedings: false,
        },
    });
    const onSubmit: SubmitHandler<PricingRevaluationInputs> = (data) => {
        const reevalutedRate = data.revaluationRate * 100;
        postMenuRevaluation({
            ...data,
            menuIds: selectedMenuIds,
            revaluationRate: reevalutedRate,
            token,
        });
    };

    const submit: SubmitHandler<PricingRevaluationInputs> = (data) => {
        onSubmit(data);
    };

    const handleRateChange =
        (onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, onBlur: () => void) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onBlur();
            onChange(event);
            trigger('revaluationRate');
        };

    const handleOptionsChange =
        (optionName: 'useOptions' | 'useExceedings', onChange: (...event: any[]) => void, onBlur: () => void) =>
        (options: boolean) => {
            onBlur();
            clearErrors(optionName);
            onChange(options);
            trigger(optionName);
        };

    const handleOnInputChange = (id: string) => {
        if (!id) return;
        if (!selectedMenuIds.includes(parseInt(id, 10))) {
            setSelectedMenuIds((prevSelectedMenuIds) => [...prevSelectedMenuIds, parseInt(id, 10)]);
        } else {
            setSelectedMenuIds((prevSelectedMenuIds) =>
                prevSelectedMenuIds.filter((idMenu) => idMenu !== parseInt(id, 10)),
            );
        }
    };
    const menusRows = getMenusPricingRevaluationTableRowsVM(menus, intl, handleOnInputChange, selectedMenuIds);
    const menusDataColumns = getMenusPricingRevaluationTableColumnsVM(intl);

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'restaurants'), []);

    if (isLoading) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    if (isSuccess) {
        const url = new URL(`/home/brands/${brandId}/restaurants/${restaurantId}/menus`, BOV1);
        window.location.assign(url.href);
    }

    return shouldUseOpsBulkTool ? (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="pricingRevaluation.tableCard.title" />
                            <Subtitle>
                                <Text text="pricingRevaluation.tableCard.subtitle" />
                            </Subtitle>
                        </HeaderLeft>
                        <HeaderRight>
                            <Controller
                                control={control}
                                name="revaluationRate"
                                rules={{ required: computeText(intl, 'field.required') }}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <Input
                                        error={error?.message}
                                        onChange={handleRateChange(onChange, onBlur)}
                                        onBlur={handleRateChange(onChange, onBlur)}
                                        type="number"
                                        placeholder="pricingRevaluation.input.placeholder"
                                        labelId="pricingRevaluation.input.placeholder"
                                        value={value.toString()}
                                        unitType="percentage"
                                        min="-100"
                                        step="0.01"
                                        name="revaluationRate"
                                    />
                                )}
                            />
                            <CheckboxContainer>
                                <Controller
                                    control={control}
                                    name="useExceedings"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Switch
                                            onChange={handleOptionsChange('useExceedings', onChange, onBlur)}
                                            labelId="pricingRevaluation.options.exceeding"
                                            value={value}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="useOptions"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Switch
                                            value={value}
                                            labelId="pricingRevaluation.options.options"
                                            onChange={handleOptionsChange('useOptions', onChange, onBlur)}
                                        />
                                    )}
                                />
                            </CheckboxContainer>
                        </HeaderRight>
                    </Header>

                    <Table
                        rows={menusRows}
                        columns={menusDataColumns}
                        emptyMessageId="pricingRevaluation.table.emptyMessage"
                        isSearchable={true}
                        searchPlaceholder="pricingRevaluation.table.search"
                        hasPagination={true}
                    />
                </HeaderContainer>
            </Container>
            <SavingBar onSave={handleSubmit(submit)} loading={isPostLoading} disabled={isPostLoading} />
        </RouterNavigationTabs>
    ) : (
        <PermissionDenied />
    );
};

export { PricingRevaluation };
