import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Table } from '@/components/atoms/Table';
import { TableRow } from '@/components/atoms/Table/Table.type';
import Tab from '@/components/organisms/Tabs/Tab';
import Tabs from '@/components/organisms/Tabs/Tabs';
import DeleteModal from '@/components/atoms/DeleteModal';
import { Routes } from '@/core/router/routes.types';
import { computeText } from '@/locales/utils';
import { useGetBrandEntranceFeesQuery, useGetBrandGrantsQuery } from '@/services/innovorder/brand/brand.endpoints';
import { useGetMinimalPricingRulesQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { getUseAssociatedPricingRules } from '@/redux/launchDarkly';
import { getUserToken } from '@/redux/user';

import { getEntranceFees, getGrants } from '@/redux/pricingRuleManage';
import { Container } from './PricingRuleComposition.style';
import { SearchSelectOptionType, SelectSearch } from './SelectSearch';
import {
    computeAssociatedPricingRulesSelectOptionsVM,
    computeComponentsWithoutTurnoverSelectOptionsVM,
    computeEntranceFeesSelectOptionsVM,
    computeGrantsSelectOptionsVM,
    getGrantsFeesTableColumnsVM,
    getPricingRuleAssociatedPricingRulesTableRowsVM,
    getPricingRuleComponentsWithoutTurnoverTableRowsVM,
    getPricingRuleCompositionTableColumnsVM,
    getPricingRuleEntranceFeesTableRowsVM,
    getPricingRuleGrantsTableRowsVM,
    handleSelectAssociatedPricingRuleOptionVM,
    handleSelectComponentWithoutTurnoverOptionVM,
    handleSelectEntranceFeeOptionVM,
    handleSelectGrantOptionVM,
    reorderGrantsAfterDragVM,
} from './utils';

interface ParamTypes {
    brandId: string;
    pricingRuleId: string;
}

export const PricingRuleComposition: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { brandId, pricingRuleId } = useParams<ParamTypes>();
    const token = useSelector(getUserToken);
    const useAssociatedPricingRules = useSelector(getUseAssociatedPricingRules);
    const { data: grants } = useGetBrandGrantsQuery({
        brandId: parseInt(brandId, 10),
        token,
    });
    const { data: entranceFees } = useGetBrandEntranceFeesQuery({
        brandId: parseInt(brandId, 10),
        token,
    });
    const { data: pricingRules } = useGetMinimalPricingRulesQuery({
        brandId: parseInt(brandId, 10),
        token,
    });

    const [idToDelete, setIdToDelete] = React.useState<number | null>(null);
    const [removeAction, setRemoveAction] = React.useState<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload | null
    >(null);

    const handleOpenModal = (
        id: number,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action: ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload,
    ): void => {
        setIdToDelete(id);
        setRemoveAction(() => action);
    };

    const handleCloseModal = () => {
        setIdToDelete(null);
        setRemoveAction(null);
    };

    const handleDelete = () => {
        idToDelete && removeAction && dispatch(removeAction(idToDelete));
        setIdToDelete(null);
        setRemoveAction(null);
    };

    const grantsFeesTableColumns = React.useMemo(() => getGrantsFeesTableColumnsVM(intl), [intl]);
    const pricingRuleCompositionTableColumns = React.useMemo(
        () => getPricingRuleCompositionTableColumnsVM(intl),
        [intl],
    );
    const localGrants = useSelector(getGrants);
    const pricingRuleGrantsTableRows = React.useMemo(
        () => getPricingRuleGrantsTableRowsVM(localGrants, handleOpenModal, intl),
        [intl, localGrants],
    );
    const localEntranceFees = useSelector(getEntranceFees);
    const pricingRuleEntranceFeesTableRows = React.useMemo(
        () => getPricingRuleEntranceFeesTableRowsVM(localEntranceFees, handleOpenModal, intl),
        [intl, localEntranceFees],
    );
    const pricingRuleComponentsWithoutTurnoverTableRows: TableRow[] = useSelector(
        getPricingRuleComponentsWithoutTurnoverTableRowsVM(handleOpenModal, intl),
    );
    const pricingRuleAssociatedPricingRulesTableRows: TableRow[] = useSelector(
        getPricingRuleAssociatedPricingRulesTableRowsVM(handleOpenModal, intl),
    );

    const reorderGrantsAfterDrag = useSelector(reorderGrantsAfterDragVM(dispatch));

    const grantsSelectOptions: SearchSelectOptionType[] = useSelector(computeGrantsSelectOptionsVM(grants));
    const entranceFeesSelectOptions: SearchSelectOptionType[] = useSelector(
        computeEntranceFeesSelectOptionsVM(entranceFees),
    );
    const componentsWithoutTurnoverSelectOptions: SearchSelectOptionType[] = useSelector(
        computeComponentsWithoutTurnoverSelectOptionsVM(entranceFees),
    );
    const associatedPricingRulesSelectOptions: SearchSelectOptionType[] = useSelector(
        computeAssociatedPricingRulesSelectOptionsVM(pricingRules, parseInt(pricingRuleId, 10)),
    );

    const handleSelectGrantOption = (option: SearchSelectOptionType) =>
        handleSelectGrantOptionVM(dispatch, grants, option, pricingRuleGrantsTableRows.length);
    const handleSelectEntranceFeeOption = (option: SearchSelectOptionType) =>
        handleSelectEntranceFeeOptionVM(dispatch, entranceFees, option);
    const handleSelectComponentWithoutTurnoverOption = (option: SearchSelectOptionType) =>
        handleSelectComponentWithoutTurnoverOptionVM(dispatch, entranceFees, option);
    const handleSelectAssociatedPricingRuleOption = (option: SearchSelectOptionType) =>
        handleSelectAssociatedPricingRuleOptionVM(dispatch, pricingRules, option);

    return (
        <Container>
            <DeleteModal
                title="pricingRule.modal.warning"
                description="pricingRule.modal.confirmDelete"
                isOpen={idToDelete !== null}
                onHide={handleCloseModal}
                onSubmit={handleDelete}
            />
            <Tabs>
                <Tab
                    title={`${computeText(intl, 'pricingRule.entranceFees')} (${
                        pricingRuleEntranceFeesTableRows.length
                    })`}
                    component={
                        <>
                            <SelectSearch
                                options={entranceFeesSelectOptions}
                                onSelectOption={handleSelectEntranceFeeOption}
                                placeholderId="pricingRule.addEntranceFee"
                                noOptionsMessageId="pricingRule.noResult"
                            />
                            <Table
                                key="EntranceFee"
                                rows={pricingRuleEntranceFeesTableRows}
                                columns={grantsFeesTableColumns}
                                emptyMessageId="pricingRule.emptyEntranceFee"
                                onRowClickPath={Routes.EntranceFee}
                                hasPagination
                            />
                        </>
                    }
                />
                <Tab
                    title={`${computeText(intl, 'pricingRule.grants')} (${pricingRuleGrantsTableRows.length})`}
                    component={
                        <>
                            <SelectSearch
                                options={grantsSelectOptions}
                                onSelectOption={handleSelectGrantOption}
                                placeholderId="pricingRule.addGrant"
                                noOptionsMessageId="pricingRule.noResult"
                            />
                            <Table
                                key="Grant"
                                rows={pricingRuleGrantsTableRows}
                                columns={grantsFeesTableColumns}
                                emptyMessageId="pricingRule.emptyGrant"
                                reorderAfterDrag={reorderGrantsAfterDrag}
                                onRowClickPath={Routes.Grant}
                                hasPagination
                            />
                        </>
                    }
                />
                <Tab
                    title={`${computeText(intl, 'pricingRule.excludedTurnover')} (${
                        pricingRuleComponentsWithoutTurnoverTableRows.length
                    })`}
                    component={
                        <>
                            <SelectSearch
                                options={componentsWithoutTurnoverSelectOptions}
                                onSelectOption={handleSelectComponentWithoutTurnoverOption}
                                placeholderId="pricingRule.addComponentWithoutTurnover"
                                noOptionsMessageId="pricingRule.noResult"
                            />
                            <Table
                                key="TurnoverExcluded"
                                rows={pricingRuleComponentsWithoutTurnoverTableRows}
                                columns={pricingRuleCompositionTableColumns}
                                emptyMessageId="pricingRule.emptyTurnoverExcluded"
                                onRowClickPath={Routes.EntranceFee}
                                hasPagination
                            />
                        </>
                    }
                />
                {useAssociatedPricingRules && (
                    <Tab
                        title={`${computeText(intl, 'pricingRule.associatedPricingRules')} (${
                            pricingRuleAssociatedPricingRulesTableRows.length
                        })`}
                        component={
                            <>
                                <SelectSearch
                                    options={associatedPricingRulesSelectOptions}
                                    onSelectOption={handleSelectAssociatedPricingRuleOption}
                                    placeholderId="pricingRule.addAssociatedPricingRule"
                                    noOptionsMessageId="pricingRule.noResult"
                                />
                                <Table
                                    key="PricingRule"
                                    rows={pricingRuleAssociatedPricingRulesTableRows}
                                    columns={pricingRuleCompositionTableColumns}
                                    emptyMessageId="pricingRule.emptyAssociatedPricingRule"
                                    onRowClickPath={Routes.PricingRule}
                                    hasPagination
                                />
                            </>
                        }
                    />
                )}
            </Tabs>
        </Container>
    );
};
