import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { getUserToken } from '@/redux/user';

import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { ActionBar } from '@/components/atoms/ActionBar';
import { Text } from '@/components/atoms/Text';
import { NavigationContext, NavigationParametersEnum, Routes } from '@/core/router/routes.types';
import {
    useCancelOrderMutation,
    useGetOrderDetailsQuery,
    useGetTransactionsByOrderQuery,
    useRefundOrderMutation,
} from '@/services/innovorder/orders/order.endpoints';
import { stringifyError } from '@/utils/errors';
import { CancelOrderReason } from '@/services/innovorder/orders/order.enum';
import { SvyButton } from '@/components/savory/SvyButton';
import Card from '../../../components/molecules/Card';
import Loader from '../../../components/atoms/Loader';
import {
    Container,
    DetailContainer,
    MainDetailContainer,
    SideDetailContainer,
    ActionBarButton,
} from './OrderDetails.style';
import OrderDetailsCancelConfirmation from './OrderDetailsCancelConfirmation';
import OrderDetailsCustomer from './OrderDetailsCustomer';
import OrderDetailsDelivery from './OrderDetailsDelivery';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsPayment from './OrderDetailsPayment';
import OrderDetailsPos from './OrderDetailsPos';
import OrderDetailsTax from './OrderDetailsTax';
import OrderDetailsTicket from './OrderDetailsTicket';
import OrderDetailsPhotos from './OrderDetailsPhotos/OrderDetailsPhotos';
import OrderDetailsTransaction from './OrderDetailsTransaction/OrderDetailsTransaction';

type ParamTypes = Required<Pick<NavigationContext, NavigationParametersEnum.orderId>>;

const OrderDetails: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const { orderId } = useParams<ParamTypes>();
    const token = useSelector(getUserToken);
    const {
        refetch: refetchOrderDetails,
        data: order,
        isLoading: orderIsLoading,
        error: orderError,
    } = useGetOrderDetailsQuery({ orderId });

    const shouldSkipGetTransactionsByOrderQuery = () => {
        if (!order || orderIsLoading) return true;
        if (Number(order?.channelId) === ChannelId.WEB || Number(order?.channelId) === ChannelId.KIOSK) return false;
        return true;
    };

    const { data: transactions, isLoading: transactionsIsLoading } = useGetTransactionsByOrderQuery(
        {
            orderId,
        },
        { skip: shouldSkipGetTransactionsByOrderQuery() },
    );
    const [refundOrder, { isLoading: isRefunding, isSuccess: isRefunded, error: refundError }] =
        useRefundOrderMutation();
    const [cancelOrder, { isLoading: isCancelling, isSuccess: isCancelled, error: cancelError }] =
        useCancelOrderMutation();
    const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);
    const isCancellable = order?.isCancellable;
    const isRefundable = order?.isRefundable && !order?.isCancellable;
    const channelId = order?.channelId;

    const theme = useTheme();

    useEffect(() => {
        if (isRefunded || isCancelled) {
            refetchOrderDetails();
        }
    }, [isRefunded, isCancelled, orderId, token, refetchOrderDetails]);

    const toggleCancelModal = useCallback(() => {
        setIsCancelModalOpened((state) => !state);
    }, []);

    const handleCancelConfirmation = useCallback(
        (cancellationReason: CancelOrderReason) => {
            setIsCancelModalOpened(false);
            if (isRefundable) {
                refundOrder({ token, orderId, cancellationReason, channelId });
            } else if (isCancellable) {
                cancelOrder({ token, orderId, cancellationReason, channelId });
            }
        },
        [cancelOrder, refundOrder, isRefundable, isCancellable, orderId, token, channelId],
    );

    if (orderIsLoading || transactionsIsLoading) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }
    if (!order) {
        return <Redirect to={Routes.NotFound} />;
    }

    return (
        <>
            <Container>
                <OrderDetailsHeader {...order} />
                <DetailContainer>
                    <MainDetailContainer>
                        <Card titleId="orderDetail.ticketInfo" isExpandable isInitialOpened>
                            <OrderDetailsTicket order={order} />
                        </Card>
                        {Boolean(order.comment) && (
                            <Card titleId="orderDetail.comment" isExpandable isInitialOpened>
                                <Text text={order.comment} />
                            </Card>
                        )}
                        {Boolean(order.comment) === false &&
                            typeof order.webComment === 'string' &&
                            order.webComment !== '' && (
                                <Card titleId="orderDetail.comment" isExpandable isInitialOpened>
                                    <Text text={order.webComment} />
                                </Card>
                            )}
                        {typeof order.userName === 'string' && (
                            <Card titleId="orderDetail.userName" isExpandable isInitialOpened>
                                <Text text={order.userName} />
                            </Card>
                        )}
                        {Boolean(order.taxes) && (
                            <Card titleId="orderDetail.taxInfo" isExpandable isInitialOpened>
                                <OrderDetailsTax taxes={order.taxes} />
                            </Card>
                        )}
                        {Boolean(order.payments) && (
                            <Card titleId="orderDetail.paymentInfo" isExpandable isInitialOpened>
                                <OrderDetailsPayment payments={order.payments} />
                            </Card>
                        )}
                    </MainDetailContainer>
                    <SideDetailContainer>
                        {order.customer && (
                            <Card titleId="orderDetail.customerInfo" isExpandable isInitialOpened>
                                <OrderDetailsCustomer
                                    firstName={order.customer.firstName}
                                    lastName={order.customer.lastName}
                                    email={order.customer.email}
                                    phone={order.customer.phone}
                                    badgeNumber={order.customer.badgeNumber}
                                    group={order.customer.student?.class}
                                    section={order.customer.student?.section}
                                    subSection={order.customer.student?.subSection}
                                    pricingRuleCode={order.customer.student?.pricingRuleCode}
                                    balanceInformation={order.customer.balanceInformation}
                                />
                            </Card>
                        )}
                        {order.deliveryAddress && (
                            <Card titleId="orderDetail.deliveryInfo" isExpandable isInitialOpened>
                                <OrderDetailsDelivery {...order.deliveryAddress} />
                            </Card>
                        )}
                        <Card titleId="orderDetail.posInfo" isExpandable isInitialOpened>
                            <OrderDetailsPos
                                seller={order.seller}
                                deviceName={order.deviceName}
                                deviceId={order.deviceId}
                                restaurant={order.restaurant.name}
                            />
                        </Card>
                        {transactions && transactions.length > 0 && (
                            <Card titleId="orderDetail.transactionInfo" isExpandable isInitialOpened>
                                <OrderDetailsTransaction transactions={transactions.map((t) => t.id)} />
                            </Card>
                        )}
                        {order.photos?.length ? (
                            <Card titleId="orderDetail.orderPhotos" isExpandable isInitialOpened>
                                <OrderDetailsPhotos photos={order.photos} />
                            </Card>
                        ) : null}
                    </SideDetailContainer>
                </DetailContainer>
            </Container>
            {(isCancellable || isRefundable) && (
                <ActionBar
                    information={intl.formatMessage({ id: 'orderDetail.action.text' })}
                    error={stringifyError(orderError) || stringifyError(refundError) || stringifyError(cancelError)}
                >
                    <ActionBarButton>
                        {isRefundable && (
                            <SvyButton isLoading={isRefunding} onClick={toggleCancelModal}>
                                <FormattedMessage id="orderDetail.action.refund" />
                            </SvyButton>
                        )}
                        {isCancellable && (
                            <SvyButton isLoading={isCancelling} onClick={toggleCancelModal}>
                                <FormattedMessage
                                    id={order?.isRefundable ? 'orderDetail.action.refund' : 'orderDetail.action.cancel'}
                                />
                            </SvyButton>
                        )}
                    </ActionBarButton>
                </ActionBar>
            )}
            {isCancelModalOpened && (
                <OrderDetailsCancelConfirmation onHide={toggleCancelModal} onValidate={handleCancelConfirmation} />
            )}
        </>
    );
};

export default OrderDetails;
