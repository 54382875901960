import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

import { Section } from '@/components/atoms/Section';
import { getUserBrandId, getUserToken } from '@/redux/user';
import { useSendBillingRequestToEliorMutation } from '@/services/innovorder/autoBilling/autoBilling.endpoints';
import { Button } from '@/components/atoms/Button';
import { FormattedMessage } from 'react-intl';
import Wizard from '../../../../components/atoms/Wizard/Wizard';
import Calendar from '../../../../components/atoms/Calendar';
import RawModal from '../../../../components/atoms/RawModal';
import AutoBillingRequestPreview from './AutoBillingRequestPreview';
import Loader from '../../../../components/atoms/Loader';
import { Container, Message } from './AutoBillingRequest.style';
import AutoBillingRequestFilters from './AutoBillingRequestFilters';
import { ALL_DROP_DOWN_VALUES } from './AutoBillingRequestFilters/utils';
import AutoBillingRequestHistory from './AutoBillingRequestHistory';

export type RequestFilters = {
    entities: string[];
    restaurants: string[];
    pricingRules: string[];
    accountPaymentTypes: string[];
};

type RequestPeriod = {
    from: Date | null;
    to: Date | null;
};

type AutoBillingRequestData = {
    period: RequestPeriod;
    filters: RequestFilters;
};

const AutoBillingRequest: React.FunctionComponent<React.PropsWithChildren> = () => {
    const [forceRefreshCounter, setForceRefreshCounter] = useState(0);

    const [sendBillingRequestToElior, { isSuccess, isLoading }] = useSendBillingRequestToEliorMutation();
    const [showOptionsChooserWizard, setShowOptionsChooserWizard] = useState(false);
    const [autoBillingRequestData, setAutoBillingRequestData] = useState<AutoBillingRequestData>({
        period: {
            from: null,
            to: null,
        },
        filters: {
            entities: [],
            restaurants: [],
            pricingRules: [],
            accountPaymentTypes: [],
        },
    });
    const token = useSelector(getUserToken);
    const theme = useTheme();
    const brandId = useSelector(getUserBrandId);

    const handleDatesChange = useCallback((dates: { startDate: Date | null; endDate: Date | null }) => {
        if (dates.startDate !== null && dates.endDate !== null) {
            setAutoBillingRequestData((currentState) => ({
                ...currentState,
                period: {
                    from: dates.startDate,
                    to: dates.endDate,
                },
            }));
        }
    }, []);

    const handleFilterChange = useCallback((filters: RequestFilters) => {
        setAutoBillingRequestData((currentState) => ({
            ...currentState,
            filters,
        }));
    }, []);

    const handleValidate = () => {
        if (!isLoading) {
            setForceRefreshCounter((currentState) => currentState + 1);
            setShowOptionsChooserWizard(false);
        }
    };

    const postBillingToElior = (): boolean => {
        sendBillingRequestToElior({
            periodFrom: dayjs(autoBillingRequestData.period.from).format('YYYY-MM-DD') as string,
            periodTo: dayjs(autoBillingRequestData.period.to).format('YYYY-MM-DD') as string,
            brandId: brandId as number,
            filters: {
                entities: autoBillingRequestData.filters.entities.filter((entity) => entity !== ALL_DROP_DOWN_VALUES),
                restaurants: autoBillingRequestData.filters.restaurants.filter(
                    (restaurant) => restaurant !== ALL_DROP_DOWN_VALUES,
                ),
                accountPaymentTypes: autoBillingRequestData.filters.accountPaymentTypes.filter(
                    (paymentType) => paymentType !== ALL_DROP_DOWN_VALUES,
                ),
                pricingRules: autoBillingRequestData.filters.pricingRules.filter(
                    (rule) => rule !== ALL_DROP_DOWN_VALUES,
                ),
            },
            token,
        });
        setAutoBillingRequestData({
            period: {
                from: null,
                to: null,
            },
            filters: {
                entities: [],
                restaurants: [],
                pricingRules: [],
                accountPaymentTypes: [],
            },
        });
        return true;
    };

    const handleCancel = () => setShowOptionsChooserWizard(false);

    const lastStep = {
        title: (): string => {
            if (!isLoading && isSuccess) {
                return 'auto.billing.request.popup.success.title';
            }
            if (!isLoading && !isSuccess) {
                return 'auto.billing.request.popup.fail.title';
            }
            return 'auto.billing.request.popup.pending.title';
        },

        content: function LastStep() {
            if (!isLoading && isSuccess) {
                return <Message text="auto.billing.request.popup.success.text" />;
            }
            if (!isLoading && !isSuccess) {
                return <Message text="auto.billing.request.popup.fail.text" />;
            }
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Loader color={theme.color.primary} size={40} />
                </div>
            );
        },
    };

    const [canNextPreview, setCanNextPreview] = useState<boolean>(false);
    const [canPreviousPreview, setCanPreviousPreview] = useState<boolean>(false);

    const handleFinishPreviewLoading = useCallback((canNext: boolean, canPrevious: boolean): void => {
        setCanNextPreview(canNext);
        setCanPreviousPreview(canPrevious);
    }, []);

    // eslint-disable-next-line no-empty-function
    const emptyFn = () => {};

    return (
        <Container>
            <Section
                title="auto.billing.request.card.title"
                subtitle="auto.billing.request.card.subtitle"
                actions={
                    <Button buttonType="primary" onClick={() => setShowOptionsChooserWizard(true)}>
                        <FormattedMessage id="auto.billing.request.card.action" />
                    </Button>
                }
            >
                <RawModal isOpen={showOptionsChooserWizard} onHide={emptyFn}>
                    <Wizard
                        onValidate={handleValidate}
                        canValidate={!isLoading && isSuccess}
                        validateLabel="auto.billing.request.wizard.validate"
                        steps={[
                            {
                                title: 'auto.billing.request.wizard.title',
                                onCancel: handleCancel,
                                onNext: () =>
                                    autoBillingRequestData.period.from !== null &&
                                    autoBillingRequestData.period.to !== null,
                                component: (
                                    <Calendar
                                        startDate={
                                            autoBillingRequestData.period.from ?? dayjs().startOf('month').toDate()
                                        }
                                        endDate={autoBillingRequestData.period.to}
                                        open={true}
                                        onChange={handleDatesChange}
                                        monthShown={2}
                                        inline={true}
                                        selectsRange={true}
                                        disabled={false}
                                    />
                                ),
                            },
                            {
                                title: 'auto.billing.request.wizard.title',
                                onCancel: handleCancel,
                                component: (
                                    <AutoBillingRequestFilters
                                        onFilterChange={handleFilterChange}
                                        filters={autoBillingRequestData.filters}
                                    />
                                ),
                                onNext: () =>
                                    !Object.values(autoBillingRequestData.filters).find((filter) => !filter.length),
                            },
                            {
                                title: 'auto.billing.request.wizard.title',
                                onCancel: handleCancel,
                                showPrevious: canPreviousPreview,
                                component: (
                                    <AutoBillingRequestPreview
                                        updateLoadingState={handleFinishPreviewLoading}
                                        periodFrom={autoBillingRequestData.period.from as Date}
                                        periodTo={autoBillingRequestData.period.to as Date}
                                        filters={autoBillingRequestData.filters}
                                    />
                                ),
                                onNext: () => canNextPreview,
                                showNext: canNextPreview,
                            },
                            {
                                title: 'auto.billing.request.popup.confirm.title',
                                onCancel: handleCancel,
                                component: <Message text="auto.billing.request.popup.confirm.text" />,
                                onNext: postBillingToElior,
                                nextLabel: 'button.validate',
                            },
                            {
                                showPrevious: false,
                                title: lastStep.title(),
                                component: lastStep.content(),
                            },
                        ]}
                    />
                </RawModal>
                <AutoBillingRequestHistory forceRefreshCounter={forceRefreshCounter} />
            </Section>
        </Container>
    );
};

export default AutoBillingRequest;
