import React, { ReactElement, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { computeText } from '@/locales/utils';
import { SvyButton } from '@/components/savory/SvyButton';
import { SvyStepper } from '@/components/savory/SvyStepper';
import { Title } from '../../Title';
import { Container, Content, ErrorMessage, Footer, FooterLeft, FooterRight, Header } from './WizardStep.style';

type Step = {
    label: string;
    active: boolean;
    completed: boolean;
};

export type WizardStepProps = {
    title: string;
    nextLabel: string;
    cancelLabel: string;
    previousLabel: string;
    validateLabel: string;
    isLastStep: boolean;
    onNext?: () => void;
    onCancel?: () => void;
    onValidate: () => void;
    component: HTMLElement | ReactElement;
    onPrevious?: () => void;
    showNext?: boolean;
    showPrevious?: boolean;
    showValidate?: boolean;
    canValidate?: boolean;
    canGoBack?: boolean;
    showHeader?: boolean;
    showStepper?: boolean;
    stepperSteps?: Step[];
    errorMessage?: string;
    isLoading?: boolean;
    isNextDisabled?: boolean;
    isForm?: boolean;
    currentStep?: number;
};

const WizardStep: React.FunctionComponent<React.PropsWithChildren<WizardStepProps>> = ({
    title,
    nextLabel,
    cancelLabel,
    previousLabel,
    validateLabel,
    isLastStep,
    onNext,
    onCancel,
    component,
    onPrevious,
    onValidate,
    showNext = !isLastStep,
    showValidate = isLastStep,
    showPrevious = true,
    canValidate = true,
    canGoBack = true,
    showHeader = true,
    showStepper = false,
    stepperSteps,
    errorMessage,
    isLoading = false,
    isNextDisabled = false,
    currentStep,
}) => {
    const intl = useIntl();
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [currentStep]);

    return (
        <Container>
            {showHeader && (
                <Header>
                    <Title text={title} />
                </Header>
            )}
            {showStepper && stepperSteps && <SvyStepper steps={stepperSteps} />}
            <Content ref={contentRef}>{component as ReactElement}</Content>
            {errorMessage && <ErrorMessage text={errorMessage} />}
            <Footer>
                <FooterLeft>
                    {canGoBack && showPrevious && onPrevious && (
                        <SvyButton
                            disabled={isLoading}
                            isLoading={isLoading}
                            type="button"
                            variant="outline"
                            onClick={onPrevious}
                        >
                            {computeText(intl, previousLabel)}
                        </SvyButton>
                    )}
                </FooterLeft>
                <FooterRight>
                    {onCancel && (
                        <SvyButton
                            disabled={isLoading}
                            isLoading={isLoading}
                            onClick={onCancel}
                            variant="outline"
                            type="button"
                        >
                            {computeText(intl, cancelLabel)}
                        </SvyButton>
                    )}
                    {showNext && (
                        <SvyButton
                            disabled={isLoading || isNextDisabled}
                            isLoading={isLoading}
                            variant="default"
                            onClick={onNext}
                            type="button"
                        >
                            {computeText(intl, nextLabel)}
                        </SvyButton>
                    )}
                    {showValidate && (
                        <SvyButton
                            disabled={!canValidate || isLoading}
                            isLoading={isLoading}
                            variant="default"
                            type="button"
                            onClick={onValidate}
                        >
                            {computeText(intl, validateLabel)}
                        </SvyButton>
                    )}
                </FooterRight>
            </Footer>
        </Container>
    );
};

export default WizardStep;
