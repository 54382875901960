import * as React from 'react';
import { PromptModals } from '../prompt';

export const AsyncActionsProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
    return (
        <>
            {children} <PromptModals />
        </>
    );
};
