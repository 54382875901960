import React from 'react';
import { FormattedMessage } from 'react-intl';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { Button } from '@/components/atoms/Button';
import { routes } from '@/core/router/routes';
import { Container, ActionsContainer } from './Groups.Style';
import { canGroupBeDeleted, formatGroupSectionKey } from './utils';
import DeleteGroupContainer from './DeleteGroupContainer/DeleteGroupContainer';
import { UploadModal } from './UploadModal/UploadModal';
import { GuestGroupsByDepthKey, useGroupMassImport, useGroupsViewModel } from './Group.viewmodel';

import { GroupingSection } from './GroupingSection';

export const Groups: React.FunctionComponent<React.PropsWithChildren> = () => {
    const {
        brandId,
        groupIdToDelete,
        isLoading,
        guestGroups,
        groupsTableRowsByDepth,
        isLoadingDelete,
        isLoadingCount,
        relatedGuestsData,
        groupsTableColumnsByDepth,
        handleCloseModal,
        handleDeleteGroup,
        handleAddGroup,
    } = useGroupsViewModel();
    const { isOpen, handleHide, handleUnHide, handleDownload } = useGroupMassImport({ guestGroups });

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <UploadModal isOpen={isOpen} onHide={handleHide} brandId={brandId} />
            <Container>
                <ActionsContainer>
                    <Button buttonType="primary" onClick={handleUnHide}>
                        <FormattedMessage id="pricingRule.action.import" />
                    </Button>
                    <Button buttonType="primary" onClick={handleDownload}>
                        <FormattedMessage id="pricingRule.action.export" />
                    </Button>
                </ActionsContainer>
                {Object.keys(groupsTableRowsByDepth).map((key) => (
                    <GroupingSection
                        key={key}
                        titleId={`${formatGroupSectionKey(key)}.tableCard.title`}
                        subtitleId={`${formatGroupSectionKey(key)}.tableCard.subtitle`}
                        actionButtonId={`${formatGroupSectionKey(key)}.tableCard.action`}
                        onActionClick={handleAddGroup}
                        tableProps={{
                            rows: groupsTableRowsByDepth[key as GuestGroupsByDepthKey],
                            columns: groupsTableColumnsByDepth[key as GuestGroupsByDepthKey],
                            isLoading,
                            emptyMessageId: 'group.emptyMessage',
                        }}
                    />
                ))}
                <DeleteGroupContainer
                    canBeDeleted={canGroupBeDeleted(relatedGuestsData)}
                    isOpen={groupIdToDelete !== null}
                    isDeleteGroupLoading={isLoadingDelete}
                    onHide={handleCloseModal}
                    onSubmit={handleDeleteGroup}
                    isGuestsCountLoading={isLoadingCount}
                />
            </Container>
        </RouterNavigationTabs>
    );
};
