import { Badge } from '@/services/innovorder/customer/customer.types';
import { theme } from '@/core/theme';
import { computeText } from '@/locales/utils';
import { Column } from 'react-table';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { IntlShape } from 'react-intl';
import { ButtonProps } from '@/components/atoms/Button';

export const getStatusValue = (intl: IntlShape, isBlocked: boolean) => ({
    text: isBlocked ? 'customer.student.badge.table.status.blocked' : 'customer.student.badge.table.status.active',
    backgroundColor: isBlocked ? theme.color.io.opacity20.red200 : theme.color.io.opacity20.green200,
    textColor: isBlocked ? theme.color.red300 : theme.color.io.green900,
});

export const getColumns = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'customer.student.badge.table.default'),
        accessor: 'isDefault',
        disableSortBy: true,
        width: '30px',
    },
    {
        Header: computeText(intl, 'customer.student.badge.table.number'),
        accessor: 'badgeNumber',
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'customer.student.badge.table.type'),
        accessor: 'badgeType',
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'customer.student.badge.table.status'),
        accessor: 'isBlocked',
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'customer.student.badge.table.action'),
        accessor: 'actions',
        disableSortBy: true,
    },
];

export const getRows = (
    entities: Badge[],
    intl: IntlShape,
    onDelete?: (badge: Badge) => void,
    onBlock?: (badge: Badge) => void,
    onEdit?: (badge: Badge) => void,
): TableRow[] =>
    entities.map((badge) => {
        const actionsList: ButtonProps[] = [];
        if (onEdit)
            actionsList.push({
                children: computeText(intl, 'button.edit'),
                buttonType: 'secondary',
                onClick: () => onEdit(badge),
            });
        if (onBlock)
            actionsList.push({
                children: computeText(intl, badge.isBlocked ? 'button.unblock' : 'button.block'),
                buttonType: 'secondary',
                onClick: () => onBlock(badge),
            });
        if (onDelete)
            actionsList.push({
                children: computeText(intl, 'button.delete'),
                buttonType: 'red',
                onClick: () => onDelete(badge),
            });

        return {
            isDefault: {
                type: 'radio',
                value: { label: '', value: badge.isDefault, checked: badge.isDefault },
            },
            badgeNumber: {
                type: 'string',
                value: {
                    text: badge.value,
                },
            },
            badgeType: {
                type: 'string',
                value: {
                    text: badge.type,
                },
            },
            isBlocked: {
                type: 'badge',
                value: getStatusValue(intl, badge.isBlocked),
            },
            actions: {
                type: 'actionList',
                value: actionsList,
            },
        };
    });
