import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import { ConsumptionMode, GetConsumptionModePayload } from './consumptionMode.type';

const consumptionModeApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getConsumptionModes: build.query<ConsumptionMode[], ApiRequest<GetConsumptionModePayload>>({
            query: ({ channelId, restaurantId, token }) => ({
                url: `/consumption_modes?channel_id=${channelId}&restaurant_id=${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<ConsumptionMode[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetConsumptionModesQuery } = consumptionModeApi;
