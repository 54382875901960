import styled from '@emotion/styled';

export const Container = styled.div`
    border-radius: ${({ theme }) => theme.radius.main};
    border: 1px solid ${({ theme }) => theme.color.grey300};
    background-color: ${({ theme }): string => theme.color.white100};
    padding: ${({ theme }) => theme.spacing[2]};
`;

export const CrossSellingGrid = styled.div`
    display: grid;
    grid-template-columns: 20px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: ${({ theme }) => theme.spacing[2]};
    padding: ${({ theme }) => theme.spacing[2]};
    border-radius: ${({ theme }) => theme.radius.main};
    align-items: center;
    &[data-is-dragged='true'] {
        background: ${({ theme }) => theme.color.white100};
    }
`;

export const CrossSellingInputHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const CrossSellingInputDroppable = styled.div`
    border: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: ${({ theme }) => theme.radius.main};
    margin: ${({ theme }) => theme.spacing[2]} 0;
    padding: ${({ theme }) => theme.spacing[2]};
    &[data-is-dragging-over='true'] {
        background: ${({ theme }) => theme.color.grey100};
    }
    &[data-hidden='true'] {
        opacity: 0;
    }
`;

export const CrossSellingInputDraggable = styled.div`
    min-height: 30px;
`;

export const CrossSellingTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;
