import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';
import { Title } from '@/components/atoms/Title';
import { Input } from '@/components/atoms/Form/Input';

export const ModalConfigurationContainer = styled.div`
    height: 500px;
    width: 800px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    display: grid;
    margin: ${({ theme }) => theme.savory.spacing[3]};
`;

export const ModalInputFullContainer = styled.div`
    grid-column: 1/3;
`;

export const ModalInputTitle = styled(Text)`
    color: ${({ theme }) => theme.color.neutral900};
    display: flex;
`;

export const ModalInputOptionalTitle = styled(Text)`
    float: left;
    margin-right: 4px;
`;

export const ModalTitleContainer = styled.div`
    margin-bottom: 8px;
`;

export const ConfigureBillingStepTitle = styled(Title)`
    ${({ theme }) => theme.savory.typography.Title5};
`;

export const BillingConfigurationInput = styled(Input)`
    border-radius: 8px;
`;

export const OptionalText = styled(Text)`
    color: ${({ theme }) => theme.color.neutral500};
`;
