import React, { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';

import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { DropDown } from '@/components/atoms/DropDown';
import type { DropDownItem } from '@/components/atoms/DropDown/DropDown.type';
import { AutoBillingInvoice } from '@/services/innovorder/autoBilling/autoBilling.type';
import { DropDownButton, DropDownButtonContainer } from './InvoiceSelector.style';
import { computeText } from '@/locales/utils';

type InvoiceSelectorProps = {
    invoices: AutoBillingInvoice[];
    onSelected: (invoiceUuid: string) => void;
};

const InvoiceSelector: React.FunctionComponent<React.PropsWithChildren<InvoiceSelectorProps>> = ({
    invoices,
    onSelected,
}) => {
    const theme = useTheme();
    const intl = useIntl();

    const formatDropdownLabel = useCallback(
        (invoice: AutoBillingInvoice) => {
            if (invoice.client.groupingBy === 'BILLING_ENTITY') {
                return invoice.client.name;
            }
            const groupingBy = computeText(
                intl,
                `auto.billing.request.preview.invoiceSelector.${invoice.groupingInfo.groupingBy}`,
            );
            let { groupByValue } = invoice.groupingInfo;
            if (invoice.groupingInfo.groupingBy === 'ACCOUNT_PAYMENT_TYPE') {
                groupByValue = computeText(
                    intl,
                    `auto.billing.request.accountPaymentTypes.${invoice.groupingInfo.groupByValue}`,
                );
            }
            return `${invoice.client.name} - ${groupingBy} : ${groupByValue}`;
        },
        [intl],
    );

    const dropDownInvoices: DropDownItem<string>[] = useMemo(
        () =>
            invoices.map((invoice) => ({
                label: formatDropdownLabel(invoice),
                value: invoice.uuid,
            })),
        [formatDropdownLabel, invoices],
    );
    const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>(dropDownInvoices[0]?.value);

    const handleInvoiceSelectionChange = useCallback(
        (invoiceUuid: string): void => {
            setSelectedInvoice(invoiceUuid);
            onSelected(invoiceUuid);
        },
        [onSelected],
    );

    return (
        <DropDown
            align={'end'}
            type="radio"
            selected={selectedInvoice}
            items={dropDownInvoices}
            onChange={handleInvoiceSelectionChange}
            filterPlaceholder="auto.billing.filters.entity"
            button={
                <DropDownButton>
                    <DropDownButtonContainer>
                        <div>{dropDownInvoices.find((item) => item.value === selectedInvoice)?.label}</div>
                        <div>
                            <SVGIcon icon={SVGIcons.CHEVRON} color={theme.color.white100} size={20} />
                        </div>
                    </DropDownButtonContainer>
                </DropDownButton>
            }
        />
    );
};

export default InvoiceSelector;
