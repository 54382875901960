import React from 'react';
import { injectDataInUri } from '@/utils/routes';
import { ENV } from '@/utils/env';
import { Routes } from '@/core/router/routes.types';
import { Container, FieldContainer, StyledLabel, StyledLinkValue, StyledValue } from '../TransactionDetail.style';

type TransactionDetailCustomerDataProps = {
    name: string;
    email: string;
    customerId: number;
    badgeNumber?: string;
};

const CustomerData: React.FunctionComponent<React.PropsWithChildren<TransactionDetailCustomerDataProps>> = ({
    name,
    email,
    customerId,
    badgeNumber,
}) => {
    return (
        <Container>
            <FieldContainer>
                <a
                    href={`${injectDataInUri({
                        uri: Routes.CustomerInfo,
                        backofficeUrl: ENV.BACKOFFICE_URL,
                        customerId: customerId.toString(),
                    })}`}
                >
                    <StyledLabel text="transaction.field.customerCompleteName" />
                    <StyledLinkValue>{name}</StyledLinkValue>
                </a>
            </FieldContainer>
            <FieldContainer>
                <StyledLabel text="transaction.field.email" />
                <StyledValue>{email}</StyledValue>
            </FieldContainer>
            <FieldContainer>
                <StyledLabel text="transaction.field.customerId" />
                <StyledValue>{customerId.toString()}</StyledValue>
            </FieldContainer>
            {badgeNumber && (
                <FieldContainer>
                    <StyledLabel text="transaction.field.badgeNumber" />
                    <StyledValue>{badgeNumber.toString()}</StyledValue>
                </FieldContainer>
            )}
        </Container>
    );
};

export default CustomerData;
