import styled from '@emotion/styled';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content min-content min-content;
    ${({ theme }) => theme.breakpoints.tabletLandscape} {
        gap: ${({ theme }) => theme.spacing[2]};
    }
    gap: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[4]};
    ${({ theme }) => theme.breakpoints.desktop} {
        gap: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[8]};
    }
    font-family: ${({ theme }) => theme.font.family};
    color: ${({ theme }) => theme.color.black200};
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.medium};
`;
