import React from 'react';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { Select } from '@/components/form/Select';
import { Container, Row } from './DriverConfigStep.style';
import {
    DriverConfigBaudRate,
    DriverConfigDataBits,
    DriverConfigParity,
    DriverConfigStopBits,
} from '@/services/innovorder/badgeReader/badgeReader.type';
import { enumValues } from '@/utils/typescript';

type DriverConfigStepProps = {};

const baudRateOptions = () =>
    enumValues(DriverConfigBaudRate).map(
        (label) => ({ label, value: label } as { label: string; value: DriverConfigBaudRate }),
    );
const dataBitsOptions = () =>
    enumValues(DriverConfigDataBits).map(
        (label) => ({ label, value: label } as { label: string; value: DriverConfigDataBits }),
    );
const parityOptions = () =>
    enumValues(DriverConfigParity).map(
        (label) => ({ label, value: label } as { label: string; value: DriverConfigParity }),
    );
const stopBitsOptions = () =>
    enumValues(DriverConfigStopBits).map(
        (label) => ({ label, value: label } as { label: string; value: DriverConfigStopBits }),
    );

export const DriverConfigStep: React.FunctionComponent<React.PropsWithChildren<DriverConfigStepProps>> = () => {
    const intl = useIntl();

    return (
        <Container>
            <Row>
                <Select
                    name="baudRate"
                    rules={{ required: computeText(intl, 'field.required') }}
                    options={baudRateOptions()}
                    placeholder="badgeReader.form.driverConfigBaudRate"
                    labelId="badgeReader.form.driverConfigBaudRate"
                />
            </Row>
            <Row>
                <Select
                    name="dataBits"
                    rules={{ required: computeText(intl, 'field.required') }}
                    options={dataBitsOptions()}
                    placeholder="badgeReader.form.driverConfigDataBits"
                    labelId="badgeReader.form.driverConfigDataBits"
                />
            </Row>
            <Row>
                <Select
                    name="parity"
                    rules={{ required: computeText(intl, 'field.required') }}
                    options={parityOptions()}
                    placeholder="badgeReader.form.driverConfigParity"
                    labelId="badgeReader.form.driverConfigParity"
                />
            </Row>
            <Row>
                <Select
                    name="stopBits"
                    rules={{ required: computeText(intl, 'field.required') }}
                    options={stopBitsOptions()}
                    placeholder="badgeReader.form.driverConfigStopBits"
                    labelId="badgeReader.form.driverConfigStopBits"
                />
            </Row>
        </Container>
    );
};
