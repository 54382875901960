import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const StyledText = styled(Text)<{ state: 'SUCCESS' | 'WARNING' | 'ERROR' }>`
    min-width: 80px;
    text-align: center;
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
    border-radius: 4px;
    color: ${({ theme }) => theme.color.white100};
    background: ${({ state, theme }) => {
        if (state === 'SUCCESS') {
            return theme.color.green200;
        }

        if (state === 'ERROR') {
            return theme.color.red300;
        }

        return theme.color.yellow200;
    }};
`;
