import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import { Menu } from '@/services/innovorder/menu/menu.type';

export const getMenusPricingRevaluationTableRowsVM = (
    menus: Menu[] | undefined,
    intl: IntlShape,
    handleOnChange: any,
    selectedMenuIds: number[],
): TableRow[] =>
    !menus
        ? []
        : menus.map((menu) => ({
              selected: {
                  type: 'switch',
                  value: { onChange: () => handleOnChange(menu.menuId), value: selectedMenuIds.includes(menu.menuId) },
              },
              name: {
                  type: 'string',
                  value: { text: menu.name },
              },
              isActive: {
                  type: 'string',
                  value: {
                      text: menu.isActive ? computeText(intl, 'status.active') : computeText(intl, 'status.inactive'),
                  },
              },
              channels: {
                  type: 'string',
                  value: {
                      text:
                          menu.channels.length !== 0
                              ? menu.channels
                                    .map(({ channelId }) => computeText(intl, `order.channel.${channelId}`))
                                    .join(', ')
                              : '',
                  },
              },
          }));

export const getMenusPricingRevaluationTableColumnsVM = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'pricingRevaluation.table.menu.selected'),
        accessor: 'selected',
        width: '1%',
    },
    {
        Header: computeText(intl, 'pricingRevaluation.table.menu.name'),
        accessor: 'name',
    },
    {
        Header: computeText(intl, 'pricingRevaluation.table.menu.isActive'),
        accessor: 'isActive',
        width: '10%',
    },
    {
        Header: computeText(intl, 'pricingRevaluation.table.menu.channels'),
        accessor: 'channels',
        width: '10%',
    },
];
