import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { hasLength, isEmail, isInteger } from '@/utils/form/validate';
import { CreateSelect } from '@/components/form/CreateSelect';
import { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { ConfigureBillingFormValues } from './ConfigureBillingStep.types';
import {
    ModalConfigurationContainer,
    ModalInputFullContainer,
    ModalInputTitle,
    ModalTitleContainer,
    ConfigureBillingStepTitle,
    BillingConfigurationInput,
    OptionalText,
    ModalInputOptionalTitle,
} from './ConfigureBillingStep.style';

type SelectEntityStepProps = {
    onNext?: () => void;
    isLoading?: boolean;
};

export const ConfigureBillingStep: React.FunctionComponent<React.PropsWithChildren<SelectEntityStepProps>> = ({
    isLoading,
}) => {
    const intl = useIntl();
    const { control } = useFormContext<ConfigureBillingFormValues>();

    const handleTransformInput = React.useCallback(
        (recipients: string[]) => {
            if (!recipients) return [];

            const newRecipient = recipients[recipients.length - 1];
            if (isEmail(intl)(newRecipient) !== undefined) recipients.pop();
            return recipients.map((recipient) => ({ label: recipient, value: recipient }));
        },
        [intl],
    );

    return (
        <ModalConfigurationContainer>
            <ConfigureBillingStepTitle text="billing.editConfiguration.configureBillingStep.title" />
            <Controller
                control={control}
                name="beneficiaryName"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value) => !!value,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle text="billing.editConfiguration.configureBillingStep.beneficiaryName" />
                        </ModalTitleContainer>

                        <BillingConfigurationInput
                            onChange={onChange}
                            value={value}
                            disabled={isLoading}
                            required={true}
                            error={error?.message}
                        />
                    </ModalInputFullContainer>
                )}
            />
            <Controller
                control={control}
                name="beneficiaryEmail"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => isEmail(intl)(value ?? ''),
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle text="billing.editConfiguration.configureBillingStep.beneficiaryEmail" />
                        </ModalTitleContainer>

                        <BillingConfigurationInput
                            onChange={onChange}
                            value={value}
                            disabled={isLoading}
                            required={true}
                            error={error?.message}
                        />
                    </ModalInputFullContainer>
                )}
            />
            <Controller
                control={control}
                name="beneficiaryAddress"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value) => !!value,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                        <ModalTitleContainer>
                            <ModalInputTitle text="billing.editConfiguration.configureBillingStep.beneficiaryAddress" />
                        </ModalTitleContainer>

                        <BillingConfigurationInput
                            onChange={onChange}
                            value={value}
                            disabled={isLoading}
                            required={true}
                            error={error?.message}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name="beneficiaryAddress2"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                        <ModalTitleContainer>
                            <ModalInputOptionalTitle text="billing.editConfiguration.configureBillingStep.beneficiaryAddress2" />
                            <OptionalText text="field.optional" />
                        </ModalTitleContainer>

                        <BillingConfigurationInput
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                            required={false}
                            error={error?.message}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name="beneficiaryZipCode"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => hasLength(5)(intl)(value ?? '') ?? isInteger(intl)(value ?? ''),
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                        <ModalTitleContainer>
                            <ModalInputTitle text="billing.editConfiguration.configureBillingStep.beneficiaryZipCode" />
                        </ModalTitleContainer>

                        <BillingConfigurationInput
                            onChange={onChange}
                            value={value}
                            disabled={isLoading}
                            required={true}
                            error={error?.message}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name="beneficiaryCity"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value) => !!value,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                        <ModalTitleContainer>
                            <ModalInputTitle text="billing.editConfiguration.configureBillingStep.beneficiaryCity" />
                        </ModalTitleContainer>

                        <BillingConfigurationInput
                            onChange={onChange}
                            value={value}
                            disabled={isLoading}
                            required={true}
                            error={error?.message}
                        />
                    </div>
                )}
            />

            <ConfigureBillingStepTitle text="billing.editConfiguration.configureBillingStep.recipients" />

            <ModalInputFullContainer>
                <CreateSelect
                    isMulti
                    formatCreateLabel={(email) =>
                        computeText(intl, 'billing.editConfiguration.configureBillingStep.recipients.createLabel', {
                            email,
                        })
                    }
                    name="recipientEmails"
                    placeholder={computeText(
                        intl,
                        'billing.editConfiguration.configureBillingStep.recipients.placeholder',
                    )}
                    transform={{
                        input: handleTransformInput,
                        output: (value) => (value as SelectOptionInterface<string>[]).map((o) => o.value),
                    }}
                    components={{ DropdownIndicator: undefined }}
                />
            </ModalInputFullContainer>
        </ModalConfigurationContainer>
    );
};
