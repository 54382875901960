import styled from '@emotion/styled';

export const Container = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.color.bloodyMary};
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: ${({ theme }) => theme.spacing[6]};
    padding-right: ${({ theme }) => theme.spacing[2]};

    p {
        color: ${({ theme }) => theme.color.white100};
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    height: 16px;

    & > * {
        margin-right: ${({ theme }) => theme.spacing[1]};
    }
`;
