import { Button } from '@/components/atoms/Button';
import styled from '@emotion/styled';

export const Container = styled.div<{ error?: string; isTouched?: boolean }>`
    background: ${({ theme }) => theme.color.white200};
    border: 1px solid
        ${({ error, isTouched, theme }) => {
            if (error) {
                return theme.color.bloodyMary;
            }
            if (isTouched) {
                return theme.color.emerald;
            }
            return theme.color.grey300;
        }};
    width: 100%;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.radius.main};
    padding: 10px 16px;
    font-size: ${({ theme }): string => theme.font.size.small};
    color: ${({ theme }) => theme.color.black200};
    display: flex;
    align-items: center;
    font-weight: 600;
    min-height: 40px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: $dark-black;
        -webkit-box-shadow: 0 0 0px 1000px $main-white inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    &:focus-within {
        border: 1px solid
            ${({ error, isTouched, theme }) => {
                if (error) {
                    return theme.color.bloodyMary;
                }
                if (isTouched) {
                    return theme.color.emerald;
                }
                return theme.color.black300;
            }};
    }
`;

export const IconContainer = styled.div`
    padding-left: 10px;
`;

export const UnstyledInput = styled.input`
    border: none;
    width: 100%;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    color: ${({ theme }) => theme.color.black200};
    height: 18px;
    line-height: 18px;
    ${({ theme }): string => theme.font.text.regular}
    font-size: ${({ theme }) => theme.font.size.normal};

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.color.black300};
        font-size: ${({ theme }) => theme.font.size.small};
    }
`;

export const StyledButton = styled(Button)`
    margin: -6px -12px -6px 5px;
    padding: 5px 16px;
    height: 30px;
`;

export const Unit = styled.p`
    font-size: 18px;
    line-height: 14px;
    margin-right: -4px;
    color: ${({ theme }) => theme.color.black200};
`;
