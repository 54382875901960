import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled(Text)<{
    backgroundColor: string;
    textColor?: string;
    width?: string;
    whiteSpace?: string;
    textAlign?: string;
    fontWeight?: string;
}>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[1]} ${({ theme }) => theme.spacing[2]};
    height: fit-content;
    width: ${({ width }) => (width ? width : 'fit-content')};
    white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : 'normal')};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'start')};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};

    color: ${({ textColor, theme }) => textColor || theme.color.white100};
    line-height: 20px;
`;
