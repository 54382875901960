import { useIntl } from 'react-intl';
import { XlsxFile } from '@/utils/xslx';
import { downloadFile } from '@/utils/file/utils';
import { getEntranceFeesHeader, getGrantsHeader, getPricingRulesHeader } from '../../utils';

export const useUploadStepVM = () => {
    const intl = useIntl();

    const sheetOptions = {
        entranceFee: {
            name: 'Admission',
            headers: getEntranceFeesHeader({ intl }),
        },
        grant: {
            name: 'Subvention',
            headers: getGrantsHeader({ intl }),
        },
        pricingRule: {
            name: 'Régime tarifaire',
            headers: getPricingRulesHeader({ intl }),
        },
    };

    const onDownload = async () => {
        const sheets = {
            [sheetOptions.entranceFee.name]: [sheetOptions.entranceFee.headers],
            [sheetOptions.grant.name]: [sheetOptions.grant.headers],
            [sheetOptions.pricingRule.name]: [sheetOptions.pricingRule.headers],
        };

        const xslx = new XlsxFile();
        Object.keys(sheets).forEach((key) => {
            xslx.addWorksheet(key, sheets[key]);
        });

        const data = xslx.toArrayBuffer();
        const filename = `import_rt_template.xlsx`;
        downloadFile(filename, data, 'text/csv');
    };

    return { onDownload };
};
