import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from '@/components/atoms/Table';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';

import { SvyButton } from '@/components/savory/SvyButton';
import { HeaderLeft, HeaderRight, Subtitle } from '@/components/atoms/Section/Section.style';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import { Container, Header, HeaderContainer } from './TableLayoutList.style';
import { useTableLayoutTableVM } from './TableLayoutList.viewmodel';
import { TableLayoutModal } from './TableLayoutModal/TableLayoutModal';
import { compareVersions } from 'compare-versions';
import { useGetPosDevicesQuery } from '@/services/innovorder/posDevice/posDevice.endpoints';

const SUPPORTED_POS_VERSION_FROM = '9.2.0';

const TableLayoutList: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { restaurantId, rows, headers, action, brandId, canEdit, clearAction, handleCreate, handleRowClick } =
        useTableLayoutTableVM();
    const { type, tableLayoutId, name, posDevices } = action;

    const { data: restaurantPosDevices = [], isLoading } = useGetPosDevicesQuery({
        brandId: +brandId,
        restaurantId: +restaurantId,
    });
    const hasPosDeviceSupported = React.useMemo(
        () =>
            restaurantPosDevices.some(
                (x) => compareVersions(x.lastKnownVersion || '', SUPPORTED_POS_VERSION_FROM) >= 0,
            ),
        [restaurantPosDevices],
    );
    const tabRoutes = React.useMemo(
        () => [
            ...routes
                .filter(({ tab: t }) => t === 'pos')
                .filter(({ name }) => name !== 'route.posAccess' || hasPosDeviceSupported),
        ],
        [hasPosDeviceSupported],
    );

    if (isLoading) {
        return null;
    }

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <TableLayoutModal
                action={type}
                brandId={brandId}
                restaurantId={restaurantId}
                tableLayoutId={tableLayoutId}
                tableLayoutName={name}
                posDevices={posDevices}
                onHide={clearAction}
            />
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="tableLayout.page.title" />
                            <Subtitle>
                                <Text text="tableLayout.page.description" />
                            </Subtitle>
                        </HeaderLeft>
                        {canEdit && (
                            <HeaderRight>
                                <SvyButton size="s" onClick={handleCreate}>
                                    <FormattedMessage id="tableLayout.action.newTableLayout" />
                                </SvyButton>
                            </HeaderRight>
                        )}
                    </Header>
                    <Table
                        isSearchable
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={headers}
                        hasPagination
                        hasUrlNavigation
                        isSortable
                        isLoading={false}
                        onRowClick={handleRowClick}
                    />
                </HeaderContainer>
            </Container>
        </RouterNavigationTabs>
    );
};

export default TableLayoutList;
