import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetPricingRuleQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { useGetEntranceFeeQuery } from '@/services/innovorder/entranceFee/entranceFee.endpoints';
import { useGetGrantQuery } from '@/services/innovorder/grant/grant.endpoints';
import { SpotlightContext } from '@/services/innovorder/spotlight/spotlight.type';
import { getUserToken } from '@/redux/user';

export const useSpotlightQueryHandler = (context: SpotlightContext | undefined): string | undefined => {
    const token = useSelector(getUserToken);
    const brandName = context?.brand;
    const restaurantName = context?.restaurant;
    const pricingRuleId = context?.pricingRule;
    const entranceFeeId = context?.entranceFee;
    const grantId = context?.grant;

    const { data: pricingRule } = useGetPricingRuleQuery(
        pricingRuleId
            ? {
                  pricingRuleId: parseInt(pricingRuleId, 10),
                  token,
              }
            : skipToken,
        { refetchOnMountOrArgChange: true },
    );

    const { data: entranceFee } = useGetEntranceFeeQuery(
        entranceFeeId
            ? {
                  entranceFeeId: parseInt(entranceFeeId, 10),
                  token,
              }
            : skipToken,
        { refetchOnMountOrArgChange: true },
    );

    const { data: grant } = useGetGrantQuery(
        grantId
            ? {
                  grantId: parseInt(grantId, 10),
                  token,
              }
            : skipToken,
        { refetchOnMountOrArgChange: true },
    );

    return pricingRule?.code || entranceFee?.code || grant?.code || restaurantName || brandName;
};
