import { ACCOUNT_PAYMENT_TYPE, ACCOUNT_TYPE, AccountType, FullAccountPaymentType } from './customer.types';

export const getAccountType = (accountPaymentType: string): FullAccountPaymentType => {
    if (accountPaymentType === ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_BANKDEBIT) {
        return {
            accountPaymentType: 'POSTPAYMENT',
            accountType: AccountType.BANKDEBIT,
        };
    }
    if (accountPaymentType === ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_GUEST) {
        return {
            accountPaymentType: 'POSTPAYMENT',
            accountType: AccountType.POSTPAYMENT,
        };
    }
    return {
        accountPaymentType,
        accountType: null,
    };
};
export const getAccountPaymentType = (groupAccountPaymentType: string, accountType: AccountType | null): string => {
    switch (accountType) {
        case ACCOUNT_TYPE.BANKDEBIT:
            return ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_BANKDEBIT;
        case ACCOUNT_TYPE.POSTPAYMENT:
            return ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_GUEST;
        default:
            return groupAccountPaymentType;
    }
};
