import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isRecordUnknown } from '@/utils/types';
import { ApiResponse, BasicError } from '@/services/innovorder/request.types';

export type QueryError = FetchBaseQueryError | SerializedError | undefined;

export const retreiveMessage = (unkownError: unknown): string => {
    const error = unkownError as BasicError & { error?: string; statusCode?: number };
    if (error === undefined || (!error.status && !error.statusCode)) {
        return '';
    }

    return error?.error ?? error.message;
};

export const stringifyError = (error: QueryError): string => {
    if (!error) {
        return '';
    }

    if ('status' in error) {
        return retreiveMessage(error.data ?? error);
    }

    return error?.message ?? '';
};

export const getBaseQueryErrorCode = (unkownError: unknown): string => {
    const error = unkownError as BasicError;
    if (error === undefined || !error.status) {
        return '';
    }

    return error.code;
};

export const getErrorCode = (error: QueryError): string => {
    if (!error) {
        return '';
    }

    if ('status' in error) {
        return getBaseQueryErrorCode(error.data);
    }

    return error.code ?? '';
};

export class MutationError extends Error {
    name = 'MutationError';
}

export const getErrorMessage = (error: unknown | undefined) =>
    isRecordUnknown(error) && 'data' in error && isRecordUnknown(error.data) ? String(error.data.message) : null;

export const safeMutate = async <D extends any>(mutation: Promise<D>) => {
    const response = (await mutation) as ApiResponse<D> | ApiResponse<D>[] | undefined;
    if (Array.isArray(response)) {
        await Promise.all(response.map((promise) => safeMutate(Promise.resolve(promise))));
    } else {
        if (response?.error) {
            throw new MutationError(response.error?.data?.message ?? response.error);
        }
        if (response?.status && response.status >= 400) {
            throw new MutationError(response?.message ?? 'error');
        }
    }

    return response;
};
