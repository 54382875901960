export type GetGrantPayload = {
    grantId: number;
};

export type GetGrantsPayload = {
    brandId: number;
};

export type DeleteGrantPayload = {
    grantId: number;
    brandId: number;
};

export type GrantType = 'RATE' | 'ABSOLUTE';

export enum GrantStrategy {
    MinimumAmountAndDailyReached = 1,
    MinimumAmountAndDailyReachedAndProduct = 2,
}

// ==== restaurants type ====

export type BaseRestaurantGrantData = {
    amount: number;
    dailyLimit: number;
    strategy: GrantStrategy;
    ceiling?: number | null;
    threshold?: number | null;
};

export type RestaurantGrantPayloadData = BaseRestaurantGrantData & {
    grantId: number;
    restaurantId: number;
    restaurantGrantId?: number;
    created_at?: string;
    deleted_at?: string | null;
    updated_at?: string | null;
    version?: string;
    position?: number;
};

export type RestaurantGrant<T> = {
    name: string;
    restaurantId: number;
    restaurant_grant: T;
    shiftGrants?: ShiftGrantPayloadData[];
};

export type RestaurantGrantPayload = RestaurantGrant<RestaurantGrantPayloadData>;

export type RestaurantGrantCreateAttribute = RestaurantGrant<BaseRestaurantGrantData>;

// ==== Grant type ====

export type BaseGrantCreateAttributes = {
    amount: number;
    code: string;
    dailyLimit: number;
    labelTicket: string;
    strategy: GrantStrategy;
    tag: string | null;
    type: GrantType;
    ceiling?: number | null;
    threshold?: number | null;
};

export type GrantCreateAttributes = BaseGrantCreateAttributes & {
    restaurants: RestaurantGrantCreateAttribute[];
    brandId: number;
};

export type GrantPayload = BaseGrantCreateAttributes & {
    grantId: number;
    restaurants: RestaurantGrantPayload[];
    brandId: number;
};

export type PutGrantPayload = GrantCreateAttributes & {
    grantId: number;
};

export type Grant = GrantPayload & {
    grantId: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
};

export type ShiftGrantPayloadData = {
    shiftId: string;
    shift: {
        name: string;
        isDefault: boolean;
    };
    amount: number;
    ceiling: number | null;
    threshold: number | null;
};

export type Grants = {
    id: number;
    code: string;
}[];
