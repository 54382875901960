import React, { useMemo } from 'react';
import Modal from '@/components/atoms/Modal';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/atoms/Button';
import { useGetRedirectToGenerationsHistoryPageHandler } from '@/utils/innovorder/reportGenerationHistory';

interface ExportResultModalProps {
    title: string;
    isOpen: boolean;
    close: () => void;
    isSuccess: boolean;
}

const ExportResultModal: React.FunctionComponent<ExportResultModalProps> = ({ title, isOpen, close, isSuccess }) => {
    const { redirectToGenerationsHistoryPage } = useGetRedirectToGenerationsHistoryPageHandler();

    const redirectButton = useMemo(
        () => (
            <Button onClick={redirectToGenerationsHistoryPage}>
                <FormattedMessage id="report.exportResultModal.redirectToGenerationHistory" />
            </Button>
        ),
        [redirectToGenerationsHistoryPage],
    );

    const buttons = useMemo(() => (isSuccess ? [redirectButton] : undefined), [isSuccess, redirectButton]);

    return (
        <Modal
            isOpen={isOpen}
            width={500}
            onHide={close}
            title={title}
            buttons={buttons}
            closeMessage="report.exportResultModal.stayInPage"
        >
            <FormattedMessage
                id={isSuccess ? 'report.exportResultModal.resultSuccess' : 'report.exportResultModal.resultFail'}
            />
        </Modal>
    );
};

export default ExportResultModal;
