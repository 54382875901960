import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { Routes } from '@/core/router/routes.types';
import { Section } from '@/components/atoms/Section';
import { Table } from '@/components/atoms/Table';
import Loader from '@/components/atoms/Loader';
import DeleteModal from '@/components/atoms/DeleteModal';
import { useGetBrandGrantsQuery } from '@/services/innovorder/brand/brand.endpoints';
import { useDeleteGrantMutation } from '@/services/innovorder/grant/grant.endpoints';
import { getUserToken } from '@/redux/user';
import { Button } from '@/components/atoms/Button';
import { getGrantsTableRowsVM, getGrantsTableColumnsVM } from './utils';

interface GrantsProps {
    brandId: string;
}

export const Grants: React.FunctionComponent<React.PropsWithChildren<GrantsProps>> = ({ brandId }) => {
    const intl = useIntl();
    const history = useHistory();
    const token = useSelector(getUserToken);
    const { data, isLoading } = useGetBrandGrantsQuery(
        {
            brandId: parseInt(brandId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const [deleteGrant, { isSuccess, isLoading: isLoadingDelete }] = useDeleteGrantMutation();

    const [idToDelete, setIdToDelete] = React.useState<number | null>(null);

    const handleOpenModal = (id: number): void => {
        setIdToDelete(id);
    };

    const handleCloseModal = () => {
        setIdToDelete(null);
    };

    const handleDeleteGrant = (): void => {
        idToDelete &&
            deleteGrant({
                grantId: idToDelete,
                brandId: parseInt(brandId, 10),
                token,
            });
    };

    useEffect(() => {
        if (isSuccess) {
            setIdToDelete(null);
        }
    }, [isSuccess]);

    const handleAddGrant = (): void => {
        history.push(generatePath(Routes.CreateGrant, { brandId }));
    };

    const grantsTableRows = React.useMemo(() => getGrantsTableRowsVM(data, handleOpenModal, intl), [data, intl]);
    const grantsTableColumns = React.useMemo(() => getGrantsTableColumnsVM(intl), [intl]);

    return (
        <>
            <Section
                title="grant.tableCard.title"
                subtitle="grant.tableCard.subtitle"
                actions={
                    <Button buttonType="primary" onClick={handleAddGrant}>
                        <FormattedMessage id="grant.tableCard.action" />
                    </Button>
                }
            >
                {isLoading ? (
                    <Loader withContainer />
                ) : (
                    <Table
                        rows={grantsTableRows}
                        columns={grantsTableColumns}
                        emptyMessageId="grant.emptyMessage"
                        onRowClickPath={Routes.Grant}
                        isSearchable={true}
                        searchPlaceholder="grant.search"
                        filters={['code']}
                        hasPagination={true}
                    />
                )}
            </Section>
            <DeleteModal
                title="grant.modal.warning"
                description="grant.modal.confirmDelete"
                isOpen={idToDelete !== null}
                onHide={handleCloseModal}
                onSubmit={handleDeleteGrant}
                isLoading={isLoadingDelete}
            />
        </>
    );
};
