/* eslint-disable react/display-name,@typescript-eslint/ban-ts-comment */
import { Controller, ControllerRenderProps, UseControllerProps, useFormContext } from 'react-hook-form';
import * as React from 'react';

// https://react-hook-form.com/advanced-usage#TransformandParse
type Transform<Inner, Change extends Function> = {
    input?: (value: any) => Inner | undefined;
    output?: Change;
};

export const withForm =
    <Props extends unknown>(
        Component: React.ComponentType<React.PropsWithChildren<Props>>,
    ): React.ComponentType<
        React.PropsWithChildren<
            Omit<Props, keyof ControllerRenderProps> &
                UseControllerProps & {
                    transform?: Transform<
                        Props extends { value?: infer V } ? V : any,
                        Props extends { onChange?: infer Change } ? Change : any
                    >;
                }
        >
    > =>
    ({ name, rules, shouldUnregister, defaultValue, transform, ...props }) => {
        const { control } = useFormContext();
        return (
            <Controller
                control={control}
                name={name}
                rules={rules}
                shouldUnregister={shouldUnregister}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => (
                    <Component
                        {...field}
                        onChange={(e: unknown, ...rest: unknown[]) =>
                            field.onChange(transform?.output?.(e, ...rest) ?? e)
                        }
                        value={transform?.input?.(field.value) ?? field.value}
                        // @ts-ignore
                        {...(props as Props)}
                        {...fieldState}
                        required={rules?.required}
                        error={fieldState.error?.message}
                    />
                )}
            />
        );
    };
