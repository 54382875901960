import React, { useEffect } from 'react';

export const KEYBOARD_KEY_K = 75;

export const useSpotlightModalHandler = (): {
    isSpotlightOpened: boolean;
    handleHideSpotlight: () => void;
} => {
    const [isSpotlightOpened, setIsSpotlightOpened] = React.useState<boolean>(false);

    useEffect(() => {
        const handleSpotlightOpen = (e: KeyboardEvent) => {
            if (e.keyCode === KEYBOARD_KEY_K && (e.metaKey || e.ctrlKey)) {
                setIsSpotlightOpened(true);
            }
        };

        document.addEventListener('keydown', handleSpotlightOpen);

        return () => {
            document.removeEventListener('keydown', handleSpotlightOpen);
        };
    }, []);

    const handleHideSpotlight = () => {
        setIsSpotlightOpened(false);
    };

    return { isSpotlightOpened, handleHideSpotlight };
};
