import { ApiTagTypes, innovorderApi } from '../../index';
import { ApiRequest, ApiResponse } from '../../request.types';
import {
    DeletePosAccessUserResponse,
    DeletePosAccessUsersBody,
    GetPosAccessUserByIdBody,
    GetPosAccessUserByIdResponse,
    GetPosAccessUsersBody,
    GetPosAccessUsersResponse,
    PostPosAccessUserBody,
    PostPosAccessUserResponse,
} from './pos_access_user.type';

const posAccessUsersApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getPosAccessUsers: build.query<GetPosAccessUsersResponse, ApiRequest<GetPosAccessUsersBody>>({
            query: ({ token, restaurantId }) => ({
                url: `/restaurants/${restaurantId}/pos_access/users`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: (response, _, queryArgs) => {
                if (response) {
                    return [
                        ...response.map(({ posUserId }) => ({
                            type: ApiTagTypes.PosUsers,
                            id: `${queryArgs.restaurantId}-${posUserId}`,
                        })),
                        { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-LIST` },
                    ];
                }
                return [{ type: ApiTagTypes.PosUsers, id: 'LIST' }];
            },
            transformResponse: ({ data }: ApiResponse<GetPosAccessUsersResponse>): GetPosAccessUsersResponse => data,
            extraOptions: { maxRetries: 3 },
        }),
        getPosAccessUserById: build.query<GetPosAccessUserByIdResponse, ApiRequest<GetPosAccessUserByIdBody>>({
            query: ({ token, restaurantId, posUserId }) => ({
                url: `/restaurants/${restaurantId}/pos_access/users/${posUserId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-${queryArgs.posUserId}` },
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<GetPosAccessUserByIdResponse>): GetPosAccessUserByIdResponse =>
                data,
            extraOptions: { maxRetries: 3 },
        }),
        createPosAccessUser: build.mutation<PostPosAccessUserResponse, ApiRequest<PostPosAccessUserBody>>({
            query: ({ token, restaurantId, ...body }) => ({
                url: `/restaurants/${restaurantId}/pos_access/users`,
                method: 'POST',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-LIST` },
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<PostPosAccessUserResponse>): PostPosAccessUserResponse => data,
        }),
        updatePosAccessUser: build.mutation<
            PostPosAccessUserResponse,
            ApiRequest<PostPosAccessUserBody & { userId: number }>
        >({
            query: ({ token, restaurantId, userId, ...body }) => ({
                url: `/restaurants/${restaurantId}/pos_access/users/${userId}`,
                method: 'PATCH',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-LIST` },
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-${queryArgs.userId}` },
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<PostPosAccessUserResponse>): PostPosAccessUserResponse => data,
        }),
        deletePosAccessUser: build.mutation<DeletePosAccessUserResponse, ApiRequest<DeletePosAccessUsersBody>>({
            query: ({ token, restaurantId, posUserId, ...body }) => ({
                url: `/restaurants/${restaurantId}/pos_access/users/${posUserId}`,
                method: 'DELETE',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-LIST` },
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<DeletePosAccessUserResponse>): DeletePosAccessUserResponse =>
                data,
        }),
    }),
});

export const {
    useGetPosAccessUserByIdQuery,
    useGetPosAccessUsersQuery,
    useCreatePosAccessUserMutation,
    useUpdatePosAccessUserMutation,
    useDeletePosAccessUserMutation,
} = posAccessUsersApi;
