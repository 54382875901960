import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie/es6/types';
import { ENV } from '@/utils/env';
import { computeCookieDomain, COOKIE_PATH } from '@/pages/Login/Login';

export const cookieLocation = `innovorder.dashboards-${ENV.DASHBOARD_ENV}`;
export const cookieLocationGuest = `innovorder.dashboards-${ENV.DASHBOARD_ENV}-guest`;
export const cookieLocationBrandGuest = `innovorder.dashboards-${ENV.DASHBOARD_ENV}-guest-brand`;

export type AuthCookie = {
    accessToken: string;
};
export type GuestBrandAuthCookie = AuthCookie & { brandGroupId: number; mainUserEmail: string };

export const selectValidToken = (
    token: AuthCookie | undefined,
    guestToken: AuthCookie | undefined,
    guestBrandToken: GuestBrandAuthCookie | undefined,
): { token: string; isGuest: boolean; isGuestBrand: boolean } => {
    if (guestToken !== undefined && token !== undefined) {
        return { token: guestToken.accessToken, isGuest: true, isGuestBrand: false };
    }
    if (guestBrandToken !== undefined && token !== undefined) {
        return { token: guestBrandToken.accessToken, isGuest: false, isGuestBrand: true };
    }

    if (token !== undefined) {
        return { token: token.accessToken, isGuest: false, isGuestBrand: false };
    }

    return { token: '', isGuest: false, isGuestBrand: false };
};

const removeCookieWithPath = (
    removeCookie: (name: string, options?: CookieSetOptions | undefined) => void,
): ((name: string, options?: CookieSetOptions | undefined) => void) => {
    return (name: string, options?: CookieSetOptions | undefined) => {
        removeCookie(name, { ...options, path: COOKIE_PATH, domain: computeCookieDomain(window.location.host) });
    };
};

export const useAuthCookieHandler = (): {
    removeCookie: (name: string, options?: CookieSetOptions | undefined) => void;
    token: string;
    isGuest: boolean;
    isGuestBrand: boolean;
} => {
    const [cookies, , removeCookie] = useCookies();
    const authCookie = cookies[cookieLocation];
    const guestAuthCookie = cookies[cookieLocationGuest];
    const guestBrandToken = cookies[cookieLocationBrandGuest];

    const { isGuest, token, isGuestBrand } = selectValidToken(authCookie, guestAuthCookie, guestBrandToken);
    return { removeCookie: removeCookieWithPath(removeCookie), token, isGuest, isGuestBrand };
};
