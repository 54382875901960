import React from 'react';
import DeleteModal from '@/components/atoms/DeleteModal';
import { DeleteGroupErrorModal } from '../DeleteGroupErrorModal/DeleteGroupErrorModal';

type DeleteGroupContainerProps = {
    isOpen: boolean;
    canBeDeleted: boolean;
    onHide: () => void;
    onSubmit: () => void;
    isDeleteGroupLoading: boolean;
    isGuestsCountLoading: boolean;
};

const DeleteGroupContainer: React.FunctionComponent<React.PropsWithChildren<DeleteGroupContainerProps>> = ({
    isOpen,
    canBeDeleted,
    onSubmit,
    onHide,
    isDeleteGroupLoading,
    isGuestsCountLoading,
}) => {
    return canBeDeleted ? (
        <DeleteModal
            title="group.modal.warning"
            description={'group.modal.confirmDelete'}
            isOpen={isOpen}
            onHide={onHide}
            onSubmit={onSubmit}
            isLoading={isDeleteGroupLoading}
        />
    ) : (
        <DeleteGroupErrorModal isOpen={isOpen} onHide={onHide} isLoading={isGuestsCountLoading} />
    );
};

export default DeleteGroupContainer;
