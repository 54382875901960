import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import Loader from '@/components/atoms/Loader';
import { Text } from '@/components/atoms/Text';
import { Routes } from '@/core/router/routes.types';
import { CrossSellingForm } from '@/components/organisms/CrossSellingForm';
import { HeaderLeft, Subtitle } from '@/components/atoms/Section/Section.style';
import { Title } from '@/components/atoms/Title';
import { useEditCrossSellingVM } from './EditCrossSelling.viewmodel';
import { Container, Header, LoaderContainer } from './EditCrossSelling.style';

const EditCrossSelling: FunctionComponent = () => {
    const { title, tabRoutes, isLoadingGet, isLoadingUpdate, defaultValue, handleSubmit, handleCancel } =
        useEditCrossSellingVM();

    if (isLoadingGet)
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );

    if (!defaultValue) return <Redirect to={Routes.NotFound} />;

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <Header>
                    <HeaderLeft>
                        <Title text={title} />
                        <Subtitle>
                            <Text text="crossSelling.page.update.description" />
                        </Subtitle>
                    </HeaderLeft>
                </Header>
                <CrossSellingForm
                    defaultValue={defaultValue}
                    isLoading={isLoadingUpdate}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            </Container>
        </RouterNavigationTabs>
    );
};

export default EditCrossSelling;
