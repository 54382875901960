import React, { FunctionComponent } from 'react';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { CrossSellingForm } from '@/components/organisms/CrossSellingForm';
import { HeaderLeft, Subtitle } from '@/components/atoms/Section/Section.style';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import { useCreateCrossSellingVM } from './CreateCrossSelling.viewmodel';
import { Container, Header } from './CreateCrossSelling.style';

const CreateCrossSelling: FunctionComponent = () => {
    const { tabRoutes, isLoading, handleSubmit, handleCancel } = useCreateCrossSellingVM();

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <Header>
                    <HeaderLeft>
                        <Title text="crossSelling.page.create.title" />
                        <Subtitle>
                            <Text text="crossSelling.page.create.description" />
                        </Subtitle>
                    </HeaderLeft>
                </Header>
                <CrossSellingForm isLoading={isLoading} onSubmit={handleSubmit} onCancel={handleCancel} />
            </Container>
        </RouterNavigationTabs>
    );
};

export default CreateCrossSelling;
