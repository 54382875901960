import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTheme } from '@emotion/react';
import { ALL_DROP_DOWN_VALUES } from '@/pages/Brand/AutoBilling/AutoBillingRequest/AutoBillingRequestFilters/utils';
import { Container } from '@/pages/Brand/AutoBilling/AutoBillingRequest/AutoBillingRequestPreview/AutoBillingRequestPreview.style';
import Loader from '@/components/atoms/Loader';
import AutoBillingInvoices from '@/pages/Brand/AutoBilling/AutoBillingRequest/AutoBillingRequestPreview/AutoBillingInvoices';
import { AutoBillingInvoice, AutoBillingRequestPayload } from '@/services/innovorder/autoBilling/autoBilling.type';
import { usePostPreviewBillingRequestMutation } from '@/services/innovorder/autoBilling/autoBilling.endpoints';
import { getUserBrandId, getUserToken } from '@/redux/user';
import { Message } from '../AutoBillingRequest.style';

export type TableLine = {
    code: string;
    label: string;
    quantity: string;
    unitPriceWithTaxExcluded: string;
    totalTax: string;
    unitPriceWithTaxIncluded: string;
    totalPriceWithTaxIncluded: string;
    totalPriceWithTaxExcluded: string;
};

type AutoBillingRequestPreviewProps = {
    periodFrom: Date;
    periodTo: Date;
    filters: {
        entities: string[];
        restaurants: string[];
        pricingRules: string[];
        accountPaymentTypes: string[];
    };
    updateLoadingState: (canNext: boolean, canPrevious: boolean) => void;
};

const AutoBillingRequestPreview: React.FunctionComponent<React.PropsWithChildren<AutoBillingRequestPreviewProps>> = ({
    periodFrom,
    periodTo,
    filters,
    updateLoadingState,
}) => {
    const [postPreviewBillingRequest, { isSuccess, data: invoices, isLoading, error }] =
        usePostPreviewBillingRequestMutation();
    const token = useSelector(getUserToken);
    const theme = useTheme();
    const brandId = useSelector(getUserBrandId);
    const autoBillingRequest: AutoBillingRequestPayload = useMemo(
        () => ({
            periodFrom: dayjs(periodFrom).format('YYYY-MM-DD'),
            periodTo: dayjs(periodTo).format('YYYY-MM-DD'),
            brandId: brandId as number,
            filters: {
                entities: filters.entities.filter((entity) => entity !== ALL_DROP_DOWN_VALUES),
                restaurants: filters.restaurants.filter((restaurant) => restaurant !== ALL_DROP_DOWN_VALUES),
                accountPaymentTypes: filters.accountPaymentTypes.filter(
                    (paymentType) => paymentType !== ALL_DROP_DOWN_VALUES,
                ),
                pricingRules: filters.pricingRules.filter((rule) => rule !== ALL_DROP_DOWN_VALUES),
            },
        }),
        [
            brandId,
            filters.accountPaymentTypes,
            filters.entities,
            filters.pricingRules,
            filters.restaurants,
            periodFrom,
            periodTo,
        ],
    );
    useEffect(() => {
        postPreviewBillingRequest({
            ...autoBillingRequest,
            token,
        });
    }, [brandId, token, periodFrom, periodTo, postPreviewBillingRequest, filters, autoBillingRequest]);

    useEffect(() => {
        updateLoadingState(!isLoading, !isLoading);
    }, [isLoading, isSuccess, updateLoadingState]);

    useEffect(() => {
        if (error !== undefined) {
            updateLoadingState(false, true);
        }
    }, [error, isLoading, updateLoadingState]);

    useEffect(() => {
        if (invoices && invoices.length > 0) {
            updateLoadingState(true, true);
        } else {
            updateLoadingState(false, true);
        }
    }, [invoices, updateLoadingState]);

    return (
        <Container>
            {isLoading && <Loader color={theme.color.primary} size={40} />}
            {!isLoading && error && 'status' in error && (
                <Message
                    text={`auto.billing.request.preview.error${error.status === 400 ? 'undefinedReferential' : ''}`}
                />
            )}
            {!isLoading && isSuccess && invoices && (
                <AutoBillingInvoices
                    autoBillingRequest={autoBillingRequest}
                    invoices={invoices as AutoBillingInvoice[]}
                />
            )}
        </Container>
    );
};

export default AutoBillingRequestPreview;
