import { ConsumptionMode, CONSUMPTION_MODE_FLAGS } from '../consumptionMode/consumptionMode.type';
import { ChannelId } from '../channel/channel.type';

export enum PaymentMethodCode {
    AlloResto = 'AlloResto',
    AmericanExpress = 'AmericanExpress',
    Cash = 'Cash',
    Change = 'Change',
    Check = 'Check',
    CreditCard = 'CreditCard',
    CreditNote = 'CreditNote',
    CreditNoteIssued = 'CreditNoteIssued',
    Deliveroo = 'Deliveroo',
    Edenred = 'Edenred',
    ELuncheonVoucher = 'ELuncheonVoucher',
    EWallet = 'EWallet',
    ExternalPayment = 'ExternalPayment',
    Foodora = 'Foodora',
    Ingenico = 'Ingenico',
    LuncheonVoucher = 'LuncheonVoucher',
    Lydia = 'Lydia',
    Nepting = 'Nepting',
    Paygreen = 'Paygreen',
    RestoFlash = 'RestoFlash',
    Stripe = 'Stripe',
    Transfer = 'Transfer',
    UberEats = 'UberEats',
    Unknown = 'Unknown',
    Unknown2 = 'Unknown2',
    IOPay = 'IOPay',
    Yavin = 'Yavin',
}

export type CreatePaymentMethodPayload = {
    code: PaymentMethodCode;
    label: string;
    position: number;
    changeNotAllowed: boolean;
    creditNoteAllowed: boolean;
    directCollectionAllowed: boolean;
    openCashDrawer: boolean;
    defaultPayment: boolean;
    consumptionMode: number;
    consumptionModes: number[];
};

export type UpdatePaymentMethodPayload = {
    id?: number;
    paymentMethod?: string;
    active: boolean;
    paymentMethodId?: number;
    code: string;
    label: string;
    position: number;
    moduleStripeId?: number | null;
    modulePaygreenId?: number | null;
    moduleIngenicoId?: number | null;
    moduleIngenico?: number | null;
    modulePaygreen?: number | null;
    moduleStripe?: number | null;
    channelId: ChannelId;
    brandId: number;
    restaurantId: number;
    consumptionMode: number;
    consumptionModes: number[];
};

export type DeletePaymentMethodPayload = {
    paymentMethodId?: number;
};

export type BulkUpdatePaymentMethodPayload = {
    channelId: ChannelId;
    restaurantId: string;
    creates: CreatePaymentMethodPayload[];
    updates: UpdatePaymentMethodPayload[];
    deletes: DeletePaymentMethodPayload[];
};

export type GetPaymentMethodPayload = {
    channelId: ChannelId;
    restaurantId: string;
};

export type PaymentMethod = {
    active: boolean;
    brandId?: number;
    channelId: ChannelId;
    restaurantId?: number;
    position: number;
    code: PaymentMethodCode;
    label: string;
    paymentMethod?: string;
    paymentMethodId?: number;
    consumptionModes: ConsumptionMode[];
    action?: 'create' | 'update' | 'delete' | null;
    consumptionModeSumFlag?: number;
    consumptionModesFlags?: (typeof CONSUMPTION_MODE_FLAGS)[keyof typeof CONSUMPTION_MODE_FLAGS][];
    changeNotAllowed?: boolean;
    creditNoteAllowed?: boolean;
    directCollectionAllowed?: boolean;
    openCashDrawer?: boolean;
    defaultPayment?: boolean;
    externalId?: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
};
