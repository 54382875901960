import { routes } from '@/core/router/routes';
import { RouteDeclaration, Routes } from '@/core/router/routes.types';

export type SpotlightCta = 'brand' | 'restaurant' | 'pricingRule' | 'entranceFee' | 'grant';

export type SpotlightContext = {
    [key in SpotlightCta]?: string;
};

export type SpotlightPage = {
    url: string;
    name: string;
    parentName?: string;
    cta: SpotlightCta;
    context?: SpotlightContext;
    parentContext?: string;
};

export type SpotlightCookie = {
    [key: string]: number;
};

export const SpotlightRoutesList: Routes[] = [
    Routes.ConfigUbereats,
    Routes.ConfigDeliverect,
    Routes.GrantsAndEntranceFees,
    Routes.EntranceFee,
    Routes.Grant,
    Routes.PricingRule,
    Routes.CreateEntranceFee,
    Routes.CreateGrant,
    Routes.CreatePricingRule,
    Routes.BrandMenus,
    Routes.RestaurantMenus,
    Routes.BrandRestaurants,
    Routes.BrandUsers,
    Routes.Orders,
    Routes.OrderDetails,
];

export const SpotlightRouteDeclarationList: RouteDeclaration[] = routes.filter(
    ({ path, uri }) => (path && SpotlightRoutesList.includes(path)) || (uri && SpotlightRoutesList.includes(uri)),
);
