import { PaymentMethod, PaymentMethodCode } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { codeDefaultList, ParsedOptions } from '../../constants';

export const getPaymentMethod = (
    paymentMethods: PaymentMethod[],
    code: PaymentMethodCode,
    channelId: ChannelId,
): PaymentMethod => {
    const paymentMethod = paymentMethods.find((pm) => pm.code === code);
    if (paymentMethod) return paymentMethod;
    const defaultPaymentMethod = codeDefaultList[code];
    return {
        label: defaultPaymentMethod.label,
        active: true,
        channelId,
        position: Object.keys(codeDefaultList).indexOf(code),
        code,
        consumptionModes: [],
        creditNoteAllowed: defaultPaymentMethod.parsedOptions.includes(ParsedOptions.CREDIT_NOTE_ALLOWED),
        changeNotAllowed: defaultPaymentMethod.parsedOptions.includes(ParsedOptions.CHANGE_ALLOWED),
    };
};
