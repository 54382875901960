import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white100};
    padding: 0;
    box-sizing: border-box;
`;

export const UploadModalContainer = styled(Container)`
    max-height: 800px;
    overflow-y: scroll;
`;
