import { Input } from '@/components/atoms/Form/Input';
import React, { useEffect } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { OnChangeValue } from 'react-select';

import Select, { Option } from '@/components/atoms/Select';
import MultiSelect, { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { Label } from '@/components/atoms/Label';
import { Text } from '@/components/atoms/Text';
import { computeText } from '@/locales/utils';
import { SavingBar } from '@/components/atoms/SavingBar';
import { computeAndTriggerTouchedFields } from '@/utils/form/utils';
import {
    BasePricingRuleCreateAttributes,
    PricingRuleApplyScope,
    PricingRuleCreateAttributes,
} from '@/services/innovorder/pricingRule/pricingRule.type';
import {
    mapOptionToApplyScope,
    mapApplyScopeToOptions,
    mapOptionToBlockedChannels,
    mapBlockedChannelsToOptions,
    CanBeBlockedChannels,
} from './utils';
import { PricingRuleComposition } from './PricingRuleComposition';
import {
    Container,
    FlexContainer,
    TitleContainer,
    CustomTitle,
    FormContainer,
    MargedTitle,
    Flex,
    FormChildrens,
} from './PricingRuleForm.style';

export const applyScopeMap = (intl: IntlShape): Option<PricingRuleApplyScope>[] => [
    { label: computeText(intl, 'pricingRule.fee'), value: 'FEE' },
    { label: computeText(intl, 'pricingRule.product'), value: 'PRODUCT' },
];

export const isUrssafEnabledMap = (intl: IntlShape): Option<boolean>[] => [
    { label: computeText(intl, 'select.disabled'), value: false },
    { label: computeText(intl, 'select.enabled'), value: true },
];

export const canBeBlockedChannelsMap = (intl: IntlShape): SelectOptionInterface<CanBeBlockedChannels>[] => [
    { label: computeText(intl, 'pricingRule.channels.pos'), value: CanBeBlockedChannels.POS, isFixed: true },
    {
        label: computeText(intl, 'pricingRule.channels.ewalletKiosk'),
        value: CanBeBlockedChannels.EWALLET_KIOSK,
        isFixed: true,
    },
    {
        label: computeText(intl, 'pricingRule.channels.vendingMachine'),
        value: CanBeBlockedChannels.VENDING_MACHINE,
        isFixed: false,
    },
    { label: computeText(intl, 'pricingRule.channels.kiosk'), value: CanBeBlockedChannels.KIOSK, isFixed: false },
    { label: computeText(intl, 'pricingRule.channels.web'), value: CanBeBlockedChannels.WEB, isFixed: false },
];

type PricingRuleFormProps = {
    pricingRule?: PricingRuleCreateAttributes;
    error?: string;
    loading: boolean;
    onSubmit: (arg: PricingRuleCreateAttributes) => void;
    createMode: boolean;
};

export const PricingRuleForm: React.FunctionComponent<React.PropsWithChildren<PricingRuleFormProps>> = ({
    pricingRule,
    error: callError,
    loading,
    onSubmit,
    createMode,
}) => {
    const intl = useIntl();

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        setError,
        clearErrors,
        formState: { touchedFields, dirtyFields, isSubmitSuccessful },
        trigger,
        reset,
    } = useForm<BasePricingRuleCreateAttributes>({
        defaultValues: {
            ...pricingRule,
        },
    });

    const finalTouchedFields = computeAndTriggerTouchedFields(pricingRule, touchedFields, trigger);

    useEffect(() => {
        if (pricingRule && !createMode) {
            setValue('brandId', pricingRule.brandId);
            setValue('code', pricingRule.code);
            setValue('applyScope', pricingRule.applyScope);
            setValue('blockedChannels', pricingRule.blockedChannels);
            setValue('version', pricingRule.version);
            setValue('isUrssafEnabled', pricingRule.isUrssafEnabled);
        }
    }, [setValue, pricingRule, createMode]);

    const submit: SubmitHandler<BasePricingRuleCreateAttributes> = (data) => {
        if (!data.applyScope.length) {
            setError('applyScope', { type: 'required' }, { shouldFocus: true });
            return;
        }

        onSubmit({ ...data, code: data.code.trim() } as PricingRuleCreateAttributes);
    };

    const handleCodeChange =
        (onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, onBlur: () => void) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onBlur();
            onChange(event);
            trigger('code');
        };

    const handleApplyScopeChange =
        (onChange: (...event: any[]) => void, onBlur: () => void) =>
        (options: OnChangeValue<SelectOptionInterface<PricingRuleApplyScope>, true>) => {
            onBlur();
            clearErrors('applyScope');
            onChange(mapOptionToApplyScope(options));
            trigger('applyScope');
        };

    const handleBlockedChannelsChange =
        (onChange: (...event: any[]) => void, onBlur: () => void) =>
        (options: OnChangeValue<SelectOptionInterface<CanBeBlockedChannels>, true>) => {
            onBlur();
            clearErrors('blockedChannels');
            onChange(mapOptionToBlockedChannels(canBeBlockedChannelsMap(intl), options));
            trigger('blockedChannels');
        };

    const handleIsUrssafEnabledChange =
        (onChange: (...event: any[]) => void, onBlur: () => void) => (value: boolean) => {
            onBlur();
            onChange(value);
            trigger('isUrssafEnabled');
        };

    const handleCancelChanges = (): void => {
        reset(pricingRule);
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(getValues());
        }
    }, [isSubmitSuccessful, getValues, reset]);

    return (
        <>
            <Container>
                <form>
                    <FlexContainer>
                        <div style={{ flex: 1, paddingRight: 50 }}>
                            <TitleContainer>
                                <CustomTitle text="pricingRule.form.title" />
                                {pricingRule && <CustomTitle text={pricingRule.code} />}
                            </TitleContainer>

                            <Text text="pricingRule.form.description" />
                        </div>
                        <FormContainer data-testid="pricingRule-form">
                            <MargedTitle
                                text={pricingRule?.code ? 'pricingRule.form.update' : 'pricingRule.form.creation'}
                                type="small"
                                weight="medium"
                            />
                            <Flex>
                                <FormChildrens style={{ marginRight: 20 }}>
                                    <Controller
                                        control={control}
                                        name="code"
                                        rules={{ required: computeText(intl, 'field.required') }}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <Input
                                                error={error?.message}
                                                onChange={handleCodeChange(onChange, onBlur)}
                                                onBlur={handleCodeChange(onChange, onBlur)}
                                                value={value}
                                                placeholder="pricingRule.form.pricingRuleCode"
                                                labelId="pricingRule.form.pricingRuleCode"
                                                isTouched={finalTouchedFields.code}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="applyScope"
                                        rules={{ required: computeText(intl, 'field.required') }}
                                        render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
                                            <>
                                                <Label
                                                    labelId="pricingRule.form.applyScope"
                                                    inputId="pricingRule.form.applyScope"
                                                />
                                                <MultiSelect<PricingRuleApplyScope>
                                                    data-testid="pricingRuleForm-applyScope"
                                                    inputId="pricingRule.form.applyScope"
                                                    isMulti={true}
                                                    options={applyScopeMap(intl)}
                                                    getOptionValue={(option) => option.value}
                                                    onChange={handleApplyScopeChange(onChange, onBlur)}
                                                    onBlur={onBlur}
                                                    placeholder={computeText(intl, 'pricingRule.form.applyScope')}
                                                    value={mapApplyScopeToOptions(
                                                        applyScopeMap(intl),
                                                        getValues('applyScope'),
                                                    )}
                                                    error={error}
                                                    isTouched={finalTouchedFields.applyScope as unknown as boolean}
                                                />
                                            </>
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="blockedChannels"
                                        render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
                                            <>
                                                <Label
                                                    labelId="pricingRule.form.authorizedChannels"
                                                    inputId="pricingRule.form.authorizedChannels"
                                                />
                                                <MultiSelect<CanBeBlockedChannels>
                                                    inputId="pricingRule.form.authorizedChannels"
                                                    isMulti={true}
                                                    options={canBeBlockedChannelsMap(intl)}
                                                    getOptionValue={(option) => option.value}
                                                    onChange={handleBlockedChannelsChange(onChange, onBlur)}
                                                    onBlur={onBlur}
                                                    placeholder={computeText(
                                                        intl,
                                                        'pricingRule.form.authorizedChannels',
                                                    )}
                                                    value={mapBlockedChannelsToOptions(
                                                        canBeBlockedChannelsMap(intl),
                                                        getValues('blockedChannels'),
                                                    )}
                                                    error={error}
                                                    isTouched={finalTouchedFields.blockedChannels as unknown as boolean}
                                                />
                                            </>
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="isUrssafEnabled"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Select<boolean>
                                                value={value ?? false}
                                                options={isUrssafEnabledMap(intl)}
                                                onChange={handleIsUrssafEnabledChange(onChange, onBlur)}
                                                onBlur={onBlur}
                                                labelId="pricingRule.form.isUrssafEnabled"
                                                isTouched={
                                                    value !== null && value !== undefined
                                                        ? finalTouchedFields.isUrssafEnabled
                                                        : undefined
                                                }
                                            />
                                        )}
                                    />
                                </FormChildrens>
                            </Flex>
                        </FormContainer>
                    </FlexContainer>
                    <CustomTitle text="pricingRule.form.composition" />
                    <Text text="pricingRule.form.compositionDescription" />
                    <div style={{ marginTop: 20 }}>
                        <PricingRuleComposition />
                    </div>
                </form>
            </Container>
            <SavingBar
                onSave={handleSubmit(submit)}
                error={callError}
                loading={loading}
                changesCount={Object.keys(dirtyFields).length}
                onCancel={handleCancelChanges}
            />
        </>
    );
};
