import styled from '@emotion/styled';

export const Container = styled.div<{ isSidebarCollapsed: boolean }>`
    width: ${({ isSidebarCollapsed }): string => (isSidebarCollapsed ? '54px' : '234px')};
    height: 100vh;
    background-color: ${({ theme }): string => theme.color.slate300};
    display: flex;
    transition: ease 200ms;
    flex-direction: column;
    transition: ${({ theme }): string => theme.breakpoints.tablet} {
        display: none;
    }
    position: relative;
`;

export const SideHeader = styled.div<{ isSidebarCollapsed: boolean }>`
    position: relative;
    z-index: 1;
    background-color: ${({ theme }): string => theme.color.slate300};
    padding: 20px 10px;
    overflow: hidden;
    transition: all 200ms ease;
    width: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? '40px' : 'auto')};
`;

export const Padded = styled.div`
    flex: 1;
    display: flex;
    overflow: auto;
    position: relative;
    padding: 10px;
`;

export const Logo = styled.img`
    height: 20px;
    margin-left: 10px;
`;

export const SidebarToggleButton = styled.button<{ isSidebarCollapsed: boolean }>`
    position: absolute;
    transition: 200ms ease;
    top: 0;
    left: 100%;
    transform: translateX(-100%);
    width: ${({ theme }): string => theme.savory.spacing[4]};
    aspect-ratio: 1;
    z-index: 2;
    border-bottom-right-radius: ${({ theme }): string => theme.savory.spacing[1]};
    border-top-right-radius: ${({ theme }): string => theme.savory.spacing[1]};
    border: 1px solid
        ${({ theme, isSidebarCollapsed }): string => (isSidebarCollapsed ? theme.color.slate300 : theme.color.white200)};
    border-left: none;
    background-color: ${({ theme }): string => theme.color.slate300};
    transform: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
    transform-origin: left;
    cursor: pointer;
`;
