import styled from '@emotion/styled';

export const StyledModalContainer = styled.div`
    width: 560px;
    height: 480px;
`;

export const StyledClientCreationForm = styled.div`
    color: ${({ theme }) => theme.color.black200};
    font-family: ${({ theme }): string => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: ${({ theme }) => theme.font.size.small};
    padding-bottom: 20px;
`;
