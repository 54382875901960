import { Table } from '@/components/atoms/Table';
import React from 'react';
import { useGroupsTableVM } from './GroupsList.viewmodel';
import Modal from '@/components/atoms/Modal';
import DeleteModal from '@/components/atoms/DeleteModal';
import { useDeletePosAccessGroupMutation } from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.endpoints';

const PosGroupsListTable: React.FunctionComponent<React.PropsWithChildren> = () => {
    const {
        headers,
        rows,
        isGetGroupsLoading,
        isLoadingDelete,
        handleOnRowClick,
        handleOnDeleteClick,
        idToDelete,
        handleCloseModal,
    } = useGroupsTableVM();

    return (
        <>
            <DeleteModal
                title="grant.modal.warning"
                description="grant.modal.confirmDelete"
                isOpen={idToDelete !== null}
                onHide={handleCloseModal}
                onSubmit={handleOnDeleteClick}
                isLoading={isLoadingDelete}
            />
            <Table
                isSearchable
                rows={rows}
                emptyMessageId="table.noRowsAfterFilter"
                columns={headers}
                hasPagination
                hasUrlNavigation
                isSortable
                isLoading={isGetGroupsLoading}
                onRowClick={handleOnRowClick}
            />
        </>
    );
};

export { PosGroupsListTable };
