import * as React from 'react';
import { Form } from '@/core/form/Form';
import { FormattedMessage } from 'react-intl';
import { SubmitHandler } from 'react-hook-form';
import { ActionBar, FormContent } from '@/components/atoms/Form';
import { CrossSellingWithProducts } from '@/services/innovorder/crossSelling/crossSelling.type';
import { CrossSellingFields } from '@/components/organisms/CrossSellingForm/CrossSellingFields';
import { SvyButton } from '@/components/savory/SvyButton';
import { useRouteMatch } from 'react-router-dom';

export interface CrossSellingFormProps {
    defaultValue?: CrossSellingWithProducts;
    isLoading: boolean;
    onSubmit: SubmitHandler<CrossSellingWithProducts>;
    onCancel: () => void;
}

export const CrossSellingForm: React.FunctionComponent<CrossSellingFormProps> = ({
    defaultValue,
    isLoading,
    onSubmit,
    onCancel,
}) => {
    const { params } = useRouteMatch<{ brandId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);

    return (
        <Form defaultValues={defaultValue} mode="onChange" preventLeave>
            {({ handleSubmit }) => {
                return (
                    <FormContent onSubmit={handleSubmit(onSubmit)}>
                        <CrossSellingFields brandId={brandId} />
                        <ActionBar>
                            <SvyButton data-testid="cross-selling-form-cancel" onClick={onCancel} variant="text">
                                <FormattedMessage id="button.cancel" />
                            </SvyButton>
                            <SvyButton data-testid="cross-selling-form-submit" type="submit" isLoading={isLoading}>
                                <FormattedMessage id="button.validate" />
                            </SvyButton>
                        </ActionBar>
                    </FormContent>
                );
            }}
        </Form>
    );
};
