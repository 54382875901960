import styled from '@emotion/styled';

export const TableContainer = styled.div`
    table {
        width: 100%;
        border-spacing: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 100%;
        text-align: left;
        border-radius: ${({ theme }) => theme.radius.main};
        border: 1px solid ${({ theme }) => theme.color.grey300};
        background-color: ${({ theme }): string => theme.color.white100};
        color: ${({ theme }): string => theme.color.black200};
        font-family: ${({ theme }): string => theme.font.family};
        font-size: ${({ theme }): string => theme.font.size.normal};

        th {
            color: ${({ theme }): string => theme.color.black};
            font-size: ${({ theme }): string => theme.font.size.small};
            height: 50px;
        }

        th,
        td {
            margin: 0;
            border-bottom: 1px solid ${({ theme }): string => theme.color.grey300};
            padding: 0 ${({ theme }) => theme.spacing[4]};
        }

        td {
            height: 60px;
        }

        tr:last-child {
            td {
                border: none;
            }
        }

        tbody > tr:hover {
            background-color: ${({ theme }): string => theme.color.white200};
        }

        .clickable:hover {
            cursor: pointer;
        }
    }
`;

export const Section = styled.div`
    color: ${({ theme }) => theme.color.black200};
    font-size: ${({ theme }) => theme.font.size.large};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;
