import React, { useCallback } from 'react';
import { useTheme } from '@emotion/react';
import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';
import Loader from '@/components/atoms/Loader';

type ClickableIconState = 'active' | 'inactive' | 'disabled' | 'loading';

const Container = styled.div<{ state: ClickableIconState }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 80px;
    cursor: ${({ state }) => (state === 'disabled' ? 'inherit' : 'pointer')};
`;

const Logo = styled.div<{ state: ClickableIconState }>`
    display: flex;
    flex-direction: column;
    border: 2px solid
        ${({ theme, state }) => {
            if (state === 'active') return theme.color.emerald;
            if (state === 'inactive') return theme.color.bloodyMary;
            return theme.color.grey200;
        }};
    background: ${({ theme, state }) => (state === 'disabled' ? theme.color.white200 : 'none')};
    opacity: ${({ state }) => (state === 'disabled' ? 0.2 : 1)};
    border-radius: ${({ theme }) => theme.radius.main};
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FormattedLabel = styled(Text)<{ state: ClickableIconState }>`
    background: ${({ theme, state }) => {
        if (state === 'active') return theme.color.emerald;
        if (state === 'inactive') return theme.color.bloodyMary;
        return theme.color.white200;
    }};
    border: 1px solid
        ${({ theme, state }) => {
            if (state === 'active') return theme.color.emerald;
            if (state === 'inactive') return theme.color.bloodyMary;
            return theme.color.grey200;
        }};
    width: 100%;
    color: ${({ theme, state }) => {
        if (state === 'disabled') return theme.color.grey200;
        return theme.color.white100;
    }};
    border-radius: ${({ theme }) => theme.radius.main};
    text-align: center;
    margin-top: ${({ theme }) => theme.spacing[1]};
    line-height: 20px;
    padding: 0 2px;
`;

const Name = styled(Text)`
    color: ${({ theme }) => theme.color.black};
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
`;

type ClickableIconProps = {
    value: unknown;
    state: ClickableIconState;
    icon: string;
    label: string;
    name?: string;
    onClick: (value: unknown) => void;
};

const ClickableIcon: React.FunctionComponent<React.PropsWithChildren<ClickableIconProps>> = ({
    value,
    state,
    icon,
    label,
    name,
    onClick,
    children,
    ...otherProps
}) => {
    const theme = useTheme();
    const handleClick = useCallback(() => {
        if (['disabled', 'loading'].includes(state)) return;
        onClick(value);
    }, [value, onClick, state]);

    return (
        <Container state={state} onClick={handleClick} {...otherProps}>
            <Logo state={state}>
                {state === 'loading' ? (
                    <Loader color={theme.color.primary} size={24} withContainer />
                ) : (
                    <img src={icon} alt={`ClickableIcon ${label} ${value}`} />
                )}
                {!!name && <Name text={name} />}
            </Logo>
            <FormattedLabel state={state} type="small" text={label} />
            {children}
        </Container>
    );
};

export default ClickableIcon;
