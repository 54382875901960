import { DependencyList, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
const useDebounce = (callback: Function, delay = 0, deps: DependencyList = []): void => {
    useEffect(() => {
        const wait = setTimeout(callback, delay);

        return () => {
            clearTimeout(wait);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, callback, delay]);
};

export default useDebounce;
