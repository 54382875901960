import { Routes } from '@/core/router/routes.types';
import { computeCurrency } from '@/locales/utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Container, FieldContainer, StyledLabel, StyledLinkValue, StyledValue } from '../TransactionDetail.style';

type TransactionDetailFinanceDataProps = {
    commissionAmount: number;
    transferId?: number;
    transferRef?: string;
    reversalAmount: number;
};

const FinanceData: React.FunctionComponent<React.PropsWithChildren<TransactionDetailFinanceDataProps>> = ({
    commissionAmount,
    transferId,
    transferRef,
    reversalAmount,
}) => {
    const intl = useIntl();

    return (
        <Container>
            <FieldContainer>
                <StyledLabel text="transaction.field.fee" />
                <StyledValue>{computeCurrency({ intl, amount: commissionAmount })}</StyledValue>
            </FieldContainer>
            {transferId && (
                <>
                    <FieldContainer>
                        <StyledLabel text="transaction.field.reversalAmount" />
                        <StyledValue>{computeCurrency({ intl, amount: reversalAmount })}</StyledValue>
                    </FieldContainer>
                    <FieldContainer>
                        <StyledLabel text="transaction.field.transfer" />
                        <Link to={`${Routes.IOPayTransfers}/${transferId}`}>
                            <StyledLinkValue>{transferRef}</StyledLinkValue>
                        </Link>
                    </FieldContainer>
                </>
            )}
        </Container>
    );
};

export default FinanceData;
