import { Text } from '@/components/atoms/Text';
import GenericCalendar from '@/components/atoms/Calendar';
import styled from '@emotion/styled';

export const Container = styled.div`
    flex: 1;
    padding: ${({ theme }) => theme.spacing[4]};
    background-color: ${({ theme }): string => theme.color.grey300};
`;

export const Header = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    width: 38%;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    width: 100%;
`;

export const Subtitle = styled(Text)`
    line-height: 20px;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};

    h2 {
        margin-bottom: ${({ theme }) => theme.spacing[2]};
    }
`;

export const FormContainer = styled.div`
    width: 60%;
    margin-top: -90px;
    margin-left: auto;
    background-color: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
`;

export const InputText = styled(Text)`
    margin-left: ${({ theme }) => theme.spacing[5]};
    margin-top: ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const Calendar = styled(GenericCalendar)`
    div.react-datepicker {
        border-top: 0;
    }
`;

export const ReportContainer = styled.div`
    margin: ${({ theme }) => theme.spacing[4]};
`;
