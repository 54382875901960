import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    CreateCustomerPayload,
    Customer,
    GetCustomerPayLoad,
    GetCustomerListPayLoad,
    ICustomerAttributes,
    UpdateCustomerPayload,
    GetCustomerListResponse,
    CreateBadgePayload,
    UpdateBadgePayload,
    DeleteBadgePayload,
} from './customer.types';

const customerApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        createCustomer: build.mutation<Customer, ApiRequest<CreateCustomerPayload>>({
            query: ({
                firstName,
                lastName,
                password,
                confirmPassword,
                badgeNumber,
                email,
                brandId,
                accountPaymentType,
                accountType,
                guestGroupId,
                pricingRuleCode,
                studentNumber,
                role,
                token,
                section,
                subSection,
            }) => ({
                url: `/brands/${brandId}/customers`,
                method: 'POST',
                body: {
                    firstName,
                    lastName,
                    password,
                    confirmPassword,
                    badgeNumber,
                    email,
                    brandId,
                    role,
                    student: {
                        accountPaymentType,
                        accountType,
                        guestGroupId,
                        pricingRuleCode,
                        studentNumber,
                        section,
                        subSection,
                    },
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<Customer>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getCustomerList: build.mutation<GetCustomerListResponse, ApiRequest<GetCustomerListPayLoad>>({
            query: ({
                brandId,
                getArchivedCustomers,
                getDisabledCustomers,
                selectedRestaurantId,
                emailDomainNames,
                groups,
                search,
                orderBy,
                limit,
                offset,
            }) => {
                const include = ['orderStats', 'optIn', 'ewalletBalance'];

                if (getArchivedCustomers) {
                    include.push('archived');
                }

                if (getDisabledCustomers) {
                    include.push('disabled');
                }

                const params = {
                    brandId,
                    ...(selectedRestaurantId ? { restaurantIds: selectedRestaurantId } : {}),
                    ...(emailDomainNames ? { emailDomainNames } : {}),
                    ...(groups ? { groups } : {}),
                    ...(search ? { search } : {}),
                    ...(orderBy ? { orderBy } : {}),
                    limit,
                    offset,
                    include: include.join(','),
                };

                return {
                    url: '/customers',
                    method: 'GET',
                    params,
                };
            },
            transformResponse: (response: ApiResponse<GetCustomerListResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getCustomerByCustomerId: build.query<ICustomerAttributes, ApiRequest<GetCustomerPayLoad>>({
            query: ({ customerId, token }) => ({
                url: `/customers/${customerId}?unscoped=true`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.Customer],
            transformResponse: (response: ApiResponse<ICustomerAttributes>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        updateCustomer: build.mutation<ICustomerAttributes, ApiRequest<UpdateCustomerPayload>>({
            query: (updateCustomerPayloadWithToken) => {
                const { token, customerId, ...updateCustomerPayload } = updateCustomerPayloadWithToken;
                return {
                    url: `customers/${customerId}`,
                    method: 'PUT',
                    body: {
                        ...updateCustomerPayload,
                    },
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: ApiResponse<ICustomerAttributes>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        addBadge: build.mutation<ICustomerAttributes, ApiRequest<CreateBadgePayload>>({
            query: (badgePayloadWithToken) => {
                const { token, customerId, ...badgePayload } = badgePayloadWithToken;
                return {
                    url: `customers/${customerId}/badges`,
                    method: 'POST',
                    body: {
                        ...badgePayload,
                    },
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: ApiResponse<ICustomerAttributes>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        updateBadge: build.mutation<ICustomerAttributes, ApiRequest<UpdateBadgePayload>>({
            query: (badgePayloadWithToken) => {
                const { token, customerId, badgeId, ...badgePayload } = badgePayloadWithToken;
                return {
                    url: `customers/${customerId}/badges/${badgeId}`,
                    method: 'PUT',
                    body: {
                        ...badgePayload,
                    },
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: ApiResponse<ICustomerAttributes>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        deleteBadge: build.mutation<ICustomerAttributes, ApiRequest<DeleteBadgePayload>>({
            query: ({ token, customerId, badgeId }) => {
                return {
                    url: `customers/${customerId}/badges/${badgeId}`,
                    method: 'DELETE',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: ApiResponse<ICustomerAttributes>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetCustomerListMutation,
    useCreateCustomerMutation,
    useGetCustomerByCustomerIdQuery,
    useUpdateCustomerMutation,
    useAddBadgeMutation,
    useUpdateBadgeMutation,
    useDeleteBadgeMutation,
} = customerApi;
