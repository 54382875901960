import { useEffect } from 'react';

const useScript = (script: string, shouldExecute: boolean): void => {
    useEffect(() => {
        const scriptTag = document.createElement('script');
        if (shouldExecute) {
            scriptTag.innerHTML = script;
            document.head.appendChild(scriptTag);
        }
    }, [script, shouldExecute]);
};

export default useScript;
