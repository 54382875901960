import React from 'react';
import { Container } from './StripeIntegration.style';
import { useStripeIntegrationsVM } from './StripeIntegration.viewmodel';

const StripeIntegration: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { moduleStripe } = useStripeIntegrationsVM();

    return (
        <Container>
            <span role="img" aria-label="WIP">
                🚧
            </span>
            <br />
            {JSON.stringify(moduleStripe, undefined, 1)}
        </Container>
    );
};

export default StripeIntegration;
