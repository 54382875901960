import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.spacing[4]};

    > div {
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;
