import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { Input } from '@/components/atoms/Form/Input';
import { DropDown } from '@/components/atoms/DropDown';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Text } from '@/components/atoms/Text';
import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import { Button } from '@/components/atoms/Button';
import Modal from '@/components/atoms/Modal';
import { CostSlice, CostSliceMode } from '@/services/innovorder/autoBilling/autoBilling.type';
import { getIntersectingSlice } from '../utils';
import {
    Container,
    DropDownButton,
    DropDownButtonContainer,
    Line,
    LineElement,
    LineElementContent,
    StyledText,
} from './CostSliceEditorModal.style';

type CostSliceEditorModalProps = {
    costSlices?: CostSlice[];
    onHide: () => void;
    slice: CostSlice;
    onSliceChange: (costSlice: CostSlice) => void;
};

const CostSliceEditorModal: React.FunctionComponent<React.PropsWithChildren<CostSliceEditorModalProps>> = ({
    costSlices,
    onHide,
    slice,
    onSliceChange,
}) => {
    const intl = useIntl();

    const [selectedSliceMode, setSelectedSliceMode] = useState(slice.mode);

    const {
        control,
        getValues,
        setValue,
        trigger,
        formState: { isValid, touchedFields },
    } = useForm<CostSlice>({
        mode: 'all',
        defaultValues: {
            ...slice,
            value: slice.value / CURRENCY_RATE,
        },
    });

    const getInputErrorMessage = (value: number): string | undefined => {
        if (value < 0) {
            return computeText(intl, 'auto.billing.referential.accountingCostCompute.modal.input.negative');
        }
        const from = Number(getValues().from);
        const to = Number(getValues().to);
        if (from >= to) {
            return computeText(
                intl,
                'auto.billing.referential.accountingCostCompute.modal.input.maxLowerThanOrEqualToMin',
            );
        }
        const intersectingSlice = getIntersectingSlice(
            {
                from,
                to,
                value: Number(getValues().value) * CURRENCY_RATE,
                mode: selectedSliceMode,
            },
            costSlices?.filter((aSlice) => aSlice !== slice),
        );
        if (intersectingSlice) {
            return intl.formatMessage(
                { id: 'auto.billing.referential.accountingCostCompute.modal.input.intersection' },
                { from: intersectingSlice.from, to: intersectingSlice.to },
            );
        }
        return undefined;
    };

    const handleEditSliceConstantCost = () => {
        if (isValid) {
            onSliceChange({
                mode: selectedSliceMode,
                from: getValues().from,
                to: getValues().to,
                value: getValues().value * CURRENCY_RATE,
            });
            setValue('value', 0);
            setValue('to', 0);
            setValue('from', 0);
            onHide();
        }
    };

    const addButton = (
        <Button onClick={handleEditSliceConstantCost} buttonType="primary" disabled={!isValid}>
            <FormattedMessage id="button.save" />
        </Button>
    );

    return (
        <Modal
            isOpen={true}
            onHide={onHide}
            title="auto.billing.referential.accountingCostCompute.modal.title"
            buttons={addButton}
        >
            <Container>
                <Line>
                    <LineElement paddingBottom={true}>
                        <Controller
                            control={control}
                            name="from"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                validate: (value) => {
                                    return getInputErrorMessage(value);
                                },
                            }}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <LineElementContent>
                                    <Text
                                        type="small"
                                        weight="medium"
                                        text="auto.billing.referential.accountingCostCompute.modal.sliceFrom"
                                    />
                                    <Input
                                        type="number"
                                        data-testid="sliceFrom"
                                        error={error?.message}
                                        onChange={onChange}
                                        onBlur={async () => {
                                            onBlur();
                                            await trigger('to');
                                        }}
                                        value={value?.toString()}
                                        isTouched={touchedFields.from}
                                    />
                                </LineElementContent>
                            )}
                        />
                        <StyledText text="auto.billing.referential.accountingCostCompute.modal.to" />
                        <Controller
                            control={control}
                            name="to"
                            rules={{
                                required: computeText(intl, 'field.required'),
                                validate: (value) => getInputErrorMessage(value),
                            }}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                    type="number"
                                    data-testid="sliceTo"
                                    error={error?.message}
                                    onChange={onChange}
                                    onBlur={async () => {
                                        onBlur();
                                        await trigger('from');
                                    }}
                                    value={value?.toString()}
                                    isTouched={touchedFields.to}
                                />
                            )}
                        />
                    </LineElement>
                    <LineElement>
                        <LineElementContent fullWidth>
                            <Text
                                type="small"
                                weight="medium"
                                text="auto.billing.referential.accountingCostCompute.modal.billingMode"
                            />
                            <DropDown
                                type="radio"
                                onChange={(sliceMode) => {
                                    setSelectedSliceMode(sliceMode);
                                }}
                                selected={selectedSliceMode}
                                items={Object.values(CostSliceMode).map((partitionMode) => ({
                                    value: partitionMode,
                                    label: computeText(
                                        intl,
                                        `auto.billing.referential.accountingCostCompute.costSlicesMode.${partitionMode}`,
                                    ),
                                }))}
                                button={
                                    <DropDownButton>
                                        <DropDownButtonContainer>
                                            <div>
                                                {computeText(
                                                    intl,
                                                    `auto.billing.referential.accountingCostCompute.costSlicesMode.${selectedSliceMode}`,
                                                )}
                                            </div>
                                            <SVGIcon icon={SVGIcons.CHEVRON} size={20} />
                                        </DropDownButtonContainer>
                                    </DropDownButton>
                                }
                            />
                        </LineElementContent>
                    </LineElement>
                </Line>
                <Line>
                    <LineElement>
                        <Controller
                            control={control}
                            name="value"
                            rules={{ required: computeText(intl, 'field.required') }}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <LineElementContent fullWidth={true}>
                                    <Text
                                        type="small"
                                        weight="medium"
                                        text={`auto.billing.referential.accountingCostCompute.modal.${selectedSliceMode}`}
                                    />
                                    <Input
                                        type="number"
                                        data-testid="sliceValue"
                                        error={error?.message}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value.toString()}
                                        isTouched={touchedFields.value}
                                        unitType="currency"
                                    />
                                </LineElementContent>
                            )}
                        />
                    </LineElement>
                </Line>
            </Container>
        </Modal>
    );
};

export default CostSliceEditorModal;
