import styled from '@emotion/styled';

export const FlexContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.spacing[4]};
    font-family: ${({ theme }): string => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: ${({ theme }) => theme.font.size.small};
`;

export const StyledInfoParagraph = styled.p`
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-left: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.color.grey500};

    & > span {
        color: ${({ theme }) => theme.color.electricBlue};
        :hover {
            cursor: pointer;
        }
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;
