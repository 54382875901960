import React, { ReactElement } from 'react';
import { IconProps } from '../SVGIcon';

const ArrowForward = ({ size, color }: IconProps): ReactElement => {
    return (
        <svg width={size / 2} height={size} viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.857296 0.71505C1.07671 0.71505 1.29612 0.799044 1.46325 0.966174L5.74866 5.25158C6.08378 5.5867 6.08378 6.12838 5.74866 6.4635L1.46325 10.7489C1.12813 11.084 0.586458 11.084 0.251339 10.7489C-0.0837793 10.4138 -0.0837793 9.87211 0.251339 9.53699L3.93079 5.85754L0.251339 2.17809C-0.0837793 1.84297 -0.0837793 1.30129 0.251339 0.966174C0.41847 0.799044 0.637883 0.71505 0.857296 0.71505Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowForward;
