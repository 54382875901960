import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSelector } from 'react-redux';
import { formatDateWithLocal } from '@/utils/date';
import { computeText } from '@/locales/utils';
import { Button } from '@/components/atoms/Button';
import InvoiceSelector from '@/pages/Brand/AutoBilling/InvoiceSelector';
import { Message } from '@/pages/Brand/AutoBilling/AutoBillingRequest/AutoBillingRequest.style';
import { saveStreamAsZipFile } from '@/pages/Brand/AutoBilling/AutoBillingRequest/utils';
import { Table } from '@/components/atoms/Table';
import { usePostPreviewBillingRequestMutation } from '@/services/innovorder/autoBilling/autoBilling.endpoints';
import { AutoBillingInvoice, AutoBillingRequestPayload } from '@/services/innovorder/autoBilling/autoBilling.type';
import { getUserToken } from '@/redux/user';
import { getBillingTableCols, getTableRows, renderHeaderCell, renderInvoiceTotalLine } from './utils';
import { ButtonsLine, Container, Header, InvoiceTotal, TableContainer } from './AutoBillingInvoices.style';

type AutoBillingInvoicesProps = {
    invoices: AutoBillingInvoice[];
    autoBillingRequest: AutoBillingRequestPayload;
};

const AutoBillingInvoices: React.FunctionComponent<React.PropsWithChildren<AutoBillingInvoicesProps>> = ({
    invoices,
    autoBillingRequest,
}) => {
    const [invoice, setInvoice] = useState<AutoBillingInvoice | undefined>(invoices[0]);

    const intl = useIntl();
    const token = useSelector(getUserToken);

    const handleSelectedInvoice = useCallback(
        (invoiceUuid: string): void => {
            if (invoices) {
                const selectedInvoice = invoices.find((anInvoice) => anInvoice.uuid === invoiceUuid);
                setInvoice(selectedInvoice);
            }
        },
        [invoices],
    );

    const [postPreviewBillingRequest, { isLoading: isDownloadingInvoices, data: downloadedInvoices, isSuccess }] =
        usePostPreviewBillingRequestMutation();

    useEffect(() => {
        if (downloadedInvoices && typeof downloadedInvoices === 'string' && isSuccess) {
            saveStreamAsZipFile(downloadedInvoices);
        }
    }, [downloadedInvoices, invoices, isSuccess]);

    const handleDownload = useCallback(() => {
        if (!isDownloadingInvoices) {
            postPreviewBillingRequest({
                ...autoBillingRequest,
                requestPdf: true,
                token,
            });
        }
    }, [autoBillingRequest, isDownloadingInvoices, postPreviewBillingRequest, token]);

    return (
        <Container>
            {invoice && invoices && (
                <>
                    <ButtonsLine>
                        <InvoiceSelector invoices={invoices} onSelected={handleSelectedInvoice} />
                        <Button buttonType="secondary" onClick={handleDownload} isLoading={isDownloadingInvoices}>
                            {computeText(intl, 'auto.billing.request.history.table.downloadButton')}
                        </Button>
                    </ButtonsLine>
                    <Header>
                        {renderHeaderCell(
                            'auto.billing.request.preview.cdpf',
                            invoice.brand.externalId,
                            'externalId',
                            intl,
                        )}
                        {renderHeaderCell(
                            'auto.billing.request.preview.clientCode',
                            invoice.client.code,
                            'clientCode',
                            intl,
                        )}
                        {renderHeaderCell(
                            'auto.billing.request.preview.clientLabel',
                            invoice.client.name,
                            'clientName',
                            intl,
                        )}
                        {renderHeaderCell(
                            'auto.billing.request.preview.billingPeriod',
                            <>
                                <div>{computeText(intl, 'auto.billing.request.preview.billingPeriod.from')}</div>
                                <div data-testid="billingPeriodFrom">
                                    {formatDateWithLocal(invoice.periodFrom, intl.locale)}{' '}
                                </div>
                                <div>{computeText(intl, 'auto.billing.request.preview.billingPeriod.to')}</div>
                                <div data-testid="billingPeriodTo">
                                    {formatDateWithLocal(invoice.periodTo, intl.locale)}
                                </div>
                            </>,
                            'billingPeriod',
                            intl,
                        )}
                    </Header>
                    <TableContainer>
                        <Table
                            emptyMessageId="auto.billing.request.preview.emptyMessage"
                            columns={getBillingTableCols(intl)}
                            rows={getTableRows(invoice.items, intl)}
                        />
                    </TableContainer>
                    <InvoiceTotal>
                        {renderInvoiceTotalLine(
                            'auto.billing.request.preview.total.amountWithTaxExcluded',
                            invoice.total.totalTaxExcluded,
                            'billingAmountWithTaxExcluded',
                            intl,
                        )}
                        {renderInvoiceTotalLine(
                            'auto.billing.request.preview.total.tax',
                            invoice.total.totalTax,
                            'billingTotalTax',
                            intl,
                            true,
                        )}
                        {renderInvoiceTotalLine(
                            'auto.billing.request.preview.total.amountWithTaxIncluded',
                            invoice.total.totalTaxIncluded,
                            'billingAmountWithTaxIncluded',
                            intl,
                        )}
                    </InvoiceTotal>
                </>
            )}
            {!invoice && <Message text="auto.billing.request.preview.emptyMessage" />}
        </Container>
    );
};

export default AutoBillingInvoices;
