import React from 'react';
import { DeleteCrossSellingModal } from './DeleteCrossSellingModal/DeleteCrossSellingModal';
import { DuplicateCrossSellingModal } from './DuplicateCrossSellingModal/DuplicateCrossSellingModal';
import { CrossSellingActionTypes } from '../types';

type CrossSellingModalProps = {
    action: CrossSellingActionTypes | null;
    onHide: () => void;
    brandId: number;
    restaurantId?: number;
    crossSellingId: number | null;
    crossSellingName: string | null;
};

const getActionModal = (action: CrossSellingActionTypes | null) => {
    switch (action) {
        case 'showDuplicateModal':
            return DuplicateCrossSellingModal;
        case 'showDeleteModal':
            return DeleteCrossSellingModal;
        default:
            return null;
    }
};

export const CrossSellingModal: React.FunctionComponent<React.PropsWithChildren<CrossSellingModalProps>> = ({
    action,
    onHide,
    brandId,
    restaurantId,
    crossSellingName,
    crossSellingId,
}) => {
    const ActionModal = getActionModal(action);

    if (!ActionModal) {
        return ActionModal;
    }

    return (
        <ActionModal
            onHide={onHide}
            brandId={brandId}
            restaurantId={restaurantId}
            crossSellingName={crossSellingName}
            crossSellingId={crossSellingId}
        />
    );
};
