import { ApiTagTypes, innovorderApi } from '../index';
import { ApiResponse } from '../request.types';
import {
    CreateRoomPayload,
    CreateRoomResponse,
    GetRoomPayload,
    GetRoomResponse,
    UpdateRoomPayload,
    UpdateRoomResponse,
    DeleteRoomPayload,
    DeleteRoomResponse,
} from './room.type';

const roomApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        createRoom: build.mutation<CreateRoomResponse, CreateRoomPayload>({
            query: ({ ...body }) => {
                return {
                    url: `/rooms`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [ApiTagTypes.Room],
            transformResponse: (response: ApiResponse<CreateRoomResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getRoom: build.query<GetRoomResponse, GetRoomPayload>({
            query: ({ roomId }) => ({
                url: `/rooms/${roomId}`,
                method: 'GET',
            }),
            providesTags: [ApiTagTypes.Room],
            transformResponse: (response: ApiResponse<GetRoomResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        updateRoom: build.mutation<UpdateRoomResponse, UpdateRoomPayload>({
            query: ({ roomId, ...body }) => {
                return {
                    url: `/rooms/${roomId}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: [ApiTagTypes.Room],
            transformResponse: (response: ApiResponse<UpdateRoomResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        deleteRoom: build.mutation<DeleteRoomResponse, DeleteRoomPayload>({
            query: ({ roomId }) => {
                return {
                    url: `/rooms/${roomId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [ApiTagTypes.Room],
        }),
    }),
});

export const { useCreateRoomMutation, useGetRoomQuery, useUpdateRoomMutation, useDeleteRoomMutation } = roomApi;
