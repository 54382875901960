import React from 'react';
import { FormattedMessage } from 'react-intl';

import MarketplaceLogo from '@/pages/Config/common/MarketplaceLogo/MarketplaceLogo';
import {
    StickyContainer,
    TitleContainer,
    PanelTitle,
    PanelSubTitle,
    PanelText,
    ContactButton,
} from './DeliverectPanel.style';

const CONTACT_URL = 'https://share.hsforms.com/1gLM3xDgiRR64afcYf0XI0g3e34n';

const DeliverectPanel: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <div>
            <StickyContainer>
                <TitleContainer>
                    <MarketplaceLogo name="deliverect" />
                    <PanelTitle text="deliverect.title" />
                </TitleContainer>

                <PanelSubTitle text="deliverect.subtitle" weight="bold" />
                <PanelText text="deliverect.description.firstpart" />

                <PanelText text="deliverect.description.secondpart" />

                <PanelText text="deliverect.description.thirdpart" />

                <a href={CONTACT_URL} target="_BLANK" rel="noopener noreferrer">
                    <ContactButton buttonType="outlinedPrimary">
                        <FormattedMessage id="deliverect.contact" />
                    </ContactButton>
                </a>
            </StickyContainer>
        </div>
    );
};

export default DeliverectPanel;
