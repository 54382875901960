import { ReportFilterValues } from '@/pages/Reporting/common/types';
import { useGetReportDataQuery } from '@/services/innovorder/report/report.endpoints';
import { useMemo } from 'react';
import { formatTableData } from '@/pages/Reporting/Report/ReportTable/ReportTable.utils';
import { Column } from 'react-table';
import { TableRow } from '@/components/atoms/Table/Table.type';

type GetReportTableDataProps = {
    dataUrl: string;
    params: Record<string, string | ReportFilterValues>;
};

type ReportTableData = {
    isTableLoading: boolean;
    columns: Column<TableRow>[];
    rows: TableRow[];
};

export const useGetReportTableData = ({ dataUrl, params }: GetReportTableDataProps): ReportTableData => {
    const { data, isLoading, isFetching } = useGetReportDataQuery({ dataUrl, params });

    const isTableLoading = useMemo(() => isLoading || isFetching, [isFetching, isLoading]);
    const { columns, rows } = useMemo(() => formatTableData(data), [data]);

    return {
        isTableLoading,
        columns,
        rows,
    };
};
