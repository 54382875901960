import React from 'react';
import { useTheme } from '@emotion/react';

import { computeBreakpoint } from '@/utils/style';
import { useViewport } from '@/hooks/useViewport';
import { Button } from '@/components/atoms/Button';
import Select, { Option } from '../../atoms/Select/Select';
import { TabProps } from './Tab';
import { StyledMobileTabList, StyledTab, StyledTabList } from './TabList.style';

type TabListProps = {
    list: TabProps[];
    selected: number;
    onSelect: (index: number) => void;
};

const TabList: React.FunctionComponent<React.PropsWithChildren<TabListProps>> = ({ list, selected, onSelect }) => {
    const { width } = useViewport();
    const theme = useTheme();
    const selectOptions: Option<number>[] = list.map(({ title }, index) => ({ value: index, label: title }));

    return width > computeBreakpoint(theme.breakpoints.mobile) ? (
        <StyledTabList>
            {list.map(({ title, component }, index) => {
                return (
                    <StyledTab key={title} onClick={() => onSelect(index)}>
                        {component && (
                            <Button
                                buttonType={selected === index ? 'primary' : 'secondary'}
                                hasBorder={selected === index}
                            >
                                {title}
                            </Button>
                        )}
                    </StyledTab>
                );
            })}
        </StyledTabList>
    ) : (
        <StyledMobileTabList>
            <Select<number> onChange={(index) => onSelect(index)} value={selected} options={selectOptions} />
        </StyledMobileTabList>
    );
};

export default TabList;
