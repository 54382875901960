import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from '@/components/atoms/Modal';
import { Button } from '@/components/atoms/Button';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { useTheme } from '@emotion/react';
import Loader from '@/components/atoms/Loader';
import { Description } from '@/components/atoms/DeleteModal/DeleteModal.style';
import { DeleteGroupErrorReason } from './DeleteGroupErrorModal.style';

interface CannotDeleteGroupModalProps {
    isOpen: boolean;
    onHide: () => void;
    isLoading: boolean;
}

export const DeleteGroupErrorModal: React.FunctionComponent<React.PropsWithChildren<CannotDeleteGroupModalProps>> = ({
    isOpen,
    onHide,
    isLoading,
}) => {
    const theme = useTheme();
    const okButton = (
        <Button onClick={onHide} buttonType="primary">
            <FormattedMessage id="button.ok" />
        </Button>
    );

    return (
        <Modal
            isOpen={isOpen}
            onHide={onHide}
            title="group.modal.warning"
            icon={SVGIcons.INFO}
            iconColor={theme.color.bloodyMary}
            buttons={okButton}
        >
            {isLoading ? (
                <Loader withContainer />
            ) : (
                <>
                    <Description>
                        <FormattedMessage id="group.modal.deleteError" />
                    </Description>
                    <DeleteGroupErrorReason>
                        <FormattedMessage id="group.modal.deleteErrorReason" />
                    </DeleteGroupErrorReason>
                </>
            )}
        </Modal>
    );
};
