import React, { useCallback, useEffect, useState } from 'react';

import { Input } from '@/components/atoms/Form/Input';
import { Text } from '@/components/atoms/Text';
import { SavingBar } from '@/components/atoms/SavingBar';

import {
    Container,
    PermissionsContainer,
    CustomTitle,
    FlexContainer,
    FormChildrens,
    FormContainer,
    TitleContainer,
} from './PosRolesForm.style';
import { useRoleEditVM } from './PosRolesForm.viewmodel';
import { Switch } from '@/components/atoms/Switch';
import { generatePath, useHistory, useParams } from 'react-router';
import { Routes } from '@/core/router/routes.types';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
    roleId: string;
};
const PosRoleForm: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { brandId, restaurantId } = useParams<ParamTypes>();
    const history = useHistory();

    const { permissions, posRole, isLoading, isSuccess, error: callError, handleSubmit } = useRoleEditVM();
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

    useEffect(() => {
        setSelectedPermissions((posRole?.permissions || []).map((p) => p.key));
    }, [posRole?.permissions]);

    const handleSuccess = useCallback(() => {
        if (isSuccess) history.push(generatePath(Routes.PosAccessRoles, { brandId, restaurantId }));
    }, [brandId, history, isSuccess, restaurantId]);

    useEffect(() => {
        handleSuccess();
    }, [handleSuccess, isSuccess]);

    const groupedPermissions: Record<'access' | 'other', string[]> = permissions?.reduce(
        (acc: Record<string, string[]>, permission: string) => {
            if (permission.startsWith('ACCESS_')) {
                acc['access'] = [...(acc['access'] || []), permission];
            } else {
                acc['other'] = [...(acc['other'] || []), permission];
            }
            return acc;
        },
        {},
    );

    const handlePermissionToggle = useCallback((permission: string, value: boolean) => {
        setSelectedPermissions((prev) => (value ? [...prev, permission] : prev.filter((p) => p !== permission)));
    }, []);

    return (
        <>
            <Container>
                <FlexContainer>
                    <TitleContainer>
                        <CustomTitle text="posRole.form.title" />
                        {posRole && <CustomTitle text={`: ${posRole.key}`} />}
                    </TitleContainer>
                    <Text text="posRole.form.description" />
                    <FormContainer>
                        <FormChildrens>
                            <Input
                                value={posRole?.posRoleId}
                                disabled
                                labelId="posRole.form.name.title"
                                placeholder="posRole.form.name.placeholder"
                            />
                            <Text text="posPermissions.accessPermissions" />
                            <PermissionsContainer>
                                {groupedPermissions.access?.map((permission) => {
                                    return (
                                        <Switch
                                            value={selectedPermissions.includes(permission)}
                                            onChange={(value) => {
                                                handlePermissionToggle(permission, value);
                                            }}
                                            labelId={`posPermissions.${permission}`}
                                        />
                                    );
                                })}
                            </PermissionsContainer>
                            <Text text="posPermissions.otherPermissions" />
                            <PermissionsContainer>
                                {groupedPermissions.other?.map((permission, index) => {
                                    return (
                                        <Switch
                                            value={posRole?.permissions
                                                .map((permission) => permission.key)
                                                .includes(permission)}
                                            onChange={(value) => {
                                                handlePermissionToggle(permission, value);
                                            }}
                                            labelId={`posPermissions.${permission}`}
                                        />
                                    );
                                })}
                            </PermissionsContainer>
                        </FormChildrens>
                    </FormContainer>
                </FlexContainer>
            </Container>
            <SavingBar onSave={() => handleSubmit(selectedPermissions)} error={callError} loading={isLoading} />
        </>
    );
};

export default PosRoleForm;
