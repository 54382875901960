import styled from '@emotion/styled';

export const TableContainer = styled.div`
    margin-top: 12px;

    div:nth-of-type(2) {
        max-height: 40vh;
        overflow-y: auto;
    }
`;
