import { Routes } from '@/core/router/routes.types';
import { computeText } from '@/locales/utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatDate } from '@/utils/date';
import { ENV } from '@/utils/env';
import { injectDataInUri } from '@/utils/routes';
import { TransactionSources } from '@/services/innovorder/io-pay/transactions/transaction.type';
import { Container, FieldContainer, StyledLabel, StyledLinkValue, StyledValue } from '../TransactionDetail.style';

type TransactionDetailMainDataProps = {
    lastUpdate: string;
    source?: number;
    error?: string;
    restaurantName?: string;
    restaurantId?: number;
    brandId: number;
};

const MainData: React.FunctionComponent<React.PropsWithChildren<TransactionDetailMainDataProps>> = ({
    lastUpdate,
    source,
    error,
    restaurantId,
    restaurantName,
    brandId,
}) => {
    const intl = useIntl();

    return (
        <Container>
            {lastUpdate && (
                <FieldContainer>
                    <StyledLabel text="transaction.field.lastUpdate" />
                    <StyledValue>{formatDate(lastUpdate)}</StyledValue>
                </FieldContainer>
            )}
            {source && (
                <FieldContainer>
                    <StyledLabel text="transaction.field.source" />
                    <StyledValue>{computeText(intl, `transaction.source.${TransactionSources[source]}`)}</StyledValue>
                </FieldContainer>
            )}
            {error && (
                <FieldContainer>
                    <StyledLabel text="transaction.field.error" />
                    <StyledValue>{error}</StyledValue>
                </FieldContainer>
            )}
            {restaurantId && restaurantName && (
                <FieldContainer>
                    <StyledLabel text="transaction.field.restaurant" />
                    <a
                        href={`${injectDataInUri({
                            uri: Routes.RestaurantInfos,
                            backofficeUrl: ENV.BACKOFFICE_URL,
                            restaurantId: restaurantId.toString(),
                            brandId: brandId.toString(),
                        })}`}
                    >
                        <StyledLinkValue>{restaurantName}</StyledLinkValue>
                    </a>
                </FieldContainer>
            )}
        </Container>
    );
};

export default MainData;
