import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse, ApiResponseBase } from '../request.types';
import {
    BadgeReader,
    CreateBadgeReadersPayload,
    DeleteBadgeReadersPayload,
    GetBadgeReadersPayload,
    UpdateBadgeReadersPayload,
} from './badgeReader.type';

const badgeReaderApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getBadgeReader: build.query<BadgeReader[], ApiRequest<GetBadgeReadersPayload>>({
            query: ({ brandId, token }) => ({
                url: `/badge_readers?brandId=${brandId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: (_, __, queryArgs) => {
                return [{ type: ApiTagTypes.BadgeReader, id: queryArgs.brandId }];
            },
            transformResponse: (response: ApiResponse<BadgeReader[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        createBadgeReader: build.mutation<BadgeReader, ApiRequest<CreateBadgeReadersPayload>>({
            query: ({ token, ...body }) => ({
                url: `/badge_readers`,
                method: 'POST',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [{ type: ApiTagTypes.BadgeReader, id: queryArgs.brandId }];
            },
            transformResponse: (response: ApiResponse<BadgeReader>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        updateBadgeReader: build.mutation<BadgeReader, ApiRequest<UpdateBadgeReadersPayload>>({
            query: ({ token, badgeReaderId, ...body }) => ({
                url: `/badge_readers/${badgeReaderId}`,
                method: 'PUT',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (result) => {
                return [{ type: ApiTagTypes.BadgeReader, id: result?.brandId }];
            },
            transformResponse: (response: ApiResponse<BadgeReader>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteBadgeReader: build.mutation<ApiResponseBase, ApiRequest<DeleteBadgeReadersPayload>>({
            query: ({ badgeReaderId, token }) => ({
                url: `/badge_readers/${badgeReaderId}`,
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [{ type: ApiTagTypes.BadgeReader, id: queryArgs.brandId }];
            },
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetBadgeReaderQuery,
    useCreateBadgeReaderMutation,
    useUpdateBadgeReaderMutation,
    useDeleteBadgeReaderMutation,
} = badgeReaderApi;
