import { OrderStatus, WebPaymentStatus } from '@/services/innovorder/orders/order.type';

type PaymentStatus = 'PAID' | 'CANCELLED' | 'REFUNDED';
type PaymentStatusOrEmpty = PaymentStatus | '';
export function getPaymentStatus(status: OrderStatus, webPaymentStatus?: WebPaymentStatus): PaymentStatusOrEmpty {
    if (webPaymentStatus === 'PAYMENT_REFUNDED') return 'REFUNDED';
    return status === 'PAID' || status === 'CANCELLED' ? status : '';
}

export function shouldDisplayPaymentKpi(paymentStatus: PaymentStatusOrEmpty): paymentStatus is PaymentStatus {
    return ['PAID', 'CANCELLED', 'REFUNDED'].includes(paymentStatus);
}
