import React from 'react';
import { SvySwitch } from '@/components/savory/SvySwitch';
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from '@/components/atoms/Label';
import styled from '@emotion/styled';
import { ToggleConfig } from '@/services/innovorder/report/report.type';

const ToggleInputWrapper = styled.div`
    margin: 10px;
`;

const ToggleInput: React.FunctionComponent<{ toggleConfig: ToggleConfig }> = ({ toggleConfig: { key, name } }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={key}
            render={({ field: { onChange, onBlur, value } }) => (
                <ToggleInputWrapper>
                    <Label labelId={`report.filtersModal.form.${name}Label`} />
                    <SvySwitch value={value} onChange={onChange} onBlur={onBlur} />
                </ToggleInputWrapper>
            )}
        ></Controller>
    );
};

export default ToggleInput;
