import React from 'react';
import { useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from '@/core/theme';
import { computeText } from '@/locales/utils';
import RadioButton from '@/components/atoms/RadioButton';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import {
    ModalButton,
    ModalButtonLabel,
    ModalInputContainer,
    ModalInputTitle,
    ModalPosButton,
    ModalTextInput,
} from '../CreateMenuModal.style';
import { EditChannelFormValues } from '../CreateMenuModal.type';
import { MenuModalChannelsTitle } from '../../MenuModal.style';

type EditChannelStepProps = {
    onNext?: () => void;
    restaurantId?: number;
    isLoading?: boolean;
};

export const EditChannelStep: React.FunctionComponent<React.PropsWithChildren<EditChannelStepProps>> = ({
    onNext,
    restaurantId,
    isLoading = false,
}) => {
    const intl = useIntl();
    const { control } = useFormContext<EditChannelFormValues>();

    return (
        <>
            <Controller
                control={control}
                name="name"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => !!value?.length,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputContainer>
                        <ModalInputTitle type="small" weight="medium" text="menu.modal.menu.name" />
                        <ModalTextInput
                            type="string"
                            data-testid="menu-modal-menu-name-input"
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                        />
                    </ModalInputContainer>
                )}
            />
            {restaurantId && (
                <Controller
                    control={control}
                    name="channels"
                    render={({ field: { onChange, value } }) => {
                        function onChannelChange(target: ChannelId.WEB | ChannelId.KIOSK) {
                            const updatedValues = value.filter(({ channelId }) => channelId !== target);

                            if (updatedValues.length === value.length) {
                                updatedValues.push({ channelId: target });
                            }

                            onChange(updatedValues);
                        }

                        return (
                            <div>
                                <MenuModalChannelsTitle
                                    type="small"
                                    weight="medium"
                                    text="menu.modal.channels.selectActive"
                                />
                                <ModalButton disabled={isLoading}>
                                    <RadioButton
                                        data-testid="menu-modal-channels-web-radio"
                                        label="legend.WEB"
                                        onChange={onChannelChange}
                                        checked={value.some(({ channelId }) => channelId === ChannelId.WEB)}
                                        value={ChannelId.WEB}
                                        disabled={isLoading}
                                    />
                                </ModalButton>
                                <ModalButton disabled={isLoading}>
                                    <RadioButton
                                        data-testid="menu-modal-channels-kiosk-radio"
                                        label="legend.KIOSK"
                                        onChange={onChannelChange}
                                        checked={value.some(({ channelId }) => channelId === ChannelId.KIOSK)}
                                        value={ChannelId.KIOSK}
                                        disabled={isLoading}
                                    />
                                </ModalButton>
                                <ModalPosButton
                                    data-testid="menu-modal-channels-pos-radio"
                                    onClick={onNext}
                                    disabled={isLoading}
                                >
                                    <SVGIcon size={18} icon={SVGIcons.POS} color={theme.color.black200} />
                                    <ModalButtonLabel
                                        type="normal"
                                        text="menu.modal.channels.associatePos"
                                    ></ModalButtonLabel>
                                    <SVGIcon icon={SVGIcons.ARROW_FORWARD} />
                                </ModalPosButton>
                            </div>
                        );
                    }}
                />
            )}
        </>
    );
};
