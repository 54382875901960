import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '@/core/router/routes.types';

const Direction: React.FunctionComponent<
    React.PropsWithChildren<{
        labelId: string;
        route?: Routes;
        uri?: string;
        onClick?: () => void;
    }>
> = ({ route, uri, children, labelId, onClick }) => {
    if (!route && !uri && !onClick) {
        throw new Error(`${labelId} link need a route, uri or overlayComponent`);
    }

    if (onClick) {
        return <div onClick={onClick}>{children}</div>;
    }

    if (route !== undefined) {
        return (
            <Link to={route} data-testid="router-link">
                {children}
            </Link>
        );
    }

    return (
        <a href={uri} data-testid="external-link">
            {children}
        </a>
    );
};

export default Direction;
