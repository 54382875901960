import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { SvyButton } from '@/components/savory/SvyButton';
import { SvyButtonProps } from '@innovorder/savory';

export const SubmitButton: React.FunctionComponent<SubmitButtonProps> = (props) => {
    const form = useFormContext();
    const { isSubmitting, isValid, isValidating } = form.formState;
    const isDisabled = !isValid || isValidating || isSubmitting;

    return <SvyButton {...props} type="submit" isLoading={isSubmitting} disabled={isDisabled} />;
};

type SubmitButtonProps = Omit<SvyButtonProps, 'isLoading' | 'disabled'>;
