import { Button } from '@/components/atoms/Button';
import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    h2 {
        margin-bottom: 10px;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const FavoriteIcon = styled.i`
    scale: 1.8;
    display: inline-block;
`;

export const FavoritedIconWrapper = styled.span`
    padding: ${({ theme }) => theme.savory.spacing[2]};
    i:before {
        color: ${({ theme }) => theme.color.primary};
    }
    &:hover {
        i:before {
            color: ${({ theme }) => theme.color.red300};
        }
    }
`;
export const NotFavoritedIconWrapper = styled.span`
    padding: ${({ theme }) => theme.savory.spacing[2]};
    i:before {
        color: ${({ theme }) => theme.savory.colors.primary};
    }
    &:hover {
        i:before {
            color: ${({ theme }) => theme.color.primary};
        }
    }
`;
