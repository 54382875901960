import { SvyButton } from '@/components/savory/SvyButton';
import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white100};
    padding: 0;
    box-sizing: border-box;
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2;
    box-sizing: border-box;
    margin-top: ${({ theme }) => theme.spacing[7]};
    & > p {
        text-align: center;
    }
`;

export const DropzoneContainer = styled.div`
    &,
    > * {
        width: 100%;
    }
`;

export const DescriptionList = styled.ol`
    font-family: ${({ theme }) => theme.font.family};
    padding: 24px;
    text-align: center;
    list-style-position: inside;
`;

export const DescriptionItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.black400};
`;

export const ActionButtonContainer = styled.div`
    margin-top: ${({ theme }) => theme.spacing[4]};
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: start;
`;

export const ActionButton = styled(SvyButton)`
    margin-left: ${({ theme }) => theme.spacing[2]};
`;
