import React from 'react';

import { Animated, LoaderContainer } from './Loader.style';
import { ReactComponent as Spinner } from './spinner.svg';

type Props = {
    color?: string;
    size?: number;
    withContainer?: boolean;
};

const Loader: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    color,
    size = 30,
    withContainer,
    ...otherProps
}) => {
    const animatedLoader = (
        <Animated color={color} size={size}>
            <Spinner height={size} width={size} data-testid="loader" />
        </Animated>
    );

    if (!withContainer) return animatedLoader;

    return <LoaderContainer {...otherProps}>{animatedLoader}</LoaderContainer>;
};

export default Loader;
