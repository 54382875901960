import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Title } from '@/components/atoms/Title';
import { getTransactionsTableColumnsVM, getTransactionsTableRowsVM } from '@/pages/IO-Pay/Transactions/utils';
import { Table, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, fetchDataProps } from '@/components/atoms/Table';
import { useGetTransactionsMutation } from '@/services/innovorder/io-pay/transactions/transaction.endpoints';
import { getUserToken } from '@/redux/user';
import { getBrandType } from '@/redux/brand';
import { Routes } from '@/core/router/routes.types';
import { FlexGrow, Header, TitleContainer } from '../Transfers.style';
import { Container } from './TransferDetails.style';
import { convertTableFiltersToQueryFilters } from '../utils';

type TransferDetailTransactionsProps = {
    transferId: number;
};

const TransferDetailsTransactions: React.FunctionComponent<
    React.PropsWithChildren<TransferDetailTransactionsProps>
> = ({ transferId }) => {
    const intl = useIntl();
    const theme = useTheme();
    const token = useSelector(getUserToken);
    const brandType = useSelector(getBrandType);

    const [fetchTransactions, { data, isLoading }] = useGetTransactionsMutation();
    const fetchData = useCallback(
        ({ pageIndex, pageSize, globalFilter, columnFilters }: fetchDataProps) => {
            if (token) {
                const filters = {
                    ...convertTableFiltersToQueryFilters(columnFilters),
                    outgoingTransferId: transferId.toString(),
                };
                fetchTransactions({
                    token,
                    search: globalFilter,
                    filters,
                    limit: pageSize ?? DEFAULT_PAGE_SIZE,
                    offset:
                        pageIndex === undefined || pageSize === undefined ? DEFAULT_PAGE_INDEX : pageIndex * pageSize,
                });
            }
        },
        [token, fetchTransactions, transferId],
    );

    const columns = useMemo(() => getTransactionsTableColumnsVM(intl, brandType), [intl, brandType]);
    const rows = useMemo(() => getTransactionsTableRowsVM(data?.transactions, intl, theme), [data, intl, theme]);
    const defaultSortBy = [{ id: 'transactionId', desc: true }];

    return (
        <>
            <Header>
                <TitleContainer>
                    <Title text="transfer.transactions.title" />
                </TitleContainer>
                <FlexGrow />
            </Header>
            <Container>
                <Table
                    rows={rows}
                    emptyMessageId="table.noRowsAfterFilter"
                    columns={columns}
                    hasPagination
                    hasUrlNavigation
                    isFilterable
                    isSortable
                    defaultSortBy={defaultSortBy}
                    fetchData={fetchData}
                    fetchDataTotalCount={data?.total}
                    isLoading={isLoading}
                    columnSelectorId="transactionList"
                    onRowClickPath={Routes.IOPayTransactionDetail}
                />
            </Container>
        </>
    );
};
export default TransferDetailsTransactions;
