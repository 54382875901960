import styled from '@emotion/styled';

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    padding: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

export const Flex = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
    justify-content: space-between;
`;

export const SectionTitle = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const StyledSelectionContainer = styled.div`
    margin-top: ${({ theme }) => theme.spacing[3]};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
`;
