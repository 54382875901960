import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.spacing[4]};

    > div {
        margin-bottom: 20px;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};

    h2 {
        margin-bottom: 10px;
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const Subtitle = styled.div`
    max-width: 500px;
    color: ${({ theme }) => theme.color.black200};
    font-size: ${({ theme }) => theme.font.size.small};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const HeaderLeft = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderRight = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[1]};
    align-items: flex-start;
    flex-direction: column;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[1]};
    flex-direction: column;
`;
