import { Badge } from '@/components/atoms/Badge';
import { Title } from '@/components/atoms/Title';
import styled from '@emotion/styled';
import { SVGIcon } from '@/components/atoms/Icon/SVGIcon';

export const Container = styled.div<{ canBeClicked?: boolean }>`
    display: flex;
    flex-direction: column;

    background-color: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    gap: 20px;

    ${({ canBeClicked, theme }) =>
        canBeClicked &&
        `&:hover {
        cursor: pointer;
        background-color: ${theme.color.grey300};
    }`}
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const IconContainer = styled.div`
    width: ${({ theme }) => theme.spacing[8]};
    height: ${({ theme }) => theme.spacing[8]};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[1]};

    display: flex;

    & > img {
        flex: 0 1 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
`;

export const ExpandIcon = styled(SVGIcon)<{ isOpen: boolean }>`
    width: '32px';
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.2s ease-out;
`;

export const StaticContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    & > *:not(:last-child) {
        flex: 1;
    }
`;

export const StyledTitle = styled(Title)`
    margin: 0;
`;

export const StyledBadge = styled(Badge)`
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: ${({ theme }) => theme.font.size.small};
`;
