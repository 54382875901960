import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import type { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { getUser } from '@/redux/user';
import { Room } from '@/services/innovorder/room/room.type';
import { useGetTableLayoutQuery } from '@/services/innovorder/tableLayout/tableLayout.endpoint';
import { Cell } from 'react-table';
import { RoomAction, RoomActionTypes } from './types';

const defaultRoomAction: RoomAction = {
    roomId: null,
    name: null,
    type: null,
    tableLayoutId: null,
};

export function useRoomTableVM() {
    const intl = useIntl();
    const { params } = useRouteMatch<{ brandId: string; restaurantId: string; tableLayoutId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);
    const restaurantId = Number.parseInt(params.restaurantId, 10);
    const { tableLayoutId } = params;
    const user = useSelector(getUser);
    const canEdit = user?.accessFlags.CAN_EDIT_TABLE_LAYOUT;

    const { data: tableLayout, isLoading: isRoomLoading } = useGetTableLayoutQuery({
        tableLayoutId,
    });
    const [action, setAction] = useState<RoomAction>(defaultRoomAction);

    const handleAction = React.useCallback(
        (actionType: RoomActionTypes, room: Pick<Room, 'roomId' | 'name' | 'tableLayoutId'>) => {
            setAction({
                roomId: room.roomId,
                name: room.name,
                tableLayoutId: room.tableLayoutId,
                type: actionType || null,
            });
        },
        [],
    );

    const handleCreate = () => {
        setAction({ ...defaultRoomAction, tableLayoutId, type: 'showCreateModal' });
    };

    const handlePublish = () => {
        setAction({ ...defaultRoomAction, tableLayoutId, type: 'showPublishModal' });
    };

    const handleRowClick = React.useCallback(
        (cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
            if (!cell) {
                return;
            }

            const { roomId: clickedRoomId, text: clickedRoomName } = cell.row.allCells[0].value.value as {
                roomId: string;
                text: string;
            };

            if (clickedRoomId && cell.column.id !== 'action') {
                handleAction('showEditModal', {
                    roomId: clickedRoomId,
                    name: clickedRoomName,
                    tableLayoutId,
                });
            }
        },
        [handleAction, tableLayoutId],
    );

    const headers = React.useMemo(
        () => [
            {
                Header: computeText(intl, 'name.name'),
                accessor: 'name',
            },
            ...(canEdit
                ? [
                      {
                          Header: computeText(intl, 'action.action'),
                          accessor: 'action',
                          width: '10%',
                          disableSortBy: true,
                      },
                  ]
                : []),
        ],
        [canEdit, intl],
    );

    const rows: TableRow[] = React.useMemo(
        () =>
            [...(tableLayout?.rooms ?? [])].map((room) => {
                const onClickHandler = (actions: RoomActionTypes) => () => handleAction(actions, room);

                return {
                    name: {
                        type: 'string',
                        value: {
                            text: room.name,
                            roomId: room.roomId,
                        },
                    },
                    action: {
                        type: 'dropdownText',
                        value: {
                            button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                            align: 'end',
                            withPortal: true,
                            items: [
                                {
                                    value: 1,
                                    label: computeText(intl, 'action.edit'),
                                    onClick: onClickHandler('showEditModal'),
                                },
                                {
                                    value: 2,
                                    label: computeText(intl, 'action.delete'),
                                    onClick: onClickHandler('showDeleteModal'),
                                },
                            ],
                            type: 'text',
                        },
                    },
                    linkTo: {
                        type: 'linkTo',
                        value: {
                            brandId: String(brandId),
                            restaurantId: String(restaurantId),
                            roomId: String(room.roomId),
                        },
                    },
                };
            }),
        [intl, brandId, restaurantId, handleAction, tableLayout],
    );

    return {
        headers,
        rows,
        action,
        brandId,
        restaurantId,
        clearAction: () => setAction(defaultRoomAction),
        canEdit,
        isRoomLoading,
        tableLayoutName: tableLayout?.name || null,
        handleCreate,
        handlePublish,
        handleRowClick,
    };
}
