import React, { useMemo, useState } from 'react';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { MargedTitle } from '@/pages/Brand/Grant/GrantForm/GrantForm.style';
import { Text } from '@/components/atoms/Text';
import { SvyButton } from '@/components/savory/SvyButton';
import { FormattedMessage, useIntl } from 'react-intl';
import Calendar from '@/components/atoms/Calendar';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getAssertedUserBrandId, getUserRestaurants, getUserToken } from '@/redux/user';
import MultiSelect, { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { computeText } from '@/locales/utils';
import { MultiValue } from 'react-select';
import { useHandleReportExport } from '@/pages/Reporting/common/hooks';
import ExportResultModal from '@/components/organisms/ExportResultModal';
import {
    getExportParams,
    mapGroupsToMultiOption,
    mapPricingRulesToMultiOption,
    mapRestaurantToMultiOption,
    Period,
    shouldUseGroupsAndPricingRulesFilters,
    getExportUrl,
} from '@/pages/Reporting/SalesSummary/SalesSummary.utils';
import { useGetBrandGroupsQuery } from '@/services/innovorder/brand/brand.endpoints';
import { useGetMinimalPricingRulesQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { ReportType, useReportTypeSelectProps } from './hooks';
import { Container, FormContainer, SpacedFlex, ButtonWrapper, Flex, SelectLabel } from './SalesSummary.style';

const SalesSummary: React.FunctionComponent = () => {
    const intl = useIntl();
    const restaurants = useSelector(getUserRestaurants);
    const token = useSelector(getUserToken);
    const brandId = useSelector(getAssertedUserBrandId);
    const { data: groups } = useGetBrandGroupsQuery({ token, brandId }, { refetchOnMountOrArgChange: true });
    const { data: pricingRules } = useGetMinimalPricingRulesQuery(
        { token, brandId },
        { refetchOnMountOrArgChange: true },
    );
    const [selectedPeriod, setSelectedPeriod] = useState<Period>({ startDate: null, endDate: null });
    const { selectedReportType, reportTypeOptions, handleSelectReportTypeChange } = useReportTypeSelectProps();
    const [selectedRestaurants, setSelectedRestaurants] = useState<MultiValue<SelectOptionInterface<number>>>([]);
    const [selectedGroups, setSelectedGroups] = useState<MultiValue<SelectOptionInterface<string>>>([]);
    const [selectedPricingRules, setSelectedPricingRules] = useState<MultiValue<SelectOptionInterface<string>>>([]);

    const maxDate = useMemo(() => new Date(), []);
    const beginOfPreviousMonth = useMemo(() => dayjs(new Date()).subtract(1, 'month').startOf('month').toDate(), []);
    const handleDatesChange = (dates: Period) => {
        setSelectedPeriod(dates);
    };

    const restaurantsOptions = useMemo(() => mapRestaurantToMultiOption(restaurants), [restaurants]);
    const groupsOptions = useMemo(() => mapGroupsToMultiOption(groups), [groups]);
    const pricingRulesOptions = useMemo(() => mapPricingRulesToMultiOption(pricingRules), [pricingRules]);
    const handleSelectRestaurantsChange = (options?: MultiValue<SelectOptionInterface<number>>) => {
        setSelectedRestaurants(options || []);
    };

    const handleSelectGroupsChange = (options?: MultiValue<SelectOptionInterface<string>>) => {
        setSelectedGroups(options || []);
    };

    const handleSelectPricingRulesChange = (options?: MultiValue<SelectOptionInterface<string>>) => {
        setSelectedPricingRules(options || []);
    };

    const isValidPeriod = useMemo(() => selectedPeriod.startDate && selectedPeriod.endDate, [selectedPeriod]);
    const params = useMemo(
        () =>
            getExportParams(
                brandId,
                selectedPeriod,
                selectedRestaurants,
                selectedGroups,
                selectedPricingRules,
                selectedReportType.value,
            ),
        [brandId, selectedPeriod, selectedRestaurants, selectedGroups, selectedPricingRules, selectedReportType],
    );
    const { isExportResultOpen, closeExportResult, exportReport, isProcessingExport, isExportRequestCreated } =
        useHandleReportExport({ exportUrl: getExportUrl(selectedReportType.value), params });

    return (
        <RouterNavigationTabs routes={[]}>
            <Container>
                <Flex>
                    <MargedTitle text="salesSummary.tableCard.title" weight="medium" />
                    <Text text="salesSummary.tableCard.subtitle" weight="light" />
                </Flex>
                <FormContainer>
                    <SpacedFlex>
                        <Text type="large" weight="medium" text="salesSummary.form.choosePeriod" />
                        <Calendar
                            startDate={selectedPeriod.startDate}
                            endDate={selectedPeriod.endDate}
                            open
                            inline
                            monthShown={2}
                            onChange={handleDatesChange}
                            selectsRange
                            maxDate={maxDate}
                            openToDate={beginOfPreviousMonth}
                        />
                        <SelectLabel labelId="salesSummary.form.reportType" inputId="salesSummary.form.reportType" />
                        <MultiSelect<ReportType>
                            isMulti={false}
                            inputId="salesSummary.form.reportType"
                            placeholder={computeText(intl, 'salesSummary.form.reportType')}
                            options={reportTypeOptions}
                            // WHY? because the type of value is not well-supported when isMulti={false}
                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                            // @ts-ignore
                            onChange={handleSelectReportTypeChange}
                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                            // @ts-ignore
                            value={selectedReportType}
                        />
                        <SelectLabel labelId="salesSummary.form.restaurants" inputId="salesSummary.form.restaurants" />
                        <MultiSelect<number>
                            isMulti
                            inputId="salesSummary.form.restaurants"
                            placeholder={computeText(intl, 'salesSummary.form.restaurants')}
                            options={restaurantsOptions}
                            onChange={handleSelectRestaurantsChange}
                            value={selectedRestaurants}
                        />
                        {shouldUseGroupsAndPricingRulesFilters(selectedReportType.value) && (
                            <>
                                <SelectLabel labelId="salesSummary.form.groups" inputId="salesSummary.form.groups" />
                                <MultiSelect<string>
                                    isMulti
                                    inputId="salesSummary.form.groups"
                                    placeholder={computeText(intl, 'salesSummary.form.groups')}
                                    options={groupsOptions}
                                    onChange={handleSelectGroupsChange}
                                    value={selectedGroups}
                                />
                                <SelectLabel
                                    labelId="salesSummary.form.pricingRules"
                                    inputId="salesSummary.form.pricingRules"
                                />
                                <MultiSelect<string>
                                    isMulti
                                    inputId="salesSummary.form.pricingRules"
                                    placeholder={computeText(intl, 'salesSummary.form.pricingRules')}
                                    options={pricingRulesOptions}
                                    onChange={handleSelectPricingRulesChange}
                                    value={selectedPricingRules}
                                />
                            </>
                        )}
                    </SpacedFlex>

                    <ButtonWrapper>
                        <SvyButton onClick={exportReport} isLoading={isProcessingExport} disabled={!isValidPeriod}>
                            <FormattedMessage id="button.generate" />
                        </SvyButton>
                    </ButtonWrapper>
                </FormContainer>
                <ExportResultModal
                    title="salesSummary.exportResultModal.title"
                    isOpen={isExportResultOpen}
                    close={closeExportResult}
                    isSuccess={isExportRequestCreated}
                />
            </Container>
        </RouterNavigationTabs>
    );
};

export default SalesSummary;
