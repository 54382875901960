import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';
import { css } from '@emotion/react';

export const Container = styled.div<{ emphased: boolean; subMenu?: boolean; isSidebarCollapsed: boolean }>`
    display: flex;
    width: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? '35px' : 'auto')};
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing[2]};
    font-size: ${({ theme }) => theme.font.size.small};
    border-color: transparent;
    border-radius: ${({ theme }) => theme.radius.main};
    margin: 2px 0;
    color: ${({ theme }) => theme.color.white100};
    cursor: pointer;
    font-weight: ${({ theme, subMenu }) => (subMenu ? theme.font.weight.medium : theme.font.weight.regular)};
    background-color: transparent;
    position: relative;

    p {
        color: inherit;
        font-weight: inherit;
        white-space: nowrap;
    }

    &:hover {
        background-color: ${({ theme, subMenu }) => (subMenu ? theme.color.white100 : theme.color.slate100)};
        color: ${({ theme, subMenu }) => (subMenu ? theme.color.black : theme.color.white100)};
    }

    ${({ emphased, theme, subMenu }) =>
        emphased
            ? css`
                  background-color: ${subMenu ? theme.color.white100 : theme.color.slate200};
                  color: ${subMenu ? theme.color.black : theme.color.white100};
              `
            : ''}

    &.active {
        background-color: ${({ theme }) => theme.color.white200};
        border-color: ${({ theme }) => theme.color.grey300};
    }
`;

export const LeftBlock = styled.div`
    display: flex;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[3]};
`;

export const SideBarItemText = styled(Text)<{ isSidebarCollapsed: boolean }>`
    text-decoration: none;
    transition: 200ms ease;
    opacity: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? 0 : 1)};
`;

export const AlignRight = styled.div`
    display: inline;
    float: right;
`;
