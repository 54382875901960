import * as React from 'react';
import Wizard from '@/components/atoms/Wizard';
import RawModal from '@/components/atoms/RawModal';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { Form } from '@/core/form/Form';
import { Switch } from '@/components/form/Switch';
import { Button } from '@/components/atoms/Button';
import POSImage from './pos.png';
import { ImportPOSMenuStep1, InfoContainer, POSListContainer, StepContainer } from './ImportPOSMenuModal.style';
import { FormValues, ImportPOSMenuModalProps } from './ImportPOSMenuModal.interface';
import { useImportPOSMenuModalVm } from './ImportPOSMenuModal.viewmodel';

export const ImportPOSMenuModal: React.FunctionComponent<ImportPOSMenuModalProps> = (props) => {
    const { isOpen, setIsOpen, handleSubmitWizard, posList, theme } = useImportPOSMenuModalVm(props);
    return (
        <RawModal isOpen={isOpen} onHide={() => setIsOpen(false)}>
            <Form<FormValues>>
                {({ handleSubmit, formState, submitError }) => {
                    const handleNextSubmit = async () => {
                        try {
                            await new Promise<void>((resolve, reject) =>
                                handleSubmit(async (values) => {
                                    try {
                                        await handleSubmitWizard(values);
                                        resolve();
                                    } catch (e) {
                                        reject(e);
                                        throw e;
                                    }
                                })(),
                            );
                        } catch (e) {
                            return false;
                        }
                        return true;
                    };
                    return (
                        <Wizard
                            canGoBack={false}
                            steps={[
                                {
                                    title: 'menu.importPOS.title',
                                    component: (
                                        <StepContainer>
                                            <Title text="menu.importPOS.step1.title" />
                                            <ImportPOSMenuStep1>
                                                <div>
                                                    <ul>
                                                        <li>
                                                            <Text weight="bold" text="menu.importPOS.step1.bullet.1" />
                                                        </li>
                                                        <li>
                                                            <Text weight="bold" text="menu.importPOS.step1.bullet.2" />
                                                        </li>
                                                        <li>
                                                            <Text weight="bold" text="menu.importPOS.step1.bullet.3" />
                                                        </li>
                                                    </ul>
                                                    <InfoContainer>
                                                        <span className="ri-information-fill" />
                                                        <Text text="menu.importPOS.step1.info" />
                                                    </InfoContainer>
                                                </div>
                                                <div>
                                                    <img src={POSImage} alt="pos" />
                                                </div>
                                            </ImportPOSMenuStep1>
                                        </StepContainer>
                                    ),
                                    onCancel: () => setIsOpen(false),
                                },
                                {
                                    title: 'menu.importPOS.title',
                                    component: (
                                        <StepContainer>
                                            <POSListContainer>
                                                <Text text="menu.importPOS.step2.pos" />
                                                <Text text="menu.importPOS.step2.switch" />
                                                {posList.map((posDevice) => (
                                                    <React.Fragment key={posDevice.posDeviceId}>
                                                        <Text noTranslation text={posDevice.deviceName} />
                                                        <Switch
                                                            name={`pos.${posDevice.posDeviceId}.isSyncFromPosEnabled`}
                                                            defaultChecked={true}
                                                        />
                                                    </React.Fragment>
                                                ))}
                                            </POSListContainer>
                                            <InfoContainer>
                                                <span className="ri-information-fill" />
                                                <Text text="menu.importPOS.step1.info" />
                                            </InfoContainer>
                                            {!!submitError && (
                                                <Text
                                                    color={theme.color.red300}
                                                    text={submitError}
                                                    noTranslation
                                                    weight="bold"
                                                />
                                            )}
                                        </StepContainer>
                                    ),
                                    onNext: handleNextSubmit,
                                },
                                {
                                    title: 'menu.importPOS.title',
                                    component: (
                                        <StepContainer>
                                            <Text weight="bold" text="menu.importPOS.step3.title" />
                                            <Text text="menu.importPOS.step3.subtitle" />
                                            <InfoContainer>
                                                <span className="ri-information-fill" />
                                                <Text text="menu.importPOS.step3.info1" />
                                            </InfoContainer>
                                            <InfoContainer>
                                                <span className="ri-information-fill" />
                                                <Text text="menu.importPOS.step3.info2" />
                                            </InfoContainer>
                                            <InfoContainer>
                                                <span className="ri-information-fill" />
                                                <Text
                                                    noTranslation
                                                    text={
                                                        <FormattedMessage
                                                            id="menu.importPOS.step3.info3"
                                                            values={{
                                                                // eslint-disable-next-line react/display-name
                                                                strong: ((chunks: any) => (
                                                                    <strong>{chunks}</strong>
                                                                )) as unknown as React.ReactNode,
                                                            }}
                                                        />
                                                    }
                                                />
                                            </InfoContainer>
                                            <a
                                                href="https://help.innovorder.fr/hc/fr/articles/9581863663388-La-gestion-du-catalogue-de-caisse-sur-le-backoffice"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Button>
                                                    <FormattedMessage id="menu.importPOS.step3.link" />
                                                </Button>
                                            </a>
                                        </StepContainer>
                                    ),
                                },
                            ]}
                            isLoading={formState.isSubmitting}
                            cancelLabel="menu.importPOS.cancel"
                            onValidate={() => setIsOpen(false)}
                        />
                    );
                }}
            </Form>
        </RawModal>
    );
};
