import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import { ModuleStripe } from './module_stripes.type';

const moduleStripesApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        findAllModuleStripesByBrand: build.query<ModuleStripe[], ApiRequest<{ brandId: number }>>({
            query: ({ brandId, token }) => ({
                url: `/module_stripes/brand/${brandId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<ModuleStripe[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        findModuleStripeByRestaurant: build.query<ModuleStripe[], ApiRequest<{ restaurantId: number }>>({
            query: ({ restaurantId, token }) => ({
                url: `/module_stripes/restaurant/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<ModuleStripe[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        findModuleStripesByRestaurants: build.query<ModuleStripe[], ApiRequest<{ restaurantIds: string }>>({
            query: ({ restaurantIds, token }) => ({
                url: `/module_stripes_v2/restaurants`,
                params: {
                    restaurantIds,
                },
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<ModuleStripe[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        findModuleStripeById: build.query<ModuleStripe, ApiRequest<{ moduleStripeId: number | undefined | null }>>({
            query: ({ moduleStripeId, token }) => ({
                url: `/module_stripes/${moduleStripeId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<ModuleStripe>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        updateModuleStripeById: build.mutation<
            ModuleStripe,
            ApiRequest<{ moduleStripeId: number | undefined | null; moduleStripe: ModuleStripe }>
        >({
            query: ({ moduleStripeId, moduleStripe, token }) => ({
                url: `/module_stripes/${moduleStripeId}`,
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body: moduleStripe,
            }),
            transformResponse: (response: ApiResponse<ModuleStripe>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useFindModuleStripeByIdQuery,
    useFindModuleStripeByRestaurantQuery,
    useFindAllModuleStripesByBrandQuery,
    useFindModuleStripesByRestaurantsQuery,
    useUpdateModuleStripeByIdMutation,
    useLazyFindModuleStripeByIdQuery,
} = moduleStripesApi;
