import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Table, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, fetchDataProps } from '@/components/atoms/Table';
import { useTheme } from '@emotion/react';
import { Title } from '@/components/atoms/Title';
import { Routes } from '@/core/router/routes.types';
import { getParamFromQueryString, replaceParamInUrl } from '@/utils/history';
import { useHistory } from 'react-router';
import RestaurantSelector from '@/components/molecules/RestaurantSelector/RestaurantSelector';
import { useGetTransfersMutation } from '@/services/innovorder/io-pay/transfers/transfer.endpoints';
import { getUserRestaurants, getUserToken } from '@/redux/user';
import {
    convertTableFiltersToQueryFilters,
    convertTableSortToQuerySort,
    getTransfersTableColumns,
    getTransfersTableData,
} from './utils';
import { Container, FlexGrow, Subtitle, TitleContainer, Header, ContentContainer } from './Transfers.style';
import IOPayTabs from '../IOPayTabs';

type Props = Record<string, never>;

const defaultSortBy = [{ id: 'executedAt', desc: true }];

const Transfers: React.FunctionComponent<React.PropsWithChildren<Props>> = () => {
    const intl = useIntl();
    const theme = useTheme();
    const token = useSelector(getUserToken);
    const restaurants = useSelector(getUserRestaurants);
    const history = useHistory();

    const [selectedRestaurantId, setSelectedRestaurantId] = useState(
        Number(getParamFromQueryString(history.location.search, 'restaurant')) || 0,
    );

    const [fetchTransfers, { data, isLoading }] = useGetTransfersMutation();
    const fetchData = useCallback(
        ({ pageIndex, pageSize, globalFilter, columnFilters, sortBy }: fetchDataProps) => {
            if (token) {
                const sortOrder = convertTableSortToQuerySort(sortBy);
                const filters = convertTableFiltersToQueryFilters(columnFilters);
                const selectedOrAllRestaurantIds = selectedRestaurantId ? [selectedRestaurantId] : undefined;
                fetchTransfers({
                    restaurantIds: selectedOrAllRestaurantIds,
                    token,
                    search: globalFilter,
                    filters,

                    limit: pageSize ?? DEFAULT_PAGE_SIZE,
                    offset:
                        pageIndex === undefined || pageSize === undefined ? DEFAULT_PAGE_INDEX : pageIndex * pageSize,
                    order: sortOrder?.length ? sortOrder : undefined,
                });
            }
        },
        [token, fetchTransfers, selectedRestaurantId],
    );

    const handleRestaurantChange = useCallback(
        (restaurantId: number) => {
            setSelectedRestaurantId(restaurantId);
            replaceParamInUrl(history, 'restaurant', String(restaurantId));
        },
        [history],
    );

    const columns = useMemo(() => getTransfersTableColumns(intl), [intl]);
    const rows = useMemo(() => getTransfersTableData(data?.transfers, intl, theme), [data, intl, theme]);

    return (
        <IOPayTabs>
            <Container>
                <ContentContainer>
                    <Header data-testid="transfers-head">
                        <TitleContainer>
                            <Title text="transfers.title" />
                            <Subtitle text="transfers.subtitle" />
                        </TitleContainer>
                        <FlexGrow />
                        {restaurants && (
                            <RestaurantSelector
                                buttonLabel={
                                    selectedRestaurantId
                                        ? restaurants.find(
                                              (restaurant) => restaurant.restaurantId === selectedRestaurantId,
                                          )?.name || '???'
                                        : intl.formatMessage({ id: 'orderList.filters.allRestaurants' })
                                }
                                restaurants={restaurants}
                                selected={selectedRestaurantId}
                                onChange={handleRestaurantChange}
                                headerLabel="orderList.filters.restaurantHeader"
                            />
                        )}
                    </Header>
                    <Table
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={columns}
                        hasPagination
                        hasUrlNavigation
                        isFilterable
                        isSortable
                        defaultSortBy={defaultSortBy}
                        fetchData={fetchData}
                        fetchDataTotalCount={data?.total}
                        isLoading={isLoading}
                        columnSelectorId="transferList"
                        onRowClickPath={Routes.IOPayTransferDetail}
                    />
                </ContentContainer>
            </Container>
        </IOPayTabs>
    );
};

export default Transfers;
