import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { Section } from '@/components/atoms/Section';
import { computeText } from '@/locales/utils';
import { Input } from '@/components/atoms/Form/Input';
import Select, { Option } from '@/components/atoms/Select';
import { Label } from '@/components/atoms/Label';
import MultiSelect from '@/components/atoms/MultiSelect';
import { BillingGroupingBy } from '@/services/innovorder/autoBilling/autoBilling.type';
import {
    Container,
    Form,
    FormText,
    InputContainer,
    InputsContainer,
    MultiSelectContainer,
} from './BillingEntityInfoForm.style';
import { getGroupingByOptions, getMultiSelectCustomStyle } from './utils';

export type BillingEntityInfo = {
    code: string;
    name: string;
    groupingBy?: BillingGroupingBy;
    groups: string[];
};
type BillingEntityInfoFormProps = BillingEntityInfo & {
    groupsOptions: string[];
    onUpdate: (billingEntityMeta: BillingEntityInfo) => void;
    onIsValidChange: (value: boolean) => void;
    action?: 'create' | 'edit';
};

const BillingEntityInfoForm: React.FunctionComponent<React.PropsWithChildren<BillingEntityInfoFormProps>> = ({
    code,
    name,
    groupsOptions,
    groups,
    groupingBy,
    onUpdate,
    onIsValidChange,
    action = 'create',
}) => {
    const intl = useIntl();
    const theme = useTheme();

    const {
        control,
        getValues,
        setValue,
        formState: { isValid, touchedFields },
    } = useForm<BillingEntityInfo>({
        mode: 'all',
        defaultValues: {
            code,
            name,
            groupingBy: groupingBy ?? 'BILLING_ENTITY',
            groups,
        },
    });
    const handleChange = useCallback(() => onUpdate(getValues()), [onUpdate, getValues]);

    const mapToGroupsSelectOptions = (values: string[]): Option<string>[] => {
        return values.map((group) => ({ label: group, value: group }));
    };

    useEffect(() => {
        onIsValidChange(isValid);
    }, [isValid, onIsValidChange]);

    const multiSelectCustomStyle = useMemo(() => getMultiSelectCustomStyle(theme), [theme]);
    const groupingByOptions = useMemo(() => getGroupingByOptions(intl), [intl]);

    return (
        <Container data-testid="billing-entity-info-form">
            <Section
                overflowBody={false}
                title={`auto.billing.referential.billingEntities.form.${action}.title`}
                subtitle={`auto.billing.referential.billingEntities.form.${action}.subtitle`}
            >
                <Form onChange={handleChange}>
                    <InputsContainer>
                        <InputContainer>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: computeText(intl, 'field.required') }}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <>
                                        <FormText
                                            type="small"
                                            weight="medium"
                                            text="auto.billing.referential.billingEntities.form.label.title"
                                        />
                                        <Input
                                            data-testid="label"
                                            error={error?.message}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            isTouched={touchedFields.name}
                                        />
                                    </>
                                )}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Controller
                                control={control}
                                name="code"
                                rules={{ required: computeText(intl, 'field.required') }}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <>
                                        <FormText
                                            type="small"
                                            weight="medium"
                                            text="auto.billing.referential.billingEntities.form.code.title"
                                        />
                                        <Input
                                            data-testid="code"
                                            error={error?.message}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            isTouched={touchedFields.code}
                                        />
                                    </>
                                )}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Controller
                                control={control}
                                name="groupingBy"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <Select<string>
                                        value={value}
                                        options={groupingByOptions}
                                        onChange={(event) => {
                                            onChange(event as BillingGroupingBy);
                                            handleChange();
                                        }}
                                        onBlur={onBlur}
                                        labelId="auto.billing.referential.billingEntities.form.groupingBy.title"
                                        isTouched={touchedFields.groupingBy}
                                    />
                                )}
                            />
                        </InputContainer>
                    </InputsContainer>
                    <MultiSelectContainer>
                        <Controller
                            control={control}
                            name="groups"
                            rules={{ required: computeText(intl, 'field.required') }}
                            render={({ field: { onChange, onBlur }, fieldState: { error } }) => {
                                return (
                                    <>
                                        <Label
                                            labelId="auto.billing.referential.billingEntities.form.groups.text"
                                            inputId="auto.billing.referential.billingEntities.form.groups.text"
                                        />
                                        <MultiSelect<string>
                                            inputId="auto.billing.referential.billingEntities.form.groups.text"
                                            isMulti
                                            options={mapToGroupsSelectOptions(groupsOptions)}
                                            getOptionValue={(option) => option.value}
                                            onChange={(values) => {
                                                onChange(values as unknown as string[]);
                                                setValue(
                                                    'groups',
                                                    values.map((value) => value.value),
                                                );
                                                handleChange();
                                            }}
                                            value={mapToGroupsSelectOptions(groups)}
                                            onBlur={onBlur}
                                            placeholder=""
                                            error={error}
                                            customStyle={multiSelectCustomStyle}
                                        />
                                    </>
                                );
                            }}
                        />
                    </MultiSelectContainer>
                </Form>
            </Section>
        </Container>
    );
};

export default BillingEntityInfoForm;
