import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getUserBrandId } from '@/redux/user';
import { DropDown } from '@/components/atoms/DropDown/DropDown';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Container, DropDownButton, TextWrapper } from './BrandSwitchBanner.style';

import { Text } from '@/components/atoms/Text';
import {
    AuthCookie,
    cookieLocation,
    cookieLocationBrandGuest,
    GuestBrandAuthCookie,
    useAuthCookieHandler,
} from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { useCookies } from 'react-cookie';
import { useLazyBrandGuestLoginQuery, useLazyMeQuery } from '@/services/innovorder/auth/auth.endpoints';
import { computeCookieDomain, COOKIE_PATH } from '@/pages/Login/Login';
import { theme } from '@/core/theme';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { useGetBrandGroupBrandsListQuery } from '@/services/innovorder/brandGroup/brandgroup.endpoints';

const BrandSwitchBanner = () => {
    const user = useSelector(getUser);
    const { isGuestBrand } = useAuthCookieHandler();
    const [cookies, setCookies] = useCookies();
    const selectedBrand = useSelector(getUserBrandId);
    const guestBrandCookieValue: GuestBrandAuthCookie = cookies[cookieLocationBrandGuest];
    const mainUserEmail = guestBrandCookieValue?.mainUserEmail;
    const mainUserToken = cookies[cookieLocation]?.accessToken;
    const { data: availableBrands } = guestBrandCookieValue?.brandGroupId
        ? useGetBrandGroupBrandsListQuery({
              brandGroupId: guestBrandCookieValue.brandGroupId,
              token: mainUserToken,
          })
        : { data: [] };
    const [loginAsGuestBrand] = useLazyBrandGuestLoginQuery();
    const [me] = useLazyMeQuery();

    const items = useMemo(() => {
        return availableBrands?.map((brand) => ({
            value: brand.brandId,
            label: brand.name || '',
        }));
    }, [availableBrands]);

    const handleChange = useCallback(async (brandId: number) => {
        if (!user?.userId) {
            return;
        }
        const response = await loginAsGuestBrand({ brandId, token: mainUserToken }).unwrap();
        setCookies(
            cookieLocationBrandGuest,
            {
                ...(guestBrandCookieValue || {}),
                accessToken: response.accessToken,
            },
            { domain: computeCookieDomain(window.location.host), path: COOKIE_PATH },
        );
        await me({ token: response.accessToken }).unwrap();
        redirectToBackofficeV1('/home');
    }, []);

    return isGuestBrand ? (
        <Container>
            <TextWrapper>
                <SVGIcon icon={SVGIcons.CLIENTS} size={16} color="white" />
                <Text text="adminBanner.message" data-testid="test-text" />
                <Text text={mainUserEmail} weight="bold" noTranslation />
            </TextWrapper>
            {items && (
                <DropDown
                    items={items}
                    onChange={handleChange}
                    isUnstyledButton={true}
                    selected={selectedBrand}
                    filterPlaceholder={'table.search'}
                    type={'radio'}
                    button={
                        <DropDownButton>
                            <SVGIcon icon={SVGIcons.BRAND} size={16} color={theme.color.white100} />
                            <Text text={availableBrands?.find((brand) => brand.brandId === selectedBrand)?.name}></Text>
                            <SVGIcon icon={SVGIcons.CHEVRON} size={16} color={theme.color.white100} />
                        </DropDownButton>
                    }
                />
            )}
        </Container>
    ) : null;
};
export default BrandSwitchBanner;
