import React, { useState } from 'react';
import { injectDataInUri } from '@/utils/routes';
import { ENV } from '@/utils/env';
import { useParams } from 'react-router';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { computeText } from '@/locales/utils';
import { Input } from '@/components/atoms/Form/Input';
import { useIntl } from 'react-intl';
import { ParamTypes } from '@/hooks/useDynamiqueBreadCrumb/useDynamiqueBreadCrumb';
import { Button } from '@/components/atoms/Button';
import { PaymentMethod, PaymentMethodCode } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { ConsumptionMode } from '@/services/innovorder/consumptionMode/consumptionMode.type';
import { ConsumptionModes } from '../../ConsumptionModes';
import { IntegrationConfig } from '../Tab';
import { Card, Message, Label } from './Integration.style';

type IntegrationProps = {
    integration: IntegrationConfig;
    consumptionModes: ConsumptionMode[];
    paymentMethods: PaymentMethod[];
    isLoadingConsumptionModes: boolean;
    isExternalIdActivated: boolean;
    onChange: (param: PaymentMethod[]) => void;
};

const Integration: React.FunctionComponent<IntegrationProps> = ({
    integration,
    isLoadingConsumptionModes,
    isExternalIdActivated,
    paymentMethods,
    consumptionModes,
    onChange,
}) => {
    const intl = useIntl();
    const [editable, setEditable] = useState(false);
    const params = useParams<ParamTypes>();
    const integrationUrl = `${injectDataInUri({
        uri: ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/integrations',
        backofficeUrl: ENV.BACKOFFICE_URL,
        ...params,
    })}`;

    const {
        type,
        logoPath,
        paymentMethod,
        paymentMethodCode,
        showIntegration,
        showConfigButton,
        showConsumptionModes,
        isFull = true,
        message = null,
    } = integration;

    const handleOnChange = (code: PaymentMethodCode) => (newPaymentMethod: PaymentMethod) => {
        if (!paymentMethods.some((pm) => pm.code === code)) {
            newPaymentMethod.label = code.toString();
            onChange([...paymentMethods, newPaymentMethod]);
            return;
        }
        const _paymentMethods = paymentMethods.map((pm) => (pm.code === code ? newPaymentMethod : pm));
        onChange(_paymentMethods);
    };

    const handleExternalIdChange =
        (code: PaymentMethodCode) =>
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            const _paymentMethods = paymentMethods.map((pm) =>
                pm.code === code ? ({ ...pm, action: 'update', externalId: value } as PaymentMethod) : pm,
            );
            onChange(_paymentMethods);
        };

    const redirectToIntegrationUrl = () => {
        window.location.replace(integrationUrl);
    };

    return (
        <>
            {showIntegration && (
                <Card data-testid={type} isFull={isFull} image={logoPath}>
                    {showConsumptionModes && isExternalIdActivated && (
                        <Label>
                            <Input
                                data-testid="externalIdInput"
                                disabled={!editable}
                                type="text"
                                onChange={handleExternalIdChange(paymentMethodCode)}
                                placeholder="paymentMethods.externalId"
                                value={paymentMethod.externalId}
                            />
                            <SVGIcon onClick={() => setEditable(!editable)} icon={SVGIcons.PEN} size={18} />
                        </Label>
                    )}
                    {showConsumptionModes && (
                        <ConsumptionModes
                            paymentMethod={paymentMethod}
                            isLoading={isLoadingConsumptionModes}
                            consumptionModes={consumptionModes ?? []}
                            onChange={handleOnChange(paymentMethodCode)}
                        />
                    )}
                    {showConfigButton && (
                        <Button data-testid="configButton" onClick={redirectToIntegrationUrl}>
                            {computeText(intl, `configure.${type}`)}
                        </Button>
                    )}
                    {message && (
                        <Message data-testid="message" type={type}>
                            {message}
                        </Message>
                    )}
                </Card>
            )}
        </>
    );
};

export default Integration;
