import styled from '@emotion/styled';
import { SVGIcon } from '@/components/atoms/Icon/SVGIcon';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const SmallPhoto = styled.img`
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s all ease-out;

    &:hover {
        transform: scale(1.02);
    }
`;

export const LargePhoto = styled.img`
    width: 100%;
    border-radius: 5px;
`;

export const ModalContent = styled.div`
    width: 55vw;
    overflow: hidden;
    align-self: center;
    padding: 10px 20px;
    position: relative;

    @media (max-width: 1023px) {
        width: 95vw;
    }
`;

export const CloseIcon = styled(SVGIcon)`
    position: absolute;
    right: 25px;
    top: 15px;
    cursor: pointer;
`;
