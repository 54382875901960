import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
// import { ApiRequest } from '../../request.types';
import { Invoice } from './invoices.type';

const configurationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getInvoices: build.query<Invoice[], void>({
            query: () => ({
                url: new URL(`/v1/billing/invoices`, ENV.PAYMENT_SERVICE_URL).href,
                method: 'GET',
            }),
            transformResponse: (response: Invoice[]) => response,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetInvoicesQuery } = configurationApi;
