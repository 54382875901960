import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { useDispatch, useSelector } from 'react-redux';

import { ContextGate } from '@/pages/ContextGate';
import AuthGate from '@/pages/AuthGate';
import NotFound from '@/pages/NotFound';
import DefaultLayout from '@/layouts/DefaultLayout';
import LoginLayout from '@/layouts/LoginLayout';
import { getHasPermissionDenied, setHasPermissionDenied } from '@/redux/app';
import { TrackingListener } from '@/core/tracking';
import { useFilterRoutes } from '@/hooks/routes/useFilterRoutes';
import { redirections, routes } from './routes';
import { Routes } from './routes.types';

interface Props {
    history: History;
}

const RootRouter: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ history }) => {
    const filteredRoutes = useFilterRoutes(routes);
    const dispatch = useDispatch();
    const hasPermissionDenied = useSelector(getHasPermissionDenied);

    useEffect(() => {
        if (hasPermissionDenied) {
            history.push(Routes.PermissionDenied);
            dispatch(setHasPermissionDenied(false));
        }
    }, [dispatch, history, hasPermissionDenied]);

    return (
        <ConnectedRouter history={history}>
            <React.Suspense>
                <TrackingListener />
            </React.Suspense>
            <Switch>
                <Route path={Routes.Login} exact>
                    <LoginLayout />
                </Route>
                <AuthGate>
                    <ContextGate>
                        <Switch>
                            {redirections.map(({ from, to, exact }) => (
                                <Redirect key={`${from}-${to}`} from={from} to={to} exact={exact} />
                            ))}

                            {filteredRoutes.map(({ path, component, name }) => {
                                if (!path) return null;
                                return (
                                    <Route path={path} key={name}>
                                        <DefaultLayout pageContent={component} />
                                    </Route>
                                );
                            })}

                            <Route path={Routes.NotFound}>
                                <DefaultLayout pageContent={NotFound} />
                            </Route>
                            <Redirect to={Routes.NotFound} />
                        </Switch>
                    </ContextGate>
                </AuthGate>
            </Switch>
        </ConnectedRouter>
    );
};

export default RootRouter;
