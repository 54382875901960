import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Switch } from '@/components/form/Switch';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import { PriceInput } from '@/components/form/PriceInput';
import { Left, Right, Row, Subtitle, SwitchRow } from './EwalletOrverdraftForm.style';
import { isPostPaymentAccountPaymentType, POST_PAYMENT_EWALLET_OVERDRAFT_DEFAULT_AMOUNT } from '../Student.utils';

export type EwalletOrverdraftFormProps = {};

const EwalletOrverdraftForm: React.FunctionComponent<React.PropsWithChildren<EwalletOrverdraftFormProps>> = () => {
    const { watch, setValue } = useFormContext();
    const watchOverrideEwalletOverdraft = watch('overrideEwalletOverdraft');
    const watchAccountPaymentType = watch('accountPaymentType');
    const isPostPayment = useMemo(
        () => isPostPaymentAccountPaymentType(watchAccountPaymentType),
        [watchAccountPaymentType],
    );

    useEffect(() => {
        if (isPostPayment) {
            setValue('ewalletOverdraftAmount', POST_PAYMENT_EWALLET_OVERDRAFT_DEFAULT_AMOUNT);
            setValue('overrideEwalletOverdraft', true);
        }
    }, [isPostPayment]);

    return (
        <Row>
            <Left>
                <SwitchRow>
                    <Title text="customer.student.overdraft.title" />
                    <Switch name={'overrideEwalletOverdraft'} disabled={isPostPayment} />
                </SwitchRow>
                <Subtitle>
                    <Text text="customer.student.overdraft.desctiption" />
                </Subtitle>
            </Left>
            <Right>
                {watchOverrideEwalletOverdraft ? (
                    <PriceInput
                        required
                        labelId="customer.student.overdraft.price.label"
                        name="ewalletOverdraftAmount"
                        min={0.01}
                    />
                ) : (
                    <Text text="customer.student.overdraft.disabled" />
                )}
            </Right>
        </Row>
    );
};

export default EwalletOrverdraftForm;
