import { generatePath, matchPath } from 'react-router';
import { NavigationContext, RouteDeclaration, UriRouteResolveDeclaration } from '@/core/router/routes.types';
import { ENV } from '@/utils/env';

type injectDataInUriProps = {
    uri: string;
    backofficeUrl: string;
} & NavigationContext;

export const injectDataInUri = ({ uri, ...params }: injectDataInUriProps): string => {
    return Object.entries(params).reduce((parsedUri, [key, val]) => {
        return parsedUri.replace(`:${key}`, val?.toString() ?? '');
    }, uri);
};

export type WithResolvedUri<T> = T & {
    resolvedUri: string | undefined;
};

export const mapWithDynamicUri = <T extends { uri?: string } | Pick<UriRouteResolveDeclaration, 'resolveUri'>>(
    array: T[],
    navigationContext: NavigationContext,
): WithResolvedUri<T>[] =>
    array.map((link) => {
        let resolvedUri: string | undefined;
        if ('uri' in link && link.uri) {
            resolvedUri = injectDataInUri({
                uri: link.uri,
                backofficeUrl: ENV.BACKOFFICE_URL,
                ...navigationContext,
            });
        }
        if ('resolveUri' in link && link.resolveUri) {
            resolvedUri = link.resolveUri({ backofficeUrl: ENV.BACKOFFICE_URL, ...navigationContext });
        }
        return {
            ...link,
            resolvedUri,
        };
    });

export type WithResolvedPath<T extends { path?: string }> = T & { resolvedPath: string | undefined };

type MapWithDynamicPathOptions = {
    navigationContext: NavigationContext;
    parentPath?: string;
};
export const mapWithDynamicPath = <T extends RouteDeclaration<any>>(
    array: T[],
    { navigationContext, parentPath = '' }: MapWithDynamicPathOptions,
): WithResolvedPath<T>[] =>
    array.map((link) => ({
        ...link,
        resolvedPath: link.path ? `${parentPath}${generatePath(link.path, navigationContext)}` : undefined,
    }));

export const getRouteAttributesFromPath = (
    pathName: string,
    routes: RouteDeclaration[],
): RouteDeclaration | undefined =>
    routes.find(({ path, uri }) => {
        return matchPath(pathName, {
            path: path ?? uri,
            strict: true,
            exact: true,
        });
    });

export const getRouteAttributesFromName = (
    routeName: string,
    routes: RouteDeclaration[],
): RouteDeclaration | undefined => routes.find(({ name }) => routeName === name);
