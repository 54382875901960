import { useHistory, useParams } from 'react-router';
import {
    useGetPosAccessRoleByIdQuery,
    useGetPosAccessRolePermissionsQuery,
    usePatchPosAccessRoleMutation,
} from '@/services/innovorder/pos_access/pos_access_role/pos_access_role.endpoints';
import { PosAccessRole } from '@/services/innovorder/pos_access/pos_access_role/pos_access_role.type';
import { useCallback } from 'react';
import { stringifyError } from '@/utils/errors';

export type MenuRolesViewModel = {
    permissions: string[];
    posRole: PosAccessRole | null;
    isLoading: boolean;
    isSuccess: boolean;
    error: string;
    handleSubmit: (permissions: string[]) => void;
};

type ParamTypes = {
    restaurantId: string;
    brandId: string;
    roleId: string;
};

export function useRoleEditVM(): MenuRolesViewModel {
    const { restaurantId, roleId } = useParams<ParamTypes>();
    const { data: posRole = null, isLoading: isLoadingRoles } = useGetPosAccessRoleByIdQuery({
        restaurantId: Number(restaurantId),
        posRoleId: Number(roleId),
    });
    const { data: permissions = [], isLoading: isLoadingPermissions } = useGetPosAccessRolePermissionsQuery({
        restaurantId: Number(restaurantId),
    });
    const history = useHistory();
    const [patchPosAccessRole, { isLoading: isLoadingPatch, isSuccess, error }] = usePatchPosAccessRoleMutation();
    const isLoading = isLoadingRoles || isLoadingPermissions || isLoadingPatch;
    const handleSubmit = useCallback(
        (permissions: string[]) => {
            patchPosAccessRole({
                permissions,
                restaurantId: Number.parseInt(restaurantId, 10),
                posRoleId: Number.parseInt(roleId, 10),
            });
        },
        [patchPosAccessRole, restaurantId, roleId],
    );
    return {
        permissions,
        posRole,
        isLoading,
        handleSubmit,
        isSuccess,
        error: stringifyError(error),
    };
}
