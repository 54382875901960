import { Button } from '@/components/atoms/Button';
import React, { useCallback, useMemo } from 'react';
import {
    ButtonsWrapper,
    Container,
    Description,
    ImageContainer,
    InstalledBadge,
    Title,
    ImageWrapper,
} from './Channel.style';
import { SVGIcon } from '../../atoms/Icon/SVGIcon';
import { SVGIcons } from '../../atoms/Icon/SVGIcon.interface';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { useSelector } from 'react-redux';
import { getUserRole } from '@/redux/user';
import { UserRoles } from '@/services/innovorder/user/user.type';
import {
    useActivateChannelOnBrandMutation,
    useDeactivateChannelOnBrandMutation,
    useActivateChannelOnRestaurantMutation,
    useDeactivateChannelOnRestaurantMutation,
} from '@/services/innovorder/channel/channel.endpoints';
import { useRouteMatch } from 'react-router';

export type ChannelProps = {
    channelId: number;
    isInstalled: boolean;
    nom: string;
    description: string;
    icon: SVGIcons;
    shouldDisplay: boolean;
    shouldAllowConfig: boolean;
    redirectToConfigPage: (event: React.MouseEvent<HTMLElement>) => void;
    overrideActivateAction?: () => void;
    overrideDeactivateAction?: () => void;
};

const Channel: React.FC<React.PropsWithChildren<ChannelProps>> = ({
    nom,
    isInstalled,
    description,
    icon,
    channelId,
    shouldDisplay,
    shouldAllowConfig,
    redirectToConfigPage,
    overrideActivateAction,
    overrideDeactivateAction,
}) => {
    const intl = useIntl();
    const { params } = useRouteMatch<{ brandId: string; restaurantId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);
    const restaurantId = params.restaurantId?.length ? Number.parseInt(params.restaurantId, 10) : undefined;
    const currentUserRole = useSelector(getUserRole);
    const showDisableButton = useMemo(() => currentUserRole === UserRoles.admin, [currentUserRole]);

    const [activateChannelOnBrand] = useActivateChannelOnBrandMutation();
    const [deactivateChannelOnBrand] = useDeactivateChannelOnBrandMutation();
    const [activateChannelOnRestaurant] = useActivateChannelOnRestaurantMutation();
    const [deactivateChannelOnRestaurant] = useDeactivateChannelOnRestaurantMutation();

    const handleEnable = useCallback(() => {
        if (overrideActivateAction) {
            return overrideActivateAction();
        }
        if (restaurantId) {
            return activateChannelOnRestaurant({
                restaurantId: String(restaurantId),
                channelId: String(channelId),
            });
        }
        activateChannelOnBrand({
            brandId: String(brandId),
            channelId: String(channelId),
        });
    }, [restaurantId]);

    const handleDisable = useCallback(() => {
        if (overrideDeactivateAction) {
            return overrideDeactivateAction();
        }
        if (restaurantId) {
            return deactivateChannelOnRestaurant({
                restaurantId: String(restaurantId),
                channelId: String(channelId),
            });
        }
        deactivateChannelOnBrand({
            brandId: String(brandId),
            channelId: String(channelId),
        });
    }, [restaurantId]);

    return shouldDisplay ? (
        <Container>
            <ImageContainer>
                <ImageWrapper>
                    <SVGIcon icon={icon} size={40} />
                </ImageWrapper>
                {isInstalled && (
                    <InstalledBadge>
                        {computeText(intl, 'channels.installed')} <SVGIcon icon={SVGIcons.CHECK} size={13} />
                    </InstalledBadge>
                )}
            </ImageContainer>

            <div>
                <Title>{computeText(intl, nom)}</Title>
                <Description>{computeText(intl, description)}</Description>
            </div>

            <ButtonsWrapper>
                {isInstalled && shouldAllowConfig ? (
                    <Button
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            redirectToConfigPage(event);
                        }}
                        fullWidth={true}
                        buttonType={'primary'}
                    >
                        {computeText(intl, 'channels.button.configure')}
                    </Button>
                ) : (
                    ''
                )}
                {showDisableButton &&
                    (isInstalled ? (
                        <Button onClick={handleDisable} fullWidth={true}>
                            {computeText(intl, 'channels.button.disable')}
                        </Button>
                    ) : (
                        <Button onClick={handleEnable} fullWidth={true}>
                            {computeText(intl, 'channels.button.enable')}
                        </Button>
                    ))}
            </ButtonsWrapper>
        </Container>
    ) : null;
};

export default Channel;
