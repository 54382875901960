import { Options } from 'react-select';
import { PricingRuleApplyScope } from '@/services/innovorder/pricingRule/pricingRule.type';
import { SelectOptionInterface } from '@/components/atoms/MultiSelect';

export enum CanBeBlockedChannels {
    KIOSK = '1',
    WEB = '2',
    // RECEPTION = '4',
    POS = '5',
    // LOYALTY = '6',
    EWALLET_KIOSK = '7',
    VENDING_MACHINE = '8',
    // UBER_EATS = '9',
    // DELIVEROO = '10',
    // JUST_EAT = '11',
}
export type BlockedChannels = {
    channelId: CanBeBlockedChannels;
};

export const mapApplyScopeToOptions = (
    options: SelectOptionInterface<PricingRuleApplyScope>[],
    applyScope: PricingRuleApplyScope[] | undefined,
): Options<SelectOptionInterface<PricingRuleApplyScope>> => {
    if (!applyScope) {
        return [];
    }

    return options.filter((option) => applyScope.includes(option.value));
};

export const mapOptionToApplyScope = (
    options: Options<SelectOptionInterface<PricingRuleApplyScope>>,
): PricingRuleApplyScope[] => options.map((option) => option.value);

export const mapBlockedChannelsToOptions = (
    availableOptions: SelectOptionInterface<CanBeBlockedChannels>[],
    blockedChannels: BlockedChannels[] | undefined,
): Options<SelectOptionInterface<CanBeBlockedChannels>> =>
    availableOptions.filter(
        (option) =>
            !blockedChannels?.some(({ channelId }) => channelId.toString() === option.value.toString()) ||
            option.isFixed,
    );

export const mapOptionToBlockedChannels = (
    availableOptions: SelectOptionInterface<CanBeBlockedChannels>[],
    selectedOptions: Options<SelectOptionInterface<CanBeBlockedChannels>>,
): BlockedChannels[] =>
    availableOptions.reduce<BlockedChannels[]>(
        (result, option) =>
            !option.isFixed && !selectedOptions.map(({ value }) => value).includes(option.value)
                ? [...result, { channelId: option.value }]
                : result,
        [],
    );
