import React from 'react';
import { HeaderRight, Subtitle } from '@/components/atoms/Section/Section.style';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import { Table } from '@/components/atoms/Table';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import RestaurantSelector from '@/components/molecules/RestaurantSelector';
import { FormattedMessage } from 'react-intl';
import { SvyIcon } from '@/components/savory/SvyIcon';
import { useCustomersTableVM } from './Customer.viewmodel';
import { Container, Header, HeaderContainer, HeaderTitle, OptionsButton } from './Customer.style';
import OptionsModal from './OptionsModal';

const Customer: React.FunctionComponent<React.PropsWithChildren> = () => {
    const {
        brandId,
        headers,
        rows,
        isLoading,
        handleRowClick,
        fetchData,
        fetchDataTotalCount,
        buttonLabel,
        selectedRestaurantId,
        restaurants,
        handleRestaurantChange,
        defaultHiddenColumns,
        showOptionsModal,
        handleOpenOptionModal,
        handleCloseOptionModal,
        customerFetchOptions,
        handleCustomerFetchOptionsChange,
    } = useCustomersTableVM();

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderTitle>
                            <Title text="customers.page.title" />
                            <Subtitle>
                                <Text text="customers.page.description" />
                            </Subtitle>
                        </HeaderTitle>
                        <HeaderRight>
                            <OptionsButton onClick={handleOpenOptionModal} buttonType="secondary">
                                <SvyIcon icon="filter-line" />
                                <FormattedMessage id="customers.options" />
                            </OptionsButton>
                            {restaurants && (
                                <RestaurantSelector
                                    buttonLabel={buttonLabel}
                                    restaurants={restaurants}
                                    selected={selectedRestaurantId}
                                    onChange={handleRestaurantChange}
                                    headerLabel="orderList.filters.restaurantHeader"
                                />
                            )}
                        </HeaderRight>
                    </Header>
                    <Table
                        isSearchable
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={headers}
                        hasPagination
                        hasUrlNavigation
                        isSortable
                        onRowClick={handleRowClick}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        fetchDataTotalCount={fetchDataTotalCount}
                        columnSelectorId="customerList"
                        defaultHiddenColumns={defaultHiddenColumns}
                    />
                    <OptionsModal
                        isOpen={showOptionsModal}
                        onHide={handleCloseOptionModal}
                        brandId={brandId}
                        customerFetchOptions={customerFetchOptions}
                        handleCustomerFetchOptionsChange={handleCustomerFetchOptionsChange}
                    />
                </HeaderContainer>
            </Container>
        </RouterNavigationTabs>
    );
};

export default Customer;
