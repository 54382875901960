import React from 'react';
import { assertUnreachable } from '@/utils/typescript';
import ubereatsLogo from '@/components/atoms/Icon/png/ubereatsLogo.png';
import deliverectLogo from '@/components/atoms/Icon/png/deliverectLogo.png';
import deliverooLogo from '@/components/atoms/Icon/png/deliverooLogo.png';
import styled from '@emotion/styled';
import { MarketplaceChannelName } from '@/services/innovorder/marketplace/marketplace.type';

export const Logo = styled.div`
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

type MarketplaceLogoProps = {
    name: MarketplaceChannelName;
};

const MarketplaceLogo: React.FunctionComponent<
    React.PropsWithChildren<MarketplaceLogoProps & Partial<React.HTMLAttributes<HTMLElement>>>
> = ({ name, ...otherProps }) => {
    const getMarketplaceLogoByName = (): string => {
        switch (name) {
            case 'ubereats':
                return ubereatsLogo;
            case 'deliverect':
                return deliverectLogo;
            case 'deliveroo':
                return deliverooLogo;
            default:
                return assertUnreachable(name);
        }
    };
    return (
        <Logo {...otherProps}>
            <img src={getMarketplaceLogoByName()} alt={`Logo ${name}`} />
        </Logo>
    );
};

export default MarketplaceLogo;
