import { useMemo, useState } from 'react';
import { DateRange } from '@/pages/Reporting/Report/ReportTable/types';
import { startOfMonth } from 'date-fns';

type DateRangeHandlers = {
    dates: DateRange;
    isDateSelectorOpen: boolean;
    maxRangeInDays: number;
    openDateSelector: () => void;
    closeDateSelector: () => void;
    submitDateRange: (dateRange: DateRange) => void;
};

export const useHandleDateRange = (): DateRangeHandlers => {
    const [dates, setDates] = useState<DateRange>({
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
    });
    const [isDateSelectorOpen, setIsDateSelectorOpen] = useState<boolean>(false);
    const maxRangeInDays = useMemo(() => 365, []);

    const openDateSelector = () => {
        setIsDateSelectorOpen(true);
    };

    const closeDateSelector = () => {
        setIsDateSelectorOpen(false);
    };

    const submitDateRange = (dateRange: DateRange) => {
        setDates(dateRange);
        closeDateSelector();
    };

    return {
        dates,
        isDateSelectorOpen,
        maxRangeInDays,
        openDateSelector,
        closeDateSelector,
        submitDateRange,
    };
};
