import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MultiSelect from '@/components/atoms/MultiSelect';
import { Label } from '@/components/atoms/Label';
import { Option } from '@/components/atoms/Select';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { SelectConfig } from '@/services/innovorder/report/report.type';

const SelectInputWrapper = styled.div`
    margin: 10px;
`;

type SelectInputProps = {
    selectConfig: SelectConfig;
};

const SelectInput: React.FunctionComponent<SelectInputProps> = ({
    selectConfig: {
        options: { values: possibleSelectValues, multiple },
        name,
        key,
    },
}) => {
    const intl = useIntl();
    const { control } = useFormContext();

    const mapSelectedValuesToMultiSelectedOptionValues = (
        selectOptions: Option<string | number>[],
        selectedValues?: (string | number)[],
    ): Option<string | number>[] => {
        return (selectedValues ?? [])
            .map((oneValue) => {
                return selectOptions.find((option) => option.value === oneValue) as Option<string | number>;
            })
            .filter(Boolean);
    };

    const convertSelectOptionToFormValue = ({ value }: Option<number | string>) => value;

    return (
        <Controller
            control={control}
            name={key}
            render={({ field: { onChange, onBlur, value: selectedValues } }) => {
                return (
                    <SelectInputWrapper>
                        <Label labelId={`report.filtersModal.form.${name}Label`} />
                        <MultiSelect
                            isMulti={multiple}
                            options={possibleSelectValues}
                            placeholder={intl.formatMessage({ id: `report.filtersModal.form.${name}Label` })}
                            value={mapSelectedValuesToMultiSelectedOptionValues(possibleSelectValues, selectedValues)}
                            onChange={(multiSelectEvent) => {
                                const selectedOptionValues = multiSelectEvent as unknown as Option<number | string>[];
                                onChange(selectedOptionValues.map(convertSelectOptionToFormValue));
                            }}
                            onBlur={onBlur}
                        />
                    </SelectInputWrapper>
                );
            }}
        />
    );
};

export default SelectInput;
