import styled from '@emotion/styled';
import { Breadcrumb } from '@/components/atoms/Breadcrumb';

const MIN_HEIGHT = '60';

export const Container = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.color.white100};
    min-height: ${MIN_HEIGHT}px;
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
    align-items: center;
    justify-content: flex-end;
    box-shadow: ${({ theme }) => theme.shadow.legacy};
    padding: 0 ${({ theme }) => theme.spacing[4]};
    transition: margin-top 0.2s ease;

    &.navigation-bar-enter {
        margin-top: -${MIN_HEIGHT}px;
    }

    &.navigation-bar-enter-active {
        margin-top: 0;
    }

    &.navigation-bar-exit {
        margin-top: 0;
    }

    &.navigation-bar-exit-active {
        margin-top: -${MIN_HEIGHT}px;
    }
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
    flex: 1;
`;

export const Crumb = styled.span`
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.black};
    }
`;
