import { Paginated } from '../../request.types';

export interface GetTransactionsResponse {
    total: number;
    transactions: TransactionResponse[];
}

export interface TransactionResponse {
    id: number;
    reference: string;
    type: TransactionTypes;
    paymentMethodType: PaymentMethodTypes;
    channel: TransactionChannel;
    status: TransactionStatus;
    source?: number;

    brand: {
        id: number;
        name: string;
    };

    restaurant?: {
        id: number;
        name: string;
    };

    customer: {
        id: number;
        badgeNumber?: string;
        name: string;
        email: string;
    };

    payment: {
        amount: number;
        currency: string;
        method: number | null;
        fee: number;
    };

    reversal: {
        amount: number;
    };

    transfer?: {
        id: number;
        reference: string;
    };

    web?: {
        ip?: string;
        referer?: string;
        userAgent?: string;
    };

    order?: {
        id: number;
    };

    error?: string;

    createdAt: string;
    updatedAt: string;
}

export interface GetTransactionsPayload extends Required<Paginated> {
    brandId?: number;
    restaurantIds?: number[];

    customerId?: number;
    search?: string;
    filters?: GetTransactionsFilters;
}

export interface GetSingleTransactionPayload {
    transactionId: number;
    brandId: number;
}

export type GetTransactionsFilters = Record<string, string>;

export enum TransactionTypes {
    CAPTURE = 1,
    REFUND = 2,
}

export enum TransactionChannel {
    WEB = 1,
    PROXIMITY = 2,
}

export enum TransactionStatus {
    /** means pending or timeout */
    PENDING = 0,
    PENDING_3DS = 1,
    DONE = 2,
    FAILED = 3,
    ERROR = 4,
}

export enum TransactionSources {
    WEBORDERING = 2,
    EWALLET = 3,
    KIOSK = 6,
    NOTIFICATION = 10,
}

export enum PaymentMethodTypes {
    BANK_CARD = 1,
    MEAL_VOUCHER = 2,
}
