import React, { useState } from 'react';

import { computeText } from '@/locales/utils';
import { Input } from '@/components/atoms/Form/Input';
import DeleteModal from '@/components/atoms/DeleteModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { ActionBar } from '@/components/atoms/ActionBar';
import { Button } from '@/components/atoms/Button';
import { IOPayConfig } from '@/services/innovorder/io-pay/configurations/configurations.type';
import { ConfigContainer } from '../../common/style/Common.style';
import { FormCard } from './IOPayForm.style';
import IOPayPanel from '../IOPayPanel';

export type IOPayInputs = {
    name: string;
    fixOnlineCommissionAmount: number;
    variableOnlineCommissionPercent: number;
    fixProximityCommissionAmount: number;
    variableProximityCommissionPercent: number;
    accountHolder: string;
    balanceAccount: string;
    storeId: string;
    transferInstrumentId: string;
};

type IOPayFormProps = {
    config?: IOPayConfig;
    onSubmit: (data: IOPayInputs) => void;
    onDelete: () => void;
    error: string;
    loading: boolean;
    loadingDelete: boolean;
    withCommissions: boolean;
};

const IOPayForm: React.FunctionComponent<React.PropsWithChildren<IOPayFormProps>> = ({
    config,
    onSubmit,
    onDelete,
    error,
    loading,
    loadingDelete,
    withCommissions,
}) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const intl = useIntl();
    const DEFAULT_COMMISSION_PERCENT = 1.4;
    const DEFAULT_COMMISSION_FIX_AMOUNT = 25;

    const { control, handleSubmit, clearErrors, trigger } = useForm<IOPayInputs>({
        defaultValues: {
            accountHolder: config?.adyen?.accountHolder,
            balanceAccount: config?.adyen?.balanceAccount,
            name: config?.name,
            fixOnlineCommissionAmount: config?.fixOnlineCommissionAmount || DEFAULT_COMMISSION_FIX_AMOUNT,
            fixProximityCommissionAmount: config?.fixProximityCommissionAmount || DEFAULT_COMMISSION_FIX_AMOUNT,
            variableOnlineCommissionPercent: config?.variableOnlineCommissionPercent || DEFAULT_COMMISSION_PERCENT,
            variableProximityCommissionPercent:
                config?.variableProximityCommissionPercent || DEFAULT_COMMISSION_PERCENT,
            storeId: config?.adyen?.storeId,
            transferInstrumentId: config?.adyen?.transferInstrumentId,
        },
    });

    const handleChange =
        (optionName: keyof IOPayInputs, onChange: (...event: any[]) => void, onBlur: () => void) =>
        (options: boolean | React.ChangeEvent<HTMLInputElement>) => {
            onBlur();
            clearErrors(optionName);
            onChange(options);
            trigger(optionName);
        };

    const handleShowModal = (): void => setShowDeleteModal(true);
    const handleCloseModal = (): void => setShowDeleteModal(false);
    const handleConfirmDelete = (): void => {
        onDelete();
        handleCloseModal();
    };

    return (
        <>
            <ConfigContainer>
                <IOPayPanel text="iopay.configuration.title" />
                <FormCard>
                    <Controller
                        control={control}
                        name="name"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                            <Input
                                error={e?.message}
                                onChange={handleChange('name', onChange, onBlur)}
                                onBlur={handleChange('name', onChange, onBlur)}
                                placeholder="iopay.form.placeholder.name"
                                labelId="iopay.form.name"
                                value={value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="accountHolder"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                            <Input
                                error={e?.message}
                                onChange={handleChange('accountHolder', onChange, onBlur)}
                                onBlur={handleChange('accountHolder', onChange, onBlur)}
                                placeholder="iopay.form.placeholder.accountHolder"
                                labelId="iopay.form.accountHolder"
                                value={value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="balanceAccount"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                            <Input
                                error={e?.message}
                                onChange={handleChange('balanceAccount', onChange, onBlur)}
                                onBlur={handleChange('balanceAccount', onChange, onBlur)}
                                placeholder="iopay.form.placeholder.balanceAccount"
                                labelId="iopay.form.balanceAccount"
                                value={value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="storeId"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                            <Input
                                error={e?.message}
                                onChange={handleChange('storeId', onChange, onBlur)}
                                onBlur={handleChange('storeId', onChange, onBlur)}
                                placeholder="iopay.form.placeholder.storeId"
                                labelId="iopay.form.storeId"
                                value={value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="transferInstrumentId"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                            <Input
                                error={e?.message}
                                onChange={handleChange('transferInstrumentId', onChange, onBlur)}
                                onBlur={handleChange('transferInstrumentId', onChange, onBlur)}
                                placeholder="iopay.form.placeholder.transferInstrumentId"
                                labelId="iopay.form.transferInstrumentId"
                                value={value}
                            />
                        )}
                    />
                </FormCard>
                {withCommissions && (
                    <>
                        <IOPayPanel text="iopay.commissions.online.title" />
                        <FormCard>
                            <Controller
                                control={control}
                                name="variableOnlineCommissionPercent"
                                rules={{ required: computeText(intl, 'field.required') }}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                                    <Input
                                        error={e?.message}
                                        onChange={handleChange('variableOnlineCommissionPercent', onChange, onBlur)}
                                        onBlur={handleChange('variableOnlineCommissionPercent', onChange, onBlur)}
                                        type="number"
                                        placeholder="iopay.form.placeholder.variableOnlineCommissionPercent"
                                        labelId="iopay.form.variableOnlineCommissionPercent"
                                        value={value?.toString()}
                                        unitType="percentage"
                                        min="0"
                                        step="0.01"
                                        name="variableOnlineCommissionPercent"
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="fixOnlineCommissionAmount"
                                rules={{ required: computeText(intl, 'field.required') }}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                                    <Input
                                        error={e?.message}
                                        onChange={handleChange('fixOnlineCommissionAmount', onChange, onBlur)}
                                        onBlur={handleChange('fixOnlineCommissionAmount', onChange, onBlur)}
                                        type="number"
                                        placeholder="iopay.form.placeholder.fixOnlineCommissionAmount"
                                        labelId="iopay.form.fixOnlineCommissionAmount"
                                        value={value?.toString()}
                                        unitType="currency"
                                        min="0"
                                    />
                                )}
                            />
                        </FormCard>
                        <IOPayPanel text="iopay.commissions.proximity.title" />
                        <FormCard>
                            <Controller
                                control={control}
                                name="variableProximityCommissionPercent"
                                rules={{ required: computeText(intl, 'field.required') }}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                                    <Input
                                        error={e?.message}
                                        onChange={handleChange('variableProximityCommissionPercent', onChange, onBlur)}
                                        onBlur={handleChange('variableProximityCommissionPercent', onChange, onBlur)}
                                        type="number"
                                        placeholder="iopay.form.placeholder.variableProximityCommissionPercent"
                                        labelId="iopay.form.variableProximityCommissionPercent"
                                        value={value?.toString()}
                                        unitType="percentage"
                                        min="0"
                                        step="0.01"
                                        name="variableProximityCommissionPercent"
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="fixProximityCommissionAmount"
                                rules={{ required: computeText(intl, 'field.required') }}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                                    <Input
                                        error={e?.message}
                                        onChange={handleChange('fixProximityCommissionAmount', onChange, onBlur)}
                                        onBlur={handleChange('fixProximityCommissionAmount', onChange, onBlur)}
                                        type="number"
                                        placeholder="iopay.form.placeholder.fixProximityCommissionAmount"
                                        labelId="iopay.form.fixProximityCommissionAmount"
                                        value={value?.toString()}
                                        unitType="currency"
                                        min="0"
                                    />
                                )}
                            />
                        </FormCard>
                    </>
                )}
            </ConfigContainer>
            <ActionBar error={error}>
                {config?.id && (
                    <Button buttonType="red" onClick={handleShowModal} isLoading={loadingDelete}>
                        <FormattedMessage id="button.delete" />
                    </Button>
                )}

                <Button buttonType="primary" onClick={handleSubmit(onSubmit)} isLoading={loading}>
                    <FormattedMessage id="button.save" />
                </Button>
            </ActionBar>
            <DeleteModal
                title="grant.modal.warning"
                description="grant.modal.confirmDelete"
                isOpen={showDeleteModal}
                onHide={handleCloseModal}
                onSubmit={handleConfirmDelete}
            />
        </>
    );
};

export default IOPayForm;
