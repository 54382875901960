import React, { useState, useMemo, ReactNode, useEffect } from 'react';

import { findAllByType } from '@/utils/ReactUtils';
import { TabProps } from './Tab';
import TabList from './TabList';
import RoutedTabList from './RoutedTabList';
import { Container, Growing } from './Tabs.style';

export type TabsProps = {
    defaultSelectedTabIndex?: number;
    style?: React.CSSProperties;
    selectedTabIndex?: number;
    onSelectTab?: (a: number) => void;
};

// TODO do not use `findAllByTypes` as it doesn't handle changes in children
const Tabs: React.FunctionComponent<React.PropsWithChildren<TabsProps>> = ({
    children,
    defaultSelectedTabIndex,
    selectedTabIndex,
    onSelectTab,
    ...otherProps
}) => {
    const [actualTab, setActualTab] = useState<number>(defaultSelectedTabIndex ?? 0);
    const selected = useMemo(() => selectedTabIndex || actualTab, [selectedTabIndex, actualTab]);
    const tabs: TabProps[] = findAllByType(children, 'Tab').map((tab) => tab.props as TabProps);
    const isRouted = !!tabs.find(({ route }) => !!route);

    const onSelect = (index: number) => {
        onSelectTab && onSelectTab(index);
        !onSelectTab && setActualTab(index);
    };

    return tabs.length > 0 ? (
        <Container {...otherProps}>
            {!isRouted && <TabList selected={selected} list={tabs} onSelect={onSelect} />}
            {isRouted && <RoutedTabList list={tabs} />}
            <Growing>{tabs[selected].component as ReactNode}</Growing>
        </Container>
    ) : null;
};

export default Tabs;
