import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ApiRequest } from '../../request.types';

export interface IOPayConfig {
    id: number;
    psp: number;
    adyen?: adyenResponse;
    brandId: number;
    name: string;
    channel: string;
    isEnabled: boolean;
    fixOnlineCommissionAmount: number;
    variableOnlineCommissionPercent: number;
    fixProximityCommissionAmount: number;
    variableProximityCommissionPercent: number;
    createdAt: string;
    updatedAt: string;
}
interface adyenResponse {
    accountHolder: string;
    balanceAccount: string;
    configurationId: number;
    storeId: string;
    transferInstrumentId: string;
}

export type DeleteConfigResponse = boolean;

export interface GetBrandConfigPayload {
    brandId?: number;
}

export interface GetRestaurantConfigPayload {
    restaurantId?: number;
}

export interface CreateConfigPayload {
    brandId?: number;
    name: string;
    accountHolder: string;
    balanceAccount: string;
    storeId: string;
    transferInstrumentId: string;
    fixOnlineCommissionAmount: number;
    fixProximityCommissionAmount: number;
    variableOnlineCommissionPercent: number;
    variableProximityCommissionPercent: number;
}

export interface UpdateConfigPayload extends CreateConfigPayload {
    configurationId?: number;
}

export interface DeleteConfigPayload {
    configurationId: number;
}

export interface ioPayParams {
    token?: string;
    brandId: number;
    restaurantId: number;
}

export enum GET_BY {
    BRAND = 'brand',
    RESTAURANT = 'restaurant',
}

export interface GetIOPayConfigResult {
    data?: any;
    isError: boolean;
    isLoading: boolean;
    isFetching: boolean;
    error?: FetchBaseQueryError | SerializedError | undefined;
    isSuccess: boolean;
    params?: any;
    getConfig:
        | ((arg: ApiRequest<GetBrandConfigPayload>) => void)
        | ((arg: ApiRequest<GetRestaurantConfigPayload>) => void);
}
