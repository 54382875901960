import { renderChannelIcon } from '@/components/atoms/Icon/utils';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OmnichannelOrder } from '@/services/innovorder/orders/order.type';
import {
    Container,
    CustomizationContainer,
    HeaderContainer,
    HeaderTextContainer,
    IconContainer,
    ItemContainer,
    MiscContainer,
    SubtitleContainer,
    TicketContainer,
    TitleContainer,
} from './OrderDetailsTicket.style';
import OrderDetailsTicketLine from './OrderDetailsTicketLine';
import { calculateSum, computeProductItemPrice, unflattenItems } from './utils';

type OrderDetailsTicketProps = {
    order: OmnichannelOrder;
};

const OrderDetailsTicket: React.FunctionComponent<React.PropsWithChildren<OrderDetailsTicketProps>> = ({ order }) => {
    const intl = useIntl();
    const outputItems = useMemo(() => unflattenItems(order.items), [order.items]);
    const taxes = useMemo(() => order.taxes.map((tax) => tax.taxRate), [order.taxes]);
    const grantTotal = useMemo(() => calculateSum(order.grants), [order.grants]);
    const entranceFeeTotal = useMemo(() => calculateSum(order.entranceFees), [order.entranceFees]);
    const feeTotal = useMemo(() => calculateSum(order.fees), [order.fees]);
    const itemTotal =
        order.totalPriceDiscountedWithTaxIncluded + grantTotal - feeTotal + order.totalDiscount - entranceFeeTotal;

    return (
        <Container>
            <HeaderContainer>
                <IconContainer>{renderChannelIcon(order.channelId)}</IconContainer>
                <HeaderTextContainer>
                    <TitleContainer>
                        {`#${order.dailyOrderId} `}
                        <FormattedMessage id={`order.consumptionMode.${order.consumptionModeId}`} />
                    </TitleContainer>
                    {order.marketplaceStoreName && (
                        <SubtitleContainer>
                            <FormattedMessage id={`order.channel.${order.channelId}`} />
                            {` - ${order.marketplaceStoreName}`}
                        </SubtitleContainer>
                    )}
                </HeaderTextContainer>
            </HeaderContainer>
            <TicketContainer>
                {outputItems.map((item, itemIndex) => (
                    <ItemContainer key={itemIndex}>
                        <OrderDetailsTicketLine
                            type="item"
                            quantity={item.quantity}
                            text={item.name}
                            price={computeProductItemPrice(item)}
                            tax={item.vatValue}
                            taxes={taxes}
                        />
                        {Boolean(item.customization.length) && (
                            <CustomizationContainer>
                                {item.customization.map((customization, customizationIndex) => (
                                    <div key={customizationIndex}>{customization.name}</div>
                                ))}
                            </CustomizationContainer>
                        )}
                    </ItemContainer>
                ))}
                <OrderDetailsTicketLine type="sum" text="orderDetail.ticket.itemSum" price={itemTotal} />

                {Boolean(order.entranceFees?.length) && (
                    <MiscContainer>
                        {order.entranceFees.map((fee) => (
                            <OrderDetailsTicketLine
                                key={fee.labelTicket}
                                type="misc"
                                text={fee.labelTicket}
                                price={fee.amount}
                                tax={fee.vat}
                                taxes={taxes}
                                noTranslation
                            />
                        ))}
                        <OrderDetailsTicketLine
                            type="sum"
                            text="orderDetail.ticket.entranceFeeSum"
                            price={entranceFeeTotal}
                        />
                    </MiscContainer>
                )}
                {Boolean(order.grants?.length) && (
                    <MiscContainer>
                        {order.grants.map((grant) => (
                            <OrderDetailsTicketLine
                                key={grant.labelTicket}
                                type="misc"
                                text={grant.labelTicket}
                                price={grant.amount}
                                noTranslation
                            />
                        ))}
                        <OrderDetailsTicketLine type="sum" text="orderDetail.ticket.grantSum" price={grantTotal} />
                    </MiscContainer>
                )}
                {Boolean(order.pricingRule?.code) && (
                    <MiscContainer>
                        <OrderDetailsTicketLine
                            type="sum"
                            text="orderDetail.ticket.pricingRule"
                            code={order.pricingRule?.code}
                        />
                    </MiscContainer>
                )}
                {Boolean(order.discounts?.length || Boolean(order.totalDiscount)) && (
                    <MiscContainer>
                        {order.discounts.map((discount) => (
                            <OrderDetailsTicketLine
                                key={discount.name}
                                type="misc"
                                text={intl.formatMessage({ id: 'orderDetail.ticket.discount' })}
                                code={discount.name}
                                noTranslation
                            />
                        ))}
                        <OrderDetailsTicketLine
                            type="sum"
                            text="orderDetail.ticket.discountSum"
                            price={order.totalDiscount}
                        />
                    </MiscContainer>
                )}
                {Boolean(order.fees?.length) && (
                    <MiscContainer>
                        {order.fees.map((fee) => (
                            <OrderDetailsTicketLine
                                key={fee.name}
                                type="misc"
                                quantity={fee.quantity}
                                text={fee.name}
                                price={fee.amount}
                                tax={fee.taxRate}
                                taxes={taxes}
                                noTranslation
                            />
                        ))}
                    </MiscContainer>
                )}
                <OrderDetailsTicketLine
                    type="finalprice"
                    text="orderDetail.ticket.finalPrice"
                    price={order.totalPriceDiscountedWithTaxIncluded}
                />
            </TicketContainer>
        </Container>
    );
};

export default OrderDetailsTicket;
