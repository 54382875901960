import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';

import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Badge, BadgeProps } from '@/components/atoms/Badge/Badge';
import { Container, StyledDate, StyledTitle, StyledValue } from './HeaderKpi.style';

type HeaderKpiProps = {
    title: string;
    text: string;
    values?: Record<string, string | number | Date>;
    date?: Date;
    badge?: BadgeProps;
};

const HeaderKpi: React.FunctionComponent<React.PropsWithChildren<HeaderKpiProps>> = ({
    title,
    text,
    values,
    date,
    badge,
}) => {
    const theme = useTheme();

    return (
        <Container>
            <StyledTitle text={title} />
            {badge ? (
                <Badge text={badge.text} backgroundColor={badge.backgroundColor} />
            ) : (
                <StyledValue text={text} values={values} />
            )}
            {date && (
                <StyledDate>
                    <SVGIcon icon={SVGIcons.CALENDAR} size={16} color={theme.color.black} />
                    <FormattedMessage id="orderDetail.kpi.date" values={{ date: date as unknown as React.ReactNode }} />
                </StyledDate>
            )}
        </Container>
    );
};

export default HeaderKpi;
