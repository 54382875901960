import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';

import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Container, StyledDate, StyledTitle, StyledValue } from './OrderDetailsHeaderKpi.style';

type OrderDetailsHeaderKpiProps = {
    title: string;
    text: string;
    values?: Record<string, string | number | Date>;
    date?: Date;
};

const OrderDetailsHeaderKpi: React.FunctionComponent<React.PropsWithChildren<OrderDetailsHeaderKpiProps>> = ({
    title,
    text,
    values,
    date,
}) => {
    const theme = useTheme();
    return (
        <Container>
            <StyledTitle text={title} />
            <StyledValue text={text} values={values} />
            {date && (
                <StyledDate>
                    <SVGIcon icon={SVGIcons.CALENDAR} size={16} color={theme.color.black} />
                    <FormattedMessage id="orderDetail.kpi.date" values={{ date: date as unknown as React.ReactNode }} />
                </StyledDate>
            )}
        </Container>
    );
};

export default OrderDetailsHeaderKpi;
