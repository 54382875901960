import { FilterConfig } from '@/services/innovorder/report/report.type';
import React from 'react';
import { assertUnreachable } from '@/utils/typescript';
import SelectInput from './SelectInput';
import ToggleInput from './ToggleInput';

interface FilterInputProps {
    filterConfig: FilterConfig;
}

const FilterInput: React.FunctionComponent<FilterInputProps> = ({ filterConfig }) => {
    const { type } = filterConfig;
    switch (type) {
        case 'select':
            return <SelectInput selectConfig={filterConfig} />;
        case 'toggle':
            return <ToggleInput toggleConfig={filterConfig} />;
        default:
            assertUnreachable(type);
            return null;
    }
};

export default FilterInput;
