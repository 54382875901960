import React from 'react';

import { OmnichannelOrder } from '@/services/innovorder/orders/order.type';
import { Container, DeviceContainer, ItemContainer, StyledLabel, StyledTitle } from './OrderDetailsPos.style';

type OrderDetailsPosProps = Pick<OmnichannelOrder, 'seller' | 'deviceName' | 'deviceId'> & {
    restaurant: string;
};

const OrderDetailsPos: React.FunctionComponent<React.PropsWithChildren<OrderDetailsPosProps>> = ({
    seller,
    deviceName,
    deviceId,
    restaurant,
}) => {
    return (
        <Container>
            {restaurant && (
                <ItemContainer>
                    <StyledLabel text="orderDetail.Pos.restaurant" />
                    <StyledTitle type="small" weight="medium" text={restaurant} noTranslation />
                </ItemContainer>
            )}
            {seller && (
                <ItemContainer>
                    <StyledLabel text="orderDetail.Pos.seller" />
                    <StyledTitle type="small" weight="medium" text={seller} noTranslation />
                </ItemContainer>
            )}
            <DeviceContainer>
                <StyledLabel text="orderDetail.Pos.deviceName" />
                <StyledTitle type="small" weight="medium" text={deviceName} noTranslation />
            </DeviceContainer>
            <DeviceContainer>
                <StyledLabel text="orderDetail.Pos.deviceId" />
                <StyledTitle type="small" weight="medium" text={deviceId} noTranslation />
            </DeviceContainer>
        </Container>
    );
};

export default OrderDetailsPos;
