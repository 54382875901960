import { Placement } from '@popperjs/core';
import en from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useIntl } from 'react-intl';

import { CssOverrides } from './Calendar.style';
import CalendarDay from './CalendarDay/CalendarDay';
import CalendarInput from './CalendarInput';

const formatDate = (date: Date | null): string => {
    return date && dayjs(date).isValid() ? `${dayjs(date).format('DD MMM')}.` : '';
};

type Period = Date | (Date | null)[] | null;

export type CalendarProps = {
    popperPlacement?: Placement;
    excludeDates?: Date[];
    startDate?: Date | null;
    minDate?: Date | null;
    maxDate?: Date | null;
    endDate?: Date | null;
    openToDate?: Date;
    onChange?: (dates: { startDate: Date | null; endDate: Date | null }) => unknown;
    open?: boolean;
    monthShown?: number;
    inline?: boolean;
    selectsRange?: boolean;
    disabled?: boolean;
};

const renderDay = (day: number) => <CalendarDay day={day} />;

const Calendar: React.FunctionComponent<React.PropsWithChildren<CalendarProps>> = ({
    popperPlacement,
    excludeDates,
    startDate,
    endDate,
    minDate,
    maxDate,
    openToDate,
    onChange,
    open,
    monthShown = 1,
    inline = false,
    selectsRange = false,
    disabled = false,
    ...otherProps
}) => {
    const [period, setPeriod] = useState({
        start: startDate || null,
        end: endDate || null,
    });

    useEffect(() => {
        setPeriod({
            start: startDate || null,
            end: endDate || null,
        });
    }, [startDate, endDate]);

    const handleChange = useCallback(
        (range: Period) => {
            if (disabled) return;
            const [start, end] = Array.isArray(range) ? range : [range, null];
            setPeriod({
                end,
                start,
            });
            if (onChange) onChange({ startDate: start, endDate: end });
        },
        [onChange, disabled],
    );

    const customInput = open === true ? <></> : <CalendarInput />;
    const displayedValue = formatDate(period.start) + (period.end ? ` - ${formatDate(period.end)}` : '');

    const { locale } = useIntl();
    switch (locale) {
        case 'fr': {
            registerLocale('fr', fr);
            break;
        }
        case 'en': {
            registerLocale('en', en);
            break;
        }
        default: {
            registerLocale('fr', fr);
            break;
        }
    }

    return (
        <CssOverrides {...otherProps}>
            <DatePicker
                selected={period.start}
                onChange={handleChange}
                locale={locale}
                showPopperArrow={false}
                startDate={period.start}
                endDate={period.end}
                monthsShown={monthShown}
                popperPlacement={popperPlacement}
                excludeDates={excludeDates}
                selectsRange={selectsRange}
                customInput={customInput}
                inline={inline}
                renderDayContents={renderDay}
                shouldCloseOnSelect={false}
                value={displayedValue}
                disabledKeyboardNavigation
                minDate={minDate}
                maxDate={maxDate}
                openToDate={openToDate}
                open={open}
                disabled={disabled}
            />
        </CssOverrides>
    );
};

export default Calendar;
