import styled from '@emotion/styled';
import { Title } from '@/components/atoms/Title';

export const Container = styled.div`
    flex: 1;
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    margin: 20px;
    padding: 20px;
`;

export const TitleContainer = styled.div`
    display: flex;

    & > h2:nth-of-type(2) {
        margin-left: 5px;
    }
`;

export const CustomTitle = styled(Title)`
    margin-bottom: 10px;
`;

export const MargedTitle = styled(Title)`
    margin-bottom: 20px;
`;

export const FlexContainer = styled.div`
    display: flex;
    margin-bottom: 80px;
`;

export const FormContainer = styled.div`
    flex: 1;
    padding: 20px;
    width: 600px;
    background: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: ${({ theme }) => theme.radius.main};

    & > *:not(:last-of-type) {
        margin-bottom: 20px;
    }
`;

export const SpacedFlex = styled.div`
    display: flex;

    & > * {
        flex: 1;
    }

    & > :first-of-type {
        margin-right: 20px;
    }
`;

export const WarningFormContainer = styled.div`
    margin-top: 10px;
    & > * {
        color: ${({ theme }) => theme.color.red300};
    }
`;

export const GrantRestaurantsContainer = styled.div<{ useShift: boolean; isValid: boolean }>`
    ${({ useShift, isValid }) =>
        useShift &&
        !isValid &&
        `opacity: 0.2;
    user-select: none;
    pointer-events: none;`}
`;
