import React from 'react';

import MarketplaceLogo from '@/pages/Config/common/MarketplaceLogo';
import { TitleContainer, PanelTitle, PanelText, PanelSubTitle } from './UbereatsPanel.style';

const UbereatsPanel: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <div>
            <TitleContainer>
                <MarketplaceLogo name="ubereats" />
                <PanelTitle text="ubereats.title" />
            </TitleContainer>
            <PanelText text="ubereats.description.firstpart" />
            <PanelSubTitle text="ubereats.description.secondpart" weight="bold" />
            <PanelText text="ubereats.description.thirdpart" />
        </div>
    );
};

export default UbereatsPanel;
