import React from 'react';
import { TitleContainer, PanelTitle, PanelText } from './CrousPanel.style';

const CrousPanel: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <div>
            <TitleContainer>
                <PanelTitle text="crous.title" />
            </TitleContainer>
            <PanelText text="crous.description" />
        </div>
    );
};

export default CrousPanel;
