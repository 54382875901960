import Loader from '@/components/atoms/Loader';
import { SavingBar } from '@/components/atoms/SavingBar';
import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import { stringifyError } from '@/utils/errors';
import { useGetLocationsQuery, usePutLocationsMutation } from '@/services/innovorder/marketplace/marketplace.endpoints';
import { Location, MarketplaceChannelId } from '@/services/innovorder/marketplace/marketplace.type';
import { getUserToken } from '@/redux/user';

import ConfigurationPanel from '../common/ConfigurationPanel';
import { ConfigContainer } from '../common/style/Common.style';
import DeliverectPanel from './DeliverectPanel';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';

const DeliverectConfig: React.FunctionComponent<React.PropsWithChildren> = () => {
    const token = useSelector(getUserToken);
    const { brandId, restaurantId } = useParams<{ brandId: string; restaurantId: string }>();
    const [currentLocations, setCurrentLocations] = useImmer<Location[]>([]);
    const [changes, setChanges] = useState(false);
    const intl = useIntl();
    const {
        data,
        isLoading,
        error: loadError,
    } = useGetLocationsQuery({ brandId, restaurantId, token, marketplace: 'deliverect' });
    const [putLocations, { isLoading: isSaving, error: saveError }] = usePutLocationsMutation();
    const theme = useTheme();

    const initLocations = useCallback(() => {
        if (!isLoading && data) {
            const formattedLocations = data
                .map((location) => ({
                    id: location.storeReference,
                    name: location.storeDisplayName || computeText(intl, 'configuration.error.unaccessible'),
                    marketplaces: location.channels.map((channel) => ({
                        id: channel.channelId,
                        active: channel.isEnabled,
                    })),
                }))
                .sort((prev, next) => prev.name.localeCompare(next.name));
            setCurrentLocations(formattedLocations);
            setChanges(false);
        }
    }, [setCurrentLocations, data, isLoading]);

    const addLocation = useCallback(
        (storeReference: string, storeName: string) => {
            setCurrentLocations((draft) => {
                draft.push({
                    id: storeReference,
                    name: storeName,
                    marketplaces: [
                        {
                            id: MarketplaceChannelId.UberEats,
                            active: true,
                            enable: true,
                        },
                        {
                            id: MarketplaceChannelId.Deliveroo,
                            active: true,
                            enable: true,
                        },
                        {
                            id: MarketplaceChannelId.JustEat,
                            active: true,
                            enable: true,
                        },
                    ],
                });
            });
            setChanges(true);
        },
        [setCurrentLocations],
    );

    const deleteLocation = useCallback(
        (locationId: string) => {
            setCurrentLocations((draft) => draft.filter(({ id }) => id !== locationId));
            setChanges(true);
        },
        [setCurrentLocations],
    );

    const toggleMarketplace = useCallback(
        (locationId: string, marketplaceId: MarketplaceChannelId) => {
            setCurrentLocations((draft) => {
                const location = draft.find(({ id }) => id === locationId);
                if (!location) return;

                const marketplace = location.marketplaces.find(({ id }) => id === marketplaceId);
                if (marketplace) {
                    marketplace.active = !marketplace.active;
                } else {
                    location.marketplaces.push({
                        id: marketplaceId,
                        active: true,
                    });
                }
            });
            setChanges(true);
        },
        [setCurrentLocations],
    );

    const save = () => {
        if (isSaving) return;

        const formattedLocations = currentLocations.map((location) => ({
            storeReference: location.id,
            channels: location.marketplaces.map((channel) => ({
                channelId: channel.id,
                isEnabled: Boolean(channel.active),
            })),
        }));
        putLocations({ brandId, restaurantId, token, locations: formattedLocations, marketplace: 'deliverect' });
    };

    useEffect(() => {
        initLocations();
    }, [initLocations]);

    if (isLoading) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <>
            <ConfigContainer>
                <DeliverectPanel />
                <ConfigurationPanel
                    locations={currentLocations}
                    addLocation={addLocation}
                    deleteLocation={deleteLocation}
                    toggleMarketplace={toggleMarketplace}
                    marketplace="deliverect"
                />
            </ConfigContainer>
            <SavingBar
                onSave={save}
                error={stringifyError(loadError) || stringifyError(saveError)}
                loading={isSaving}
                changesCount={changes ? 1 : 0}
                displayChangesCount={false}
                onCancel={initLocations}
            />
        </>
    );
};

export default DeliverectConfig;
