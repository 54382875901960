import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';

import { stringifyError } from '@/utils/errors';
import {
    useUpdatePaygreenConfigDeprecatedMutation,
    useDeletePaygreenConfigDeprecatedMutation,
    useLazyGetPaygreenConfigDeprecatedQuery,
} from '@/services/innovorder/paygreen/configurations_deprecated/configurations.endpoints';
import { getUserToken } from '@/redux/user';
import {
    useCreatePaygreenConfigMutation,
    useDeletePaygreenConfigMutation,
    useLazyGetPaygreenConfigQuery,
    useUpdatePaygreenConfigMutation,
} from '@/services/innovorder/paygreen/configurations/configurations.endpoints';
import { getUseMigratedPaygreenV3 } from '@/redux/launchDarkly';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import PaygreenForm, { PaygreenInputs } from './PaygreenForm';
import { Loader } from './PaygreenConfig.style';

const PaygreenConfig: React.FunctionComponent = () => {
    const theme = useTheme();
    const token = useSelector(getUserToken);
    const useMigratedPaygreenV3 = useSelector(getUseMigratedPaygreenV3);
    const { brandId, restaurantId } = useParams<{ brandId: string; restaurantId: string }>();
    const params = {
        brandId: Number(brandId),
        restaurantId: Number(restaurantId),
    };

    // Get config from IO DB (deprecated, has to be removed after Paygreen has been totally migrated to Payment service and to v3)
    const [
        getConfigDeprecated,
        { data: dataDeprecated, error: loadErrorDeprecated, isFetching: isFetchingDeprecated },
    ] = useLazyGetPaygreenConfigDeprecatedQuery();
    const [updateConfigDeprecated, { error: updateErrorDeprecated, isLoading: isSavingUpdateDeprecated }] =
        useUpdatePaygreenConfigDeprecatedMutation();
    const [deleteConfigDeprecated, { isLoading: isSavingDeleteDeprecated }] =
        useDeletePaygreenConfigDeprecatedMutation();

    // Get config from Payment DB
    const [getConfig, { data, error: loadError, isFetching }] = useLazyGetPaygreenConfigQuery();
    const [createConfig, { error: createError, isLoading: isCreating }] = useCreatePaygreenConfigMutation();
    const [updateConfig, { error: updateError, isLoading: isUpdating }] = useUpdatePaygreenConfigMutation();
    const [deleteConfig, { isLoading: isDeleting }] = useDeletePaygreenConfigMutation();

    useEffect(() => {
        getConfigDeprecated({ token, restaurantId: params.restaurantId });
        getConfig({ token, restaurantId: params.restaurantId });
    }, [getConfigDeprecated, getConfig, token, params.restaurantId]);

    const onSubmit = async (submitdata: PaygreenInputs) => {
        if (dataDeprecated?.modulePaygreenId) {
            await updateConfigDeprecated({
                token,
                modulePaygreenId: dataDeprecated.modulePaygreenId,
                label: submitdata.label,
                enablePaygreenV3: submitdata.enablePaygreenV3,
                // Paygreen V2
                publicKey: !submitdata.enablePaygreenV3 ? submitdata.publicKey : undefined,
                privateKey: !submitdata.enablePaygreenV3 ? submitdata.privateKey : undefined,
                supportsLuncheon: !submitdata.enablePaygreenV3 ? submitdata.supportsLuncheon : false,
                supportsRestoflash: !submitdata.enablePaygreenV3 ? submitdata.supportsRestoflash : false,
                supportsSwile: !submitdata.enablePaygreenV3 ? submitdata.supportsRestoflash : false,
                // Paygreen V3
                secretKey: submitdata.enablePaygreenV3 ? submitdata.secretKey : undefined,
                shopId: submitdata.enablePaygreenV3 ? submitdata.shopId : undefined,
                subShopId: submitdata.enablePaygreenV3 ? submitdata.subShopId : undefined,
            });
            getConfigDeprecated({ token, restaurantId: params.restaurantId });
        }

        if (!data?.id) {
            await createConfig({
                token,
                ...params,
                name: submitdata.label,
                shopId: submitdata.shopId,
                secretKey: submitdata.secretKey,
                marketplaceSubshopId: submitdata.subShopId,
            });
            getConfig({ token, restaurantId: params.restaurantId });
        }

        if (data?.id) {
            await updateConfig({
                token,
                ...params,
                configurationId: data.id,
                name: submitdata.label,
                shopId: submitdata.shopId,
                secretKey: submitdata.secretKey,
                marketplaceSubshopId: submitdata.subShopId,
            });
            getConfig({ token, restaurantId: params.restaurantId });
        }
    };

    const onDelete = async () => {
        if (dataDeprecated) {
            await deleteConfigDeprecated({
                token,
                modulePaygreenId: dataDeprecated.modulePaygreenId,
                restaurantId: params.restaurantId,
            });
        }

        if (data) {
            await deleteConfig({
                token,
                configurationId: data.id,
            });
        }

        const integrationPagePath = `/home/brands/${params.brandId}/restaurants/${params.restaurantId}/integrations`;
        redirectToBackofficeV1(integrationPagePath);
    };

    if (isFetchingDeprecated || isFetching) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    if (!dataDeprecated || !data) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <PaygreenForm
            config={{
                ...dataDeprecated,
                ...(useMigratedPaygreenV3
                    ? {
                          shopId: data.paygreen?.shopId,
                          secretKey: data.paygreen?.secretKey,
                          subShopId: data.paygreen?.marketplaceSubshopId,
                      }
                    : {}),
            }}
            onDelete={onDelete}
            onSubmit={onSubmit}
            error={
                stringifyError(loadError) ||
                stringifyError(createError) ||
                stringifyError(updateError) ||
                stringifyError(loadErrorDeprecated) ||
                stringifyError(updateErrorDeprecated)
            }
            loading={isSavingUpdateDeprecated || isCreating || isUpdating}
            loadingDelete={isSavingDeleteDeprecated || isDeleting}
        />
    );
};

export default PaygreenConfig;
