import groupBy from 'lodash/groupBy';
import { ApiTagTypes, reportV2Api } from '../index';
import { ApiResponse } from '../request.types';
import {
    CreateReportExportRequestPayload,
    CreateUserSavedReportFilterPayload,
    DeleteUserSavedReportFilterPayload,
    FilterConfig,
    GetReportDataPayload,
    GetReportFiltersPayload,
    GetUserSavedReportFilterPayload,
    ReportMetadata,
    SavedReportFilter,
} from './report.type';

export const REPORT_V2_PREFIX = '/reports/v2';

const reportGenerationApi = reportV2Api.injectEndpoints({
    endpoints: (build) => ({
        getReportsMeta: build.query<ReportMetadata[], Record<string, never>>({
            query: () => ({
                url: `${REPORT_V2_PREFIX}/meta`,
                method: 'GET',
            }),
            providesTags: [ApiTagTypes.DynamicReport],
            transformResponse: (response: ApiResponse<ReportMetadata[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getUserSavedReportFilters: build.query<Record<string, SavedReportFilter[]>, GetUserSavedReportFilterPayload>({
            query: ({ userId }) => ({
                url: `users/${userId}/report_filters`,
                method: 'GET',
            }),
            providesTags: [ApiTagTypes.DynamicReportSavedFilters],
            transformResponse: (response: ApiResponse<SavedReportFilter[]>) => groupBy(response.data, 'reportType'),
            extraOptions: { maxRetries: 3 },
        }),
        getReportData: build.query<string, GetReportDataPayload>({
            query: ({ dataUrl, params }) => ({
                url: dataUrl,
                method: 'GET',
                params,
            }),
            providesTags: [ApiTagTypes.DynamicReport],
            transformResponse: (response: ApiResponse<string>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getReportFilters: build.query<FilterConfig[], GetReportFiltersPayload>({
            query: ({ filtersUrl }) => ({
                url: filtersUrl,
                method: 'GET',
            }),
            providesTags: [ApiTagTypes.DynamicReport],
            transformResponse: (response: ApiResponse<FilterConfig[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        createReportExportRequest: build.mutation<ApiResponse<never>, CreateReportExportRequestPayload>({
            query: ({ exportUrl, params }) => ({
                url: exportUrl,
                method: 'GET',
                params,
            }),
            extraOptions: { maxRetries: 3 },
        }),
        saveUserReportFilter: build.mutation<SavedReportFilter, CreateUserSavedReportFilterPayload>({
            query: ({ userId, body }) => ({
                url: `users/${userId}/report_filters`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [ApiTagTypes.DynamicReportSavedFilters],
            transformResponse: (response: ApiResponse<SavedReportFilter>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteUserReportFilter: build.mutation<ApiResponse<never>, DeleteUserSavedReportFilterPayload>({
            query: ({ userId, reportFilterId }) => ({
                url: `users/${userId}/report_filters/${reportFilterId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [ApiTagTypes.DynamicReportSavedFilters],
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetReportsMetaQuery,
    useGetReportDataQuery,
    useGetReportFiltersQuery,
    useCreateReportExportRequestMutation,
    useGetUserSavedReportFiltersQuery,
    useSaveUserReportFilterMutation,
    useDeleteUserReportFilterMutation,
} = reportGenerationApi;
