import styled from '@emotion/styled';

export const TitleContainer = styled.div`
    margin: ${({ theme }) => theme.spacing[4]} 0px 0px ${({ theme }) => theme.spacing[4]};
`;

export const Container = styled.div`
    heigth: 100%;
    flex-wrap: wrap;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    margin: ${({ theme }) => theme.spacing[4]};
    display: grid;
    grid-template-columns: 33% 33% 33%;
`;

export const Item = styled.div`
    margin: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[4]};
`;
