import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { SupportedLanguages, getLocale } from '@/locales';
import { COOKIE_PATH, computeCookieDomain } from '@/pages/Login/Login';
import { getLanguage, setLanguage } from '@/redux/app';
import { getBrand } from '@/redux/brand';
import { getUser } from '@/redux/user';
import { UserRoles } from '@/services/innovorder/user/user.type';

const locales = {
    fr: import('dayjs/locale/fr'),
    en: import('dayjs/locale/en'),
    sodexo: import('dayjs/locale/fr'),
};

const setDayJsLocale = (language: keyof typeof locales) => {
    locales[language].then(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dayjs.locale({ ...language, weekStart: 1 });
    });
};

export const localeCookieLocation = 'innovorder.NG_TRANSLATE_LANG_KEY';

export const useLocaleHandler = (): {
    currentLocale: string;
    messages: Record<string, string>;
    supportedLanguages: SupportedLanguages[];
} => {
    const dispatch = useDispatch();
    const currentLanguage = useSelector(getLanguage);
    const locale = getLocale();
    const [currentLocale, setCurrentLocale] = useState<string>(locale);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [messages, setMessages] = useState<any>();
    const [cookies, setCookies] = useCookies();
    const brand = useSelector(getBrand);
    const user = useSelector(getUser);
    const supportedLanguages = useMemo(() => {
        const isSodexoDictionaryEnabled = brand?.options?.ENABLE_SODEXO_DICTIONARY?.isActivated;
        return Object.values(SupportedLanguages)
            .filter(
                (language) =>
                    language !== SupportedLanguages.sodexo ||
                    (language === SupportedLanguages.sodexo &&
                        isSodexoDictionaryEnabled &&
                        user?.role !== UserRoles.admin),
            )
            .map((language) => language);
    }, [brand?.options, user?.role]);
    const localeCookie = cookies[localeCookieLocation];

    const loadLocale = useCallback(
        async (newLocale: keyof typeof locales) => {
            if (!supportedLanguages.includes(newLocale as SupportedLanguages)) newLocale = SupportedLanguages.fr;
            setCurrentLocale(newLocale);
            const defaultMessages = await import(/* @vite-ignore */ `../../locales/${newLocale}.ts`);
            setMessages(defaultMessages);

            setDayJsLocale(newLocale);
        },
        [supportedLanguages],
    );

    useEffect(() => {
        dispatch(setLanguage(localeCookie || locale));
    }, [dispatch, locale, localeCookie]);

    useEffect(() => {
        if (currentLanguage) {
            loadLocale(currentLanguage);
            setCookies(localeCookieLocation, currentLanguage, {
                domain: computeCookieDomain(window.location.host),
                path: COOKIE_PATH,
            });
        }
    }, [currentLanguage, supportedLanguages, loadLocale, setCookies]);

    return { currentLocale, messages: messages?.default, supportedLanguages };
};
