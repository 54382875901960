import * as React from 'react';
import { usePostMenuMutation } from '@/services/innovorder/menu/menu.endpoints';
import { useGetPosDevicesQuery } from '@/services/innovorder/posDevice/posDevice.endpoints';
import { getUsePosMenuFastImport } from '@/redux/launchDarkly';
import { useSelector } from 'react-redux';
import { safeMutate } from '@/utils/errors';
import { useTheme } from '@emotion/react';
import pLimit from 'p-limit';
import { FormValues, ImportPOSMenuModalProps } from './ImportPOSMenuModal.interface';

const MIN_POS_VERSION = 7;
const limit = pLimit(20);
export const useImportPOSMenuModalVm = ({ brandId, restaurantId }: ImportPOSMenuModalProps) => {
    const theme = useTheme();

    const shouldShow = useSelector(getUsePosMenuFastImport);
    const posDeviceResponse = useGetPosDevicesQuery({
        brandId,
        restaurantId,
    });

    const posDeviceList = React.useMemo(() => {
        return (posDeviceResponse.data ?? [])?.filter(
            (posDevice) =>
                !posDevice.menuId &&
                Number(posDevice.lastKnownVersion?.split('.')[0]) >= MIN_POS_VERSION &&
                posDevice.role !== 'slave',
        );
    }, [posDeviceResponse.data]);

    const [isOpen, setIsOpen] = React.useState(false);
    React.useEffect(() => {
        if (posDeviceList.length && shouldShow) {
            setIsOpen(true);
        }
    }, [posDeviceList, shouldShow]);

    const [handleCreateMenu] = usePostMenuMutation();
    const handleSubmitWizard = React.useCallback(
        async (values: FormValues) => {
            try {
                await safeMutate(
                    Promise.all(
                        Object.entries(values.pos).map(([posDeviceId, { isSyncFromPosEnabled = true }]) => {
                            const sourcePosId = Number(posDeviceId);
                            const name =
                                posDeviceList.find((posDevice) => posDevice.posDeviceId === sourcePosId)?.deviceName ??
                                posDeviceId;
                            return limit(() =>
                                handleCreateMenu({
                                    name,
                                    brandId,
                                    sourcePosId,
                                    restaurantId,
                                    channels: [],
                                    posDevices: [sourcePosId],
                                    isSyncFromPosEnabled,
                                }),
                            );
                        }),
                    ),
                );
            } catch (e) {
                posDeviceResponse.refetch();
                throw e;
            }
        },
        [brandId, handleCreateMenu, posDeviceList, posDeviceResponse, restaurantId],
    );

    return {
        theme,
        isOpen,
        setIsOpen,
        handleSubmitWizard,
        posList: posDeviceList,
    };
};
