import React from 'react';
import { computeCurrency, computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { PaymentMethodTypes } from '@/services/innovorder/io-pay/transactions/transaction.type';
import { Container } from './PaymentMethodData.style';

type TransactionDetailPaymentMethodDataProps = {
    paymentMethodType: PaymentMethodTypes;
    amount: number;
};

const PaymentMethodData: React.FunctionComponent<React.PropsWithChildren<TransactionDetailPaymentMethodDataProps>> = ({
    paymentMethodType,
    amount,
}) => {
    const intl = useIntl();

    return (
        <Container>
            <div>{computeText(intl, `transaction.field.paymentMethod.${PaymentMethodTypes[paymentMethodType]}`)}</div>
            <div>{computeCurrency({ intl, amount })}</div>
        </Container>
    );
};

export default PaymentMethodData;
