import { Option } from '@/components/atoms/Select';
import React, { ReactElement } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import MultiSelect from '@/components/atoms/MultiSelect';
import { Label } from '@/components/atoms/Label';

type SelectFieldValues = Record<string, unknown>;

type SelectInputProps<T> = {
    rules: RegisterOptions<SelectFieldValues, keyof SelectFieldValues>;
    fieldName: string;
    options: Option<T>[];
    handleChange?: (value: T) => void;
    showErrorMessage?: boolean;
};

const SelectInput = <T extends unknown>({
    rules,
    fieldName,
    options,
    handleChange,
    showErrorMessage,
}: SelectInputProps<T>): ReactElement => {
    const {
        control,
        formState: { touchedFields },
    } = useFormContext();

    const intl = useIntl();

    const mapValueToMultiSelectValue = (selectOptions: Option<T>[], value: T): Option<T> => {
        return selectOptions.find((option) => option.value === value) as Option<T>;
    };

    return (
        <Controller
            control={control}
            name={fieldName}
            rules={rules}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                return (
                    <div>
                        <Label
                            labelId={`customer.form.${fieldName}Label`}
                            inputId={`customer.form.${fieldName}Label`}
                        />
                        <MultiSelect<T>
                            isMulti={false}
                            value={[mapValueToMultiSelectValue(options, value)]}
                            options={options}
                            onChange={(event) => {
                                const singleOptionValue = event as unknown as Option<T>;
                                onChange(singleOptionValue.value);
                                handleChange && handleChange(singleOptionValue.value);
                            }}
                            getOptionValue={(option) => option.value as unknown as string}
                            onBlur={onBlur}
                            inputId={`customer.form.${fieldName}Label`}
                            placeholder={computeText(intl, `customer.form.${fieldName}PlaceHolder`)}
                            isTouched={!!value || touchedFields[fieldName]}
                            error={error}
                            showErrorMessage={showErrorMessage}
                        />
                    </div>
                );
            }}
        />
    );
};

export default SelectInput;
