import React from 'react';

// keeping this fn as a joconde to remembre how to check compoenent type.

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isBreadcrumbChildrenType = (element: any): boolean => {
    if (!element || !element.type) {
        return false;
    }

    if (element.type === 'a') {
        return true;
    }

    if (element.type.displayName && element.type.displayName === 'Link') {
        return true;
    }

    return false;
};

export const childrenToMapAndCheckTypes = (children: React.ReactNode): React.ReactNode[] => {
    const elements: React.ReactNode[] = [];

    React.Children.forEach(children, (child) => {
        elements.push(child);
    });

    return elements;
};
