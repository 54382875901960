import * as React from 'react';

import { computeText } from '@/locales/utils';
import { Input } from '@/components/form/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import { SvyButton } from '@/components/savory/SvyButton';
import { confirm } from '@/core/asyncActions/confirm';
import { useEditPasswordExpiresAtMutation } from '@/services/innovorder/adminTools/adminTools.endpoints';
import { FormControl, FormControls, SuccessMessage } from '../AdminTools.style';
import { safeMutate } from '@/utils/errors';
import { Form } from '@/core/form/Form';
import { hasLength, isInteger, mustMatchValue } from '@/utils/form/validate';

export type EditPasswordExpiresAtInputs = {
    shouldResetPassword: string;
    brandId: string;
    customerId: string;
};

const EditPasswordExpiresAt: React.FunctionComponent = () => {
    const intl = useIntl();

    const [editPasswordExpiresAt, { isSuccess }] = useEditPasswordExpiresAtMutation();

    const handleSubmitForm = async ({ shouldResetPassword, brandId, customerId }: EditPasswordExpiresAtInputs) => {
        const hasConfirmed = await confirm(computeText(intl, 'adminTools.confirmModal.description'));
        if (hasConfirmed) {
            await safeMutate(
                editPasswordExpiresAt({
                    shouldResetPassword,
                    brandId,
                    customerId,
                }),
            );
        }
    };

    return (
        <Form<EditPasswordExpiresAtInputs>>
            {({ handleSubmit }) => {
                return (
                    <>
                        <Text type="large" text="brand.adminTools.editPasswordExpiresAt.title" />
                        <FormControls>
                            <FormControl>
                                <Input
                                    name="shouldResetPassword"
                                    rules={{
                                        required: computeText(intl, 'field.required'),
                                        validate: (value = '') => mustMatchValue('force', 'cancel')(intl)(value ?? ''),
                                    }}
                                    placeholder="brand.adminTools.editPasswordExpiresAt.form.placeholder.shouldResetPassword"
                                    labelId="brand.adminTools.editPasswordExpiresAt.form.shouldResetPassword"
                                />
                                <Input
                                    name="brandId"
                                    rules={{
                                        required: computeText(intl, 'field.required'),
                                        validate: (value = '') => isInteger(intl)(value ?? ''),
                                    }}
                                    placeholder="brand.adminTools.editPasswordExpiresAt.form.placeholder.brandId"
                                    labelId="brand.adminTools.editPasswordExpiresAt.form.brandId"
                                />
                                <Input
                                    name="customerId"
                                    placeholder="brand.adminTools.editPasswordExpiresAt.form.placeholder.customerId"
                                    labelId="brand.adminTools.editPasswordExpiresAt.form.customerId"
                                />
                            </FormControl>
                            <SvyButton onClick={handleSubmit(handleSubmitForm)}>
                                <FormattedMessage id="button.validate" />
                            </SvyButton>
                            {isSuccess && <SuccessMessage text="brand.adminTools.editPasswordExpiresAt.form.success" />}
                        </FormControls>
                    </>
                );
            }}
        </Form>
    );
};

export default EditPasswordExpiresAt;
