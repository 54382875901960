import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import Loader from '@/components/atoms/Loader';
import { SavingBar } from '@/components/atoms/SavingBar';
import { Title } from '@/components/atoms/Title';
import NavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { useAuthCookieHandler } from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { routes } from '@/core/router/routes';
import { useGetBrandQuery, useUpdateBrandOptionsMutation } from '@/services/innovorder/brand/brand.endpoints';
import { BrandOptionName, BrandOptions } from '@/services/innovorder/brand/brand.type';
import { Switch } from '@/components/atoms/Switch';
import { TitleContainer, Container, Item } from './Options.style';

interface ParamTypes {
    brandId: string;
}

export const GENERAL_OPTIONS: Partial<Record<BrandOptionName, string>> = {
    [BrandOptionName.EDUCATION_MODE]: 'options.educationMode',
    [BrandOptionName.BIRTHDATE]: 'options.birthdate',
    [BrandOptionName.TRIP_ADVISOR_USERNAME]: 'options.tripAdvisorUsername',
    [BrandOptionName.ADVANCED_SECURITY_RULES]: 'options.advancedSecurityRules',
    [BrandOptionName.OPT_IN]: 'options.optIn',
    [BrandOptionName.PRODUCTION_DECLARATION]: 'options.productionDeclaration',
    [BrandOptionName.GRANTS_AND_ENTRANCE_FEE]: 'options.grantsAndEntranceFee',
    [BrandOptionName.PIN_CODE_DOUBLE_ATHENTICATION]: 'options.pinCodeDoubleAuth',
    [BrandOptionName.CHARLEMAGNE_EXPORT]: 'options.charlemagneExport',
    [BrandOptionName.HANDLE_BADGE_4_CHAR]: 'options.handleBadgeForChar',
    [BrandOptionName.AUTO_BILLING]: 'options.autoBilling',
    [BrandOptionName.LINK_PRODUCT_IMAGES_BY_SKU]: 'options.linkProductImageBySku',
    [BrandOptionName.EXTERNAL_IDS_MAPPING]: 'options.externalIdsMapping',
    [BrandOptionName.EWALLET_AUTO_RELOAD]: 'options.ewalletAutoReload',
    [BrandOptionName.ENABLE_SODEXO_DICTIONARY]: 'options.enableSodexoDictionary',
};

export const FOODCOURT_OPTIONS: Partial<Record<BrandOptionName, string>> = {
    [BrandOptionName.FOOD_COURT_MODE]: 'options.foodCourtMode',
    [BrandOptionName.SHUFFLE_RESTAURANTS]: 'options.shuffleRestaurants',
};

export const Options: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { token } = useAuthCookieHandler();
    const { brandId } = useParams<ParamTypes>();
    const [options, setOptions] = useState<BrandOptions>({});
    const {
        data: brand,
        isLoading,
        refetch,
    } = useGetBrandQuery({ brandId: parseInt(brandId, 10), token }, { refetchOnMountOrArgChange: true });
    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    const [updateOptions, { isLoading: isLoadingUpdate }] = useUpdateBrandOptionsMutation();
    const changeCount = useMemo(
        () =>
            Object.keys(GENERAL_OPTIONS).reduce(
                (count, optionName) =>
                    brand?.options?.[optionName as BrandOptionName]?.isActivated !==
                    options?.[optionName as BrandOptionName]?.isActivated
                        ? count + 1
                        : count,
                0,
            ),
        [brand, options],
    );

    const handleChange = (optionName: BrandOptionName) => (value: boolean) => {
        const newOptions = { ...options, [optionName]: { ...options?.[optionName], isActivated: value } };
        if (!brand?.options?.[optionName as BrandOptionName] && !value) delete newOptions?.[optionName];
        setOptions(newOptions);
    };

    const handleSave = async () => {
        await updateOptions({ token, options, brandId: parseInt(brandId, 10) });
        refetch();
    };

    const handleCancel = () => {
        setOptions(brand?.options || {});
    };

    useEffect(() => {
        setOptions(brand?.options || {});
    }, [brand]);

    return (
        <>
            <NavigationTabs routes={tabRoutes}>
                <TitleContainer>
                    <Title text="options.main" />
                </TitleContainer>
                <Container>
                    {isLoading ? (
                        <Loader withContainer />
                    ) : (
                        Object.keys(GENERAL_OPTIONS).map((optionName) => (
                            <Item key={optionName} data-testid={`switch-${optionName}`}>
                                <Switch
                                    labelId={GENERAL_OPTIONS[optionName as BrandOptionName]}
                                    onChange={handleChange(optionName as BrandOptionName)}
                                    value={options?.[optionName as BrandOptionName]?.isActivated}
                                />
                            </Item>
                        ))
                    )}
                </Container>

                <TitleContainer>
                    <Title text="options.foodCourt" />
                </TitleContainer>
                <Container>
                    {isLoading ? (
                        <Loader withContainer />
                    ) : (
                        Object.keys(FOODCOURT_OPTIONS).map((optionName) => (
                            <Item key={optionName} data-testid={`switch-${optionName}`}>
                                <Switch
                                    labelId={FOODCOURT_OPTIONS[optionName as BrandOptionName]}
                                    value={options?.[optionName as BrandOptionName]?.isActivated}
                                    onChange={handleChange(optionName as BrandOptionName)}
                                />
                            </Item>
                        ))
                    )}
                </Container>
            </NavigationTabs>
            <SavingBar
                onSave={handleSave}
                changesCount={changeCount}
                onCancel={handleCancel}
                loading={isLoadingUpdate}
            />
        </>
    );
};
