import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled.div`
    background: ${({ theme }) => theme.color.grey100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    &:last-child {
        margin-bottom: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    width: 100%;
`;

export const LocationHead = styled.div`
    flex-grow: 1;
    line-height: 20px;
`;

export const Title = styled(Text)`
    color: ${({ theme }) => theme.color.black};
    line-height: 20px;
`;

export const LocationBody = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[1]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    margin-right: ${({ theme }) => theme.spacing[4]};
    line-height: 20px;
    color: ${({ theme }) => theme.color.black300};
`;

export const LocationName = styled.label`
    ${({ theme }) => theme.font.caption.bold}
    text-transform: none;
`;

export const LocationVat = styled.label`
    ${({ theme }) => theme.font.caption.regular}
    text-transform: none;
    font-style: italic;
`;

export const MarketplacesPanel = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[3]};
    flex-wrap: wrap;
`;
