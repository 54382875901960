import Modal from '@/components/atoms/Modal';
import { CreateManagerPayload } from '@/services/innovorder/user/user.type';
import React, { useEffect, useState } from 'react';
import { Input } from '@/components/atoms/Form/Input';
import { InputsContainer } from './CreateUserForm.style';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useCreateManagerMutation } from '@/services/innovorder/user/user.endpoints';
import { useRouteMatch } from 'react-router';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SvyButton } from '@/components/savory/SvyButton';
import { computeText } from '@/locales/utils';
import { useIntl, FormattedMessage } from 'react-intl';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { isValidPhoneNumber } from 'libphonenumber-js';
interface CreateUserFormProps {
    isOpen: boolean;
    onHide: () => void;
}
const CreateUserForm = ({ isOpen, onHide }: CreateUserFormProps) => {
    const intl = useIntl();
    const resolver = yupResolver(
        Yup.object().shape({
            firstName: Yup.string().required(computeText(intl, 'field.required')),
            lastName: Yup.string().required(computeText(intl, 'field.required')),
            phone: Yup.string()
                .test(
                    'validPhoneNumber',
                    computeText(intl, 'user.create.error.phone.valid'),
                    (value) => !!value && isValidPhoneNumber(value, 'FR'),
                )
                .required(computeText(intl, 'field.required')),
            email: Yup.string()
                .email(computeText(intl, 'field.invalidEmail'))
                .required(computeText(intl, 'field.required')),
            password: Yup.string()
                .min(12, computeText(intl, 'user.create.error.password.min'))
                .required(computeText(intl, 'field.required')),
            confirmPassword: Yup.string()
                .oneOf(
                    [Yup.ref('password'), null],
                    computeText(intl, 'user.create.error.passwordConfirm.sameAsPassword'),
                )
                .required(computeText(intl, 'field.required')),
        }),
    );

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

    const form = useForm<CreateManagerPayload>({
        resolver,
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
        },
    });
    const [createManager, { data: createdUser, isSuccess, error, reset, isLoading: isCreateLoading }] =
        useCreateManagerMutation();
    const {
        params: { brandId },
    } = useRouteMatch<{ brandId: string }>();

    const onSubmit = async (data: CreateManagerPayload) => {
        await createManager({ ...data, brandId: Number(brandId), role: 'brand' }).unwrap();
    };

    useEffect(() => {
        const errorObject = error && ((error as FetchBaseQueryError).data as { code: string; message: string });
        if (errorObject?.code === 'duplicate_email') {
            form.setError('email', { message: errorObject.message });
        }
        if (errorObject?.code === 'invalid_password') {
            form.setError('password', { message: errorObject.message });
        }
    }, [error]);
    useEffect(() => {
        if (isSuccess && createdUser?.userId) {
            redirectToBackofficeV1(`/home/brands/${brandId}/users/${createdUser?.userId}/access_flags`);
        }
    }, [isSuccess, createdUser]);

    const handleHide = () => {
        if (Object.values(form.getValues()).every((value) => value === '')) {
            onHide();
            form.reset();
            reset();
            return;
        }
        setIsConfirmationModalOpen(true);
    };

    const handleConfirmationHide = () => {
        setIsConfirmationModalOpen(false);
    };
    const forceHide = () => {
        form.reset();
        onHide();
        handleConfirmationHide();
    };

    return (
        <Modal
            isOpen={isOpen}
            onHide={handleHide}
            width={700}
            title={'user.create.title'}
            buttons={
                !isSuccess
                    ? [
                          <SvyButton isLoading={isCreateLoading} onClick={form.handleSubmit(onSubmit)}>
                              <FormattedMessage id="button.save" />
                          </SvyButton>,
                      ]
                    : []
            }
        >
            <Modal
                isOpen={isConfirmationModalOpen}
                onHide={handleConfirmationHide}
                width={500}
                buttons={[
                    <SvyButton onClick={forceHide} color="destructive">
                        <FormattedMessage id="button.close" />
                    </SvyButton>,
                ]}
            >
                <FormattedMessage id="form.preventLeave" />
            </Modal>
            <FormProvider {...form}>
                <InputsContainer>
                    <Controller
                        control={form.control}
                        name="firstName"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                labelId="user.create.firstName"
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={form.control}
                        name="lastName"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                labelId="user.create.lastName"
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={form.control}
                        name="phone"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                labelId="user.create.phone"
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={form.control}
                        name="email"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                name="email"
                                labelId="user.create.email"
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={form.control}
                        name={'password'}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                type="password"
                                labelId="user.create.password"
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={form.control}
                        name={'confirmPassword'}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                type="password"
                                labelId="user.create.confirmPassword"
                                error={error?.message}
                            />
                        )}
                    />
                </InputsContainer>
            </FormProvider>
        </Modal>
    );
};
export default CreateUserForm;
