import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { computeText } from '@/locales/utils';
import { StepData } from '@/components/atoms/Wizard/Wizard';
import { stringifyError } from '@/utils/errors';
import {
    useImportMenuFromExcelMutation,
    usePostMenuMutation,
    usePutMenuByIdMutation,
} from '@/services/innovorder/menu/menu.endpoints';
import type { MenuChannel, MenuPosDevice, PostImportMenuFromExcelResponse } from '@/services/innovorder/menu/menu.type';
import { getUserToken } from '@/redux/user';
import { ImportMenuStep } from './ImportMenuStep';
import { EditChannelStep } from './EditChannelStep/EditChannelStep';
import { SelectPosDeviceStep } from './SelectPosDeviceStep/SelectPosDeviceStep';
import { EditChannelFormValues } from './CreateMenuModal.type';

type CreateMenuModalVMProps = {
    onHide: () => void;
    brandId: number;
    restaurantId?: number;
    menuId: number | null;
    channels: MenuChannel[];
    posDevices: MenuPosDevice[];
    menuName: string | null;
    isSyncFromPosEnabled: boolean;
};

type OnStepChange = () => true;

export const useCreateMenuModalVM = ({
    onHide,
    brandId,
    restaurantId,
    menuId,
    channels,
    posDevices,
    menuName,
    isSyncFromPosEnabled,
}: CreateMenuModalVMProps) => {
    const [currentStep, setCurrentStep] = useState(0);
    const intl = useIntl();
    const token = useSelector(getUserToken);
    const [isImportProcessLoading, setImportProcessLoading] = useState(false);
    const [putMenuById, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, error: errorPut }] =
        usePutMenuByIdMutation();
    const [postMenu, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, error: errorPost }] =
        usePostMenuMutation();
    const [importMenuFromExcel, { isLoading: isImportLoading, isSuccess: isImportSuccess, error: errorImport }] =
        useImportMenuFromExcelMutation();
    const isSuccess = isCreateSuccess || isUpdateSuccess || isImportSuccess;
    const isLoading = isCreateLoading || isUpdateLoading || isImportLoading || isImportProcessLoading;
    const error = errorPut || errorPost || errorImport;

    const errorMessage = React.useMemo(() => {
        if (error && 'status' in error) {
            const errorData = error.data as { extraData?: { errors?: { lineNumber: string; error: string }[] } };
            const extraData = errorData?.extraData
                ? errorData.extraData?.errors
                      ?.map(({ error: lineError, lineNumber }) => `${lineNumber}: ${lineError}`)
                      .join('\n')
                : '';
            return `${stringifyError(error)}${extraData ? `\n${extraData}` : ''}`;
        }
        return '';
    }, [error]);

    const form: UseFormReturn<EditChannelFormValues> = useForm<EditChannelFormValues>({
        mode: 'all',
        defaultValues: {
            name: menuName,
            file: null,
            isSyncFromPosEnabled,
            channels,
            posDevices,
        },
    });

    const handleStepChange =
        (type: 'next' | 'previous'): OnStepChange =>
        () => {
            setCurrentStep(type === 'previous' ? currentStep - 1 : currentStep + 1);
            return true;
        };

    const handleHide = useCallback(() => {
        if (!isLoading) {
            onHide();
        }
        return true;
    }, [isLoading, onHide]);

    const steps: StepData[] = [
        {
            title: `menu.modal.channels.${menuId ? 'editMenu' : 'createMenu'}`,
            component: (
                <EditChannelStep onNext={handleStepChange('next')} restaurantId={restaurantId} isLoading={isLoading} />
            ),
            showHeader: true,
            showNext: false,
            showValidate: true,
            onCancel: handleHide,
            onNext: handleStepChange('next'),
            onPrevious: handleStepChange('previous'),
        },
        ...(restaurantId
            ? [
                  {
                      title: 'menu.modal.channels.selectPos',
                      component: <SelectPosDeviceStep brandId={brandId} restaurantId={restaurantId} />,
                      showHeader: true,
                      showNext: true,
                      showPrevious: true,
                      showValidate: false,
                      nextLabel: computeText(intl, 'button.validate'),
                      onNext: handleStepChange('previous'),
                      onPrevious: handleStepChange('previous'),
                  },
              ]
            : []),
    ];

    React.useEffect(() => {
        form.watch(['file', 'sourcePosId']);
    }, [form]);

    if (!menuId) {
        const hasData = form.getFieldState('file')?.isDirty || form.getFieldState('sourcePosId')?.isDirty;
        steps.unshift({
            title: 'menu.modal.channels.createMenu',
            component: <ImportMenuStep brandId={brandId} restaurantId={restaurantId} />,
            showHeader: false,
            nextLabel: computeText(intl, hasData ? 'button.validate' : 'button.skipStep'),
            onNext: handleStepChange('next'),
            onPrevious: handleHide,
        });
    }

    const onFormValidate = async () => {
        const { getValues, setError } = form;
        const {
            sourcePosId,
            name,
            channels: formChannels,
            posDevices: formPosDevices,
            file,
            isSyncFromPosEnabled: formIsPosSyncActive,
        } = getValues();
        const channelIds = formChannels.map(({ channelId }) => channelId);
        const posDeviceIds = formPosDevices.map(({ posDeviceId }) => posDeviceId);

        if (!name) {
            setError('name', { message: computeText(intl, 'menu.modal.error.wrongName') });
            return;
        }

        if (menuId) {
            await putMenuById({
                menuId,
                name,
                channels: channelIds,
                posDevices: posDeviceIds,
                isSyncFromPosEnabled: formIsPosSyncActive,
                token,
            });
            return;
        }
        if (!file) {
            await postMenu({
                name,
                brandId,
                sourcePosId,
                restaurantId,
                channels: channelIds,
                posDevices: posDeviceIds,
                isSyncFromPosEnabled: formIsPosSyncActive,
                token,
            });
            return;
        }

        const fileReader = new FileReader();
        let fileToUpdate: File | Record<string, unknown> = { ...file };

        if (file.type === 'application/json') {
            fileReader.onload = (e) => {
                if (e.target && typeof e.target.result === 'string') {
                    try {
                        fileToUpdate = JSON.parse(e.target.result) as Record<string, unknown>;
                    } catch (err) {
                        setError('file', { message: computeText(intl, 'file.cannotProcessJson') });
                        setCurrentStep(0);
                        return;
                    }

                    postMenu({
                        name,
                        brandId,
                        restaurantId,
                        channels: channelIds,
                        posDevices: posDeviceIds,
                        import: fileToUpdate,
                        isSyncFromPosEnabled: formIsPosSyncActive,
                        token,
                    });
                }
            };
            fileReader.readAsText(file, 'UTF-8');
        } else {
            const formData = new FormData();
            formData.append('file', file);
            setImportProcessLoading(true);
            const importMenuParams = restaurantId ? { restaurantId } : { brandId };
            const importedMenu = (await importMenuFromExcel({
                name,
                formData,
                token,
                ...importMenuParams,
            })) as { data?: PostImportMenuFromExcelResponse };

            // We cannot assign channels with current import api so we update afterward
            if (importedMenu?.data?.menuId) {
                await putMenuById({
                    menuId: importedMenu?.data?.menuId,
                    name,
                    channels: channelIds,
                    posDevices: posDeviceIds,
                    isSyncFromPosEnabled: formIsPosSyncActive,
                    token,
                });
            }
            setImportProcessLoading(false);
        }
    };

    useEffect(() => {
        if (!isLoading && isSuccess) {
            handleHide();
        }
    }, [isLoading, isSuccess, handleHide]);

    return {
        form,
        currentStep,
        steps,
        errorMessage,
        handleHide,
        isLoading,
        onFormValidate,
    };
};
