import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';

import { SavingBar } from '@/components/atoms/SavingBar';
import Loader from '@/components/atoms/Loader';
import { getUserToken } from '@/redux/user';
import { ConfigContainer } from '../common/style/Common.style';
import CrousPanel from './CrousPanel/CrousPanel';
import {
    useAddCrousModuleMutation,
    useGetCrousModuleQuery,
    usePutCrousModuleMutation,
} from '@/services/innovorder/moduleCrous/moduleCrous.endpoints';
import { FormCard } from './CrousConfig.style';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { Input } from '@/components/atoms/Form/Input';
import Select from '@/components/atoms/Select';
import { CrousList, crousMap } from './CrousConfigTypes';
import { stringifyError } from '@/utils/errors';
import { Routes } from '@/core/router/routes.types';
import { ENV } from '@/utils/env';
import { injectDataInUri } from '@/utils/routes';

export type CrousModuleInputs = {
    crousId: number;
    posId: string;
};

const CrousConfig: React.FunctionComponent<React.PropsWithChildren> = () => {
    const token = useSelector(getUserToken) as string;
    const { brandId, restaurantId } = useParams<{ brandId: string; restaurantId: string }>();
    const { data, isLoading, refetch, error: loadError } = useGetCrousModuleQuery({ restaurantId, token });
    const [addCrousModule, { isLoading: isSavingAdd, error: saveErrorAdd }] = useAddCrousModuleMutation();
    const [putCrousModule, { isLoading: isSavingUpdate, error: saveErrorUpdate }] = usePutCrousModuleMutation();
    const intl = useIntl();
    const [changes, setChanges] = useState(false);
    const theme = useTheme();

    const { control, handleSubmit, clearErrors, trigger, reset } = useForm<CrousModuleInputs>({
        defaultValues: {
            crousId: 5,
            posId: '',
        },
    });

    useEffect(() => {
        if (data) {
            reset({
                crousId: data.crousId,
                posId: data.posId,
            });
        }
    }, [data, reset]);

    const handleChange =
        (optionName: keyof CrousModuleInputs, onChange: (...event: any[]) => void, onBlur: () => void) =>
        (options: boolean | React.ChangeEvent<HTMLInputElement>) => {
            onBlur();
            clearErrors(optionName);
            onChange(options);
            trigger(optionName);
        };

    const handleSave = async (crousModule: CrousModuleInputs) => {
        if (isSavingAdd || isSavingUpdate) return;
        const refetchedResponse = await refetch();
        const refetchedData = refetchedResponse?.data;

        refetchedData
            ? await putCrousModule({ restaurantId, token, module: crousModule })
            : await addCrousModule({ restaurantId, token, module: crousModule });
        setChanges(true);

        const redirectTo = injectDataInUri({
            uri: Routes.RestaurantIntegrations,
            backofficeUrl: ENV.BACKOFFICE_URL,
            brandId,
            restaurantId,
        });
        window.location.replace(redirectTo);
    };

    if (isLoading) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <>
            <ConfigContainer>
                <CrousPanel />
                <FormCard>
                    <Controller
                        control={control}
                        name="crousId"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Select<CrousList>
                                value={value}
                                options={crousMap}
                                onChange={onChange}
                                onBlur={onBlur}
                                labelId="crous.listcrousrestaurants.label"
                                placeholder="crous.listcrousrestaurants.placeholder"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="posId"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error: e } }) => (
                            <Input
                                error={e?.message}
                                onChange={handleChange('posId', onChange, onBlur)}
                                onBlur={handleChange('posId', onChange, onBlur)}
                                placeholder="crous.numerocaisse.label"
                                labelId="crous.numerocaisse.placeholder"
                                value={value}
                            />
                        )}
                    />
                </FormCard>
            </ConfigContainer>
            <SavingBar
                onSave={handleSubmit(handleSave)}
                error={stringifyError(saveErrorAdd) || stringifyError(saveErrorUpdate)}
                loading={isSavingAdd || isSavingUpdate}
                changesCount={changes ? 1 : 0}
                displayChangesCount={false}
            />
        </>
    );
};

export default CrousConfig;
