import { css } from '@emotion/react';
import styled from '@emotion/styled';

type StyleProps = { width: number; height: number };

const ImageDefaultStyle = ({ width, height }: StyleProps) => css`
    height: ${height}px;
    min-height: ${height}px;
    width: ${width}px;
    min-width: ${width}px;
    margin: 0;
`;

export const ImageContainer = styled.img<StyleProps & { src: string }>`
    ${ImageDefaultStyle};
    object-fit: cover;
    border-radius: ${({ theme }) => theme.radius.main};
    overflow: hidden;
`;

export const ImagePlaceholder = styled.div<StyleProps>`
    ${ImageDefaultStyle}
    background: ${({ theme }) =>
        `linear-gradient(45deg, ${theme.color.grey300}, ${theme.color.grey200}, ${theme.color.grey300})`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.radius.main};
    font-size: 24px;
    color: ${({ theme }) => theme.color.grey500};
`;
