import * as React from 'react';
import { Text } from '@/components/atoms/Text';
import { computeCurrency, computeText } from '@/locales/utils';
import { SvyButton } from '@/components/savory/SvyButton';
import { SvyBadge } from '@/components/savory/SvyBadge';
import { Image } from '@/components/atoms/Image';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { SvyFormInput } from '@/components/savory/SvyInput';
import { useCrossSellingFieldsVm } from './CrossSellingFields.viewmodel';
import {
    CrossSellingGrid,
    CrossSellingInputDroppable,
    CrossSellingInputDraggable,
    CrossSellingTop,
    Container,
} from './CrossSellingFields.style';

export type CrossSellingFieldsProps = {
    brandId: number;
};

export const CrossSellingFields: React.FunctionComponent<CrossSellingFieldsProps> = (props) => {
    const { intl, handleDragEnd, handleAddProducts, handleRemove, products } = useCrossSellingFieldsVm(props);

    return (
        <Container data-testid="cross-selling-fields-container">
            <CrossSellingTop>
                <SvyFormInput
                    data-testid="cross-selling-form-name"
                    label={computeText(intl, 'crossSelling.products.name')}
                    name="name"
                    rules={{
                        required: computeText(intl, 'field.required'),
                    }}
                />
                <SvyButton onClick={handleAddProducts} size="s">
                    {computeText(intl, 'crossSelling.products.add')}
                </SvyButton>
            </CrossSellingTop>
            <DragDropContext onDragEnd={handleDragEnd}>
                <CrossSellingGrid>
                    <div />
                    <Text weight="bold" text="crossSelling.products.image" />
                    <Text weight="bold" text="crossSelling.products.name" />
                    <Text weight="bold" text="crossSelling.products.price" />
                    <Text weight="bold" text="crossSelling.products.vat" />
                    <Text weight="bold" text="crossSelling.products.tags" />
                    <Text weight="bold" text="crossSelling.products.sku" />
                    <Text weight="bold" text="crossSelling.products.menuName" />
                    <Text weight="bold" text="crossSelling.products.menuState" />
                    <Text weight="bold" text="crossSelling.products.restaurantName" />
                    <div />
                </CrossSellingGrid>
                <Droppable key="droppableId" droppableId={`droppableId`}>
                    {(providedDrop, snapshot) => (
                        <CrossSellingInputDroppable
                            data-is-dragging-over={snapshot.isDraggingOver}
                            key={`droppable-item`}
                            ref={providedDrop.innerRef}
                            {...providedDrop.droppableProps}
                        >
                            {products.map((product, productIndex) => (
                                <Draggable
                                    key={product.productId}
                                    draggableId={`${product.productId}`}
                                    index={productIndex}
                                >
                                    {(providedDrag, draggableSnapshot) => (
                                        <CrossSellingInputDraggable>
                                            <CrossSellingGrid
                                                data-is-dragged={draggableSnapshot.isDragging}
                                                ref={providedDrag.innerRef}
                                                {...providedDrag.draggableProps}
                                            >
                                                <div {...providedDrag.dragHandleProps}>
                                                    <i className="ri-draggable" />
                                                </div>
                                                <Image src={product.image?.fullUrl} alt={product.name} />
                                                <Text text={product.name} />
                                                <Text text={computeCurrency({ intl, amount: product.price })} />
                                                <Text text={`${product.vat / 100}%`} />
                                                <Text text={product.tags.map(({ name }) => name).join(', ')} />
                                                <Text text={product.sku?.value} />
                                                <Text text={product.menu.name} />
                                                <SvyBadge
                                                    label={computeText(
                                                        intl,
                                                        product.menu.isActive
                                                            ? 'crossSelling.products.menuState.enabled'
                                                            : 'crossSelling.products.menuState.disabled',
                                                    )}
                                                    color={product.menu.isActive ? 'success' : 'destructive'}
                                                />
                                                <Text text={product.menu.restaurant.name} />
                                                <SvyButton
                                                    type="button"
                                                    size="s"
                                                    variant="outline"
                                                    color="destructive"
                                                    onClick={() => handleRemove(product.productId)}
                                                >
                                                    {computeText(intl, 'button.delete')}
                                                </SvyButton>
                                            </CrossSellingGrid>
                                        </CrossSellingInputDraggable>
                                    )}
                                </Draggable>
                            ))}
                            {providedDrop.placeholder}
                        </CrossSellingInputDroppable>
                    )}
                </Droppable>
            </DragDropContext>
        </Container>
    );
};
