import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import { PaymentMethod, GetPaymentMethodPayload, BulkUpdatePaymentMethodPayload } from './paymentMethod.type';

const paymentMethodApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getPaymentMethods: build.query<PaymentMethod[], ApiRequest<GetPaymentMethodPayload>>({
            query: ({ channelId, restaurantId, token }) => ({
                url: `/payment_methods?channelId=${channelId}&restaurantId=${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<PaymentMethod[]>) =>
                response.data.filter(({ deleted_at }) => !deleted_at),
            extraOptions: { maxRetries: 3 },
        }),
        bulkUpdatePaymentMethods: build.mutation<PaymentMethod[], ApiRequest<BulkUpdatePaymentMethodPayload>>({
            query: ({ channelId, restaurantId, creates, updates, deletes, token }) => ({
                url: '/payment_methods',
                method: 'PUT',
                body: {
                    channelId,
                    restaurantId,
                    creates,
                    updates,
                    deletes,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<PaymentMethod[]>): PaymentMethod[] => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetPaymentMethodsQuery, useBulkUpdatePaymentMethodsMutation } = paymentMethodApi;
