import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Routes } from '@/core/router/routes.types';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { AccessFlags } from '@/services/innovorder/auth/auth.type';
import { OverlayComponent } from '../menu';

export enum SideBarArea {
    top = 'top',
    middle = 'middle',
    bottom = 'bottom',
}

export type LinkPayload = {
    labelId: string;
    route?: Routes;
    icon?: SVGIcons;
    roles: UserRoles[];
    accessFlags?: AccessFlags[];
    layoutArea: SideBarArea;
    uri?: string;
    resolvedUri?: string;
    forward?: boolean;
    enabledBy?: string;
    disabledBy?: string;
    subMenu?: LinkPayload[] | OverlayComponent;
    subMenuUri?: string;
    subMenuRoute?: Routes;
    onClick?: () => void;
    class?: string;
};

export type SplitedSideBarItems = {
    [key in SideBarArea]: LinkPayload[];
};
