export type GetEntranceFeesPayload = {
    brandId: number;
};

export type GetEntranceFeePayload = {
    entranceFeeId: number;
};

export type DeleteEntranceFeePayload = {
    entranceFeeId: number;
    brandId: number;
};

export enum EntranceFeeType {
    AdmissionFee = 'AdmissionFee',
    RoyaltyFee = 'RoyaltyFee',
}

export enum EntranceFeeStrategy {
    Always = 1,
    Daily_minimum_granted = 2,
}

export enum VatEnum {
    zero = 0,
    twoOne = 210,
    fiveFive = 550,
    six = 600,
    heigthFive = 850,
    ten = 1000,
    twenty = 2000,
    twentyOne = 2100,
}

export type BaseRestaurantEntranceFeeData = {
    amount: number;
    dailyLimit: number;
    strategy: EntranceFeeStrategy;
    vat: number;
};

export type ShiftEntranceFeePayloadData = {
    shiftId: string;
    shift: {
        name: string;
        isDefault: boolean;
    };
    vat: number | null;
    amount: number;
};

export type RestaurantEntranceFeePayloadData = BaseRestaurantEntranceFeeData & {
    restaurantEntranceFeeId?: number;
    restaurantId: number;
    entranceFeeId: number;
    version?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
};

export type RestaurantEntranceFee<T> = {
    name: string;
    restaurantId: number;
    restaurant_entrance_fee: T;
    shiftEntranceFees: ShiftEntranceFeePayloadData[];
};

export type RestaurantEntranceFeePayload = RestaurantEntranceFee<RestaurantEntranceFeePayloadData>;

export type RestaurantEntranceFeeCreateAttribute = Omit<
    RestaurantEntranceFee<BaseRestaurantEntranceFeeData>,
    'shiftEntranceFees'
>;

export type BaseEntranceFeeAttributes = {
    code: string;
    labelTicket: string;
    amount: number;
    dailyLimit: number;
    strategy: EntranceFeeStrategy;
    vat: VatEnum;
    type: EntranceFeeType;
};

export type EntranceFeeCreateAttributes = BaseEntranceFeeAttributes & {
    brandId: number;
    restaurants: RestaurantEntranceFeeCreateAttribute[];
};

export type EntranceFeePayload = BaseEntranceFeeAttributes & {
    brandId: number;
    entranceFeeId: number;
    restaurants: RestaurantEntranceFeePayload[];
};

export type EntranceFee = EntranceFeePayload & {
    entranceFeeId: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
};

export type PutRestaurantEntranceFeeBody = EntranceFeeCreateAttributes & {
    entranceFeeId: number;
};

export type EntranceFees = {
    id: number;
    code: string;
}[];
