/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import { assertUnreachable } from '@/utils/typescript';
import { KycStatus } from '@/services/innovorder/io-pay/legalEntity/legalEntity.type';
import { getBrand } from '@/redux/brand';
import { useSelector } from 'react-redux';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { useGetBrandLegalEntitiesQuery } from '@/services/innovorder/io-pay/legalEntity/legalEntity.endpoints';
import { getUserBrandId, getUserRestaurants, getUserRole } from '@/redux/user';
import { filterTypes } from '@/components/atoms/Table/Table.utils';
import { Column } from 'react-table';
import { Theme, useTheme } from '@emotion/react';
import { UserRestaurant, UserRoles } from '@/services/innovorder/user/user.type';
import { ConfigurationAction } from './types';

export const getKycStatusBackgroundColor = (status: KycStatus, theme: Theme): string => {
    if (status === KycStatus.Approved) return theme.color.io.green100;
    if (status === KycStatus.Pending) return theme.color.io.orange100;
    if (status === KycStatus.Rejected) return theme.color.io.destructive100;
    if (status === KycStatus.ToComplete) return theme.color.io.warning100;
    return assertUnreachable(status);
};

export const getKycStatusTextColor = (status: KycStatus, theme: Theme): string => {
    if (status === KycStatus.Approved) return theme.color.io.green500;
    if (status === KycStatus.Pending) return theme.color.io.orange500;
    if (status === KycStatus.Rejected) return theme.color.io.destructive500;
    if (status === KycStatus.ToComplete) return theme.color.io.warning500;
    return assertUnreachable(status);
};

export type ConfigurationTableViewModel = {
    rows: TableRow[];
    unconfiguredRestaurants: UserRestaurant[];
    hasEwalletConfigured: boolean;
    isLoading: boolean;
    canCreateLegalEntity: boolean;
    toggleCreateModal: () => void;
    onClearAction: () => void;
    action: ConfigurationAction;
};

export const getConfigurationTableColumns = (): readonly Column<TableRow>[] => {
    const intl = useIntl();
    return [
        {
            Header: computeText(intl, 'legalEntity.field.entity'),
            accessor: 'legalEntity',
            minWidth: 100,
            disableFilters: false,
            disableSortBy: false,
            filter: filterTypes.string,
        },
        {
            Header: computeText(intl, 'legalEntity.field.siret'),
            accessor: 'siret',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: false,
        },
        {
            Header: computeText(intl, 'legalEntity.field.city'),
            accessor: 'city',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: false,
        },
        {
            Header: computeText(intl, 'legalEntity.field.status'),
            accessor: 'status',
            minWidth: 100,
            disableSortBy: false,
            filter: filterTypes.list,
            sortType: (rowA, rowB, id) => {
                if (rowA.values[id].value.text > rowB.values[id].value.text) return 1;
                if (rowB.values[id].value.text > rowA.values[id].value.text) return -1;
                return 0;
            },
            // @ts-ignore
            Filter: [
                { value: 'legalEntity.status.1', label: computeText(intl, 'legalEntity.status.1') },
                { value: 'legalEntity.status.2', label: computeText(intl, 'legalEntity.status.2') },
                { value: 'legalEntity.status.3', label: computeText(intl, 'legalEntity.status.3') },
                { value: 'legalEntity.status.4', label: computeText(intl, 'legalEntity.status.4') },
            ].sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            Header: computeText(intl, 'legalEntity.field.associatedRestaurants'),
            accessor: 'associatedRestaurants',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'legalEntity.field.actions'),
            accessor: 'actions',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: true,
        },
    ];
};

const defaultConfigurationAction: ConfigurationAction = {
    name: null,
    organizationType: null,
    siret: null,
    addressStreet: null,
    addressZipCode: null,
    addressCity: null,
    industryCode: null,
    contactEmail: null,
    iban: null,
    type: null,
};

export function useConfigurationTableVM(): ConfigurationTableViewModel {
    const brand = useSelector(getBrand);
    const brandId = useSelector(getUserBrandId) as number;
    const restaurants = useSelector(getUserRestaurants);
    const userRole = useSelector(getUserRole);
    const theme = useTheme();
    const intl = useIntl();

    const { data: legalEntities, isLoading: isGetLegalEntityLoading } = useGetBrandLegalEntitiesQuery({ brandId });
    const [action, setAction] = useState<ConfigurationAction>(defaultConfigurationAction);
    const toggleCreateModal = (): void => {
        setAction({ ...defaultConfigurationAction, type: 'showCreateLegalEntityModal' });
    };

    const hasAllRestaurantsConfigured = React.useMemo(() => {
        if (!restaurants) return false;
        return restaurants.every((restaurant) =>
            legalEntities?.some((legalEntity) =>
                legalEntity.adyenConfigurations.some(
                    (adyenConfiguration) => adyenConfiguration.configuration.restaurantId === restaurant.restaurantId,
                ),
            ),
        );
    }, [legalEntities, restaurants]);

    const hasEwalletConfigured = React.useMemo(() => {
        if (!legalEntities) return false;
        return legalEntities?.some((legalEntity) =>
            legalEntity.adyenConfigurations.some(
                (adyenConfiguration) => adyenConfiguration.configuration.restaurantId === null,
            ),
        );
    }, [legalEntities]);

    const unconfiguredRestaurants = React.useMemo(() => {
        if (!restaurants) return [];
        return restaurants.filter((restaurant) =>
            legalEntities?.every((legalEntity) =>
                legalEntity.adyenConfigurations.every(
                    (adyenConfiguration) => adyenConfiguration.configuration.restaurantId !== restaurant.restaurantId,
                ),
            ),
        );
    }, [legalEntities, restaurants]);

    const rows: TableRow[] = React.useMemo(() => {
        const hasEducationMode = brand?.options?.EDUCATION_MODE?.isActivated;

        const getRestaurantName = (restaurantId: number): string => {
            if (!restaurants) return '';
            if (restaurantId === null) return 'E-Wallet';
            return restaurants.find((restaurant) => restaurant.restaurantId === restaurantId)?.name || '';
        };

        return !legalEntities
            ? []
            : legalEntities?.map((legalEntity) => {
                  const rowDropdownActions = [];

                  if (!hasAllRestaurantsConfigured) {
                      rowDropdownActions.push({
                          value: 1,
                          label: computeText(intl, 'legalEntity.action.addRestaurant'),
                          onClick: () => true,
                      });
                  }
                  if (hasEducationMode && !hasEwalletConfigured && userRole === UserRoles.brand) {
                      rowDropdownActions.push({
                          value: 2,
                          label: computeText(intl, 'legalEntity.action.addEwallet'),
                          onClick: () => true,
                      });
                  }
                  if (legalEntity.kycStatus === KycStatus.ToComplete) {
                      rowDropdownActions.unshift({
                          value: 3,
                          label: computeText(intl, 'legalEntity.action.completeKyc'),
                          onClick: () => true,
                      });
                  }
                  if (legalEntity.kycStatus === KycStatus.Rejected) {
                      rowDropdownActions.unshift({
                          value: 4,
                          label: computeText(intl, 'legalEntity.action.updateKyc'),
                          onClick: () => true,
                      });
                  }

                  return {
                      legalEntity: {
                          type: 'string',
                          value: {
                              text: legalEntity.legalName,
                          },
                      },
                      siret: {
                          type: 'string',
                          value: {
                              text: legalEntity.siret,
                          },
                      },
                      city: {
                          type: 'string',
                          value: {
                              text: legalEntity.addressCity,
                          },
                      },
                      status: {
                          type: 'badge',
                          value: {
                              text: `legalEntity.status.${legalEntity.kycStatus}`,
                              backgroundColor: getKycStatusBackgroundColor(legalEntity.kycStatus, theme),
                              textColor: getKycStatusTextColor(legalEntity.kycStatus, theme),
                          },
                      },
                      associatedRestaurants: {
                          type: 'badgeList',
                          value: legalEntity.adyenConfigurations.map((adyenConfiguration) => {
                              return {
                                  text: getRestaurantName(adyenConfiguration.configuration.restaurantId),
                                  backgroundColor: '#F4F4F5',
                                  textColor: '#71717A',
                              };
                          }),
                      },
                      actions: {
                          type: 'dropdownText',
                          value: {
                              button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                              align: 'end',
                              withPortal: true,
                              items: rowDropdownActions,
                              type: 'text',
                          },
                      },
                  };
              });
    }, [intl, brand, legalEntities, restaurants, hasAllRestaurantsConfigured, hasEwalletConfigured, userRole, theme]);

    const canCreateLegalEntity = React.useMemo(() => {
        if (isGetLegalEntityLoading) return false;

        const allRestaurantsConfigured = unconfiguredRestaurants.length === 0;
        const isRestaurantUser = userRole === UserRoles.restaurant;
        const hasRightsToCreateLegalEntity = !(allRestaurantsConfigured && (hasEwalletConfigured || isRestaurantUser));

        return hasRightsToCreateLegalEntity && !isGetLegalEntityLoading;
    }, [isGetLegalEntityLoading, unconfiguredRestaurants.length, hasEwalletConfigured, userRole]);

    return {
        rows,
        unconfiguredRestaurants,
        hasEwalletConfigured,
        isLoading: isGetLegalEntityLoading,
        canCreateLegalEntity,
        toggleCreateModal,
        action,
        onClearAction: () => setAction(defaultConfigurationAction),
    };
}
