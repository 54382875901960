import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Table, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, fetchDataProps } from '@/components/atoms/Table';
import Modal from '@/components/atoms/Modal';
import { saveStreamAsZipFile } from '@/pages/Brand/AutoBilling/AutoBillingRequest/utils';
import { BillingHistory } from '@/services/innovorder/autoBilling/autoBilling.type';
import {
    useGetBillingHistoriesMutation,
    usePostPreviewBillingRequestMutation,
} from '@/services/innovorder/autoBilling/autoBilling.endpoints';
import { getUserBrandId, getUserToken } from '@/redux/user';
import AutoBillingInvoices from '../AutoBillingRequestPreview/AutoBillingInvoices';
import { getColumns, getRows } from './utils';

type AutoBillingRequestHistoryProps = {
    forceRefreshCounter: number;
};
const AutoBillingRequestHistory: React.FunctionComponent<React.PropsWithChildren<AutoBillingRequestHistoryProps>> = ({
    forceRefreshCounter,
}) => {
    const intl = useIntl();

    const token = useSelector(getUserToken);
    const brandId = useSelector(getUserBrandId) as number;

    const [getBillingHistories, { data, isLoading }] = useGetBillingHistoriesMutation();

    const [selectedBillingHistory, setSelectedBillingHistory] = useState<BillingHistory>();
    const [proceededBillingHistory, setProceededBillingHistory] = useState<BillingHistory>();

    const onPreview = useCallback((billingHistory: BillingHistory) => {
        setSelectedBillingHistory(billingHistory);
    }, []);

    const [postPreviewBillingRequest, { isSuccess, data: invoices }] = usePostPreviewBillingRequestMutation();

    useEffect(() => {
        if (invoices && isSuccess) {
            saveStreamAsZipFile(invoices as string);
            setProceededBillingHistory(undefined);
        }
    }, [invoices, isSuccess]);

    const onDownload = useCallback(
        (billingHistory: BillingHistory) => {
            if (proceededBillingHistory === undefined) {
                setProceededBillingHistory(billingHistory);
                postPreviewBillingRequest({
                    ...billingHistory.billingRequest,
                    requestPdf: true,
                    token,
                });
            }
        },
        [proceededBillingHistory, postPreviewBillingRequest, token],
    );

    const columns = useMemo(() => getColumns(intl), [intl]);
    const rows = useMemo(
        () => getRows(data?.content ?? [], intl, onPreview, onDownload, proceededBillingHistory),
        [data, intl, onDownload, onPreview, proceededBillingHistory],
    );

    const fetchData = useCallback(
        ({ pageIndex, pageSize }: fetchDataProps) => {
            getBillingHistories({
                brandId,
                token,
                page: pageIndex ?? DEFAULT_PAGE_INDEX,
                size: pageSize ?? DEFAULT_PAGE_SIZE,
                sort: 'date',
            });
        },
        [brandId, getBillingHistories, token],
    );

    useEffect(() => {
        getBillingHistories({
            brandId,
            token,
            page: 0,
            size: DEFAULT_PAGE_SIZE,
            sort: 'date',
        });
    }, [brandId, getBillingHistories, forceRefreshCounter, token]);

    return (
        <>
            <Table
                fetchDataTotalCount={data?.count}
                hasPagination
                emptyMessageId="auto.billing.request.history.table.emptyMessage"
                columns={columns}
                rows={rows}
                isLoading={isLoading}
                fetchData={fetchData}
            />
            {selectedBillingHistory && (
                <Modal isOpen={true} onHide={() => setSelectedBillingHistory(undefined)}>
                    <AutoBillingInvoices
                        autoBillingRequest={selectedBillingHistory.billingRequest}
                        invoices={selectedBillingHistory.invoices}
                    />
                </Modal>
            )}
        </>
    );
};

export default AutoBillingRequestHistory;
