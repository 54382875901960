import React from 'react';

import { Container, FieldContainer, StyledLabel, StyledValue } from '../TransactionDetail.style';

type TransactionDetailWebDataProps = {
    ip?: string;
    referer?: string;
    userAgent?: string;
};

const WebData: React.FunctionComponent<React.PropsWithChildren<TransactionDetailWebDataProps>> = ({
    ip,
    referer,
    userAgent,
}) => {
    return (
        <Container>
            {ip && (
                <FieldContainer>
                    <StyledLabel text="transaction.field.ip" />
                    <StyledValue>{ip}</StyledValue>
                </FieldContainer>
            )}
            {referer && (
                <FieldContainer>
                    <StyledLabel text="transaction.field.referer" />
                    <StyledValue>{referer}</StyledValue>
                </FieldContainer>
            )}
            {userAgent && (
                <FieldContainer>
                    <StyledLabel text="transaction.field.userAgent" />
                    <StyledValue>{userAgent}</StyledValue>
                </FieldContainer>
            )}
        </Container>
    );
};

export default WebData;
