import React from 'react';
import { useSelector } from 'react-redux';
import { injectDataInUri, mapWithDynamicUri } from '@/utils/routes';
import {
    getUseBillingSpace,
    getUseCollectiveCateringSalesSummary,
    getUseEwalletExport,
    getUseReportsV2,
} from '@/redux/launchDarkly';
import { getActualRoute } from '@/redux/router/router.selector';
import { getUser, getUserBrandId, getUserRole } from '@/redux/user';

import { getBrand } from '@/redux/brand';
import { getReportMetadata } from '@/redux/report';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { ENV } from '@/utils/env';
import { SvyIcon } from '@/components/savory/SvyIcon';

import SideBarItem from '../SideBarItem';
import { Area, Container, HelpContainer, StyledSvyButton } from './DynamicMenu.style';
import { LinkPayload } from './dynamicMenu.type';
import { dynamicMenuData } from './dynamicMenuData';
import { filterItemsByFlag, filterItemsByRoleAndAccessFlags, splitSideBarItemsByArea } from './utils';
import { getUseNewBrandsPage } from '@/redux/launchDarkly/launchDarkly.slice';
import { HELP_LINK } from '@/utils/constants';
import { getSidebareStatus } from '@/redux/app';

const DynamicMenu: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const user = useSelector(getUser);
    const role = useSelector(getUserRole);
    const brandId = useSelector(getUserBrandId);
    const brand = useSelector(getBrand);
    const actualRoute = useSelector(getActualRoute);
    const useEwalletExport = useSelector(getUseEwalletExport);
    const sidebarStatus = useSelector(getSidebareStatus);
    const useReportsV2 = useSelector(getUseReportsV2);
    const useBillingSpace = useSelector(getUseBillingSpace);
    const reportMetaData = useSelector(getReportMetadata);
    const useNewBrandsPage = useSelector(getUseNewBrandsPage);
    const useCollectiveCateringSalesSummary = useSelector(getUseCollectiveCateringSalesSummary);
    const items = filterItemsByRoleAndAccessFlags(dynamicMenuData(reportMetaData), role, user?.accessFlags);
    const educationModeEnabledInBrand = !!brand?.options?.EDUCATION_MODE?.isActivated;
    const autoBillingEnabledInBrand = !!brand?.options?.AUTO_BILLING?.isActivated;
    const filteredItems = filterItemsByFlag(items, {
        'ewallet-export-enabled': educationModeEnabledInBrand && useEwalletExport,
        'education-mode-enabled': educationModeEnabledInBrand,
        'old-sales-summary-enabled': educationModeEnabledInBrand && !useCollectiveCateringSalesSummary,
        'sales-summary-enabled': educationModeEnabledInBrand && useCollectiveCateringSalesSummary,
        'reports-v2-enabled': useReportsV2,
        'billing-space-enabled': useBillingSpace,
        'auto-billing-enabled': autoBillingEnabledInBrand,
        'use-new-brands-page': useNewBrandsPage,
    });
    const dynamicItems = mapWithDynamicUri(filteredItems, { brandId: String(brandId ?? '') || undefined });
    const { top, middle, bottom } = splitSideBarItemsByArea(dynamicItems);

    const mapItemToSideBarItem = ({
        labelId,
        icon,
        route,
        resolvedUri,
        subMenu,
        subMenuUri,
        subMenuRoute,
        forward,
        onClick,
        class: className,
    }: LinkPayload): React.ReactElement => (
        <SideBarItem
            key={labelId}
            icon={icon}
            labelId={labelId}
            emphased={route === actualRoute}
            route={route}
            uri={resolvedUri}
            subMenu={subMenu}
            subMenuUri={subMenuUri}
            subMenuRoute={subMenuRoute}
            forward={forward}
            onClick={onClick}
            class={className}
        />
    );

    return (
        <Container data-testid="dynamic-menu">
            <div>
                <Area data-testid="dynamic-area">{top.map(mapItemToSideBarItem)}</Area>
                <Area data-testid="dynamic-area">{middle.map(mapItemToSideBarItem)}</Area>
            </div>
            <div>
                <HelpContainer isSidebarCollapsed={sidebarStatus} data-testid="help-link">
                    <a href={HELP_LINK} target="_blank">
                        <StyledSvyButton variant="outline" size="s">
                            <SvyIcon icon="question-line" />
                            {!sidebarStatus && computeText(intl, 'sidebar.help')}
                        </StyledSvyButton>
                    </a>
                </HelpContainer>
                <div data-testid="dynamic-area">{bottom.map(mapItemToSideBarItem)}</div>
            </div>
        </Container>
    );
};

export default DynamicMenu;
