import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { RouterState, connectRouter, routerMiddleware } from 'connected-react-router';
import { History, createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import { innovorderApi, reportV2Api } from '@/services/innovorder';
import { AnalyticsFiltersState, analyticsFiltersSlice } from '@/redux/analyticsFilters';
import { AppState, appSlice } from '@/redux/app';
import { PricingRuleManageState, pricingRuleManageSlice } from '@/redux/pricingRuleManage';
import { UserState, userSlice } from '@/redux/user';

import brandReducer, { BrandState } from './brand';
import { checkPermissionDeniedMiddleware } from './customMiddlewares';
import grandAndEntranceFeeReducer, { GrantAndEntranceFeeState } from './grantAndEntranceFee';
import launchDarklyReducer, { LaunchDarklyState } from './launchDarkly';
import reportReducer, { ReportState } from './report';
import { MenuState } from './menu';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createRootReducer = (history: History<any>) =>
    combineReducers({
        analyticsFilters: analyticsFiltersSlice.reducer,
        router: connectRouter(history),
        user: userSlice.reducer,
        app: appSlice.reducer,
        pricingRuleManage: pricingRuleManageSlice.reducer,
        grantAndEntranceFee: grandAndEntranceFeeReducer,
        launchDarkly: launchDarklyReducer,
        report: reportReducer,
        brand: brandReducer,
        [innovorderApi.reducerPath]: innovorderApi.reducer,
        [reportV2Api.reducerPath]: reportV2Api.reducer,
    });

export type RootState = {
    analyticsFilters: AnalyticsFiltersState;
    router: RouterState;
    user: UserState;
    app: AppState;
    pricingRuleManage: PricingRuleManageState;
    grantAndEntranceFee: GrantAndEntranceFeeState;
    launchDarkly: LaunchDarklyState;
    report: ReportState;
    brand: BrandState;
    menu: MenuState;
};

// CREATE STORE
export const history = createBrowserHistory();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const configureStoreWithPreloadedState = (preloadedState?: any) =>
    configureStore({
        reducer: createRootReducer(history),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false })
                .concat(innovorderApi.middleware)
                .concat(reportV2Api.middleware)
                .concat(checkPermissionDeniedMiddleware)
                .concat(routerMiddleware(history)),
        preloadedState,
    });

const store = configureStoreWithPreloadedState();

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
