import styled from '@emotion/styled';

const DefaultButton = styled.button<{ fullWidth?: boolean; thick?: boolean; hasBorder?: boolean }>`
    height: ${({ thick }) => (thick ? '50px' : '40px')};
    position: relative;
    outline: none;
    border: ${({ theme, hasBorder }) => (hasBorder ? `1px solid ${theme.color.primary}` : '0px')};
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.radius.main};
    align-items: center;
    padding: ${({ thick }) => (thick ? '16px 16px' : '10px 16px')};
    cursor: pointer;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    font-size: ${({ theme }) => theme.font.size.normal};
    font-family: ${({ theme }) => theme.font.family};

    &:active {
        transform: scale(0.97);
    }

    &:disabled {
        opacity: 0.7;
        pointer-events: none;
    }

    transition: 0.1s ease;
`;

export const RedStyledButton = styled(DefaultButton)`
    background-color: ${({ theme }): string => theme.color.primary};

    div {
        color: ${({ theme }): string => theme.color.white100};
    }
`;

export const SecondaryStyledButton = styled(DefaultButton)<{ hasBorder?: boolean }>`
    background: ${({ theme }): string => theme.color.white200};
    border: ${({ theme, hasBorder }) => (hasBorder ? `1px solid ${theme.color.grey200}` : '0px')};

    &:hover {
        background-color: ${({ theme }): string => theme.color.grey300};
    }

    &:active {
        background-color: ${({ theme }): string => theme.color.grey200};
    }

    div {
        color: ${({ theme }): string => theme.color.black};
    }
`;

export const OutlinedPrimaryStyledButton = styled(DefaultButton)<{ hasBorder?: boolean }>`
    background: ${({ theme }): string => theme.color.white100};
    border: ${({ theme, hasBorder }) => (hasBorder ? `1px solid ${theme.color.grey200}` : '0px')};
    color: ${({ theme }): string => theme.color.primary};

    &:hover {
        background-color: ${({ theme }): string => theme.color.primary};
    }

    &:hover,
    &:hover div {
        color: ${({ theme }): string => theme.color.white100};
    }

    &:active {
        background-color: ${({ theme }): string => theme.color.bloodyMary};
    }

    &:active,
    &:active div {
        color: ${({ theme }): string => theme.color.white100};
    }

    div {
        color: ${({ theme }): string => theme.color.primary};
    }
`;

export const PrimaryStyledButton = styled(DefaultButton)<{ hasBorder?: boolean }>`
    color: ${({ theme }): string => theme.color.white100};
    background-color: ${({ theme }): string => theme.color.slate300};
    border: ${({ theme, hasBorder }) => (hasBorder ? `1px solid ${theme.color.grey200}` : '0px')};

    &:hover {
        background-color: ${({ theme }): string => theme.color.slate200};
    }

    &:active {
        background-color: ${({ theme }): string => theme.color.slate100};
    }

    div {
        color: ${({ theme }): string => theme.color.white100};
    }
`;

export const TextButton = styled(DefaultButton)`
    color: ${({ theme }): string => theme.color.black200};
    background-color: transparent;
    border: none;

    padding: 0;

    width: 100%;

    div {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    &:hover {
        color: ${({ theme }): string => theme.color.black200};
    }
`;

export const ChildrenContainer = styled.div<{ isLoading: boolean }>`
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
    display: flex;
    justify-content: center;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 12px;
    }
`;

export const LoadingContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;
