import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { EntranceFee } from '@/services/innovorder/entranceFee/entranceFee.type';
import { PricingRule, PricingRuleGrant } from '@/services/innovorder/pricingRule/pricingRule.type';
import { RootState } from '@/redux/store';

export const pricingRuleManageSliceName = 'pricingRuleManage';

export interface PricingRuleManageState {
    grants: PricingRuleGrant[];
    entranceFees: EntranceFee[];
    componentsWithoutTurnover: EntranceFee[];
    pricingRules: PricingRule[];
}

const initialState: PricingRuleManageState = {
    grants: [],
    entranceFees: [],
    componentsWithoutTurnover: [],
    pricingRules: [],
};

export const pricingRuleManageSlice = createSlice<
    PricingRuleManageState,
    SliceCaseReducers<PricingRuleManageState>,
    string
>({
    name: pricingRuleManageSliceName,
    initialState,
    reducers: {
        setGrants: (state, action: PayloadAction<PricingRuleGrant[]>) => {
            return {
                ...state,
                grants: action.payload,
            };
        },
        setEntranceFees: (state, action: PayloadAction<EntranceFee[]>) => {
            return {
                ...state,
                entranceFees: action.payload,
            };
        },
        setComponentsWithoutTurnover: (state, action: PayloadAction<EntranceFee[]>) => {
            return {
                ...state,
                componentsWithoutTurnover: action.payload,
            };
        },
        setPricingRules: (state, action: PayloadAction<PricingRule[]>) => {
            return {
                ...state,
                pricingRules: action.payload,
            };
        },
        addGrant: (state, action: PayloadAction<PricingRuleGrant>) => {
            return {
                ...state,
                grants: [...state.grants, action.payload],
            };
        },
        addEntranceFee: (state, action: PayloadAction<EntranceFee>) => {
            return {
                ...state,
                entranceFees: [...state.entranceFees, action.payload],
            };
        },
        addComponentWithoutTurnover: (state, action: PayloadAction<EntranceFee>) => {
            return {
                ...state,
                componentsWithoutTurnover: [...state.componentsWithoutTurnover, action.payload],
            };
        },
        addPricingRule: (state, action: PayloadAction<PricingRule>) => {
            return {
                ...state,
                pricingRules: [...state.pricingRules, action.payload],
            };
        },
        removeGrant: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                grants: state.grants.filter(({ grantId }) => grantId !== action.payload),
            };
        },
        removeEntranceFee: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                entranceFees: state.entranceFees.filter(({ entranceFeeId }) => entranceFeeId !== action.payload),
            };
        },
        removeComponentWithoutTurnover: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                componentsWithoutTurnover: state.componentsWithoutTurnover.filter(
                    ({ entranceFeeId }) => entranceFeeId !== action.payload,
                ),
            };
        },
        removePricingRule: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                pricingRules: state.pricingRules.filter(({ pricingRuleId }) => pricingRuleId !== action.payload),
            };
        },
    },
});

export const {
    setGrants,
    setEntranceFees,
    setComponentsWithoutTurnover,
    setPricingRules,
    addGrant,
    addEntranceFee,
    addComponentWithoutTurnover,
    addPricingRule,
    removeGrant,
    removeEntranceFee,
    removeComponentWithoutTurnover,
    removePricingRule,
} = pricingRuleManageSlice.actions;

export const getGrants = (state: RootState): PricingRuleGrant[] => state.pricingRuleManage.grants;
export const getEntranceFees = (state: RootState): EntranceFee[] => state.pricingRuleManage.entranceFees;
export const getComponentsWithoutTurnover = (state: RootState): EntranceFee[] =>
    state.pricingRuleManage.componentsWithoutTurnover;
export const getPricingRules = (state: RootState): PricingRule[] => state.pricingRuleManage.pricingRules;
