import { UseFormReturn, useForm } from 'react-hook-form';
import { StepData } from '@/components/atoms/Wizard/Wizard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AddLegalEntityStep } from '@/pages/IO-Pay/Configuration/ConfigurationModal/AddLegalEntityStep/AddLegalEntityStep';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { AddRestaurantStep } from '@/pages/IO-Pay/Configuration/ConfigurationModal/AddRestaurantStep/AddRestaurantStep';
import { useSelector } from 'react-redux';
import { getBrand } from '@/redux/brand';
import { hasLength, isEmail } from '@/utils/form/validate';
import { EditConfigurationFormValues } from './CreateLegalEntityModal.types';
import { CreateLegalEntityModalProps } from './CreateLegalEntityModal';

export const useCreateConfigurationModalVM = ({
    onHide,
    unconfiguredRestaurants,
    hasEwalletConfigured,
}: CreateLegalEntityModalProps) => {
    const intl = useIntl();
    const brand = useSelector(getBrand);
    const hasEducationMode = brand?.options?.EDUCATION_MODE?.isActivated;
    const form: UseFormReturn<EditConfigurationFormValues> = useForm<EditConfigurationFormValues>({
        mode: 'all',
        defaultValues: {
            legalName: '',
            organizationType: 1,
            siret: '',
            addressStreet: '',
            addressStreet2: '',
            addressZipcode: '',
            addressCity: '',
            industryCode: '7223',
            contactEmail: '',
            iban: '',
            restaurantId: null,
        },
    });
    const firstStepKeys: (keyof EditConfigurationFormValues)[] = useMemo(
        () => [
            'addressCity',
            'addressStreet',
            'addressZipcode',
            'siret',
            'iban',
            'contactEmail',
            'organizationType',
            'industryCode',
            'legalName',
        ],
        [],
    );
    const watchFirstStepValues = form.watch(firstStepKeys);
    const [firstStepValues, setFirstStepValues] = useState<typeof watchFirstStepValues>([]);

    const onNext = useCallback(() => {
        const { getValues, setValue } = form;

        const values = getValues();
        let canNext = true;

        firstStepKeys.forEach((key) => {
            // isEmail etc. returns undefined if value is OK, and a string if not
            const specialCases =
                (key === 'contactEmail' && !!isEmail(intl)(values[key] ?? '')) ||
                (key === 'iban' && !!hasLength(27)(intl)(values[key] ?? '')) ||
                (key === 'siret' && !!hasLength(14)(intl)(values[key] ?? '')) ||
                (key === 'addressZipcode' && !!hasLength(5)(intl)(values[key] ?? ''));

            if (specialCases || !values[key]) {
                canNext = false;
            }
        });
        setValue('restaurantIban', getValues('iban'));
        return canNext;
    }, [form, firstStepKeys, intl]);

    const [steps, setSteps] = useState<StepData[]>([
        {
            title: computeText(intl, 'legalEntity.wizard.step1.title'),
            component: <AddLegalEntityStep />,
            onCancel: () => onHide(),
            showHeader: false,
            showNext: true,
            isNextDisabled: true,
            showValidate: false,
            onNext,
        },
        {
            title: computeText(intl, `legalEntity.wizard.step2.title${hasEducationMode ? 'MassCatering' : ''}`),
            component: (
                <AddRestaurantStep
                    unconfiguredRestaurants={unconfiguredRestaurants}
                    hasEwalletConfigured={hasEwalletConfigured}
                />
            ),
            onCancel: () => onHide(),
            showHeader: false,
            showNext: false,
            showPrevious: true,
            showValidate: true,
        },
    ]);

    useEffect(() => {
        const arrayCompare = (a: typeof firstStepValues, b: typeof watchFirstStepValues) => {
            if (a.length !== b.length) {
                return false;
            }
            return a.every((el, i) => el === b[i]);
        };

        if (!arrayCompare(firstStepValues, watchFirstStepValues)) {
            setSteps((prevSteps) => {
                const newSteps = [...prevSteps];
                newSteps[0].isNextDisabled = !onNext();
                return newSteps;
            });
            setFirstStepValues(watchFirstStepValues);
        }
    }, [firstStepValues, onNext, watchFirstStepValues]);

    const onFormValidate = () => {
        const { getValues, setError } = form;
        const { restaurantId } = getValues();

        if (!restaurantId || !unconfiguredRestaurants?.find((restaurant) => restaurant.restaurantId === restaurantId)) {
            setError('restaurantId', {
                type: 'required',
                message: computeText(intl, 'field.required'),
            });
            return false;
        }
        return true;
    };

    return {
        form,
        steps,
        currentStep: 0,
        isLoading: false,
        errorMessage: '',
        onFormValidate,
    };
};
