import styled from '@emotion/styled';

const Container = styled.div`
    flex: 1;
    padding: ${({ theme }) => theme.spacing[4]};
    background-color: ${({ theme }): string => theme.color.grey300};
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing[3]}};
`;

export default Container;
