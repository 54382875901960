import React, { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import RawModal from '@/components/atoms/RawModal';
import { Title } from '@/components/atoms/Title';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { findAllByType } from '@/utils/ReactUtils';
import { SvyButton } from '@/components/savory/SvyButton';
import {
    BodyContainer,
    Container,
    ErrorMessage,
    FooterContainer,
    HeaderContainer,
    HeaderLeftContainer,
} from './Modal.style';

export type ModalProps = React.PropsWithChildren<{
    isOpen: boolean;
    onHide: () => void;
    width?: number;
    height?: number;
    style?: React.CSSProperties;
    title?: string;
    icon?: SVGIcons;
    iconColor?: string;
    error?: string;
    buttons?: ReactElement | ReactElement[];
    headerSideElement?: ReactElement;
    shouldNotHideOnClickOutside?: boolean;
    hideFooter?: boolean;
    closeMessage?: string;
}>;
interface SubComponents {
    Header: typeof HeaderContainer;
    Body: typeof BodyContainer;
    Footer: typeof FooterContainer;
}
const Modal: React.FunctionComponent<React.PropsWithChildren<ModalProps>> & SubComponents = ({
    isOpen,
    onHide,
    hideFooter,
    width,
    height,
    title,
    icon,
    iconColor,
    error,
    buttons,
    children,
    shouldNotHideOnClickOutside,
    closeMessage,
    headerSideElement,
    ...otherProps
}) => {
    const hideMessageId = useMemo(() => {
        if (closeMessage) {
            return closeMessage;
        }
        return buttons ? 'button.cancel' : 'button.close';
    }, [buttons, closeMessage]);

    return (
        <RawModal
            isOpen={isOpen}
            onHide={shouldNotHideOnClickOutside ? undefined : onHide}
            width={width}
            height={height}
        >
            <Container {...otherProps}>
                {Boolean(title) && !findAllByType(children, HeaderContainer).length && (
                    <HeaderContainer>
                        <HeaderLeftContainer>
                            {icon && <SVGIcon icon={icon} size={20} color={iconColor} />}
                            <Title text={title as string} />
                        </HeaderLeftContainer>
                        {headerSideElement}
                    </HeaderContainer>
                )}

                {findAllByType(children, BodyContainer).length ? children : <BodyContainer>{children}</BodyContainer>}

                {!hideFooter && !findAllByType(children, FooterContainer).length && (
                    <FooterContainer>
                        {error && <ErrorMessage text={error} weight="medium" />}
                        <SvyButton variant={buttons ? 'text' : 'default'} color={'primary'} onClick={onHide}>
                            <FormattedMessage id={hideMessageId} />
                        </SvyButton>
                        {buttons}
                    </FooterContainer>
                )}
            </Container>
        </RawModal>
    );
};
Modal.Header = HeaderContainer;
Modal.Body = BodyContainer;
Modal.Footer = FooterContainer;
export default Modal;
