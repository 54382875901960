import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as EmptyStateIcon } from './empty-state.svg';
import { EmptyData, EmptyMessage } from './EmptyState.style';

type Props = {
    emptyMessageId: string;
    size?: 'large' | 'medium';
};

export const EmptyState: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    emptyMessageId,
    size = 'large',
    children,
    ...othersProps
}) => {
    const [width, height] = size === 'large' ? [97, 125] : [62, 80];
    return (
        <EmptyData {...othersProps}>
            <EmptyStateIcon width={width} height={height} role="img" />
            <EmptyMessage>
                <FormattedMessage id={emptyMessageId} />
            </EmptyMessage>
            {children}
        </EmptyData>
    );
};
