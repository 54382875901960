import React, { useState } from 'react';

import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Input } from '../Input';
import { Container, IconContainer } from './PasswordInput.style';

type PasswordInputProps = {
    onChange: (value: string) => void;
    value: string;
};

const PasswordInput: React.FunctionComponent<React.PropsWithChildren<PasswordInputProps>> = ({ onChange, value }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Container>
            <Input
                data-testid="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="login.password"
                value={value}
                onChange={(event) => onChange(event.target.value)}
            />
            <IconContainer onClick={handleClickShowPassword} data-testid="show-icon">
                {showPassword ? (
                    <SVGIcon icon={SVGIcons.EYE_DISABLED} size={20} />
                ) : (
                    <SVGIcon icon={SVGIcons.EYE} size={20} />
                )}
            </IconContainer>
        </Container>
    );
};

export default PasswordInput;
