import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';
import { computeText } from '@/locales/utils';
import type { TableRow } from '@/components/atoms/Table/Table.type';
import { getUserToken } from '@/redux/user';
import { downloadFile } from '@/utils/file/utils';
import {
    useGetCatalogHistoryQuery,
    useLazyDownloadCatalogQuery,
} from '@/services/innovorder/catalog/catalog.endpoints';
import { CatalogHistory } from '@/services/innovorder/catalog/catalog.type';

export type CatalogHistoryTableViewModel = {
    headers: Column<TableRow>[];
    rows: TableRow[];
    isLoading: boolean;
};

export function useCatalogHistoryTableVM(): CatalogHistoryTableViewModel {
    const intl = useIntl();
    const { params } = useRouteMatch<{ posDeviceId: string }>();
    const posDeviceId = Number.parseInt(params.posDeviceId, 10);
    const token = useSelector(getUserToken);
    const { data, isLoading: isGetMenuLoading } = useGetCatalogHistoryQuery({ posDeviceId, token });
    const [downloadCatalog, { isLoading: isDownloadLoading }] = useLazyDownloadCatalogQuery();
    const dateFormatter = Intl.DateTimeFormat(intl.locale, { dateStyle: 'short', timeStyle: 'short' });

    const onDownload = async (history: CatalogHistory) => {
        const response = await downloadCatalog({ posDeviceId, hash: history.hash, token }).unwrap();
        if (response) {
            downloadFile(history.hash, JSON.stringify(response), 'application/json');
        }
    };

    const headers = useMemo(
        () => [
            {
                Header: computeText(intl, 'posDevice.catalogHistory.table.hash'),
                accessor: 'hash',
                disableSortBy: true,
            },
            {
                Header: computeText(intl, 'posDevice.catalogHistory.table.date'),
                accessor: 'timestamp',
                disableSortBy: true,
            },
            {
                Header: computeText(intl, 'posDevice.catalogHistory.table.actions'),
                accessor: 'actions',
                width: '20%',
                disableSortBy: true,
            },
        ],
        [intl],
    );

    const rows: TableRow[] = React.useMemo(
        () =>
            [...(data ?? [])].map((history) => {
                return {
                    hash: {
                        type: 'string',
                        value: {
                            text: history.hash,
                        },
                    },
                    timestamp: {
                        type: 'string',
                        value: {
                            text: dateFormatter.format(new Date(history.timestamp)),
                        },
                    },
                    actions: {
                        type: 'actionList',
                        value: [
                            {
                                children: computeText(intl, 'posDevice.catalogHistory.button.download'),
                                buttonType: 'primary',
                                isLoading: isDownloadLoading,
                                onClick: () => onDownload(history),
                            },
                        ],
                    },
                };
            }),
        [data, intl],
    );

    return {
        headers,
        rows,
        isLoading: isGetMenuLoading,
    };
}
