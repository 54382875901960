import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    BaseRestaurantEntranceFeeData,
    EntranceFeeType,
    EntranceFeeStrategy,
    VatEnum,
    EntranceFeePayload,
} from '@/services/innovorder/entranceFee/entranceFee.type';
import { BaseRestaurantGrantData, GrantPayload, GrantStrategy } from '@/services/innovorder/grant/grant.type';
import { RootState } from '@/redux/store';

export type CustomisationStatus = 'disabled' | 'enabled' | 'customised';

export const sortedStatusMap: Record<CustomisationStatus, number> = {
    customised: 0,
    enabled: 1,
    disabled: 2,
};

type RestaurantCustomisation = {
    restaurantId: number;
    status: CustomisationStatus;
    name: string;
    shifts?: string;
};

export type EntranceFeeRestaurantCustomisations = Partial<BaseRestaurantEntranceFeeData> & RestaurantCustomisation;
export type GrantRestaurantCustomisations = Partial<BaseRestaurantGrantData> & RestaurantCustomisation;
export type CompleteEntranceFeeRestaurantCustomisations = BaseRestaurantEntranceFeeData & RestaurantCustomisation;
export type CompleteGrantRestaurantCustomisations = BaseRestaurantGrantData & RestaurantCustomisation;

export interface GrantAndEntranceFeeState {
    entranceFee: EntranceFeePayload;
    entranceFeeRestaurants: EntranceFeeRestaurantCustomisations[];
    grant: GrantPayload;
    grantRestaurants: GrantRestaurantCustomisations[];
}

const initialState: GrantAndEntranceFeeState = {
    entranceFee: {
        code: '',
        labelTicket: '',
        amount: 0,
        dailyLimit: 0,
        strategy: EntranceFeeStrategy.Always,
        vat: VatEnum.zero,
        type: EntranceFeeType.AdmissionFee,
        brandId: 1,
        entranceFeeId: 1,
        restaurants: [],
    },
    entranceFeeRestaurants: [],
    grant: {
        code: '',
        labelTicket: '',
        amount: 0,
        dailyLimit: 0,
        strategy: GrantStrategy.MinimumAmountAndDailyReached,
        tag: '',
        type: 'ABSOLUTE',
        ceiling: 0,
        threshold: 0,
        brandId: 1,
        grantId: 1,
        restaurants: [],
    },
    grantRestaurants: [],
};

const grandAndEntranceFeeSlice = createSlice({
    name: 'grandAndEntranceFee',
    initialState,
    reducers: {
        updateEntranceFee(state, action: PayloadAction<Partial<EntranceFeePayload>>) {
            state.entranceFee = { ...state.entranceFee, ...action.payload };
        },
        updateEntranceFeeRestaurants(state, action: PayloadAction<EntranceFeeRestaurantCustomisations[]>) {
            state.entranceFeeRestaurants = action.payload;
        },
        updateGrant(state, action: PayloadAction<Partial<GrantPayload>>) {
            state.grant = { ...state.grant, ...action.payload };
        },
        updateGrantRestaurants(state, action: PayloadAction<GrantRestaurantCustomisations[]>) {
            state.grantRestaurants = action.payload;
        },
    },
});

export const getGrantAndEntranceFee = (state: RootState): GrantAndEntranceFeeState => state.grantAndEntranceFee;
export const getEntranceFee = (state: RootState): EntranceFeePayload => getGrantAndEntranceFee(state).entranceFee;
export const getEntranceFeeRestaurants = (state: RootState): EntranceFeeRestaurantCustomisations[] =>
    getGrantAndEntranceFee(state).entranceFeeRestaurants;
export const getGrant = (state: RootState): GrantPayload => getGrantAndEntranceFee(state).grant;
export const getGrantRestaurants = (state: RootState): GrantRestaurantCustomisations[] =>
    getGrantAndEntranceFee(state).grantRestaurants;

export const { updateEntranceFee, updateEntranceFeeRestaurants, updateGrant, updateGrantRestaurants } =
    grandAndEntranceFeeSlice.actions;
export default grandAndEntranceFeeSlice.reducer;
