import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Channels, ConsumptionMode, Taxes } from '@/pages/Analytics/dashboards';
import { UserBrand, UserRestaurant } from '@/services/innovorder/user/user.type';
import { RootState } from '@/redux/store';

export const analyticsFiltersSliceName = 'analyticsFilters';

export type AnalyticsFiltersState = {
    brand: UserBrand | null;
    restaurant?: UserRestaurant[];
    taxes?: Taxes;
    consumptionMode?: ConsumptionMode[];
    channels?: Channels[];
    groups?: string[];
};

const initialState: AnalyticsFiltersState = {
    brand: null,
    restaurant: undefined,
    taxes: undefined,
    consumptionMode: undefined,
    channels: undefined,
    groups: undefined,
};

export const analyticsFiltersSlice = createSlice<
    AnalyticsFiltersState,
    SliceCaseReducers<AnalyticsFiltersState>,
    string
>({
    name: analyticsFiltersSliceName,
    initialState,
    reducers: {
        setBrandFilter: (state, action: PayloadAction<UserBrand | null>) => ({
            ...state,
            brand: action.payload,
        }),
        setRestaurantsFilter: (state, action: PayloadAction<UserRestaurant[]>) => ({
            ...state,
            restaurant: action.payload,
        }),
        setTaxesFilter: (state, action: PayloadAction<Taxes>) => ({
            ...state,
            taxes: action.payload,
        }),
        setConsumptionModeFilter: (state, action: PayloadAction<ConsumptionMode[]>) => ({
            ...state,
            consumptionMode: action.payload,
        }),
        setChannelsFilter: (state, action: PayloadAction<Channels[]>) => ({
            ...state,
            channels: action.payload,
        }),
        setGroupsFilter: (state, action: PayloadAction<string[]>) => ({
            ...state,
            groups: action.payload,
        }),
    },
});

export const {
    setBrandFilter,
    setRestaurantsFilter,
    setTaxesFilter,
    setConsumptionModeFilter,
    setChannelsFilter,
    setGroupsFilter,
} = analyticsFiltersSlice.actions;

export const getAnalyticsFilters = (state: RootState): AnalyticsFiltersState => state.analyticsFilters;
export const getAnalyticsFilterBrand = (state: RootState): UserBrand | null => getAnalyticsFilters(state).brand;
export const getAnalyticsFilterBrandId = (state: RootState): number | null =>
    getAnalyticsFilters(state).brand?.brandId ?? null;
export const getAnalyticsFilterRestaurant = (state: RootState): UserRestaurant[] | undefined =>
    getAnalyticsFilters(state).restaurant;
export const getAnalyticsFilterRestaurantId = (state: RootState): number[] | undefined =>
    getAnalyticsFilters(state).restaurant?.map((restaurant) => restaurant.restaurantId);
export const getAnalyticsFilterTaxes = (state: RootState): Taxes | undefined => state.analyticsFilters.taxes;
export const getAnalyticsFilterConsumptionMode = (state: RootState): ConsumptionMode[] | undefined =>
    state.analyticsFilters.consumptionMode;
export const getAnalyticsFilterChannels = (state: RootState): Channels[] | undefined => state.analyticsFilters.channels;
export const getAnalyticsFilterGroups = (state: RootState): string[] | undefined => state.analyticsFilters.groups;
