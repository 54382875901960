import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Redirect, useParams } from 'react-router';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import Loader from '@/components/atoms/Loader';
import { Routes } from '@/core/router/routes.types';
import { MainDetailContainer, SideDetailContainer } from '@/pages/Order/OrderDetails/OrderDetails.style';
import Card from '@/components/molecules/Card';
import { useGetTransactionByIdQuery } from '@/services/innovorder/io-pay/transactions/transaction.endpoints';
import { getUserBrandId, getUserToken } from '@/redux/user';
import TransactionDetailsHeader from './TransactionDetailsHeader';
import { Container, ContentContainer } from '../Transactions.style';
import FinanceData from './FinanceData';
import WebData from './WebData';
import MainData from './MainData';
import CustomerData from './CustomerData';
import OrderData from './OrderData';
import { DetailContainer } from './TransactionDetail.style';
import PaymentMethodData from './PaymentMethodData/PaymentMethodData';

type Props = Record<string, never>;

const TransactionDetail: React.FunctionComponent<React.PropsWithChildren<Props>> = () => {
    const { transactionId } = useParams<{ transactionId: string }>();
    const theme = useTheme();
    const token = useSelector(getUserToken);
    const brandId = useSelector(getUserBrandId);

    const { data: transaction, isLoading } = useGetTransactionByIdQuery(
        transactionId && brandId
            ? {
                  transactionId: parseInt(transactionId, 10),
                  token,
                  brandId,
              }
            : skipToken,
        { refetchOnMountOrArgChange: true },
    );

    if (isLoading) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    if (!transaction) {
        return <Redirect to={Routes.NotFound} />;
    }

    const featuresWebData = transaction.web?.ip || transaction.web?.referer || transaction.web?.userAgent;

    return (
        <Container data-testid="transaction-detail-page">
            <ContentContainer>
                <TransactionDetailsHeader
                    id={transaction.id.toString()}
                    date={transaction.createdAt}
                    type={transaction.type}
                    status={transaction.status}
                    currency={transaction.payment.currency}
                    amount={transaction.payment.amount}
                />
                <DetailContainer>
                    <MainDetailContainer>
                        <Card titleId="transaction.detail.card.main" isExpandable isInitialOpened>
                            <MainData
                                lastUpdate={transaction.updatedAt}
                                source={transaction?.source}
                                error={transaction.error}
                                brandId={transaction.brand.id}
                                restaurantId={transaction.restaurant?.id}
                                restaurantName={transaction.restaurant?.name}
                            />
                        </Card>
                        <Card titleId="transaction.detail.card.finance" isExpandable isInitialOpened>
                            <FinanceData
                                commissionAmount={transaction.payment.fee}
                                transferId={transaction.transfer?.id}
                                transferRef={transaction.transfer?.reference}
                                reversalAmount={transaction.reversal.amount}
                            />
                        </Card>
                        {featuresWebData && (
                            <Card titleId="transaction.detail.card.web" isExpandable isInitialOpened>
                                <WebData
                                    ip={transaction.web?.ip}
                                    userAgent={transaction.web?.userAgent}
                                    referer={transaction.web?.referer}
                                />
                            </Card>
                        )}
                    </MainDetailContainer>

                    <SideDetailContainer>
                        {transaction.customer && (
                            <Card titleId="transaction.detail.card.customer" isExpandable isInitialOpened>
                                <CustomerData
                                    name={transaction.customer.name}
                                    email={transaction.customer.email}
                                    customerId={transaction.customer.id}
                                    badgeNumber={transaction.customer.badgeNumber}
                                />
                            </Card>
                        )}
                        {transaction.order && (
                            <Card titleId="transaction.detail.card.order" isExpandable isInitialOpened>
                                <OrderData orderId={transaction.order.id} />
                            </Card>
                        )}
                        <Card titleId="transaction.detail.card.paymentMethod" isExpandable isInitialOpened>
                            <PaymentMethodData
                                amount={transaction.payment.amount}
                                paymentMethodType={transaction.paymentMethodType}
                            />
                        </Card>
                    </SideDetailContainer>
                </DetailContainer>
            </ContentContainer>
        </Container>
    );
};

export default TransactionDetail;
