import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import { BillingEntity } from '@/services/innovorder/autoBilling/autoBilling.type';

export const getColumns = (intl: IntlShape, readOnly?: boolean): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'auto.billing.referential.billingEntities.table.label'),
        accessor: 'label',
        width: '100px',
    },
    {
        Header: computeText(intl, 'auto.billing.referential.billingEntities.table.code'),
        accessor: 'code',
        width: '70px',
    },
    {
        Header: computeText(intl, 'auto.billing.referential.billingEntities.table.groups'),
        accessor: 'groups',
        width: '130px',
    },
    {
        Header: computeText(intl, 'auto.billing.referential.billingEntities.table.groupingBy'),
        accessor: 'groupingBy',
        width: '100px',
    },
    ...(!readOnly
        ? [
              {
                  Header: '',
                  accessor: 'editAction',
                  width: '1%',
                  disableSortBy: true,
              },
          ]
        : []),
    ...(!readOnly
        ? [
              {
                  Header: '',
                  accessor: 'removeAction',
                  width: '1%',
                  disableSortBy: true,
              },
          ]
        : []),
];

export const getRows = (
    entities: BillingEntity[],
    intl: IntlShape,
    onDelete: (entity: BillingEntity) => void,
    onEdit: (entity: BillingEntity) => void,
): TableRow[] =>
    entities.map((entity) => ({
        label: {
            type: 'string',
            value: {
                text: entity.name,
            },
        },
        code: {
            type: 'string',
            value: {
                text: entity.code,
            },
        },
        groups: {
            type: 'list',
            value: { listItems: entity.groups },
        },
        groupingBy: {
            type: 'string',
            value: {
                text: `auto.billing.referential.billingEntities.form.groupingBy.options.${entity.groupingBy}`,
            },
        },
        editAction: {
            type: 'action',
            value: {
                children: computeText(intl, 'button.edit'),
                buttonType: 'secondary',
                onClick: () => onEdit(entity),
            },
        },
        removeAction: {
            type: 'action',
            value: {
                children: computeText(intl, 'button.delete'),
                buttonType: 'outlinedPrimary',
                onClick: () => onDelete(entity),
            },
        },
    }));
