export interface GetBrandLegalEntities {
    brandId: number;
}

export interface LegalEntityResponse {
    id: number;
    kycStatus: number;
    brandId: number;
    organisationLegalEntityId: string;
    businessLineId: string;
    transferInstrumentId: string;
    accountHolderId: string;
    legalName: string;
    siret: string;
    organizationType: number;
    industryCode: string;
    addressLine1: string;
    addressLine2: string;
    addressZipcode: string;
    addressCity: string;
    iban: string;

    adyenConfigurations: {
        configurationId: number;
        accountHolder: string;
        balanceAccount: string;
        transferInstrumentId: string;
        storeId: string;
        adyenLegalEntityId: string;
        ecomStoreId: string;
        posStoreReference: string;
        posStoreId: string;
        conecsMid: string;
        edenredMid: string;
        shopperStatement: string;
        iban: string;
        phoneNumber: string;
        addressLine1: string;
        addressLine2: string;
        addressZipcode: string;
        addressCity: string;

        configuration: {
            id: number;
            psp: number;
            restaurantId: number;
            brandId: number;
            name: string;
            isEnabled: boolean;
        };
    }[];
}

export enum KycStatus {
    Rejected = 1,
    ToComplete = 2,
    Pending = 3,
    Approved = 4,
}
