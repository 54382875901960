import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SpotlightItem } from '@/pages/Spotlight/SpotlightItem';
import { computeText } from '@/locales/utils';
import { SpotlightCookie, SpotlightPage } from '@/services/innovorder/spotlight/spotlight.type';
import { getUserBrands, getUserRestaurants } from '@/redux/user';
import { computeSpotlightPageList } from './utils';
import { SpotlightSearch } from './SpotlightSearch';
import { Container, Header, Context, Search, Content, Section, Empty, List } from './Spotlight.style';

type SpotlightProps = {
    spotlightHistory: SpotlightCookie;
    onHide: () => void;
};

export const Spotlight: React.FunctionComponent<React.PropsWithChildren<SpotlightProps>> = ({
    spotlightHistory,
    onHide,
}) => {
    const intl = useIntl();
    const userBrands = useSelector(getUserBrands);
    const userRestaurants = useSelector(getUserRestaurants);
    const [spotlightPageList, setSpotlightPageList] = useState<SpotlightPage[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        const sortedSpotlightPageList = computeSpotlightPageList(userBrands, userRestaurants, spotlightHistory);
        setSpotlightPageList(sortedSpotlightPageList);
    }, [userBrands, userRestaurants, spotlightHistory]);

    const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    return (
        <Container>
            <Header>
                <Context>{/* TODO will contain the spotlight context */}</Context>
                <Search>
                    <SpotlightSearch
                        value={searchValue}
                        placeholder="spotlight.search.placeholder"
                        onChange={handleOnSearchChange}
                    />
                </Search>
            </Header>

            <Content>
                <>
                    <Section>{computeText(intl, 'spotlight.section.history')}</Section>
                    {!spotlightPageList.length ? (
                        <Empty>{computeText(intl, 'spotlight.error.noHistory')}</Empty>
                    ) : (
                        <List>
                            {spotlightPageList.map((page) => (
                                <Link key={page.url} to={page.url} onClick={onHide}>
                                    <SpotlightItem {...page} />
                                </Link>
                            ))}
                        </List>
                    )}
                </>
            </Content>
        </Container>
    );
};
