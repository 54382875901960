import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { IntlShape } from 'react-intl';
import { Column } from 'react-table';

import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import {
    addComponentWithoutTurnover,
    addEntranceFee,
    addGrant,
    addPricingRule,
    getComponentsWithoutTurnover,
    getEntranceFees,
    getGrants,
    getPricingRules,
    removeComponentWithoutTurnover,
    removeEntranceFee,
    removeGrant,
    removePricingRule,
    setGrants,
} from '@/redux/pricingRuleManage';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeCurrency, computePercentage, computeText } from '@/locales/utils';
import { AppDispatch, RootState } from '@/redux/store';
import { EntranceFee, EntranceFeeType } from '@/services/innovorder/entranceFee/entranceFee.type';
import { Grant } from '@/services/innovorder/grant/grant.type';
import { PricingRule, PricingRuleGrant } from '@/services/innovorder/pricingRule/pricingRule.type';
import { EntranceFeeStrategyTranslationMap } from '../../../EntranceFee/EntranceFeeForm/EntranceFeeForm';
import { GrantStrategyTranslationMap } from '../../../Grant/GrantForm/GrantForm';
import { SearchSelectOptionType } from './SelectSearch';

export const getPricingRuleGrantsTableRowsVM = (
    grants: PricingRuleGrant[],
    onDelete: (
        id: number,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action: ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload,
    ) => void,
    intl: IntlShape,
): TableRow[] =>
    grants.map(({ brandId, grantId, code, type, amount, dailyLimit, strategy, labelTicket }) => ({
        code: {
            type: 'string',
            value: { text: code },
        },
        amount: {
            type: 'computedNumber',
            value: {
                text:
                    type === 'ABSOLUTE'
                        ? computeCurrency({ intl, amount })
                        : computePercentage({ amount: amount / CURRENCY_RATE }),
                number: amount,
            },
        },
        dailyLimit: {
            type: 'string',
            value: { text: dailyLimit.toString() },
        },
        strategy: {
            type: 'string',
            value: { text: computeText(intl, String(GrantStrategyTranslationMap[strategy])) },
        },
        labelTicket: {
            type: 'string',
            value: { text: labelTicket },
        },
        action: {
            type: 'action',
            value: {
                children: computeText(intl, 'button.delete'),
                buttonType: 'outlinedPrimary',
                onClick: () => onDelete(grantId, removeGrant),
            },
        },
        linkTo: {
            type: 'linkTo',
            value: { brandId: String(brandId), grantId: String(grantId) },
        },
    }));

export const getPricingRuleEntranceFeesTableRowsVM = (
    entranceFees: EntranceFee[],
    onDelete: (
        id: number,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action: ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload,
    ) => void,
    intl: IntlShape,
): TableRow[] => {
    return entranceFees.map(({ brandId, entranceFeeId, code, amount, dailyLimit, strategy, labelTicket }) => ({
        code: {
            type: 'string',
            value: { text: code },
        },
        amount: {
            type: 'computedNumber',
            value: { text: computeCurrency({ intl, amount }), number: amount },
        },
        dailyLimit: {
            type: 'string',
            value: { text: dailyLimit.toString() },
        },
        strategy: {
            type: 'string',
            value: { text: computeText(intl, String(EntranceFeeStrategyTranslationMap[strategy])) },
        },
        labelTicket: {
            type: 'string',
            value: { text: labelTicket },
        },
        action: {
            type: 'action',
            value: {
                children: computeText(intl, 'button.delete'),
                buttonType: 'outlinedPrimary',
                onClick: () => onDelete(entranceFeeId, removeEntranceFee),
            },
        },
        linkTo: {
            type: 'linkTo',
            value: { brandId: String(brandId), entranceFeeId: String(entranceFeeId) },
        },
    }));
};

export const getPricingRuleComponentsWithoutTurnoverTableRowsVM =
    (
        onDelete: (
            id: number,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload,
        ) => void,
        intl: IntlShape,
    ) =>
    (state: RootState): TableRow[] => {
        const componentsWithoutTurnover: EntranceFee[] = getComponentsWithoutTurnover(state);

        return componentsWithoutTurnover.map(({ brandId, entranceFeeId, code, strategy, labelTicket }) => ({
            code: {
                type: 'string',
                value: { text: code },
            },
            strategy: {
                type: 'string',
                value: { text: computeText(intl, String(EntranceFeeStrategyTranslationMap[strategy])) },
            },
            labelTicket: {
                type: 'string',
                value: { text: labelTicket },
            },
            action: {
                type: 'action',
                value: {
                    children: computeText(intl, 'button.delete'),
                    buttonType: 'outlinedPrimary',
                    onClick: () => onDelete(entranceFeeId, removeComponentWithoutTurnover),
                },
            },
            linkTo: {
                type: 'linkTo',
                value: { brandId: String(brandId), entranceFeeId: String(entranceFeeId) },
            },
        }));
    };

export const getPricingRuleAssociatedPricingRulesTableRowsVM =
    (
        onDelete: (
            id: number,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload,
        ) => void,
        intl: IntlShape,
    ) =>
    (state: RootState): TableRow[] => {
        const associatedPricingRules: PricingRule[] = getPricingRules(state);

        return associatedPricingRules.map(({ brandId, pricingRuleId, code, applyScope }) => ({
            code: {
                type: 'string',
                value: { text: code },
            },
            strategy: {
                type: 'list',
                value: {
                    listItems: applyScope.map((scope) => computeText(intl, `pricingRule.${scope.toLowerCase()}`)),
                },
            },
            labelTicket: {
                type: 'string',
                value: { text: code },
            },
            action: {
                type: 'action',
                value: {
                    children: computeText(intl, 'button.delete'),
                    buttonType: 'outlinedPrimary',
                    onClick: () => onDelete(pricingRuleId, removePricingRule),
                },
            },
            linkTo: {
                type: 'linkTo',
                value: { brandId: String(brandId), pricingRuleId: String(pricingRuleId) },
            },
        }));
    };

const commonTableColumns = (intl: IntlShape) => [
    {
        Header: computeText(intl, 'pricingRule.strategy'),
        accessor: 'strategy',
    },
    {
        Header: computeText(intl, 'pricingRule.labelTicket'),
        accessor: 'labelTicket',
    },
    {
        Header: computeText(intl, 'pricingRule.action'),
        accessor: 'action',
        width: '1%',
        disableSortBy: true,
    },
];
export const getPricingRuleCompositionTableColumnsVM = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'pricingRule.code'),
        accessor: 'code',
    },
    ...commonTableColumns(intl),
];
export const getGrantsFeesTableColumnsVM = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'pricingRule.code'),
        accessor: 'code',
    },
    {
        Header: computeText(intl, 'pricingRule.grants_fees.amount'),
        accessor: 'amount',
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'pricingRule.grants_fees.dailyLimit'),
        accessor: 'dailyLimit',
        disableSortBy: true,
    },
    ...commonTableColumns(intl),
];

export const swapRows = (data: PricingRuleGrant[], sourceId: number, destinationId: number): PricingRuleGrant[] => {
    const newData = [...data];
    const [movedRow] = newData.splice(sourceId, 1);
    newData.splice(destinationId, 0, movedRow);

    return newData;
};

export const applyPosition = (data: PricingRuleGrant[]): PricingRuleGrant[] =>
    data.map((grant, index) => ({
        ...grant,
        position: index,
    }));

export const reorderGrantsAfterDragVM =
    (dispatch: AppDispatch) =>
    (state: RootState): ((sourceId: number, destinationId: number) => void) => {
        const grants: PricingRuleGrant[] = getGrants(state);

        return (sourceId: number, destinationId: number) => {
            const newData = swapRows(grants, sourceId, destinationId);
            const finalData = applyPosition(newData);
            dispatch(setGrants(finalData));
        };
    };

export const computeGrantsSelectOptionsVM =
    (allGrants: Grant[] | undefined) =>
    (state: RootState): SearchSelectOptionType[] => {
        if (!allGrants) {
            return [];
        }

        const grants: Grant[] = getGrants(state);
        const grantIds: number[] = grants.map(({ grantId }) => grantId);

        const grantsSelectOptions: SearchSelectOptionType[] = allGrants.reduce(
            (result: SearchSelectOptionType[], { grantId, code }) =>
                grantIds.includes(grantId)
                    ? result
                    : [
                          ...result,
                          {
                              value: grantId,
                              label: code,
                          },
                      ],
            [],
        );

        return grantsSelectOptions;
    };

export const computeEntranceFeesSelectOptionsVM =
    (allEntranceFee: EntranceFee[] | undefined) =>
    (state: RootState): SearchSelectOptionType[] => {
        if (!allEntranceFee) {
            return [];
        }

        const entranceFees: EntranceFee[] = getEntranceFees(state);
        const entranceFeeIds: number[] = entranceFees.map(({ entranceFeeId }) => entranceFeeId);

        const entranceFeesSelectOptions: SearchSelectOptionType[] = allEntranceFee.reduce(
            (result: SearchSelectOptionType[], { entranceFeeId, code, type }) =>
                type !== EntranceFeeType.AdmissionFee || entranceFeeIds.includes(entranceFeeId)
                    ? result
                    : [
                          ...result,
                          {
                              value: entranceFeeId,
                              label: code,
                          },
                      ],
            [],
        );

        return entranceFeesSelectOptions;
    };

export const computeComponentsWithoutTurnoverSelectOptionsVM =
    (allEntranceFee: EntranceFee[] | undefined) =>
    (state: RootState): SearchSelectOptionType[] => {
        if (!allEntranceFee) {
            return [];
        }

        const componentsWithoutTurnover: EntranceFee[] = getComponentsWithoutTurnover(state);
        const componentsWithoutTurnoverIds: number[] = componentsWithoutTurnover.map(
            ({ entranceFeeId }) => entranceFeeId,
        );

        const componentsWithoutTurnoverSelectOptions: SearchSelectOptionType[] = allEntranceFee.reduce(
            (result: SearchSelectOptionType[], { entranceFeeId, code, type }) =>
                type === EntranceFeeType.AdmissionFee || componentsWithoutTurnoverIds.includes(entranceFeeId)
                    ? result
                    : [
                          ...result,
                          {
                              value: entranceFeeId,
                              label: code,
                          },
                      ],
            [],
        );

        return componentsWithoutTurnoverSelectOptions;
    };

export const computeAssociatedPricingRulesSelectOptionsVM =
    (allPricingRules: PricingRule[] | undefined, currentPricingRuleId: number) =>
    (state: RootState): SearchSelectOptionType[] => {
        if (!allPricingRules) {
            return [];
        }

        const pricingRules: PricingRule[] = getPricingRules(state);
        const pricingRuleIds: number[] = pricingRules.map(({ pricingRuleId }) => pricingRuleId);

        const pricingRulesSelectOptions: SearchSelectOptionType[] = allPricingRules.reduce(
            (result: SearchSelectOptionType[], { pricingRuleId, code }) =>
                pricingRuleIds.includes(pricingRuleId) || pricingRuleId === currentPricingRuleId
                    ? result
                    : [
                          ...result,
                          {
                              value: pricingRuleId,
                              label: code,
                          },
                      ],
            [],
        );

        return pricingRulesSelectOptions;
    };

export const handleSelectGrantOptionVM = (
    dispatch: AppDispatch,
    grants: Grant[] | undefined,
    option: SearchSelectOptionType,
    displayedGrantsCount: number,
): void => {
    const selectGrant = grants?.find(({ grantId }) => grantId === option.value);
    selectGrant && dispatch(addGrant({ ...selectGrant, position: displayedGrantsCount }));
};

export const handleSelectEntranceFeeOptionVM = (
    dispatch: AppDispatch,
    entranceFees: EntranceFee[] | undefined,
    option: SearchSelectOptionType,
): void => {
    const selectEntranceFee = entranceFees?.find(
        ({ entranceFeeId, type }) => entranceFeeId === option.value && type === EntranceFeeType.AdmissionFee,
    );
    selectEntranceFee && dispatch(addEntranceFee(selectEntranceFee));
};

export const handleSelectComponentWithoutTurnoverOptionVM = (
    dispatch: AppDispatch,
    entranceFees: EntranceFee[] | undefined,
    option: SearchSelectOptionType,
): void => {
    const selectComponentWithoutTurnover = entranceFees?.find(
        ({ entranceFeeId, type }) => entranceFeeId === option.value && type !== EntranceFeeType.AdmissionFee,
    );
    selectComponentWithoutTurnover && dispatch(addComponentWithoutTurnover(selectComponentWithoutTurnover));
};

export const handleSelectAssociatedPricingRuleOptionVM = (
    dispatch: AppDispatch,
    pricingRules: PricingRule[] | undefined,
    option: SearchSelectOptionType,
): void => {
    const selectAssociatedPricingRule = pricingRules?.find(({ pricingRuleId }) => pricingRuleId === option.value);
    selectAssociatedPricingRule && dispatch(addPricingRule(selectAssociatedPricingRule));
};
