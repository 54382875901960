import React from 'react';
import { useIntl } from 'react-intl';
import { RestaurantModule } from '@/services/innovorder/restaurant/restaurant.type';
import { Module, ModuleTpe } from '@/services/innovorder/modules/modules.type';
import { ConsumptionMode } from '@/services/innovorder/consumptionMode/consumptionMode.type';
import { PaymentMethod, PaymentMethodCode } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { Brand } from '@/services/innovorder/brand/brand.type';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { computeText } from '@/locales/utils';
import { IOPayConfig } from '@/services/innovorder/io-pay/configurations/configurations.type';
import { ENV } from '@/utils/env';
import { IntegrationConfig, Tab } from '../../components/Tab/Tab';
import { getPaymentMethod } from '../../components/Tab/Tab.utils';

export type KioskProps = {
    channelId: ChannelId;
    consumptionModes: ConsumptionMode[];
    paymentMethods: PaymentMethod[];
    isLoadingConsumptionModes: boolean;
    isLoadingSave: boolean;
    restaurantModules?: RestaurantModule;
    ingenicoConfig?: Module;
    neptingConfig?: Module;
    stripeConfig?: Module;
    ioPayConfig?: IOPayConfig;
    ingenicoTpeList?: ModuleTpe[];
    brand?: Brand;
    onChange: (param: PaymentMethod[]) => void;
    onCancel: () => void;
    onSave: () => void;
};

const PaymentMethodKioskTab: React.FunctionComponent<KioskProps> = ({
    channelId,
    consumptionModes,
    isLoadingConsumptionModes,
    paymentMethods,
    isLoadingSave,
    restaurantModules,
    ingenicoConfig,
    neptingConfig,
    stripeConfig,
    ioPayConfig,
    ingenicoTpeList,
    brand,
    onChange,
    onCancel,
    onSave,
}) => {
    const intl = useIntl();

    const isIngenicoConfigured = !!ingenicoConfig && !!ingenicoTpeList && ingenicoTpeList.length > 0;
    const isStripeConfigured = !!stripeConfig?.moduleStripeId;
    const isIopayConfigured = !!ioPayConfig?.isEnabled;
    const isNeptingConfigured = !!neptingConfig?.merchantId;
    const isYavinConfigured = !!getPaymentMethod(paymentMethods, PaymentMethodCode.Yavin, channelId).consumptionModes
        .length;
    const isEwalletConfigured =
        !!restaurantModules?.[channelId].ordering.flow.isLoginEnabled &&
        !!brand?.options?.EDUCATION_MODE?.isActivated &&
        !!brand?.moduleStripeId;
    const isExternalIdActivated = !!brand?.options?.EXTERNAL_IDS_MAPPING?.isActivated;

    const integrations: IntegrationConfig[] = [
        {
            type: 'ingenico',
            isFull: false,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/ingenico/logo_group.jpg`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.Ingenico, channelId),
            paymentMethodCode: PaymentMethodCode.Ingenico,
            showIntegration: true,
            showConfigButton: !isIngenicoConfigured,
            showConsumptionModes: isIngenicoConfigured,
        },
        {
            type: 'stripe',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/payment/stripe-payment.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.Stripe, channelId),
            paymentMethodCode: PaymentMethodCode.Stripe,
            showIntegration: true,
            showConfigButton: !isStripeConfigured && !isIopayConfigured,
            showConsumptionModes: isStripeConfigured,
            message: isIopayConfigured ? computeText(intl, 'configure.stripe.iopayConfigured') : undefined,
        },
        {
            type: 'iopay',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/payment/adyen.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.IOPay, channelId),
            paymentMethodCode: PaymentMethodCode.IOPay,
            showIntegration: true,
            showConfigButton: !isIopayConfigured,
            showConsumptionModes: isIopayConfigured,
        },
        {
            type: 'nepting',
            isFull: false,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/nepting-logo.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.Nepting, channelId),
            paymentMethodCode: PaymentMethodCode.Nepting,
            showIntegration: true,
            showConfigButton: !isNeptingConfigured && !isYavinConfigured,
            showConsumptionModes: isNeptingConfigured && !isYavinConfigured,
            message: isYavinConfigured ? computeText(intl, 'configure.nepting.yavinConfigured') : undefined,
        },
        {
            type: 'yavin',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/yavin-with-text.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.Yavin, channelId),
            paymentMethodCode: PaymentMethodCode.Yavin,
            showIntegration: true,
            showConfigButton: false,
            showConsumptionModes: !isNeptingConfigured,
            message: isNeptingConfigured ? computeText(intl, 'configure.yavin.neptingConfigured') : undefined,
        },
        {
            type: 'ewallet',
            isFull: true,
            logoPath: `${ENV.STATIC_URL}/dashboard/images/payment/e-wallet-illustration.png`,
            paymentMethod: getPaymentMethod(paymentMethods, PaymentMethodCode.EWallet, channelId),
            paymentMethodCode: PaymentMethodCode.EWallet,
            showIntegration: isEwalletConfigured,
            showConfigButton: false,
            showConsumptionModes: isEwalletConfigured,
        },
    ];

    return (
        <Tab
            channelId={channelId}
            isLoadingConsumptionModes={isLoadingConsumptionModes}
            integrations={integrations}
            paymentMethods={paymentMethods}
            consumptionModes={consumptionModes}
            isLoadingSave={isLoadingSave}
            isExternalIdActivated={isExternalIdActivated}
            onChange={onChange}
            onSave={onSave}
            onCancel={onCancel}
        />
    );
};

export { PaymentMethodKioskTab };
