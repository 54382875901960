import React from 'react';
import { UserRestaurant } from '@/services/innovorder/user/user.type';
import { ConfigurationType } from '../types';
import { CreateLegalEntityModal } from './CreateLegalEntityModal/CreateLegalEntityModal';

type ConfigurationModalProps = {
    action: ConfigurationType | null;
    legalEntityId?: string;
    unconfiguredRestaurants?: UserRestaurant[];
    hasEwalletConfigured?: boolean;
    onHide: () => void;
};

const getActionModal = (action: ConfigurationType | null) => {
    switch (action) {
        case 'showCreateLegalEntityModal':
            return CreateLegalEntityModal;
        default:
            return null;
    }
};

export const ConfigurationModal: React.FunctionComponent<React.PropsWithChildren<ConfigurationModalProps>> = ({
    action,
    onHide,
    legalEntityId,
    unconfiguredRestaurants,
    hasEwalletConfigured,
}) => {
    const ActionModal = getActionModal(action);

    if (!ActionModal) {
        return ActionModal;
    }

    return (
        <ActionModal
            onHide={onHide}
            legalEntityId={legalEntityId}
            unconfiguredRestaurants={unconfiguredRestaurants}
            hasEwalletConfigured={hasEwalletConfigured}
        />
    );
};
