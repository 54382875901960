import styled from '@emotion/styled';

export const FormCard = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    gap: ${({ theme }) => theme.spacing[3]};
    line-height: 20px;
    height: fit-content;
`;
