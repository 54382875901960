import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.color.white100};
`;

export const Header = styled.div`
    background-color: ${({ theme }): string => theme.color.grey200};
    color: ${({ theme }): string => theme.color.black};
    font-weight: ${({ theme }): number => theme.font.weight.medium};
    font-size: ${({ theme }): string => theme.font.size.large};
    padding: ${({ theme }): string => theme.spacing[3]} ${({ theme }): string => theme.spacing[4]};
`;

export const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: inherit;
    padding: ${({ theme }) => theme.spacing[2]};
`;

export const Footer = styled.div`
    width: auto;
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }): string => theme.spacing[2]};
    padding: ${({ theme }): string => theme.savory.spacing[3]};
`;

export const FooterLeft = styled.div`
    display: flex;
`;

export const FooterRight = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${({ theme }): string => theme.spacing[2]};
`;

export const ErrorMessage = styled(Text)`
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[3]}`};
    color: ${({ theme }) => theme.color.bloodyMary};
    white-space: pre-line;
`;
