import { useSelector } from 'react-redux';
import { useGetFileSignedUrlMutation } from '@/services/innovorder/reportGeneration/reportGeneration.endpoints';
import { getUserToken } from '@/redux/user';

const downloadFromUrl = (url: string): void => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
    link.remove();
};

const useDownloadReportGenerationFile = (): ((reportGenerationId: number) => void) => {
    const token = useSelector(getUserToken);
    const [getFileSignedUrl] = useGetFileSignedUrlMutation();

    return (reportGenerationId: number): void => {
        getFileSignedUrl({ token, reportGenerationId }).then((response) => {
            if ('data' in response) {
                downloadFromUrl(response.data.url);
            }
        });
    };
};

export default useDownloadReportGenerationFile;
