import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    GetBrandChannelsPayload,
    GetChannelsResponse,
    GetRestaurantChannelsPayload,
    ActivateChannelOnRestaurantPayload,
    ActivateChannelOnBrandPayload,
    GetKdsPayload,
    GetRestaurantKdsResponse,
} from './channel.type';

const channelApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getRestaurantChannels: build.query<GetChannelsResponse, ApiRequest<GetRestaurantChannelsPayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/restaurants/${restaurantId}/channels`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.Channels],
            transformResponse: (response: ApiResponse<GetChannelsResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        activateChannelOnRestaurant: build.mutation<
            ApiResponse<Record<string, never>>,
            ApiRequest<ActivateChannelOnRestaurantPayload>
        >({
            query: ({ restaurantId, channelId, token }) => {
                return {
                    url: `/restaurants/${restaurantId}/channels/${channelId}`,
                    method: 'PUT',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            invalidatesTags: [ApiTagTypes.Channels],
        }),
        deactivateChannelOnRestaurant: build.mutation<
            ApiResponse<Record<string, never>>,
            ApiRequest<ActivateChannelOnRestaurantPayload>
        >({
            query: ({ restaurantId, channelId, token }) => {
                return {
                    url: `/restaurants/${restaurantId}/channels/${channelId}`,
                    method: 'DELETE',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            invalidatesTags: [ApiTagTypes.Channels],
        }),

        getBrandChannels: build.query<GetChannelsResponse, ApiRequest<GetBrandChannelsPayload>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/channels`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.Channels],
            transformResponse: (response: ApiResponse<GetChannelsResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        activateChannelOnBrand: build.mutation<
            ApiResponse<Record<string, never>>,
            ApiRequest<ActivateChannelOnBrandPayload>
        >({
            query: ({ brandId, channelId, token }) => {
                return {
                    url: `/brands/${brandId}/channels/${channelId}`,
                    method: 'PUT',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            invalidatesTags: [ApiTagTypes.Channels],
        }),
        deactivateChannelOnBrand: build.mutation<
            ApiResponse<Record<string, never>>,
            ApiRequest<ActivateChannelOnBrandPayload>
        >({
            query: ({ brandId, channelId, token }) => {
                return {
                    url: `/brands/${brandId}/channels/${channelId}`,
                    method: 'DELETE',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            invalidatesTags: [ApiTagTypes.Channels],
        }),
        getRestaurantKds: build.query<ApiResponse<GetRestaurantKdsResponse>, ApiRequest<GetKdsPayload>>({
            query: ({ restaurantId, token }) => {
                return {
                    url: `/restaurants/${restaurantId}/kitchen_display`,
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            extraOptions: { maxRetries: 3 },
            providesTags: [ApiTagTypes.Channels],
        }),
        activateRestaurantKds: build.mutation<ApiResponse<Record<string, never>>, ApiRequest<GetKdsPayload>>({
            query: ({ restaurantId, token }) => {
                return {
                    url: `/restaurants/${restaurantId}/kitchen_display`,
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            invalidatesTags: [ApiTagTypes.Channels],
        }),
        deactivateRestaurantKds: build.mutation<ApiResponse<Record<string, never>>, ApiRequest<GetKdsPayload>>({
            query: ({ restaurantId, token }) => {
                return {
                    url: `/restaurants/${restaurantId}/kitchen_display`,
                    method: 'DELETE',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            invalidatesTags: [ApiTagTypes.Channels],
        }),
    }),
});

export const {
    useGetRestaurantChannelsQuery,
    useLazyGetRestaurantChannelsQuery,
    useLazyGetBrandChannelsQuery,
    useActivateChannelOnBrandMutation,
    useDeactivateChannelOnBrandMutation,
    useLazyGetRestaurantKdsQuery,
    useActivateRestaurantKdsMutation,
    useDeactivateRestaurantKdsMutation,
    useActivateChannelOnRestaurantMutation,
    useDeactivateChannelOnRestaurantMutation,
} = channelApi;
