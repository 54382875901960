import React, { useState, useEffect, useMemo } from 'react';

import { SavingBar } from '@/components/atoms/SavingBar';
import { Title } from '@/components/atoms/Title';
import NavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import { Switch } from '@/components/atoms/Switch';
import {
    useGetRestaurantDetailsQuery,
    useUpdateRestaurantOptionsMutation,
} from '@/services/innovorder/restaurant/restaurant.endpoints';
import { useAuthCookieHandler } from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { useParams } from 'react-router';
import { RestaurantOptionName, RestaurantOptionsPayload } from '@/services/innovorder/restaurant/restaurant.type';
import Loader from '@/components/atoms/Loader';
import { useSelector } from 'react-redux';
import { getUseOptionIncludeDepositsInTurnover } from '@/redux/launchDarkly';
import { TitleContainer, Container, Item } from './RestaurantOptionsTab.style';

const GENERAL_OPTIONS: Partial<Record<RestaurantOptionName, string>> = {
    [RestaurantOptionName.INCLUDE_DEPOSITS_IN_TURNOVER]: 'restaurant.options.includeDepositsInTurnover',
};

type ParamTypes = {
    restaurantId: string;
};

export const RestaurantOptionsTab: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { token } = useAuthCookieHandler();
    const { restaurantId } = useParams<ParamTypes>();
    const isOptionIncludeDepositInTurnoverActive = useSelector(getUseOptionIncludeDepositsInTurnover);
    const [options, setOptions] = useState<RestaurantOptionsPayload>({});
    const {
        data: restaurant,
        isLoading: isRestaurantLoading,
        refetch,
    } = useGetRestaurantDetailsQuery({ restaurantId, token }, { refetchOnMountOrArgChange: true });
    const [updateOptions, { isLoading: isLoadingUpdate }] = useUpdateRestaurantOptionsMutation();

    const generalOptions = useMemo((): Partial<Record<RestaurantOptionName, string>> => {
        return isOptionIncludeDepositInTurnoverActive ? GENERAL_OPTIONS : {};
    }, [isOptionIncludeDepositInTurnoverActive]);

    const restaurantOptions = useMemo(() => {
        return restaurant?.options ?? {};
    }, [restaurant]);

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'restaurants'), []);

    const changeCount = useMemo(
        () =>
            Object.keys(generalOptions).reduce(
                (count, optionName) =>
                    restaurantOptions[optionName as RestaurantOptionName]?.isActivated !==
                    options?.[optionName as RestaurantOptionName]?.isActivated
                        ? count + 1
                        : count,
                0,
            ),
        [generalOptions, restaurantOptions, options],
    );

    const handleChange = (optionName: RestaurantOptionName) => (value: boolean) => {
        const newOptions: RestaurantOptionsPayload = {
            ...options,
            [optionName]: { ...options?.[optionName], isActivated: value },
        };
        if (!restaurantOptions[optionName as RestaurantOptionName] && !value) {
            delete newOptions?.[optionName];
        }
        setOptions(newOptions);
    };

    const handleSave = () => {
        updateOptions({ restaurantId, options, token }).then(() => {
            refetch();
        });
    };

    const handleCancel = () => {
        setOptions(restaurantOptions || {});
    };

    useEffect(() => {
        setOptions(restaurantOptions || {});
    }, [restaurantOptions]);

    return (
        <>
            <NavigationTabs routes={tabRoutes}>
                <TitleContainer>
                    <Title text="restaurant.options.main" />
                </TitleContainer>
                <Container>
                    {isRestaurantLoading ? (
                        <Loader withContainer />
                    ) : (
                        Object.keys(generalOptions).map((optionName) => (
                            <Item key={optionName}>
                                <Switch
                                    labelId={generalOptions[optionName as RestaurantOptionName]}
                                    onChange={handleChange(optionName as RestaurantOptionName)}
                                    value={options?.[optionName as RestaurantOptionName]?.isActivated}
                                />
                            </Item>
                        ))
                    )}
                </Container>
            </NavigationTabs>
            <SavingBar
                onSave={handleSave}
                changesCount={changeCount}
                onCancel={handleCancel}
                loading={isLoadingUpdate}
            />
        </>
    );
};
