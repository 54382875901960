import styled from '@emotion/styled';

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 ${({ theme }) => theme.spacing[3]};
    right: ${({ theme }) => theme.spacing[1]};
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing[4]} 0;
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    span {
        margin-right: ${({ theme }) => theme.spacing[3]};
    }
`;

export const Title = styled.h3`
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => `${theme.font.weight.medium}`};
    font-size: ${({ theme }) => theme.font.size.large};
    color: ${({ theme }) => theme.color.white100};
`;
