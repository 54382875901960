import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDateRangeValidation, DEFAULT_MAX_DATE_RANGE } from '@/utils/daterange';
import dayjs from 'dayjs';
import { computeText } from '@/locales/utils';
import Modal from '@/components/atoms/Modal';
import { Button } from '@/components/atoms/Button';
import { min } from 'date-fns';
import { DateRange } from '../types';
import { StyledCalendar } from './CalendarModal.style';

interface CalendarModalProps {
    isOpen: boolean;
    initialDateRange: DateRange;
    submitDates: (dates: DateRange) => void;
    close: () => void;
    maxRangeInDays?: number;
}

const CalendarModal: React.FunctionComponent<CalendarModalProps> = ({
    isOpen,
    initialDateRange: { startDate: initialStartDate, endDate: initialEndDate },
    submitDates,
    close,
    maxRangeInDays = DEFAULT_MAX_DATE_RANGE,
}) => {
    const intl = useIntl();
    const [{ startDate, endDate }, setDates] = useState<DateRange>({
        startDate: initialStartDate,
        endDate: initialEndDate,
    });

    const dateRangeValidation = useDateRangeValidation({ startDate, endDate }, maxRangeInDays);

    const { isDateRangeValid, shouldShowDateRangeErrorMessage } = dateRangeValidation;

    const openToDate = useMemo(() => {
        const beginOfPreviousMonth = dayjs(new Date()).subtract(1, 'month').startOf('month').toDate();
        return min([initialStartDate, beginOfPreviousMonth]);
    }, [initialStartDate]);

    const maxDate = useMemo(() => new Date(), []);

    const validateAndSubmit = useCallback(() => {
        if (isDateRangeValid) {
            submitDates({ startDate, endDate });
        } else {
            close();
        }
    }, [close, endDate, isDateRangeValid, startDate, submitDates]);

    const dateRangeErrorMessage = () =>
        shouldShowDateRangeErrorMessage
            ? computeText(intl, 'report.calendarModal.dateRage.errorMessage', { maxRangeInDays })
            : undefined;

    const onCalendarChange = (dates: { startDate: Date | null; endDate: Date | null }) => {
        if (dates.startDate && dates.endDate) {
            setDates({ startDate: dates.startDate, endDate: dates.endDate });
        }
    };

    const submitButton = useMemo(
        () => (
            <Button key="submit-button" buttonType="primary" onClick={validateAndSubmit} disabled={!isDateRangeValid}>
                <FormattedMessage id="report.calendarModal.submit" />
            </Button>
        ),
        [isDateRangeValid, validateAndSubmit],
    );

    return (
        <Modal
            isOpen={isOpen}
            width={680}
            height={550}
            onHide={close}
            title="report.calendarModal.title"
            error={dateRangeErrorMessage()}
            buttons={[submitButton]}
        >
            <StyledCalendar
                startDate={startDate}
                endDate={endDate}
                open={isOpen}
                monthShown={2}
                inline
                maxDate={maxDate}
                openToDate={openToDate}
                selectsRange={true}
                onChange={onCalendarChange}
            />
        </Modal>
    );
};

export default CalendarModal;
