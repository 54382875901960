import React, { useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';

import { Input } from '@/components/atoms/Form/Input';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { PaymentMethod } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { ConsumptionMode, CONSUMPTION_MODE_FLAGS } from '@/services/innovorder/consumptionMode/consumptionMode.type';
import { ReactComponent as Drag } from './drag.svg';
import { DraggableRow, ToggleContainer, ToggleIcon, Label } from './DraggablePaymentMethodsRow.style';
import { ConsumptionModes } from '../ConsumptionModes';

type DraggablePaymentMethodsRowProps = {
    item: PaymentMethod;
    index: number;
    consumptionModes: ConsumptionMode[];
    isExternalIdActivated: boolean;
    onChange?: (param: PaymentMethod) => void;
    isDisabled?: boolean;
};

export const DraggablePaymentMethodsRow: React.FunctionComponent<
    React.PropsWithChildren<DraggablePaymentMethodsRowProps>
> = ({ item, index, consumptionModes, isExternalIdActivated, onChange, isDisabled }) => {
    const [editable, setEditable] = useState(false);

    const onPaymentMethodChange =
        (field: string) =>
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (!onChange) {
                return;
            }

            const { consumptionModesFlags, paymentMethodId, consumptionModes = [] } = item;
            const currentFlags =
                consumptionModesFlags || consumptionModes.map(({ type }) => CONSUMPTION_MODE_FLAGS[`FLAG_${type}`]);

            onChange({
                ...item,
                action: paymentMethodId ? 'update' : 'create',
                consumptionModeSumFlag: currentFlags.reduce((accumulator, curr) => accumulator + curr, 0),
                consumptionModesFlags: currentFlags,
                [field]: value,
            });
        };

    return (
        <Draggable draggableId={`${item.code}-${index}`} key={`${item.code}-${index}`} index={index}>
            {(providedDrag, snapshotDrag) => {
                return (
                    <DraggableRow
                        {...providedDrag.draggableProps}
                        ref={providedDrag.innerRef}
                        isDragging={snapshotDrag.isDragging}
                    >
                        <ToggleContainer>
                            <ToggleIcon {...providedDrag.dragHandleProps} data-testid="dragToogle">
                                <Drag width={32} height={32} />
                            </ToggleIcon>
                        </ToggleContainer>
                        <Label>
                            <Input
                                disabled={!editable}
                                type="text"
                                onChange={onPaymentMethodChange('label')}
                                placeholder={item.code}
                                value={item.label}
                            />
                        </Label>
                        {isExternalIdActivated && (
                            <Label>
                                <Input
                                    disabled={!editable}
                                    type="text"
                                    onChange={onPaymentMethodChange('externalId')}
                                    placeholder="paymentMethods.externalId"
                                    value={item.externalId}
                                />
                            </Label>
                        )}
                        <td>
                            {!isDisabled && (
                                <SVGIcon onClick={() => setEditable(!editable)} icon={SVGIcons.PEN} size={18} />
                            )}
                        </td>
                        <td>
                            <ConsumptionModes
                                paymentMethod={item}
                                consumptionModes={consumptionModes}
                                onChange={onChange}
                                isDisabled={isDisabled}
                            />
                        </td>
                    </DraggableRow>
                );
            }}
        </Draggable>
    );
};
