import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
    width: 1000px;
    display: flex;
    margin: ${({ theme }) => theme.spacing[3]}; 0px;

    & > * {
        margin-right: ${({ theme }) => theme.spacing[3]};
    }
`;
