import { useScanMenuMutation } from '@/services/innovorder/menu/menu.endpoints';
import { downloadFileFromUrl } from '@/utils/file/utils';
import { UseFormReturn, useForm } from 'react-hook-form';

type AIMenuScanFormValue = {
    file: File;
};

type IAScanModalVMProps = {
    onHide: () => void;
};

export const useAIMenuScanModalVm = ({
    onHide,
}: IAScanModalVMProps): { form: UseFormReturn<AIMenuScanFormValue>; handleSubmit: () => void; isLoading: boolean } => {
    const [scanMenu, { isLoading }] = useScanMenuMutation();
    const form: UseFormReturn<AIMenuScanFormValue> = useForm<AIMenuScanFormValue>({
        mode: 'all',
        defaultValues: {
            file: undefined,
        },
    });

    const handleSubmit = async () => {
        const { file } = form.getValues();
        const formData = new FormData();
        formData.append('file', file);
        const response = await scanMenu({ formData });

        if ('data' in response) {
            downloadFileFromUrl(response.data.data);
        }

        onHide();
    };

    return { form, handleSubmit, isLoading };
};
