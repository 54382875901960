import React from 'react';
import { FormattedMessage } from 'react-intl';

import { StyledLabel } from './Label.style';

type LabelProps = {
    inputId?: string;
    labelId: string;
    disabled?: boolean;
    required?: boolean;
};

const Label: React.FunctionComponent<React.PropsWithChildren<LabelProps>> = ({
    inputId,
    labelId,
    required,
    ...otherProps
}) => (
    <StyledLabel htmlFor={inputId ?? ''} {...otherProps}>
        <FormattedMessage id={labelId} />
        {required && <sup>*</sup>}
    </StyledLabel>
);

export default Label;
