import React from 'react';
import { Row, Cell } from 'react-table';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';

import { ReactComponent as Drag } from './drag.svg';
import { TableRow, TableCell } from '../Table.type';
import { DraggableRow, ToggleContainer, ToggleIcon } from './DraggableTable.style';

type DraggableTableProps = {
    page: Row<TableRow>[];
    emptyMessageId: string;
    prepareRow: (row: Row<TableRow>) => void;
    isCellClickable: (cell: Cell<TableRow, TableCell>) => boolean;
    handleOnCellClick: (cell: Cell<TableRow, TableCell>, event?: React.MouseEvent<HTMLTableCellElement>) => void;
    handleDragEnd: (result: DropResult) => void;
};

export const DraggableTable: React.FunctionComponent<React.PropsWithChildren<DraggableTableProps>> = ({
    page,
    emptyMessageId,
    prepareRow,
    isCellClickable,
    handleOnCellClick,
    handleDragEnd,
}) => {
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="table-body">
                {(providedDrop, snapshotDrop) => (
                    <tbody ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            const { key: rowKey, ...rowProps } = row.getRowProps();
                            return (
                                <Draggable
                                    draggableId={`${rowKey.toString()}-${emptyMessageId}`}
                                    key={rowKey}
                                    index={index}
                                >
                                    {(providedDrag, snapshotDrag) => {
                                        return (
                                            <DraggableRow
                                                {...rowProps}
                                                {...providedDrag.draggableProps}
                                                ref={providedDrag.innerRef}
                                                isDragging={snapshotDrag.isDragging}
                                            >
                                                <ToggleContainer>
                                                    <ToggleIcon
                                                        {...providedDrag.dragHandleProps}
                                                        data-testid="dragToogle"
                                                    >
                                                        <Drag width={32} height={32} />
                                                    </ToggleIcon>
                                                </ToggleContainer>
                                                {row.cells.map((cell) => {
                                                    const { key: cellKey, ...cellProps } = cell.getCellProps();
                                                    return (
                                                        <td
                                                            key={cellKey}
                                                            {...cellProps}
                                                            className={isCellClickable(cell) ? 'clickable' : ''}
                                                            onClick={(event) => handleOnCellClick(cell, event)}
                                                        >
                                                            {cell.render('Cell', {
                                                                dragHandleProps: providedDrag.dragHandleProps,
                                                                isSomethingDragging: snapshotDrop.isDraggingOver,
                                                            })}
                                                        </td>
                                                    );
                                                })}
                                            </DraggableRow>
                                        );
                                    }}
                                </Draggable>
                            );
                        })}
                        {providedDrop.placeholder}
                    </tbody>
                )}
            </Droppable>
        </DragDropContext>
    );
};
