import { Theme } from '@emotion/react';
import { ControlProps } from 'react-select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleBorderColor = (state: ControlProps<any, true, any>, theme: Theme): string => {
    if (state.selectProps.error) {
        return theme.color.bloodyMary;
    }
    if (state.selectProps.isTouched) {
        return theme.color.emerald;
    }
    return theme.color.grey300;
};
