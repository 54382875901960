import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';

import { stringifyError } from '@/utils/errors';
import { PricingRuleForm } from '@/pages/Brand/PricingRule/PricingRuleForm';
import Loader from '@/components/atoms/Loader';
import {
    PricingRuleCreateAttributes,
    PricingRule as PricingRuleType,
    PricingRuleGrant,
} from '@/services/innovorder/pricingRule/pricingRule.type';
import {
    useGetPricingRuleQuery,
    usePutPricingRuleMutation,
} from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { EntranceFee, EntranceFeeType } from '@/services/innovorder/entranceFee/entranceFee.type';
import {
    setGrants,
    setEntranceFees,
    setPricingRules,
    setComponentsWithoutTurnover,
    getGrants,
    getEntranceFees,
    getPricingRules,
    getComponentsWithoutTurnover,
} from '@/redux/pricingRuleManage';
import { getUserToken } from '@/redux/user';
import { sortPricingRuleGrants } from './utils';

interface ParamTypes {
    brandId: string;
    pricingRuleId: string;
}

const PricingRule: React.FunctionComponent<React.PropsWithChildren> = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const { pricingRuleId } = useParams<ParamTypes>();
    const token = useSelector(getUserToken);
    const { data, error, isSuccess } = useGetPricingRuleQuery(
        {
            pricingRuleId: parseInt(pricingRuleId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );
    const [putPricingRule, { error: putError, isLoading }] = usePutPricingRuleMutation();

    const finalGrants = useSelector(getGrants);
    const finalEntranceFees = useSelector(getEntranceFees);
    const finalComponentsWithoutTurnover = useSelector(getComponentsWithoutTurnover);
    const finalPricingRules = useSelector(getPricingRules);

    const isInitialized = useRef(false);

    const update = (arg: PricingRuleCreateAttributes) => {
        putPricingRule({
            ...arg,
            pricingRuleId: parseInt(pricingRuleId, 10),
            grants: finalGrants,
            entranceFees: [...finalEntranceFees, ...finalComponentsWithoutTurnover],
            associatedPricingRules: finalPricingRules,
            token,
        });
    };

    useEffect(() => {
        if (!isInitialized.current && isSuccess) {
            isInitialized.current = true;

            const grants: PricingRuleGrant[] = sortPricingRuleGrants(data?.grants || []);
            const entranceFees: EntranceFee[] = data?.entranceFees || [];
            const associatedPricingRules: PricingRuleType[] = data?.associatedPricingRules || [];

            dispatch(setGrants(grants));
            dispatch(setEntranceFees(entranceFees.filter(({ type }) => type === EntranceFeeType.AdmissionFee)));
            dispatch(
                setComponentsWithoutTurnover(entranceFees.filter(({ type }) => type !== EntranceFeeType.AdmissionFee)),
            );
            dispatch(setPricingRules(associatedPricingRules));
        }
    }, [dispatch, data, isSuccess]);

    if (data === undefined) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <PricingRuleForm
            pricingRule={data}
            error={stringifyError(error) || stringifyError(putError)}
            loading={isLoading}
            onSubmit={update}
            createMode={false}
        />
    );
};

export default PricingRule;
