import React from 'react';
import { TableLayoutPosDevice } from '@/services/innovorder/tableLayout/tableLayout.type';
import { TableLayoutActionTypes } from '../types';
import { DeleteTableLayoutModal } from './DeleteTableLayoutModal/DeleteTableLayoutModal';
import { CreateTableLayoutModal } from './CreateTableLayoutModal/CreateTableLayoutModal';
import { EditTableLayoutModal } from './EditTableLayoutModal/EditTableLayoutModal';
import { PublishTableLayoutModal } from './PublishTableLayoutModal/PublishTableLayoutModal';

type TableLayoutModalProps = {
    action: TableLayoutActionTypes | null;
    onHide: () => void;
    brandId: number;
    restaurantId: number;
    tableLayoutId: string | null;
    tableLayoutName: string | null;
    posDevices: TableLayoutPosDevice[];
};

const getActionModal = (action: TableLayoutActionTypes | null) => {
    switch (action) {
        case 'showCreateModal':
            return CreateTableLayoutModal;
        case 'showEditModal':
            return EditTableLayoutModal;
        case 'showDeleteModal':
            return DeleteTableLayoutModal;
        case 'showPublishModal':
            return PublishTableLayoutModal;
        default:
            return null;
    }
};

export const TableLayoutModal: React.FunctionComponent<React.PropsWithChildren<TableLayoutModalProps>> = ({
    action,
    onHide,
    brandId,
    restaurantId,
    tableLayoutName,
    tableLayoutId,
    posDevices,
}) => {
    const ActionModal = getActionModal(action);

    if (!ActionModal) {
        return ActionModal;
    }

    return (
        <ActionModal
            onHide={onHide}
            brandId={brandId}
            restaurantId={restaurantId}
            tableLayoutName={tableLayoutName}
            tableLayoutId={tableLayoutId}
            posDevices={posDevices}
        />
    );
};
