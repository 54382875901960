import React from 'react';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';

import { Routes } from '@/core/router/routes.types';
import ArrowForward from '@/components/atoms/Icon/icons/arrowForward';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { getSidebareStatus, setSidebarOverlay, toggleSidebar } from '@/redux/app';
import Direction from './Direction';
import { OverlayComponent } from '../menu';
import { LinkPayload } from '../DynamicMenu/dynamicMenu.type';
import { AlignRight, Container, LeftBlock, SideBarItemText } from './SideBarItem.style';
import { useSelector } from 'react-redux';

type SideBarItemProps = {
    icon?: SVGIcons;
    labelId: string;
    class?: string;
    emphased?: boolean;
    forward?: boolean;
    route?: Routes;
    uri?: string;
    subMenu?: OverlayComponent | LinkPayload[];
    subMenuRoute?: Routes;
    subMenuUri?: string;
    isSubMenu?: boolean;
    onClick?: () => void;
};

const SideBarItem: React.FunctionComponent<React.PropsWithChildren<SideBarItemProps>> = ({
    icon,
    labelId,
    class: className,
    emphased = false,
    forward = false,
    route,
    uri,
    subMenu,
    subMenuUri,
    subMenuRoute,
    isSubMenu,
    onClick,
}) => {
    const theme = useTheme();
    const isSidebarCollapsed = useSelector(getSidebareStatus);

    const dispatch = useDispatch();
    const dispatchOverlay = () => {
        if (isSidebarCollapsed) {
            dispatch(toggleSidebar(undefined));
        }
        dispatch(
            setSidebarOverlay({
                subMenu,
                uri: subMenuUri,
                route: subMenuRoute,
                parentName: labelId,
                parentIcon: icon,
            }),
        );
    };

    return (
        <Direction {...{ labelId, route, uri, onClick: onClick || (subMenu && dispatchOverlay) }}>
            <Container
                isSidebarCollapsed={isSidebarCollapsed}
                className={className}
                emphased={emphased}
                data-testid="sidebar-item"
                subMenu={isSubMenu}
            >
                <LeftBlock>
                    {icon && <SVGIcon icon={icon} size={15} color={theme.color.white100} />}
                    <SideBarItemText isSidebarCollapsed={isSidebarCollapsed} text={labelId} weight="regular" />
                </LeftBlock>
                {forward ? (
                    <AlignRight>
                        <ArrowForward
                            size={15}
                            color={emphased ? theme.color.primary : theme.color.white100}
                        ></ArrowForward>
                    </AlignRight>
                ) : null}
            </Container>
        </Direction>
    );
};

export default SideBarItem;
