import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import Wizard from '@/components/atoms/Wizard';
import { getChannelText } from '@/utils/innovorder/channel';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import RawModal from '@/components/atoms/RawModal';
import { theme } from '@/core/theme';
import { stringifyError } from '@/utils/errors';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { usePublishMenuOnChannelMutation } from '@/services/innovorder/menu/menu.endpoints';
import { MenuChannel, MenuPosDevice } from '@/services/innovorder/menu/menu.type';
import { getUserToken } from '@/redux/user';
import { useKioskReloadMutation } from '@/services/innovorder/restaurant/restaurant.endpoints';
import { Checkbox } from '@/components/atoms/Checkbox';
import { MenuModalChannelsTitle, MenuModalInputContainer } from '../MenuModal.style';
import {
    AssociatedChannelsContainer,
    AssociatedChannelsDescription,
    AssociatedChannelsTitle,
    DeviceListContainer,
    MenuDescriptionContainer,
    MenuTitleContainer,
} from './PublishMenuModal.style';

export type PublishMenuModalProps = {
    menuId: number | null;
    channels: MenuChannel[];
    posDevices: MenuPosDevice[];
    menuName: string | null;
    restaurantId?: number;
    onHide: () => void;
};

export const PublishMenuModal: React.FunctionComponent<React.PropsWithChildren<PublishMenuModalProps>> = ({
    onHide,
    menuId,
    channels,
    posDevices,
    menuName = '',
    restaurantId,
}) => {
    const intl = useIntl();
    const token = useSelector(getUserToken);
    const [publish, setPublish] = React.useState<Record<string, boolean>>({
        [ChannelId.KIOSK]: false,
        [ChannelId.POS]: false,
    });

    const [postPublishMenu, { isSuccess: isPublishSuccess, isLoading: isPublishLoading, error: errorPublish }] =
        usePublishMenuOnChannelMutation();
    const [reloadKiosk, { isSuccess: isReloadSuccess, isLoading: isReloadLoading }] = useKioskReloadMutation();

    const handleToggleDevice = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPublish((currentPublish) => {
            return {
                ...currentPublish,
                [event.target.id]: !currentPublish[event.target.id],
            };
        });
    }, []);

    const onFormValidate = useCallback(() => {
        publish[ChannelId.POS] && postPublishMenu({ menuId: menuId as number, channelId: ChannelId.POS, token });
        publish[ChannelId.KIOSK] && reloadKiosk({ restaurantId });
    }, [postPublishMenu, reloadKiosk, menuId, token, restaurantId, publish]);

    useEffect(() => {
        if (
            (isPublishSuccess && isReloadSuccess) ||
            (isPublishSuccess && !isReloadLoading) ||
            (isReloadSuccess && !isPublishLoading)
        ) {
            onHide();
        }
    }, [isPublishSuccess, isReloadSuccess, isPublishLoading, isReloadLoading, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={520}
                errorMessage={stringifyError(errorPublish)}
                onValidate={onFormValidate}
                isLoading={isPublishLoading || isReloadLoading}
                steps={[
                    {
                        title: 'menu.modal.publish.title',
                        onCancel: onHide,
                        component: (
                            <MenuModalInputContainer>
                                <MenuModalChannelsTitle type="normal" weight="medium" text={menuName} />

                                {channels
                                    .filter((channel) => channel.channelId === ChannelId.KIOSK)
                                    .map((channel) => {
                                        const isPosChannel = channel.channelId === ChannelId.POS;

                                        return (
                                            <AssociatedChannelsContainer
                                                key={`associated-channel-${channel.channelId}`}
                                            >
                                                <MenuTitleContainer>
                                                    <SVGIcon
                                                        icon={isPosChannel ? SVGIcons.POS : SVGIcons.KIOSK}
                                                        size={20}
                                                        color={theme.color.slate100}
                                                    />
                                                    <AssociatedChannelsTitle
                                                        type="normal"
                                                        weight="medium"
                                                        text={getChannelText(intl, channel)}
                                                    />
                                                </MenuTitleContainer>
                                                <MenuDescriptionContainer>
                                                    <Checkbox
                                                        id={channel.channelId.toString()}
                                                        value={publish[channel.channelId]}
                                                        onChange={handleToggleDevice}
                                                    />
                                                    <AssociatedChannelsDescription
                                                        type="normal"
                                                        weight="light"
                                                        text={computeText(intl, 'menu.modal.publish.autoPublish')}
                                                        isDisabled={false}
                                                    />
                                                </MenuDescriptionContainer>
                                            </AssociatedChannelsContainer>
                                        );
                                    })}

                                {posDevices.length > 0 && (
                                    <AssociatedChannelsContainer key={`associated-channel-pos`}>
                                        <MenuTitleContainer>
                                            <SVGIcon icon={SVGIcons.POS} size={20} color={theme.color.slate100} />
                                            <AssociatedChannelsTitle type="normal" weight="medium" text={'POS'} />
                                        </MenuTitleContainer>
                                        <MenuDescriptionContainer>
                                            <Checkbox
                                                id={ChannelId.POS.toString()}
                                                value={publish[ChannelId.POS]}
                                                onChange={handleToggleDevice}
                                            />
                                            <AssociatedChannelsDescription
                                                type="normal"
                                                weight="light"
                                                text={computeText(intl, 'menu.modal.publish.devicePublish')}
                                                isDisabled={false}
                                            />
                                        </MenuDescriptionContainer>

                                        <DeviceListContainer>
                                            {posDevices.map((posDevice) => {
                                                return (
                                                    <AssociatedChannelsDescription
                                                        key={posDevice.posDeviceId}
                                                        type="normal"
                                                        weight="light"
                                                        text={'- '.concat(posDevice.deviceName)}
                                                        isDisabled={false}
                                                    />
                                                );
                                            })}
                                        </DeviceListContainer>
                                    </AssociatedChannelsContainer>
                                )}
                            </MenuModalInputContainer>
                        ),
                    },
                ]}
            />
        </RawModal>
    );
};
