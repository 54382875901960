import styled from '@emotion/styled';

export const CalendarDayContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }): string => theme.font.family};
    font-weight: ${({ theme }): number => theme.font.weight.medium};
`;
