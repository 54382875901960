import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';

export const AssociatedChannelsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0 16px 5px;
    border: solid 1px ${({ theme }) => theme.color.grey400};
    border-radius: 8px;
    padding: 10px;
`;

export const MenuTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;

    & > span > svg {
        margin-right: 8px;
    }
`;

export const MenuDescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;

    & > * {
        padding: 10px 8px 0 0;
    }
`;

export const MenuTitle = styled(Text)`
    margin: 0 0 16px 10px;
    color: ${({ theme }) => theme.color.black400};
`;

export const AssociatedChannelsTitle = styled(Text)`
    color: ${({ theme }) => theme.color.black400};
`;

export const AssociatedChannelsDescription = styled(Text)<{ isDisabled: boolean }>`
    font-style: ${({ isDisabled }) => (isDisabled ? 'italic' : 'normal')};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.8 : 1)};
    margin: 5px 0px;
`;

export const DeviceListContainer = styled.div`
    margin-left: 28px;
`;
