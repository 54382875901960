import { IntlShape } from 'react-intl';
import { computeText } from '@/locales/utils';
import { MenuChannel } from '@/services/innovorder/menu/menu.type';

export const getChannelText = (intl: IntlShape, menuChannel: MenuChannel): string => {
    const { channelId } = menuChannel;

    return computeText(intl, `order.channel.${channelId}`);
};

export const getChannelOrDeviceText = (intl: IntlShape, menuChannel: MenuChannel): string => {
    const { menu_channel: channelRelation } = menuChannel;
    if (!menuChannel.menu_channel) {
        return getChannelText(intl, menuChannel);
    }

    const { devices = [] } = channelRelation;
    if (devices.length) {
        return devices.map(({ name }) => name).join(', ');
    }

    return getChannelText(intl, menuChannel);
};
