import React from 'react';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import { Container, Content } from './PosAcess.style';
import { useParams } from 'react-router';
import { useGetPosDevicesQuery } from '@/services/innovorder/posDevice/posDevice.endpoints';
import { compareVersions } from 'compare-versions';

const SUPPORTED_POS_VERSION_FROM = '9.2.0';

const PosAccessBase: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
    const { brandId, restaurantId } = useParams<{ brandId: string; restaurantId: string }>();
    const { data: posDevices = [], isLoading } = useGetPosDevicesQuery({
        brandId: +brandId,
        restaurantId: +restaurantId,
    });

    const hasPosDeviceSupported = React.useMemo(
        () => posDevices.some((x) => compareVersions(x.lastKnownVersion || '', SUPPORTED_POS_VERSION_FROM) >= 0),
        [posDevices],
    );
    const tabRoutes = React.useMemo(
        () => [
            ...routes
                .filter(({ tab: t }) => t === 'pos')
                .filter(({ name }) => name !== 'route.posAccess' || hasPosDeviceSupported),
        ],
        [hasPosDeviceSupported],
    );
    const subTabRoutes = React.useMemo(() => [...routes.filter(({ tab: t }) => t === 'posAccess')], []);

    if (isLoading) {
        return null;
    }

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <Content>
                    <RouterNavigationTabs routes={subTabRoutes}>{children}</RouterNavigationTabs>
                </Content>
            </Container>
        </RouterNavigationTabs>
    );
};

export { PosAccessBase };
