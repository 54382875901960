import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
import { ApiRequest } from '../../request.types';
import { GenerateOutgoingTransfersReportPayload } from './reports.type';

const reportsApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        generateOutgoingTransfersReport: build.mutation<string, ApiRequest<GenerateOutgoingTransfersReportPayload>>({
            query: ({ token, brandId, startDate, endDate }) => ({
                url: new URL('/v1/outgoing-transfers/report', ENV.PAYMENT_SERVICE_URL).href,
                params: {
                    brandId,
                    startDate: startDate.toISOString().slice(0, 10),
                    endDate: endDate.toISOString().slice(0, 10),
                },
                method: 'GET',
                headers: { authorization: `Bearer ${token}` },
                responseHandler: 'text',
            }),
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGenerateOutgoingTransfersReportMutation } = reportsApi;
