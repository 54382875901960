import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { SavingBar } from '@/components/atoms/SavingBar';
import { useBillingReferential } from '@/hooks/billingReferential/useBillingReferential';
import {
    BillingEntity,
    BillingReferential,
    BillingServiceCodes,
    PerceivedAmountCode,
} from '@/services/innovorder/autoBilling/autoBilling.type';
import { useSaveBillingReferentialMutation } from '@/services/innovorder/autoBilling/autoBilling.endpoints';
import { getUser, getUserBrandId, getUserToken } from '@/redux/user';
import Container from './AutoBillingReferential.style';
import BillingEntities from './BillingEntities';
import ServiceCodesForm from './ServiceCodesForm';
import PerceivedAmountCodesForm from './PerceivedAmountCodesForm';

const AutoBillingReferential: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { billingReferential: data, isLoading: isLoadingReferential } = useBillingReferential();

    const [billingReferential, setBillingReferential] = useImmer<BillingReferential>({
        billingServiceCodes: {
            fixedCostCode: '',
            admissionLevelCostCode: '',
            perceivedAmountCodes: [],
        },
        entities: [],
    });
    const brandId = useSelector(getUserBrandId) as number;
    const token = useSelector(getUserToken);
    const [saveBillingReferential, { isLoading: isSaving }] = useSaveBillingReferentialMutation();

    const [canSaveServiceCodes, setCanSaveServiceCodes] = useState<boolean>(true);
    const [canSavePerceivedAmountCodes, setCanSavePerceivedAmountCodes] = useState<boolean>(true);

    const user = useSelector(getUser);

    const canEditBillingReferential = user?.accessFlags.CAN_EDIT_AUTO_BILLING_REFERENTIAL;

    useEffect(() => {
        if (data) {
            setBillingReferential(data);
        }
    }, [data, setBillingReferential]);

    const handleServiceCodesFormUpdate = useCallback(
        (value: BillingServiceCodes) => {
            setBillingReferential((draft) => {
                if (draft) {
                    draft.billingServiceCodes = value;
                }
            });
        },
        [setBillingReferential],
    );

    const handlePerceivedAmountCodesUpdate = useCallback(
        (perceivedAmountCodes: PerceivedAmountCode[]) => {
            setBillingReferential((draft) => {
                if (draft) {
                    draft.billingServiceCodes.perceivedAmountCodes = perceivedAmountCodes;
                }
            });
        },
        [setBillingReferential],
    );

    const handleSave = useCallback(() => {
        if (brandId && token) {
            saveBillingReferential({
                brandId,
                billingReferential: billingReferential as BillingReferential,
                token,
            });
        }
    }, [billingReferential, saveBillingReferential, token, brandId]);

    const handleDeleteEntity = useCallback(
        (entityToDelete: BillingEntity) => {
            setBillingReferential((draft) => {
                if (draft) {
                    draft.entities = draft.entities.filter((entity) => entity.code !== entityToDelete.code);
                }
            });
        },
        [setBillingReferential],
    );
    return (
        <Container>
            {!isLoadingReferential && (
                <>
                    <ServiceCodesForm
                        onIsValidChange={setCanSaveServiceCodes}
                        servicesCodes={billingReferential.billingServiceCodes}
                        onUpdate={handleServiceCodesFormUpdate}
                        disabled={!canEditBillingReferential}
                    />
                    <PerceivedAmountCodesForm
                        onIsValidChange={setCanSavePerceivedAmountCodes}
                        perceivedAmountCodes={billingReferential.billingServiceCodes.perceivedAmountCodes ?? []}
                        onUpdate={handlePerceivedAmountCodesUpdate}
                        disabled={!canEditBillingReferential}
                    />
                    <BillingEntities
                        brandId={brandId}
                        entities={billingReferential.entities}
                        onDelete={handleDeleteEntity}
                        readOnly={!canEditBillingReferential}
                    />
                </>
            )}
            {canEditBillingReferential && (
                <SavingBar
                    onSave={handleSave}
                    disabled={!canSaveServiceCodes || !canSavePerceivedAmountCodes}
                    loading={isLoadingReferential || isSaving}
                />
            )}
        </Container>
    );
};

export default AutoBillingReferential;
