import * as React from 'react';
import styled from '@emotion/styled';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';

const TextStyle = styled.p<{
    weight: TextFontWeights;
    type: TextFontSizes;
    fontStyle: TextFontStyles;
    opacity?: number;
    isTruncated?: boolean;
}>`
    font-size: ${({ type, theme }) => theme.font.size[type]};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme, weight }) => theme.font.weight[weight]};
    font-style: ${({ fontStyle }) => fontStyle};
    color: ${({ theme }) => theme.color.black200};
    opacity: ${({ opacity, theme }) => opacity ?? theme.opacity.visible};
    white-space: pre-line;
    ${({ isTruncated }) =>
        isTruncated &&
        `
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        `};
`;

export type TextFontWeights = 'light' | 'medium' | 'regular' | 'bold';
export type TextFontSizes = 'large' | 'normal' | 'small';
export type TextFontStyles = 'normal' | 'italic';

export type TextProps = Partial<React.HTMLAttributes<HTMLElement>> & {
    text: React.ReactNode;
    weight?: TextFontWeights;
    fontStyle?: TextFontStyles;
    type?: TextFontSizes;
    opacity?: number;
    values?: Record<string, string | number | Date>;
    isTruncated?: boolean;
    noTranslation?: boolean;
};

export const Text: React.FunctionComponent<React.PropsWithChildren<TextProps>> = ({
    type = 'normal',
    weight = 'regular',
    fontStyle = 'normal',
    text,
    opacity,
    values,
    isTruncated,
    noTranslation = false,
    ...otherProps
}) => {
    const intl = useIntl();
    return (
        <TextStyle {...{ type, weight, fontStyle, opacity, isTruncated }} {...otherProps}>
            {!noTranslation && typeof text === 'string' ? computeText(intl, text, values) : text}
        </TextStyle>
    );
};
