import * as React from 'react';
import edenredLogo from '@/components/atoms/Icon/png/edenredLogo.png';

import { EdenredConfig } from '@/services/innovorder/edenred/configurations/configurations.type';
import { TitleContainer, PanelTitle, Logo, PanelText, PanelWarning } from './EdenredPanel.style';

interface EdenredPanelProp {
    text: string;
    config?: EdenredConfig;
}

const EdenredPanel: React.FunctionComponent<EdenredPanelProp> = ({ text, config }) => {
    const sent = !!config?.id && !config?.edenred?.toSend && !config?.edenred?.mid;
    const toSend = config?.id && config?.edenred?.toSend;

    return (
        <div>
            <TitleContainer>
                <Logo>
                    <img src={edenredLogo} alt={'Logo'} />
                </Logo>
                <PanelTitle text={text} />
            </TitleContainer>

            <PanelText text="edenred.form.description" />

            {toSend && <PanelWarning text="edenred.form.warning.toSend" />}
            {sent && <PanelWarning text="edenred.form.warning.sent" />}
        </div>
    );
};

export default EdenredPanel;
