import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { FormatDateOptions, useIntl } from 'react-intl';
import { Column } from 'react-table';
import { computeText } from '@/locales/utils';
import { theme } from '@/core/theme';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { BadgeProps } from '@/components/atoms/Badge/Badge';
import { fetchDataProps } from '@/components/atoms/Table';
import { MenuOperation, MenuOperationState, MenuOperationType } from '@/services/innovorder/menu/menu.type';
import { useGetMenuOperationsMutation } from '@/services/innovorder/menu/menu.endpoints';
import { getUserToken } from '@/redux/user';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
};

type MenuOperationListViewModel = {
    menuOperations: MenuOperation[];
    isLoading: boolean;
    headers: Column<TableRow>[];
    rows: TableRow[];
    fetchData: (props: fetchDataProps) => void;
    refreshData: () => void;
    fetchDataTotalCount: number;
};

export function useMenuOperationListVM(): MenuOperationListViewModel {
    const intl = useIntl();
    const { brandId: paramBrandId, restaurantId: paramRestaurantId } = useParams<ParamTypes>();
    const brandId = Number.parseInt(paramBrandId, 10);
    const restaurantId = Number.parseInt(paramRestaurantId, 10);
    const token = useSelector(getUserToken);

    const [getMenuOperations, { data, isLoading: isGetMenuOperationLoading }] = useGetMenuOperationsMutation();
    const { count = 0, data: menuOperations = [] } = data || {};
    const [currentFilter, setFilter] = useState({ offset: 0, limit: 10 });

    const fetchData = useCallback(
        ({ pageIndex = 0, pageSize = 0 }: fetchDataProps) => {
            const offset = pageSize * pageIndex;
            const limit = pageSize;

            setFilter({ offset, limit });
            getMenuOperations({ brandId, restaurantId, token, offset, limit });
        },
        [brandId, restaurantId, token, getMenuOperations, setFilter],
    );

    const refreshData = () => {
        getMenuOperations({ brandId, restaurantId, token, ...currentFilter });
    };

    const getTypeBadge = (type: MenuOperationType): BadgeProps => {
        switch (type) {
            case 'update':
                return {
                    text: computeText(intl, 'menuoperation.type.update'),
                    backgroundColor: theme.color.lightBlue100,
                };
            case 'publish':
                return {
                    text: computeText(intl, 'menuoperation.type.publish'),
                    backgroundColor: theme.color.electricBlue,
                };
            default:
                return {
                    text: computeText(intl, 'status.unknown'),
                    backgroundColor: theme.color.grey400,
                };
        }
    };

    const getOperationMessage = ({ message, status, type }: MenuOperation): string => {
        const formattedMessage = message ?? computeText(intl, 'status.unknown');

        if (status === 'ERROR') {
            return formattedMessage;
        }

        switch (type) {
            case 'update':
                return `${computeText(intl, 'menuoperation.message.from')} ${formattedMessage}`;
            case 'publish':
                return `${computeText(intl, 'menuoperation.message.to')} ${formattedMessage}`;
            default:
                return formattedMessage;
        }
    };

    const getStatusBadge = (status: MenuOperationState): BadgeProps => {
        switch (status) {
            case 'NEW':
                return {
                    text: computeText(intl, 'status.new'),
                    backgroundColor: theme.color.mint,
                };
            case 'FILE_UPLOADED':
                return {
                    text: computeText(intl, 'status.file_uploaded'),
                    backgroundColor: theme.color.mint,
                };
            case 'PROCESSING':
                return {
                    text: computeText(intl, 'status.processing'),
                    backgroundColor: theme.color.mint,
                };
            case 'ERROR':
                return {
                    text: computeText(intl, 'status.error'),
                    backgroundColor: theme.color.bloodyMary,
                };
            case 'DONE':
                return {
                    text: computeText(intl, 'status.done'),
                    backgroundColor: theme.color.emerald,
                };
            default:
                return {
                    text: computeText(intl, 'status.unknown'),
                    backgroundColor: theme.color.grey400,
                };
        }
    };

    const headers = [
        {
            Header: computeText(intl, 'date.created'),
            accessor: 'createdAt',
            width: '10%',
        },
        {
            Header: computeText(intl, 'date.updated'),
            accessor: 'updatedAt',
            width: '10%',
        },
        {
            Header: computeText(intl, 'menuoperation.type'),
            accessor: 'type',
            width: '10%',
        },
        {
            Header: computeText(intl, 'menu.name'),
            accessor: 'name',
            width: '20%',
        },
        {
            Header: computeText(intl, 'message.message'),
            accessor: 'message',
            width: '50%',
        },
        {
            Header: computeText(intl, 'status.status'),
            accessor: 'status',
            width: '10%',
        },
    ];

    const dateFormatOptions: FormatDateOptions = {
        hourCycle: 'h24',
        timeStyle: 'short',
        dateStyle: 'short',
    };
    const rows: TableRow[] = menuOperations.map((menuOperation) => ({
        createdAt: {
            type: 'string',
            value: {
                text: intl.formatDate(menuOperation.createdAt, dateFormatOptions),
            },
        },
        updatedAt: {
            type: 'string',
            value: {
                text: intl.formatDate(menuOperation.updatedAt, dateFormatOptions),
            },
        },
        type: {
            type: 'badge',
            value: getTypeBadge(menuOperation.type),
        },
        name: {
            type: 'string',
            value: {
                text: menuOperation.name,
            },
        },
        message: {
            type: 'string',
            value: {
                text: getOperationMessage(menuOperation),
            },
        },
        status: {
            type: 'badge',
            value: getStatusBadge(menuOperation.status),
        },
    }));

    return {
        menuOperations,
        isLoading: isGetMenuOperationLoading,
        fetchData,
        refreshData,
        headers,
        rows,
        fetchDataTotalCount: count,
    };
}
