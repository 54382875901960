import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';

import { stringifyError } from '@/utils/errors';
import {
    useLazyGetIOPayConfigQuery,
    useCreateConfigMutation,
    useUpdateConfigMutation,
    useDeleteConfigMutation,
} from '@/services/innovorder/io-pay/configurations/configurations.endpoints';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { getUser, getUserToken } from '@/redux/user';
import IOPayForm, { IOPayInputs } from './IOPayForm';
import { Loader } from './IOPayConfig.style';

const IOPayConfig: React.FunctionComponent<React.PropsWithChildren> = () => {
    const theme = useTheme();
    const user = useSelector(getUser);
    const token = useSelector(getUserToken);
    const canSetCommissions = !!user && user?.role === UserRoles.admin;
    const { brandId, restaurantId } = useParams<{ brandId: string; restaurantId: string }>();

    const {
        data,
        error: loadError,
        isFetching,
        params,
        getConfig,
    } = useLazyGetIOPayConfigQuery({
        token,
        brandId: Number(brandId),
        restaurantId: Number(restaurantId),
    });
    const [createConfig, { error: createError, isLoading: isSavingCreate }] = useCreateConfigMutation();
    const [updateConfig, { error: updateError, isLoading: isSavingUpdate }] = useUpdateConfigMutation();
    const [deleteConfig, { isLoading: isSavingDelete }] = useDeleteConfigMutation();

    const onSubmit = async (_data: IOPayInputs) => {
        if (data?.id) {
            updateConfig({
                token,
                configurationId: data.id,
                ...params,
                ..._data,
            });
        } else {
            await createConfig({
                token,
                ...params,
                ..._data,
            });
            getConfig(params);
        }
    };

    const onDelete = async () => {
        await deleteConfig({
            token,
            configurationId: data.id,
        });
        getConfig(params);
    };

    if (isFetching) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <IOPayForm
            config={data}
            onDelete={onDelete}
            onSubmit={onSubmit}
            error={stringifyError(loadError) || stringifyError(createError) || stringifyError(updateError)}
            withCommissions={canSetCommissions}
            loading={isSavingCreate || isSavingUpdate}
            loadingDelete={isSavingDelete}
        />
    );
};

export default IOPayConfig;
