import * as React from 'react';
import { generatePath, useHistory } from 'react-router';
import { Section } from '@/components/atoms/Section';
import { Button } from '@/components/atoms/Button';
import { FormattedMessage } from 'react-intl';
import { BillingEntity } from '@/services/innovorder/autoBilling/autoBilling.type';
import { Routes } from '@/core/router/routes.types';
import BillingEntitiesTable from './BillingEntitiesTable';

type BillingEntitiesProps = {
    brandId: number;
    entities: BillingEntity[];
    onDelete: (entity: BillingEntity) => void;
    readOnly?: boolean;
};

const BillingEntities: React.FunctionComponent<React.PropsWithChildren<BillingEntitiesProps>> = ({
    brandId,
    entities,
    onDelete,
    readOnly = false,
}) => {
    const history = useHistory();

    const createNewEntityHandler = React.useCallback(() => {
        history.push(generatePath(Routes.CreateBillingEntity, { brandId }));
    }, [brandId, history]);

    return (
        <Section
            overflowBody={false}
            title="auto.billing.referential.billingEntities.card.title"
            subtitle="auto.billing.referential.billingEntities.card.subtitle"
            actions={
                !readOnly && (
                    <Button buttonType="primary" onClick={createNewEntityHandler}>
                        <FormattedMessage id="auto.billing.referential.billingEntities.add" />
                    </Button>
                )
            }
        >
            <BillingEntitiesTable entities={entities} onDelete={onDelete} readOnly={readOnly} />
        </Section>
    );
};

export default BillingEntities;
