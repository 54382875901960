import React, { useEffect, useState } from 'react';
import Wizard from '@/components/atoms/Wizard';
import { useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import RawModal from '@/components/atoms/RawModal';
import { computeText } from '@/locales/utils';
import { useDeleteTableLayoutMutation } from '@/services/innovorder/tableLayout/tableLayout.endpoint';
import { Container } from './DeleteTableLayoutModal.style';

export type DeleteTableLayoutModalProps = {
    tableLayoutId: string | null;
    tableLayoutName: string | null;
    onHide: () => void;
};

export const DeleteTableLayoutModal: React.FunctionComponent<React.PropsWithChildren<DeleteTableLayoutModalProps>> = ({
    onHide,
    tableLayoutId,
    tableLayoutName = '',
}) => {
    const intl = useIntl();
    const [deleteTableLayoutById, { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading }] =
        useDeleteTableLayoutMutation();
    const [name, setName] = useState(tableLayoutName);

    useEffect(() => {
        setName(name);
    }, [name, setName]);

    useEffect(() => {
        if (isDeleteSuccess) {
            onHide();
        }
    }, [isDeleteSuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={480}
                steps={[
                    {
                        title: 'tableLayout.modal.delete.title',
                        onCancel: onHide,
                        component: (
                            <Container>
                                <Text
                                    type="normal"
                                    weight="regular"
                                    text={computeText(intl, 'tableLayout.modal.delete.description', {
                                        tableLayoutName: tableLayoutName ?? '',
                                    })}
                                />
                            </Container>
                        ),
                    },
                ]}
                isLoading={isDeleteLoading}
                onValidate={() => {
                    if (tableLayoutId) {
                        deleteTableLayoutById({ tableLayoutId });
                    }
                }}
            />
        </RawModal>
    );
};
