import React from 'react';

import Modal, { ModalProps } from '@/components/atoms/Modal';
import { SvySwitch } from '@/components/savory/SvySwitch';
import { getUserToken } from '@/redux/user';
import {
    useGetActiveStudentsEntitiesQuery,
    useGetWhitelistedCustomerEmailDomainNamesQuery,
} from '@/services/innovorder/brand/brand.endpoints';
import { useSelector } from 'react-redux';
import MultiSelect, { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { CustomerFetchOptions } from '@/services/innovorder/customer/customer.types';
import { MultiValue } from 'react-select';
import { OptionsModalBody } from './OptionsModal.style';

type OptionsModalProps = ModalProps & {
    brandId: number;
    customerFetchOptions: CustomerFetchOptions;
    handleCustomerFetchOptionsChange: (options: Partial<CustomerFetchOptions>) => void;
};

const OptionsModal: React.FunctionComponent<React.PropsWithChildren<OptionsModalProps>> = ({
    isOpen,
    onHide,
    brandId,
    customerFetchOptions,
    handleCustomerFetchOptionsChange,
}) => {
    const intl = useIntl();
    const token = useSelector(getUserToken);

    const { data: activeStudentEntities } = useGetActiveStudentsEntitiesQuery(
        {
            brandId,
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const { data: whitelistedCustomerEmailDomainNamesQuery } = useGetWhitelistedCustomerEmailDomainNamesQuery(
        {
            brandId,
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const domainNameOptions =
        whitelistedCustomerEmailDomainNamesQuery?.domainNames.map(({ emailDomainName }) => ({
            label: emailDomainName,
            value: emailDomainName,
        })) || [];
    const groupsOptions = activeStudentEntities?.classes.map((value) => ({ label: value, value })) || [];

    const selectedDomainNames = customerFetchOptions.emailDomainNames
        ? customerFetchOptions.emailDomainNames.split(',').map((emailDomainName) => ({
              label: emailDomainName,
              value: emailDomainName,
          }))
        : [];

    const selectedGroups = customerFetchOptions.groups
        ? customerFetchOptions.groups.split(',').map((group) => ({
              label: group,
              value: group,
          }))
        : [];

    const handleDomainNamesChange = (options: MultiValue<SelectOptionInterface<string>>) => {
        handleCustomerFetchOptionsChange({ emailDomainNames: options.map(({ value }) => value).join(',') });
    };

    const handleGroupsChange = (options: MultiValue<SelectOptionInterface<string>>) => {
        handleCustomerFetchOptionsChange({ groups: options.map(({ value }) => value).join(',') });
    };

    const handleGetArchivedCustomers = () => {
        handleCustomerFetchOptionsChange({ getArchivedCustomers: !customerFetchOptions.getArchivedCustomers });
    };

    const handleGetDisabledCustomers = () => {
        handleCustomerFetchOptionsChange({ getDisabledCustomers: !customerFetchOptions.getDisabledCustomers });
    };

    return (
        <Modal width={500} isOpen={isOpen} onHide={onHide} title="customers.options">
            <OptionsModalBody>
                <MultiSelect
                    inputId="customers.domainNames"
                    isMulti={true}
                    options={domainNameOptions}
                    onChange={handleDomainNamesChange}
                    placeholder={computeText(intl, 'customers.selectDomainNames')}
                    value={selectedDomainNames}
                />
                <MultiSelect
                    inputId="customers.groups"
                    isMulti={true}
                    options={groupsOptions}
                    onChange={handleGroupsChange}
                    placeholder={computeText(intl, 'customers.selectGroups')}
                    value={selectedGroups}
                />
                <SvySwitch
                    labelId={'customers.showArchivedCustomers'}
                    value={customerFetchOptions.getArchivedCustomers}
                    onChange={handleGetArchivedCustomers}
                />
                <SvySwitch
                    labelId={'customers.showDisabledCustomers'}
                    value={customerFetchOptions.getDisabledCustomers}
                    onChange={handleGetDisabledCustomers}
                />
            </OptionsModalBody>
        </Modal>
    );
};

export default OptionsModal;
