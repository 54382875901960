import { SavingBar } from '@/components/atoms/SavingBar';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { usePostWebhookMutation } from '@/services/innovorder/moduleExernalWebhooks/moduleExternalWebhooks.endpoints';
import { getUserToken } from '@/redux/user';
import { stringifyError } from '@/utils/errors';
import { Routes } from '@/core/router/routes.types';
import { ConfigContainer } from '../../common/style/Common.style';
import WebhookPanel from '../WebhookPanel';
import WebhookConfigurationPanel from '../WebhookConfigurationPanel';
import { WebhookConfig, WebhookEventTypes } from '../types';

type handleSubmitFn = (data: WebhookConfig) => void;

type FormRefType = {
    handleSubmit: (fn: handleSubmitFn) => () => void;
};

const CreateWebhook: React.FunctionComponent<React.PropsWithChildren> = () => {
    const [isValid, setValid] = useState(false);
    const formRef = useRef<FormRefType>();
    const token = useSelector(getUserToken);
    const { brandId, restaurantId } = useParams<{ brandId: string; restaurantId: string }>();
    const [postWebhook, { isLoading: isPostLoading, error: postError }] = usePostWebhookMutation();
    const history = useHistory();

    const onSubmit = useCallback(
        async (data: WebhookConfig) => {
            const webhookId = await postWebhook({
                token,
                body: {
                    ...data,
                    brandId: Number(brandId),
                    restaurantId: Number(restaurantId),
                    eventType: WebhookEventTypes.orderNotifications,
                    additionalHeaders: JSON.stringify(data.additionalHeaders),
                },
            }).unwrap();

            if (webhookId) {
                history.push(generatePath(Routes.EditWebhook, { brandId, restaurantId, webhookId }));
            }
        },
        [brandId, history, postWebhook, restaurantId, token],
    );

    const webhookConfigDefaultData: WebhookConfig = {
        webhookUrl: '',
        webhookHmacSecret: '',
        channels: [1, 2, 5, 12],
        additionalHeaders: [],
    };

    return (
        <>
            <ConfigContainer>
                <WebhookPanel />
                <WebhookConfigurationPanel ref={formRef} webhook={webhookConfigDefaultData} setValid={setValid} />
            </ConfigContainer>
            <SavingBar
                onSave={() => formRef.current?.handleSubmit(onSubmit)()}
                displayChangesCount={false}
                loading={isPostLoading}
                disabled={!isValid}
                error={stringifyError(postError)}
            />
        </>
    );
};

export default CreateWebhook;
