import React, { useState } from 'react';
import { OmnichannelOrder } from '@/services/innovorder/orders/order.type';
import { Container, SmallPhoto } from './OrderDetailsPhotos.style';
import OrderDetailsPhotoModal from './OrderDetailsPhotoModal';

type OrderDetailsPhotosProps = Pick<OmnichannelOrder, 'photos'>;

const OrderDetailsPhotos: React.FunctionComponent<OrderDetailsPhotosProps> = ({ photos }) => {
    const [showIndex, setShowIndex] = useState<number | undefined>();
    const showPhoto = showIndex !== undefined ? photos?.[showIndex].url : undefined;

    return (
        <>
            <Container>
                {photos?.map((photo, index) => (
                    <SmallPhoto
                        data-testid={`small-photo-${index}`}
                        role="img"
                        key={`photo-${index}`}
                        src={photo.url}
                        onClick={() => setShowIndex(index)}
                    />
                ))}
            </Container>
            <OrderDetailsPhotoModal
                data-testid="photo-modal"
                isOpen={!!showPhoto}
                src={showPhoto}
                onHide={() => setShowIndex(undefined)}
            />
        </>
    );
};

export default OrderDetailsPhotos;
