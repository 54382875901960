import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from '@/components/atoms/Table';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import RawModal from '@/components/atoms/RawModal';
import Loader from '@/components/atoms/Loader';
import { SvyButton } from '@/components/savory/SvyButton';
import { HeaderLeft, HeaderRight, Subtitle } from '@/components/atoms/Section/Section.style';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import { useSelector } from 'react-redux';
import { getUseAiMenuScan } from '@/redux/launchDarkly';
import { ButtonIcon, Container, Header, HeaderContainer, AIMenuScanButton } from './MenuList.style';
import { useMenuTableVM } from './MenuList.viewmodel';
import { ImportPOSMenuModal } from './components/ImportPOSMenuModal';
import { MenuModal } from './components/MenuModal/MenuModal';

const MenuList: React.FunctionComponent<React.PropsWithChildren> = () => {
    const {
        headers,
        rows,
        action: { type, name, channels, posDevices, isSyncFromPosEnabled, menuId },
        brandId,
        restaurantId,
        isGetMenuLoading,
        isExportMenuLoading,
        isCrousEnabled,
        handleRowClick,
        clearAction,
        toggleCreateModal,
        toggleAIMenuScanModal,
        toggleForceCrousImport,
        goToImportPage,
        userAccessFlags,
    } = useMenuTableVM();

    const isAIMenuScanFlagEnabled = useSelector(getUseAiMenuScan);

    const tabRoutes = React.useMemo(
        () => routes.filter(({ tab }) => tab === (restaurantId ? 'restaurants' : 'brands')),
        [restaurantId],
    );

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <ImportPOSMenuModal brandId={brandId} restaurantId={restaurantId} />
            <MenuModal
                action={type}
                onHide={clearAction}
                brandId={brandId}
                restaurantId={restaurantId}
                channels={channels}
                posDevices={posDevices}
                isSyncFromPosEnabled={isSyncFromPosEnabled}
                menuId={menuId}
                menuName={name}
            />
            <RawModal isOpen={isExportMenuLoading}>
                <Loader size={32} />
            </RawModal>
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="menu.page.title" />
                            <Subtitle>
                                <Text text="menu.page.description" />
                            </Subtitle>
                        </HeaderLeft>
                        <HeaderRight>
                            {restaurantId && (
                                <SvyButton
                                    onClick={goToImportPage}
                                    size="s"
                                    variant="outline"
                                    style={{ marginRight: '12px' }}
                                >
                                    <FormattedMessage id="menu.action.seeOperations" />
                                </SvyButton>
                            )}
                            {isAIMenuScanFlagEnabled && (
                                <AIMenuScanButton
                                    color="primary"
                                    onClick={toggleAIMenuScanModal}
                                    size="s"
                                    variant="outline"
                                >
                                    <ButtonIcon icon="magic-line" />
                                    <FormattedMessage id="menu.action.aiMenuScan" />
                                </AIMenuScanButton>
                            )}
                            {isCrousEnabled && (
                                <SvyButton onClick={toggleForceCrousImport} size="s" style={{ marginRight: '12px' }}>
                                    <FormattedMessage id="menu.action.manualImportCrous" />
                                </SvyButton>
                            )}
                            {userAccessFlags?.MENU_CREATE_OR_DELETE && (
                                <SvyButton onClick={toggleCreateModal} size="s">
                                    <FormattedMessage id="menu.action.newMenu" />
                                </SvyButton>
                            )}
                        </HeaderRight>
                    </Header>
                    <Table
                        isSearchable
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={headers}
                        hasPagination
                        hasUrlNavigation
                        isSortable
                        isLoading={isGetMenuLoading}
                        onRowClick={handleRowClick}
                    />
                </HeaderContainer>
            </Container>
        </RouterNavigationTabs>
    );
};

export default MenuList;
