import RawModal from '@/components/atoms/RawModal';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Text } from '@/components/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileInput } from '@/components/form/FileInput';
import { Accept } from 'react-dropzone';
import { computeText } from '@/locales/utils';
import { useAIMenuScanModalVm } from './AIMenuScanModal.viewmodel';
import {
    ActionButton,
    ActionButtonContainer,
    Container,
    DescriptionItem,
    DescriptionList,
    DropzoneContainer,
    TextContainer,
} from './AIMenuScanModal.style';

const ALLOWED_FILE_TYPES: Accept = {
    'application/pdf': ['.pdf'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
};

type AIMenuScanModalProps = {
    onHide: () => void;
};

export const AIMenuScanModal: React.FunctionComponent<React.PropsWithChildren<AIMenuScanModalProps>> = ({ onHide }) => {
    const intl = useIntl();
    const { form, handleSubmit, isLoading } = useAIMenuScanModalVm({ onHide });

    useEffect(() => {
        form.watch('file');
    }, [form]);

    return (
        <RawModal onHide={onHide}>
            <FormProvider {...form}>
                <Container>
                    <TextContainer>
                        <Text text={`menu.modal.aiMenuScan.title`} type="large" weight="light" />
                        <DescriptionList>
                            <DescriptionItem>
                                <FormattedMessage id={`menu.modal.aiMenuScan.description.part1`} />
                            </DescriptionItem>
                            <DescriptionItem>
                                <FormattedMessage id={`menu.modal.aiMenuScan.description.part2`} />
                            </DescriptionItem>
                            <DescriptionItem>
                                <FormattedMessage id={`menu.modal.aiMenuScan.description.part3`} />
                            </DescriptionItem>
                        </DescriptionList>
                    </TextContainer>
                    <DropzoneContainer>
                        <React.Suspense>
                            <FileInput
                                mode="compact"
                                accept={ALLOWED_FILE_TYPES}
                                name="file"
                                rules={{
                                    required: computeText(intl, 'field.required'),
                                    validate: (value) => value.length > 0,
                                }}
                                transform={{
                                    output: (file) => file[0],
                                    input: (file: File) => (file ? [file] : []),
                                }}
                            />
                        </React.Suspense>
                    </DropzoneContainer>

                    <ActionButtonContainer>
                        <ActionButton type="button" size="s" onClick={onHide}>
                            {computeText(intl, 'menu.modal.aiMenuScan.action.cancel')}
                        </ActionButton>
                        <ActionButton
                            disabled={!form.getValues('file')}
                            onClick={handleSubmit}
                            type="button"
                            size="s"
                            isLoading={isLoading}
                        >
                            {computeText(intl, 'menu.modal.aiMenuScan.action.generate')}
                        </ActionButton>
                    </ActionButtonContainer>
                </Container>
            </FormProvider>
        </RawModal>
    );
};
