import React from 'react';

import { HeaderGroup } from 'react-table';
import { TableFooterContent } from './TableFooter.style';
import { TableRow } from '../Table.type';

type TableFooterProps = {
    footerGroups: HeaderGroup<TableRow>[];
};

export const TableFooter: React.FunctionComponent<TableFooterProps> = ({ footerGroups }) => {
    return (
        <tfoot>
            {footerGroups.map((footerGroup) => {
                const { key: trKey, ...headerGroupProps } = footerGroup.getFooterGroupProps();
                return (
                    <tr key={trKey} {...headerGroupProps}>
                        {footerGroup.headers.map((column) => {
                            const { key: thKey, ...footerProps } = column.getFooterProps();
                            return (
                                <th
                                    data-testid="footer-column"
                                    key={thKey}
                                    {...footerProps}
                                    style={{
                                        minWidth: column.minWidth,
                                        width: column.width,
                                        maxWidth: column.maxWidth,
                                    }}
                                >
                                    <TableFooterContent>{column.render('Footer')}</TableFooterContent>
                                </th>
                            );
                        })}
                    </tr>
                );
            })}
        </tfoot>
    );
};
