import styled from '@emotion/styled';

export const Container = styled.div`
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }): string => theme.color.black};
    opacity: 0.5;
`;

export const Content = styled.div<{ width?: number; height?: number }>`
    display: flex;
    z-index: 3;
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    max-width: 100%;

    > div {
        border-radius: ${({ theme }) => theme.radius.main};
    }
`;
