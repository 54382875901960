import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import { Brand } from '../brand/brand.type';
import { GetBrandGroupListResponse } from './brandgroup.type';

const brandGroupApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getBrandGroupBrandsList: build.query<Brand[], ApiRequest<{ brandGroupId: number }>>({
            query: ({ brandGroupId, token }) => ({
                url: `/brand_groups/${brandGroupId}/brands`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetBrandGroupListResponse>) => response.data.brands,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetBrandGroupBrandsListQuery } = brandGroupApi;
