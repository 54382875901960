import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { uniqBy, difference, isEmpty, cloneDeep } from 'lodash';
import { Table } from '@/components/atoms/Table';
import { getColumns, getRows, PerceivedAmountCodeRow } from './utils';

const validatePerceivedAmountCodeRows = (
    perceivedAmountCodeRows: PerceivedAmountCodeRow[],
): PerceivedAmountCodeRow[] => {
    const rows = cloneDeep(perceivedAmountCodeRows);
    rows.forEach((row) => {
        row.code.error = isEmpty(row.code.value) ? 'empty' : '';
        row.vat.error = isEmpty(row.vat.value) ? 'empty' : '';
    });
    const uniqByCodes = uniqBy(rows, 'code.value');
    difference(rows, uniqByCodes).forEach((row) => {
        row.code.error = 'code.duplicated';
    });

    const uniqByVat = uniqBy(rows, 'vat.value');
    difference(rows, uniqByVat).forEach((row) => {
        row.vat.error = 'vat.duplicated';
    });
    return rows;
};

type PerceivedAmountCodesTableProps = {
    perceivedAmountCodes: PerceivedAmountCodeRow[];
    onDelete: (perceivedAmountCodeRow: PerceivedAmountCodeRow) => void;
    onUpdate: (perceivedAmountCodeRows: PerceivedAmountCodeRow[]) => void;
    onIsValidChange: (value: boolean) => void;
    readOnly?: boolean;
};

const PerceivedAmountCodesTable: React.FunctionComponent<React.PropsWithChildren<PerceivedAmountCodesTableProps>> = ({
    perceivedAmountCodes,
    onDelete,
    onUpdate,
    onIsValidChange,
    readOnly = false,
}) => {
    const intl = useIntl();

    const [perceivedAmountCodeRows, setPerceivedAmountCodeRows] = useState(perceivedAmountCodes);

    const onchange = useCallback(
        (index: number, perceivedAmountCodeRow: PerceivedAmountCodeRow): void => {
            const updatedRows = perceivedAmountCodeRows.map((row, i) => ({
                ...row,
                ...(i === index && perceivedAmountCodeRow),
            }));

            const validatedPerceivedAmountCodeRows = validatePerceivedAmountCodeRows(updatedRows);
            setPerceivedAmountCodeRows(validatedPerceivedAmountCodeRows);
            const isValid = validatedPerceivedAmountCodeRows.every(
                (row) => isEmpty(row.code.error) && isEmpty(row.vat.error),
            );
            onIsValidChange(isValid);
            if (isValid) {
                onUpdate(validatedPerceivedAmountCodeRows);
            }
        },
        [onIsValidChange, onUpdate, perceivedAmountCodeRows],
    );
    const columns = useMemo(() => getColumns(intl, readOnly), [intl, readOnly]);
    const rows = useMemo(() => {
        return getRows(perceivedAmountCodeRows, intl, onDelete, onchange, readOnly);
    }, [intl, onDelete, onchange, perceivedAmountCodeRows, readOnly]);

    useEffect(() => {
        setPerceivedAmountCodeRows(perceivedAmountCodes);
    }, [perceivedAmountCodes]);
    return (
        <Table
            emptyMessageId="auto.billing.referential.serviceCodes.perceivedAmountCodes.emptyMessage"
            columns={columns}
            rows={rows}
        />
    );
};

export default PerceivedAmountCodesTable;
