import styled from '@emotion/styled';
import Wizard from '@/components/atoms/Wizard';

export const Container = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white100};
    box-sizing: border-box;
`;

export const WizardIoPayConfiguration = styled(Wizard)`
    max-height: 90vh;
    border: none;

    & > div {
        gap: 40px;
    }
`;
