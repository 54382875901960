import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Switch } from '@/components/atoms/Switch';
import { SelectPosDevice } from '@/components/form/SelectPosDevice';
import { Container } from '../CreateMenuModal.style';
import { EditChannelFormValues } from '../CreateMenuModal.type';
import { MenuModalChannelsTitle, ModalSection } from '../../MenuModal.style';

export type SelectPosDeviceStepProps = {
    brandId: number;
    restaurantId?: number;
};

export const SelectPosDeviceStep: React.FunctionComponent<React.PropsWithChildren<SelectPosDeviceStepProps>> = ({
    brandId,
    restaurantId,
}) => {
    const { control } = useFormContext<EditChannelFormValues>();

    return (
        <Container>
            <Controller
                control={control}
                name="isSyncFromPosEnabled"
                render={({ field: { onChange, value } }) => (
                    <ModalSection>
                        <MenuModalChannelsTitle type="small" weight="medium" text="menu.modal.channels.shouldSyncPos" />
                        <Switch
                            data-testid={`menu-modal-channels-pos-devices-is-sync-active`}
                            labelId="menu.modal.channels.isSyncFromPosEnabled"
                            onChange={() => onChange(!value)}
                            value={value}
                        />
                    </ModalSection>
                )}
            />
            <ModalSection>
                <MenuModalChannelsTitle type="small" weight="medium" text="menu.modal.channels.pos.selectActive" />
                <SelectPosDevice brandId={brandId} restaurantId={restaurantId} name="posDevices" />
            </ModalSection>
        </Container>
    );
};
