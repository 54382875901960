import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { Cell } from 'react-table';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { getUseShift } from '@/redux/launchDarkly';
import { getUserToken } from '@/redux/user';
import { getGrant, getGrantRestaurants, updateGrantRestaurants } from '@/redux/grantAndEntranceFee';

import { Table } from '@/components/atoms/Table';
import { InputUnitType } from '@/components/atoms/Form/Input/Input';
import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import Loader from '@/components/atoms/Loader';
import { RestaurantGrantCreateAttribute } from '@/services/innovorder/grant/grant.type';
import { useGetBrandRestaurantsQuery } from '@/services/innovorder/brand/brand.endpoints';
import {
    computeGrantRestaurantTableColumnsVM,
    computeGrantRestaurantTableRowsVM,
    computeRestaurantsCreateAttributes,
    udpateEnabledRestaurants,
} from './utils';

interface ParamTypes {
    brandId: string;
    entranceFeeId: string;
}
interface GrantRestaurantsProps {
    unitType: InputUnitType;
    isCreateMode?: boolean;
    handleOnConfigure: ({ restaurantId, name }: { restaurantId: number; name: string }) => void;
}

export const GrantRestaurants: React.FunctionComponent<React.PropsWithChildren<GrantRestaurantsProps>> = ({
    unitType,
    isCreateMode,
    handleOnConfigure,
}) => {
    const { brandId } = useParams<ParamTypes>();
    const dispatch = useDispatch();
    const token = useSelector(getUserToken);
    const enabledRestaurants = useSelector(getGrantRestaurants);
    const grant = useSelector(getGrant);
    const { data } = useGetBrandRestaurantsQuery({
        brandId: parseInt(brandId, 10),
        token,
    });
    const useShift = useSelector(getUseShift);

    const intl = useIntl();
    const theme = useTheme();

    const tableChange = React.useCallback(
        (restaurantId: number, updates: Partial<RestaurantGrantCreateAttribute>): void => {
            if (data) {
                dispatch(
                    updateGrantRestaurants(
                        udpateEnabledRestaurants({ updates, enabledRestaurants, allRestaurants: data, restaurantId }),
                    ),
                );
            }
        },
        [data, dispatch, enabledRestaurants],
    );

    useEffect(() => {
        if (isCreateMode && enabledRestaurants.length) {
            dispatch(updateGrantRestaurants([]));
        }
    }, [isCreateMode, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const computedRestaurants = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return computeRestaurantsCreateAttributes({
            restaurants: data,
            enabledRestaurants,
            grant,
            intl,
            useShift,
        });
    }, [data, enabledRestaurants, grant, intl, useShift]);

    const grantRestaurantTableRows = React.useMemo(
        () =>
            computeGrantRestaurantTableRowsVM({
                restaurants: computedRestaurants,
                unitType,
                theme,
                intl,
                isCreateMode,
                onChange: tableChange,
                handleOnConfigure,
            }),
        [computedRestaurants, unitType, theme, intl, isCreateMode, tableChange, handleOnConfigure],
    );
    const grantRestaurantTableColumns = React.useMemo(
        () => computeGrantRestaurantTableColumnsVM(intl, useShift),
        [intl, useShift],
    );

    const handleOnRowClick = (cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
        if (!useShift) return;
        cell && cell.row.values.editAction.value.onClick();
    };

    if (!data) {
        return <Loader withContainer />;
    }

    return (
        <Table
            rows={grantRestaurantTableRows}
            columns={grantRestaurantTableColumns}
            emptyMessageId="grant.emptyMessage"
            isSearchable
            searchPlaceholder="restaurant.search"
            filters={['restaurant']}
            hasPagination={true}
            onRowClick={handleOnRowClick}
        />
    );
};
