import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { menuHeaderSelector, menuItemSelector, menuSelector } from '@szhsin/react-menu/style-utils';

import styled from '@emotion/styled';
import { Input } from '../Form/Input';
import checkbox from '../Icon/svg/checkbox.svg';
import checkboxChecked from '../Icon/svg/checkboxChecked.svg';
import radio from '../Icon/svg/radio.svg';
import radioChecked from '../Icon/svg/radioChecked.svg';

export const StyledMenu = styled(Menu)`
    line-height: 22px;
    ${menuSelector.name} {
        padding: ${({ theme }) => theme.spacing[2]};
        border-radius: ${({ theme }) => theme.radius.main};
        box-shadow: ${({ theme }) => theme.shadow.legacy};
    }
    ${menuHeaderSelector.name} {
        ${({ theme }) => theme.font.text.regular};
        font-weight: ${({ theme }) => theme.font.weight.medium};
        color: ${({ theme }) => theme.color.black200};
    }
    ${menuItemSelector.typeRadio}::before, ${menuItemSelector.typeCheckbox}::before {
        content: '';
        width: 16px;
        height: 22px;
        margin-right: ${({ theme }) => theme.spacing[2]};
        padding-right: 0;
        float: left;
        mask-image: url(${radio});
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: ${({ theme }) => theme.color.black300};
    }
    ${menuItemSelector.typeCheckbox}::before {
        mask-image: url(${checkbox});
    }
    ${menuItemSelector.typeRadio}${menuItemSelector.checked},
    ${menuItemSelector.typeRadio}:hover,
    ${menuItemSelector.typeCheckbox}${menuItemSelector.checked},
    ${menuItemSelector.typeCheckbox}:hover {
        background-color: ${({ theme }) => theme.color.grey200};
        border-radius: ${({ theme }) => theme.radius.main};
    }
    ${menuItemSelector.typeCheckbox}${menuItemSelector.checked}+${menuItemSelector.typeCheckbox}${menuItemSelector.checked} {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    ${menuItemSelector.typeCheckbox}${menuItemSelector.checked}[data-notlastselected=true] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    ${menuItemSelector.typeRadio}${menuItemSelector.checked}:before {
        mask-image: url(${radioChecked});
        background-color: ${({ theme }) => theme.color.slate300};
    }
    ${menuItemSelector.typeCheckbox}${menuItemSelector.checked}:before {
        mask-image: url(${checkboxChecked});
        background-color: ${({ theme }) => theme.color.slate300};
    }
    ${menuItemSelector.name}:hover {
        border-radius: ${({ theme }) => theme.radius.main};
    }
`;

export const StyledMenuButton = styled(MenuButton, {
    shouldForwardProp: (prop) => prop !== 'displayName',
})`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[2]};
    height: 40px;
    max-width: 250px;

    padding: ${({ theme }) => theme.spacing[2]};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    background: ${({ theme }) => theme.color.white100};

    line-height: 20px;
    ${({ theme }) => theme.font.text.regular};
    color: ${({ theme }) => theme.color.black200};
    cursor: pointer;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:hover {
        background: ${({ theme }) => theme.color.white200};
    }
    &[aria-expanded='true'] {
        background: ${({ theme }) => theme.color.grey100};
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    ${({ theme }) => theme.font.text.regular};
    color: ${({ theme }) => theme.color.black};
    padding: ${({ theme }) => theme.spacing[1]} ${({ theme }) => theme.spacing[2]} !important;
`;

export const StyledInput = styled(Input)<{ withoutItems?: boolean }>`
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme, withoutItems }) => (withoutItems ? 0 : theme.spacing[4])};
    padding: ${({ theme }) => theme.spacing[1]} ${({ theme }) => theme.spacing[2]};
    min-height: 30px;
`;

export const ClearFilter = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing[1]} ${({ theme }) => theme.spacing[2]};
    gap: ${({ theme }) => theme.spacing[2]};
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.size.small};
    line-height: 20px;
    font-weight: ${({ theme }) => theme.font.weight.medium};
    color: ${({ theme }) => theme.color.bloodyMary};
`;
