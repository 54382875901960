export type MarketplaceChannelName = 'ubereats' | 'deliverect' | 'deliveroo';

export type MarketplaceState = 'active' | 'inactive' | 'disabled';

export enum MarketplaceChannelId {
    UberEats = 9,
    Deliveroo = 10,
    JustEat = 11,
}

export interface GetStoreDetailPayload {
    storeReference: string;
}

export interface GetStoresPayload {
    brandId: string;
    restaurantId: string;
}

export interface MarketplaceChannel {
    id: MarketplaceChannelId;
    enable?: boolean;
    active?: boolean;
}

export type MarketplaceConfigChannel = {
    channelId: MarketplaceChannelId;
    isEnabled: boolean;
};

export type MarketplaceConfig = {
    restaurantId: string;
    brandId: string;
    storeReference: string;
    channels: MarketplaceConfigChannel[];
    marketplaceId?: string;
    storeDisplayName?: string;
    vatDefault?: number | null;
};

export interface Location {
    id: string;
    name: string;
    vatDefault?: number | null;
    marketplaces: MarketplaceChannel[];
}

export type GetLocationDetailPayload = GetStoreDetailPayload & {
    marketplace: MarketplaceChannelName;
};

export interface GetLocationDetailResponse {
    marketplace: string;
    storeDisplayName: string;
    storeReference: string;
    isEnabled: boolean;
}

export type GetLocationsPayload = GetStoresPayload & { marketplace: MarketplaceChannelName };

export type GetLocationsResponse = MarketplaceConfig[];

export interface PutLocationsPayload {
    brandId: string;
    restaurantId: string;
    locations: Pick<MarketplaceConfig, 'storeReference' | 'channels' | 'vatDefault'>[];
    marketplace: MarketplaceChannelName;
}

export type PutLocationsResponse = MarketplaceConfig[];
