import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const RoomModalInputContainer = styled.div`
    margin: 0 ${({ theme }) => theme.spacing[1]};
`;

export const RoomModalTextInputLabel = styled(Text)`
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.black300};
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;
