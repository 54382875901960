import React from 'react';
import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { SupportedLanguages } from '@/locales';
import { computeText } from '@/locales/utils';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { setLanguage } from '@/redux/app';
import SideBarItem from '../SideBarItem';
import { Title, TitleContainer, Wrapper } from './overlay.style';
import { useLocaleHandler } from '@/hooks/useLocaleHandler/useLocaleHandler';

const LanguagesList: React.FC<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { currentLocale, supportedLanguages } = useLocaleHandler();

    const handleChangeLanguage = (language: SupportedLanguages) => () => {
        dispatch(setLanguage(language));
    };

    return (
        <Wrapper>
            <TitleContainer>
                <SVGIcon icon={SVGIcons.LANGUAGES} size={16} color={theme.color.white100} />
                <Title>{computeText(intl, 'sidebar.languages')}</Title>
            </TitleContainer>

            {supportedLanguages.map((language) => (
                <SideBarItem
                    key={language}
                    labelId={`languages.${language}_${language.toUpperCase()}`}
                    onClick={handleChangeLanguage(language)}
                    emphased={currentLocale === language}
                    isSubMenu={true}
                />
            ))}
        </Wrapper>
    );
};

export default LanguagesList;
