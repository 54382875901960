import Modal from '@/components/atoms/Modal';
import styled from '@emotion/styled';

export const ShiftModal = styled(Modal)`
    min-height: 600px;
    max-height: 80vh;
    width: 1200px;
    max-width: 90vw;
`;

export const HeaderContainer = styled.div`
    display: flex;
    margin: ${({ theme }) => theme.spacing[3]} 0px;

    & > * {
        margin-right: ${({ theme }) => theme.spacing[3]};
    }
`;
