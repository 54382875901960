import { useIntl } from 'react-intl';
import * as React from 'react';
import { useTheme } from '@emotion/react';
import { useGetPosDevicesQuery } from '@/services/innovorder/posDevice/posDevice.endpoints';
import { MenuPosDevice } from '@/services/innovorder/menu/menu.type';
import { PosDeviceRole, PosDevice } from '@/services/innovorder/posDevice/posDevice.type';
import { computeText } from '@/locales/utils';
import { SelectPosDeviceProps } from './SelectPosDevice.interface';

const DEFAULT_VALUE: MenuPosDevice[] = [];
const DEFAULT_POS_DEVICE_ROLE_NOT_SUPPORTED = [PosDeviceRole.Slave];
const DEFAULT_LAST_SUPPORTED_VERSION = 700;

export const useSelectPosDeviceVm = (props: SelectPosDeviceProps) => {
    const {
        brandId,
        restaurantId,
        value = DEFAULT_VALUE,
        onChange,
        lastSupportedVersion,
        posDeviceRoleNotSupported,
    } = props;
    const theme = useTheme();
    const intl = useIntl();
    const { data: posDevices = [], isLoading: isPosDevicesLoading } = useGetPosDevicesQuery({
        brandId,
        restaurantId,
    });

    const onPosDeviceChange = React.useCallback(
        (target: number) => {
            const currentIndex = value.findIndex(({ posDeviceId }) => posDeviceId === target);
            const updatedValue = [...value];
            if (currentIndex < 0) {
                updatedValue.push({ deviceName: '', posDeviceId: target });
            } else {
                updatedValue.splice(currentIndex, 1);
            }
            onChange(updatedValue);
        },
        [onChange, value],
    );

    const disableStrategy = React.useCallback(
        (posDevice: PosDevice) => {
            const versionAsNumber = Number.parseInt(posDevice.lastKnownVersion?.replaceAll('.', '') ?? '', 10);

            if (lastSupportedVersion) {
                const lastSupportedVersionAsNumber = Number.parseInt(
                    lastSupportedVersion?.replaceAll('.', '') ?? '',
                    10,
                );
                return versionAsNumber < lastSupportedVersionAsNumber;
            }

            if (posDeviceRoleNotSupported) {
                return posDeviceRoleNotSupported.includes(posDevice.role);
            }

            return (
                DEFAULT_POS_DEVICE_ROLE_NOT_SUPPORTED.includes(posDevice.role) ||
                versionAsNumber < DEFAULT_LAST_SUPPORTED_VERSION
            );
        },
        [lastSupportedVersion, posDeviceRoleNotSupported],
    );

    const isSomeIncompatiblePosDevice = React.useMemo(
        () => posDevices.some((posDevice) => disableStrategy(posDevice)),
        [posDevices, disableStrategy],
    );

    const disclaimers = [];

    if (isSomeIncompatiblePosDevice && lastSupportedVersion) {
        disclaimers.push({
            text: 'menu.modal.message.versionIncompatibleDisclaimer',
            values: lastSupportedVersion,
        });
    }

    if (isSomeIncompatiblePosDevice && posDeviceRoleNotSupported?.length) {
        disclaimers.push({
            text: 'menu.modal.message.roleIncompatibleDisclaimer',
            values: posDeviceRoleNotSupported
                .map((role) => computeText(intl, `pos_device.role.${role}.plural`).toLowerCase())
                .join(` ${computeText(intl, 'random.or')} `),
        });
    }

    if (isSomeIncompatiblePosDevice && !disclaimers.length) {
        disclaimers.push({
            text: 'menu.modal.message.defaultIncompatibleDisclaimer',
            values: '',
        });
    }

    return {
        isPosDevicesLoading,
        posDevices,
        onPosDeviceChange,
        theme,
        intl,
        value,
        disableStrategy,
        disclaimers,
    };
};
