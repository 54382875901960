import { useFieldArray, useFormContext } from 'react-hook-form';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { prompt } from '@/core/asyncActions/prompt';
import { DropResult } from '@hello-pangea/dnd';
import { CrossSellingWithProducts } from '@/services/innovorder/crossSelling/crossSelling.type';
import { ProductsTable } from './ProductsTable';
import { CrossSellingFieldsProps } from './CrossSellingFields';

export const useCrossSellingFieldsVm = ({ brandId }: CrossSellingFieldsProps) => {
    const intl = useIntl();
    const form = useFormContext();
    const { fields } = useFieldArray<{
        [key: string]: CrossSellingWithProducts['products'];
    }>({
        name: 'products',
    });

    const products = React.useMemo(() => {
        return Object.values(fields).sort(
            (a, b) => Number(a?.crossSellingProduct.position) - Number(b?.crossSellingProduct.position),
        );
    }, [fields]);

    const persistProducts = React.useCallback(
        (newProducts: typeof products) => {
            const finalProducts = newProducts.map((product, index) => ({
                ...product,
                crossSellingProduct: {
                    ...product.crossSellingProduct,
                    position: index,
                },
            }));
            form.setValue('products', finalProducts);
        },
        [form],
    );

    const handleDragEnd = React.useCallback(
        (result: DropResult) => {
            const destinationId = Number(result.destination?.index);
            const sourceId = Number(result.source.index);

            const newData = [...products];
            const [movedRow] = newData.splice(sourceId, 1);
            newData.splice(destinationId, 0, movedRow);

            persistProducts(newData);
        },
        [products, persistProducts],
    );

    const handleRemove = React.useCallback(
        (productId: number) => {
            const newData = products.filter((product) => product.productId !== productId);
            persistProducts(newData);
        },
        [products, persistProducts],
    );

    const selectedProducts = React.useCallback(() => {
        const selectedProductIds = products.map(({ productId }) => productId);
        return prompt<CrossSellingWithProducts['products']>(({ onResolve, onHide }) => ({
            title: 'crossSelling.products.add',
            hideFooter: true,
            children: (
                <ProductsTable
                    brandId={brandId}
                    selectedProductIds={selectedProductIds}
                    onValidate={onResolve}
                    onCancel={onHide}
                />
            ),
            width: 1200,
        }));
    }, [brandId, products]);

    const handleAddProducts = React.useCallback(async () => {
        const selectedData = await selectedProducts();

        if (!selectedData) {
            return;
        }

        const newData = [...products, ...selectedData] as typeof products;
        persistProducts(newData);
    }, [products, selectedProducts, persistProducts]);

    return {
        handleDragEnd,
        intl,
        fields,
        products,
        handleAddProducts,
        handleRemove,
    };
};
