import React from 'react';

import { Text } from '@/components/atoms/Text';
import { ReactComponent as Next } from './next.svg';
import { ReactComponent as Previous } from './previous.svg';
import { DEFAULT_PAGE_SIZE } from '../Table';
import {
    Pagination,
    PaginationButton,
    PaginationDetails,
    SelectContainer,
    SelectRows,
    SingleOptionPagination,
} from './TablePagination.style';

type TablePaginationProps = {
    pageIndex: number;
    pageSize: number;
    pageSizeOptions?: number[];
    pageOptions: number[];
    canPreviousPage: boolean;
    canNextPage: boolean;
    hasFirstAndLastPage?: boolean;
    goFirstPage?: () => void;
    goLastPage?: () => void;
    previousPage: () => void;
    nextPage: () => void;
    setPageSize: (pageSize: number) => void;
};

export const TablePagination: React.FunctionComponent<React.PropsWithChildren<TablePaginationProps>> = ({
    pageIndex,
    pageSize,
    pageSizeOptions,
    pageOptions,
    canPreviousPage,
    canNextPage,
    hasFirstAndLastPage = false,
    goFirstPage,
    goLastPage,
    previousPage,
    nextPage,
    setPageSize,
}) => {
    const PAGE_SIZE_OPTIONS = [DEFAULT_PAGE_SIZE, 20, 30, 40, 50];

    pageSizeOptions = pageSizeOptions ?? PAGE_SIZE_OPTIONS;

    return (
        <Pagination>
            {hasFirstAndLastPage && (
                <PaginationButton onClick={goFirstPage} disabled={!canPreviousPage}>
                    <Text text="table.firstPage" type="small" />
                </PaginationButton>
            )}
            <PaginationButton onClick={previousPage} disabled={!canPreviousPage}>
                <Previous width={10} height={20} />
            </PaginationButton>
            <PaginationDetails>
                {pageIndex + 1}/{pageOptions.length}
            </PaginationDetails>
            <PaginationButton onClick={nextPage} disabled={!canNextPage}>
                <Next width={10} height={20} />
            </PaginationButton>
            {hasFirstAndLastPage && (
                <PaginationButton onClick={goLastPage} disabled={!canNextPage}>
                    <Text text="table.lastPage" type="small" />
                </PaginationButton>
            )}

            {pageSizeOptions.length != 0 && (
                <SelectContainer>
                    <Text text="table.rowsPerPage" type="small" />:
                    {pageSizeOptions.length === 1 ? (
                        <SingleOptionPagination>{pageSizeOptions[0]}</SingleOptionPagination>
                    ) : (
                        <SelectRows
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                            data-testid="pagination-select"
                        >
                            {pageSizeOptions.map((newPageSize) => (
                                <option key={newPageSize} value={newPageSize}>
                                    {newPageSize}
                                </option>
                            ))}
                        </SelectRows>
                    )}
                </SelectContainer>
            )}
        </Pagination>
    );
};
