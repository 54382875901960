import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled.div`
    flex: 1;
    padding: ${({ theme }) => theme.spacing[4]};
    background-color: ${({ theme }): string => theme.color.grey300};
`;

export const Message = styled(Text)`
    margin: ${({ theme }) => theme.spacing[2]};
`;

export const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
