import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { SavingBar } from '@/components/atoms/SavingBar';
import { useBillingReferential } from '@/hooks/billingReferential/useBillingReferential';
import {
    BillingEntity,
    BillingReferential,
    CostComputingMode,
} from '@/services/innovorder/autoBilling/autoBilling.type';
import { useSaveBillingReferentialMutation } from '@/services/innovorder/autoBilling/autoBilling.endpoints';
import { getUser, getUserBrandId, getUserToken } from '@/redux/user';
import BillingEntityEditor from '../BillingEntityEditor';
import { getErrorMessage } from '../utils';
import { Container } from './CreateBillingEntity.style';

const CreateBillingEntity: React.FunctionComponent<React.PropsWithChildren> = () => {
    const brandId = useSelector(getUserBrandId) as number;
    const token = useSelector(getUserToken);
    const user = useSelector(getUser);
    const canEditBillingReferential = user?.accessFlags.CAN_EDIT_AUTO_BILLING_REFERENTIAL;

    const { billingReferential, isLoading: isLoadingReferential } = useBillingReferential();

    const [isBillingEntityInfoFormValid, setIsBillingEntityInfoFormValid] = useState<boolean>(true);
    const [isBillingCostComputeValid, setIsBillingCostComputeValid] = useState<boolean>(true);
    const [saveBillingReferential, { isLoading: isSaving, isSuccess }] = useSaveBillingReferentialMutation();

    const [errors, setErrors] = useState<string>('');
    const history = useHistory();
    const intl = useIntl();

    const [billingEntity, setBillingEntity] = useState<BillingEntity>({
        code: '',
        name: '',
        groupingBy: 'BILLING_ENTITY',
        groups: [],
        costCompute: {
            mode: CostComputingMode.SUMMED_UP_BY_ADMISSION_FEES_AMOUNT,
            costSlices: [],
        },
    });

    const handleOnEntityChange = useCallback(
        (changedBillingEntity: BillingEntity) => {
            setBillingEntity(changedBillingEntity);
            if (billingReferential) {
                const errorMessage = getErrorMessage(billingReferential, changedBillingEntity, intl);
                setErrors(errorMessage);
            }
        },
        [billingReferential, intl],
    );

    const handleCreateEntity = useCallback(() => {
        if (brandId && token && billingReferential) {
            const newBillingReferential: BillingReferential = {
                ...billingReferential,
                entities: [...(billingReferential.entities ?? []), billingEntity],
            };
            saveBillingReferential({
                brandId,
                billingReferential: newBillingReferential,
                token,
            });
        }
    }, [brandId, token, billingReferential, saveBillingReferential, billingEntity]);

    useEffect(() => {
        if (isSuccess) {
            history.goBack();
        }
    }, [history, isSuccess]);

    return (
        <Container>
            <BillingEntityEditor
                billingEntity={billingEntity}
                onChange={handleOnEntityChange}
                onIsBillingEntityInfoFormValidChange={setIsBillingEntityInfoFormValid}
                onIsBillingCostComputeValidChange={setIsBillingCostComputeValid}
            />
            <SavingBar
                error={errors}
                onSave={handleCreateEntity}
                disabled={
                    !isBillingEntityInfoFormValid ||
                    !isBillingCostComputeValid ||
                    errors.length > 0 ||
                    !canEditBillingReferential
                }
                loading={isSaving || isLoadingReferential}
            />
        </Container>
    );
};

export default CreateBillingEntity;
