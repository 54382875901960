import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    GetRestaurantDetailsPayload,
    Restaurant,
    RestaurantPayload,
    RestaurantModule,
    RestaurantOptions,
    UpdateRestaurantOptionsPayload,
    GetRestaurantShiftsResponse,
    GetRestaurantShiftsPayload,
} from './restaurant.type';

const restaurantApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getRestaurants: build.query<Restaurant[], Record<string, never> | void>({
            query: () => ({
                url: `/restaurants`,
                method: 'GET',
            }),
            transformResponse: (response: ApiResponse<Restaurant[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getRestaurantDetails: build.query<RestaurantPayload, ApiRequest<GetRestaurantDetailsPayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/restaurants/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<RestaurantPayload>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getRestaurantModules: build.query<RestaurantModule, ApiRequest<GetRestaurantDetailsPayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/restaurants/${restaurantId}/modules`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<RestaurantModule>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getRestaurantShifts: build.query<GetRestaurantShiftsResponse, ApiRequest<GetRestaurantShiftsPayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/restaurants/${restaurantId}/shifts`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetRestaurantShiftsResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        kioskReload: build.mutation({
            query: ({ restaurantId }) => ({
                url: `/restaurants/kiosk/reload?restaurantId=${restaurantId}`,
                method: 'POST',
                body: { restaurantId },
            }),
            extraOptions: { maxRetries: 3 },
        }),

        updateRestaurantOptions: build.mutation<RestaurantOptions, ApiRequest<UpdateRestaurantOptionsPayload>>({
            query: ({ restaurantId, token, options }) => ({
                url: `/restaurants/${restaurantId}/options`,
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body: {
                    restaurantId,
                    options,
                },
            }),
            transformResponse: (response: ApiResponse<RestaurantOptions>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetRestaurantsQuery,
    useGetRestaurantModulesQuery,
    useKioskReloadMutation,
    useGetRestaurantShiftsQuery,
    useGetRestaurantDetailsQuery,
    useUpdateRestaurantOptionsMutation,
} = restaurantApi;
