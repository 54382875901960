import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    background: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    flex: 1;
    margin-bottom: 15px;
`;

export const AuditTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing[4]};
    flex: 1;
`;

export const TypeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[3]};
    height: fit-content;
    width: fit-content;
`;

export const AuditDiffContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 20px;
`;

export const ContentRow = styled.p``;
