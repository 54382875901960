import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Table } from '@/components/atoms/Table';
import { Routes } from '@/core/router/routes.types';
import { BillingEntity } from '@/services/innovorder/autoBilling/autoBilling.type';
import { getUserBrandId } from '@/redux/user';
import { getColumns, getRows } from './utils';

type BillingEntitiesTableProps = {
    entities: BillingEntity[];
    onDelete: (entity: BillingEntity) => void;
    readOnly?: boolean;
};

const BillingEntitiesTable: React.FunctionComponent<React.PropsWithChildren<BillingEntitiesTableProps>> = ({
    entities,
    onDelete,
    readOnly = false,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const brandId = useSelector(getUserBrandId) as number;
    const onEdit = useCallback(
        (entity: BillingEntity) => {
            history.push(generatePath(Routes.EditBillingEntity, { brandId, billingEntityCode: entity.code }));
        },
        [brandId, history],
    );
    const columns = useMemo(() => getColumns(intl, readOnly), [intl, readOnly]);
    const rows = useMemo(() => getRows(entities, intl, onDelete, onEdit), [entities, intl, onDelete, onEdit]);

    return (
        <Table
            emptyMessageId="auto.billing.referential.billingEntities.table.emptyMessage"
            columns={columns}
            rows={rows}
        />
    );
};

export default BillingEntitiesTable;
