import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ReportMetadata } from 'services/innovorder/report/report.type';

export const reportSliceName = 'report';

export interface ReportState {
    metadata: ReportMetadata[];
}

export const initialState: ReportState = {
    metadata: [],
};

const reportSlice = createSlice({
    name: reportSliceName,
    initialState,
    reducers: {
        updateMetadata: (state, action: PayloadAction<ReportMetadata[]>) => {
            state.metadata = action.payload;
        },
    },
});

export const getReportMetadata = (state: RootState): ReportMetadata[] => state.report.metadata;
export const getCurrentReportMetadata =
    (currentReportName: string) =>
    (state: RootState): ReportMetadata | undefined => {
        return state.report.metadata.find(({ name }) => name === currentReportName);
    };

export const { updateMetadata } = reportSlice.actions;
export default reportSlice.reducer;
