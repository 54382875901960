import { ApiTagTypes, innovorderApi } from '../../index';
import { ApiRequest, ApiResponse } from '../../request.types';
import {
    GetPosAccessRoleByIdBody,
    GetPosAccessRoleByIdResponse,
    GetPosAccessRolePermissionsResponse,
    GetPosAccessRolesBody,
    GetPosAccessRolesResponse,
    PatchPosAccessRoleByIdBody,
    PatchPosAccessRoleByIdResponse,
} from './pos_access_role.type';

const posAccessRolesApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getPosAccessRolePermissions: build.query<
            GetPosAccessRolePermissionsResponse,
            ApiRequest<GetPosAccessRolesBody>
        >({
            query: ({ token, restaurantId }) => ({
                url: `/restaurants/${restaurantId}/pos_access/roles/permissions`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: ({
                data,
            }: ApiResponse<GetPosAccessRolePermissionsResponse>): GetPosAccessRolePermissionsResponse => data,
            extraOptions: { maxRetries: 3 },
        }),
        getPosAccessRoles: build.query<GetPosAccessRolesResponse, ApiRequest<GetPosAccessRolesBody>>({
            query: ({ token, restaurantId }) => ({
                url: `/restaurants/${restaurantId}/pos_access/roles`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: (response, _, queryArgs) => {
                if (response) {
                    return [
                        ...response.map(({ posRoleId }) => ({
                            type: ApiTagTypes.PosRoles,
                            id: `${queryArgs.restaurantId}-${posRoleId}`,
                        })),
                        { type: ApiTagTypes.PosRoles, id: `${queryArgs.restaurantId}-LIST` },
                    ];
                }
                return [{ type: ApiTagTypes.PosRoles, id: 'LIST' }];
            },
            transformResponse: ({ data }: ApiResponse<GetPosAccessRolesResponse>): GetPosAccessRolesResponse => data,
            extraOptions: { maxRetries: 3 },
        }),
        getPosAccessRoleById: build.query<GetPosAccessRoleByIdResponse, ApiRequest<GetPosAccessRoleByIdBody>>({
            query: ({ token, restaurantId, posRoleId }) => ({
                url: `/restaurants/${restaurantId}/pos_access/roles/${posRoleId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosRoles, id: `${queryArgs.restaurantId}-${queryArgs.posRoleId}` },
                    { type: ApiTagTypes.PosRoles, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<GetPosAccessRoleByIdResponse>): GetPosAccessRoleByIdResponse =>
                data,
            extraOptions: { maxRetries: 3 },
        }),
        patchPosAccessRole: build.mutation<PatchPosAccessRoleByIdResponse, ApiRequest<PatchPosAccessRoleByIdBody>>({
            query: ({ token, restaurantId, posRoleId, ...body }) => ({
                url: `/restaurants/${restaurantId}/pos_access/roles/${posRoleId}`,
                method: 'PATCH',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosRoles, id: `${queryArgs.restaurantId}-LIST` },
                    { type: ApiTagTypes.PosRoles, id: `${queryArgs.restaurantId}-${queryArgs.posRoleId}` },
                ];
            },
            transformResponse: ({
                data,
            }: ApiResponse<PatchPosAccessRoleByIdResponse>): PatchPosAccessRoleByIdResponse => data,
        }),
    }),
});

export const {
    useGetPosAccessRolePermissionsQuery,
    useGetPosAccessRolesQuery,
    useGetPosAccessRoleByIdQuery,
    usePatchPosAccessRoleMutation,
} = posAccessRolesApi;
