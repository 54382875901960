import {
    ICustomerAttributes,
    StudentAttributes,
    UpdateCustomerType,
} from '@/services/innovorder/customer/customer.types';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { getAccountPaymentType } from '@/services/innovorder/customer/customer.utils';

const getPricingRuleCode = (
    guestGroups: GuestGroupWithGuestsCount[],
    guestGroupId: number | null,
    groupPricingRuleCode: string | null,
): string | undefined => {
    let pricingRuleCode;
    if (guestGroupId && groupPricingRuleCode) {
        const doesPricingRuleBelongsToGroup = guestGroups.find(
            ({ guestGroupId: id, pricingRules }) =>
                id === guestGroupId && pricingRules.map(({ code }) => code).includes(groupPricingRuleCode),
        );
        pricingRuleCode = doesPricingRuleBelongsToGroup ? groupPricingRuleCode : undefined;
    }

    return pricingRuleCode;
};

const getStudentDefaultValues = (
    student: StudentAttributes,
    guestGroups: GuestGroupWithGuestsCount[],
): Partial<UpdateCustomerType> => {
    const {
        tariffCode,
        studentNumber,
        guestGroupId,
        accountPaymentType: groupAccountPaymentType,
        accountType,
        pricingRuleCode: groupPricingRuleCode,
        section,
        subSection,
    } = student;
    const accountPaymentType = getAccountPaymentType(groupAccountPaymentType, accountType);
    const pricingRuleCode = getPricingRuleCode(guestGroups, guestGroupId, groupPricingRuleCode);
    return {
        tariffCode: tariffCode || undefined,
        studentNumber: studentNumber || undefined,
        guestGroupId: guestGroupId || undefined,
        accountPaymentType: accountPaymentType || undefined,
        section: section || undefined,
        subSection: subSection || undefined,
        pricingRuleCode,
    };
};
export const getCustomerDefaultValues = (
    customer: ICustomerAttributes | undefined,
    guestGroups: GuestGroupWithGuestsCount[],
): Partial<UpdateCustomerType> => {
    if (!customer) {
        return {};
    }

    const { badgeNumber } = customer;

    if (!customer.student) {
        return {
            badgeNumber: badgeNumber || undefined,
        };
    }

    const studentDefaultValues = getStudentDefaultValues(customer.student, guestGroups);
    return {
        badgeNumber: badgeNumber || undefined,
        ...studentDefaultValues,
    };
};
