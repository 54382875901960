/* eslint-disable react/display-name */
import {
    Redirect,
    Route,
    Switch,
    match as matchType,
    matchPath,
    useLocation,
    useRouteMatch,
    RouteComponentProps,
} from 'react-router-dom';
import * as React from 'react';
import { ReactNode } from 'react';
import { generatePath, useHistory } from 'react-router';
import { RouteDeclaration, Routes } from './routes.types';
import { useFilterRoutes } from '@/hooks/routes/useFilterRoutes';
import Modal from '../../components/atoms/Modal';

const useWithRouteVm = <R extends any>(propsRoutes: RouteDeclaration<R>[]) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { path, params } = useRouteMatch();
    const routes = useFilterRoutes(propsRoutes);

    const matchingRoute = React.useMemo(() => {
        let match = null;
        let route = null;
        // eslint-disable-next-line no-restricted-syntax
        for (route of routes) {
            match = matchPath(pathname, { path: `${path}${route.path}`, exact: true });
            if (match?.isExact) {
                break;
            }
        }
        if (!match) {
            return undefined;
        }
        return { ...match, ...route };
    }, [path, routes, pathname]);

    return {
        params,
        path,
        history,
        matchingRoute,
        routes,
    };
};

export const withRoutes =
    <R extends any>(propsRoutes: RouteDeclaration<R>[]) =>
    <Props extends WithRoutes<R>>(
        Container: React.ComponentType<React.PropsWithChildren<Props>>,
    ): React.FunctionComponent<React.PropsWithChildren<WithRouterProps & Omit<Props, 'routes' | 'matchingRoute'>>> =>
    ({ fallbackPath, ...props }) => {
        const { history, matchingRoute, routes, path, params } = useWithRouteVm<R>(propsRoutes);

        const validRoutes = React.useMemo(() => routes.filter((route) => route.path && route.component), [routes]);
        const pageRoutes = React.useMemo(
            () => validRoutes.filter(({ type }) => !type || type === 'page'),
            [validRoutes],
        );
        const modalRoutes = React.useMemo(() => validRoutes.filter(({ type }) => type === 'modal'), [validRoutes]);
        const portalRoutes = React.useMemo(() => validRoutes.filter(({ type }) => type === 'portal'), [validRoutes]);

        return (
            <Container
                {...(props as Props)}
                matchingRoute={matchingRoute}
                routes={
                    <>
                        <Switch>
                            {pageRoutes.map((route) => {
                                const RouteComponent = route.component!;
                                return (
                                    <Route
                                        exact={route.exact}
                                        key={`${route.path}`}
                                        path={`${path}${route.path}`}
                                        render={(routeProps) => (
                                            <RouteComponent matchingRoute={matchingRoute} {...routeProps} />
                                        )}
                                    />
                                );
                            })}
                            <Redirect from="/" to={fallbackPath ?? Routes.NotFound} />
                        </Switch>
                        <Switch>
                            {modalRoutes.map((route) => {
                                const RouteComponent = route.component!;
                                return (
                                    <Route
                                        exact={route.exact}
                                        key={`${route.path}`}
                                        path={`${path}${route.path}`}
                                        render={(routeProps) => (
                                            <Modal
                                                isOpen
                                                onHide={() => history.push(generatePath(path, params))}
                                                hideFooter
                                                {...route.routeProps}
                                            >
                                                <RouteComponent matchingRoute={matchingRoute} {...routeProps} />
                                            </Modal>
                                        )}
                                    />
                                );
                            })}
                        </Switch>
                        <Switch>
                            {portalRoutes.map((route) => {
                                const RouteComponent = route.component!;
                                return (
                                    <Route
                                        exact={route.exact}
                                        key={`${route.path}`}
                                        path={`${path}${route.path}`}
                                        render={(routeProps) => (
                                            <RouteComponent matchingRoute={matchingRoute} {...routeProps} />
                                        )}
                                    />
                                );
                            })}
                        </Switch>
                    </>
                }
            />
        );
    };

type WithRouterProps = {
    fallbackPath?: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type RoutedComponentProps<Props = {}, R = any> = Props &
    RouteComponentProps & {
        matchingRoute: RouteDeclaration<R> & matchType;
    };

// eslint-disable-next-line @typescript-eslint/ban-types
export type WithRoutes<R = any, P = {}> = P & { routes: ReactNode; matchingRoute: RouteDeclaration<R> & matchType };
