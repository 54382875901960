import React from 'react';
import { PosAccessBase } from '../../components/PosAcess';
import PosRolesForm from '../../components/PosRoles/forms/PosRolesForm';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
    roleId: string;
};

const EditPosAccessRole: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <PosAccessBase>
            <PosRolesForm />
        </PosAccessBase>
    );
};

export { EditPosAccessRole };
