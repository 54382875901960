import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { EntranceFeeStrategyTranslationMap } from '@/pages/Brand/EntranceFee/EntranceFeeForm/EntranceFeeForm';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeCurrency, computePercentage, computeText } from '@/locales/utils';
import { EntranceFeePayload } from '@/services/innovorder/entranceFee/entranceFee.type';

export const getEntranceFeesTableRowsVM = (
    entranceFees: EntranceFeePayload[] | undefined,
    onDelete: (id: number) => void,
    intl: IntlShape,
): TableRow[] =>
    !entranceFees
        ? []
        : entranceFees.map(
              ({ brandId, entranceFeeId, amount, code, labelTicket, restaurants, dailyLimit, strategy, vat }) => ({
                  amount: {
                      type: 'computedNumber',
                      value: { text: computeCurrency({ intl, amount }), number: amount },
                  },
                  code: {
                      type: 'string',
                      value: { text: code },
                  },
                  labelTicket: {
                      type: 'string',
                      value: { text: labelTicket },
                  },
                  restaurants: {
                      type: 'list',
                      value: { listItems: restaurants.map(({ name }) => name), isTruncated: true },
                  },
                  dailyLimit: {
                      type: 'string',
                      value: { text: dailyLimit.toString() },
                  },
                  strategy: {
                      type: 'string',
                      value: {
                          text: EntranceFeeStrategyTranslationMap[strategy],
                      },
                  },
                  vat: {
                      type: 'string',
                      value: {
                          text: computePercentage({ amount: vat / 100 }),
                      },
                  },
                  action: {
                      type: 'action',
                      value: {
                          children: computeText(intl, 'button.delete'),
                          buttonType: 'outlinedPrimary',
                          onClick: () => onDelete(entranceFeeId),
                      },
                  },
                  linkTo: {
                      type: 'linkTo',
                      value: { brandId: String(brandId), entranceFeeId: String(entranceFeeId) },
                  },
              }),
          );

export const getEntranceFeesTableColumnsVM = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'entranceFee.amount'),
        accessor: 'amount',
        maxWidth: 75,
    },
    {
        Header: computeText(intl, 'entranceFee.code'),
        accessor: 'code',
        maxWidth: 120,
    },
    {
        Header: computeText(intl, 'entranceFee.labelTicket'),
        accessor: 'labelTicket',
        maxWidth: 120,
    },
    {
        Header: computeText(intl, 'entranceFee.restaurants'),
        accessor: 'restaurants',
        maxWidth: 300,
    },
    {
        Header: computeText(intl, 'entranceFee.dailyLimit'),
        accessor: 'dailyLimit',
        maxWidth: 75,
    },
    {
        Header: computeText(intl, 'entranceFee.strategy'),
        accessor: 'strategy',
        maxWidth: 100,
    },
    {
        Header: computeText(intl, 'entranceFee.vat'),
        accessor: 'vat',
        maxWidth: 75,
    },
    {
        Header: computeText(intl, 'entranceFee.action'),
        accessor: 'action',
        width: '1%',
        disableSortBy: true,
    },
];
