import React, { useEffect } from 'react';
import { useHistory, generatePath } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Section } from '@/components/atoms/Section';
import { Routes } from '@/core/router/routes.types';
import { Table } from '@/components/atoms/Table';
import Loader from '@/components/atoms/Loader';
import DeleteModal from '@/components/atoms/DeleteModal';
import { useDeleteEntranceFeeMutation } from '@/services/innovorder/entranceFee/entranceFee.endpoints';
import { useGetBrandEntranceFeesQuery } from '@/services/innovorder/brand/brand.endpoints';
import { getUserToken } from '@/redux/user';
import { Button } from '@/components/atoms/Button';
import { getEntranceFeesTableRowsVM, getEntranceFeesTableColumnsVM } from './utils';

interface EntranceFeesProps {
    brandId: string;
}

export const EntranceFees: React.FunctionComponent<React.PropsWithChildren<EntranceFeesProps>> = ({ brandId }) => {
    const intl = useIntl();
    const history = useHistory();
    const token = useSelector(getUserToken);
    const { data, isLoading } = useGetBrandEntranceFeesQuery(
        {
            brandId: parseInt(brandId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const [deleteEntranceFee, { isSuccess, isLoading: isLoadingDelete }] = useDeleteEntranceFeeMutation();

    const [idToDelete, setIdToDelete] = React.useState<number | null>(null);

    const handleOpenModal = (id: number): void => {
        setIdToDelete(id);
    };

    const handleCloseModal = () => {
        setIdToDelete(null);
    };

    const handleDeleteEntranceFee = (): void => {
        idToDelete &&
            deleteEntranceFee({
                entranceFeeId: idToDelete,
                brandId: parseInt(brandId, 10),
                token,
            });
    };

    useEffect(() => {
        if (isSuccess) {
            setIdToDelete(null);
        }
    }, [isSuccess]);

    const handleAddEntranceFee = (): void => {
        history.push(generatePath(Routes.CreateEntranceFee, { brandId }));
    };

    const entranceFeesTableRows = React.useMemo(
        () => getEntranceFeesTableRowsVM(data, handleOpenModal, intl),
        [data, intl],
    );
    const entranceFeesTableColumns = React.useMemo(() => getEntranceFeesTableColumnsVM(intl), [intl]);

    return (
        <>
            <Section
                title="entranceFee.tableCard.title"
                subtitle="entranceFee.tableCard.subtitle"
                actions={
                    <Button buttonType="primary" onClick={handleAddEntranceFee}>
                        <FormattedMessage id="entranceFee.tableCard.action" />
                    </Button>
                }
            >
                {isLoading ? (
                    <Loader withContainer />
                ) : (
                    <Table
                        rows={entranceFeesTableRows}
                        columns={entranceFeesTableColumns}
                        emptyMessageId="entranceFee.emptyMessage"
                        onRowClickPath={Routes.EntranceFee}
                        isSearchable={true}
                        searchPlaceholder="entranceFee.search"
                        filters={['code']}
                        hasPagination={true}
                    />
                )}
            </Section>
            <DeleteModal
                title="entranceFee.modal.warning"
                description="entranceFee.modal.confirmDelete"
                isOpen={idToDelete !== null}
                onHide={handleCloseModal}
                onSubmit={handleDeleteEntranceFee}
                isLoading={isLoadingDelete}
            />
        </>
    );
};
