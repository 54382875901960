import React, { useMemo } from 'react';
import { HeaderLeft, HeaderRight, Header } from '@/components/atoms/Section/Section.style';
import { Title } from '@/components/atoms/Title';
import { Table } from '../../components/atoms/Table/Table';
import { Container, HeaderContainer } from './BrandsUsersList.style';
import useBrandsUsersListVM from './BrandsUsersList.viewmodel';
import { SvyButton } from '../../components/savory/SvyButton';
import { FormattedMessage } from 'react-intl';
import CreateUserForm from './components/CreateUserForm/CreateUserForm';
import { routes } from '@/core/router/routes';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';

const BrandsUsersList = () => {
    const {
        rows,
        columns,
        fetchData,
        isNewUserModalOpen,
        openNewUserModal,
        closeNewUserModal,
        data,
        isLoading,
        handleRowClick,
    } = useBrandsUsersListVM();
    const tabRoutes = useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <CreateUserForm isOpen={isNewUserModalOpen} onHide={closeNewUserModal} />
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="route.brandUsers" />
                        </HeaderLeft>
                        <HeaderRight>
                            <SvyButton size="s" onClick={openNewUserModal}>
                                <FormattedMessage id="brandUsers.newUser" />
                            </SvyButton>
                        </HeaderRight>
                    </Header>
                    <div>
                        <Table
                            rows={rows}
                            emptyMessageId="table.noRowsAfterFilter"
                            columns={columns}
                            isSearchable
                            fetchData={fetchData}
                            fetchDataTotalCount={data?.length}
                            isLoading={isLoading}
                            onRowClick={handleRowClick}
                        />
                    </div>
                </HeaderContainer>
            </Container>
        </RouterNavigationTabs>
    );
};
export default BrandsUsersList;
