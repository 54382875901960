import React from 'react';
import { IntlShape } from 'react-intl';

import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import type { DropDownItem } from '@/components/atoms/DropDown/DropDown.type';
import { computeText } from '@/locales/utils';
import { AccountPaymentType, BillingEntity } from '@/services/innovorder/autoBilling/autoBilling.type';
import { PricingRule } from '@/services/innovorder/pricingRule/pricingRule.type';
import { Restaurant } from '@/services/innovorder/restaurant/restaurant.type';
import { DropDownButton, DropDownButtonContainer } from './AutoBillingRequestFilters.style';

export type DropDownProperty = 'entities' | 'restaurants' | 'pricingRules' | 'accountPaymentTypes';

export const ALL_DROP_DOWN_VALUES = 'ALL_DROP_DOWN_VALUES';
export const NO_PRICING_RULES = 'NO_PRICING_RULES';

export type DropDownValues = {
    entities: DropDownItem<string>[];
    restaurants: DropDownItem<string>[];
    pricingRules: DropDownItem<string>[];
    accountPaymentTypes: DropDownItem<string>[];
};

export const formatSelectedValues = (
    selectedValues: string[],
    dropDownProperty: DropDownProperty,
    dropDownsValues: DropDownValues,
    intl: IntlShape,
): string => {
    const allDropDowValues = dropDownsValues[dropDownProperty];
    if (dropDownProperty !== 'pricingRules' && selectedValues.length === allDropDowValues.length) {
        return computeText(intl, `auto.billing.request.filter.${dropDownProperty}.all`);
    }
    if (dropDownProperty === 'pricingRules' && selectedValues.length === allDropDowValues.length - 1) {
        return computeText(intl, `auto.billing.request.filter.${dropDownProperty}.all`);
    }
    const formattedValue = allDropDowValues
        .filter((value) => selectedValues.find((item) => item === value.value))
        .map((value) => value.label)
        .join(', ');
    if (formattedValue.length > 50) {
        return `${formattedValue.substring(0, 30)}...`;
    }
    return formattedValue;
};

export const getPricingRulesSelectedValues = (
    values: string[],
    dropDownsValues: DropDownValues,
    oldValues: string[],
): string[] => {
    if (values.includes(NO_PRICING_RULES) && !oldValues.includes(NO_PRICING_RULES)) {
        return [NO_PRICING_RULES];
    }
    if (!values.includes(ALL_DROP_DOWN_VALUES) && oldValues.includes(ALL_DROP_DOWN_VALUES)) {
        return [];
    }
    if (values.includes(ALL_DROP_DOWN_VALUES) && !oldValues.includes(ALL_DROP_DOWN_VALUES)) {
        return dropDownsValues.pricingRules.map((value) => value.value).filter((value) => value !== NO_PRICING_RULES);
    }
    if (values.length === dropDownsValues.pricingRules.length - 2 && !values.includes(ALL_DROP_DOWN_VALUES)) {
        return [...values, ALL_DROP_DOWN_VALUES];
    }
    return values.filter((value) => value !== NO_PRICING_RULES && value !== ALL_DROP_DOWN_VALUES);
};

export const getSelectedValues = (
    values: string[],
    dropDownProperty: DropDownProperty,
    dropDownsValues: DropDownValues,
    oldValues: string[],
): string[] => {
    if (!values.includes(ALL_DROP_DOWN_VALUES) && oldValues.includes(ALL_DROP_DOWN_VALUES)) {
        return [];
    }
    if (
        oldValues.length === dropDownsValues[dropDownProperty].length &&
        values.length < dropDownsValues[dropDownProperty].length
    ) {
        return values.filter((value) => value !== ALL_DROP_DOWN_VALUES);
    }
    if (values.includes(ALL_DROP_DOWN_VALUES)) {
        return dropDownsValues[dropDownProperty].map((value) => value.value);
    }
    if (values.length === dropDownsValues[dropDownProperty].length - 1 && !values.includes(ALL_DROP_DOWN_VALUES)) {
        return [...values, ALL_DROP_DOWN_VALUES];
    }
    return values;
};

export const getDropDownValues = (
    billingEntities: BillingEntity[],
    restaurantsValues: Restaurant[],
    pricingRulesValues: PricingRule[],
    intl: IntlShape,
): DropDownValues => ({
    entities: [
        { label: computeText(intl, 'auto.billing.request.filter.entities.all'), value: ALL_DROP_DOWN_VALUES },
        ...billingEntities.map((entity) => ({ label: entity.name, value: entity.code })),
    ],
    restaurants: [
        {
            label: computeText(intl, 'auto.billing.request.filter.restaurants.all'),
            value: ALL_DROP_DOWN_VALUES,
        },
        ...restaurantsValues.map((restaurant) => ({
            label: restaurant.name,
            value: restaurant.restaurantId.toString(),
        })),
    ],
    pricingRules: [
        {
            label: computeText(intl, 'auto.billing.request.filter.pricingRules.noPricingRules'),
            value: NO_PRICING_RULES,
        },
        {
            label: computeText(intl, 'auto.billing.request.filter.pricingRules.all'),
            value: ALL_DROP_DOWN_VALUES,
        },
        ...pricingRulesValues.map((pricingRule) => ({
            label: pricingRule.code,
            value: pricingRule.code,
        })),
    ],
    accountPaymentTypes: [
        {
            label: computeText(intl, 'auto.billing.request.filter.accountPaymentTypes.all'),
            value: ALL_DROP_DOWN_VALUES,
        },
        ...Object.values(AccountPaymentType).map((value) => ({
            label: computeText(intl, `auto.billing.request.accountPaymentTypes.${value}`),
            value: value.toString(),
        })),
    ],
});

export const getDropDownButton = (displayedValue: string, dropDownProperty: DropDownProperty): JSX.Element => {
    return (
        <DropDownButton isError={!displayedValue}>
            <DropDownButtonContainer>
                <div data-testid={dropDownProperty}>{displayedValue}</div>
                <SVGIcon icon={SVGIcons.CHEVRON} size={20} />
            </DropDownButtonContainer>
        </DropDownButton>
    );
};
