import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import { GetExternalIdsPayload, ExternalIds } from './externalIds.type';

const externalIdsApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getExternalIds: build.query<ExternalIds, ApiRequest<GetExternalIdsPayload>>({
            query: ({ mappingKey, internalValues, token }) => ({
                url: `/external_ids_mappings?&mappingKey=${mappingKey}&internalValues=${internalValues}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: ({ data }: ApiResponse<ExternalIds>) => data,
            providesTags: [ApiTagTypes.ExternalId],
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useLazyGetExternalIdsQuery } = externalIdsApi;
