import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';

import Modal from '@/components/atoms/Modal';
import { Button } from '@/components/atoms/Button';
import { computeText } from '@/locales/utils';
import { useDateRangeValidation, DateRange } from '@/utils/daterange';
import { ExportFormatContainer, PresetDateButtonsContainer, StyledCalendar } from './ExportModal.style';
import { useGetCreateOrdersReportGenerationHandler } from '../hooks/useGetExportButtons';
import { ExportOption } from '@/services/innovorder/orders/order.type';
import { useUrlQueryState } from '@/hooks/routes/useUrlQueryState';
import { Switch } from '@/components/atoms/Switch';

import { Title } from '@/components/atoms/Title';
import { setCurrentMonth, setCurrentWeek, setLastMonth } from './utils';

type ExportModalProps = {
    width: number;
    brandId?: number;
    restaurantId?: number;
    onHide: () => void;
};

const ExportModal: React.FunctionComponent<React.PropsWithChildren<ExportModalProps>> = ({
    width,
    onHide,
    restaurantId,
}) => {
    const [dates, setDates] = useState<DateRange>({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [exportFormats, setExportFormats] = useState<Record<ExportOption, boolean>>({
        CSV: false,
        XLSX: false,
    });
    const [selectedButton, setSelectedButton] = useState<string | null>(null);
    const handleButtonClick = (buttonType: string) => {
        setSelectedButton(buttonType);
    };

    useEffect(() => {
        const today = new Date();
        if (selectedButton === 'currentWeek') {
            setCurrentWeek(today, setDates);
        } else if (selectedButton === 'currentMonth') {
            setCurrentMonth(today, setDates);
        } else if (selectedButton === 'lastMonth') {
            setLastMonth(today, setDates);
        }
    }, [selectedButton]);

    const intl = useIntl();

    const dateRangeValidation = useDateRangeValidation(dates);

    const { isDateRangeValid, shouldShowDateRangeErrorMessage } = dateRangeValidation;

    const { createOrdersReportGeneration, isOrdersReportGenerationRequestLoading } =
        useGetCreateOrdersReportGenerationHandler({
            ...dates,
            exportFileType: (Object.keys(exportFormats) as ExportOption[]).filter((key) => exportFormats[key]),
            restaurantId: restaurantId && restaurantId > 0 ? restaurantId : undefined,
        });

    const handleExport = () => {
        createOrdersReportGeneration();
    };
    const isAnyFormatSelected = Object.values(exportFormats).some((value) => value);
    const canGenerateReport = isDateRangeValid && isAnyFormatSelected;
    const exportButtons = (
        <Button onClick={handleExport} isLoading={isOrdersReportGenerationRequestLoading} disabled={!canGenerateReport}>
            <FormattedMessage id="orderList.generate" />
        </Button>
    );

    const maxDate = useMemo(() => new Date(), []);
    const beginOfPreviousMonth = useMemo(() => dayjs(new Date()).subtract(1, 'month').startOf('month').toDate(), []);

    const dateRangeErrorMessage = () =>
        shouldShowDateRangeErrorMessage ? computeText(intl, 'orderList.export.dateRage.errorMessage') : undefined;

    const PresetDateButtons = [
        { key: 'currentWeek', label: computeText(intl, 'orderList.export.presetDate.currentWeek') },
        { key: 'currentMonth', label: computeText(intl, 'orderList.export.presetDate.currentMonth') },
        { key: 'lastMonth', label: computeText(intl, 'orderList.export.presetDate.lastMonth') },
    ];

    const onDateChange = useCallback(
        (dates: DateRange) => {
            setSelectedButton(null);
            setDates(dates);
        },
        [setSelectedButton, setDates],
    );
    return (
        <Modal
            isOpen={true}
            width={width}
            onHide={onHide}
            title="orderList.export.title"
            error={dateRangeErrorMessage()}
            buttons={exportButtons}
        >
            <Title type="small" text="orderList.export.presetDate.chooseDate" />
            <PresetDateButtonsContainer>
                {PresetDateButtons.map(({ key, label }) => (
                    <Button
                        key={key}
                        type="button"
                        buttonType={selectedButton === key ? 'primary' : 'secondary'}
                        onClick={() => handleButtonClick(key)}
                    >
                        {label}
                    </Button>
                ))}
            </PresetDateButtonsContainer>

            <StyledCalendar
                startDate={dates.startDate}
                endDate={dates.endDate}
                open={true}
                monthShown={2}
                inline
                maxDate={maxDate}
                openToDate={beginOfPreviousMonth}
                selectsRange={true}
                onChange={onDateChange}
                disabled={isOrdersReportGenerationRequestLoading}
                data-testid="export-modal-calendar"
            />
            <ExportFormatContainer>
                <Title type="small" text="orderList.export.format" />

                <ExportFormatContainer>
                    {(Object.keys(exportFormats) as ExportOption[]).map((key) => (
                        <Switch
                            data-testid={`${key}-export-option`}
                            key={key}
                            labelId={key}
                            onChange={() => {
                                setExportFormats({ ...exportFormats, [key]: !exportFormats[key] });
                            }}
                            value={exportFormats[key]}
                        />
                    ))}
                </ExportFormatContainer>
            </ExportFormatContainer>
        </Modal>
    );
};

export default ExportModal;
