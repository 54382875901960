import React from 'react';
import { useTheme } from '@emotion/react';

import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { childrenToMapAndCheckTypes } from './utils';
import { Container, Flex } from './Breadcrumb.style';

export type BreadcrumbProps = {
    icon?: SVGIcons;
};

export const Breadcrumb: React.FunctionComponent<React.PropsWithChildren<BreadcrumbProps>> = ({
    children,
    icon,
    ...otherProps
}) => {
    const theme = useTheme();
    const childMap = childrenToMapAndCheckTypes(children);

    return (
        <Flex data-testid="breadcrumb" {...otherProps}>
            <Container>
                {icon && <SVGIcon testid="breadcrumb-icon" icon={icon} size={15} color={theme.color.primary} />}
                {childMap.map((child, index) => {
                    const isFirst = index === 0;
                    return (
                        <React.Fragment key={index}>
                            {!isFirst && <p> / </p>}
                            {child}
                        </React.Fragment>
                    );
                })}
            </Container>
        </Flex>
    );
};
