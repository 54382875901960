import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const CHECKBOX_SIZE = 16;
const CHECKBOX_BORDER_WIDTH = 2;
const CHECKBOX_CONTAINER_SIZE = CHECKBOX_SIZE + CHECKBOX_BORDER_WIDTH * 2;

export const Container = styled.div<{ disabled: boolean }>`
    display: flex;
    min-width: ${CHECKBOX_CONTAINER_SIZE}px;
    min-height: ${CHECKBOX_CONTAINER_SIZE}px;
    gap: ${({ theme }) => theme.spacing[2]};
    align-items: center;
    justify-content: flex-start;
    &,
    label {
        cursor: pointer;
    }
    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
            &,
            label {
                cursor: initial;
            }
        `};
`;

export const CheckboxContainer = styled.label<{ checked: boolean }>`
    width: ${CHECKBOX_SIZE}px;
    height: ${CHECKBOX_SIZE}px;
    border: ${CHECKBOX_BORDER_WIDTH}px solid ${({ theme }) => theme.color.grey400};
    border-radius: ${({ theme }) => theme.radius.main};
    box-sizing: content-box;
`;

export const TextContainer = styled.label`
    display: flex;
    flex-direction: column;
`;

export const HiddenInput = styled.input`
    display: none;
`;
