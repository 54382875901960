import React from 'react';
import { Props } from 'react-select';
import {
    BillingTitle,
    Container,
    ContentContainer,
    FlexGrow,
    Header,
    Subtitle,
    TitleContainer,
} from '@/pages/Billing/Billing.style';
import { SvyButton } from '@/components/savory/SvyButton';
import { FormattedMessage } from 'react-intl';
import { Table } from '@/components/atoms/Table';
import { getBillingTableColumns, useBillingVM } from '@/pages/Billing/Billing.viewmodel';
import { EditConfigurationModal } from './EditConfigurationModal/EditConfigurationModal';

export const Billing: React.FunctionComponent<React.PropsWithChildren<Props>> = () => {
    const {
        invoices,
        show,
        hasStripeModules,
        isStripeModuleLoading: isModuleStripeLoading,
        onClearAction,
        toggleEditConfigurationModal,
    } = useBillingVM();
    const headers = getBillingTableColumns();

    return (
        <>
            <EditConfigurationModal show={show} hide={onClearAction} />
            <Container>
                <ContentContainer>
                    <Header data-testid="configuration-head">
                        <TitleContainer>
                            <BillingTitle text="billing.title" />
                            <Subtitle text="billing.subtitle" />
                            <Subtitle text="billing.description" />
                        </TitleContainer>
                        <FlexGrow />
                        <SvyButton
                            data-testid="edit-configuration-button"
                            type="button"
                            disabled={isModuleStripeLoading || !hasStripeModules}
                            onClick={toggleEditConfigurationModal}
                        >
                            <FormattedMessage id="billing.information.edit" />
                        </SvyButton>
                    </Header>
                    <Table
                        isSearchable
                        rows={invoices}
                        emptyMessageId="billing.noIssuedInvoices"
                        columns={headers}
                        hasPagination
                        isFilterable
                        isSortable
                        isLoading={false}
                    />
                </ContentContainer>
            </Container>
        </>
    );
};
