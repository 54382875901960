import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import {
    ICustomerAttributes,
    UpdateCustomerType,
    UpdateCustomerPayload,
    AccountType,
    ACCOUNT_PAYMENT_TYPE,
} from '@/services/innovorder/customer/customer.types';
import { getAccountPaymentType, getAccountType } from '@/services/innovorder/customer/customer.utils';

const isPostPaymentAccountPaymentType = (accountPaymentType: string): boolean => {
    return [ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_BANKDEBIT, ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_GUEST].includes(
        accountPaymentType,
    );
};

const POST_PAYMENT_EWALLET_OVERDRAFT_DEFAULT_AMOUNT = 1000000;

const isChangingAccountPaymentType = (
    selectedPaymentType: string,
    currentAccountPaymentType: string,
    currentAccountType: AccountType | null,
): boolean => {
    return getAccountPaymentType(currentAccountPaymentType, currentAccountType) !== selectedPaymentType;
};

export const getEWalletOverdraftPayload = (
    selectedPaymentType: string,
    currentAccountPaymentType?: string,
    currentAccountType?: AccountType | null,
) => {
    if (currentAccountPaymentType === undefined || currentAccountType === undefined) {
        return {};
    }
    const isChangingPaymentType = isChangingAccountPaymentType(
        selectedPaymentType,
        currentAccountPaymentType,
        currentAccountType,
    );
    if (isChangingPaymentType) {
        if (isPostPaymentAccountPaymentType(selectedPaymentType)) {
            return {
                paymentDailyLimit: null,
                overrideDailyPaymentLimit: false,
                ewalletOverdraftAmount: POST_PAYMENT_EWALLET_OVERDRAFT_DEFAULT_AMOUNT,
                overrideEwalletOverdraft: true,
            };
        }

        return {
            overrideEwalletOverdraft: false,
        };
    }

    return {};
};

export const prepareUpdateCustomerPayload = (
    customer: ICustomerAttributes,
    formValues: UpdateCustomerType,
): UpdateCustomerPayload => {
    const {
        firstName,
        lastName,
        phone,
        email,
        ewalletReloadRecieveConfirmation,
        ewalletReloadConfirmationEmail,
        paymentDailyLimit,
        ewalletOverdraftAmount,
        overrideEwalletOverdraft,
        isBadgeBlocked,
        student,
        tripAdvisorUsername,
        birthdate,
        password,
        customerId,
    } = customer;

    const {
        accountPaymentType,
        badgeNumber,
        guestGroupId,
        pricingRuleCode,
        studentNumber,
        tariffCode,
        section,
        subSection,
    } = formValues;

    return {
        firstName,
        lastName,
        phone,
        email,
        ewalletReloadRecieveConfirmation: ewalletReloadRecieveConfirmation || false,
        ewalletReloadConfirmationEmail,
        paymentDailyLimit,
        ewalletOverdraftAmount,
        overrideEwalletOverdraft,
        isBadgeBlocked,
        tripAdvisorUsername,
        birthdate,
        password,
        badgeNumber,
        customerId,
        ...getEWalletOverdraftPayload(
            accountPaymentType,
            customer.student?.accountPaymentType,
            customer?.student?.accountType,
        ),
        student: {
            ...student,
            ...getAccountType(accountPaymentType),
            guestGroupId,
            pricingRuleCode,
            studentNumber,
            tariffCode,
            section,
            subSection,
        },
    };
};

export const redirectToStudentDetailsPage = (customerId: number | string): void => {
    const studentDetailsPage = `/home/customers/${customerId}/student#customer-student`;
    redirectToBackofficeV1(studentDetailsPage);
};
