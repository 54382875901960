import React, { useCallback } from 'react';

import { assertUnreachable } from '@/utils/typescript';
import ubereatsLogo from '@/components/atoms/Icon/png/ubereatsLogo.png';
import deliverooLogo from '@/components/atoms/Icon/png/deliverooLogo.png';
import justeatLogo from '@/components/atoms/Icon/png/justeatLogo.png';
import ClickableIcon from '@/components/atoms/ClickableIcon';
import { MarketplaceChannelId, MarketplaceState } from '@/services/innovorder/marketplace/marketplace.type';

const getLogo = (id: MarketplaceChannelId): string => {
    switch (id) {
        case MarketplaceChannelId.UberEats:
            return ubereatsLogo;
        case MarketplaceChannelId.Deliveroo:
            return deliverooLogo;
        case MarketplaceChannelId.JustEat:
            return justeatLogo;
        default:
            return assertUnreachable(id);
    }
};

type MarketplaceItemProps = {
    id: MarketplaceChannelId;
    state: MarketplaceState;
    onClick: (id: MarketplaceChannelId) => void;
};

const MarketplaceItem: React.FunctionComponent<React.PropsWithChildren<MarketplaceItemProps>> = ({
    id,
    state,
    onClick,
    ...otherProps
}) => {
    const handleClick = useCallback(() => {
        if (state === 'disabled') return;
        onClick(id);
    }, [id, onClick, state]);

    const src = getLogo(id);

    return (
        <ClickableIcon
            value={id}
            state={state}
            onClick={handleClick}
            icon={src}
            label={`status.${state}`}
            {...otherProps}
        />
    );
};

export default MarketplaceItem;
