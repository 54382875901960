import React from 'react';
import { HeaderLeft, Subtitle } from '@/components/atoms/Section/Section.style';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import { PosAccessBase } from '../../components/PosAcess';
import { PosRolesListTable } from '../../components/PosRoles/tables/PosRolesListTable';
import { Container, Header, HeaderContainer } from '../../components/PosRoles/tables/RolesListTable.style';

const PosRolesList: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <PosAccessBase>
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="pos.roles.page.title" />
                            <Subtitle>
                                <Text text="pos.roles.page.description" />
                            </Subtitle>
                        </HeaderLeft>
                    </Header>
                </HeaderContainer>
                <PosRolesListTable />
            </Container>
        </PosAccessBase>
    );
};

export { PosRolesList };
