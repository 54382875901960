import styled from '@emotion/styled';

export const SpotlightSearchInput = styled.input`
    border: none;
    width: 100%;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    color: ${({ theme }): string => theme.color.black300};
    font-family: ${({ theme }): string => theme.font.family};
    font-size: ${({ theme }): string => theme.font.size.large};
    font-weight: ${({ theme }) => theme.font.weight.medium};

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${({ theme }): string => theme.color.black300};
        font-family: ${({ theme }): string => theme.font.family};
        font-size: ${({ theme }): string => theme.font.size.large};
        font-weight: ${({ theme }) => theme.font.weight.medium};
    }
`;
