import React, { ReactNode, useCallback } from 'react';
import { SVGIcon, SVGIcons } from '../Icon/SVGIcon';
import { Text } from '../Text';
import { Container, TextContainer } from './RadioButton.style';

export type RadioButtonProps<T extends unknown> = {
    label: string;
    value: T;
    checked: boolean;
    disabled?: boolean;
    onChange?: (value: T) => void;
    children?: ReactNode;
};

const RadioButton = <T extends unknown>({
    label,
    value,
    checked,
    disabled = false,
    onChange,
    children,
    ...otherProps
}: RadioButtonProps<T & Partial<React.ButtonHTMLAttributes<HTMLDivElement>>>): JSX.Element => {
    const handleClick = useCallback(() => {
        if (!disabled && onChange) {
            onChange(value);
        }
    }, [onChange, disabled, value]);

    return (
        <Container onClick={handleClick} disabled={disabled} {...otherProps}>
            <SVGIcon
                data-testid={checked ? 'checked' : 'notChecked'}
                icon={checked ? SVGIcons.RADIO_CHECKED : SVGIcons.RADIO}
                size={16}
            />
            <TextContainer>
                <Text type="normal" text={label} />
                {children}
            </TextContainer>
        </Container>
    );
};

RadioButton.displayName = 'Radio';
export default RadioButton;
