import { ModalProps } from '@/components/atoms/Modal';

import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import { SvyButton } from '@/components/savory/SvyButton';
import { prompt } from '../prompt';

export const confirm = (config: ConfirmConfig | string, title?: string): Promise<boolean> | undefined => {
    const innerConfig = typeof config === 'string' ? { children: config } : config;

    return prompt<boolean>(({ onResolve }) => ({
        title,
        ...innerConfig,
        buttons: (
            <SvyButton onClick={() => onResolve(true)}>
                <FormattedMessage id="button.confirm" />
            </SvyButton>
        ),
        onHide: () => onResolve(false),
    }));
};

interface ConfirmConfig extends Omit<ModalProps, 'buttons' | 'onHide' | 'isOpen'> {
    message: React.ReactNode;
}
