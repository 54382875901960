import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import Wizard from '@/components/atoms/Wizard';
import { Input } from '@/components/atoms/Form/Input';
import RawModal from '@/components/atoms/RawModal';
import { useDuplicateCrossSellingMutation } from '@/services/innovorder/crossSelling/crossSelling.endpoint';
import { stringifyError } from '@/utils/errors';
import { CrossSellingModalInputContainer, CrossSellingModalTextInputLabel } from '../CrossSellingModal.style';

export type DuplicateCrossSellingModalProps = {
    brandId: number;
    restaurantId?: number;
    crossSellingId: number | null;
    crossSellingName: string | null;
    onHide: () => void;
};

export const DuplicateCrossSellingModal: React.FunctionComponent<
    React.PropsWithChildren<DuplicateCrossSellingModalProps>
> = ({ onHide, crossSellingId, crossSellingName = '' }) => {
    const intl = useIntl();
    const [
        postDuplicateCrossSelling,
        { isSuccess: isDuplicateSuccess, isLoading: isDuplicateLoading, error: duplicateError },
    ] = useDuplicateCrossSellingMutation();
    const { control, getValues, setValue, setError } = useForm<{ name: string | null }>({
        defaultValues: {
            name: null,
        },
    });

    const onDuplicateValidate = useCallback(() => {
        const { name } = getValues();

        if (!(crossSellingId && name)) {
            return setError('name', { message: computeText(intl, 'crossSelling.modal.error.name') });
        }

        return postDuplicateCrossSelling({
            crossSellingId,
            // TODO : send new name
        });
    }, [crossSellingId, setError, intl, getValues, postDuplicateCrossSelling]);

    useEffect(() => {
        setValue('name', `${crossSellingName} - copy` ?? '');
    }, [crossSellingName, setValue]);

    useEffect(() => {
        if (isDuplicateSuccess) {
            onHide();
        }
    }, [isDuplicateSuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={480}
                isLoading={isDuplicateLoading}
                steps={[
                    {
                        title: 'crossSelling.modal.duplicate.title',
                        onCancel: onHide,
                        component: (
                            <CrossSellingModalInputContainer>
                                <CrossSellingModalTextInputLabel
                                    type="small"
                                    weight="medium"
                                    text="crossSelling.name"
                                />
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Input
                                            data-testid="cross-selling-modal-name-input"
                                            value={value ?? ''}
                                            isTouched={!!value && value.length > 0}
                                            onChange={onChange}
                                            error={error?.message}
                                        />
                                    )}
                                />
                            </CrossSellingModalInputContainer>
                        ),
                    },
                ]}
                errorMessage={stringifyError(duplicateError)}
                onValidate={onDuplicateValidate}
            />
        </RawModal>
    );
};
