import React from 'react';
import { useSelector } from 'react-redux';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import PermissionDenied from '@/pages/PermissionDenied';
import { useGetGuestGroupsQuery } from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { useGetActiveStudentsEntitiesQuery } from '@/services/innovorder/brand/brand.endpoints';
import { getUseNewGuestJourneyDesign } from '@/redux/launchDarkly';
import { getUserToken, getAssertedUserBrandId } from '@/redux/user';
import CreateCustomerModal from './CreateCustomerModal';
import { Container } from './CreateCustomer.style';

const CreateCustomer: React.FunctionComponent<React.PropsWithChildren> = () => {
    const shouldUseNewGuestJourneyDesign = useSelector(getUseNewGuestJourneyDesign);
    const token = useSelector(getUserToken);
    const brandId = useSelector(getAssertedUserBrandId);

    const { data: guestGroups } = useGetGuestGroupsQuery(
        {
            brandId,
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const { data: activeStudentEntities } = useGetActiveStudentsEntitiesQuery(
        {
            brandId,
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const onHide = () => {
        redirectToBackofficeV1('/home/customers');
    };
    return shouldUseNewGuestJourneyDesign ? (
        <Container>
            {guestGroups && (
                <CreateCustomerModal
                    title="customer.create.modalTitle"
                    isOpen
                    onHide={onHide}
                    guestGroups={guestGroups}
                    activeStudentEntities={activeStudentEntities}
                />
            )}
        </Container>
    ) : (
        <PermissionDenied />
    );
};

export default CreateCustomer;
