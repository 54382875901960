import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import React from 'react';
import { routes } from '@/core/router/routes';
import useChannelListVM from './ChannelList.viewmodel';
import { Container, Header, HeaderContainer, ChannelsWrapper } from './ChannelList.style';
import { HeaderLeft, Subtitle } from '@/components/atoms/Section/Section.style';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import Channel from '@/components/molecules/Channel';

const ChannelList = () => {
    const { restaurantId, channelsData } = useChannelListVM();
    const tabRoutes = React.useMemo(
        () => routes.filter(({ tab }) => tab === (restaurantId ? 'restaurants' : 'brands')),
        [restaurantId],
    );

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="channels.page.title" />
                            <Subtitle>
                                <Text text="channels.page.description" />
                            </Subtitle>
                        </HeaderLeft>
                    </Header>
                    <ChannelsWrapper>
                        {channelsData.map((channelData) => {
                            return <Channel {...channelData} />;
                        })}
                    </ChannelsWrapper>
                </HeaderContainer>
            </Container>
        </RouterNavigationTabs>
    );
};
export default ChannelList;
