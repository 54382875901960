import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Label } from '@/components/atoms/Label';
import { Input } from '@/components/atoms/Form/Input';
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import kioskLogo from '@/components/atoms/Icon/svg/kiosk.svg';
import webLogo from '@/components/atoms/Icon/svg/web.svg';
import posLogo from '@/components/atoms/Icon/svg/pos.svg';
import trayScanLogo from '@/components/atoms/Icon/svg/scaner.svg';
import { computeText } from '@/locales/utils';
import { theme } from '@/core/theme';
import { WebhookChannels, WebhookConfig, WebhookHeader } from '../types';
import { Container, Icon, Panel, PanelRow, Title, WebhookChannelConfig, ClickableIcon } from './Styles';

export const availableWebhookChannels: WebhookChannels[] = [
    {
        channelId: 1,
        name: 'kiosk',
        logo: kioskLogo,
    },
    {
        channelId: 2,
        name: 'web',
        logo: webLogo,
    },
    {
        channelId: 5,
        name: 'pos',
        logo: posLogo,
    },
    {
        channelId: 12,
        name: 'trayScan',
        logo: trayScanLogo,
    },
];

export interface WebhookConfigurationPanelProps {
    webhook?: WebhookConfig;
    setValid: (state: boolean) => void;
    setDirty?: (state: boolean) => void;
}

type HeadersError = {
    name?: FieldError;
    value?: FieldError;
};

const WebhookConfigurationPanel = ({ webhook, setValid, setDirty }: WebhookConfigurationPanelProps, ref: any) => {
    const intl = useIntl();
    const fieldRequiredErrorText = computeText(intl, 'field.required');
    const invalidUrlErrorText = computeText(intl, 'field.invalidURL');
    const isEditWebhook = !!webhook?.webhookHmacSecret;
    const WebhookFormResolver = yupResolver(
        Yup.object().shape({
            webhookUrl: Yup.string().url(invalidUrlErrorText).required(fieldRequiredErrorText),
            additionalHeaders: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().required(fieldRequiredErrorText),
                        value: Yup.string().required(fieldRequiredErrorText),
                    }),
                )
                .transform((headers) =>
                    headers.filter(({ name, value }: WebhookHeader) => name !== '' || value !== ''),
                ),
            channels: Yup.array(Yup.number()),
        }),
    );
    const {
        control,
        handleSubmit,
        reset,
        formState: { isValid, isDirty },
    } = useForm<WebhookConfig>({
        mode: 'all',
        defaultValues: {
            ...webhook,
            webhookHmacSecret: undefined,
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            additionalHeaders: [...webhook?.additionalHeaders!, { name: '', value: '' }],
        },
        resolver: WebhookFormResolver,
    });

    const {
        fields: headers,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'additionalHeaders',
    });

    const toggleChannel = (value: number[], channelId: number, onChange: (channels: number[]) => void) => {
        const channels = [...value];
        const channelIndex = channels.indexOf(channelId);
        if (channelIndex > -1) channels.splice(channelIndex, 1);
        else channels.push(channelId);
        onChange(channels);
    };

    const copyToClipboard = (event: React.MouseEvent<HTMLSpanElement>, text: string) => {
        const button = event.currentTarget;
        navigator.clipboard.writeText(text).then(() => {
            button.style.color = theme.color.green200;
            setTimeout(() => {
                button.style.color = 'unset';
            }, 300);
        });
    };

    useImperativeHandle(
        ref,
        () => ({
            handleSubmit,
            reset,
        }),
        [handleSubmit, reset],
    );

    useEffect(() => {
        setValid(isValid);
    }, [setValid, isValid]);

    useEffect(() => {
        setDirty?.(isDirty);
    }, [setDirty, isDirty]);

    return (
        <Container>
            <PanelRow style={{ paddingRight: !isEditWebhook ? '30px' : '' }}>
                <Controller
                    control={control}
                    name="webhookUrl"
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Input
                            onChange={onChange}
                            data-testid="webhook_url"
                            onBlur={onBlur}
                            value={value}
                            labelId="webhook_url.label"
                            placeholder="webhook_url.placeholder"
                            error={error?.message}
                        />
                    )}
                />
                {isEditWebhook ? (
                    <>
                        <Input
                            data-testid="webhook_secret"
                            value={webhook?.webhookHmacSecret}
                            labelId="webhook_secret.label"
                            placeholder="webhook_secret.placeholder"
                            disabled={true}
                        />
                        <Icon
                            data-testid="clipboard.copy"
                            icon={SVGIcons.CLIPBOARD}
                            size={18}
                            marginTop="30px"
                            onClick={(e) => copyToClipboard(e, webhook.webhookHmacSecret!)}
                        />
                    </>
                ) : null}
            </PanelRow>
            <Label labelId="webhook.headers.label" />
            {headers.map((headerField, index) => (
                <Controller
                    key={headerField.id}
                    control={control}
                    name={`additionalHeaders.${index}`}
                    render={({ field: { onChange, value: fieldData }, fieldState: { error } }) => (
                        <PanelRow role="header-row">
                            <Input
                                onChange={(e) => onChange({ ...fieldData, name: e.target.value })}
                                name={`headers.${index}.name`}
                                value={fieldData.name}
                                data-testid={`headers.${index}.name`}
                                placeholder="webhook.headers.name.placeholder"
                                error={(error as HeadersError)?.name?.message}
                            />
                            <Input
                                onChange={(e) => onChange({ ...fieldData, value: e.target.value })}
                                name={`headers.${index}.value`}
                                value={fieldData.value}
                                data-testid={`headers.${index}.value`}
                                placeholder="webhook.headers.value.placeholder"
                                error={(error as HeadersError)?.value?.message}
                            />
                            {index === headers.length - 1 ? (
                                <Icon
                                    data-testid="headers.add"
                                    icon={SVGIcons.PLUS}
                                    size={18}
                                    onClick={() => append({ name: '', value: '' })}
                                />
                            ) : (
                                <Icon
                                    data-testid={`headers.${index}.remove`}
                                    icon={SVGIcons.TRASH}
                                    color="red"
                                    size={18}
                                    onClick={() => remove(index)}
                                />
                            )}
                        </PanelRow>
                    )}
                />
            ))}
            <Label labelId="webhook.channels.title" />
            <Panel>
                <Title type="small" weight="bold" text={'external_pos.channels.subtitle'} />
                <WebhookChannelConfig>
                    <Controller
                        control={control}
                        name="channels"
                        render={({ field: { onChange, value } }) => (
                            <>
                                {availableWebhookChannels.map((channel, index) => {
                                    const channelState = (value as Array<number>).includes(channel.channelId)
                                        ? 'active'
                                        : 'inactive';
                                    return (
                                        <ClickableIcon
                                            key={`channel.${channel.channelId}`}
                                            data-testid={`channel.${channel.channelId}`}
                                            value={value}
                                            name={`webhook.channels.${channel.name}`}
                                            state={channelState}
                                            label={`status.${channelState}`}
                                            icon={availableWebhookChannels[index].logo}
                                            onClick={() => toggleChannel(value, channel.channelId, onChange)}
                                        />
                                    );
                                })}
                            </>
                        )}
                    />
                </WebhookChannelConfig>
            </Panel>
        </Container>
    );
};
export default forwardRef(WebhookConfigurationPanel);
