import { useTheme } from '@emotion/react';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Title } from '@/components/atoms/Title';
import { DropDown } from '../../DropDown';
import { Input } from '../../Form/Input';
import { SVGIcon, SVGIcons } from '../../Icon/SVGIcon';
import { TableColumn } from '../Table.type';
import {
    ButtonContainer,
    TitleContainer,
    Container,
    RemoveButton,
    SearchContainer,
    SearchIcon,
} from './TableToolbar.style';

type TableToolbarProps = {
    placeholder?: string;
    defaultFilter?: string;
    onChange?: (value: string) => void;
    buttons?: ReactElement[];
    onColumnDisplayChange?: (selectedColumns: string[]) => void;
    columns?: readonly TableColumn[];
    selectedColumns?: string[];
    onClearFilters?: () => void;
    selectedLinesCount?: number;
    title?: string;
};

export const TableToolbar = ({
    placeholder = '',
    defaultFilter = '',
    onChange,
    buttons,
    onColumnDisplayChange,
    columns = [],
    selectedColumns = [],
    onClearFilters,
    selectedLinesCount,
    title,
}: TableToolbarProps): ReactElement => {
    const [filter, setFilter] = React.useState<string>(defaultFilter);
    const theme = useTheme();

    const handleChange = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            if (onChange) {
                const { value } = event.currentTarget;
                setFilter(value);
                onChange(value);
            }
        },
        [onChange],
    );

    const handleClearFilters = useCallback(() => {
        if (onChange) {
            const value = '';
            setFilter(value);
            onChange(value);
        }
        if (onClearFilters) onClearFilters();
    }, [onChange, onClearFilters]);

    const items = useMemo(
        () =>
            columns
                ? columns.map((column) => ({
                      value: String(column.accessor),
                      label: String(column.label ?? column.Header),
                  }))
                : [],
        [columns],
    );

    const hasButtons = Boolean(buttons?.length) || Boolean(onColumnDisplayChange) || Boolean(onClearFilters);
    return (
        <Container>
            {title && (
                <TitleContainer>
                    <Title text={title} />
                </TitleContainer>
            )}

            <SearchContainer>
                {onChange && (
                    <>
                        <SearchIcon icon={SVGIcons.MAGNIFY} size={20} color={theme.color.black300} />

                        <Input
                            type="text"
                            onChange={handleChange}
                            placeholder={placeholder}
                            value={filter}
                            data-testid="table-search"
                        />
                    </>
                )}
            </SearchContainer>
            {(hasButtons || !!selectedLinesCount) && (
                <ButtonContainer>
                    {selectedLinesCount !== undefined && (
                        <FormattedMessage id="table.selection.count" values={{ count: selectedLinesCount }} />
                    )}
                    {onClearFilters && (
                        <RemoveButton onClick={handleClearFilters} buttonType="secondary">
                            <SVGIcon icon={SVGIcons.CROSS_CIRCLED} size={16} />
                            <FormattedMessage id="table.clearAllFilters" />
                        </RemoveButton>
                    )}
                    {onColumnDisplayChange && (
                        <div>
                            <DropDown
                                items={items}
                                selected={selectedColumns}
                                onChange={onColumnDisplayChange}
                                align="end"
                                button={
                                    <>
                                        <SVGIcon icon={SVGIcons.COLUMN} size={16} />
                                        <FormattedMessage
                                            id={`${
                                                selectedColumns.length === 0 ||
                                                selectedColumns.length === columns.length
                                                    ? 'table.columnSelection.button'
                                                    : 'table.columnSelection.buttonFiltered'
                                            }`}
                                            values={{ count: selectedColumns.length }}
                                        />
                                    </>
                                }
                                headerLabel="table.columnSelection.header"
                                filterPlaceholder="table.columnSelection.placeholder"
                                clearLabel="table.searchColumn.clearFilter"
                                type="checkbox"
                            />
                        </div>
                    )}
                    {buttons}
                </ButtonContainer>
            )}
        </Container>
    );
};
