import { SVGIcon } from '@/components/atoms/Icon/SVGIcon';
import styled from '@emotion/styled';

import { Button } from '../../Button';

export const Container = styled.div`
    margin: ${({ theme }) => theme.spacing[2]};
    height: 40px;
    display: flex;
    justify-content: space-between;
`;

export const SearchContainer = styled.div`
    width: 250px;
    display: flex;
    position: relative;

    > div {
        width: 100%;
    }

    > div > div {
        display: flex;
        justify-content: start;
        align-items: center;

        font-family: ${({ theme }): string => theme.font.family};
        font-size: ${({ theme }): string => theme.font.size.small};
        color: ${({ theme }): string => theme.color.black200};
        background-color: ${({ theme }): string => theme.color.grey100};
        border: solid 1px ${({ theme }): string => theme.color.grey200};

        input {
            position: absolute;
            left: 40px;
            width: 200px;
        }
    }
`;

export const SearchIcon = styled(SVGIcon)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
    align-items: center;
`;

export const RemoveButton = styled(Button)`
    padding: ${({ theme }) => theme.spacing[2]};
    background: ${({ theme }) => theme.color.white100};
    &:hover {
        background: ${({ theme }) => theme.color.white200};
    }
    & > div {
        display: flex;
        gap: ${({ theme }) => theme.spacing[2]};
        color: ${({ theme }) => theme.color.black200};
        font-size: ${({ theme }) => theme.font.size.normal};
        font-weight: ${({ theme }) => theme.font.weight.regular};
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
