import { CostSlice } from '@/services/innovorder/autoBilling/autoBilling.type';

const isEdgeInSlice = (value: number, costSlice: CostSlice): boolean =>
    value >= costSlice.from && value <= costSlice.to;

const doesIncludeSlice = (sliceToAdd: CostSlice, existingSlice: CostSlice): boolean =>
    sliceToAdd.from < existingSlice.from && sliceToAdd.to > existingSlice.to;

export const getIntersectingSlice = (sliceToAdd: CostSlice, costSlices?: CostSlice[]): CostSlice | undefined => {
    if (!costSlices || !costSlices.length) {
        return undefined;
    }
    return costSlices.find(
        (existingSlice) =>
            isEdgeInSlice(sliceToAdd.from, existingSlice) ||
            isEdgeInSlice(sliceToAdd.to, existingSlice) ||
            doesIncludeSlice(sliceToAdd, existingSlice),
    );
};
