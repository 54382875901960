import styled from '@emotion/styled';
import { Input } from '@/components/atoms/Form/Input';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import { withTheme, withTooltip } from '@innovorder/savory';

const TextWithTooltip = withTheme(withTooltip(Text));

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 16px;
`;

export const ConecsContainer = styled.div`
    display: block;
`;

export const PanelTitle = styled(Title)`
    color: ${({ theme }) => theme.color.black};
    display: inline-block;
`;

export const OptionalText = styled(TextWithTooltip)`
    color: ${({ theme }) => theme.color.grey500};
    display: inline-block;
    margin-left: 4px;
`;

export const ModalPartsContainer = styled.div`
    display: grid;
    grid-column: 1/3;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    margin-bottom: 24px;
`;

export const ModalConfigurationContainer = styled.div`
    height: 800px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white100};
    padding: 0;
    box-sizing: border-box;
`;

export const ModalInputFullContainer = styled.div`
    grid-column: 1/3;
    margin-bottom: 32px;
`;

export const ModalInputContainer = styled.div`
    margin-bottom: 32px;
`;

export const ModalInputTitle = styled(TextWithTooltip)`
    text-transform: uppercase;
`;

export const ModalTitleContainer = styled.div`
    margin-bottom: 8px;
`;

export const ConecsTitleContainer = styled.div`
    margin-bottom: 16px;
`;

export const ModalTextInput = styled(Input)`
    width: auto;
`;
