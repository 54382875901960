import React from 'react';
import { Redirect } from 'react-router';

interface Props {
    children: React.ReactNode;
    isActive: boolean;
    redirect: string;
}
const Gate: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ redirect, isActive, children }) => {
    if (isActive) {
        return <>{children}</>;
    }

    return <Redirect to={redirect} />;
};
export default Gate;
