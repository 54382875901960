import { innovorderApi } from '../index';
import { ApiResponse, ApiRequest } from '../request.types';
import {
    AddCrousModulePayload,
    CrousModuleResponse,
    DeleteCrousModulePayload,
    GetCrousModulePayload,
    PutCrousModulePayload,
} from './moduleCrous.type';

const moduleCrousApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getCrousModule: build.query<CrousModuleResponse, ApiRequest<GetCrousModulePayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/module_crous/restaurants/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CrousModuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        addCrousModule: build.mutation<CrousModuleResponse, ApiRequest<AddCrousModulePayload>>({
            query: ({ restaurantId, module, token }) => ({
                url: `/module_crous/restaurants/${restaurantId}`,
                method: 'POST',
                body: module,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CrousModuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        putCrousModule: build.mutation<CrousModuleResponse, ApiRequest<PutCrousModulePayload>>({
            query: ({ restaurantId, module, token }) => ({
                url: `/module_crous/restaurants/${restaurantId}`,
                method: 'PUT',
                body: module,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CrousModuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteCrousModule: build.mutation<CrousModuleResponse, ApiRequest<DeleteCrousModulePayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/module_crous/restaurants/${restaurantId}`,
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CrousModuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        syncCrousCatalog: build.mutation<CrousModuleResponse, ApiRequest<GetCrousModulePayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/module_crous/restaurants/${restaurantId}/synchronize`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<CrousModuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetCrousModuleQuery,
    useLazyGetCrousModuleQuery,
    useAddCrousModuleMutation,
    usePutCrousModuleMutation,
    useDeleteCrousModuleMutation,
    useSyncCrousCatalogMutation,
} = moduleCrousApi;
