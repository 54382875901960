import styled from '@emotion/styled';
import { Title } from '@/components/atoms/Title';

export const Background = styled.div`
    height: 100vh;
    background: ${({ theme }) => theme.color.grey300};
    padding: 24px;
`;

export const Container = styled.div`
    background: ${({ theme }) => theme.color.white100};
    border-radius: ${({ theme }) => theme.radius.main};
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
        margin-bottom: 50px;
    }

    button {
        margin-top: 24px;
    }
`;

export const StyledTitle = styled(Title)`
    margin-top: 5px;
    max-width: 60%;
    text-align: center;
`;
