import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';

import { computeText } from '@/locales/utils';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { injectDataInUri } from '@/utils/routes';
import { ENV } from '@/utils/env';
import { Routes } from '@/core/router/routes.types';
import { getActualRoute } from '@/redux/router/router.selector';
import SideBarItem from '../SideBarItem';
import { LinkPayload } from '../DynamicMenu/dynamicMenu.type';
import { Title, TitleContainer, Wrapper } from './overlay.style';

type SubMenuItemsProps = {
    items: LinkPayload[];
    title: string;
    icon?: SVGIcons;
};

const fillUrlVariables = (url: Routes | string | undefined) => {
    if (!url) return url;

    return injectDataInUri({
        uri: String(url),
        backofficeUrl: ENV.BACKOFFICE_URL,
    });
};

const SubMenuItems: React.FunctionComponent<React.PropsWithChildren<SubMenuItemsProps>> = ({ items, title, icon }) => {
    const intl = useIntl();
    const theme = useTheme();
    const actualRoute = useSelector(getActualRoute);

    return (
        <Wrapper>
            <TitleContainer>
                {icon && <SVGIcon icon={icon} size={16} color={theme.color.white100} />}

                <Title>{computeText(intl, title)}</Title>
            </TitleContainer>

            {items?.map(({ labelId, icon: itemIcon, route, uri }) => (
                <SideBarItem
                    key={labelId}
                    icon={itemIcon}
                    labelId={labelId}
                    emphased={route === actualRoute}
                    route={fillUrlVariables(route) as Routes}
                    uri={fillUrlVariables(uri)}
                    isSubMenu={true}
                />
            ))}
        </Wrapper>
    );
};

export default SubMenuItems;
