import { ChannelId } from '../channel/channel.type';

export enum RestaurantOptionName {
    INCLUDE_DEPOSITS_IN_TURNOVER = 'INCLUDE_DEPOSITS_IN_TURNOVER',
}

export type RestaurantOption = {
    optionId: number;
    nameValue: RestaurantOptionName;
    isActivated: boolean;
    brandId: number | null;
    restaurantId: number | null;

    created_at: Date;
    updated_at: Date | null;
    deleted_at: Date | null;
};

export type RestaurantOptions = {
    [key in RestaurantOptionName]?: RestaurantOption;
};

export type RestaurantOptionsPayload = {
    [key in RestaurantOptionName]?: Partial<RestaurantOption>;
};

export type UpdateRestaurantOptionsPayload = {
    options: RestaurantOptionsPayload;
    restaurantId: string;
};

export type RestaurantPayload = {
    restaurantId: number;
    address: unknown;
    addressId: number;
    brandId: number;
    contactEmail: string;
    contactName: string | null;
    contactPhone: number;
    currency: string;
    delayTakeAway: number | null;
    externalId: number | null;
    legalId: number | null;
    locale: string;
    name: string;
    phoneIndicator: number | null;
    options?: RestaurantOptions;
};

export type Restaurant = RestaurantPayload & {
    created_at: string;
};

export type GetRestaurantDetailsPayload = {
    restaurantId: string;
};

export type GetRestaurantShiftsPayload = {
    restaurantId: string;
};

export type GetRestaurantShiftsResponse = RestaurantShift[];

export type RestaurantShift = {
    shiftId: string;
    restaurantId: number;

    name: string;
    daysOfWeek: string;
    start: number;
    end: number;
    endsTomorrow: boolean;
    color: string;
    isEnabled: boolean;
    isDefault: boolean;

    createdAt: Date;
    updatedAt: Date;
};

export type ModuleOrderingFlowAttributes = {
    moduleOrderingFlowId: number;
    brandId: number | null;
    restaurantId: number | null;
    isLoginEnabled: boolean;

    is_enabled: boolean;
    created_at: Date;
    updated_at: Date;
};

export type RestaurantModule = {
    [key in ChannelId]: {
        ordering: {
            flow: ModuleOrderingFlowAttributes;
        };
    };
};
