import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';
import { Button } from '@/components/atoms/Button';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { useSelector } from 'react-redux';
import { useCreateOrdersReportGenerationRequestMutation } from '@/services/innovorder/orders/order.endpoints';
import { getAssertedUserBrandId, getUserToken } from '@/redux/user';
import { getUseGenerateOrdersReportAsynchronously } from '@/redux/launchDarkly';
import { formatDate } from '@/utils/date';
import { DateRange } from '@/utils/daterange';
import { useGetRedirectToGenerationsHistoryPageHandler } from '@/utils/innovorder/reportGenerationHistory';
import { ExportOption } from '@/services/innovorder/orders/order.type';

type useGetCreateOrdersReportGenerationHandlerArgs = DateRange & {
    restaurantId?: number;
    exportFileType: ExportOption[];
};
export const useGetCreateOrdersReportGenerationHandler = ({
    startDate,
    endDate,
    exportFileType,
    restaurantId,
}: useGetCreateOrdersReportGenerationHandlerArgs) => {
    const token = useSelector(getUserToken);
    const brandId = useSelector(getAssertedUserBrandId);

    const { redirectToGenerationsHistoryPage } = useGetRedirectToGenerationsHistoryPageHandler();
    const [
        createOrdersReportGenerationRequest,
        {
            isSuccess: isOrdersReportGenerationRequestCreatedWithSuccess,
            isLoading: isOrdersReportGenerationRequestLoading,
        },
    ] = useCreateOrdersReportGenerationRequestMutation();

    const createOrdersReportGeneration = useCallback(() => {
        createOrdersReportGenerationRequest({
            brandId,
            token,
            startDate: formatDate(startDate as Date, 'YYYY-MM-DD'),
            endDate: formatDate(endDate as Date, 'YYYY-MM-DD'),
            exportFileType,
            restaurantId,
        });
    }, [createOrdersReportGenerationRequest, brandId, token, startDate, endDate, exportFileType, restaurantId]);

    useEffect(() => {
        if (isOrdersReportGenerationRequestCreatedWithSuccess) {
            redirectToGenerationsHistoryPage();
        }
    }, [isOrdersReportGenerationRequestCreatedWithSuccess, redirectToGenerationsHistoryPage]);

    return { createOrdersReportGeneration, isOrdersReportGenerationRequestLoading };
};

export type ExportModalProperties = {
    exportButtons: ReactElement[];
    isExportModalVisible: boolean;
    setExportModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useGetExportButtons = (): ExportModalProperties => {
    const [isExportModalVisible, setExportModalVisibility] = useState<boolean>(false);
    const theme = useTheme();
    const shouldUseGenerateOrdersReportAsynchronously = useSelector(getUseGenerateOrdersReportAsynchronously);
    const { redirectToGenerationsHistoryPage } = useGetRedirectToGenerationsHistoryPageHandler();

    return useMemo(() => {
        if (shouldUseGenerateOrdersReportAsynchronously) {
            const exportButtons = [
                <Button key="1" buttonType="secondary" onClick={redirectToGenerationsHistoryPage}>
                    <SVGIcon icon={SVGIcons.HISTORY} size={16} color={theme.color.black200} />
                    <p>
                        <FormattedMessage id="orderList.generation_history" />
                    </p>
                </Button>,
                <Button key="2" onClick={() => setExportModalVisibility(true)}>
                    <SVGIcon icon={SVGIcons.EXPORTS} size={16} color={theme.color.white100} />
                    <p>
                        <FormattedMessage id="orderList.export" />
                    </p>
                </Button>,
            ];
            return {
                exportButtons,
                isExportModalVisible,
                setExportModalVisibility,
            };
        }
        return {
            exportButtons: [],
            isExportModalVisible,
            setExportModalVisibility,
        };
    }, [
        shouldUseGenerateOrdersReportAsynchronously,
        isExportModalVisible,
        redirectToGenerationsHistoryPage,
        theme.color.black200,
        theme.color.white100,
    ]);
};
