import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    margin: ${({ theme }) => theme.spacing[4]};
`;

export const Header = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    width: 100%;
`;

export const Subtitle = styled(Text)`
    line-height: 20px;
`;
