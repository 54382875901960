export enum SupportedLanguages {
    'fr' = 'fr',
    'en' = 'en',
    'sodexo' = 'sodexo',
}

export const SupportedApiLanguages = {
    [SupportedLanguages.fr]: 'fr',
    [SupportedLanguages.en]: 'en',
    [SupportedLanguages.sodexo]: 'fr',
};

export function getLocale(): SupportedLanguages {
    const locale: string = (navigator.languages && navigator.languages[0]) || navigator.language;

    switch (locale) {
        case 'fr-FR':
        case 'fr':
            return SupportedLanguages.fr;
        case 'en-GB':
        case 'en':
            return SupportedLanguages.en;
        case 'sodexo-SODEXO':
        case 'sodexo':
            return SupportedLanguages.sodexo;

        default:
            return SupportedLanguages.fr;
    }
}

export const localeStringMap = {
    [SupportedLanguages.fr]: 'fr-FR',
    [SupportedLanguages.en]: 'en-GB',
    [SupportedLanguages.sodexo]: 'sodexo-SODEXO',
};
