import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { Input } from '@/components/atoms/Form/Input';
import PasswordInput from '@/components/atoms/Form/PasswordInput/PasswordInput';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import { Center, FormContainer, FormTitle, InputSpacer, MarginTop } from './LoginPanel.style';

type LoginPanelProps = {
    onSubmit: (payload: { email: string; password: string; rememberMe: boolean }) => void;
    errorMessage: string;
};

const LoginPanel: React.FunctionComponent<React.PropsWithChildren<LoginPanelProps>> = ({ onSubmit, errorMessage }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submit = () => {
        onSubmit({
            email,
            password,
            rememberMe: false,
        });
    };

    return (
        <div>
            <Center>
                <FormContainer onSubmit={(e) => e.preventDefault()}>
                    <FormTitle>
                        <FormattedMessage id="login.panelTitle" />
                    </FormTitle>
                    <InputSpacer>
                        <Input
                            type="text"
                            onChange={(event): unknown => setEmail(event.target.value)}
                            placeholder="Email"
                            value={email}
                        />
                    </InputSpacer>
                    <PasswordInput value={password} onChange={(value) => setPassword(value)} />
                    {errorMessage !== undefined && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    <MarginTop>
                        <Button onClick={submit} buttonType="primary" fullWidth thick>
                            <Text text="login.login" />
                        </Button>
                    </MarginTop>
                </FormContainer>
            </Center>
        </div>
    );
};

export default LoginPanel;
