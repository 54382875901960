import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';

import Select from '@/components/atoms/Select';
import Loader from '@/components/atoms/Loader';
import { Input } from '@/components/atoms/Form/Input';
import { Table } from '@/components/atoms/Table';

import { EntranceFeeStrategy } from '@/services/innovorder/entranceFee/entranceFee.type';
import { computeText } from '@/locales/utils';
import { SubmitButton } from '@/core/form/SubmitButton';
import { Form } from '@/core/form/Form';

import { useEntranceFeeShiftsVM } from './useEntranceFeeShiftsVm';
import { HeaderContainer, ShiftModal } from './EntranceFeeShifts.style';
import { entranceFeeStrategyMap } from '../EntranceFeeForm';

export type EntranceFeeShiftsProps = {
    restaurantId: number;
    name: string;
    isCreateMode: boolean;
    handleOnHide: () => void;
};

const EntranceFeeShifts: React.FunctionComponent<React.PropsWithChildren<EntranceFeeShiftsProps>> = (props) => {
    const { name, handleOnHide } = props;

    const intl = useIntl();
    const { control, columns, rows, isLoading, dailyLimitMax, handleOnRowClick, handleOnSubmit } =
        useEntranceFeeShiftsVM(props);

    return (
        <Form>
            <ShiftModal
                data-testid="entrance-fee-shifts-form"
                isOpen={true}
                onHide={handleOnHide}
                title={computeText(intl, 'entranceFee.configuration.title', { name })}
                buttons={[
                    <SubmitButton key="entrance-fee-shifts-submit-btn" onClick={handleOnSubmit}>
                        {!isLoading && <FormattedMessage id="button.save" />}
                        {isLoading && <Loader />}
                    </SubmitButton>,
                ]}
            >
                <HeaderContainer>
                    <Controller
                        control={control}
                        name="strategy"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, value } }) => (
                            <Select<EntranceFeeStrategy>
                                value={value}
                                options={entranceFeeStrategyMap}
                                onChange={onChange}
                                labelId="entranceFee.strategy"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="dailyLimit"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return (
                                <Input
                                    error={error && computeText(intl, 'grant.warningLimit')}
                                    onChange={onChange}
                                    value={value?.toString()}
                                    placeholder="entranceFee.dailyLimit"
                                    type="number"
                                    labelId="entranceFee.dailyLimit"
                                    min={0}
                                    max={dailyLimitMax}
                                />
                            );
                        }}
                    />
                </HeaderContainer>

                <Table
                    rows={rows}
                    columns={columns}
                    emptyMessageId="entranceFee.emptyMessage"
                    isSearchable={true}
                    searchPlaceholder="entranceFee.configuration.search"
                    filters={['name']}
                    hasPagination={true}
                    onRowClick={handleOnRowClick}
                />
            </ShiftModal>
        </Form>
    );
};

export default EntranceFeeShifts;
