import { components, OptionProps } from 'react-select';
import { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { Button } from '@/components/atoms/Button';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import React from 'react';
import { useTheme } from '@emotion/react';

export const getOption = (onDeleteReportFilter: (reportFilterId: number) => void) => {
    const Option = (props: OptionProps<SelectOptionInterface<number>>) => {
        const theme = useTheme();

        const handleButtonClick = () => {
            // This cast is used because the value always exists in props, but TypeScript's type inference is not exact
            const optionValue = (props as unknown as { value?: number }).value;
            if (optionValue) {
                onDeleteReportFilter(optionValue);
            }
        };

        return (
            <div style={{ display: 'flex' }}>
                <components.Option {...props} />
                <Button buttonType="secondary" hasBorder={false} onClick={handleButtonClick}>
                    <SVGIcon icon={SVGIcons.TRASH} size={16} color={theme.color.red300} />
                </Button>
            </div>
        );
    };

    return Option;
};
