import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
import { ApiRequest } from '../../request.types';
import {
    GetConfigPayload,
    CreateConfigPayload,
    EdenredConfig,
    UpdateConfigPayload,
    DeleteConfigPayload,
    DeleteConfigResponse,
} from './configurations.type';

const configurationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getEdenredConfig: build.query<EdenredConfig, ApiRequest<GetConfigPayload>>({
            query: ({ token, restaurantId }) => {
                return {
                    url: new URL(`/v1/edenred/configurations/restaurants/${restaurantId}`, ENV.PAYMENT_SERVICE_URL)
                        .href,
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    transformResponse: (response: EdenredConfig) => {
                        return response.id ? response : undefined;
                    },
                    validateStatus: (response, result) => response.status < 400 || result.statusCode === 404,
                };
            },
            extraOptions: { maxRetries: 3 },
        }),
        createEdenredConfig: build.mutation<EdenredConfig, ApiRequest<CreateConfigPayload>>({
            query: ({ token, ...params }) => {
                return {
                    url: new URL('/v1/edenred/configurations', ENV.PAYMENT_SERVICE_URL).href,
                    body: params,
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: EdenredConfig) => response,
            extraOptions: { maxRetries: 3 },
        }),
        updateEdenredConfig: build.mutation<EdenredConfig, ApiRequest<UpdateConfigPayload>>({
            query: ({ token, configurationId, ...params }) => {
                return {
                    url: new URL(`/v1/edenred/configurations/${configurationId}`, ENV.PAYMENT_SERVICE_URL).href,
                    body: params,
                    method: 'PATCH',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: EdenredConfig) => response,
            extraOptions: { maxRetries: 3 },
        }),
        deleteEdenredConfig: build.mutation<DeleteConfigResponse, ApiRequest<DeleteConfigPayload>>({
            query: ({ token, configurationId }) => {
                return {
                    url: new URL(`/v1/edenred/configurations/${configurationId}`, ENV.PAYMENT_SERVICE_URL).href,
                    method: 'DELETE',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: DeleteConfigResponse) => response,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useLazyGetEdenredConfigQuery,
    useGetEdenredConfigQuery,
    useCreateEdenredConfigMutation,
    useUpdateEdenredConfigMutation,
    useDeleteEdenredConfigMutation,
} = configurationApi;
