import React from 'react';
import { useIntl } from 'react-intl';

import { computeCurrency, computeText } from '@/locales/utils';
import { Title } from '@/components/atoms/Title';
import { OrderDetailsPayment as Payments } from '@/services/innovorder/orders/order.type';
import { Container } from './OrderDetailsPayment.style';

type paymentTable = Array<{ type: string; quantity: number; amount: number }>;

type OrderDetailsPaymentProps = {
    payments: Payments[];
};

const OrderDetailsPayment: React.FunctionComponent<React.PropsWithChildren<OrderDetailsPaymentProps>> = ({
    payments,
}) => {
    const intl = useIntl();

    const paymentTable = payments.reduce((table: paymentTable, payment) => {
        const paymentsByType = table.find((line) => line.type === payment.type);
        if (paymentsByType) {
            paymentsByType.quantity += payment.quantity;
            paymentsByType.amount += payment.value;
        } else {
            table.push({ type: payment.type, quantity: payment.quantity, amount: payment.value });
        }
        return table;
    }, []);

    return (
        <Container>
            <div />
            <Title type="small" weight="bold" text="orderDetail.payment.quantity" />
            <Title type="small" weight="bold" text="orderDetail.payment.price" />

            {paymentTable.map((paymentsByType) => {
                return (
                    <React.Fragment key={paymentsByType.type}>
                        <div>{computeText(intl, `order.paymentMethod.${paymentsByType.type}`)}</div>
                        <div>{paymentsByType.quantity}</div>
                        <div>{computeCurrency({ intl, amount: paymentsByType.amount })}</div>
                    </React.Fragment>
                );
            })}
        </Container>
    );
};

export default OrderDetailsPayment;
