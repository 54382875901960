import * as XLSX from 'xlsx';

export class XlsxFile {
    private readonly workbook: XLSX.WorkBook;

    constructor() {
        this.workbook = XLSX.utils.book_new();
    }

    public addWorksheet(name: string, values: (string | number)[][]): void {
        const sheet = XLSX.utils.aoa_to_sheet(values);
        XLSX.utils.book_append_sheet(this.workbook, sheet, name);
    }

    public toArrayBuffer(): ArrayBuffer {
        return XLSX.write(this.workbook, { bookType: 'xlsx', type: 'array' });
    }
}
