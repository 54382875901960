import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { GrantStrategyTranslationMap } from '@/pages/Brand/Grant/GrantForm/GrantForm';
import { CURRENCY_RATE } from '@/components/atoms/Form/utils';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeCurrency, computePercentage, computeText } from '@/locales/utils';
import { Grant } from '@/services/innovorder/grant/grant.type';

export const getGrantsTableRowsVM = (
    grants: Grant[] | undefined,
    onDelete: (id: number) => void,
    intl: IntlShape,
): TableRow[] =>
    !grants
        ? []
        : grants.map(
              ({
                  brandId,
                  grantId,
                  amount,
                  code,
                  labelTicket,
                  restaurants,
                  type,
                  dailyLimit,
                  strategy,
                  ceiling,
                  threshold,
              }) => ({
                  amount: {
                      type: 'computedNumber',
                      value: {
                          text:
                              type === 'ABSOLUTE'
                                  ? computeCurrency({ intl, amount })
                                  : computePercentage({ amount: amount / CURRENCY_RATE }),
                          number: amount,
                      },
                  },
                  code: {
                      type: 'string',
                      value: { text: code },
                  },
                  labelTicket: {
                      type: 'string',
                      value: { text: labelTicket },
                  },
                  restaurants: {
                      type: 'list',
                      value: { listItems: restaurants.map(({ name }) => name), isTruncated: true },
                  },
                  dailyLimit: {
                      type: 'string',
                      value: { text: dailyLimit.toString() },
                  },
                  strategy: {
                      type: 'string',
                      value: {
                          text: GrantStrategyTranslationMap[strategy],
                      },
                  },
                  ceiling: {
                      type: 'string',
                      value: {
                          text: ceiling ? computeCurrency({ intl, amount: ceiling }) : '',
                      },
                  },
                  threshold: {
                      type: 'string',
                      value: {
                          text: threshold ? computeCurrency({ intl, amount: threshold }) : '',
                      },
                  },
                  action: {
                      type: 'action',
                      value: {
                          children: computeText(intl, 'button.delete'),
                          buttonType: 'outlinedPrimary',
                          onClick: () => onDelete(grantId),
                      },
                  },
                  linkTo: {
                      type: 'linkTo',
                      value: { brandId: String(brandId), grantId: String(grantId) },
                  },
              }),
          );

export const getGrantsTableColumnsVM = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'grant.amount'),
        accessor: 'amount',
        maxWidth: 75,
    },
    {
        Header: computeText(intl, 'grant.code'),
        accessor: 'code',
        maxWidth: 120,
    },
    {
        Header: computeText(intl, 'grant.labelTicket'),
        accessor: 'labelTicket',
        maxWidth: 120,
    },
    {
        Header: computeText(intl, 'grant.restaurants'),
        accessor: 'restaurants',
        maxWidth: 300,
    },
    {
        Header: computeText(intl, 'grant.dailyLimit'),
        accessor: 'dailyLimit',
        maxWidth: 75,
    },
    {
        Header: computeText(intl, 'grant.strategy'),
        accessor: 'strategy',
        maxWidth: 150,
    },
    {
        Header: computeText(intl, 'grant.ceiling'),
        accessor: 'ceiling',
        maxWidth: 75,
    },
    {
        Header: computeText(intl, 'grant.threshold'),
        accessor: 'threshold',
        maxWidth: 100,
    },
    {
        Header: computeText(intl, 'grant.action'),
        accessor: 'action',
        width: '1%',
        disableSortBy: true,
    },
];
