import React from 'react';
import { FormattedMessage } from 'react-intl';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import { Table } from '@/components/atoms/Table';
import { HeaderLeft, HeaderRight, Subtitle } from '@/components/atoms/Section/Section.style';
import { Button } from '@/components/atoms/Button';
import { routes } from '@/core/router/routes';
import { Container, Header, HeaderContainer } from './MenuOperationList.style';
import { useMenuOperationListVM } from './MenuOperationList.viewmodel';

const MenuOperationListPage: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { isLoading, rows, headers, fetchData, refreshData, fetchDataTotalCount } = useMenuOperationListVM();

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'restaurants'), []);

    return (
        <>
            <RouterNavigationTabs routes={tabRoutes}>
                <Container>
                    <HeaderContainer>
                        <Header>
                            <HeaderLeft>
                                <Title text="menuoperation.page.title" />
                                <Subtitle>
                                    <Text text="menuoperation.page.description" />
                                </Subtitle>
                            </HeaderLeft>
                            <HeaderRight>
                                <Button onClick={refreshData} buttonType="secondary" disabled={isLoading}>
                                    <FormattedMessage id="button.refresh" />
                                </Button>
                            </HeaderRight>
                        </Header>
                        <Table
                            rows={rows}
                            emptyMessageId="table.noRowsAfterFilter"
                            columns={headers}
                            hasPagination
                            hasUrlNavigation
                            isSortable={false}
                            fetchData={fetchData}
                            isLoading={isLoading}
                            fetchDataTotalCount={fetchDataTotalCount}
                        />
                    </HeaderContainer>
                </Container>
            </RouterNavigationTabs>
        </>
    );
};

export default MenuOperationListPage;
