import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[3]};
    height: fit-content;
    width: fit-content;
    line-height: 20px;
`;
