import React from 'react';

import {
    useGetEntranceFeeFieldSuggestions,
    useGetGrantFieldSuggestions,
} from '@/pages/Customer/PricingConfigurationSection/hooks';
import { Select } from '@/components/form/Select';
import { Input } from '@/components/form/Input';
import { EntranceFees } from '@/services/innovorder/entranceFee/entranceFee.type';
import { Grants } from '@/services/innovorder/grant/grant.type';
import { PricingRules } from '@/services/innovorder/pricingRule/pricingRule.type';
import { FlexContainer } from './PricingRuleForm.style';
import { getPricingRulesOptions } from './PricingRuleForm.utils';

type PricingRuleFormProps = {
    grants?: Grants;
    pricingRules?: PricingRules;
    entranceFees?: EntranceFees;
};

const PricingRuleForm: React.FunctionComponent<React.PropsWithChildren<PricingRuleFormProps>> = ({
    grants,
    pricingRules,
    entranceFees,
}) => {
    const grantsFieldSuggestions = useGetGrantFieldSuggestions(grants);
    const entrenceFeeFieldSuggestions = useGetEntranceFeeFieldSuggestions(entranceFees);

    return (
        <FlexContainer>
            <Input
                labelId="customer.student.pricing.rules.grant.code"
                name={'grantCode'}
                suggestions={grantsFieldSuggestions}
            />
            <Input
                labelId="customer.student.pricing.rules.entrance.fee.code"
                name={'entranceFeeCode'}
                suggestions={entrenceFeeFieldSuggestions}
            />
            <Select
                labelId="customer.student.pricing.rules.code"
                name={'pricingRuleCode'}
                options={getPricingRulesOptions(pricingRules || [])}
            />
        </FlexContainer>
    );
};

export default PricingRuleForm;
