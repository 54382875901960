import React from 'react';

import { FormattedMessage } from 'react-intl';
import MarketplaceLogo from '@/pages/Config/common/MarketplaceLogo';
import { TitleContainer, PanelTitle, PanelText, PanelSubTitle, ContactButton } from './ExternalPosPanel.style';

const CONTACT_URL = 'https://share.hsforms.com/1gLM3xDgiRR64afcYf0XI0g3e34n';

const ExternalPosPanel: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <div>
            <TitleContainer>
                <MarketplaceLogo name="deliverect" />
                <PanelTitle text="external_pos.title" />
            </TitleContainer>
            <PanelSubTitle text="external_pos.subtitle" weight="bold" />
            <PanelText text="external_pos.description.firstpart" />
            <PanelText text="external_pos.description.secondpart" />
            <PanelText text="external_pos.description.thirdpart" />
            <a href={CONTACT_URL} target="_BLANK" rel="noopener noreferrer">
                <ContactButton buttonType="outlinedPrimary">
                    <FormattedMessage id="deliverect.contact" />
                </ContactButton>
            </a>
        </div>
    );
};

export default ExternalPosPanel;
