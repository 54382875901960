import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from './BillingEntityEditor.style';
import BillingEntityInfoForm, { BillingEntityInfo } from './BillingEntityInfoForm/BillingEntityInfoForm';
import { BillingEntity, CostCompute } from '@/services/innovorder/autoBilling/autoBilling.type';
import { getUserBrandId, getUserToken } from '@/redux/user';
import BillingCostCompute from '../BillingCostCompute';
import { Section } from '@/components/atoms/Section';
import { useGetGroupsMutation } from '@/services/innovorder/brand/brand.endpoints';

export type BillingEntityEditorProps = {
    billingEntity: BillingEntity;
    onIsBillingEntityInfoFormValidChange: (value: boolean) => void;
    onIsBillingCostComputeValidChange: (value: boolean) => void;
    onChange: (billingEntity: BillingEntity) => void;
};
const BillingEntityEditor: React.FunctionComponent<React.PropsWithChildren<BillingEntityEditorProps>> = ({
    billingEntity,
    onChange,
    onIsBillingEntityInfoFormValidChange,
    onIsBillingCostComputeValidChange,
}) => {
    const brandId = useSelector(getUserBrandId) as number;
    const token = useSelector(getUserToken);
    const [getGroups, { data: groups }] = useGetGroupsMutation();

    useEffect(() => {
        if (brandId && token) {
            getGroups({ brandId: brandId as number, token });
        }
    }, [brandId, token, getGroups]);

    const handleCostComputingModeChange = useCallback(
        (costCompute: CostCompute) => {
            onChange({ ...billingEntity, costCompute });
        },
        [billingEntity, onChange],
    );

    const handleBillingEntityInfoChange = useCallback(
        (entityInfo: BillingEntityInfo) => {
            onChange({
                ...billingEntity,
                ...entityInfo,
            });
        },
        [billingEntity, onChange],
    );
    return (
        <Container data-testid="billing-entity-editor">
            <BillingEntityInfoForm
                code={billingEntity.code}
                name={billingEntity.name}
                groupingBy={billingEntity.groupingBy}
                groups={billingEntity.groups}
                groupsOptions={groups ?? []}
                onUpdate={handleBillingEntityInfoChange}
                onIsValidChange={onIsBillingEntityInfoFormValidChange}
            />
            <Section
                overflowBody={false}
                title={`auto.billing.referential.billingEntities.costCompute.title`}
                subtitle={`auto.billing.referential.billingEntities.costCompute.subtitle`}
            >
                <BillingCostCompute
                    costCompute={billingEntity.costCompute}
                    onChange={handleCostComputingModeChange}
                    onIsValidChange={onIsBillingCostComputeValidChange}
                />
            </Section>
        </Container>
    );
};

export default BillingEntityEditor;
