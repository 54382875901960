import React, { useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import type { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { getUser } from '@/redux/user';
import { Routes } from '@/core/router/routes.types';
import { Cell } from 'react-table';
import { TableLayout } from '@/services/innovorder/tableLayout/tableLayout.type';
import { useGetTableLayoutListQuery } from '@/services/innovorder/tableLayout/tableLayout.endpoint';
import { TableLayoutAction, TableLayoutActionTypes } from './types';

const defaultTableLayoutAction: TableLayoutAction = {
    tableLayoutId: null,
    name: null,
    posDevices: [],
    type: null,
};

export function useTableLayoutTableVM() {
    const intl = useIntl();
    const history = useHistory();
    const { params } = useRouteMatch<{ brandId: string; restaurantId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);
    const restaurantId = Number.parseInt(params.restaurantId, 10);
    const user = useSelector(getUser);
    const canEdit = user?.accessFlags.CAN_EDIT_TABLE_LAYOUT;

    const { data: tableLayoutList, isLoading: isTableLayoutLoading } = useGetTableLayoutListQuery({ restaurantId });
    const [action, setAction] = useState<TableLayoutAction>(defaultTableLayoutAction);

    const handleAction = React.useCallback((actionType: TableLayoutActionTypes, tableLayout: TableLayout) => {
        setAction({
            tableLayoutId: tableLayout.tableLayoutId,
            name: tableLayout.name,
            posDevices: tableLayout.posDevices,
            type: actionType || null,
        });
    }, []);

    const handleCreate = () => {
        setAction({ ...defaultTableLayoutAction, type: 'showCreateModal' });
    };

    const headers = React.useMemo(
        () => [
            {
                Header: computeText(intl, 'name.name'),
                accessor: 'name',
            },
            {
                Header: computeText(intl, 'tableLayout.table.associatedPos'),
                accessor: 'associatedPos',
            },
            ...(canEdit
                ? [
                      {
                          Header: computeText(intl, 'action.action'),
                          accessor: 'action',
                          width: '10%',
                          disableSortBy: true,
                      },
                  ]
                : []),
        ],

        [canEdit, intl],
    );

    const rows: TableRow[] = React.useMemo(
        () =>
            [...(tableLayoutList ?? [])].map((tableLayout) => {
                const onClickHandler = (actions: TableLayoutActionTypes) => () => handleAction(actions, tableLayout);

                return {
                    name: {
                        type: 'string',
                        value: {
                            text: tableLayout.name,
                            tableLayoutId: tableLayout.tableLayoutId,
                        },
                    },
                    associatedPos: {
                        type: 'list',
                        value: {
                            listItems: tableLayout.posDevices.map(({ deviceName }) => ({
                                icon: SVGIcons.POS,
                                text: deviceName,
                            })),
                        },
                    },
                    action: {
                        type: 'dropdownText',
                        value: {
                            button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                            align: 'end',
                            withPortal: true,
                            items: [
                                {
                                    value: 1,
                                    label: computeText(intl, 'action.edit'),
                                    onClick: onClickHandler('showEditModal'),
                                },
                                {
                                    value: 2,
                                    label: computeText(intl, 'action.delete'),
                                    onClick: onClickHandler('showDeleteModal'),
                                },
                                {
                                    value: 3,
                                    label: computeText(intl, 'action.publish'),
                                    onClick: onClickHandler('showPublishModal'),
                                },
                            ],
                            type: 'text',
                        },
                    },
                    linkTo: {
                        type: 'linkTo',
                        value: {
                            brandId: String(brandId),
                            restaurantId: String(restaurantId),
                            tableLayoutId: String(tableLayout.tableLayoutId),
                        },
                    },
                };
            }),
        [intl, brandId, restaurantId, handleAction, tableLayoutList],
    );

    const handleRowClick = React.useCallback(
        (cell: Cell<TableRow, TableCell<unknown>> | undefined, event?: React.MouseEvent<HTMLTableCellElement>) => {
            if (!cell) {
                return;
            }

            const { tableLayoutId: clickedTableLayoutId } = cell.row.allCells[0].value.value as {
                tableLayoutId: string;
            };
            const path = generatePath(`${Routes.RestaurantTableLayoutsRooms}`, {
                brandId,
                restaurantId,
                tableLayoutId: clickedTableLayoutId,
            });
            if (clickedTableLayoutId && cell.column.id !== 'action') {
                if (event?.ctrlKey) {
                    window.open(path);
                } else {
                    history.push(path);
                }
            }
        },
        [history, brandId, restaurantId],
    );

    return {
        headers,
        rows,
        action,
        brandId,
        restaurantId,
        clearAction: () => setAction(defaultTableLayoutAction),
        canEdit,
        isTableLayoutLoading,
        handleCreate,
        handleRowClick,
    };
}
