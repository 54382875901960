import * as React from 'react';
import { Text } from '@/components/atoms/Text';
import RawModal from '@/components/atoms/RawModal';
import Wizard from '@/components/atoms/Wizard';
import { computeText } from '@/locales/utils';
import { StepContainer } from '../ImportPOSMenuModal/ImportPOSMenuModal.style';
import { useIntl } from 'react-intl';
import { useManualImportCrousModalVm } from './ManualImportCrousModal.viewmodel';
import { stringifyError } from '@/utils/errors';
import { useEffect } from 'react';

export const ManualImportCrousModal: React.FunctionComponent<any> = ({ onHide, restaurantId }) => {
    const intl = useIntl();
    const { isSyncSuccess, isLoading, handleValidate, loadError } = useManualImportCrousModalVm({
        restaurantId,
        onHide,
    });

    useEffect(() => {
        if (isSyncSuccess) {
            onHide();
            window.location.reload();
        }
    }, [isSyncSuccess, onHide]);

    return (
        <RawModal isOpen={onHide}>
            <Wizard
                width={480}
                isLoading={isLoading}
                steps={[
                    {
                        title: 'menu.modal.manualImportCrous.title',
                        onCancel: onHide,
                        component: (
                            <StepContainer>
                                <Text
                                    type="normal"
                                    weight="regular"
                                    text={computeText(intl, 'menu.modal.manualImportCrous.description')}
                                />
                            </StepContainer>
                        ),
                    },
                ]}
                errorMessage={stringifyError(loadError)}
                onValidate={() => {
                    handleValidate();
                }}
            />
        </RawModal>
    );
};
