import * as React from 'react';
import { Table } from '@/components/atoms/Table';
import { FormattedMessage } from 'react-intl';
import { GetProductListByBrandIdResponse } from '@/services/innovorder/crossSelling/crossSelling.type';
import { SvyButton } from '@/components/savory/SvyButton';
import { ModalFooter, TableContainer } from './ProductsTable.style';
import { useProductsTableVM } from './ProductsTable.viewmodel';

export interface ProductsTableProps {
    brandId: number;
    selectedProductIds: number[];
    onValidate: (products: GetProductListByBrandIdResponse) => void;
    onCancel: () => void;
}

export const ProductsTable: React.FunctionComponent<ProductsTableProps> = (props) => {
    const { columns, rows, tableRef, isLoading, handleCancel, handleValidate } = useProductsTableVM(props);

    return (
        <>
            <TableContainer>
                <Table
                    hasPagination
                    isSearchable
                    isSelectable
                    columns={columns}
                    rows={rows}
                    emptyMessageId="table.noRowsAfterFilter"
                    tableRef={tableRef}
                    isLoading={isLoading}
                />
            </TableContainer>

            <ModalFooter>
                <SvyButton variant="text" type="button" onClick={handleCancel}>
                    <FormattedMessage id="button.cancel" />
                </SvyButton>
                <SvyButton type="button" onClick={handleValidate}>
                    <FormattedMessage id="button.validate" />
                </SvyButton>
            </ModalFooter>
        </>
    );
};
