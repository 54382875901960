import { RouteDeclaration } from '@/core/router/routes.types';
import React, { ReactElement } from 'react';
import { WithResolvedPath, WithResolvedUri } from '@/utils/routes';

export type TabProps = {
    title: string;
    component?: HTMLElement | ReactElement;
    route?: WithResolvedPath<WithResolvedUri<RouteDeclaration>>;
};

const Tab: React.FunctionComponent<React.PropsWithChildren<TabProps>> = ({ component }) => <>{component}</>;
Tab.displayName = 'Tab';

export default Tab;
