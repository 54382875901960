import styled from '@emotion/styled';

export const StepContainer = styled.div`
    padding: 0 ${({ theme }) => theme.spacing[2]};
    max-width: 70vw;
    max-height: 70vh;
    overflow: auto;
`;

export const POSListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => theme.spacing[4]};
`;
export const ImportPOSMenuStep1 = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing[4]};
    gap: ${({ theme }) => theme.spacing[4]};
    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    li {
        margin-top: ${({ theme }) => theme.spacing[2]};
    }
    img {
        max-width: 400px;
    }
`;

export const InfoContainer = styled.div`
    margin: ${({ theme }) => theme.spacing[5]} 0;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[5]};
    span {
        font-size: 32px;
    }
`;
