import * as React from 'react';
import RawModal, { RawModalProps } from '@/components/atoms/RawModal/RawModal';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { CloseIcon, LargePhoto, ModalContent } from './OrderDetailsPhotos.style';

type OrderDetailsPhotoModalProps = Omit<RawModalProps, 'width' | 'height'> & {
    src?: string;
};

const OrderDetailsPhotoModal: React.FunctionComponent<OrderDetailsPhotoModalProps> = ({
    isOpen,
    src,
    onHide,
    ...rest
}) => {
    return (
        <RawModal isOpen={isOpen} onHide={onHide} {...rest}>
            <ModalContent>
                <CloseIcon data-testid="close-btn" icon={SVGIcons.CROSS} size={38} onClick={onHide} />
                <LargePhoto data-testid="large-photo" src={src} />
            </ModalContent>
        </RawModal>
    );
};

export default OrderDetailsPhotoModal;
