import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { useTheme } from '@emotion/react';
import { ActionBar } from '@/components/atoms/ActionBar';
import { Button } from '@/components/atoms/Button';
import { FormattedMessage } from 'react-intl';
import DeleteModal from '@/components/atoms/DeleteModal/DeleteModal';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import { ENV } from '@/utils/env';
import { getUserToken } from '@/redux/user';
import {
    useDeleteWebhookMutation,
    useGetWebhookQuery,
    usePutWebhookMutation,
} from '@/services/innovorder/moduleExernalWebhooks/moduleExternalWebhooks.endpoints';
import { Routes } from '@/core/router/routes.types';
import { stringifyError } from '@/utils/errors';
import { ConfigContainer } from '../../common/style/Common.style';
import WebhookPanel from '../WebhookPanel';
import WebhookConfigurationPanel from '../WebhookConfigurationPanel';
import { WebhookConfig, WebhookEventTypes } from '../types';
import Loader from '../../../../components/atoms/Loader';

type handleSubmitFn = (data: WebhookConfig) => void;

type FormRefType = {
    handleSubmit: (fn: handleSubmitFn) => () => void;
    reset: (data: any) => void;
};

const EditWebhook: React.FunctionComponent<React.PropsWithChildren> = () => {
    const theme = useTheme();
    const formRef = useRef<FormRefType>();
    const [isValid, setValid] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const token = useSelector(getUserToken);
    const { webhookId, brandId, restaurantId } = useParams<{
        webhookId: string;
        brandId: string;
        restaurantId: string;
    }>();
    const { data: externalWebhook, isLoading: isDataLoading } = useGetWebhookQuery({
        token,
        webhookId,
    });

    const [putWebhook, { isLoading: isPutLoading, error: putError }] = usePutWebhookMutation();
    const [deleteWebhook, { isLoading: isDeleteLoading, error: deleteError }] = useDeleteWebhookMutation();

    const onSubmit = useCallback(
        (data: WebhookConfig) => {
            putWebhook({
                token,
                webhookId,
                body: {
                    brandId: Number(brandId),
                    restaurantId: Number(restaurantId),
                    eventType: WebhookEventTypes.orderNotifications,
                    additionalHeaders: JSON.stringify(data.additionalHeaders),
                    webhookUrl: data.webhookUrl,
                    channels: data.channels,
                },
            })
                .unwrap()
                .then((res) => {
                    formRef.current?.reset({
                        ...res,
                        channels: data.channels,
                        additionalHeaders: [...res.additionalHeaders, { name: '', value: '' }],
                    });
                });
        },
        [brandId, putWebhook, restaurantId, token, webhookId],
    );
    const onDelete = useCallback(async () => {
        const res = await deleteWebhook({ token, webhookId }).unwrap();
        if (res)
            document.location.href = new URL(
                `home/brands/${brandId}/restaurants/${restaurantId}/integrations`,
                ENV.BACKOFFICE_URL,
            ).href;
    }, [token, webhookId, brandId, restaurantId, deleteWebhook]);

    if (isDataLoading) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }
    if (!externalWebhook) {
        return <Redirect to={Routes.NotFound} />;
    }

    return (
        <>
            <ConfigContainer>
                <WebhookPanel />
                <WebhookConfigurationPanel
                    ref={formRef}
                    webhook={externalWebhook}
                    setValid={setValid}
                    setDirty={setDirty}
                />
            </ConfigContainer>

            <ActionBar error={stringifyError(putError)}>
                <Button buttonType="red" onClick={() => setDelete(true)}>
                    <FormattedMessage id="button.delete" />
                </Button>

                <Button
                    buttonType="primary"
                    onClick={() => formRef.current?.handleSubmit(onSubmit)()}
                    isLoading={isPutLoading}
                    disabled={!isValid || !isDirty}
                >
                    <FormattedMessage id="button.save" />
                </Button>
            </ActionBar>
            <DeleteModal
                title="webhook.modal.warning"
                description="webhook.modal.confirmDelete"
                isOpen={isDelete}
                onHide={() => setDelete(false)}
                onSubmit={onDelete}
                isLoading={isDeleteLoading}
            >
                {deleteError ? <ErrorMessage>{stringifyError(deleteError)}</ErrorMessage> : null}
            </DeleteModal>
        </>
    );
};

export default EditWebhook;
