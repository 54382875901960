import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Cell, Column } from 'react-table';
import { computeText } from '@/locales/utils';
import type { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import {
    useDeletePosAccessUserMutation,
    useGetPosAccessUsersQuery,
} from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.endpoints';
import { SVGIcon } from '@/components/atoms/Icon/SVGIcon';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon.interface';
import { group } from 'console';

type ParamType = {
    brandId: string;
    restaurantId: string;
};
export type MenuUsersViewModel = {
    headers: Column<TableRow>[];
    rows: TableRow[];
    brandId?: number;
    restaurantId?: number;
    isGetUsersLoading: boolean;
    isLoadingDelete: boolean;
    idToDelete: number | null;
    handleOnRowClick: (
        cell: Cell<TableRow, TableCell<unknown>> | undefined,
        event?: React.MouseEvent<HTMLTableCellElement>,
    ) => void;
    handleOnDeleteClick: () => void;
    handleCloseModal: () => void;
};

export function useUsersTableVM(): MenuUsersViewModel {
    const intl = useIntl();
    const history = useHistory();
    const { brandId, restaurantId } = useParams<ParamType>();

    const { data: users = [], isLoading: isGetUsersLoading } = useGetPosAccessUsersQuery({
        restaurantId: Number(restaurantId),
    });
    const [deletePosAccessUser, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete }] =
        useDeletePosAccessUserMutation();

    const handleOnEditClick = useCallback(
        (userId: number) => {
            history.push(`/brands/${brandId}/restaurants/${restaurantId}/channels/pos/access/users/edit/${userId}`);
        },
        [brandId, history, restaurantId],
    );

    const [idToDelete, setIdToDelete] = React.useState<number | null>(null);

    const handleOnDeleteClick = useCallback(() => {
        idToDelete && deletePosAccessUser({ restaurantId: +restaurantId, posUserId: idToDelete });
    }, [deletePosAccessUser, restaurantId, idToDelete]);

    const handleOnRowClick = useCallback((cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
        if (cell && cell.column.id !== 'action') cell.row.values.action.value.items[0].onClick();
    }, []);

    const handleCloseModal = () => {
        setIdToDelete(null);
    };

    useEffect(() => {
        if (isSuccessDelete) {
            setIdToDelete(null);
        }
    }, [isSuccessDelete]);

    const headers = React.useMemo(
        () => [
            {
                Header: computeText(intl, 'name.name'),
                accessor: 'name',
                width: '20%',
            },
            {
                Header: 'Rôle',
                accessor: 'role',
                width: '10%',
            },
            {
                Header: 'Groupes',
                accessor: 'groups',
                width: '50%',
            },

            {
                Header: computeText(intl, 'action.action'),
                accessor: 'action',
                width: '10%',
                disableSortBy: true,
            },
        ],
        [intl],
    );

    const buildRowDropdownActions = (userId: number) => [
        {
            value: 1,
            label: computeText(intl, 'action.edit'),
            onClick: () => {
                handleOnEditClick(userId);
            },
        },
        {
            value: 2,
            label: computeText(intl, 'action.delete'),
            onClick: () => {
                setIdToDelete(userId);
            },
        },
    ];

    const rows: TableRow[] = React.useMemo(() => {
        if (isGetUsersLoading) {
            return [];
        }
        return users?.map((user) => {
            return {
                name: {
                    type: 'string',
                    value: {
                        text: user.name,
                    },
                },
                role: {
                    type: 'string',
                    value: {
                        text: user.posRole.key,
                    },
                },

                groups: {
                    type: 'list',
                    value: {
                        listItems: [...user.posAccessGroups.map((group) => group.name)],
                    },
                },
                action: {
                    type: 'dropdownText',
                    value: {
                        button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                        align: 'end',
                        withPortal: true,
                        items: buildRowDropdownActions(user.posUserId),
                        type: 'text',
                    },
                },
            };
        });
    }, [isGetUsersLoading, users, intl, handleOnEditClick, handleOnDeleteClick]);

    return {
        headers,
        rows,
        brandId: +brandId,
        restaurantId: +restaurantId,
        isGetUsersLoading,
        isLoadingDelete,
        idToDelete,
        handleOnRowClick,
        handleOnDeleteClick,
        handleCloseModal,
    };
}
