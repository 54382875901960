import { innovorderApi } from '../index';
import { ApiResponse, ApiRequest } from '../request.types';
import {
    GetLocationDetailPayload,
    GetLocationDetailResponse,
    GetLocationsPayload,
    GetLocationsResponse,
    PutLocationsPayload,
    PutLocationsResponse,
} from './marketplace.type';

const marketplaceApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getLocationDetail: build.mutation<GetLocationDetailResponse, ApiRequest<GetLocationDetailPayload>>({
            query: ({ storeReference, token, marketplace }) => ({
                url: `/module_marketplaces/marketplaces/${marketplace}/stores/${storeReference}/verify`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetLocationDetailResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getLocations: build.query<GetLocationsResponse, ApiRequest<GetLocationsPayload>>({
            query: ({ brandId, restaurantId, token, marketplace }) => ({
                url: `/module_marketplaces/marketplaces/${marketplace}/brands/${brandId}/restaurants/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetLocationsResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        putLocations: build.mutation<PutLocationsResponse, ApiRequest<PutLocationsPayload>>({
            query: ({ brandId, restaurantId, locations, token, marketplace }) => ({
                url: `/module_marketplaces/marketplaces/${marketplace}/brands/${brandId}/restaurants/${restaurantId}`,
                method: 'PUT',
                body: locations,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<PutLocationsResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetLocationDetailMutation, useGetLocationsQuery, usePutLocationsMutation } = marketplaceApi;
