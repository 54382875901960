import styled from '@emotion/styled';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const PanelTitle = styled(Title)`
    color: ${({ theme }) => theme.color.black};
`;

export const PanelText = styled(Text)`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const PanelWarning = styled(Text)`
    color: ${({ theme }) => theme.color.red300};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const Logo = styled.div`
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
