import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const ItemContainer = styled.div`
    width: 100%;
`;

export const DeviceContainer = styled.div`
    width: calc(50% - ${({ theme }) => theme.spacing[1]});
    ${({ theme }) => theme.breakpoints.mobile} {
        width: 100%;
    }
`;

export const StyledLabel = styled(Text)`
    ${({ theme }) => theme.font.caption.bold};
    line-height: 20px;
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

export const StyledTitle = styled(Title)`
    overflow-wrap: break-word;
`;
