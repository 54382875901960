import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SupportedLanguages } from '@/locales';
import { stringifyError } from '@/utils/errors';
import { Routes } from '@/core/router/routes.types';
import { cookieLocation, cookieLocationBrandGuest } from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { useLazyBrandGuestLoginQuery, useLoginMutation } from '@/services/innovorder/auth/auth.endpoints';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { setBrandFilter } from '@/redux/analyticsFilters';
import { updateUser } from '@/redux/user';
import { getLanguage } from '@/redux/app';
import LoginPanel from './LoginPanel';
import logo from './logo.svg';
import { Header, Logo, ProductTitle } from './Login.style';

export const computeCookieDomain = (host: string): string | undefined => {
    switch (true) {
        case host.includes('.innovorder.io'):
            return '.innovorder.io';
        case host.includes('.innovorder.fr'):
            return '.innovorder.fr';

        default:
            return undefined;
    }
};

export const COOKIE_PATH = '/';

const LoginPage: React.FunctionComponent<React.PropsWithChildren> = () => {
    const [postLogin, { data, isSuccess, error }] = useLoginMutation();
    const [loginAsGuestBrand, { data: guestBrandLoginData, isSuccess: guestBrandLoginSuccess }] =
        useLazyBrandGuestLoginQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const currentLocale = useSelector(getLanguage);
    const [, setCookies] = useCookies([cookieLocation]);

    useEffect(() => {
        if (isSuccess && data?.accessToken) {
            setCookies(
                cookieLocation,
                { accessToken: data.accessToken },
                { domain: computeCookieDomain(window.location.host), path: COOKIE_PATH },
            );
            dispatch(updateUser({ ...data.user, isGuest: false, accessToken: data.accessToken }));

            if (data.user.role !== UserRoles.admin) {
                dispatch(setBrandFilter(data.user.brands[0]));
            }
            if (data.user.role === UserRoles.brandGroup) {
                loginAsGuestBrand({ brandId: data.user.brands[0].brandId });
                return;
            }
            history.push(Routes.Analytics);
        }
    }, [isSuccess, data, history, setCookies, dispatch]);
    useEffect(() => {
        if (guestBrandLoginSuccess && guestBrandLoginData && data) {
            setCookies(
                cookieLocationBrandGuest,
                {
                    accessToken: guestBrandLoginData.accessToken,
                    brandGroupId: data.user.brandGroupId,
                    mainUserEmail: data.user.email,
                },
                { domain: computeCookieDomain(window.location.host), path: COOKIE_PATH },
            );
            history.push(Routes.Analytics);
        }
    }, [guestBrandLoginSuccess, data, guestBrandLoginData]);

    const login = ({ email, password, rememberMe }: { email: string; password: string; rememberMe: boolean }) => {
        if (email && password) {
            postLogin({
                locale: currentLocale || SupportedLanguages.fr,
                email,
                password,
                rememberMe,
            });
        }
    };

    return (
        <div>
            <Header>
                <Logo src={logo} />
                <ProductTitle>Backoffice</ProductTitle>
            </Header>
            <LoginPanel onSubmit={login} errorMessage={stringifyError(error)} />
        </div>
    );
};

export default LoginPage;
