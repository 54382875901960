import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { BillingReferential } from '@/services/innovorder/autoBilling/autoBilling.type';
import { getUserBrandId, getUserToken } from '@/redux/user';
import { useGetBillingReferentialMutation } from '@/services/innovorder/autoBilling/autoBilling.endpoints';

export const useBillingReferential = (): { billingReferential: BillingReferential | undefined; isLoading: boolean } => {
    const brandId = useSelector(getUserBrandId) as number;
    const token = useSelector(getUserToken);

    const [getBillingReferential, { data: billingReferential, isLoading }] = useGetBillingReferentialMutation();

    useEffect(() => {
        if (brandId && token) {
            getBillingReferential({ brandId, token });
        }
    }, [getBillingReferential, brandId, token]);

    return { billingReferential, isLoading };
};
