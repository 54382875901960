import React from 'react';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { SVGIcon, SVGIcons } from './SVGIcon';

export const getChannelIcon = (channel: ChannelId): SVGIcons => {
    switch (Number(channel)) {
        case ChannelId.DELIVEROO:
            return SVGIcons.DELIVEROO;
        case ChannelId.JUST_EAT:
            return SVGIcons.JUSTEAT;
        case ChannelId.KIOSK:
            return SVGIcons.KIOSK;
        case ChannelId.POS:
            return SVGIcons.POS;
        case ChannelId.UBER_EATS:
            return SVGIcons.UBEREATS;
        case ChannelId.WEB:
            return SVGIcons.WEB;
        case ChannelId.VENDING_MACHINE:
            return SVGIcons.NAYAX;
        default:
            return SVGIcons.BUBBLES;
    }
};

export const renderChannelIcon = (channelId: ChannelId) => {
    return <SVGIcon icon={getChannelIcon(channelId)} size={28} />;
};
