import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { computeCurrency } from '@/locales/utils';
import { getTaxLetter } from '../utils';
import { Container, LinePrice, LineText, StyledItemName } from './OrderDetailsTicketLine.style';

export type TicketLineType = 'item' | 'misc' | 'sum' | 'finalprice';

type OrderDetailsTicketLineProps = {
    type: TicketLineType;
    text: string;
    quantity?: number;
    price?: number;
    code?: string;
    tax?: number;
    taxes?: number[];
    noTranslation?: boolean;
};

const OrderDetailsTicketLine: React.FunctionComponent<React.PropsWithChildren<OrderDetailsTicketLineProps>> = ({
    type,
    text,
    quantity,
    price,
    code,
    tax,
    taxes,
    noTranslation,
}) => {
    const intl = useIntl();

    const taxLetter = tax ? getTaxLetter(tax, taxes) : '';

    return (
        <Container type={type}>
            <LineText>
                {quantity && `${quantity}x `}
                {type === 'item' && <StyledItemName>{text}</StyledItemName>}
                {type !== 'item' && !noTranslation && <FormattedMessage id={text} />}
                {type !== 'item' && noTranslation && text}
            </LineText>
            {price !== undefined && (
                <LinePrice type={type}>
                    {computeCurrency({ intl, amount: price })}
                    {Boolean(price) && taxLetter && ` (${taxLetter})`}
                </LinePrice>
            )}
            {code && <LinePrice type={type}>{code}</LinePrice>}
        </Container>
    );
};

export default OrderDetailsTicketLine;
