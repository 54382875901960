import '@szhsin/react-menu/dist/core.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { FocusableItem, MenuHeader } from '@szhsin/react-menu';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { HeaderGroup } from 'react-table';
import Calendar from '@/components/atoms/Calendar';
import dayjs from 'dayjs';
import { computeText } from '@/locales/utils';
import debounce from 'lodash/debounce';
import { DropDown } from '../../DropDown';
import { StyledInput, StyledMenu } from '../../DropDown/DropDown.style';
import { TableRow } from '../Table.type';
import { NumberFilterValue, filterTypes, getCellValue, DatePickerFilterValue } from '../Table.utils';
import { RangeContainer, RangeInput, StyledHeader } from './tableHeaderFilter.style';

type TableHeaderFilterProps = {
    column: HeaderGroup<TableRow>;
};

const TableHeaderFilter: React.FunctionComponent<React.PropsWithChildren<TableHeaderFilterProps>> = ({ column }) => {
    const intl = useIntl();
    const [localFilterValue, setLocalFilterValue] = useState(column.filterValue || '');

    const menuButton = (
        <StyledHeader>
            {column.render('Header')}
            {Boolean(column.filterValue) && ` (${Array.isArray(column.filterValue) ? column.filterValue.length : 1})`}
        </StyledHeader>
    );

    const valueList = useMemo(() => {
        if (column.filter !== filterTypes.list) return undefined;

        if (Array.isArray(column.Filter)) {
            return column.Filter as Array<{ value: typeof column.filterValue; label: string }>;
        }

        if (!column.preFilteredRows.length) return undefined;

        // No fixed list => build it from data
        const list = new Set();
        column.preFilteredRows.forEach((row) => {
            const cell = row.values[column.id];
            const values = getCellValue(cell);
            if (Array.isArray(values)) {
                values.forEach((value) => list.add(value));
            } else {
                list.add(values);
            }
        });
        return Array.from(list).map((value) => ({ label: value, value })) as Array<{
            value: typeof column.filterValue;
            label: string;
        }>;
    }, [column]);

    const handleListChange = useCallback(
        (values: any[]) => {
            column.setFilter(values.length ? values : undefined);
        },
        [column],
    );

    const handleRangeChange = useCallback(
        (target: string) => {
            return (e: React.ChangeEvent<HTMLInputElement>) => {
                const newTargetValue = e.target?.value ? e.target.value : undefined;
                column.setFilter((oldFilter: NumberFilterValue) => {
                    const newFilter = { ...oldFilter, [target]: newTargetValue };
                    if (newFilter.from === undefined && newFilter.to === undefined) return undefined;
                    return newFilter;
                });
            };
        },
        [column],
    );

    const calendarRef = useRef<HTMLElement>(null);

    const handleDateRangeChange = useCallback(
        (dates: DatePickerFilterValue) => {
            const day = dayjs(dates.endDate);

            const endOfEndDate = dates.endDate ? day.add(23, 'hour').add(59, 'minutes').add(59, 'seconds') : null;

            column.setFilter((oldFilter: DatePickerFilterValue) => {
                calendarRef.current?.focus();
                calendarRef.current?.click();
                const newFilter = { ...oldFilter, startDate: dates.startDate, endDate: endOfEndDate };
                return newFilter;
            });
        },
        [column],
    );

    const debouncedColumnSetFilter = useMemo(
        () =>
            debounce(function (e: React.ChangeEvent<HTMLInputElement>) {
                column.setFilter(e.target.value || undefined);
            }, 300),
        [column],
    );

    const handleStyledInputChange = useMemo(
        () => (e: React.ChangeEvent<HTMLInputElement>) => {
            setLocalFilterValue(e.target.value);
            debouncedColumnSetFilter(e);
        },
        [debouncedColumnSetFilter],
    );

    useEffect(() => {
        setLocalFilterValue(column.filterValue || '');
    }, [column.filterValue]);

    if (valueList) {
        return (
            <DropDown
                type="checkbox"
                items={valueList}
                selected={column.filterValue || []}
                onChange={handleListChange}
                headerLabel={String(column.Header)}
                filterPlaceholder="table.searchColumn.placeholder"
                clearLabel="table.searchColumn.clearFilter"
                button={menuButton}
                isUnstyledButton
                withPortal
            />
        );
    }

    if (column.filter === filterTypes.datePicker) {
        return (
            <StyledMenu
                ref={calendarRef}
                overflow="auto"
                setDownOverflow
                offsetY={5}
                menuButton={menuButton}
                position="anchor"
                transition
            >
                <Calendar
                    openToDate={new Date()}
                    monthShown={2}
                    onChange={handleDateRangeChange}
                    inline={false}
                    selectsRange={true}
                />
            </StyledMenu>
        );
    }

    if (column.filter === filterTypes.number) {
        return (
            <StyledMenu
                overflow="auto"
                setDownOverflow
                offsetY={5}
                menuButton={menuButton}
                position="anchor"
                transition
            >
                <MenuHeader>{column.Header as string}</MenuHeader>
                <RangeContainer>
                    <FocusableItem>
                        {({ ref }: { ref: React.RefObject<HTMLInputElement> }) => (
                            <RangeInput
                                ref={ref}
                                type="number"
                                placeholder="table.searchColumn.placeholder.from"
                                value={column.filterValue?.from === undefined ? '' : column.filterValue.from}
                                onChange={handleRangeChange('from')}
                                withoutItems
                            />
                        )}
                    </FocusableItem>
                    <FocusableItem>
                        {({ ref }: { ref: React.RefObject<HTMLInputElement> }) => (
                            <RangeInput
                                ref={ref}
                                type="number"
                                placeholder="table.searchColumn.placeholder.to"
                                value={column.filterValue?.to === undefined ? '' : String(column.filterValue.to)}
                                onChange={handleRangeChange('to')}
                                withoutItems
                            />
                        )}
                    </FocusableItem>
                </RangeContainer>
            </StyledMenu>
        );
    }

    return (
        <StyledMenu overflow="auto" setDownOverflow offsetY={5} menuButton={menuButton} position="anchor" transition>
            <MenuHeader>{column.Header as string}</MenuHeader>

            <FocusableItem>
                {({ ref }: { ref: React.RefObject<HTMLInputElement> }) => (
                    <StyledInput
                        ref={ref}
                        type="text"
                        placeholder={computeText(intl, 'table.searchColumn', { name: String(column.Header) })}
                        value={localFilterValue}
                        onChange={handleStyledInputChange}
                        withoutItems
                    />
                )}
            </FocusableItem>
        </StyledMenu>
    );
};

export default TableHeaderFilter;
