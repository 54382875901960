import React from 'react';
import { useTheme } from '@emotion/react';

import { SVGIcon, SVGIcons } from '../../Icon/SVGIcon';
import { CalendarInputContainer } from './CalendarInput.style';

export type CalendarInputProps = { value?: string; onClick?: () => unknown };

export const CalendarInput = React.forwardRef<HTMLDivElement, CalendarInputProps>(({ value, onClick }, ref) => {
    const theme = useTheme();
    return (
        <CalendarInputContainer onClick={onClick} data-testid="CalendarInput" ref={ref}>
            <SVGIcon icon={SVGIcons.CALENDAR} color={theme.color.bloodyMary} size={15} />
            {value}
            <SVGIcon icon={SVGIcons.CHEVRON} color={theme.color.black} size={20} />
        </CalendarInputContainer>
    );
});
CalendarInput.displayName = 'CalendarInput';
export default CalendarInput;
