import React from 'react';
import styled from '@emotion/styled';
import { computeText } from '@/locales/utils';
import { Theme } from '@emotion/react';
import { useIntl } from 'react-intl';
import { TextFontWeights } from '@/components/atoms/Text/Text';

const mapFontSize = (theme: Theme): { [K in TitleFontSize]: string } => ({
    normal: theme.font.size.large,
    small: theme.font.size.medium,
});

const TitleStyle = styled.h2<{ weight: TextFontWeights; type: TitleFontSize }>`
    font-size: ${({ theme, type }) => mapFontSize(theme)[type]};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme, weight }) => theme.font.weight[weight]};
    color: ${({ theme }) => theme.color.black};
`;

export type TitleFontSize = 'normal' | 'small';

type Props = {
    text: string;
    weight?: TextFontWeights;
    type?: TitleFontSize;
    values?: Record<string, string | number | Date>;
    noTranslation?: boolean;
};

export const Title: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    type = 'normal',
    weight = 'medium',
    text,
    values,
    noTranslation = false,
    ...otherProps
}) => {
    const intl = useIntl();
    return (
        <TitleStyle weight={weight} type={type} {...otherProps}>
            {noTranslation ? text : computeText(intl, text, values)}
        </TitleStyle>
    );
};
