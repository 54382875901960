import * as React from 'react';
import { isNil } from 'lodash';
import { Input } from '../Input';

export const transformInput = (value: unknown) => {
    const newValue = Number(value);
    return !Number.isNaN(newValue) && newValue !== 0 ? Number((newValue / 100).toFixed(2)) : undefined;
};
export const transformOutput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = target.value.length ? Number(target.value) : undefined;
    return !isNil(newValue) && !Number.isNaN(newValue) ? Math.round(Number(target.value) * 100) : undefined;
};

export const PriceInput: React.FunctionComponent<PriceInputProps> = (props) => {
    const handleKeyDownCapture = (event: React.KeyboardEvent<HTMLInputElement>) => {
        props.onKeyDownCapture?.(event);
        // Do not allow up and down arrow
        const isUpDownKeys = ['arrowup', 'arrowdown'].includes(event.key.toLowerCase());
        // Do not allow exponent
        const isExponentialKey = event.key.toLowerCase() === 'e';
        if (isUpDownKeys || isExponentialKey) {
            event.preventDefault();
            return;
        }
        return event.key;
    };
    return (
        <Input
            {...props}
            type="number"
            unitType="currency"
            placeholder="0.00"
            step="0.01"
            onKeyDownCapture={handleKeyDownCapture}
            transform={{
                input: transformInput,
                output: transformOutput,
            }}
        />
    );
};

type PriceInputProps = Exclude<React.ComponentProps<typeof Input>, 'type' | 'transform' | 'unitType'>;
