import { SupportedLanguages } from '@/locales';
import { User, UserBrand, UserRestaurant, UserRoles } from '@/services/innovorder/user/user.type';

export type LoginPayload = {
    email: string;
    password: string;
    locale: SupportedLanguages;
    rememberMe?: boolean;
};

export type AuthUser = {
    accessFlags: Record<AccessFlags, boolean>;
    userId: number;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    password: string;
    passwordSalt: string;
    passwordService: null;
    role: UserRoles;
    forgottenPasswordToken: string | null;
    forgottenPasswordTokenDate: string | null;
    additionalAccessFlags: string | null;
    disallowedAccessFlags: string | null;
    lastConnection: string;
    brandId: number;
    created_at: string;
    updated_at: string;
    brands: UserBrand[];
    restaurants: UserRestaurant[];
    brandGroupId: number;
};

export enum AccessFlags {
    CAN_ACCESS_CUSTOMERS_SECTION = 'CAN_ACCESS_CUSTOMERS_SECTION',
    CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION = 'CAN_ACCESS_GRANTS_AND_ENTRANCE_FEES_SECTION',
    CAN_ACCESS_IO_PAY = 'CAN_ACCESS_IO_PAY',
    CAN_ARCHIVE_CUSTOMERS = 'CAN_ARCHIVE_CUSTOMERS',
    CAN_CREATE_ORDERS_WITH_NEGATIVE_BALANCE = 'CAN_CREATE_ORDERS_WITH_NEGATIVE_BALANCE',
    CAN_CREDIT_POST_PAYMENT_CUSTOMERS_EWALLET = 'CAN_CREDIT_POST_PAYMENT_CUSTOMERS_EWALLET',
    CAN_EDIT_AREAS = 'CAN_EDIT_AREAS',
    CAN_EDIT_AUTO_BILLING_REFERENTIAL = 'CAN_EDIT_AUTO_BILLING_REFERENTIAL',
    CAN_EDIT_CUSTOMERS_CREDENTIALS = 'CAN_EDIT_CUSTOMERS_CREDENTIALS',
    CAN_EXPORT_CUSTOMERS_CSV = 'CAN_EXPORT_CUSTOMERS_CSV',
    CAN_FORCE_DEFAULT_CREDENTIALS_CHANGE = 'CAN_FORCE_DEFAULT_CREDENTIALS_CHANGE',
    CAN_ONLY_EDIT_PRICE_AND_PRODUCT_NAME = 'CAN_ONLY_EDIT_PRICE_AND_PRODUCT_NAME',
    CAN_SYNC_SALES = 'CAN_SYNC_SALES',
    CAN_CHANGE_OWN_PASSWORD = 'CAN_CHANGE_OWN_PASSWORD',
    CAN_EDIT_TABLE_LAYOUT = 'CAN_EDIT_TABLE_LAYOUT',
    MENU_CATEGORY_CREATE_OR_DELETE = 'MENU_CATEGORY_CREATE_OR_DELETE',
    MENU_CATEGORY_EDIT = 'MENU_CATEGORY_EDIT',
    MENU_CREATE_OR_DELETE = 'MENU_CREATE_OR_DELETE',
    MENU_EDIT = 'MENU_EDIT',
    MENU_PRODUCT_CREATE_OR_DELETE = 'MENU_PRODUCT_CREATE_OR_DELETE',
    MENU_PRODUCT_EDIT = 'MENU_PRODUCT_EDIT',
    MENU_STEP_CREATE_OR_DELETE = 'MENU_STEP_CREATE_OR_DELETE',
    MENU_STEP_EDIT = 'MENU_STEP_EDIT',
    MENU_TAG_CREATE_OR_DELETE = 'MENU_TAG_CREATE_OR_DELETE',
    MENU_TAG_EDIT = 'MENU_TAG_EDIT',
}

export type AuthResponseData = {
    user: AuthUser;
    tokenType: string;
    accessToken: string;
};

export type LoginResponse = {
    access_token: string;
    status: number;
    code: string;
    message: string;
    data: AuthResponseData;
    token_type: string;
};

export type LoginAsGuestPayload = { userId: number };
export type LoginAsGuestResponse = { accessToken: string; user: User };

export type BrandGuestLoginPayload = {
    brandId: number;
};
