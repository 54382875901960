import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled.div`
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    margin: ${({ theme }) => theme.spacing[4]};
    div {
        flex: 1 1 30%;
    }
`;

export const FormControls = styled.div`
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.font.family};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[2]};
    margin: ${({ theme }) => theme.spacing[2]};
    div {
        flex: 1 1 30%;
    }
`;

export const FormControl = styled.div`
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.font.family};
    border-radius: ${({ theme }) => theme.radius.main};
    margin: ${({ theme }) => theme.spacing[4]} 0;
    div {
        flex: 1 1 30%;
    }
`;

export const SuccessMessage = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.color.green200};
`;
