import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUserRestaurants, getUserToken } from '@/redux/user';
import { getParamFromQueryString, replaceParamInUrl } from '@/utils/history';
import { generatePath, useHistory } from 'react-router';
import { Table, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, fetchDataProps } from '@/components/atoms/Table';
import { useTheme } from '@emotion/react';
import { Title } from '@/components/atoms/Title';
import { Routes } from '@/core/router/routes.types';
import RestaurantSelector from '@/components/molecules/RestaurantSelector';
import { useGetTransactionsMutation } from '@/services/innovorder/io-pay/transactions/transaction.endpoints';
import { getBrandType } from '@/redux/brand';
import { convertTableFiltersToQueryFilters, getTransactionsTableColumnsVM, getTransactionsTableRowsVM } from './utils';
import { Container, FlexGrow, Subtitle, TitleContainer, Header, ContentContainer } from './Transactions.style';
import IOPayTabs from '../IOPayTabs';

type Props = Record<string, never>;

const defaultHiddenColumns: string[] = ['brandName'];
const defaultSortBy = [{ id: 'transactionId', desc: true }];

const Transactions: React.FunctionComponent<React.PropsWithChildren<Props>> = () => {
    const intl = useIntl();
    const theme = useTheme();
    const token = useSelector(getUserToken);
    const brandType = useSelector(getBrandType);
    const restaurants = useSelector(getUserRestaurants);
    const history = useHistory();

    const [selectedRestaurantId, setSelectedRestaurantId] = useState(
        Number(getParamFromQueryString(history.location.search, 'restaurant')) || 0,
    );
    const [fetchTransactions, { data, isLoading }] = useGetTransactionsMutation();
    const fetchData = useCallback(
        ({ pageIndex, pageSize, globalFilter, columnFilters }: fetchDataProps) => {
            if (token) {
                const filters = convertTableFiltersToQueryFilters(columnFilters);
                const selectedOrAllRestaurantIds = selectedRestaurantId ? [selectedRestaurantId] : undefined;
                fetchTransactions({
                    restaurantIds: selectedOrAllRestaurantIds,
                    token,
                    search: globalFilter,
                    filters,

                    limit: pageSize ?? DEFAULT_PAGE_SIZE,
                    offset:
                        pageIndex === undefined || pageSize === undefined ? DEFAULT_PAGE_INDEX : pageIndex * pageSize,
                });
            }
        },
        [token, fetchTransactions, selectedRestaurantId],
    );

    const columns = useMemo(
        () => getTransactionsTableColumnsVM(intl, brandType, { includeTransfer: true }),
        [intl, brandType],
    );
    const rows = useMemo(
        () =>
            getTransactionsTableRowsVM(data?.transactions, intl, theme, {
                includeTransfer: true,
                onTransferClick: (transferId: number) =>
                    history.push(generatePath(Routes.IOPayTransferDetail, { transferId })),
            }),
        [data, intl, theme, history],
    );

    const handleRestaurantChange = useCallback(
        (restaurantId: number) => {
            setSelectedRestaurantId(restaurantId);
            replaceParamInUrl(history, 'restaurant', String(restaurantId));
        },
        [history],
    );

    return (
        <IOPayTabs>
            <Container>
                <ContentContainer>
                    <Header data-testid="transactions-head">
                        <TitleContainer>
                            <Title text="transactions.title" />
                            <Subtitle text="transactions.subtitle" />
                        </TitleContainer>
                        <FlexGrow />
                        {restaurants && (
                            <RestaurantSelector
                                buttonLabel={
                                    selectedRestaurantId
                                        ? restaurants.find(
                                              (restaurant) => restaurant.restaurantId === selectedRestaurantId,
                                          )?.name || '???'
                                        : intl.formatMessage({ id: 'orderList.filters.allRestaurants' })
                                }
                                restaurants={restaurants}
                                selected={selectedRestaurantId}
                                onChange={handleRestaurantChange}
                                headerLabel="orderList.filters.restaurantHeader"
                            />
                        )}
                    </Header>
                    <Table
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={columns}
                        hasPagination
                        hasUrlNavigation
                        isFilterable
                        isSortable
                        defaultSortBy={defaultSortBy}
                        fetchData={fetchData}
                        fetchDataTotalCount={data?.total}
                        isLoading={isLoading}
                        columnSelectorId="transactionList"
                        defaultHiddenColumns={defaultHiddenColumns}
                        onRowClickPath={Routes.IOPayTransactionDetail}
                    />
                </ContentContainer>
            </Container>
        </IOPayTabs>
    );
};

export default Transactions;
