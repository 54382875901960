import styled from '@emotion/styled';

export const DropDownButton = styled.div`
    width: calc(100% + ${({ theme }) => theme.spacing[4]});
    margin: -${({ theme }) => theme.spacing[2]};
    color: white;
    border: 1px solid ${({ theme }) => theme.color.slate300};
    background-color: ${({ theme }) => theme.color.slate300};
    height: 40px;
    padding: ${({ theme }) => theme.spacing[3]};
    border-radius: ${({ theme }) => theme.radius.main};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: ${({ theme }) => theme.font.size.normal};
`;
export const DropDownButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
