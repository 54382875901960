import { CSSTransition } from 'react-transition-group';
import React, { useEffect, useRef } from 'react';

import { Routes } from '@/core/router/routes.types';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { OverlayComponent, OverlayComponentCatalog } from '../menu';
import SubMenuItems from '../menu/SubMenuItems';
import { LinkPayload } from '../DynamicMenu/dynamicMenu.type';
import { ComponentContainer, Container, TransparentContainer } from './SideBarOverlay.style';

type SideBarOverlayProps = {
    subMenu?: LinkPayload[] | OverlayComponent;
    title: string;
    icon?: SVGIcons;
    onClose: () => void;
    uri?: string;
    route?: Routes;
};

const SideBarOverlay: React.FunctionComponent<React.PropsWithChildren<SideBarOverlayProps>> = ({
    subMenu,
    title,
    icon,
    onClose,
    uri,
    route,
}) => {
    const overlayRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (!overlayRef.current || overlayRef.current.contains(event.target as Node)) {
                return;
            }
            onClose();
        };

        if (subMenu) {
            document.addEventListener('mousedown', listener);
        }

        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [subMenu, overlayRef, onClose]);

    if (!subMenu) {
        return <></>;
    }
    const Component = !Array.isArray(subMenu) && OverlayComponentCatalog[subMenu];
    return (
        <Container data-testid="overlay-menu" ref={overlayRef}>
            <CSSTransition in={subMenu !== undefined} timeout={0} classNames="sidebar" unmountOnExit>
                <>
                    <ComponentContainer>
                        {Array.isArray(subMenu) && <SubMenuItems items={subMenu} title={title} icon={icon} />}
                        {Component && <Component uri={uri} route={route} />}
                    </ComponentContainer>
                    <TransparentContainer data-testid="overlay-transparent" onClick={onClose} />
                </>
            </CSSTransition>
        </Container>
    );
};

export default SideBarOverlay;
