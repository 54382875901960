import React, { useState } from 'react';

import { computeText } from '@/locales/utils';
import { Input } from '@/components/form/Input';
import { Input as BaseInput } from '@/components/atoms/Form/Input';
import DeleteModal from '@/components/atoms/DeleteModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionBar } from '@/components/atoms/ActionBar';
import { Button } from '@/components/atoms/Button';
import { Form } from '@/core/form/Form';
import { EdenredConfig } from '@/services/innovorder/edenred/configurations/configurations.type';
import { ConfigContainer } from '../../common/style/Common.style';
import { FormCard } from './EdenredForm.style';
import EdenredPanel from '../EdenredPanel';
import { isInteger, isPositive, isPositiveOrZero, isValidSiret, validationPipe } from '@/utils/form/validate';

export type EdenredInputs = {
    name: string;
    siret: string;
    address: string;
    zipCode: string;
    city: string;
};

type EdenredFormProps = {
    config?: EdenredConfig;
    onSubmit: (data: Partial<EdenredInputs>) => void;
    onDelete: () => void;
    error: string;
    loading: boolean;
    loadingDelete: boolean;
};

const EdenredForm: React.FunctionComponent<EdenredFormProps> = ({
    config,
    onSubmit,
    onDelete,
    error,
    loading,
    loadingDelete,
}) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const intl = useIntl();

    const handleShowModal = (): void => setShowDeleteModal(true);
    const handleCloseModal = (): void => setShowDeleteModal(false);
    const handleConfirmDelete = (): void => {
        onDelete();
        handleCloseModal();
    };

    const sent = !!config?.id && !config?.edenred?.toSend && !config?.edenred?.mid;
    const processed = !!config?.edenred?.mid;

    return (
        <Form
            defaultValues={{
                name: config?.name,
                siret: config?.edenred?.siret,
                address: config?.edenred?.address,
                zipCode: config?.edenred?.zipCode,
                city: config?.edenred?.city,
            }}
        >
            {({ handleSubmit }) => (
                <>
                    <ConfigContainer>
                        <EdenredPanel text="edenred.configuration.title" config={config} />
                        <FormCard>
                            <Input
                                name="name"
                                rules={{ required: computeText(intl, 'field.required') }}
                                type="text"
                                labelId="edenred.form.name"
                                placeholder="edenred.form.placeholder.name"
                            />
                            {processed && (
                                <BaseInput disabled={true} labelId="edenred.form.mid" value={config.edenred?.mid} />
                            )}
                            {!processed && (
                                <>
                                    <Input
                                        name="siret"
                                        rules={{
                                            validate: isValidSiret(intl),
                                            required: computeText(intl, 'field.required'),
                                        }}
                                        placeholder="edenred.form.placeholder.siret"
                                        disabled={sent}
                                        labelId="edenred.form.siret"
                                    />

                                    <Input
                                        name="address"
                                        rules={{ required: computeText(intl, 'field.required') }}
                                        placeholder="edenred.form.placeholder.address"
                                        disabled={sent}
                                        labelId="edenred.form.address"
                                    />

                                    <Input
                                        name="zipCode"
                                        rules={{ required: computeText(intl, 'field.required') }}
                                        placeholder="edenred.form.placeholder.zipCode"
                                        disabled={sent}
                                        labelId="edenred.form.zipCode"
                                    />

                                    <Input
                                        name="city"
                                        rules={{ required: computeText(intl, 'field.required') }}
                                        placeholder="edenred.form.placeholder.city"
                                        disabled={sent}
                                        labelId="edenred.form.city"
                                    />
                                </>
                            )}
                        </FormCard>
                    </ConfigContainer>
                    <ActionBar error={error}>
                        {config?.id && (
                            <Button buttonType="red" onClick={handleShowModal} isLoading={loadingDelete}>
                                <FormattedMessage id="button.delete" />
                            </Button>
                        )}

                        <Button buttonType="primary" onClick={handleSubmit(onSubmit)} isLoading={loading}>
                            <FormattedMessage id="button.save" />
                        </Button>
                    </ActionBar>
                    <DeleteModal
                        title="grant.modal.warning"
                        description="grant.modal.confirmDelete"
                        isOpen={showDeleteModal}
                        onHide={handleCloseModal}
                        onSubmit={handleConfirmDelete}
                    />
                </>
            )}
        </Form>
    );
};

export default EdenredForm;
