import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    overflow: auto;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    overflow: auto;
    position: relative;
    background-color: ${({ theme }) => theme.color.grey300};
`;
