import React from 'react';
import { RoomActionTypes } from '../types';
import { DeleteRoomModal } from './DeleteRoomModal/DeleteRoomModal';
import { CreateOrEditRoomModal } from './CreateOrEditRoomModal/CreateOrEditRoomModal';
import { PublishTableLayoutModal } from '../../List/TableLayoutModal/PublishTableLayoutModal/PublishTableLayoutModal';

type RoomModalProps = {
    action: RoomActionTypes | null;
    onHide: () => void;
    roomId: string | null;
    roomName: string | null;
    tableLayoutId: string | null;
    tableLayoutName: string | null;
};

const getActionModal = (action: RoomActionTypes | null) => {
    switch (action) {
        case 'showCreateModal':
            return CreateOrEditRoomModal;
        case 'showDeleteModal':
            return DeleteRoomModal;
        case 'showEditModal':
            return CreateOrEditRoomModal;
        case 'showPublishModal':
            return PublishTableLayoutModal;
        default:
            return null;
    }
};

export const RoomModal: React.FunctionComponent<React.PropsWithChildren<RoomModalProps>> = ({
    action,
    onHide,
    roomId,
    roomName,
    tableLayoutId,
    tableLayoutName,
}) => {
    const ActionModal = getActionModal(action);

    if (!ActionModal) {
        return ActionModal;
    }

    return (
        <ActionModal
            onHide={onHide}
            roomName={roomName}
            roomId={roomId}
            tableLayoutId={tableLayoutId}
            tableLayoutName={tableLayoutName}
        />
    );
};
