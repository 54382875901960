import React, { useState } from 'react';

import { computeText } from '@/locales/utils';
import { Input } from '@/components/form/Input';
import DeleteModal from '@/components/atoms/DeleteModal';
import { Switch } from '@/components/form/Switch';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionBar } from '@/components/atoms/ActionBar';
import { Button } from '@/components/atoms/Button';
import { Form } from '@/core/form/Form';
import { PaygreenConfig } from '@/services/innovorder/paygreen/configurations_deprecated/configurations.type';
import { ConfigContainer } from '../../common/style/Common.style';
import { FormCard } from './PaygreenForm.style';
import PaygreenPanel from '../PaygreenPanel';

export type PaygreenInputs = {
    enablePaygreenV3: boolean;
    label: string;
    privateKey: string;
    publicKey: string;
    secretKey: string;
    shopId: string;
    subShopId: string;
    supportsLuncheon: boolean;
    supportsRestoflash: boolean;
    supportsSwile: boolean;
};

type PaygreenFormProps = {
    config: PaygreenConfig;
    onSubmit: (data: PaygreenInputs) => void;
    onDelete: () => void;
    error: string;
    loading: boolean;
    loadingDelete: boolean;
};

const PaygreenForm: React.FunctionComponent<PaygreenFormProps> = ({
    config,
    onSubmit,
    onDelete,
    error,
    loading,
    loadingDelete,
}) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const intl = useIntl();

    const handleShowModal = (): void => setShowDeleteModal(true);
    const handleCloseModal = (): void => setShowDeleteModal(false);
    const handleConfirmDelete = (): void => {
        onDelete();
        handleCloseModal();
    };

    return (
        <Form
            defaultValues={{
                enablePaygreenV3: config.enablePaygreenV3,
                label: config.label,
                privateKey: config.privateKey ?? '',
                publicKey: config.publicKey ?? '',
                secretKey: config.secretKey ?? '',
                shopId: config.shopId ?? '',
                subShopId: config.subShopId ?? '',
                supportsLuncheon: !!config.supportsLuncheon,
                supportsRestoflash: !!config.supportsRestoflash,
                supportsSwile: !!config.supportsSwile,
            }}
            watch={['enablePaygreenV3']}
        >
            {({ handleSubmit, getValues }) => {
                return (
                    <>
                        <ConfigContainer>
                            <PaygreenPanel text="paygreen.configuration.title" config={config} />
                            <FormCard>
                                <Input
                                    name="label"
                                    type="text"
                                    labelId="paygreen.form.label"
                                    placeholder="paygreen.form.placeholder.label"
                                />
                                <Switch
                                    labelId={'paygreen.form.enablePaygreenV3'}
                                    name={'enablePaygreenV3'}
                                    key={'enablePaygreenV3'}
                                />
                                <>
                                    <Input
                                        name="publicKey"
                                        rules={{
                                            validate: (value) => {
                                                if (!getValues('enablePaygreenV3') && !value)
                                                    return computeText(intl, 'field.required');
                                                return true;
                                            },
                                        }}
                                        type="text"
                                        labelId="paygreen.form.publicKey"
                                        placeholder="paygreen.form.placeholder.publicKey"
                                        disabled={getValues('enablePaygreenV3')}
                                    />
                                    <Input
                                        name="privateKey"
                                        rules={{
                                            validate: (value) => {
                                                if (!getValues('enablePaygreenV3') && !value)
                                                    return computeText(intl, 'field.required');
                                                return true;
                                            },
                                        }}
                                        type="text"
                                        labelId="paygreen.form.privateKey"
                                        placeholder="paygreen.form.placeholder.privateKey"
                                        disabled={getValues('enablePaygreenV3')}
                                    />
                                    <Switch
                                        labelId={'paygreen.form.supportsLuncheon'}
                                        name={'supportsLuncheon'}
                                        key={'supportsLuncheon'}
                                        disabled={getValues('enablePaygreenV3')}
                                    />
                                    <Switch
                                        labelId={'paygreen.form.supportsRestoflash'}
                                        name={'supportsRestoflash'}
                                        key={'supportsRestoflash'}
                                        disabled={getValues('enablePaygreenV3')}
                                    />
                                    <Switch
                                        labelId={'paygreen.form.supportsSwile'}
                                        name={'supportsSwile'}
                                        key={'supportsSwile'}
                                        disabled={getValues('enablePaygreenV3')}
                                    />
                                </>
                                <>
                                    <Input
                                        name="shopId"
                                        type="text"
                                        labelId="paygreen.form.shopId"
                                        placeholder="paygreen.form.placeholder.shopId"
                                        rules={{
                                            validate: (value) => {
                                                if (getValues('enablePaygreenV3') && !value)
                                                    return computeText(intl, 'field.required');
                                                return true;
                                            },
                                        }}
                                        disabled={!getValues('enablePaygreenV3')}
                                    />
                                    <Input
                                        name="secretKey"
                                        type="text"
                                        labelId="paygreen.form.secretKey"
                                        placeholder="paygreen.form.placeholder.secretKey"
                                        rules={{
                                            validate: (value) => {
                                                if (getValues('enablePaygreenV3') && !value)
                                                    return computeText(intl, 'field.required');
                                                return true;
                                            },
                                        }}
                                        disabled={!getValues('enablePaygreenV3')}
                                    />
                                    <Input
                                        name="subShopId"
                                        type="text"
                                        labelId="paygreen.form.subShopId"
                                        placeholder="paygreen.form.placeholder.subShopId"
                                        disabled={!getValues('enablePaygreenV3')}
                                    />
                                </>
                            </FormCard>
                        </ConfigContainer>
                        <ActionBar error={error}>
                            {config?.modulePaygreenId && (
                                <Button buttonType="red" onClick={handleShowModal} isLoading={loadingDelete}>
                                    <FormattedMessage id="button.delete" />
                                </Button>
                            )}

                            <Button buttonType="primary" onClick={handleSubmit(onSubmit)} isLoading={loading}>
                                <FormattedMessage id="button.save" />
                            </Button>
                        </ActionBar>
                        <DeleteModal
                            title="grant.modal.warning"
                            description="grant.modal.confirmDelete"
                            isOpen={showDeleteModal}
                            onHide={handleCloseModal}
                            onSubmit={handleConfirmDelete}
                        />
                    </>
                );
            }}
        </Form>
    );
};

export default PaygreenForm;
