import React from 'react';
import { ENV } from '@/utils/env';

export type StaticImageProps = {
    ressourceUrl: string;
    alt: string;
};

export const StaticImage = ({ ressourceUrl, alt, ...otherProps }: StaticImageProps) => {
    return <img src={`${ENV.STATIC_URL}/${ressourceUrl}`} alt={alt} {...otherProps} />;
};
