import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SavingBar } from '@/components/atoms/SavingBar';
import { useBillingReferential } from '@/hooks/billingReferential/useBillingReferential';
import { Routes } from '@/core/router/routes.types';
import { BillingEntity } from '@/services/innovorder/autoBilling/autoBilling.type';
import { useSaveBillingReferentialMutation } from '@/services/innovorder/autoBilling/autoBilling.endpoints';
import { getUser, getUserBrandId, getUserToken } from '@/redux/user';
import BillingEntityEditor from '../BillingEntityEditor';
import { Container } from './EditBillingEntity.style';
import { getErrorMessage } from '../utils';

interface ParamTypes {
    billingEntityCode: string;
}

const EditBillingEntity: React.FunctionComponent<React.PropsWithChildren> = () => {
    const brandId = useSelector(getUserBrandId) as number;
    const token = useSelector(getUserToken);
    const user = useSelector(getUser);
    const canEditBillingReferential = user?.accessFlags.CAN_EDIT_AUTO_BILLING_REFERENTIAL;
    const [isBillingEntityInfoFormValid, setIsBillingEntityInfoFormValid] = useState<boolean>(true);
    const [isBillingCostComputeValid, setIsBillingCostComputeValid] = useState<boolean>(true);
    const [saveBillingReferential, { isLoading: isSaving, isSuccess }] = useSaveBillingReferentialMutation();
    const history = useHistory();
    const intl = useIntl();
    const { billingEntityCode } = useParams<ParamTypes>();
    const [billingEntity, setBillingEntity] = useState<BillingEntity>();
    const { billingReferential } = useBillingReferential();
    const [errors, setErrors] = useState<string>('');

    useEffect(() => {
        if (billingReferential && billingEntityCode) {
            setBillingEntity(
                billingReferential.entities.find((entity) => entity.code === billingEntityCode) as BillingEntity,
            );
        }
    }, [billingEntityCode, billingReferential]);

    const handleEdit = useCallback(() => {
        if (billingReferential && billingEntity) {
            const entityIndex = billingReferential.entities.findIndex((entity) => entity.code === billingEntityCode);
            const entities = billingReferential.entities.filter((entity) => entity.code !== billingEntityCode);
            entities.splice(entityIndex, 0, billingEntity);
            saveBillingReferential({
                brandId,
                billingReferential: {
                    ...billingReferential,
                    entities,
                },
                token,
            });
        }
    }, [billingReferential, billingEntity, saveBillingReferential, brandId, token, billingEntityCode]);

    useEffect(() => {
        if (isSuccess) {
            history.push(generatePath(Routes.AutoBillingReferential, { brandId }));
        }
    }, [history, isSuccess, brandId]);

    const handleOnEntityChange = useCallback(
        (entity: BillingEntity) => {
            setBillingEntity(entity);
            if (billingReferential) {
                const errorMessage = getErrorMessage(billingReferential, entity, intl, billingEntityCode);
                setErrors(errorMessage);
            }
        },
        [billingEntityCode, billingReferential, intl],
    );

    return (
        <>
            {billingEntity && (
                <Container>
                    <BillingEntityEditor
                        billingEntity={billingEntity}
                        onChange={handleOnEntityChange}
                        onIsBillingEntityInfoFormValidChange={setIsBillingEntityInfoFormValid}
                        onIsBillingCostComputeValidChange={setIsBillingCostComputeValid}
                    />
                    <SavingBar
                        error={errors}
                        onSave={handleEdit}
                        disabled={
                            !isBillingEntityInfoFormValid ||
                            !isBillingCostComputeValid ||
                            errors.length > 0 ||
                            !canEditBillingReferential
                        }
                        loading={isSaving}
                    />
                </Container>
            )}
        </>
    );
};

export default EditBillingEntity;
