import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';

import Modal from '@/components/atoms/Modal';
import Select from '@/components/atoms/Select';
import Loader from '@/components/atoms/Loader';
import { Input } from '@/components/atoms/Form/Input';
import { Table } from '@/components/atoms/Table';

import { GrantStrategy, GrantType } from '@/services/innovorder/grant/grant.type';
import { computeText } from '@/locales/utils';
import { SubmitButton } from '@/core/form/SubmitButton';
import { Form } from '@/core/form/Form';

import { HeaderContainer } from './GrantShifts.style';
import { useGrantShiftsVM } from './useGrantShiftsVm';
import { GrantStrategyMap } from '../GrantForm';

export type GrantShiftsProps = {
    restaurantId: number;
    name: string;
    grantType: GrantType;
    isCreateMode: boolean;
    handleOnHide: () => void;
};

const GrantShifts: React.FunctionComponent<React.PropsWithChildren<GrantShiftsProps>> = (props) => {
    const { name, handleOnHide } = props;

    const intl = useIntl();
    const { controlRestaurantGrant, columns, rows, isLoading, dailyLimitMax, handleOnRowClick, handleOnSubmit } =
        useGrantShiftsVM(props);

    return (
        <Form>
            <Modal
                data-testid="grant-shifts-form"
                isOpen={true}
                onHide={handleOnHide}
                title={computeText(intl, 'grant.configuration.title', { name })}
                buttons={[
                    <SubmitButton key="grant-shifts-submit-btn" onClick={handleOnSubmit}>
                        {!isLoading && <FormattedMessage id="button.save" />}
                        {isLoading && <Loader />}
                    </SubmitButton>,
                ]}
            >
                <HeaderContainer>
                    <Controller
                        control={controlRestaurantGrant}
                        name="strategy"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, value } }) => (
                            <Select<GrantStrategy>
                                value={value}
                                options={GrantStrategyMap}
                                onChange={onChange}
                                labelId="grant.strategy"
                                isTouched={false}
                            />
                        )}
                    />
                    <Controller
                        control={controlRestaurantGrant}
                        name="dailyLimit"
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return (
                                <Input
                                    error={error && computeText(intl, 'grant.warningLimit')}
                                    onChange={onChange}
                                    value={value?.toString()}
                                    placeholder="grant.dailyLimit"
                                    type="number"
                                    labelId="grant.dailyLimit"
                                    min={0}
                                    max={dailyLimitMax}
                                />
                            );
                        }}
                    />
                </HeaderContainer>

                <Table
                    rows={rows}
                    columns={columns}
                    emptyMessageId="grant.emptyMessage"
                    isSearchable={true}
                    searchPlaceholder="grant.configuration.search"
                    filters={['name']}
                    hasPagination={true}
                    onRowClick={handleOnRowClick}
                />
            </Modal>
        </Form>
    );
};

export default GrantShifts;
