import styled from '@emotion/styled';

export const StyledModalContainer = styled.div`
    width: 480px;
    color: ${({ theme }) => theme.color.black200};
    font-family: ${({ theme }): string => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: ${({ theme }) => theme.font.size.small};
    label {
        margin-top: ${({ theme }) => theme.spacing[3]};
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing[3]};
    gap: ${({ theme }) => theme.spacing[4]};
    div {
        heigth: 20px;
    }
`;
