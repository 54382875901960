import styled from '@emotion/styled';

export const Container = styled.div<{ height?: number; width?: number }>`
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
`;
