import * as React from 'react';
import { useForm, FieldPath, FieldValues, UseFormProps, UseFormReturn, FormProvider } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { MutationError } from '@/utils/errors';
import { Text } from '@/components/atoms/Text';
import { usePreventLeave } from '@/hooks/usePreventLeave';

export const Form = <TFieldValues extends FieldValues = FieldValues>({
    children,
    watch,
    preventLeave,
    ...props
}: FormProps<Required<TFieldValues>>): React.ReactElement => {
    const theme = useTheme();
    const form = useForm<Required<TFieldValues>>(props);
    const [submitError, setSubmitError] = React.useState<string | undefined>();

    usePreventLeave(
        !!preventLeave &&
            !form.formState.isSubmitting &&
            !form.formState.isSubmitted &&
            form.formState.isDirty &&
            !!Object.values(form.formState.touchedFields).length,
    );

    React.useEffect(() => {
        if (watch) {
            form.watch(watch);
        }
    }, [form, form.watch, watch]);

    const handleSubmit = React.useCallback<typeof form.handleSubmit>(
        (onValid, onInvalid) => {
            // eslint-disable-next-line consistent-return
            const handleValid: typeof onValid = async (...params) => {
                try {
                    const response = await onValid(...params);
                    setSubmitError(undefined);
                    return response;
                } catch (e) {
                    if (e instanceof MutationError || e instanceof Error) {
                        setSubmitError(e.message);
                    }
                }
            };
            return form.handleSubmit(handleValid, onInvalid);
        },
        [form],
    );

    return (
        <FormProvider {...form}>
            {typeof children === 'function' ? children({ ...form, handleSubmit, submitError }) : children}
            {!!submitError && (
                <Text style={{ color: theme.color.red300 }} text={submitError} noTranslation weight="bold" />
            )}
        </FormProvider>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-types
interface FormProps<TFieldValues extends FieldValues = FieldValues, TContext extends object = object>
    extends UseFormProps<TFieldValues, TContext> {
    children:
        | React.ReactNode
        | ((form: UseFormReturn<TFieldValues, TContext> & { submitError?: string }) => React.ReactNode);
    watch?: FieldPath<TFieldValues>[];
    preventLeave?: boolean;
}
