import React from 'react';
import { Header, Subtitle, TitleContainer } from './OrderList.style';
import { Title } from '@/components/atoms/Title';

export interface PageHeaderProps {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, children }) => (
    <Header>
        <TitleContainer>
            <Title text={title} />
            {subtitle && <Subtitle text={subtitle} />}
        </TitleContainer>
        {children}
    </Header>
);

export default PageHeader;
