import styled from '@emotion/styled';

export const CenterLoader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    padding-top: 90px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: 20px;
`;
