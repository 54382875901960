import styled from '@emotion/styled';
import { SvyIcon } from '../../components/savory/SvyIcon';

export const Container = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    h2 {
        margin-bottom: 10px;
    }
`;

export const SvyIconWithMargin = styled(SvyIcon)`
    margin-left: ${({ theme }) => theme.savory.spacing[2]};
    display: inline-block;
    scale: 1.7;
    &:hover {
        color: ${({ theme }) => theme.savory.colors.primary[500]};
    }
`;
