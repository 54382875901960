import styled from '@emotion/styled';

export const Container = styled.div`
    position: relative;
`;

export const IconContainer = styled.div`
    color: ${({ theme }) => theme.color.black200};
    position: absolute;
    right: 3%;
    top: 0;
    max-width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
`;
