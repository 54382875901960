import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { SupportedLanguages } from '@/locales';
import { Overlay } from '@/layouts/DefaultLayout/SideBar/menu';
import { RootState } from '@/redux/store';

export const appSliceName = 'app';

export type AppState = {
    overlay: Overlay | undefined;
    language: SupportedLanguages | undefined;
    hasPermissionDenied: boolean;
    brandId?: string;
    restaurantId?: string;
    resolvedPathNames: Record<string, string>;
    isSidebarCollapsed: boolean;
};

const initialState: AppState = {
    overlay: undefined,
    language: undefined,
    hasPermissionDenied: false,
    brandId: undefined,
    restaurantId: undefined,
    resolvedPathNames: {},
    isSidebarCollapsed: false,
};

export const appSlice = createSlice<AppState, SliceCaseReducers<AppState>, string>({
    name: appSliceName,
    initialState,
    reducers: {
        setSidebarOverlay: (state, action: PayloadAction<Overlay | undefined>) => {
            return {
                ...state,
                overlay: action.payload,
            };
        },
        setLanguage: (state, action: PayloadAction<SupportedLanguages>) => ({
            ...state,
            language: action.payload,
        }),
        setHasPermissionDenied: (state, action: PayloadAction<boolean>) => ({
            ...state,
            hasPermissionDenied: action.payload,
        }),
        setRestaurantId: (state, action: PayloadAction<string>) => ({
            ...state,
            restaurantId: action.payload,
        }),
        setBrandId: (state, action: PayloadAction<string>) => ({
            ...state,
            brandId: action.payload,
        }),
        setResolvedPathName: (state, action: PayloadAction<{ path: string; name: string }>) => ({
            ...state,
            resolvedPathNames: {
                ...state.resolvedPathNames,
                [action.payload.path]: action.payload.name,
            },
        }),
        removePathName: (state, action: PayloadAction<string>) => ({
            ...state,
            resolvedPathNames: omit(state.resolvedPathNames, action.payload),
        }),
        toggleSidebar: (state) => ({
            ...state,
            isSidebarCollapsed: !state.isSidebarCollapsed,
        }),
    },
});

export const {
    removePathName,
    setSidebarOverlay,
    setRestaurantId,
    setBrandId,
    setLanguage,
    setHasPermissionDenied,
    setResolvedPathName,
    toggleSidebar,
} = appSlice.actions;

export const getApp = (state: RootState): AppState => state.app;
export const getSidebarOverlay = (state: RootState): Overlay | undefined => getApp(state).overlay;
export const getLanguage = (state: RootState): SupportedLanguages | undefined => getApp(state).language;
export const getHasPermissionDenied = (state: RootState): boolean => getApp(state).hasPermissionDenied;
export const getRestaurantIdContext = (state: RootState): string | undefined => getApp(state).restaurantId;
export const getBrandIdContext = (state: RootState): string | undefined => getApp(state).brandId;

export const getResolvedPathNames = (state: RootState): Record<string, string> => getApp(state).resolvedPathNames;
export const getSidebareStatus = (state: RootState): boolean => getApp(state).isSidebarCollapsed;
