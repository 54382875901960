import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: 20px;

    overflow-y: auto;
    flex-grow: 1;
    h2 {
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
    button {
        margin: ${({ theme }) => theme.spacing[4]};
    }
`;

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.color.black200};
    font-size: ${({ theme }) => theme.font.size.small};
`;

export const Link = styled.span`
    margin-left: ${({ theme }) => theme.spacing[1]};
    font-size: ${({ theme }) => theme.font.size.normal};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    color: ${({ theme }) => theme.color.primary};
    text-decoration: underline;
    cursor: pointer;
`;
