import styled from '@emotion/styled';
import { Title } from '@/components/atoms/Title';
import { Text } from '@/components/atoms/Text';
import { Button } from '@/components/atoms/Button';

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const PanelTitle = styled(Title)`
    color: ${({ theme }) => theme.color.black};
`;

export const PanelSubTitle = styled(Text)`
    color: ${({ theme }) => theme.color.black};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const PanelText = styled(Text)`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const ContactButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing[3]};
`;
