import React from 'react';

import { CalendarDayContainer } from './CalendarDay.style';

type CustomCalendarDayProps = {
    day: number;
};

const CalendarDay: React.FunctionComponent<React.PropsWithChildren<CustomCalendarDayProps>> = ({ day }) => {
    return <CalendarDayContainer data-testid="CalendarDay">{day}</CalendarDayContainer>;
};

export default CalendarDay;
