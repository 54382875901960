import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[1]};
`;

export const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Line = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[4]};
`;

export const LineElement = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    min-width: 250px;
`;

export const DropDownContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const DropDownButton = styled.div<{ isError?: boolean }>`
    margin: -${({ theme }) => theme.spacing[2]};
    width: 260px;
    height: 40px;
    padding: ${({ theme }) => theme.spacing[2]};
    border-radius: ${({ theme }) => theme.radius.main};
    border: ${({ isError }) => (isError === true ? '1px solid red' : '1px solid transparent')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: ${({ theme }) => theme.font.size.normal};
    background-color: ${({ theme }): string => theme.color.grey300};
    color: ${({ theme }): string => theme.color.black};
    font-weight: ${({ theme }) => theme.font.weight.bold};
`;
export const DropDownButtonContainer = styled.div`
    width: 230px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledText = styled.div`
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    color: ${({ theme }): string => theme.color.black};
    margin-bottom: 10px;
`;

export const SmallStyledText = styled.div`
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.size.tiny};
    color: ${({ theme }): string => theme.color.black200};
`;

export const DropDownErrorMessage = styled(SmallStyledText)`
    color: red;
    margin-left: ${({ theme }) => theme.spacing[1]};
`;
