import React, { useState } from 'react';
import { Button } from '@/components/atoms/Button';
import { Input } from '@/components/atoms/Form/Input';
import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from 'react-intl';

interface SaveFilterAsProps {
    onSaveFilterAs: (name: string) => void;
}

const Wrapper = styled.div`
    display: flex;
`;

const SaveFilterAs: React.FunctionComponent<SaveFilterAsProps> = ({ onSaveFilterAs }) => {
    const intl = useIntl();
    const [filterName, setFilterName] = useState<string>('');

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterName(event.target.value);
    };

    const onSaveFilter = () => {
        onSaveFilterAs(filterName);
    };

    return (
        <Wrapper>
            <Input
                value={filterName}
                onChange={handleNameChange}
                placeholder={intl.formatMessage({ id: 'report.filtersModal.saveAs.placeholder' })}
            />
            <Button onClick={onSaveFilter} disabled={!filterName}>
                <FormattedMessage id={'report.filtersModal.saveAs.save'} />
            </Button>
        </Wrapper>
    );
};

export default SaveFilterAs;
