import {
    ICustomerAttributes,
    StudentAttributes,
    UpdateCustomerType,
    UpdateCustomerPayload,
    ACCOUNT_PAYMENT_TYPE,
} from '@/services/innovorder/customer/customer.types';
import { getAccountPaymentType, getAccountType } from '@/services/innovorder/customer/customer.utils';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isString } from 'lodash';

export const isPostPaymentAccountPaymentType = (accountPaymentType: string): boolean => {
    return [ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_BANKDEBIT, ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_GUEST].includes(
        accountPaymentType,
    );
};

export const POST_PAYMENT_EWALLET_OVERDRAFT_DEFAULT_AMOUNT = 1000000;

export const getEWalletOverdraftPayload = (selectedPaymentType: string) => {
    if (isPostPaymentAccountPaymentType(selectedPaymentType)) {
        return {
            paymentDailyLimit: null,
            overrideDailyPaymentLimit: false,
        };
    }

    return {};
};
export const prepareUpdateCustomerPayload = (
    customer: ICustomerAttributes,
    formValues: UpdateCustomerType,
): UpdateCustomerPayload => {
    const {
        firstName,
        lastName,
        phone,
        email,
        ewalletReloadRecieveConfirmation,
        ewalletReloadConfirmationEmail,
        paymentDailyLimit,
        student,
        tripAdvisorUsername,
        birthdate,
        password,
        customerId,
    } = customer;

    const {
        accountPaymentType,
        badgeNumber,
        guestGroupId,
        pricingRuleCode,
        studentNumber,
        tariffCode,
        section,
        subSection,
        isBadgeBlocked,
        entranceFeeCode,
        grantCode,
        ewalletOverdraftAmount,
        overrideEwalletOverdraft,
    } = formValues;

    return {
        firstName,
        lastName,
        phone,
        email,
        ewalletReloadRecieveConfirmation: ewalletReloadRecieveConfirmation || false,
        ewalletReloadConfirmationEmail,
        paymentDailyLimit,
        ewalletOverdraftAmount,
        overrideEwalletOverdraft,
        isBadgeBlocked,
        tripAdvisorUsername,
        birthdate,
        password,
        badgeNumber,
        customerId,
        ...getEWalletOverdraftPayload(accountPaymentType),
        student: {
            ...student,
            ...getAccountType(accountPaymentType),
            guestGroupId,
            pricingRuleCode,
            studentNumber,
            tariffCode,
            section,
            subSection,
            grantCode,
            entranceFeeCode,
        },
    };
};

const getStudentDefaultValues = (student: StudentAttributes): Partial<UpdateCustomerType> => {
    const {
        tariffCode,
        studentNumber,
        guestGroupId,
        accountPaymentType: groupAccountPaymentType,
        accountType,
        pricingRuleCode,
        entranceFeeCode,
        grantCode,
        section,
        subSection,
    } = student;
    const accountPaymentType = getAccountPaymentType(groupAccountPaymentType, accountType);
    return {
        tariffCode: tariffCode || undefined,
        studentNumber: studentNumber || undefined,
        guestGroupId: guestGroupId || undefined,
        accountPaymentType: accountPaymentType || undefined,
        section: section || undefined,
        subSection: subSection || undefined,
        entranceFeeCode: entranceFeeCode || undefined,
        grantCode: grantCode || undefined,
        pricingRuleCode: pricingRuleCode || undefined,
    };
};

export const getEwalletOverdraftValues = ({ paymentDailyLimit, ewalletOverdraftAmount }: ICustomerAttributes) => ({
    paymentDailyLimit,
    overrideDailyPaymentLimit: paymentDailyLimit != null,
    overrideEwalletOverdraft: ewalletOverdraftAmount != null,
    ewalletOverdraftAmount: ewalletOverdraftAmount ?? undefined,
});

export const getCustomerDefaultValues = (customer: ICustomerAttributes | undefined): Partial<UpdateCustomerType> => {
    if (!customer) {
        return {};
    }

    const { badgeNumber, isBadgeBlocked } = customer;

    if (!customer.student) {
        return {
            isBadgeBlocked,
            badgeNumber: isString(badgeNumber) ? badgeNumber : undefined,
        };
    }

    const studentDefaultValues = getStudentDefaultValues(customer.student);
    const ewalletOverdraftValues = getEwalletOverdraftValues(customer);
    return {
        isBadgeBlocked,
        badgeNumber: isString(badgeNumber) ? badgeNumber : undefined,
        ...ewalletOverdraftValues,
        ...studentDefaultValues,
    };
};

type FormErrorData = {
    code: string;
    message: string;
};

export const getErrorMessage = (errors: FetchBaseQueryError[]): string => {
    return errors.reduce(
        (message, error: FetchBaseQueryError) => message || (error?.data as FormErrorData)?.message,
        '',
    );
};
