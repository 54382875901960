import React, { useCallback, useEffect } from 'react';
import { useCreatePosAccessUserMutation } from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.endpoints';
import { PostPosAccessUserBody } from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.type';
import { stringifyError } from '@/utils/errors';
import { useParams, useHistory } from 'react-router';
import { PosAccessBase } from '../../components/PosAcess';
import PosUserForm from '../../components/PosUsers/forms/PosUserForm';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
};

const CreatePosAccessUser: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { brandId, restaurantId } = useParams<ParamTypes>();
    const history = useHistory();

    const [createPosAccessUser, { isLoading, isSuccess, error }] = useCreatePosAccessUserMutation();

    const handleSuccess = useCallback(() => {
        if (isSuccess) history.push(`/brands/${brandId}/restaurants/${restaurantId}/channels/pos/access/users/`);
    }, [brandId, history, isSuccess, restaurantId]);

    useEffect(() => {
        handleSuccess();
    }, [handleSuccess]);

    const onSubmit = useCallback(
        async (data: PostPosAccessUserBody) => {
            await createPosAccessUser({ ...data, restaurantId: Number.parseInt(restaurantId, 10) });
        },
        [createPosAccessUser, restaurantId],
    );
    return (
        <PosAccessBase>
            <PosUserForm error={stringifyError(error)} loading={isLoading} onSubmit={onSubmit} />
        </PosAccessBase>
    );
};

export { CreatePosAccessUser };
