export enum MenuRoutes {
    Tags = '/tags',
    PosLayout = '/pos_layout',
    Products = '/products',
    Categories = '/categories',
    Steps = '/steps',
}

export enum SortLayoutTypes {
    RANDOM = 'random',
    BY_NAME = 'by_name',
    BY_PRICE = 'by_price',
}
