import styled from '@emotion/styled';

export const StyledTabList = styled.div`
    border-bottom: 3px solid ${({ theme }) => theme.color.white200};
`;

export const StyledTab = styled.span`
    border: none;
    background-color: ${({ theme }) => theme.color.white100};
    outline: none;
    button {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;

export const StyledMobileTabList = styled(StyledTabList)`
    padding: 20px 20px;
`;
