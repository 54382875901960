import { TableRow } from '@/components/atoms/Table/Table.type';
import { AuditDiff, GetHistoryFilters, StringifiedAuditDiff } from '@/services/innovorder/history/history.type';
import { Filters, SortingRule } from 'react-table';

export const brandHistorySortByMapper = {
    entityType: 'entity_type',
    entityName: 'entity_name',
    restaurantId: 'restaurant_id',
    user: 'user_user_id',
    action: 'action',
    eventDate: 'created_at',
};

export const convertTableSortToQuerySort = (sortBy?: SortingRule<TableRow>[]): string | undefined => {
    if (!sortBy?.length) {
        return undefined;
    }

    const sortByKey = sortBy[0].id as keyof typeof brandHistorySortByMapper;

    return `${brandHistorySortByMapper[sortByKey]} ${sortBy[0].desc ? 'DESC' : 'ASC'}`;
};

export const convertTableFiltersToQueryFilters = (columnFilters?: Filters<TableRow>): GetHistoryFilters => {
    if (!columnFilters?.length) return {};
    const filters = columnFilters.reduce((acc: GetHistoryFilters, column) => {
        if (column.id === 'restaurantId') {
            acc.restaurantIds = column.value;
        } else {
            acc[column.id] = column.value;
        }
        return acc;
    }, {});
    return filters;
};

const stringifyValue = (value: string | boolean | undefined): string => {
    if (typeof value === 'string') return value;
    if (typeof value === 'boolean') return value ? 'Activé' : 'désactivé';
    if (value === undefined) return '∅';

    return JSON.stringify(value);
};

const computeAuditChange = ({ oldValue, newValue, fieldName }: AuditDiff): StringifiedAuditDiff => {
    return {
        oldValue: stringifyValue(oldValue),
        newValue: stringifyValue(newValue),
        fieldName,
    };
};

export const formatAuditDiff = (diff: AuditDiff[]): StringifiedAuditDiff[] => {
    return diff.map(computeAuditChange);
};
