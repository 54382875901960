import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse, ApiResponseBase } from '../request.types';
import {
    EntranceFee,
    EntranceFees,
    GetEntranceFeePayload,
    GetEntranceFeesPayload,
    PutRestaurantEntranceFeeBody,
    EntranceFeeCreateAttributes,
    DeleteEntranceFeePayload,
} from './entranceFee.type';

const entranceFeeApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getEntranceFees: build.query<EntranceFees, ApiRequest<GetEntranceFeesPayload>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/entrance_fees/list`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.EntranceFee],
            transformResponse: (response: ApiResponse<EntranceFees>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getEntranceFee: build.query<EntranceFee, ApiRequest<GetEntranceFeePayload>>({
            query: ({ entranceFeeId, token }) => ({
                url: `/entrance_fees/${entranceFeeId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.EntranceFee],
            transformResponse: (response: ApiResponse<EntranceFee>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        postEntranceFee: build.mutation<EntranceFee, ApiRequest<EntranceFeeCreateAttributes>>({
            query: ({ brandId, vat, code, labelTicket, amount, strategy, dailyLimit, restaurants, type, token }) => ({
                url: `/entrance_fees`,
                method: 'POST',
                body: {
                    brandId,
                    vat,
                    code,
                    labelTicket,
                    amount,
                    strategy,
                    dailyLimit,
                    restaurants,
                    type,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.EntranceFee],
            transformResponse: (response: ApiResponse<EntranceFee>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        putEntranceFee: build.mutation<EntranceFee, ApiRequest<PutRestaurantEntranceFeeBody>>({
            query: ({
                brandId,
                vat,
                code,
                labelTicket,
                amount,
                strategy,
                dailyLimit,
                restaurants,
                token,
                entranceFeeId,
                type,
            }) => ({
                url: `/entrance_fees/${entranceFeeId}`,
                method: 'PUT',
                body: {
                    brandId,
                    vat,
                    code,
                    labelTicket,
                    amount,
                    strategy,
                    dailyLimit,
                    restaurants,
                    type,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.EntranceFee],
            transformResponse: (response: ApiResponse<EntranceFee>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteEntranceFee: build.mutation<ApiResponseBase, ApiRequest<DeleteEntranceFeePayload>>({
            query: ({ entranceFeeId, brandId, token }) => ({
                url: `/entrance_fees/${entranceFeeId}`,
                method: 'DELETE',
                params: { brandId },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.PricingRule, ApiTagTypes.EntranceFee],
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetEntranceFeeQuery,
    useLazyGetEntranceFeesQuery,
    usePostEntranceFeeMutation,
    usePutEntranceFeeMutation,
    useDeleteEntranceFeeMutation,
} = entranceFeeApi;
