import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Loader from '@/components/atoms/Loader';
import { Routes } from '@/core/router/routes.types';
import {
    cookieLocation,
    useAuthCookieHandler,
    cookieLocationGuest,
    cookieLocationBrandGuest,
} from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { useLogoutMutation } from '@/services/innovorder/auth/auth.endpoints';
import { getUserToken } from '@/redux/user';
import { ApiTagTypes, innovorderApi } from '@/services/innovorder';

const Logout: React.FunctionComponent<React.PropsWithChildren> = () => {
    const history = useHistory();
    const token = useSelector(getUserToken);
    const { removeCookie } = useAuthCookieHandler();

    const [postLogout, { isSuccess }] = useLogoutMutation();

    useEffect(() => {
        postLogout({ token });
    }, [postLogout, token]);

    useEffect(() => {
        if (isSuccess) {
            removeCookie(cookieLocation);
            removeCookie(cookieLocationGuest);
            removeCookie(cookieLocationBrandGuest);
            innovorderApi.util.invalidateTags([ApiTagTypes.User]);
            /*We invalidate manually because we removed the invalidatesTags in the logout endpoint in order to fix the disconnection
             when going back to admin from a brand/restaurant mode*/
            history.push(Routes.Login);
        }
    }, [history, isSuccess, removeCookie]);

    return <Loader withContainer />;
};

export default Logout;
