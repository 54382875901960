import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useUrlQueryState = () => {
    const { search } = useLocation();
    const history = useHistory();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    const setQueryParam = (key: string, value: string) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete(key);
        urlSearchParams.append(key, value);
        history.replace({ search: urlSearchParams.toString() });
    };

    return [query, setQueryParam] as const;
};
