import styled from '@emotion/styled';

export const Container = styled.div<{ disabled: boolean }>`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
    align-items: center;
    justify-content: flex-start;
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
