import React, { useState } from 'react';
import { SvyIcon } from '@/components/savory/SvyIcon';
import { ImageContainer, ImagePlaceholder } from './Image.style';

export type ImageProps = {
    alt: string;
    src: string | undefined;
    width?: number;
    height?: number;
};

export const Image: React.FunctionComponent<ImageProps> = ({ width = 80, height = 72, src, ...otherProps }) => {
    const [isError, setError] = useState(false);

    const onImageError = () => {
        setError(true);
    };

    if (isError || !src) {
        return (
            <ImagePlaceholder width={width} height={height}>
                <SvyIcon icon="image-line" />
            </ImagePlaceholder>
        );
    }

    return <ImageContainer width={width} height={height} src={src} {...otherProps} onError={onImageError} />;
};
