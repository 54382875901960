import styled from '@emotion/styled';

export const Pagination = styled.div`
    min-height: 40px;
    font-size: ${({ theme }) => theme.font.size.small};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    color: ${({ theme }) => theme.color.black};
    background-color: ${({ theme }) => theme.color.grey300};
    position: relative;
    padding: ${({ theme }) => theme.spacing[2]};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PaginationButton = styled.button<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    margin: 0 ${({ theme }) => theme.spacing[3]};

    &:focus {
        outline: none;
    }
`;

export const PaginationDetails = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SelectContainer = styled.div`
    position: absolute;
    top: 0;
    left: ${({ theme }) => theme.spacing[3]};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        color: ${({ theme }) => theme.color.black};
    }
`;

export const SelectRows = styled.select`
    font-size: ${({ theme }) => theme.font.size.small};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    background-color: ${({ theme }) => theme.color.grey300};
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

export const SingleOptionPagination = styled.div`
    padding: 0 0 0 4px;
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.small};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
`;
