import React from 'react';
import { useIntl } from 'react-intl';

import Loader from '@/components/atoms/Loader';
import { Section } from '@/components/atoms/Section';
import { Table } from '@/components/atoms/Table';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import Modal from '@/components/atoms/Modal';
import { Text } from '@/components/atoms/Text';
import { SVGIcon } from '@/components/atoms/Icon/SVGIcon';
import { Badge } from '@/components/atoms/Badge';
import { computeText } from '@/locales/utils';
import { AuditDiff } from '@/services/innovorder/history/history.type';
import { Title } from '@/components/atoms/Title';
import { useBrandHistoryVm } from './BrandHistory.viewmodel';
import { AuditTypeContainer, Container, ContentRow, TypeContainer } from './BrandHistory.style';
import { LoaderContainer } from '../Brand/AutoBilling/AutoBillingRequest/AutoBillingRequest.style';

const BrandHistory: React.FunctionComponent = () => {
    const intl = useIntl();
    const {
        rows,
        headers,
        isLoading,
        fetchData,
        fetchDataTotalCount,
        tabRoutes,
        handleRowClick,
        isModalOpen,
        closeModal,
        auditDetail,
        getIconFromEntityType,
        getTypeBadge,
        shouldDisplayDiff,
    } = useBrandHistoryVm();

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Section title="brandHistory.tableCard.title" subtitle="brandHistory.tableCard.subtitle">
                <Table
                    columns={headers}
                    rows={rows}
                    emptyMessageId="brandHistory.emptyMessage"
                    searchPlaceholder="brandHistory.searchPlaceholder"
                    hasPagination
                    isFilterable
                    isSortable
                    hasUrlNavigation
                    fetchData={fetchData}
                    isLoading={isLoading}
                    onRowClick={handleRowClick}
                    fetchDataTotalCount={fetchDataTotalCount}
                />

                <Modal isOpen={isModalOpen} onHide={closeModal} width={700} title="brandHistory.modal.title">
                    {!auditDetail ? (
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    ) : (
                        <div>
                            <AuditTypeContainer>
                                <TypeContainer>
                                    <SVGIcon icon={getIconFromEntityType(auditDetail.auditType)} size={24} />
                                    <Text text={auditDetail.auditType} weight="medium" />
                                </TypeContainer>

                                <Text text={auditDetail.auditName} noTranslation></Text>

                                <Badge {...getTypeBadge(auditDetail.action)} />
                            </AuditTypeContainer>
                            <Container>
                                <Text weight="bold" type="large" text="brandHistory.modal.informations" />

                                <ContentRow>
                                    <span>{computeText(intl, 'brandHistory.modal.user')}</span>
                                    <a href={`mailto:${auditDetail.user.mail}`}>{auditDetail.user.mail}</a> (
                                    {auditDetail.user.id})
                                </ContentRow>

                                <ContentRow>
                                    <span>{computeText(intl, 'brandHistory.modal.date')}</span>
                                    {auditDetail.auditDate}
                                </ContentRow>
                            </Container>
                            <Container>
                                {shouldDisplayDiff && (
                                    <>
                                        <Text weight="bold" type="large" text="brandHistory.modal.actions" />

                                        {auditDetail.diff.map(({ fieldName, oldValue, newValue }: AuditDiff, index) => (
                                            <ContentRow key={index}>
                                                <span>
                                                    {fieldName} : {oldValue} → {newValue}
                                                </span>
                                            </ContentRow>
                                        ))}
                                    </>
                                )}
                                {!shouldDisplayDiff && <Title text="brandHistory.modal.empty" type="small" />}
                            </Container>
                        </div>
                    )}
                </Modal>
            </Section>
        </RouterNavigationTabs>
    );
};

export default BrandHistory;
