import React, { ReactElement } from 'react';
import { SVGIcon } from '@/components/atoms/Icon/SVGIcon';
import { ListContainer, ListItemContainer, ListSeparator, ListTextWithIcon } from './List.style';
import type { IconAndText } from './IconAndText';

export type ListProps = {
    listItems: (string | IconAndText)[];
    isTruncated?: boolean;
};

type ListComponent = {
    stringList: string;
    components: ReactElement | null;
};

const joinListItems = (listItems: (string | IconAndText)[]): ListComponent => {
    const textSeparator = ', ';
    const elementSeparator = ' - ';
    const defaultListComponent: ListComponent = {
        stringList: '',
        components: null,
    };
    return listItems.reduce((acc: ListComponent, item, currentIndex): ListComponent => {
        const isBeginning = currentIndex === 0;
        const textSeparatorToUse = isBeginning ? '' : textSeparator;
        const elementSeparatorToUse = isBeginning ? '' : elementSeparator;
        const { stringList, components } = acc;
        if (typeof item === 'string') {
            const joinedItemList = `${stringList}${textSeparatorToUse}${item}`;
            return {
                stringList: joinedItemList,
                components: <>{joinedItemList}</>,
            };
        }
        const { icon, text: textElement } = item;
        const text = Array.isArray(textElement) ? textElement.join(textSeparator) : textElement;
        return {
            stringList: `${stringList}${textSeparatorToUse}${text}`,
            components: (
                <ListItemContainer>
                    {components}
                    <ListSeparator>{elementSeparatorToUse}</ListSeparator>
                    <SVGIcon icon={icon} size={24} />
                    <ListTextWithIcon text={text} weight="medium" />
                </ListItemContainer>
            ),
        };
    }, defaultListComponent);
};

export const List: React.FunctionComponent<React.PropsWithChildren<ListProps>> = ({ listItems, isTruncated }) => {
    const { stringList, components } = joinListItems(listItems);

    return (
        <ListContainer
            isTruncated={isTruncated}
            title={isTruncated ? stringList : undefined}
            containsComplexElement={!!listItems.length && typeof listItems[0] !== 'string'}
        >
            {components}
        </ListContainer>
    );
};
