import styled from '@emotion/styled';
import { Title } from '@/components/atoms/Title';

export const Container = styled.div`
    flex: 1;
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    margin: 20px;
    padding: 20px;
`;

export const TitleContainer = styled.div`
    display: flex;

    & > h2:last-child {
        margin-left: 5px;
    }
`;

export const CustomTitle = styled(Title)`
    margin-bottom: 10px;
`;

export const MargedTitle = styled(Title)`
    margin-bottom: 20px;
`;

export const FlexContainer = styled.div`
    display: flex;
    margin-bottom: 80px;
`;

export const FormContainer = styled.div`
    padding: 20px;
    flex: 1;
    background: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: ${({ theme }) => theme.radius.main};
`;

export const Flex = styled.div`
    display: flex;

    & > * {
        flex: 1;
    }
`;

export const FormChildrens = styled.div`
    & > *:not(:last-of-type) {
        margin-bottom: 20px;
    }
`;
