import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import React from 'react';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';

export const getColumns = (intl: IntlShape, readOnly?: boolean): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'auto.billing.referential.serviceCodes.perceivedAmountCodes.code.label'),
        accessor: 'code',
        width: '20%',
    },
    {
        Header: computeText(intl, 'auto.billing.referential.serviceCodes.perceivedAmountCodes.vat.label'),
        accessor: 'vat',
        width: '20%',
    },
    ...(!readOnly
        ? [
              {
                  Header: '',
                  accessor: 'removeAction',
                  width: '1%',
                  disableSortBy: true,
              },
          ]
        : []),
];
export type PerceivedAmountCodeRow = {
    code: {
        value?: string;
        error?: string;
    };
    vat: {
        value?: string;
        error?: string;
    };
};

export const getRows = (
    perceivedAmountCodesRows: PerceivedAmountCodeRow[],
    intl: IntlShape,
    onDelete: (perceivedAmountCodeRaw: PerceivedAmountCodeRow) => void,
    onChange: (index: number, perceivedAmountCodeRaw: PerceivedAmountCodeRow) => void,
    readOnly: boolean,
): TableRow[] =>
    perceivedAmountCodesRows.map((row, index) => ({
        code: {
            type: 'input',
            value: {
                value: row.code.value,
                error: row.code.error,
                type: 'string',
                disabled: readOnly,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;

                    return onChange(index, {
                        ...row,
                        code: { value },
                    });
                },
            },
        },

        vat: {
            type: 'input',
            value: {
                value: (Number(row.vat.value) / 100).toString(),
                error: row.vat.error,
                type: 'number',
                unitType: 'percentage',
                disabled: readOnly,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    return onChange(index, {
                        ...row,
                        vat: { value: (Number(value) * 100).toString() },
                    });
                },
            },
        },
        removeAction: {
            type: 'action',
            value: {
                children: computeText(intl, 'button.delete'),
                buttonType: 'outlinedPrimary',
                onClick: () => onDelete(row),
            },
        },
    }));
