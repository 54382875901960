import React, { useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@/components/atoms/Button';
import { ActionBar } from '@/components/atoms/ActionBar';

type SavingBarProps = {
    onSave: () => void;
    loading?: boolean;
    error?: string;
    changesCount?: number;
    displayChangesCount?: boolean;
    onCancel?: () => void;
    disabled?: boolean;
};

export const SavingBar: React.FunctionComponent<React.PropsWithChildren<SavingBarProps>> = ({
    onSave,
    loading,
    error,
    changesCount,
    displayChangesCount = true,
    onCancel,
    disabled = false,
}) => {
    const intl = useIntl();
    const information =
        changesCount && displayChangesCount
            ? intl.formatMessage({ id: 'savingBar.changesDone' }, { changesCount })
            : '';

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (!disabled && (event.metaKey || event.ctrlKey) && event.key === 'Enter') onSave();
        },
        [disabled, onSave],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <ActionBar information={information} error={error}>
            {!!changesCount && onCancel && (
                <Button data-testid="savingBar-cancel" buttonType="secondary" onClick={onCancel} isLoading={loading}>
                    <FormattedMessage id="button.cancel" />
                </Button>
            )}
            <Button
                data-testid="savingBar-save"
                buttonType="primary"
                onClick={onSave}
                isLoading={loading}
                disabled={disabled}
            >
                <FormattedMessage id="button.save" />
            </Button>
        </ActionBar>
    );
};
