import React, { useEffect, useState } from 'react';
import Wizard from '@/components/atoms/Wizard';
import { useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import RawModal from '@/components/atoms/RawModal';
import { computeText } from '@/locales/utils';
import { useDeleteCrossSellingMutation } from '@/services/innovorder/crossSelling/crossSelling.endpoint';
import { StepContainer } from './DeleteCrossSellingModal.style';

export type DeleteCrossSellingModalProps = {
    crossSellingId: number | null;
    crossSellingName: string | null;
    onHide: () => void;
};

export const DeleteCrossSellingModal: React.FunctionComponent<
    React.PropsWithChildren<DeleteCrossSellingModalProps>
> = ({ onHide, crossSellingId, crossSellingName = '' }) => {
    const intl = useIntl();
    const [deleteCrossSellingById, { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading }] =
        useDeleteCrossSellingMutation();
    const [name, setName] = useState(crossSellingName);

    useEffect(() => {
        setName(name);
    }, [name, setName]);

    useEffect(() => {
        if (isDeleteSuccess) {
            onHide();
        }
    }, [isDeleteSuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={480}
                steps={[
                    {
                        title: 'crossSelling.modal.delete.title',
                        onCancel: onHide,
                        component: (
                            <StepContainer>
                                <Text
                                    type="normal"
                                    weight="regular"
                                    text={computeText(intl, 'crossSelling.modal.delete.description', {
                                        crossSellingName: crossSellingName ?? '',
                                    })}
                                />
                            </StepContainer>
                        ),
                    },
                ]}
                isLoading={isDeleteLoading}
                onValidate={() => {
                    if (crossSellingId) {
                        deleteCrossSellingById({ crossSellingId });
                    }
                }}
            />
        </RawModal>
    );
};
