import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import { BillingHistory } from '@/services/innovorder/autoBilling/autoBilling.type';
import { formatDateWithLocal, formatTimestampWithLocal } from '@/utils/date';

export const getColumns = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'auto.billing.request.history.table.date'),
        accessor: 'date',
        width: '100px',
    },
    {
        Header: computeText(intl, 'auto.billing.request.history.table.period'),
        accessor: 'period',
        width: '90px',
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'auto.billing.request.history.table.user'),
        accessor: 'user',
        width: '130px',
        disableSortBy: true,
    },
    {
        Header: 'Action',
        accessor: 'previewAction',
        width: '1%',
        disableSortBy: true,
    },
    {
        Header: '',
        accessor: 'downloadAction',
        width: '1%',
        disableSortBy: true,
    },
];

export const getRows = (
    billingHistories: BillingHistory[],
    intl: IntlShape,
    onPreview: (entity: BillingHistory) => void,
    onDownload: (entity: BillingHistory) => void,
    proceededBillingHistory: BillingHistory | undefined,
): TableRow[] =>
    billingHistories.map((billingHistory) => ({
        date: {
            type: 'string',
            value: {
                text: formatTimestampWithLocal(billingHistory.date, intl.locale),
            },
        },
        period: {
            type: 'string',
            value: {
                text: `${formatDateWithLocal(
                    billingHistory.billingRequest.periodFrom,
                    intl.locale,
                )} - ${formatDateWithLocal(billingHistory.billingRequest.periodTo, intl.locale)}`,
            },
        },
        user: {
            type: 'string',
            value: { text: billingHistory.user },
        },
        previewAction: {
            type: 'action',
            value: {
                children: computeText(intl, 'auto.billing.request.history.table.previewButton'),
                buttonType: 'secondary',
                onClick: () => onPreview(billingHistory),
            },
        },
        downloadAction: {
            type: 'action',
            value: {
                children: computeText(intl, 'auto.billing.request.history.table.downloadButton'),
                buttonType: 'primary',
                isLoading: billingHistory === proceededBillingHistory,
                onClick: () => onDownload(billingHistory),
            },
        },
    }));
