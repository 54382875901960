import styled from '@emotion/styled';
import type { TicketLineType } from './OrderDetailsTicketLine';

export const Container = styled.div<{ type: TicketLineType }>`
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    ${({ theme }) => theme.font.text.bold};
    font-size: ${({ theme, type }) => (type === 'finalprice' ? theme.font.size.large : theme.font.size.medium)};
    color: ${({ theme, type }) => (type === 'misc' ? theme.color.black200 : theme.color.black)};
    font-weight: ${({ theme, type }) => (type === 'misc' ? theme.font.weight.medium : theme.font.weight.bold)};
`;

export const LineText = styled.div`
    flex: 1;
`;

export const LinePrice = styled.div<{ type: TicketLineType }>`
    color: ${({ theme, type }) => (type === 'item' || type === 'misc' ? theme.color.black200 : theme.color.black)};
    font-weight: ${({ theme, type }) =>
        type === 'item' || type === 'misc' ? theme.font.weight.medium : theme.font.weight.bold};
`;

export const StyledItemName = styled.span`
    color: ${({ theme }) => theme.color.bloodyMary};
`;
