import { Input } from '@/components/atoms/Form/Input';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StepWrapper, TitleWrapper } from '../CreateBrandForm.style';
import { Select } from '@/components/form/Select';
import { Currency } from '@/services/innovorder/brand/brand.type';
import { CreateBrandPayload } from '../../../../services/innovorder/brand/brand.type';
import { LOCALES } from './util';

import { useIntl } from 'react-intl';
import { Title } from '@/components/atoms/Title';
import { computeText } from '@/locales/utils';
export const currencyOptions = [
    {
        value: Currency.EUR,
        label: Currency.EUR,
    },
    {
        value: Currency.GPB,
        label: Currency.GPB,
    },
    {
        value: Currency.USD,
        label: Currency.USD,
    },
];
const mappedLocales = LOCALES.map((locale) => ({ value: locale, label: locale }));

const BrandNameStep = () => {
    const { control } = useFormContext<CreateBrandPayload>();
    const intl = useIntl();
    return (
        <StepWrapper>
            <TitleWrapper>
                <Title text={computeText(intl, 'brands.form.brandInfo')} />
            </TitleWrapper>

            <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <Input
                            name="name"
                            labelId="brands.form.brandName"
                            onChange={onChange}
                            value={value}
                            error={error?.message}
                        />
                    );
                }}
            />
            <Controller
                name="locale"
                control={control}
                render={({ field: { onChange, value } }) => {
                    return <Select name="locale" labelId="brands.form.region" options={mappedLocales} />;
                }}
            />
            <Controller
                name="currency"
                control={control}
                render={({ field: { onChange, value } }) => {
                    return <Select labelId="brands.form.currency" name="currency" options={currencyOptions} />;
                }}
            />
        </StepWrapper>
    );
};

export default BrandNameStep;
