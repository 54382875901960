import styled from '@emotion/styled';
import { EmptyState } from '@/components/atoms/EmptyState';
import { Button } from '@/components/atoms/Button';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    line-height: 20px;
    height: fit-content;
`;

export const ActiveLocationsContainer = styled.div`
    margin-top: ${({ theme }) => theme.spacing[1]};
`;

export const AddButton = styled(Button)`
    width: 92px;
    height: 40px;
    align-self: flex-end;
`;

export const StyledEmptyState = styled(EmptyState)`
    padding: ${({ theme }) => theme.spacing[5]};
    border: 2px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.size.normal};
    color: ${({ theme }) => theme.color.black200};
`;
