import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';

export const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing[4]} 0;
`;
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`;

export const FormText = styled(Text)`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;
