import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeCurrency, computeText } from '@/locales/utils';
import { CostSlice } from '@/services/innovorder/autoBilling/autoBilling.type';

export const getCostSliceTableCols = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'auto.billing.referential.accountingGroups.costCompute.slice.table.range'),
        accessor: 'range',
        width: '110px',
    },
    {
        Header: computeText(intl, 'auto.billing.referential.accountingGroups.costCompute.slice.table.mode'),
        accessor: 'mode',
        width: '220px',
    },
    {
        Header: computeText(intl, 'auto.billing.referential.accountingGroups.costCompute.slice.table.value'),
        accessor: 'value',
        width: '100px',
    },
    {
        Header: '',
        accessor: 'editAction',
        width: '1%',
        disableSortBy: true,
    },
    {
        Header: '',
        accessor: 'removeAction',
        width: '1%',
        disableSortBy: true,
    },
];

export const getCostSliceTableRows = (
    costSlices: CostSlice[],
    intl: IntlShape,
    onDelete: (slice: CostSlice) => void,
    onEdit: (slice: CostSlice) => void,
): TableRow[] =>
    costSlices.map((slice) => ({
        range: {
            type: 'string',
            role: 'range',
            value: {
                text: `${slice.from} ${computeText(
                    intl,
                    'auto.billing.referential.accountingGroups.costCompute.slice.table.to',
                )} ${slice.to}`,
            },
        },
        mode: {
            type: 'string',
            value: {
                text: computeText(intl, `auto.billing.referential.accountingCostCompute.costSlicesMode.${slice.mode}`),
            },
        },
        value: {
            type: 'string',
            value: { text: computeCurrency({ intl, amount: slice.value }) },
        },
        editAction: {
            type: 'action',
            value: {
                children: computeText(intl, 'button.edit'),
                buttonType: 'secondary',
                onClick: () => onEdit(slice),
            },
        },
        removeAction: {
            type: 'action',
            value: {
                children: computeText(intl, 'button.delete'),
                buttonType: 'outlinedPrimary',
                onClick: () => onDelete(slice),
            },
        },
    }));
