import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[3]};
`;

export const Line = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[10]};
    max-width: 550px;
`;

export const LineElement = styled.div<{ paddingBottom?: boolean }>`
    display: flex;
    align-items: end;
    gap: ${({ theme }) => theme.spacing[2]};
    padding-bottom: ${({ paddingBottom, theme }) => (paddingBottom ? theme.spacing[1] : '0')};
    width: 100%;
`;

export const LineElementContent = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[1]};
    width: ${({ fullWidth }) => (fullWidth ? `100%` : 'auto')};
`;

export const StyledText = styled(Text)`
    line-height: 40px;
    height: 40px;
`;

export const DropDownButton = styled.div`
    width: calc(100% + ${({ theme }) => theme.spacing[4]});
    margin: -${({ theme }) => theme.spacing[2]};
    height: 40px;
    padding: ${({ theme }) => theme.spacing[3]};
    border-radius: ${({ theme }) => theme.radius.main};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: ${({ theme }) => theme.font.size.normal};
`;
export const DropDownButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
