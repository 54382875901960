import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { Select } from '@/components/form/Select';
import { hasLength, isEmail, isInteger } from '@/utils/form/validate';
import { EditConfigurationFormValues } from '../CreateLegalEntityModal/CreateLegalEntityModal.types';
import {
    ModalConfigurationContainer,
    ModalInputContainer,
    ModalInputFullContainer,
    ModalInputTitle,
    ModalTextInput,
    ModalTitleContainer,
} from './AddLegalEntityStep.style';

type EditChannelStepProps = {
    onNext?: () => void;
    isLoading?: boolean;
};

const organizationTypeOptions = (intl: IntlShape) => [
    { label: computeText(intl, 'legalEntity.organizationType.1'), value: 1 },
    { label: computeText(intl, 'legalEntity.organizationType.2'), value: 2 },
    { label: computeText(intl, 'legalEntity.organizationType.3'), value: 3 },
    { label: computeText(intl, 'legalEntity.organizationType.4'), value: 4 },
    { label: computeText(intl, 'legalEntity.organizationType.6'), value: 6 },
];

const industryCodeOptions = (intl: IntlShape) => [
    { label: computeText(intl, 'legalEntity.industryCode.specialFoodServices'), value: '7223' },
    { label: computeText(intl, 'legalEntity.industryCode.fullServiceRestaurants'), value: '722511' },
    { label: computeText(intl, 'legalEntity.industryCode.limitedServiceRestaurants'), value: '722513' },
    { label: computeText(intl, 'legalEntity.industryCode.cafeteriasGrillBuffets'), value: '722514' },
    { label: computeText(intl, 'legalEntity.industryCode.snacksNonalcoholicBeverages'), value: '722515' },
    { label: computeText(intl, 'legalEntity.industryCode.bakeries'), value: '311811' },
];

export const AddLegalEntityStep: React.FunctionComponent<React.PropsWithChildren<EditChannelStepProps>> = ({
    isLoading = false,
}) => {
    const intl = useIntl();
    const { control } = useFormContext<EditConfigurationFormValues>();

    return (
        <ModalConfigurationContainer>
            <Controller
                control={control}
                name="legalName"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => !!value?.length,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.legalName"
                                tooltip={computeText(intl, 'legalEntity.wizard.step1.legalName.tooltip')}
                            />
                        </ModalTitleContainer>
                        <ModalTextInput
                            type="string"
                            placeholder={computeText(intl, 'legalEntity.wizard.step1.legalName.placeholder')}
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                            required={true}
                        />
                    </ModalInputFullContainer>
                )}
            />
            <Controller
                control={control}
                name="organizationType"
                render={() => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.organizationType"
                            />
                        </ModalTitleContainer>
                        <Select
                            name="organizationType"
                            options={organizationTypeOptions(intl)}
                            showValidation={false}
                        />
                    </ModalInputFullContainer>
                )}
            />
            <Controller
                control={control}
                name="siret"
                rules={{
                    required: computeText(intl, 'field.required'),
                    // we use ?? and not && because hasLength + isInteger returns undefined if value is OK, and a string if not
                    validate: (value) => hasLength(14)(intl)(value ?? '') ?? isInteger(intl)(value ?? ''),
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.siret"
                                tooltip={computeText(intl, 'legalEntity.wizard.step1.siret.tooltip')}
                            />
                        </ModalTitleContainer>
                        <ModalTextInput
                            type="string"
                            placeholder={computeText(intl, 'legalEntity.wizard.step1.siret.placeholder')}
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                            required={true}
                        />
                    </ModalInputFullContainer>
                )}
            />
            <Controller
                control={control}
                name="addressStreet"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => !!value?.length,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.addressStreet"
                            />
                        </ModalTitleContainer>
                        <ModalTextInput
                            type="string"
                            placeholder={computeText(intl, 'legalEntity.wizard.step1.addressStreet.placeholder')}
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                            required={true}
                        />
                    </ModalInputContainer>
                )}
            />
            <Controller
                control={control}
                name="addressStreet2"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.addressStreet2"
                            />
                        </ModalTitleContainer>
                        <ModalTextInput
                            type="string"
                            placeholder={computeText(intl, 'legalEntity.wizard.step1.addressStreet2.placeholder')}
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                        />
                    </ModalInputContainer>
                )}
            />
            <Controller
                control={control}
                name="addressZipcode"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => hasLength(5)(intl)(value ?? '') ?? isInteger(intl)(value ?? ''),
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.addressZipcode"
                            />
                        </ModalTitleContainer>
                        <ModalTextInput
                            type="string"
                            placeholder={computeText(intl, 'legalEntity.wizard.step1.addressZipcode.placeholder')}
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                            required={true}
                        />
                    </ModalInputContainer>
                )}
            />
            <Controller
                control={control}
                name="addressCity"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => !!value?.length,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle type="small" weight="medium" text="legalEntity.wizard.step1.addressCity" />
                        </ModalTitleContainer>
                        <ModalTextInput
                            type="string"
                            placeholder={computeText(intl, 'legalEntity.wizard.step1.addressCity.placeholder')}
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                            required={true}
                        />
                    </ModalInputContainer>
                )}
            />
            <Controller
                control={control}
                name="industryCode"
                render={() => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.industryCode"
                            />
                        </ModalTitleContainer>
                        <Select name="industryCode" options={industryCodeOptions(intl)} showValidation={false} />
                    </ModalInputFullContainer>
                )}
            />
            <Controller
                control={control}
                name="contactEmail"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => isEmail(intl)(value ?? ''),
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.contactEmail"
                            />
                        </ModalTitleContainer>
                        <ModalTextInput
                            type="email"
                            placeholder={computeText(intl, 'legalEntity.wizard.step1.contactEmail.placeholder')}
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                            required={true}
                        />
                    </ModalInputFullContainer>
                )}
            />
            <Controller
                control={control}
                name="iban"
                rules={{
                    required: computeText(intl, 'field.required'),
                    validate: (value = '') => hasLength(27)(intl)(value ?? ''),
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ModalInputFullContainer>
                        <ModalTitleContainer>
                            <ModalInputTitle
                                type="small"
                                weight="medium"
                                text="legalEntity.wizard.step1.iban"
                                tooltip={computeText(intl, 'legalEntity.wizard.step1.iban.tooltip')}
                            />
                        </ModalTitleContainer>
                        <ModalTextInput
                            type="string"
                            placeholder={computeText(intl, 'legalEntity.wizard.step1.iban.placeholder')}
                            error={error?.message}
                            onChange={onChange}
                            value={value?.toString()}
                            disabled={isLoading}
                            required={true}
                        />
                    </ModalInputFullContainer>
                )}
            />
        </ModalConfigurationContainer>
    );
};
