import { ReportFilterValues } from '@/pages/Reporting/common/types';
import { useEffect, useState } from 'react';
import { useCreateReportExportRequestMutation } from '@/services/innovorder/report/report.endpoints';

type HandleReportExportProps = {
    exportUrl: string;
    params: Record<string, string | number | ReportFilterValues>;
};

interface ExportHandlers {
    isExportResultOpen: boolean;
    closeExportResult: () => void;
    exportReport: () => void;
    isProcessingExport: boolean;
    isExportRequestCreated: boolean;
}

export const useHandleReportExport = ({ exportUrl, params }: HandleReportExportProps): ExportHandlers => {
    const [isExportResultOpen, setIsExportResultOpen] = useState<boolean>(false);
    const [
        createReportExportRequest,
        { isLoading: isProcessingExport, isSuccess: isExportRequestCreated, isError: isExportRequestErrored },
    ] = useCreateReportExportRequestMutation();

    const exportReport = () => {
        createReportExportRequest({ exportUrl, params });
    };

    const openExportResult = () => {
        setIsExportResultOpen(true);
    };

    const closeExportResult = () => {
        setIsExportResultOpen(false);
    };

    useEffect(() => {
        if (isExportRequestCreated || isExportRequestErrored) {
            openExportResult();
        }
    }, [isExportRequestCreated, isExportRequestErrored]);

    return {
        isExportResultOpen,
        closeExportResult,
        exportReport,
        isProcessingExport,
        isExportRequestCreated,
    };
};
