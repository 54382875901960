import styled from '@emotion/styled';

export const EmptyData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3rem;
`;

export const EmptyMessage = styled.div`
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
`;
