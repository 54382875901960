import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    margin: ${({ theme }) => theme.spacing[4]};
`;

export const Header = styled.div<{ image: string }>`
    height: 210px;
    width: 100%;
    background: ${({ image }) => `url(${image})`} no-repeat;
    background-size: cover;
    padding: ${({ theme }) => theme.spacing[4]};

    h2 {
        margin-bottom: 10px;
    }
`;

export const HeaderLeft = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Subtitle = styled.div`
    max-width: 500px;
    color: ${({ theme }) => theme.color.black200};
    font-size: ${({ theme }) => theme.font.size.small};
`;

export const Content = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing[4]};
    background-color: ${({ theme }) => theme.color.white100};
    flex-grow: 1;
`;
