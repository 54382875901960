import { IntlShape } from 'react-intl';
import { Theme } from '@emotion/react';
import { computeText } from '@/locales/utils';
import { Option } from '@/components/atoms/Select';
import { CustomStyle } from '@/hooks/react-select/useReactSelectCustomStyles';

export const getMultiSelectCustomStyle = (theme: Theme): CustomStyle => ({
    multiValue: {
        height: '30px',
        backgroundColor: theme.color.slate300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.color.white100,
        borderRadius: theme.radius.main,
        padding: `0 ${theme.spacing[1]}`,
    },
    multiValueLabel: {
        color: theme.color.white100,
    },
});

export const getGroupingByOptions = (intl: IntlShape): Option<string>[] => [
    {
        label: computeText(intl, 'auto.billing.referential.billingEntities.form.groupingBy.options.BILLING_ENTITY'),
        value: 'BILLING_ENTITY',
    },
    {
        label: computeText(intl, 'auto.billing.referential.billingEntities.form.groupingBy.options.GROUP'),
        value: 'GROUP',
    },
    {
        label: computeText(intl, 'auto.billing.referential.billingEntities.form.groupingBy.options.PRICING_RULE'),
        value: 'PRICING_RULE',
    },
    {
        label: computeText(
            intl,
            'auto.billing.referential.billingEntities.form.groupingBy.options.ACCOUNT_PAYMENT_TYPE',
        ),
        value: 'ACCOUNT_PAYMENT_TYPE',
    },
];
