import { css } from '@emotion/react';

import Loader from '@/components/atoms/Loader';
import styled from '@emotion/styled';
import { EmptyState } from '../EmptyState';

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    text-align: left;
    border-radius: ${({ theme }) => theme.radius.main};
    border: 1px solid ${({ theme }) => theme.color.grey300};
    background-color: ${({ theme }): string => theme.color.white100};
    color: ${({ theme }): string => theme.color.black200};
    font-family: ${({ theme }): string => theme.font.family};
    font-size: ${({ theme }): string => theme.font.size.normal};

    table {
        width: 100%;
        border-spacing: 0;

        th {
            color: ${({ theme }): string => theme.color.black};
            font-size: ${({ theme }): string => theme.font.size.small};
            height: 50px;
        }

        th,
        td {
            margin: 0;
            border-bottom: 1px solid ${({ theme }): string => theme.color.grey300};
            padding: 0 ${({ theme }) => theme.spacing[4]};
        }

        td {
            height: 60px;
        }

        tr:last-child {
            td {
                border: none;
            }
        }

        tbody > tr:hover {
            background-color: ${({ theme }): string => theme.color.white200};
        }

        .clickable:hover {
            cursor: pointer;
        }
    }
`;

export const TableWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: initial;
`;

const noDataBlock = css`
    min-height: 260px;
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
`;

export const TableLoader = styled(Loader)`
    ${noDataBlock}
`;

export const TableEmptyState = styled(EmptyState)`
    ${noDataBlock}
`;

export const TableImg = styled.img`
    height: 100%;
    width: 60px;
    object-fit: cover;
    display: block;
`;
