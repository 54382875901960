import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { stringifyError } from '@/utils/errors';
import GrantForm from '@/pages/Brand/Grant/GrantForm';
import { Routes } from '@/core/router/routes.types';
import { computeGrantForQuery, formatRestaurantsForQuery } from '@/pages/Brand/Grant/GrantForm/GrantRestaurants/utils';
import { usePostGrantMutation } from '@/services/innovorder/grant/grant.endpoints';
import { getUserToken } from '@/redux/user';
import { getGrant, getGrantRestaurants, updateGrant, updateGrantRestaurants } from '@/redux/grantAndEntranceFee';
import { GrantStrategy } from '@/services/innovorder/grant/grant.type';
import { getUseShift } from '@/redux/launchDarkly';
import { useGetBrandGrantsQuery } from '@/services/innovorder/brand/brand.endpoints';

interface ParamTypes {
    brandId: string;
    grantId: string;
}

const CreateGrant: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { brandId } = useParams<ParamTypes>();
    const history = useHistory();
    const hasMounted = useRef(false);

    const dispatch = useDispatch();
    const token = useSelector(getUserToken);
    const grant = useSelector(getGrant);
    const grantRestaurants = useSelector(getGrantRestaurants);
    const useShift = useSelector(getUseShift);

    const { data: grantsData } = useGetBrandGrantsQuery(
        {
            brandId: parseInt(brandId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const [postGrant, { isSuccess, error, data, isLoading }] = usePostGrantMutation();

    if (isSuccess && data?.grantId) {
        history.push(generatePath(Routes.Grant, { brandId, grantId: data.grantId }));
    }

    useEffect(() => {
        if (useShift && !hasMounted.current) {
            dispatch(
                updateGrant({
                    brandId: parseInt(brandId, 10),
                    strategy: GrantStrategy.MinimumAmountAndDailyReached,
                    type: 'ABSOLUTE',
                    amount: 0,
                    ceiling: null,
                    code: '',
                    dailyLimit: 0,
                    labelTicket: '',
                    tag: null,
                    threshold: null,
                    restaurants: [],
                }),
            );

            dispatch(updateGrantRestaurants([]));
        }
    }, [brandId, useShift, dispatch]);

    const post = () => {
        postGrant({
            ...computeGrantForQuery(grant),
            brandId: parseInt(brandId, 10),
            token,
            restaurants: formatRestaurantsForQuery(grantRestaurants, undefined, grant, useShift),
        });
    };

    return (
        <GrantForm error={stringifyError(error)} onSubmit={post} loading={isLoading} grants={grantsData} isCreateMode />
    );
};

export default CreateGrant;
