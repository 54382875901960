import React from 'react';

import { ReadingCondition } from '@/services/innovorder/badgeReader/badgeReader.type';
import { Text } from '@/components/atoms/Text';
import { Section } from './ReadingConditions.style';

type ConditionProps = {
    index: number;
} & ReadingCondition;

const Condition: React.FunctionComponent<ConditionProps> = ({ index, ...readingCondition }) => {
    const values = {
        index,
        ...readingCondition,
    };
    return (
        <>
            <Text text="badgeReader.readingCondition.number" values={values} weight="bold" />
            <Section>
                <Text text="badgeReader.readingCondition.stringLength" values={values} />
                <Text
                    text={`badgeReader.readingCondition.convertBefore.${readingCondition.convertBefore}`}
                    values={values}
                />
                <Text text="badgeReader.readingCondition.substrPositionStart" values={values} />
                <Text text="badgeReader.readingCondition.substrLength" values={values} />
                <Text
                    text={`badgeReader.readingCondition.convertAfter.${readingCondition.convertAfter}`}
                    values={values}
                />
            </Section>
        </>
    );
};

type ReadingConditionsProps = {
    data: ReadingCondition[];
};

export const ReadingConditions: React.FunctionComponent<ReadingConditionsProps> = ({ data }) => {
    return (
        <>
            {data.map((readingCondition, index) => (
                <Condition key={`reading-condition-${index}`} index={index + 1} {...readingCondition} />
            ))}
        </>
    );
};
