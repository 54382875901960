import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindModuleStripeByIdQuery } from '@/services/innovorder/module_stripes/module_stripes.endpoints';
import { ModuleStripe } from '@/services/innovorder/module_stripes/module_stripes.type';
import { getBrandModuleStripeId } from '@/redux/brand';
import { getUserToken } from '@/redux/user';

export type StripeIntegrationViewModel = {
    moduleStripe?: ModuleStripe;
};

export function useStripeIntegrationsVM(): StripeIntegrationViewModel {
    const token = useSelector(getUserToken);
    const brandModuleStripeId = useSelector(getBrandModuleStripeId);

    const { data: moduleStripe } = useFindModuleStripeByIdQuery(
        brandModuleStripeId ? { moduleStripeId: brandModuleStripeId, token } : skipToken,
    );

    return {
        moduleStripe,
    };
}
