import React from 'react';

import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import { Container, Link, Subtitle } from './Empty.style';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { Button } from '@/components/atoms/Button';

type EmptyProps = {
    onCreate: () => void;
    onHelp: () => void;
};

const Empty: React.FunctionComponent<React.PropsWithChildren<EmptyProps>> = ({ onHelp, onCreate }) => {
    const intl = useIntl();

    return (
        <Container>
            <SVGIcon icon={SVGIcons.CARD} size={200} />
            <Title text="badgeReader.empty" />

            <Subtitle>
                <Text
                    noTranslation={true}
                    text={
                        <>
                            {computeText(intl, 'badgeReader.notConfigured')}
                            <Link onClick={onHelp}>
                                <FormattedMessage id="badgeReader.help" />
                            </Link>
                        </>
                    }
                />
            </Subtitle>
            <Button onClick={onCreate}>
                <FormattedMessage id="badgeReader.create" />
            </Button>
        </Container>
    );
};

export default Empty;
