import { Column } from 'react-table';
import { TableRow } from 'components/atoms/Table/Table.type';
import { DateRange } from '@/pages/Reporting/Report/ReportTable/types';
import { ReportFilterFormValues, ReportFilterValues } from '@/pages/Reporting/common/types';
import { getDateComponents } from '@/utils/date';

const COLUMNS_SEPARATOR = ';';
const END_OF_LINE_SEPARATOR = '\n';

function buildColumnAccessor(index: number) {
    return `column_num_${index}`;
}

function mapColumnNameToTableColumn(columnName: string, index: number, footer?: string) {
    return {
        Header: columnName,
        accessor: buildColumnAccessor(index),
        minWidth: 150,
        ...(footer && { Footer: footer }),
    };
}

function stringRowToTableRowMapper(dataStringRow: string, options?: { isBoldLine?: boolean }): TableRow {
    const { isBoldLine = false } = options || {};

    const dataRow = dataStringRow.split(COLUMNS_SEPARATOR);
    const emptyTableRow = {};

    return dataRow.reduce((tableRow, rowValue, index) => {
        const columnAccessor = buildColumnAccessor(index);

        const value = { text: rowValue, weight: isBoldLine ? 'bold' : undefined };

        return {
            ...tableRow,
            [columnAccessor]: { type: 'string', value },
        };
    }, emptyTableRow);
}

function mapStringRowToTableRow(dataStringRow: string): TableRow {
    return stringRowToTableRowMapper(dataStringRow);
}

const constructColumns = (allRows: string[]) => {
    if (allRows.length === 0) {
        return [];
    }
    const headerString = allRows[0];
    const headerColumns = headerString.split(COLUMNS_SEPARATOR);

    if (allRows.length > 1) {
        const totalStringRow = allRows[allRows.length - 1];
        const totalRowColumns = totalStringRow.split(COLUMNS_SEPARATOR);

        return headerColumns.map((headerColumn, index) =>
            mapColumnNameToTableColumn(headerColumn, index, totalRowColumns[index]),
        );
    }

    return headerColumns.map((headerColumn, index) => mapColumnNameToTableColumn(headerColumn, index));
};

export function formatTableData(data: string | undefined): {
    columns: Column<TableRow>[];
    rows: TableRow[];
} {
    let columns: Column<TableRow>[] = [];
    let rows: TableRow[] = [];
    if (data) {
        const allRows = data.split(END_OF_LINE_SEPARATOR).filter(Boolean);
        columns = constructColumns(allRows);
        const dataStringRows = allRows.slice(1, -1);
        rows = dataStringRows.map(mapStringRowToTableRow);
    }

    return { columns, rows };
}

export function getReportParams(
    { startDate, endDate }: DateRange,
    filters: ReportFilterFormValues,
): Record<string, string | ReportFilterValues> {
    return {
        ...filters,
        startDate: JSON.stringify(getDateComponents(startDate)),
        endDate: JSON.stringify(getDateComponents(endDate)),
    };
}
