import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Table } from '@/components/atoms/Table/Table';
import { CostSlice } from '@/services/innovorder/autoBilling/autoBilling.type';
import {
    getCostSliceTableCols,
    getCostSliceTableRows,
} from '../../../../AutoBillingRequest/AutoBillingRequestPreview/utils';

type CostSliceTableProps = {
    costSlices: CostSlice[];
    onDelete: (slice: CostSlice) => void;
    onEdit: (slice: CostSlice) => void;
};

const CostSliceTable: React.FunctionComponent<React.PropsWithChildren<CostSliceTableProps>> = ({
    costSlices,
    onDelete,
    onEdit,
}) => {
    const intl = useIntl();

    const costSliceTableCols = useMemo(() => getCostSliceTableCols(intl), [intl]);
    const costSliceTableRows = useMemo(
        () => getCostSliceTableRows(costSlices, intl, onDelete, onEdit),
        [costSlices, intl, onDelete, onEdit],
    );
    return (
        <>
            <Table
                emptyMessageId="auto.billing.referential.accountingGroups.costCompute.slice.table.emptyMessage"
                columns={costSliceTableCols}
                rows={costSliceTableRows}
            />
        </>
    );
};

export default CostSliceTable;
