import React, { useCallback, useEffect } from 'react';
import Wizard from '@/components/atoms/Wizard';
import { Input } from '@/components/atoms/Form/Input';
import { Controller, useForm } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { stringifyError } from '@/utils/errors';
import { Table } from '@/components/atoms/Table';
import RawModal from '@/components/atoms/RawModal';
import { theme } from '@/core/theme';
import { useGetBrandRestaurantsQuery } from '@/services/innovorder/brand/brand.endpoints';
import { Restaurant } from '@/services/innovorder/restaurant/restaurant.type';
import { usePostCopyMenuByIdMutation } from '@/services/innovorder/menu/menu.endpoints';
import { getUserToken } from '@/redux/user';
import { TableContainer } from './CopyMenuModal.style';
import { MenuModalInputContainer, MenuModalTextInputLabel } from '../MenuModal.style';

export type CopyMenuModalProps = {
    brandId: number;
    restaurantId?: number;
    menuId: number | null;
    menuName: string | null;
    onHide: () => void;
};

type RestaurantWithChecked = Restaurant & { isChecked: boolean };

export const CopyMenuModal: React.FunctionComponent<React.PropsWithChildren<CopyMenuModalProps>> = ({
    onHide,
    menuId,
    brandId,
    menuName = '',
}) => {
    const intl = useIntl();
    const token = useSelector(getUserToken);
    const [postCopyMenu, { isSuccess: isCopySuccess, isLoading: isCopyLoading, error: copyError }] =
        usePostCopyMenuByIdMutation();
    const { data = [], isLoading } = useGetBrandRestaurantsQuery(
        { brandId, token },
        { refetchOnMountOrArgChange: true },
    );
    const restaurantsWithChecked = data.map((d) => ({ ...d, isChecked: false }));
    const { control, getValues, setValue, setError } = useForm<{
        name: string | null;
        restaurants: RestaurantWithChecked[];
    }>({
        defaultValues: {
            name: null,
            restaurants: [],
        },
    });

    const onFormValidate = useCallback(() => {
        const { name, restaurants } = getValues();

        if (!(menuId && name)) {
            return setError('name', { message: computeText(intl, 'menu.modal.error.name') });
        }

        const restaurantList = restaurants.filter(({ isChecked }) => isChecked).map(({ restaurantId }) => restaurantId);
        return postCopyMenu({ menuId, restaurantList, name, token });
    }, [getValues, setError, postCopyMenu, intl, menuId, token]);

    const onRestaurantChecked = (
        checkedRestaurantId: number,
        restaurants: RestaurantWithChecked[],
        onChange: (values: RestaurantWithChecked[]) => void,
    ) => {
        const updatedRestaurants: RestaurantWithChecked[] = [...restaurants];
        const index = updatedRestaurants.findIndex(({ restaurantId: id }) => id === checkedRestaurantId);

        if (index > -1) {
            updatedRestaurants[index].isChecked = !updatedRestaurants[index].isChecked;
        }

        return onChange(updatedRestaurants);
    };

    useEffect(() => {
        setValue('restaurants', restaurantsWithChecked);
    }, [data, setValue, restaurantsWithChecked]);

    useEffect(() => {
        setValue('name', `${menuName} - copy` ?? '');
    }, [menuName, setValue]);

    useEffect(() => {
        if (isCopySuccess) {
            onHide();
        }
    }, [isCopySuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={720}
                isLoading={isCopyLoading}
                steps={[
                    {
                        title: 'menu.modal.copy.title',
                        onCancel: onHide,
                        component: (
                            <MenuModalInputContainer>
                                <MenuModalTextInputLabel type="small" weight="medium" text="menu.name" />
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Input
                                            data-testid="menu-modal-name-input"
                                            value={value ?? ''}
                                            isTouched={!!value && value.length > 0}
                                            onChange={onChange}
                                            error={error?.message}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="restaurants"
                                    render={({ field: { onChange, value: restaurants } }) => (
                                        <TableContainer>
                                            <Table
                                                hasPagination
                                                isSearchable
                                                emptyMessageId="table.noRowsAfterFilter"
                                                columns={[
                                                    {
                                                        Header: '',
                                                        accessor: 'checked',
                                                        width: '5%',
                                                        disableSortBy: true,
                                                    },
                                                    {
                                                        Header: computeText(intl, 'restaurant.name'),
                                                        accessor: 'name',
                                                        width: '95%',
                                                        disableSortBy: true,
                                                    },
                                                ]}
                                                isLoading={isLoading}
                                                onRowClick={(cell) => {
                                                    if (cell && cell.value) {
                                                        const { additionalProps } = cell.value as unknown as {
                                                            additionalProps: Record<string, number>;
                                                        };
                                                        onRestaurantChecked(
                                                            additionalProps.restaurantId,
                                                            restaurants,
                                                            onChange,
                                                        );
                                                    }
                                                }}
                                                rows={restaurants.map(
                                                    ({ restaurantId: currentRestaurantId, name, isChecked }) => ({
                                                        checked: {
                                                            type: 'radio',
                                                            value: {
                                                                value: isChecked,
                                                                checked: isChecked,
                                                                label: '',
                                                                color: theme.color.emerald,
                                                            },
                                                            additionalProps: {
                                                                restaurantId: currentRestaurantId,
                                                            },
                                                        },
                                                        name: {
                                                            restaurantId: currentRestaurantId,
                                                            type: 'string',
                                                            value: { text: name },
                                                            additionalProps: {
                                                                restaurantId: currentRestaurantId,
                                                            },
                                                        },
                                                    }),
                                                )}
                                            />
                                        </TableContainer>
                                    )}
                                />
                            </MenuModalInputContainer>
                        ),
                    },
                ]}
                errorMessage={stringifyError(copyError)}
                onValidate={onFormValidate}
            />
        </RawModal>
    );
};
