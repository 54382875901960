import { GuestGroupWithGuestsCount, RelatedGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { useEffect, useMemo, useState } from 'react';
import { getSheetOptions, GroupData } from './UploadModal/utils';
import { downloadFile } from '@/utils/file/utils';
import { XlsxFile } from '@/utils/xslx';
import { useIntl } from 'react-intl';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { Routes } from '@/core/router/routes.types';
import { getUserToken } from '@/redux/user';
import {
    useGetGuestGroupsQuery,
    useDeleteGuestGroupMutation,
    useGetRelatedGuestsCountMutation,
} from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams, generatePath } from 'react-router';
import { Column } from 'react-table';
import { getGroupsTableRowsVM, getGroupsTableColumnsVM } from './utils';

export const useGroupMassImport = ({ guestGroups: data }: { guestGroups: GuestGroupWithGuestsCount[] | undefined }) => {
    const intl = useIntl();
    const guestGroups = useMemo(() => data || [], [data]);
    const [isOpen, setIsOpen] = useState(false);
    const handleHide = () => setIsOpen(false);
    const handleUnHide = () => setIsOpen(true);

    const handleDownload = async () => {
        const groups = (guestGroups || []).filter((guestGroup) => guestGroup.depth === 0);
        const groupData = groups.reduce((acc, group) => {
            const section = guestGroups.find(
                (guestGroup) => guestGroup.parentGuestGroupId === group.guestGroupId && guestGroup.depth === 1,
            );
            const subsection = guestGroups.find(
                (guestGroup) => guestGroup.parentGuestGroupId === section?.guestGroupId && guestGroup.depth === 2,
            );
            const pricingRules = subsection
                ? subsection.pricingRules
                : section
                ? section.pricingRules
                : group.pricingRules;
            return [
                ...acc,
                {
                    group: group.name,
                    section: section?.name,
                    subsection: subsection?.name,
                    pricingRules: pricingRules?.map(({ code }) => code),
                },
            ];
        }, [] as GroupData[]);

        const sheetOptions = getSheetOptions({ intl });
        const sheets = {
            [sheetOptions.group.name]: [sheetOptions.group.headers, ...groupData.map(sheetOptions.group.mapper)],
        };

        const xslx = new XlsxFile();
        Object.keys(sheets).forEach((key) => {
            const sheetData = sheets[key].map((row) => row.map((cell) => cell ?? ''));
            xslx.addWorksheet(key, sheetData);
        });

        const data = xslx.toArrayBuffer();
        const filename = `export_${new Date().toISOString()}.xlsx`;
        downloadFile(filename, data, 'text/csv');
    };

    return {
        isOpen,
        handleHide,
        handleUnHide,
        handleDownload,
    };
};

export enum GuestGroupsByDepthKey {
    GROUPS = 'GROUPS',
    SECTIONS = 'SECTIONS',
    SUB_SECTIONS = 'SUB_SECTIONS',
}
export type GuestGroupsByDepth = Record<GuestGroupsByDepthKey, GuestGroupWithGuestsCount[] | undefined>;
export type GroupsTableRowsByDepth = Record<GuestGroupsByDepthKey, TableRow[]>;
export type GroupsTableColumnsByDepth = Record<GuestGroupsByDepthKey, Column<TableRow>[]>;

export const useGroupsViewModel = (): {
    brandId: string;
    groupIdToDelete: number | null;
    guestGroups: GuestGroupWithGuestsCount[] | undefined;
    guestGroupsByDepth: GuestGroupsByDepth;
    isLoading: boolean;
    isLoadingDelete: boolean;
    isLoadingCount: boolean;
    relatedGuestsData: RelatedGuestsCount | undefined;
    groupsTableRows: TableRow[];
    groupsTableRowsByDepth: GroupsTableRowsByDepth;
    groupsTableColumns: Column<TableRow>[];
    groupsTableColumnsByDepth: GroupsTableColumnsByDepth;
    handleOpenModal: (id: number) => void;
    handleCloseModal: () => void;
    handleDeleteGroup: () => void;
    handleAddGroup: () => void;
} => {
    const intl = useIntl();
    const history = useHistory();
    const { brandId } = useParams<{ brandId: string }>();
    const token = useSelector(getUserToken);
    const [groupIdToDelete, setGroupIdToDelete] = useState<number | null>(null);

    const { data: guestGroups, isLoading } = useGetGuestGroupsQuery(
        {
            brandId: parseInt(brandId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );
    const { 0: GROUPS, 1: SECTIONS, 2: SUB_SECTIONS } = groupBy(guestGroups, 'depth');
    const guestGroupsByDepth = { GROUPS, SECTIONS, SUB_SECTIONS };

    const [deleteGuestGroup, { isSuccess, isLoading: isLoadingDelete }] = useDeleteGuestGroupMutation();
    const [getRelatedGuestsCount, { data: relatedGuestsData, isLoading: isLoadingCount }] =
        useGetRelatedGuestsCountMutation();

    const handleOpenModal = (id: number) => {
        getRelatedGuestsCount({ token, groupId: id });
        setGroupIdToDelete(id);
    };

    const handleDeleteGroup = () => groupIdToDelete && deleteGuestGroup({ guestGroupId: groupIdToDelete, token });
    const handleCloseModal = () => setGroupIdToDelete(null);
    const handleUpdateClick = (groupId: number) => history.push(generatePath(Routes.EditGroup, { brandId, groupId }));
    const handleAddGroup = () => history.push(generatePath(Routes.CreateGroup, { brandId }));

    const groupsTableRows = getGroupsTableRowsVM(intl, handleOpenModal, handleUpdateClick, guestGroups);

    const groupsTableRowsByDepth = Object.fromEntries(
        ['GROUPS', 'SECTIONS', 'SUB_SECTIONS'].map((key) => [
            key,
            getGroupsTableRowsVM(
                intl,
                handleOpenModal,
                handleUpdateClick,
                guestGroupsByDepth[key as GuestGroupsByDepthKey],
            ),
        ]),
    ) as GroupsTableRowsByDepth;
    const groupsTableColumns = getGroupsTableColumnsVM(intl);
    const groupsTableColumnsByDepth = Object.fromEntries(
        ['GROUPS', 'SECTIONS', 'SUB_SECTIONS'].map((key) => [
            key,
            getGroupsTableColumnsVM(intl, key as GuestGroupsByDepthKey),
        ]),
    ) as GroupsTableColumnsByDepth;

    useEffect(() => {
        isSuccess && setGroupIdToDelete(null);
    }, [isSuccess]);

    return {
        brandId,
        groupIdToDelete,
        guestGroups,
        guestGroupsByDepth,
        groupsTableRowsByDepth,
        isLoading,
        isLoadingDelete,
        isLoadingCount,
        relatedGuestsData,
        groupsTableRows,
        groupsTableColumns,
        groupsTableColumnsByDepth,
        handleOpenModal,
        handleCloseModal,
        handleDeleteGroup,
        handleAddGroup,
    };
};
