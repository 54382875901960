import styled from '@emotion/styled';
import { SVGIcon } from '@/components/atoms/Icon/SVGIcon';
import { Text } from '@/components/atoms/Text';
import BaseClickableIcon from '@/components/atoms/ClickableIcon';

export const Container = styled.div`
    background: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    line-height: 20px;
    height: fit-content;
`;

export const PanelRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    align-items: flex-end;
    & > div {
        flex: 1;
    }
`;

export const Panel = styled.div`
    background: ${({ theme }) => theme.color.grey100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    &:last-child {
        margin-bottom: 0;
    }
`;

export const WebhookChannelConfig = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

export const Icon = styled(SVGIcon)<{ marginTop?: string }>`
    align-self: center;
    cursor: pointer;
    margin-block-start: ${({ marginTop }) => marginTop ?? ''};
`;

export const Title = styled(Text)`
    color: ${({ theme }) => theme.color.black};
    line-height: 20px;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

export const ClickableIcon = styled(BaseClickableIcon)`
    & > div {
        width: 75px;
        height: 80px;
        justify-content: space-between;
        padding-block: 10px 2px;
    }
`;
