import { useIntl } from 'react-intl';
import { Column } from 'react-table';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeCurrency, computeText } from '@/locales/utils';
import { theme } from '@/core/theme';
import { UseProductsTableDataParams } from '../CrossSellingFields.interface';

export const useProductsTableRows = ({ data }: UseProductsTableDataParams): TableRow[] => {
    const intl = useIntl();

    return (data ?? []).map((product) => ({
        image: {
            type: 'image',
            value: product.image?.fullUrl
                ? {
                      src: product.image?.fullUrl,
                      alt: product.name,
                  }
                : undefined,
        },
        name: {
            type: 'string',
            value: {
                text: product.name,
            },
        },
        price: {
            type: 'string',
            value: {
                text: computeCurrency({ intl, amount: product.price }),
            },
        },
        vat: {
            type: 'string',
            value: {
                text: `${product.vat / 100}%`,
            },
        },
        tags: {
            type: 'string',
            value: {
                text: product.tags.map(({ name }) => name).join(', '),
            },
        },
        sku: {
            type: 'string',
            value: {
                text: product.sku?.value,
            },
        },
        menuName: {
            type: 'string',
            value: {
                text: product.menu.name,
            },
        },
        menuState: {
            type: 'badge',
            value: product.menu.isActive
                ? {
                      backgroundColor: theme.color.emerald,
                      text: computeText(intl, 'crossSelling.products.menuState.enabled'),
                  }
                : {
                      backgroundColor: theme.color.bloodyMary,
                      text: computeText(intl, 'crossSelling.products.menuState.disabled'),
                  },
        },
        restaurantName: {
            type: 'string',
            value: {
                text: product.menu.restaurant.name,
            },
        },
        productId: {
            type: 'identifier',
            value: product.productId,
        },
    }));
};

export const useProductsColumns = (): readonly Column<TableRow>[] => {
    const intl = useIntl();

    return [
        {
            Header: computeText(intl, 'crossSelling.products.image'),
            accessor: 'image',
            width: 200,
        },
        {
            Header: computeText(intl, 'crossSelling.products.name'),
            accessor: 'name',
            width: 200,
        },
        {
            Header: computeText(intl, 'crossSelling.products.price'),
            accessor: 'price',
            width: 200,
        },
        {
            Header: computeText(intl, 'crossSelling.products.vat'),
            accessor: 'vat',
            width: 200,
        },
        {
            Header: computeText(intl, 'crossSelling.products.tags'),
            accessor: 'tags',
            width: 200,
        },
        {
            Header: computeText(intl, 'crossSelling.products.sku'),
            accessor: 'sku',
            width: 200,
        },
        {
            Header: computeText(intl, 'crossSelling.products.menuName'),
            accessor: 'menuName',
            width: 200,
        },
        {
            Header: computeText(intl, 'crossSelling.products.menuState'),
            accessor: 'menuState',
            width: 200,
        },
        {
            Header: computeText(intl, 'crossSelling.products.restaurantName'),
            accessor: 'restaurantName',
            width: 200,
        },
    ];
};
