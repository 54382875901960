import React from 'react';

import { Table } from '@/components/atoms/Table';
import { BadgeReader } from '@/services/innovorder/badgeReader/badgeReader.type';
import { Section } from '@/components/atoms/Section';
import { Button } from '@/components/atoms/Button';
import { FormattedMessage } from 'react-intl';
import { Container } from './BadgeReaderList.style';
import { useBadgeReadersListVM } from './BadgeReaderList.viewmodel';

type EmptyProps = {
    isLoading: boolean;
    data: BadgeReader[];
    onCreate: () => void;
    onEdit: (data: BadgeReader) => void;
    onDelete: (data: number) => void;
};

const BadgeReadersList: React.FunctionComponent<React.PropsWithChildren<EmptyProps>> = ({
    isLoading,
    data,
    onCreate,
    onEdit,
    onDelete,
}) => {
    const { rows, columns, handleRowClick } = useBadgeReadersListVM({ data, onEdit, onDelete });

    return (
        <Container>
            <Section
                title="badgeReader.table.title"
                actions={
                    <Button onClick={onCreate}>
                        <FormattedMessage id="badgeReader.create" />
                    </Button>
                }
            >
                <Table
                    rows={rows}
                    emptyMessageId="table.noRowsAfterFilter"
                    columns={columns}
                    isSearchable={true}
                    hasPagination
                    isLoading={isLoading}
                    onRowClick={handleRowClick}
                />
            </Section>
        </Container>
    );
};

export default BadgeReadersList;
