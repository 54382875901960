import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getCurrentReportMetadata } from '@/redux/report';
import Loader from '@/components/atoms/Loader';
import { useTheme } from '@emotion/react';
import { CenterLoader, Container } from './Report.style';
import ReportTable from './ReportTable';

const Report: React.FunctionComponent = () => {
    const theme = useTheme();
    const { reportName } = useParams<{ reportName: string }>();
    const metadata = useSelector(getCurrentReportMetadata(reportName));

    return (
        <Container>
            {metadata ? (
                <ReportTable metadata={metadata} />
            ) : (
                <CenterLoader>
                    <Loader color={theme.color.primary} size={40} withContainer />
                </CenterLoader>
            )}
        </Container>
    );
};

export default Report;
