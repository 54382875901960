import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';

import { stringifyError } from '@/utils/errors';
import {
    useCreateEdenredConfigMutation,
    useUpdateEdenredConfigMutation,
    useDeleteEdenredConfigMutation,
    useLazyGetEdenredConfigQuery,
} from '@/services/innovorder/edenred/configurations/configurations.endpoints';
import { getUserToken } from '@/redux/user';
import EdenredForm, { EdenredInputs } from './EdenredForm';
import { Loader } from './EdenredConfig.style';

const EdenredConfig: React.FunctionComponent = () => {
    const theme = useTheme();
    const token = useSelector(getUserToken);
    const { brandId, restaurantId } = useParams<{ brandId: string; restaurantId: string }>();
    const params = {
        brandId: Number(brandId),
        restaurantId: Number(restaurantId),
    };

    const [getConfig, { data, error: loadError, isFetching }] = useLazyGetEdenredConfigQuery();
    const [createConfig, { error: createError, isLoading: isSavingCreate }] = useCreateEdenredConfigMutation();
    const [updateConfig, { error: updateError, isLoading: isSavingUpdate }] = useUpdateEdenredConfigMutation();
    const [deleteConfig, { isLoading: isSavingDelete }] = useDeleteEdenredConfigMutation();

    useEffect(() => {
        getConfig({ token, restaurantId: Number(restaurantId) });
    }, [getConfig, token, restaurantId]);

    const onSubmit = async (_data: Partial<EdenredInputs>) => {
        if (data?.id) {
            updateConfig({
                token,
                configurationId: data.id,
                ...params,
                ..._data,
            });
        } else {
            await createConfig({
                token,
                ...params,
                ..._data,
            });
            getConfig(params);
        }
    };

    const onDelete = async () => {
        if (data) {
            await deleteConfig({
                token,
                configurationId: data.id,
            });
        }
        getConfig(params);
    };

    if (isFetching) {
        return <Loader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <EdenredForm
            config={data}
            onDelete={onDelete}
            onSubmit={onSubmit}
            error={stringifyError(loadError) || stringifyError(createError) || stringifyError(updateError)}
            loading={isSavingCreate || isSavingUpdate}
            loadingDelete={isSavingDelete}
        />
    );
};

export default EdenredConfig;
