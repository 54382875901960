import React from 'react';

import { TitleContainer, PanelTitle } from './IOPayPanel.style';

interface IOPayPanelProp {
    text: string;
}

const IOPayPanel: React.FunctionComponent<React.PropsWithChildren<IOPayPanelProp>> = ({ text }) => {
    return (
        <div>
            <TitleContainer>
                <PanelTitle text={text} />
            </TitleContainer>
        </div>
    );
};

export default IOPayPanel;
