export interface WebhookChannels {
    channelId: number;
    name: string;
    logo: any;
}

export interface WebhookHeader {
    name?: string;
    value?: string;
}

export interface WebhookConfig {
    webhookUrl: string;
    webhookHmacSecret?: string;
    channels: number[];
    additionalHeaders: WebhookHeader[];
}

export enum WebhookEventTypes {
    orderNotifications = 'orders.notification',
}
