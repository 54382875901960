import styled from '@emotion/styled';

export const ModalFooter = styled.div`
    margin: ${({ theme }) => theme.spacing[2]} 0;
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const TableContainer = styled.div`
    overflow: auto;
`;
