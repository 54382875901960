import { billingApi } from '../index';
import {
    AutoBillingInvoice,
    AutoBillingRequestPayload,
    BillingReferential,
    BillingReferentialRequestPayload,
    PageBillingHistory,
} from './autoBilling.type';
import { ApiRequest, ApiResponse } from '../request.types';

const autoBillingApi = billingApi.injectEndpoints({
    endpoints: (build) => ({
        postPreviewBillingRequest: build.mutation<
            string | AutoBillingInvoice[],
            ApiRequest<AutoBillingRequestPayload & { requestPdf?: boolean }>
        >({
            query: ({ brandId, periodFrom, periodTo, token, filters, requestPdf }) => ({
                url: `/billingRequestPreview/`,
                method: 'POST',
                body: {
                    brandId,
                    periodFrom,
                    periodTo,
                    filters,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    Accept: requestPdf === true ? 'application/octet-stream' : 'application/json',
                    ContentType: 'application/json',
                },
            }),
            invalidatesTags: ['AutoBilling'],
            transformResponse: (response: ApiResponse<string | AutoBillingInvoice[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        sendBillingRequestToElior: build.mutation<void, ApiRequest<AutoBillingRequestPayload>>({
            query: ({ brandId, periodFrom, periodTo, token, filters }) => ({
                url: `/sendBillingRequestToElior/`,
                method: 'POST',
                body: {
                    brandId,
                    periodFrom,
                    periodTo,
                    filters,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                },
            }),
            invalidatesTags: ['AutoBilling'],
            transformResponse: (response: ApiResponse<void>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getBillingReferential: build.mutation<BillingReferential, ApiRequest<{ brandId: number }>>({
            query: ({ brandId, token }) => ({
                url: `/billingReferential/${brandId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                },
            }),
            invalidatesTags: ['AutoBilling'],
            transformResponse: (response: ApiResponse<BillingReferential>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        saveBillingReferential: build.mutation<void, ApiRequest<BillingReferentialRequestPayload>>({
            query: ({ brandId, token, billingReferential }) => ({
                url: `/billingReferential/`,
                method: 'PUT',
                body: {
                    brandId,
                    billingReferential,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                },
                transformResponse: (response: ApiResponse<void>) => response.data,
            }),
            invalidatesTags: ['AutoBilling'],
            extraOptions: { maxRetries: 3 },
        }),
        getBillingHistories: build.mutation<
            PageBillingHistory,
            ApiRequest<{ brandId: number; page: number; size: number; sort: string }>
        >({
            query: ({ brandId, token, page = 0, size = 10, sort = 'date' }) => ({
                url: `/billingHistory/?brandId=${brandId}&page=${page}&size=${size}&sort=${sort}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                },
            }),
            invalidatesTags: ['AutoBilling'],
            transformResponse: (response: ApiResponse<PageBillingHistory>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    usePostPreviewBillingRequestMutation,
    useSendBillingRequestToEliorMutation,
    useGetBillingReferentialMutation,
    useSaveBillingReferentialMutation,
    useGetBillingHistoriesMutation,
} = autoBillingApi;
