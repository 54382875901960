import { DateRange } from '@/utils/daterange';
import dayjs from 'dayjs';

export const setCurrentWeek = (currentDay: Date, setDates: (dateRange: DateRange) => void) => {
    const startDate =
        dayjs(currentDay).day() === 0
            ? dayjs(currentDay).subtract(6, 'days').startOf('day').toDate()
            : dayjs(currentDay).startOf('week').add(1, 'day').toDate();
    setDates({
        startDate,
        endDate: currentDay,
    });
};

export const setCurrentMonth = (currentDay: Date, setDates: (dateRange: DateRange) => void) => {
    setDates({ startDate: dayjs(currentDay).startOf('month').toDate(), endDate: currentDay });
};

export const setLastMonth = (currentDay: Date, setDates: (dateRange: DateRange) => void) => {
    setDates({
        startDate: dayjs(currentDay).subtract(1, 'month').startOf('month').toDate(),
        endDate: dayjs(currentDay).subtract(1, 'month').endOf('month').toDate(),
    });
};
