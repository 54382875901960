import * as React from 'react';

export const useIsMounted = () => {
    const isMounted = React.useRef(true);

    React.useEffect(() => {
        isMounted.current = true; // avoid bug with strict mode https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
        return () => {
            isMounted.current = false;
        };
    }, []);

    return isMounted;
};
