import React from 'react';

import { OrderDetailsDeliveryAddress } from '@/services/innovorder/orders/order.type';
import {
    Container,
    FullwidthContainer,
    HalfwidthContainer,
    StyledLabel,
    StyledTitle,
} from './OrderDetailsDelivery.style';

type OrderDetailsDeliveryProps = Partial<OrderDetailsDeliveryAddress>;

const OrderDetailsDelivery: React.FunctionComponent<React.PropsWithChildren<OrderDetailsDeliveryProps>> = ({
    additionalInfo,
    apartmentNumber,
    digiCode,
    locality,
    phone,
    postalCode,
    route,
    streetNumber,
}) => {
    const address = [];
    if (streetNumber) address.push(streetNumber);
    if (route) address.push(route);
    if (postalCode) address.push(postalCode);
    if (locality) address.push(locality);

    return (
        <Container>
            {Boolean(address.length) && (
                <FullwidthContainer>
                    <StyledLabel text="orderDetail.delivery.address" />
                    <StyledTitle type="small" weight="medium" text={address.join(' ')} noTranslation />
                </FullwidthContainer>
            )}
            {apartmentNumber && (
                <HalfwidthContainer>
                    <StyledLabel text="orderDetail.delivery.apartmentNumber" />
                    <StyledTitle type="small" weight="medium" text={apartmentNumber} noTranslation />
                </HalfwidthContainer>
            )}
            {digiCode && (
                <HalfwidthContainer>
                    <StyledLabel text="orderDetail.delivery.digicode" />
                    <StyledTitle type="small" weight="medium" text={digiCode} noTranslation />
                </HalfwidthContainer>
            )}
            {phone && (
                <HalfwidthContainer>
                    <StyledLabel text="orderDetail.delivery.phoneNumber" />
                    <StyledTitle type="small" weight="medium" text={phone} noTranslation />
                </HalfwidthContainer>
            )}
            {additionalInfo && (
                <FullwidthContainer>
                    <StyledLabel text="orderDetail.delivery.additionalInfo" />
                    <StyledTitle type="small" weight="medium" text={additionalInfo} noTranslation />
                </FullwidthContainer>
            )}
        </Container>
    );
};

export default OrderDetailsDelivery;
