import React from 'react';
import Loader from '@/components/atoms/Loader';
import { Module, ModuleTpe } from '@/services/innovorder/modules/modules.type';
import { Brand } from '@/services/innovorder/brand/brand.type';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { IOPayConfig } from '@/services/innovorder/io-pay/configurations/configurations.type';
import { PaymentMethodKioskTab as Tab } from './Kiosk.tab';
import { usePaymentMethods } from '@/hooks/usePaymentMethods';

export type KioskProps = {
    channelId: ChannelId;
    restaurantId: string;
    brand?: Brand;
    ingenicoConfig?: Module;
    neptingConfig?: Module;
    stripeConfig?: Module;
    ioPayConfig?: IOPayConfig;
    ingenicoTpeList?: ModuleTpe[];
};

const PaymentMethodKioskTab: React.FunctionComponent<React.PropsWithChildren<KioskProps>> = ({
    channelId,
    restaurantId,
    brand,
    ingenicoConfig,
    neptingConfig,
    stripeConfig,
    ioPayConfig,
    ingenicoTpeList,
}) => {
    const {
        isLoading,
        paymentMethods,
        isLoadingConsumptionModes,
        consumptionModes,
        restaurantModules,
        isLoadingSave,
        onChange,
        onSave,
        resetPaymentMethods,
    } = usePaymentMethods(channelId, restaurantId, brand?.brandId);

    if (isLoading) {
        return <Loader withContainer />;
    }

    return (
        <Tab
            ingenicoConfig={ingenicoConfig}
            neptingConfig={neptingConfig}
            stripeConfig={stripeConfig}
            ioPayConfig={ioPayConfig}
            ingenicoTpeList={ingenicoTpeList}
            restaurantModules={restaurantModules}
            channelId={channelId}
            brand={brand}
            isLoadingConsumptionModes={isLoadingConsumptionModes}
            paymentMethods={paymentMethods}
            consumptionModes={consumptionModes}
            isLoadingSave={isLoadingSave}
            onChange={onChange}
            onSave={onSave}
            onCancel={resetPaymentMethods}
        />
    );
};

export { PaymentMethodKioskTab };
