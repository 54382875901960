import { ENV } from '../env';

export const redirectToBackofficeV1 = (path: string, openInNewTab = false): void => {
    const url = new URL(path, ENV.BACKOFFICE_URL);
    if (openInNewTab) {
        window.open(url.href);
    } else {
        window.location.assign(url.href);
    }
};
