import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
        margin-bottom: 50px;
    }

    h2 {
        margin-bottom: 5px;
    }

    button {
        margin-top: 24px;
    }
`;
