import { IntlShape } from 'react-intl';
import { Column } from 'react-table';

import { TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import { PricingRule } from '@/services/innovorder/pricingRule/pricingRule.type';

export const getPricingRulesTableRowsVM = (
    pricingRules: PricingRule[] | undefined,
    onDelete: (id: number) => void,
    intl: IntlShape,
): TableRow[] =>
    !pricingRules
        ? []
        : pricingRules.map(({ pricingRuleId, brandId, applyScope, code, grants, entranceFees }) => ({
              code: {
                  type: 'string',
                  value: { text: code },
              },
              applyScope: {
                  type: 'list',
                  value: {
                      listItems: applyScope.map((scope) => computeText(intl, `pricingRule.${scope.toLowerCase()}`)),
                  },
              },
              grants: {
                  type: 'list',
                  value: { listItems: grants.map(({ code: grantCode }) => grantCode), isTruncated: true },
              },
              entranceFees: {
                  type: 'list',
                  value: {
                      listItems: entranceFees.map(({ code: entranceFeeCode }) => entranceFeeCode),
                      isTruncated: true,
                  },
              },
              action: {
                  type: 'action',
                  value: {
                      children: computeText(intl, 'button.delete'),
                      buttonType: 'outlinedPrimary',
                      onClick: () => onDelete(pricingRuleId),
                  },
              },
              linkTo: {
                  type: 'linkTo',
                  value: { brandId: String(brandId), pricingRuleId: String(pricingRuleId) },
              },
          }));

export const getPricingRulesTableColumnsVM = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'pricingRule.code'),
        accessor: 'code',
    },
    {
        Header: computeText(intl, 'pricingRule.applyScope'),
        accessor: 'applyScope',
    },
    {
        Header: computeText(intl, 'pricingRule.grants'),
        accessor: 'grants',
        maxWidth: 300,
    },
    {
        Header: computeText(intl, 'pricingRule.entranceFees'),
        accessor: 'entranceFees',
        maxWidth: 300,
    },
    {
        Header: computeText(intl, 'pricingRule.action'),
        accessor: 'action',
        width: '1%',
        disableSortBy: true,
    },
];
