import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useImmer } from 'use-immer';
import { DropDown } from '@/components/atoms/DropDown';
import { computeText } from '@/locales/utils';
import { useGetRestaurantsForBrandMutation } from '@/services/innovorder/brand/brand.endpoints';
import { getUserBrandId, getUserToken } from '@/redux/user';
import { RequestFilters } from '../AutoBillingRequest';
import {
    Container,
    DropDownContainer,
    DropDownErrorMessage,
    Line,
    LineElement,
    LoaderContainer,
    SmallStyledText,
    StyledText,
} from './AutoBillingRequestFilters.style';
import {
    ALL_DROP_DOWN_VALUES,
    DropDownProperty,
    DropDownValues,
    formatSelectedValues,
    getDropDownButton,
    getDropDownValues,
    getPricingRulesSelectedValues,
    getSelectedValues,
    NO_PRICING_RULES,
} from './utils';
import Loader from '../../../../../components/atoms/Loader';
import { useGetBrandPricingRulesMutation } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { useBillingReferential } from '@/hooks/billingReferential/useBillingReferential';

type AutoBillingRequestFiltersProps = {
    filters: RequestFilters;
    onFilterChange: (filters: RequestFilters) => void;
};

const AutoBillingRequestFilters: React.FunctionComponent<React.PropsWithChildren<AutoBillingRequestFiltersProps>> = ({
    filters,
    onFilterChange,
}) => {
    const intl = useIntl();
    const theme = useTheme();
    const brandId = useSelector(getUserBrandId);
    const token = useSelector(getUserToken);

    const [dropDownsValues, setDropDownsValues] = useImmer<DropDownValues>({
        entities: [],
        restaurants: [],
        pricingRules: [],
        accountPaymentTypes: [],
    });

    const [getRestaurants, { data: restaurants }] = useGetRestaurantsForBrandMutation();
    const [getPricingRules, { data: pricingRules }] = useGetBrandPricingRulesMutation();

    const { billingReferential } = useBillingReferential();

    useEffect(() => {
        if (brandId && token) {
            getRestaurants({ brandId: brandId as number, token });
            getPricingRules({ brandId: brandId as number, token });
        }
    }, [brandId, getPricingRules, getRestaurants, token]);

    useEffect(() => {
        if (billingReferential && restaurants && pricingRules) {
            const { entities } = billingReferential;
            const dropDownValues = getDropDownValues(entities, restaurants, pricingRules, intl);
            setDropDownsValues((currentState) => {
                currentState.entities = dropDownValues.entities;
                currentState.restaurants = dropDownValues.restaurants;
                currentState.pricingRules = dropDownValues.pricingRules;
                currentState.accountPaymentTypes = dropDownValues.accountPaymentTypes;
            });
            const initializedFilter: Partial<RequestFilters> = {};
            Object.entries(filters).forEach(([filterName, filterValues]) => {
                initializedFilter[filterName as DropDownProperty] =
                    filterValues.length === 0
                        ? dropDownValues[filterName as DropDownProperty].map((item) => item.value)
                        : filterValues;
                if (filterValues.length === dropDownValues[filterName as DropDownProperty].length - 1) {
                    initializedFilter[filterName as DropDownProperty]?.push(ALL_DROP_DOWN_VALUES);
                }
                if (filterName === 'pricingRules') {
                    initializedFilter.pricingRules = [NO_PRICING_RULES];
                }
            });
            onFilterChange(initializedFilter as RequestFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onFilterChange, billingReferential, pricingRules, restaurants, setDropDownsValues]);

    return (
        <Container>
            {(!dropDownsValues.entities.length ||
                !dropDownsValues.restaurants.length ||
                !dropDownsValues.pricingRules.length ||
                !dropDownsValues.accountPaymentTypes.length) && (
                <LoaderContainer>
                    <Loader color={theme.color.primary} size={40} />
                </LoaderContainer>
            )}
            {dropDownsValues.entities.length > 0 &&
                dropDownsValues.restaurants.length > 0 &&
                dropDownsValues.pricingRules.length > 0 &&
                dropDownsValues.accountPaymentTypes.length > 0 && (
                    <>
                        <StyledText>{computeText(intl, 'auto.billing.request.filter.title')}</StyledText>
                        <Line>
                            <LineElement>
                                <SmallStyledText>
                                    {computeText(intl, 'auto.billing.request.filter.entities.title')}
                                </SmallStyledText>
                                <DropDownContainer>
                                    <DropDown
                                        type="checkbox"
                                        onChange={(updatedEntities) =>
                                            onFilterChange({
                                                ...filters,
                                                entities: getSelectedValues(
                                                    updatedEntities,
                                                    'entities',
                                                    dropDownsValues,
                                                    filters.entities,
                                                ),
                                            })
                                        }
                                        selected={filters.entities}
                                        items={dropDownsValues.entities}
                                        button={getDropDownButton(
                                            formatSelectedValues(filters.entities, 'entities', dropDownsValues, intl),
                                            'entities',
                                        )}
                                    />
                                    {!filters.entities.length && (
                                        <DropDownErrorMessage>
                                            {computeText(intl, 'auto.billing.request.filter.dropDown.errorMessage')}
                                        </DropDownErrorMessage>
                                    )}
                                </DropDownContainer>
                            </LineElement>
                            <LineElement>
                                <SmallStyledText>
                                    {computeText(intl, 'auto.billing.request.filter.restaurants.title')}
                                </SmallStyledText>
                                <DropDownContainer>
                                    <DropDown
                                        type="checkbox"
                                        onChange={(updatedRestaurants) =>
                                            onFilterChange({
                                                ...filters,
                                                restaurants: getSelectedValues(
                                                    updatedRestaurants,
                                                    'restaurants',
                                                    dropDownsValues,
                                                    filters.restaurants,
                                                ),
                                            })
                                        }
                                        selected={filters.restaurants}
                                        items={dropDownsValues.restaurants}
                                        button={getDropDownButton(
                                            formatSelectedValues(
                                                filters.restaurants,
                                                'restaurants',
                                                dropDownsValues,
                                                intl,
                                            ),
                                            'restaurants',
                                        )}
                                    />
                                    {!filters.restaurants.length && (
                                        <DropDownErrorMessage>
                                            {computeText(intl, 'auto.billing.request.filter.dropDown.errorMessage')}
                                        </DropDownErrorMessage>
                                    )}
                                </DropDownContainer>
                            </LineElement>
                        </Line>

                        <Line>
                            <LineElement>
                                <SmallStyledText>
                                    {computeText(intl, 'auto.billing.request.filter.pricingRules.title')}
                                </SmallStyledText>
                                <DropDownContainer>
                                    <DropDown
                                        type="checkbox"
                                        onChange={(updatedPricingRules) =>
                                            onFilterChange({
                                                ...filters,
                                                pricingRules: getPricingRulesSelectedValues(
                                                    updatedPricingRules,
                                                    dropDownsValues,
                                                    filters.pricingRules,
                                                ),
                                            })
                                        }
                                        selected={filters.pricingRules}
                                        items={dropDownsValues.pricingRules}
                                        button={getDropDownButton(
                                            formatSelectedValues(
                                                filters.pricingRules,
                                                'pricingRules',
                                                dropDownsValues,
                                                intl,
                                            ),
                                            'pricingRules',
                                        )}
                                    />
                                    {!filters.pricingRules.length && (
                                        <DropDownErrorMessage>
                                            {computeText(intl, 'auto.billing.request.filter.dropDown.errorMessage')}
                                        </DropDownErrorMessage>
                                    )}
                                </DropDownContainer>
                            </LineElement>
                            <LineElement>
                                <SmallStyledText>
                                    {computeText(intl, 'auto.billing.request.accountPaymentTypes.title')}
                                </SmallStyledText>
                                <DropDownContainer>
                                    <DropDown
                                        type="checkbox"
                                        onChange={(updatedAccountPaymentTypes) =>
                                            onFilterChange({
                                                ...filters,
                                                accountPaymentTypes: getSelectedValues(
                                                    updatedAccountPaymentTypes,
                                                    'accountPaymentTypes',
                                                    dropDownsValues,
                                                    filters.accountPaymentTypes,
                                                ),
                                            })
                                        }
                                        selected={filters.accountPaymentTypes}
                                        items={dropDownsValues.accountPaymentTypes}
                                        button={getDropDownButton(
                                            formatSelectedValues(
                                                filters.accountPaymentTypes,
                                                'accountPaymentTypes',
                                                dropDownsValues,
                                                intl,
                                            ),
                                            'accountPaymentTypes',
                                        )}
                                    />
                                    {!filters.accountPaymentTypes.length && (
                                        <DropDownErrorMessage>
                                            {computeText(intl, 'auto.billing.request.filter.dropDown.errorMessage')}
                                        </DropDownErrorMessage>
                                    )}
                                </DropDownContainer>
                            </LineElement>
                        </Line>
                    </>
                )}
        </Container>
    );
};

export default AutoBillingRequestFilters;
