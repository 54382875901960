import React from 'react';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/atoms/Button';
import { useTheme } from '@emotion/react';
import Loader from '@/components/atoms/Loader';

interface FiltersButtonProps {
    openFiltersSelector: () => void;
    areFiltersLoading: boolean;
}

const FiltersButton: React.FunctionComponent<FiltersButtonProps> = ({ openFiltersSelector, areFiltersLoading }) => {
    const theme = useTheme();

    return (
        <Button buttonType="secondary" onClick={openFiltersSelector} disabled={areFiltersLoading}>
            <SVGIcon icon={SVGIcons.MAGNIFY} size={16} color={theme.color.black200} />
            <span style={{ minWidth: 40 }}>
                {areFiltersLoading ? <Loader size={16} /> : <FormattedMessage id={'report.filtersButton.text'} />}
            </span>
        </Button>
    );
};

export default FiltersButton;
