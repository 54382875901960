import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { stringifyError } from '@/utils/errors';
import {
    computeEntranceFeeForQuery,
    formatRestaurantsForQuery,
} from '@/pages/Brand/EntranceFee/EntranceFeeForm/EntranceFeeRestaurants/utils';
import EntranceFeeForm from '@/pages/Brand/EntranceFee/EntranceFeeForm';
import { Routes } from '@/core/router/routes.types';
import { usePostEntranceFeeMutation } from '@/services/innovorder/entranceFee/entranceFee.endpoints';
import {
    getEntranceFee,
    getEntranceFeeRestaurants,
    updateEntranceFee,
    updateEntranceFeeRestaurants,
} from '@/redux/grantAndEntranceFee';
import { getUserToken } from '@/redux/user';
import { getUseShift } from '@/redux/launchDarkly';
import { EntranceFeeStrategy, EntranceFeeType, VatEnum } from '@/services/innovorder/entranceFee/entranceFee.type';
import { useGetBrandEntranceFeesQuery } from '@/services/innovorder/brand/brand.endpoints';

interface ParamTypes {
    brandId: string;
}

const CreateEntranceFee: React.FunctionComponent<React.PropsWithChildren> = () => {
    const hasMounted = useRef(false);

    const dispatch = useDispatch();
    const token = useSelector(getUserToken);
    const entranceFee = useSelector(getEntranceFee);
    const entranceFeeRestaurants = useSelector(getEntranceFeeRestaurants);
    const useShift = useSelector(getUseShift);
    const { brandId } = useParams<ParamTypes>();
    const history = useHistory();
    const [postEntranceFee, { isSuccess, error, data, isLoading }] = usePostEntranceFeeMutation();

    const { data: entranceFeesData } = useGetBrandEntranceFeesQuery(
        {
            brandId: parseInt(brandId, 10),
            token,
        },
        { refetchOnMountOrArgChange: false },
    );

    const create = () => {
        postEntranceFee({
            ...computeEntranceFeeForQuery(entranceFee),
            brandId: parseInt(brandId, 10),
            restaurants: formatRestaurantsForQuery(entranceFeeRestaurants, undefined, entranceFee, useShift),
            token,
        });
    };

    if (isSuccess && data?.entranceFeeId) {
        history.push(generatePath(Routes.EntranceFee, { brandId, entranceFeeId: data.entranceFeeId }));
    }

    useEffect(() => {
        if (useShift && !hasMounted.current) {
            dispatch(
                updateEntranceFee({
                    brandId: parseInt(brandId, 10),
                    strategy: EntranceFeeStrategy.Always,
                    vat: VatEnum.zero,
                    type: EntranceFeeType.AdmissionFee,
                    amount: 0,
                    code: '',
                    dailyLimit: 0,
                    labelTicket: '',
                    restaurants: [],
                }),
            );

            dispatch(updateEntranceFeeRestaurants([]));
        }
    }, [brandId, useShift, dispatch]);

    return (
        <EntranceFeeForm
            error={stringifyError(error)}
            onSubmit={create}
            loading={isLoading}
            entranceFees={entranceFeesData}
            isCreateMode
        />
    );
};

export default CreateEntranceFee;
