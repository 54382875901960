import styled from '@emotion/styled';
import Calendar from '@/components/atoms/Calendar';

export const StyledCalendar = styled(Calendar)`
    margin: ${({ theme }) => theme.spacing[4]} 0 ${({ theme }) => theme.spacing[6]};
`;

export const PresetDateButtonsContainer = styled.div`
    margin-top: ${({ theme }) => theme.spacing[4]};
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const ExportFormatContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing[4]};
`;
