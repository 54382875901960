import React from 'react';
import { useIntl } from 'react-intl';
import { HeaderGroup, TableSortByToggleProps } from 'react-table';

import { SVGIcons } from '../../Icon/SVGIcon';
import { TableRow } from '../Table.type';
import TableHeaderFilter from '../TableHeaderFilter';
import { SortIcon, TableHeaderContainer, TableHeaderContent } from './TableHeader.style';

type RenderSortProps = { isSorted?: boolean; isSortedDesc?: boolean; sortParams?: TableSortByToggleProps };

const renderSortIcon = ({ isSorted, isSortedDesc, sortParams }: RenderSortProps) => {
    if (!isSorted) return <SortIcon icon={SVGIcons.DOUBLE_CHEVRON} size={24} {...sortParams} />;
    if (isSortedDesc) return <SortIcon icon={SVGIcons.CHEVRON} size={24} {...sortParams} />;
    return <SortIcon icon={SVGIcons.CHEVRON} size={24} rotate={180} {...sortParams} />;
};

type TableHeaderProps = {
    isSortable?: boolean;
    headerGroups: HeaderGroup<TableRow>[];
    reorderAfterDrag?: (sourceId: number, destinationId: number) => void;
};

export const TableHeader: React.FunctionComponent<React.PropsWithChildren<TableHeaderProps>> = ({
    isSortable,
    headerGroups,
    reorderAfterDrag,
}) => {
    const intl = useIntl();
    return (
        <thead>
            {headerGroups.map((headerGroup) => {
                const { key: trKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                    <tr key={trKey} {...headerGroupProps}>
                        {reorderAfterDrag && <th data-testid="header-column"></th>}
                        {headerGroup.headers.map((column) => {
                            const { key: thKey, ...headerProps } = column.getHeaderProps();
                            const title =
                                isSortable &&
                                !column.disableSortBy &&
                                intl.formatMessage({ id: 'table.sort' }, { name: column.Header as string });

                            return (
                                <TableHeaderContainer
                                    data-testid="header-column"
                                    key={thKey}
                                    {...headerProps}
                                    style={{
                                        minWidth: column.minWidth,
                                        width: column.width,
                                        maxWidth: column.maxWidth,
                                    }}
                                >
                                    <TableHeaderContent>
                                        {isSortable &&
                                            !column.disableSortBy &&
                                            renderSortIcon({
                                                isSorted: column.isSorted,
                                                isSortedDesc: column.isSortedDesc,
                                                sortParams: column.getSortByToggleProps({ title: title as string }),
                                            })}
                                        {column.canFilter && <TableHeaderFilter column={column} />}
                                        {!column.canFilter && column.render('Header')}
                                    </TableHeaderContent>
                                </TableHeaderContainer>
                            );
                        })}
                    </tr>
                );
            })}
        </thead>
    );
};
