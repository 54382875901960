import { ApiTagTypes, innovorderApi } from '../../index';
import { ApiRequest, ApiResponse } from '../../request.types';
import {
    DeletePosAccessGroupPayload,
    DeletePosAccessGroupResponse,
    GetPosAccessGroupByIdBody,
    GetPosAccessGroupByIdResponse,
    GetPosAccessGroupsBody,
    GetPosAccessGroupsResponse,
    PatchPosAccessGroupPayload,
    PatchPosAccessGroupResponse,
    PostPosAccessGroupPayload,
    PostPosAccessGroupResponse,
} from './pos_access_group.type';

const posAccessGroupsApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getPosAccessGroups: build.query<GetPosAccessGroupsResponse, ApiRequest<GetPosAccessGroupsBody>>({
            query: ({ token, restaurantId }) => ({
                url: `/restaurants/${restaurantId}/pos_access/groups`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: (response, _, queryArgs) => {
                if (response) {
                    return [
                        ...response.map(({ posAccessGroupId }) => ({
                            type: ApiTagTypes.PosGroups,
                            id: `${queryArgs.restaurantId}-${posAccessGroupId}`,
                        })),
                        { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-LIST` },
                    ];
                }
                return [{ type: ApiTagTypes.PosGroups, id: 'LIST' }];
            },
            transformResponse: ({ data }: ApiResponse<GetPosAccessGroupsResponse>): GetPosAccessGroupsResponse => data,
            extraOptions: { maxRetries: 3 },
        }),
        getPosAccessGroupById: build.query<GetPosAccessGroupByIdResponse, ApiRequest<GetPosAccessGroupByIdBody>>({
            query: ({ token, restaurantId, groupId }) => ({
                url: `/restaurants/${restaurantId}/pos_access/groups/${groupId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-${queryArgs.groupId}` },
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<GetPosAccessGroupByIdResponse>): GetPosAccessGroupByIdResponse =>
                data,
            extraOptions: { maxRetries: 3 },
        }),
        createPosAccessGroup: build.mutation<PostPosAccessGroupResponse, ApiRequest<PostPosAccessGroupPayload>>({
            query: ({ token, restaurantId, ...body }) => ({
                url: `/restaurants/${restaurantId}/pos_access/groups`,
                method: 'POST',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-LIST` },
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<PostPosAccessGroupResponse>): PostPosAccessGroupResponse => data,
        }),
        updatePosAccessGroup: build.mutation<PatchPosAccessGroupResponse, ApiRequest<PatchPosAccessGroupPayload>>({
            query: ({ token, restaurantId, groupId, ...body }) => ({
                url: `/restaurants/${restaurantId}/pos_access/groups/${groupId}`,
                method: 'PATCH',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-LIST` },
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-${queryArgs.groupId}` },
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<PatchPosAccessGroupResponse>): PatchPosAccessGroupResponse =>
                data,
        }),

        deletePosAccessGroup: build.mutation<DeletePosAccessGroupResponse, ApiRequest<DeletePosAccessGroupPayload>>({
            query: ({ token, restaurantId, posGroupId, ...body }) => ({
                url: `/restaurants/${restaurantId}/pos_access/groups/${posGroupId}`,
                method: 'DELETE',
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: (_, __, queryArgs) => {
                return [
                    { type: ApiTagTypes.PosGroups, id: `${queryArgs.restaurantId}-LIST` },
                    { type: ApiTagTypes.PosUsers, id: `${queryArgs.restaurantId}-LIST` },
                ];
            },
            transformResponse: ({ data }: ApiResponse<DeletePosAccessGroupResponse>): DeletePosAccessGroupResponse =>
                data,
        }),
    }),
});

export const {
    useGetPosAccessGroupsQuery,
    useGetPosAccessGroupByIdQuery,
    useCreatePosAccessGroupMutation,
    useUpdatePosAccessGroupMutation,
    useDeletePosAccessGroupMutation,
} = posAccessGroupsApi;
