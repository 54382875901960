import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Cell, Column } from 'react-table';
import { BadgeProps } from '@/components/atoms/Badge/Badge';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { fetchDataProps, DEFAULT_PAGE_INDEX } from '@/components/atoms/Table';
import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { routes } from '@/core/router/routes';
import { RouteDeclaration } from '@/core/router/routes.types';
import { theme } from '@/core/theme';
import { computeText, computeDate } from '@/locales/utils';
import { getUserRestaurants, getUserToken } from '@/redux/user';
import { useGetHistoryListMutation, useGetAuditMutation } from '@/services/innovorder/history/history.endpoints';
import {
    ActionType,
    ActionTypes,
    EntityType,
    EntityTypes,
    GetAuditResponse,
} from '@/services/innovorder/history/history.type';
import { filterTypes } from '@/components/atoms/Table/Table.utils';
import { convertTableFiltersToQueryFilters, convertTableSortToQuerySort, formatAuditDiff } from './utils';

export type BrandHistoryViewModel = {
    headers: Column<TableRow>[];
    rows: TableRow[];
    isLoading: boolean;
    fetchData: ({ pageIndex, pageSize }: fetchDataProps) => void;
    fetchDataTotalCount: number;
    tabRoutes: RouteDeclaration[];
    handleRowClick: (cell: Cell<TableRow, TableCell<unknown>> | undefined) => void;
    isModalOpen: boolean;
    closeModal: () => void;
    auditDetail: GetAuditResponse | null;
    getIconFromEntityType: (entityType: EntityType) => SVGIcons;
    getTypeBadge: (action: ActionType) => BadgeProps;
    shouldDisplayDiff: boolean;
};

export type ParamTypes = {
    brandId: string;
};

const DEFAULT_PAGE_SIZE_HISTORY = 50;

export function useBrandHistoryVm(): BrandHistoryViewModel {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [auditDetail, setAuditDetail] = useState<GetAuditResponse | null>(null);
    const intl = useIntl();
    const restaurants = useSelector(getUserRestaurants);

    const { brandId: paramBrandId } = useParams<ParamTypes>();
    const brandId = Number.parseInt(paramBrandId, 10);

    const token = useSelector(getUserToken);

    const [fetchHistoryList, { data, isLoading }] = useGetHistoryListMutation();
    const [fetchSingleAuditData, { data: auditData, isLoading: isLoadingAuditData }] = useGetAuditMutation();

    const { count = 0, data: historyList = [] } = data || {};

    useEffect(() => {
        if (auditData) {
            setAuditDetail({
                ...auditData,
                diff: formatAuditDiff(auditData.diff),
            });
        }
    }, [isLoadingAuditData, auditData]);

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    const fetchData = useCallback(
        ({ pageIndex, pageSize, globalFilter, sortBy, columnFilters }: fetchDataProps) => {
            if (token) {
                const filters = convertTableFiltersToQueryFilters(columnFilters);
                fetchHistoryList({
                    brandId,
                    token,
                    orderBy: convertTableSortToQuerySort(sortBy),
                    search: globalFilter,
                    limit: pageSize ?? DEFAULT_PAGE_SIZE_HISTORY,
                    offset:
                        pageIndex === undefined || pageSize === undefined ? DEFAULT_PAGE_INDEX : pageIndex * pageSize,
                    filters,
                });
            }
        },
        [brandId, token, fetchHistoryList],
    );

    const fetchAuditData = useCallback(
        (auditId: string) => {
            fetchSingleAuditData({ auditId });
        },
        [fetchSingleAuditData],
    );

    const getTypeBadge = useCallback(
        (action: ActionType): BadgeProps => {
            switch (action) {
                case 'CREATE':
                    return {
                        text: computeText(intl, 'brandHistory.action.create'),
                        backgroundColor: theme.color.emerald,
                    };
                case 'OPEN':
                    return {
                        text: computeText(intl, 'brandHistory.action.open'),
                        backgroundColor: theme.color.emerald,
                    };
                case 'ACTIVATE':
                    return {
                        text: computeText(intl, 'brandHistory.action.activate'),
                        backgroundColor: theme.color.emerald,
                    };

                case 'DELETE':
                    return {
                        text: computeText(intl, 'brandHistory.action.delete'),
                        backgroundColor: theme.color.bloodyMary,
                    };
                case 'CLOSE':
                    return {
                        text: computeText(intl, 'brandHistory.action.close'),
                        backgroundColor: theme.color.bloodyMary,
                    };
                case 'DEACTIVATE':
                    return {
                        text: computeText(intl, 'brandHistory.action.deactivate'),
                        backgroundColor: theme.color.bloodyMary,
                    };
                case 'CANCEL':
                    return {
                        text: computeText(intl, 'brandHistory.action.cancel'),
                        backgroundColor: theme.color.bloodyMary,
                    };

                case 'UPDATE':
                    return {
                        text: computeText(intl, 'brandHistory.action.update'),
                        backgroundColor: theme.color.yellow200,
                        textColor: theme.color.black,
                    };
                case 'DUPLICATE':
                    return {
                        text: computeText(intl, 'brandHistory.action.duplicate'),
                        backgroundColor: theme.color.blue200,
                        textColor: theme.color.white200,
                    };
                case 'IMPORT':
                    return {
                        text: computeText(intl, 'brandHistory.action.import'),
                        backgroundColor: theme.color.yellow200,
                        textColor: theme.color.black,
                    };
                default:
                    return {
                        text: computeText(intl, 'brandHistory.action.unknown'),
                        backgroundColor: theme.color.grey400,
                    };
            }
        },
        [intl],
    );

    const getIconFromEntityType = (entityType: EntityType) => {
        switch (entityType) {
            case 'PRICING_RULE':
            case 'GRANT':
            case 'ENTRANCE_FEE':
                return SVGIcons.LOYALTY;

            case 'BRAND':
            case 'RESTAURANT':
            case 'MODULE':
                return SVGIcons.BRAND;

            case 'CUSTOMER':
            case 'USER':
                return SVGIcons.CLIENTS;

            case 'CHANNEL':
                return SVGIcons.KIOSK;

            case 'CONSUMPTION_MODE':
                return SVGIcons.JUSTEAT;

            case 'ORDER':
                return SVGIcons.PAYMENT;

            case 'PRODUCT':
            case 'STEP':
            case 'CATEGORY':
            case 'MENU':
                return SVGIcons.POS;

            case 'CUSTOMERS_IMPORT':
            case 'CUSTOMERS_UPDATE':
                return SVGIcons.CLIPBOARD;

            default:
                break;
        }
        return SVGIcons.HELP;
    };

    const rows: TableRow[] = [...(historyList ?? [])].map(
        ({ entityName, entityType, userEmail, userId, eventDate, action, restaurantId, historyId }) => ({
            entityType: {
                type: 'texticon',
                value: {
                    icon: getIconFromEntityType(entityType),
                    text: computeText(intl, `brandHistory.entityType.${entityType}`),
                },
            },
            entityName: {
                type: 'string',
                value: {
                    text: entityName,
                },
            },
            user: {
                type: 'string',
                value: {
                    text: `${userEmail} (${userId})`,
                },
            },
            eventDate: {
                type: 'string',
                value: {
                    text: computeDate(intl, eventDate),
                },
            },
            action: {
                type: 'badge',
                value: getTypeBadge(action),
            },
            restaurantId: {
                type: 'string',
                value: {
                    text:
                        restaurants?.find((r) => r.restaurantId === parseInt(restaurantId ?? '', 10))?.name ??
                        ('' as string),
                },
            },
            historyId: {
                type: 'identifier',
                value: historyId,
            },
        }),
    );

    const headers: Column<TableRow>[] = [
        {
            Header: computeText(intl, 'brandHistory.eventDate'),
            accessor: 'eventDate',
        },
        {
            Header: computeText(intl, 'brandHistory.entityType'),
            accessor: 'entityType',
            filter: filterTypes.list,
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            Filter: EntityTypes.map((entityType) => ({
                value: entityType,
                label: computeText(intl, `brandHistory.entityType.${entityType}`),
            })).sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            Header: computeText(intl, 'brandHistory.entityName'),
            accessor: 'entityName',
        },
        {
            Header: computeText(intl, 'brandHistory.restaurant'),
            accessor: 'restaurantId',
            filter: filterTypes.list,
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            Filter: restaurants.map(({ restaurantId, name }) => ({
                value: restaurantId,
                label: name,
            })),
        },
        {
            Header: computeText(intl, 'brandHistory.user'),
            accessor: 'user',
        },
        {
            Header: computeText(intl, 'brandHistory.action'),
            accessor: 'action',
            filter: filterTypes.list,
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            Filter: ActionTypes.map((actionType) => ({
                value: actionType,
                label: computeText(intl, `brandHistory.action.${actionType.toLowerCase()}`),
            })).sort((a, b) => a.label.localeCompare(b.label)),
        },
    ];

    const handleRowClick = React.useCallback(
        (cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
            if (!cell) return;

            const { id: historyId } = cell?.row;

            if (historyId) {
                fetchAuditData(historyId);
                setIsModalOpen(true);
            }
        },
        [fetchAuditData],
    );

    const shouldDisplayDiff = useMemo(() => !!(auditDetail?.diff && auditDetail.diff.length), [auditDetail]);

    const closeModal = () => {
        setAuditDetail(null);
        setIsModalOpen(false);
    };

    return {
        headers,
        rows,
        isLoading,
        fetchData,
        fetchDataTotalCount: count,
        tabRoutes,
        handleRowClick,
        isModalOpen,
        closeModal,
        auditDetail,
        getIconFromEntityType,
        getTypeBadge,
        shouldDisplayDiff,
    };
}
