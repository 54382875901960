import Wizard from '@/components/atoms/Wizard';
import styled from '@emotion/styled';
import { SvyIcon } from '@innovorder/savory';

export const RoomWizard = styled(Wizard)`
    width: 90vw;
`;

export const LineContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[1]};
`;

export const TableInput = styled.div`
    flex: 1;
`;

export const TableContainer = styled.div`
    height: 50vh;
    overflow-y: auto;
    padding: ${({ theme }) => theme.spacing[1]};
    border: ${({ theme }) => `1px ${theme.savory.colors.secondary[900]} solid`};
`;

export const TableItemContainer = styled.div`
    position: relative;
`;

export const TableItem = styled.div<{ height: number; width: number; canEdit: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    background-color: ${({ theme }) => theme.savory.colors.secondary[50]};
    border: ${({ theme }) => `1px ${theme.savory.colors.secondary[900]} solid`};
    color: ${({ theme }) => theme.savory.colors.secondary[900]};

    div {
        display: none;
    }
    ${({ canEdit, theme }) =>
        canEdit &&
        `&:hover {
        border: 2px ${theme.savory.colors.secondary[900]} solid;

        div {
            display: block;
        }
    }`};
`;

export const DeleteItem = styled(SvyIcon)`
    position: absolute;
    top: 0;
    right: 0;
    color: ${({ theme }) => theme.savory.colors.secondary[900]};
    font-size: ${({ theme }) => theme.font.size.huge};
`;
