import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Animated = styled.div<{ color?: string; size: number }>`
    animation: ${animation} 1s linear infinite;
    transform-origin: center;
    color: ${({ color }) => color};
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
`;

export const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
