import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getAssertedUserBrandId, getUserToken } from '@/redux/user';
import { useGetCustomerByCustomerIdQuery } from '@/services/innovorder/customer/customer.endpoints';
import { useGetGuestGroupsQuery } from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { useGetActiveStudentsEntitiesQuery } from '@/services/innovorder/brand/brand.endpoints';
import UpdateCustomerModal from './UpdateCustomerModal';
import { Container } from './UpdateCustomer.style';
import Loader from '../../../components/atoms/Loader';
import { redirectToStudentDetailsPage } from './UpdateCustomerModal/UpdateCustomerModal.utils';

type UpdateCustomerParams = {
    customerId: string;
};

const UpdateCustomer: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { customerId } = useParams<UpdateCustomerParams>();
    const token = useSelector(getUserToken);
    const brandId = useSelector(getAssertedUserBrandId);
    const { isLoading: isCustomerLoading, data: customer } = useGetCustomerByCustomerIdQuery({
        customerId: parseInt(customerId, 10),
        token,
    });
    const { isLoading: areGuestGroupsLoading, data: guestGroups } = useGetGuestGroupsQuery(
        {
            brandId,
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const { data: activeStudentEntities } = useGetActiveStudentsEntitiesQuery(
        {
            brandId,
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const onHide = () => {
        redirectToStudentDetailsPage(customerId);
    };
    return (
        <Container>
            {isCustomerLoading && areGuestGroupsLoading ? (
                <Loader withContainer />
            ) : (
                customer &&
                guestGroups && (
                    <UpdateCustomerModal
                        title="customer.update.modalTitle"
                        isOpen
                        onHide={onHide}
                        customer={customer}
                        guestGroups={guestGroups}
                        activeStudentEntities={activeStudentEntities}
                    />
                )
            )}
        </Container>
    );
};

export default UpdateCustomer;
