import React from 'react';
import { HeaderRight } from '@/components/atoms/Section/Section.style';
import { Title } from '@/components/atoms/Title';
import { SvyButton } from '@/components/savory/SvyButton';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { Text } from '@/components/atoms/Text';
import { PosAccessBase } from '../../components/PosAcess';
import { PosUsersListTable } from '../../components/PosUsers/tables/UsersListTable';
import { HeaderLeft, Subtitle } from '../../components/PosAcess.style';
import { Container, Header, HeaderContainer } from '../../components/PosUsers/tables/UsersListTable.style';

type ParamTypes = {
    restaurantId: string;
    brandId: string;
};

const PosUsersList: React.FunctionComponent<React.PropsWithChildren> = () => {
    const history = useHistory();
    const { brandId, restaurantId } = useParams<ParamTypes>();

    const handleOnCreate = () => {
        history.push(`/brands/${brandId}/restaurants/${restaurantId}/channels/pos/access/users/new`);
    };
    return (
        <PosAccessBase>
            <Container>
                <HeaderContainer>
                    <Header>
                        <HeaderLeft>
                            <Title text="pos.users.page.title" />
                            <Subtitle>
                                <Text text="pos.users.page.description" />
                            </Subtitle>
                        </HeaderLeft>
                        <HeaderRight>
                            <SvyButton onClick={handleOnCreate} style={{ backgroundColor: 'black' }} size="s">
                                <FormattedMessage id="pos.users.action.newUser" />
                            </SvyButton>
                        </HeaderRight>
                    </Header>
                </HeaderContainer>
                <PosUsersListTable />
            </Container>
        </PosAccessBase>
    );
};

export { PosUsersList };
