import { Badge } from '@/services/innovorder/customer/customer.types';

export const MODAL_PROPS = {
    CREATE: (forceDefault: boolean) => ({
        title: 'customer.student.badge.form.add',
        isOpen: true,
        badge: { isDefault: forceDefault },
        disableSwitch: forceDefault,
    }),
    UPDATE: (badge: Badge) => ({
        title: 'customer.student.badge.form.update',
        isOpen: true,
        badge,
    }),
    NONE: () => ({
        title: '',
        isOpen: false,
    }),
};
