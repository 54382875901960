import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Section } from '@/components/atoms/Section';
import { Input } from '@/components/atoms/Form/Input';
import { computeText } from '@/locales/utils';
import { BillingServiceCodes } from '@/services/innovorder/autoBilling/autoBilling.type';
import { InputContainer, Form, FormText } from './ServiceCodesForm.style';

type ServiceCodesFormProps = {
    servicesCodes?: BillingServiceCodes;
    onUpdate: (servicesCodes: BillingServiceCodes) => void;
    onIsValidChange: (value: boolean) => void;
    disabled?: boolean;
};

const ServiceCodesForm: React.FunctionComponent<React.PropsWithChildren<ServiceCodesFormProps>> = ({
    servicesCodes,
    onUpdate,
    onIsValidChange,
    disabled = false,
}) => {
    const intl = useIntl();
    const {
        control,
        getValues,
        reset,
        formState: {
            isValid,
            touchedFields: { admissionLevelCostCode, fixedCostCode },
        },
    } = useForm<BillingServiceCodes>({
        mode: 'all',
        defaultValues: servicesCodes,
    });

    const handleChange = useCallback(() => onUpdate(getValues()), [onUpdate, getValues]);
    useEffect(() => {
        onIsValidChange(isValid);
    }, [isValid, onIsValidChange]);

    useEffect(() => {
        reset(servicesCodes);
    }, [reset, servicesCodes]);

    return (
        <div data-testid="serviceCodesForm">
            <Section title="auto.billing.referential.serviceCodes.card.title" subtitle="">
                <Form onChange={handleChange}>
                    <InputContainer>
                        <Controller
                            control={control}
                            name="admissionLevelCostCode"
                            rules={{ required: computeText(intl, 'field.required') }}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <>
                                    <FormText
                                        type="small"
                                        weight="medium"
                                        text="auto.billing.referential.serviceCodes.admissionLevelCostCode.label"
                                    />
                                    <Input
                                        data-testid="admissionLevelCostCode"
                                        error={error?.message}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        isTouched={admissionLevelCostCode}
                                        disabled={disabled}
                                    />
                                </>
                            )}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Controller
                            control={control}
                            name="fixedCostCode"
                            rules={{ required: computeText(intl, 'field.required') }}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <>
                                    <FormText
                                        type="small"
                                        weight="medium"
                                        text="auto.billing.referential.serviceCodes.fixedCostCode.label"
                                    />
                                    <Input
                                        data-testid="fixedCostCode"
                                        error={error?.message}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        isTouched={fixedCostCode}
                                        disabled={disabled}
                                    />
                                </>
                            )}
                        />
                    </InputContainer>
                </Form>
            </Section>
        </div>
    );
};

export default ServiceCodesForm;
