import React, { useRef } from 'react';
import { useHistory } from 'react-router';

import { Spotlight } from '@/pages/Spotlight';
import RawModal from '@/components/atoms/RawModal';
import { useSpotlightModalHandler } from '@/hooks/useSpotlightModalHandler/useSpotlightModalHandler';
import { useSpotlightCookieHandler } from '@/hooks/useSpotlightCookieHandler/useSpotlightCookieHandler';
import SideBar from './SideBar';
import InitialiseThirdParties from './InitialiseThirdParties';
import { AdminBanner } from './AdminBanner';
import { NavigationBar } from './NavigationBar';
import { Container, ContentContainer } from './DefaultLayout.style';
import BrandSwitchBanner from './BrandSwitchBanner/BrandSwitchBanner';

const DefaultLayout: React.FunctionComponent<
    React.PropsWithChildren<{
        pageContent?: React.FunctionComponent<React.PropsWithChildren>;
    }>
> = ({ pageContent: PageContent }) => {
    const history = useHistory();
    const scrollToTopRef = useRef<HTMLInputElement>(null);
    const { isSpotlightOpened, handleHideSpotlight } = useSpotlightModalHandler();
    const spotlightHistory = useSpotlightCookieHandler();

    history.listen(() => {
        if (scrollToTopRef.current) {
            scrollToTopRef.current.scrollIntoView();
        }
    });

    return (
        <Container id="scrollToTop">
            <RawModal isOpen={isSpotlightOpened} onHide={handleHideSpotlight}>
                <Spotlight spotlightHistory={spotlightHistory} onHide={handleHideSpotlight} />
            </RawModal>
            <SideBar />

            <ContentContainer>
                <div ref={scrollToTopRef}></div>
                <AdminBanner />
                <BrandSwitchBanner />
                <NavigationBar />
                <React.Suspense>{PageContent && <PageContent />}</React.Suspense>
            </ContentContainer>
            <InitialiseThirdParties />
        </Container>
    );
};

export default DefaultLayout;
