import styled from '@emotion/styled';

export const DraggableRow = styled.tr<{ isDragging?: boolean }>`
    display: ${({ isDragging }) => (isDragging ? 'table' : '')};
    svg {
        cursor: pointer;
    }
`;

export const ToggleContainer = styled.td`
    width: 1%;
`;

export const ToggleIcon = styled.span`
    cursor: pointer;
`;

export const Label = styled.td`
    width: 50%;
`;
