import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const ListContainer = styled.div<{ isTruncated?: boolean; containsComplexElement: boolean }>`
    width: 100%;
    ${({ containsComplexElement }) =>
        containsComplexElement &&
        `
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    `}
    ${({ isTruncated }) =>
        isTruncated &&
        `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
`;

export const ListTextWithIcon = styled(Text)`
    margin: auto 8px auto 4px;
`;

export const ListSeparator = styled.div`
    margin: auto 8px auto 0;
`;

export const ListItemContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
