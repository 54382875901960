import * as React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { computeText } from '@/locales/utils';
import { confirm } from '@/core/asyncActions/confirm';

export const usePreventLeave = (shouldPreventLeaving: boolean) => {
    const history = useHistory();
    const intl = useIntl();

    React.useEffect(() => {
        const preventNavigation = (e: BeforeUnloadEvent) => {
            if (!shouldPreventLeaving) {
                return undefined;
            }
            e.preventDefault();
            e.returnValue = '';
            return '';
        };

        window.addEventListener('beforeunload', preventNavigation, false);

        return () => window.removeEventListener('beforeunload', preventNavigation);
    }, [shouldPreventLeaving]);

    React.useEffect(() => {
        const unblock = history?.block((location) => {
            if (shouldPreventLeaving) {
                const confirmLeaving = async () => {
                    const confirmation = await confirm(computeText(intl, 'form.preventLeave'));

                    if (confirmation) {
                        unblock();
                        history.push(location);
                    }
                };

                confirmLeaving();

                return false;
            }
            return undefined;
        });

        return unblock;
    }, [shouldPreventLeaving, history, intl]);
};
