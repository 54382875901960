import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { computeText } from '@/locales/utils';
import { stringifyError } from '@/utils/errors';
import Wizard from '@/components/atoms/Wizard';
import { Input } from '@/components/atoms/Form/Input';
import RawModal from '@/components/atoms/RawModal';
import { usePostDuplicateMenuByIdMutation } from '@/services/innovorder/menu/menu.endpoints';
import { getUserToken } from '@/redux/user';
import { MenuModalInputContainer, MenuModalTextInputLabel } from '../MenuModal.style';

export type DuplicateMenuModalProps = {
    brandId: number;
    restaurantId?: number;
    menuId: number | null;
    menuName: string | null;
    onHide: () => void;
};

export const DuplicateMenuModal: React.FunctionComponent<React.PropsWithChildren<DuplicateMenuModalProps>> = ({
    onHide,
    menuId,
    brandId,
    restaurantId,
    menuName = '',
}) => {
    const intl = useIntl();
    const token = useSelector(getUserToken);
    const [postDuplicateMenu, { isSuccess: isDuplicateSuccess, isLoading: isDuplicateLoading, error: duplicateError }] =
        usePostDuplicateMenuByIdMutation();
    const { control, getValues, setValue, setError } = useForm<{ name: string | null }>({
        defaultValues: {
            name: null,
        },
    });

    const onDuplicateValidate = useCallback(() => {
        const { name } = getValues();

        if (!(menuId && name)) {
            return setError('name', { message: computeText(intl, 'menu.modal.error.name') });
        }

        return postDuplicateMenu({ menuId, restaurantId, brandId: restaurantId ? undefined : brandId, name, token });
    }, [menuId, brandId, restaurantId, intl, token, postDuplicateMenu, getValues, setError]);

    useEffect(() => {
        setValue('name', `${menuName} - copy` ?? '');
    }, [menuName, setValue]);

    useEffect(() => {
        if (isDuplicateSuccess) {
            onHide();
        }
    }, [isDuplicateSuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <Wizard
                width={480}
                isLoading={isDuplicateLoading}
                steps={[
                    {
                        title: 'menu.modal.duplicate.title',
                        onCancel: onHide,
                        component: (
                            <MenuModalInputContainer>
                                <MenuModalTextInputLabel type="small" weight="medium" text="menu.name" />
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Input
                                            data-testid="menu-modal-name-input"
                                            value={value ?? ''}
                                            isTouched={!!value && value.length > 0}
                                            onChange={onChange}
                                            error={error?.message}
                                        />
                                    )}
                                />
                            </MenuModalInputContainer>
                        ),
                    },
                ]}
                errorMessage={stringifyError(duplicateError)}
                onValidate={onDuplicateValidate}
            />
        </RawModal>
    );
};
