import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { RouteDeclaration, NavigationParametersEnum } from '@/core/router/routes.types';
import { getUserBrands, getUserRestaurants } from '@/redux/user';
import { getResolvedPathNames } from '@/redux/app';

export type ParamTypes = {
    [NavigationParametersEnum.brandId]?: string;
    [NavigationParametersEnum.restaurantId]?: string;
};

export const useDynamiqueBreadCrumb = (): {
    populateBreadCrumb: (routes: RouteDeclaration[]) => RouteDeclaration[];
} => {
    const params = useParams<ParamTypes>();
    const userBrands = useSelector(getUserBrands);
    const userRestaurants = useSelector(getUserRestaurants);
    const resolvedPathNames = useSelector(getResolvedPathNames);

    const getName = {
        [NavigationParametersEnum.brandId]: ({ accessor }: { accessor: string }) =>
            get(
                userBrands?.find(({ brandId }) => brandId.toString() === params[NavigationParametersEnum.brandId]),
                accessor,
                '',
            ),
        [NavigationParametersEnum.restaurantId]: ({ accessor }: { accessor: string }) =>
            get(
                userRestaurants?.find(
                    ({ restaurantId }) => restaurantId.toString() === params[NavigationParametersEnum.restaurantId],
                ),
                accessor,
                '',
            ),
    };

    const populateBreadCrumb = (routes: RouteDeclaration[]): RouteDeclaration[] => {
        return routes
            .map(({ breadCrumb, ...rest }) => {
                let name = resolvedPathNames[rest?.name as string];
                if (!name) {
                    name =
                        breadCrumb?.source === NavigationParametersEnum.brandId ||
                        breadCrumb?.source === NavigationParametersEnum.restaurantId
                            ? getName[breadCrumb?.source]({ accessor: breadCrumb.accessor || 'name' })
                            : rest.name;
                }

                return {
                    ...rest,
                    name,
                };
            })
            .filter(({ name }) => name);
    };

    return { populateBreadCrumb };
};
