import { TableRow } from '@/components/atoms/Table/Table.type';
import { useGetProductListByBrandIdQuery } from '@/services/innovorder/crossSelling/crossSelling.endpoint';
import React from 'react';
import { TableState } from 'react-table';
import { useProductsTableRows, useProductsColumns } from './ProductsTable.table';
import { ProductsTableProps } from './ProductsTable';

export const useProductsTableVM = ({ brandId, selectedProductIds, onValidate, onCancel }: ProductsTableProps) => {
    const { data, isLoading } = useGetProductListByBrandIdQuery({ brandId });
    const allRows = useProductsTableRows({ data });

    // Remove rows already in input
    const rows = React.useMemo(() => {
        return allRows.filter((row) => !selectedProductIds.includes(Number(row.productId.value)));
    }, [selectedProductIds, allRows]);

    const columns = useProductsColumns();

    const tableRef = React.useRef<TableState<TableRow>>(null);

    const handleValidate = React.useCallback(() => {
        const tableSelectedProductIds = Object.keys(tableRef.current?.selectedRowIds ?? {});
        const products = (data ?? []).filter((product) =>
            tableSelectedProductIds?.includes(product.productId.toString()),
        );
        onValidate(products);
    }, [data, onValidate]);

    const handleCancel = React.useCallback(() => {
        onCancel();
    }, [onCancel]);

    return { columns, rows, tableRef, isLoading, handleCancel, handleValidate };
};
