import * as React from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { Menu } from '@/components/templates/Menu';
import { useGetMenuByIdQuery } from '@/services/innovorder/menu/menu.endpoints';
import { useRegisterPathName } from '@/hooks/routes/useRegisterPathName';
import { Routes } from '@/core/router/routes.types';

export const BrandMenu: React.FunctionComponent = () => {
    const params = useParams<{ menuId: string; brandId: string }>();

    const menuResponse = useGetMenuByIdQuery({ menuId: Number(params.menuId) });

    useRegisterPathName('route.brandMenu', menuResponse.data?.name);

    return (
        <React.Suspense>
            <Menu
                fallbackPath={generatePath(Routes.BrandMenus, params)}
                menuId={Number(params.menuId)}
                channels={menuResponse.data?.channels || []}
                isActive={menuResponse.data?.isActive || false}
            />
        </React.Suspense>
    );
};
