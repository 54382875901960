import React from 'react';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import RawModal from '@/components/atoms/RawModal';
import { WizardIoPayConfiguration } from './FormModal.style';
import { useFormModalVM } from './FormModal.viewmodel';
import { BadgeReader } from '@/services/innovorder/badgeReader/badgeReader.type';

export type FormModalProps = {
    isOpen: boolean;
    data?: BadgeReader;
    onHide: () => void;
};

export const FormModal: React.FunctionComponent<React.PropsWithChildren<FormModalProps>> = ({
    isOpen,
    data,
    onHide,
}) => {
    const intl = useIntl();
    const { steps, isLoading, errorMessage, onSubmit } = useFormModalVM({
        data,
        onHide,
    });

    return (
        <RawModal isOpen={isOpen} data-testid="raw-modal">
            <WizardIoPayConfiguration
                width={900}
                steps={steps}
                validateLabel={computeText(intl, 'button.save')}
                onValidate={onSubmit}
                showStepper={true}
                errorMessage={errorMessage}
                isLoading={isLoading}
                isForm={true}
            />
        </RawModal>
    );
};
