import React from 'react';

import ErrorMessage from '@/components/atoms/ErrorMessage';
import { ActionContainer, Container, InfoContainer } from './ActionBar.style';

type Props = {
    information?: string;
    error?: string;
};

export const ActionBar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    information,
    error,
    children,
}) => (
    <Container>
        <InfoContainer>{information && <p>{information}</p>}</InfoContainer>
        <ActionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {children}
        </ActionContainer>
    </Container>
);
