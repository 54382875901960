import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.color.white200};
    padding: 10px 8px;
    border-radius: ${({ theme }) => theme.radius.main};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    height: 40px;

    * {
        color: ${({ theme }) => theme.color.black200};
        font-size: ${({ theme }) => theme.font.size.normal};
        font-weight: ${({ theme }) => theme.font.weight.regular};
        margin: 0 3px;

        &:last-of-type {
            color: ${({ theme }) => theme.color.primary};

            cursor: auto;

            &:hover {
                color: ${({ theme }) => theme.color.primary};
            }
        }
    }

    svg {
        margin-right: 12px;
    }
`;

export const Flex = styled.div`
    display: flex;
`;
