import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { computeText } from '@/locales/utils';
import { ButtonStyle, Container, Label, SwitchTip } from './Switch.style';

export type SwitchProps = {
    onChange: (value: boolean) => void;
    value?: boolean;
    labelId?: string;
    disabled?: boolean;
};

export const Switch: React.FunctionComponent<React.PropsWithChildren<SwitchProps>> = ({
    labelId,
    value = false,
    onChange,
    disabled = false,
    ...rest
}) => {
    const intl = useIntl();
    const [enabled, setEnabled] = useState(value);

    const handleOnChange = () => {
        if (!disabled) {
            setEnabled(!enabled);
            onChange(!enabled);
        }
    };

    useEffect(() => {
        setEnabled(value);
    }, [value]);

    return (
        <Container disabled={disabled}>
            <ButtonStyle {...rest} checked={enabled} onChange={handleOnChange} disabled={disabled}>
                <SwitchTip aria-hidden="true" active={enabled} id="a" />
            </ButtonStyle>
            {labelId && <Label onClick={handleOnChange}>{computeText(intl, labelId)}</Label>}
        </Container>
    );
};
