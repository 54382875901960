import React, { ReactNode } from 'react';

import Loader from '@/components/atoms/Loader';
import { assertUnreachable } from '@/utils/typescript';
import {
    ChildrenContainer,
    LoadingContainer,
    OutlinedPrimaryStyledButton,
    PrimaryStyledButton,
    RedStyledButton,
    SecondaryStyledButton,
    TextButton,
} from './Button.style';

export type ButtonType = 'primary' | 'secondary' | 'outlinedPrimary' | 'red' | 'text';

export type ButtonProps = {
    children?: ReactNode;
    buttonType?: ButtonType;
    thick?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    hasBorder?: boolean;
    isLoading?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const Button: React.FunctionComponent<
    React.PropsWithChildren<ButtonProps & Partial<React.ButtonHTMLAttributes<HTMLButtonElement>>>
> = ({ children, buttonType = 'primary', hasBorder = true, isLoading = false, disabled = false, ...otherProps }) => {
    const getButton = () => {
        switch (buttonType) {
            case 'primary':
                return PrimaryStyledButton;
            case 'secondary':
                return SecondaryStyledButton;
            case 'outlinedPrimary':
                return OutlinedPrimaryStyledButton;
            case 'red':
                return RedStyledButton;
            case 'text':
                return TextButton;
            default:
                return assertUnreachable(buttonType);
        }
    };

    const ButtonComponent = getButton();

    return (
        <ButtonComponent type="button" data-testid="button" {...otherProps} disabled={disabled} hasBorder={hasBorder}>
            <ChildrenContainer isLoading={isLoading}>{children}</ChildrenContainer>
            {isLoading && (
                <LoadingContainer>
                    <Loader size={16} />
                </LoadingContainer>
            )}
        </ButtonComponent>
    );
};
