import { Input } from '@/components/atoms/Form/Input';
import { Text } from '@/components/atoms/Text';
import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white100};
    padding: 0;
    box-sizing: border-box;
`;

export const ModalButton = styled.div<{ disabled?: boolean }>`
    user-select: none;

    & > div {
        padding: 12px;
    }

    &:hover {
        background-color: ${({ theme, disabled }) => (disabled ? theme.color.white100 : theme.color.grey300)};
    }
`;

export const ModalInputContainer = styled.div`
    margin-bottom: 16px;
`;

export const ModalInputTitle = styled(Text)`
    margin-bottom: 8px;
    text-transform: uppercase;
`;

export const ModalTextInput = styled(Input)`
    width: auto;
`;

export const ModalButtonLabel = styled(Text)`
    flex: 1;
    margin-left: 8px;
`;

export const ModalPosButton = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: row;
    padding-right: 12px;
    padding: 12px;
    margin-bottom: 32px;
    user-select: none;
    cursor: ${({ disabled }) => (disabled ? 'pointer' : 'initial')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
    background-color: ${({ theme }): string => theme.color.white200};

    &:hover {
        background-color: ${({ theme }): string => theme.color.grey300};
    }
`;

export const UploadModalContainer = styled(Container)`
    max-height: 800px;
    overflow-y: scroll;
`;
