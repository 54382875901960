import styled from '@emotion/styled';

export const Container = styled.div`
    border: 1px solid none;
    flex: 1;
    margin: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[1]};
    font-family: ${({ theme }) => theme.font.family};
    display: flex;
    flex-direction: column;
    align-items: center;
`;
