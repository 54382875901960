import { AuthUser } from '../auth/auth.type';

export type UserBrand = {
    brandId: number;
    name: string;
};
export type UserRestaurant = {
    restaurantId: number;
    name: string;
    brandId: number; // TODO: this type is wrong, it is optional
};
export type User = {
    userId: number;
    email: string;
    role: UserRoles;
    accessFlags: AuthUser['accessFlags'];
    accessToken: string;
    brands: UserBrand[];
    restaurants: UserRestaurant[];
    firstName: string;
    lastName: string;
    phone: string;
    brandId: number | null;
};

export enum UserRoles {
    admin = 'admin',
    brand = 'brand',
    restaurant = 'restaurant',
    brandGroup = 'brandGroup',
}

export type GetUserPayload = { userId: number };
export type UpdateUserPayload = Omit<User, 'accessFlags'> & { accessFlags: Record<string, boolean> };
export type GetBrandsUsersListPayload = { brandId: number; search?: string; orderByLastConnection: boolean };
export type GetBrandsUsersListResponse = {
    firstName: string;
    lastName: string;
    email: string;
    userId: number;
    lastConnection: string;
}[];

export type CreateManagerPayload = {
    badgeNumber: number;
    brandId: number;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    role: string;
    password: string;
    confirmPassword: string;
};
