import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import assert from 'assert';
import { User, UserRoles, UserBrand, UserRestaurant } from '@/services/innovorder/user/user.type';
import { RootState } from '@/redux/store';

export const userSliceName = 'user';

type UserWithGuestIndication = User & {
    isGuest: boolean;
};

export type UserState = UserWithGuestIndication | null;

const initialState: UserState = null;

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>, string>({
    name: userSliceName,
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<UserState>) => {
            return {
                ...action.payload,
                restaurants: action.payload?.restaurants.map((restaurant) => ({
                    ...restaurant,
                    // Fallback to user brandId if restaurant brandId is not defined to respect types
                    brandId: restaurant.brandId ?? state?.brands[0].brandId ?? action.payload?.brands[0].brandId,
                })),
            } as UserState;
        },
        updateUserRestaurants: (state, action: PayloadAction<UserRestaurant[]>) => {
            if (!state) {
                return state;
            }

            return {
                ...state,
                restaurants: action.payload.map((restaurant) => ({
                    ...restaurant,
                    // Fallback to user brandId if restaurant brandId is not defined to respect types
                    brandId: restaurant.brandId ?? state.brands[0].brandId,
                })),
            };
        },
    },
});

export const { updateUser, updateUserRestaurants } = userSlice.actions;

export const getUser = (state: RootState): UserState => state.user as UserWithGuestIndication | null;
export const getUserId = (state: RootState): number | undefined => getUser(state)?.userId;
export const getUserToken = (state: RootState): string | undefined => getUser(state)?.accessToken;
export const getUserRole = (state: RootState): UserRoles | undefined => getUser(state)?.role;
export const getUserBrandId = (state: RootState): number | undefined => getUser(state)?.brands[0].brandId;
export const getAssertedUserBrandId = (state: RootState): number => {
    const brandId = getUserBrandId(state);
    assert(brandId);
    return brandId;
};
export const getUserBrands = (state: RootState): UserBrand[] | undefined => getUser(state)?.brands;
export const getUserBrandIds = (state: RootState): number[] =>
    getUserBrands(state)?.map((brand) => brand.brandId) ?? [];
export const getUserRestaurants = (state: RootState): UserRestaurant[] | undefined => getUser(state)?.restaurants;
