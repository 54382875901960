import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

import { Button } from '@/components/atoms/Button';
import { Badge, ICustomerAttributes } from '@/services/innovorder/customer/customer.types';
import { ButtonContainer } from './BadgeForm.style';
import Table from './Table';
import Modal, { type BadgeFormValues } from './Modal';
import { MODAL_PROPS } from './BadgeForm.utils';

type UpdateCustomerFormProps = {
    badges: Badge[];
    isLoading: boolean;
    errorMessage?: string;
    onSave: (
        badge: BadgeFormValues,
    ) => Promise<{ data: ICustomerAttributes } | { error: FetchBaseQueryError | SerializedError }>;
    onDelete: (badge: Badge) => void;
    onResetErrorMessage: () => void;
};

const UpdateInfoForm: React.FunctionComponent<React.PropsWithChildren<UpdateCustomerFormProps>> = ({
    badges,
    isLoading,
    errorMessage,
    onSave,
    onDelete,
    onResetErrorMessage,
}) => {
    const [badgeForm, setBadgeForm] = useState(MODAL_PROPS.NONE());

    const handleSave = async (data: BadgeFormValues) => {
        const response = await onSave(data);
        if ('data' in response) {
            handleClose();
        }
    };

    const handleToggleBlock = async (data: BadgeFormValues) => {
        onSave({ ...data, isBlocked: !data.isBlocked });
    };

    const handleDelete = async (badge: Badge) => {
        onDelete(badge);
    };

    const handleCreate = () => {
        setBadgeForm(MODAL_PROPS.CREATE(badges.length === 0));
    };

    const handleEdit = (data: Badge) => {
        setBadgeForm(MODAL_PROPS.UPDATE(data));
    };

    const handleClose = () => {
        setBadgeForm(MODAL_PROPS.NONE());
        onResetErrorMessage();
    };

    return (
        <div>
            <ButtonContainer>
                <Button buttonType="primary" onClick={handleCreate}>
                    <FormattedMessage id="customer.student.add.badge" />
                </Button>
            </ButtonContainer>
            <Table badges={badges} onEdit={handleEdit} onBlock={handleToggleBlock} onDelete={handleDelete} />
            <Modal {...badgeForm} onSave={handleSave} onHide={handleClose} isLoading={isLoading} error={errorMessage} />
        </div>
    );
};

export default UpdateInfoForm;
