import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.spacing[4]};
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    gap: ${({ theme }) => theme.spacing[4]};
    h2 {
        margin-bottom: 10px;
    }
`;

export const PermissionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
export const PermissionText = styled.p`
    font-size: ${({ theme }) => theme.font.size.normal};
`;
export const ButtonWrapper = styled.div`
    width: 40px;
`;
