import styled from '@emotion/styled';
import { IntegrationType } from '../Tab';

type CardProps = {
    isFull?: boolean;
    image: string;
};

export const Card = styled.div<CardProps>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
    background-image: ${({ image }) => `url("${image}")`};
    background-size: ${({ isFull }) => `auto ${isFull ? '100%' : '40%'}`};
    background-repeat: no-repeat;
    background-position: ${({ isFull }) => `${isFull ? '0px' : '40px'} center`};
    border: 1px solid ${({ theme }) => theme.color.grey400};
    border-radius: ${({ theme }) => theme.radius.main};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    padding-right: ${({ theme }) => theme.spacing[4]};
`;

export const Message = styled.p<{ type: IntegrationType }>`
    color: ${({ theme, type }) => (type === 'yavin' ? theme.color.grey400 : theme.color.purple100)};
    font-weight: ${({ theme }) => theme.font.weight.regular};
`;

export const Label = styled.td`
    display: flex;
    flex-direction: row;
    svg {
        cursor: pointer;
        margin-right: ${({ theme }) => theme.spacing[4]};
        margin-left: ${({ theme }) => theme.spacing[4]};
    }
`;
