import { useIntl } from 'react-intl';
import * as React from 'react';
import { computeText } from '@/locales/utils';

export const withIntlLabel = <P extends { label?: string }, ElementType extends HTMLElement>(
    Component: React.ComponentType<P>,
) => {
    const newComponent = React.forwardRef<
        ElementType,
        Omit<P, 'label'> & {
            skipIntl?: boolean;
            labelId?: string;
            labelValues?: Record<string, string | Date | number>;
        }
    >(({ skipIntl, labelId, labelValues, ...props }, ref) => {
        const intl = useIntl();
        return (
            <Component
                ref={ref}
                {...(props as unknown as P)}
                label={skipIntl ? labelId : computeText(intl, labelId, labelValues)}
            />
        );
    });
    newComponent.displayName = Component.displayName;

    return newComponent;
};
