import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumn, TableRow, TableCell } from '@/components/atoms/Table/Table.type';
import { fetchDataProps } from '../../components/atoms/Table/Table';
import { Cell } from 'react-table';
import { useCreateManagerMutation, useLazyGetBrandUsersListQuery } from '@/services/innovorder/user/user.endpoints';
import { useRouteMatch, useHistory } from 'react-router';
import { IntlShape, useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { formatDate } from '@/utils/date';
import { useLazyLoginAsGuestQuery, useMeQuery } from '@/services/innovorder/auth/auth.endpoints';
import { cookieLocationGuest, useAuthCookieHandler } from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { useCookies } from 'react-cookie';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { computeCookieDomain, COOKIE_PATH } from '../Login/Login';
import { CreateManagerPayload, GetBrandsUsersListResponse, UserRoles } from '@/services/innovorder/user/user.type';
import { SvyTooltip } from '@/components/savory/SvyTooltip';
import { SvyIconWithMargin } from './BrandsUsersList.style';
import { useSelector } from 'react-redux';
import { getUserBrandId, getUserRole } from '@/redux/user';
import { Routes } from '@/core/router/routes.types';

interface BrandsUsersListViewModel {
    rows: TableRow[];
    columns: TableColumn[];
    isLoading: boolean;
    data: GetBrandsUsersListResponse | undefined;
    url: string;
    isNewUserModalOpen: boolean;
    intl: IntlShape;
    openNewUserModal: () => void;
    closeNewUserModal: () => void;
    handleRowClick: (
        cell: Cell<TableRow, TableCell<unknown>> | undefined,
        event?: React.MouseEvent<HTMLTableCellElement>,
    ) => void;
    fetchData: (fetchProps: fetchDataProps) => void;
}

const useBrandsUsersListVM = (): BrandsUsersListViewModel => {
    const intl = useIntl();
    const history = useHistory();
    const { params, url } = useRouteMatch<{ brandId: string }>();
    const [, setCookie] = useCookies();

    const userRole = useSelector(getUserRole);
    const currentUserBrandId = useSelector(getUserBrandId);

    const [getBrandsList, { data, isLoading }] = useLazyGetBrandUsersListQuery();
    const [loginAsGuest, { isSuccess: loggedInAsGuest }] = useLazyLoginAsGuestQuery();
    const { token, isGuest } = useAuthCookieHandler();

    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState<boolean>(false);
    const openNewUserModal = () => {
        setIsNewUserModalOpen(true);
    };

    const closeNewUserModal = () => {
        setIsNewUserModalOpen(false);
    };

    const { isFetching: isMeQueryFetching } = useMeQuery({ token }, { refetchOnMountOrArgChange: true });

    const rows: TableRow[] = useMemo(() => {
        if (!data) return [];
        return data.map((user) => ({
            lastName: {
                key: user.userId,
                type: 'string',
                value: {
                    text: user.lastName,
                },
            },
            firstName: {
                type: 'string',
                value: {
                    text: user.firstName,
                },
            },
            email: {
                type: 'string',
                value: {
                    text: user.email,
                },
            },
            lastConnection: {
                type: 'string',
                value: {
                    text: formatDate(user.lastConnection),
                },
            },

            ...(userRole === UserRoles.admin
                ? {
                      login: {
                          type: 'string',
                          value: {
                              text: (
                                  <SvyTooltip
                                      key={user.userId}
                                      tooltipContent={computeText(intl, 'brandUsers.loginAs') + ` ${user.email}`}
                                  >
                                      <SvyIconWithMargin
                                          onClick={(ev) => {
                                              ev.stopPropagation();
                                              loginAsGuestHandler(user.userId);
                                          }}
                                          icon="account-circle-fill"
                                      />
                                  </SvyTooltip>
                              ),
                          },
                      },
                  }
                : {}),
        }));
    }, [data, intl, userRole]);
    const columns: TableColumn[] = useMemo(() => {
        const columnArray = [
            {
                Header: computeText(intl, 'name.lastName'),
                accessor: 'lastName',
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: computeText(intl, 'name.firstName'),
                accessor: 'firstName',
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: computeText(intl, 'brandUsers.email'),
                accessor: 'email',
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: computeText(intl, 'brandUsers.lastConnection'),
                accessor: 'lastConnection',
                disableFilters: true,
                disableSortBy: true,
            },
        ];
        if (userRole === UserRoles.admin) {
            columnArray.push({
                Header: computeText(intl, 'brandUsers.login'),
                accessor: 'login',
                disableFilters: true,
                disableSortBy: true,
            });
        }
        return columnArray;
    }, [intl, userRole]);

    const loginAsGuestHandler = useCallback(
        async (userId: number) => {
            const { accessToken } = await loginAsGuest({ userId }).unwrap();
            setCookie(
                cookieLocationGuest,
                { accessToken },
                { domain: computeCookieDomain(window.location.host), path: COOKIE_PATH },
            );
        },
        [setCookie],
    );

    const handleRowClick = useCallback(
        (cell: Cell<TableRow, TableCell<unknown>> | undefined, event?: React.MouseEvent<HTMLTableCellElement>) => {
            if (!cell) {
                return;
            }
            const url =
                userRole === UserRoles.admin
                    ? `/home/brands/${params.brandId}/users/${cell?.row.values.lastName.key}/info`
                    : `/home/brand/users/${cell?.row.values.lastName.key}/info`;

            redirectToBackofficeV1(url, event?.ctrlKey);
        },
        [userRole],
    );
    const fetchData = useCallback(({ globalFilter }: fetchDataProps) => {
        getBrandsList({ brandId: Number(params.brandId), search: globalFilter, orderByLastConnection: true });
    }, []);

    useEffect(() => {
        if (loggedInAsGuest && isGuest && !isMeQueryFetching) {
            redirectToBackofficeV1('/home');
        }
    }, [isMeQueryFetching, loggedInAsGuest, isGuest]);

    useEffect(() => {
        if (userRole !== UserRoles.admin && currentUserBrandId !== Number(params.brandId)) {
            history.replace(Routes.PermissionDenied);
        }
    }, [currentUserBrandId]);
    return {
        rows,
        columns,
        data,
        url,
        handleRowClick,
        isNewUserModalOpen,
        openNewUserModal,
        closeNewUserModal,
        fetchData,
        isLoading,
        intl,
    };
};

export default useBrandsUsersListVM;
