import styled from '@emotion/styled';

export const Container = styled.div`
    background-color: ${({ theme }) => theme.color.white100};
    border-top: 1px solid ${({ theme }) => theme.color.grey200};
    padding: 10px;
`;

export const Content = styled.div`
    min-height: 40px;
    width: 250px;
`;

export const InputContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export const IconContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InputContent = styled.div`
    position: absolute;
    top: 0;
    left: 40px;
    height: 100%;
    width: 210px;
    display: flex;
    justify-content: start;
    align-items: center;
`;

export const OptionContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;
