import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Cell, Column } from 'react-table';
import { computeText } from '@/locales/utils';
import type { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import {
    useDeletePosAccessGroupMutation,
    useGetPosAccessGroupsQuery,
} from '@/services/innovorder/pos_access/pos_access_group/pos_access_group.endpoints';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';

type ParamType = {
    brandId: string;
    restaurantId: string;
};

export type MenuGroupsViewModel = {
    headers: Column<TableRow>[];
    rows: TableRow[];
    brandId?: number;
    restaurantId?: number;
    isGetGroupsLoading: boolean;
    isLoadingDelete: boolean;
    idToDelete: number | null;
    handleOnRowClick: (
        cell: Cell<TableRow, TableCell<unknown>> | undefined,
        event?: React.MouseEvent<HTMLTableCellElement>,
    ) => void;
    handleOnDeleteClick: () => void;
    handleCloseModal: () => void;
};

export function useGroupsTableVM(): MenuGroupsViewModel {
    const intl = useIntl();
    const history = useHistory();
    const { brandId, restaurantId } = useParams<ParamType>();
    const { data: groups = [], isLoading: isGetGroupsLoading } = useGetPosAccessGroupsQuery({
        restaurantId: Number(restaurantId),
    });
    const [deletePosAccessGroup, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete }] =
        useDeletePosAccessGroupMutation();
    const handleOnEditClick = React.useCallback(
        (groupId: number) => {
            history.push(`/brands/${brandId}/restaurants/${restaurantId}/channels/pos/access/groups/edit/${groupId}`);
        },
        [brandId, history, restaurantId],
    );

    const [idToDelete, setIdToDelete] = React.useState<number | null>(null);

    const handleOnDeleteClick = useCallback(() => {
        idToDelete && deletePosAccessGroup({ restaurantId: +restaurantId, posGroupId: idToDelete });
    }, [deletePosAccessGroup, restaurantId, idToDelete]);

    const handleOnRowClick = useCallback((cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
        if (cell && cell.column.id !== 'action') cell.row.values.action.value.items[0].onClick();
    }, []);

    const handleCloseModal = () => {
        setIdToDelete(null);
    };

    useEffect(() => {
        if (isSuccessDelete) {
            setIdToDelete(null);
        }
    }, [isSuccessDelete]);
    const headers = React.useMemo(
        () => [
            {
                Header: computeText(intl, 'name.name'),
                accessor: 'name',
                width: '15%',
            },
            {
                Header: computeText(intl, 'assiciated.devices'),
                accessor: 'devices',
                width: '25%',
            },
            {
                Header: computeText(intl, 'assiciated.operators'),
                accessor: 'users',
                width: '50%',
            },
            {
                Header: computeText(intl, 'action.action'),
                accessor: 'action',
                width: '10%',
                disableSortBy: true,
            },
        ],
        [intl],
    );
    const buildRowDropdownActions = (groupId: number, isDefaultGroup: boolean) => [
        {
            value: 1,
            label: computeText(intl, 'action.edit'),
            onClick: () => handleOnEditClick(groupId),
        },
        ...(!isDefaultGroup
            ? [
                  {
                      value: 2,
                      label: computeText(intl, 'action.delete'),
                      onClick: () => setIdToDelete(groupId),
                  },
              ]
            : []),
    ];

    const rows: TableRow[] = React.useMemo(() => {
        if (isGetGroupsLoading) {
            return [];
        }
        return [...(groups ?? [])].map((group) => {
            return {
                name: {
                    type: 'string',
                    value: {
                        text: group.name,
                    },
                },
                devices: {
                    type: 'string',
                    value: {
                        text: `${group.posDevices.map((device) => device.deviceName).join(', ')}`,
                    },
                },
                users: {
                    type: 'string',
                    value: {
                        text: `${group.posUsers.map((user) => user.name).join(', ')}`,
                    },
                },

                action: {
                    type: 'dropdownText',
                    value: {
                        button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                        align: 'end',
                        withPortal: true,
                        items: buildRowDropdownActions(group.posAccessGroupId, group.isDefault),
                        type: 'text',
                    },
                },
            };
        });
    }, [groups, handleOnDeleteClick, handleOnEditClick, intl, isGetGroupsLoading]);

    return {
        headers,
        rows,
        brandId: +brandId,
        restaurantId: +restaurantId,
        isGetGroupsLoading: false,
        isLoadingDelete,
        idToDelete,
        handleOnRowClick,
        handleOnDeleteClick,
        handleCloseModal,
    };
}
