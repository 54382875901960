import { Text } from '@/components/atoms/Text';
import { Input } from '@/components/atoms/Form/Input';
import styled from '@emotion/styled';
import Select from '@/components/atoms/Select';
import { Title } from '@/components/atoms/Title';

export const ModalConfigurationContainer = styled.div`
    margin: ${({ theme }) => theme.savory.spacing[3]};
`;

export const ModalInputFullContainer = styled.div`
    grid-column: 1/3;
    margin-bottom: ${({ theme }) => theme.savory.spacing[4]};
`;

export const ModalInputTitle = styled(Text)`
    font-weight: 400;
    ${({ theme }) => theme.savory.typography['Body/Regular/Text3']};
    line-height: 20px;
    color: ${({ theme }) => theme.color.neutral900};
`;

export const ModalTitleContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.savory.spacing[1]};
`;

export const ModalTextInput = styled(Input)`
    width: auto;
`;

export const LegalEntitySelect = styled(Select<number>)`
    padding-top: ${({ theme }) => theme.savory.spacing[1]};
    padding-right: ${({ theme }) => theme.savory.spacing[2]};
    padding-bottom: ${({ theme }) => theme.savory.spacing[1]};
    padding-left: ${({ theme }) => theme.savory.spacing[2]};
    border-radius: ${({ theme }) => theme.radius.main};
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.savory.spacing[4]};
    margin-bottom: ${({ theme }) => theme.savory.spacing[4]};
`;

export const SelectEntityStepTitle = styled(Title)`
    ${({ theme }) => theme.savory.typography.Title5};
`;

export const Subtitle = styled(Text)`
    ${({ theme }) => theme.savory.typography['Body/Regular/Text3']};
    margin-top: -${({ theme }) => theme.savory.spacing[3]};
`;
