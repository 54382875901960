import React from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Button } from '@/components/atoms/Button';
import { computeText } from '@/locales/utils';
import { TabProps } from './Tab';
import { StyledTab, StyledTabList } from './TabList.style';

type RoutedTabListProps = {
    list: TabProps[];
};

const RoutedTabList: React.FunctionComponent<React.PropsWithChildren<RoutedTabListProps>> = ({ list }) => {
    const { pathname } = useLocation();
    const intl = useIntl();

    return (
        <StyledTabList>
            {list.map(({ title, route }) => (
                <StyledTab key={title}>
                    {route && route.resolvedUri && (
                        <a href={route.resolvedUri}>
                            <Button buttonType="secondary" hasBorder={false}>
                                {computeText(intl, title)}
                            </Button>
                        </a>
                    )}
                    {route && route.resolvedPath && (
                        <Link to={route.resolvedPath}>
                            <Button
                                buttonType={
                                    matchPath(pathname, {
                                        path: route.resolvedPath,
                                        exact: route.exact,
                                    })
                                        ? 'primary'
                                        : 'secondary'
                                }
                                hasBorder={false}
                            >
                                {computeText(intl, title)}
                            </Button>
                        </Link>
                    )}
                </StyledTab>
            ))}
        </StyledTabList>
    );
};

export default RoutedTabList;
