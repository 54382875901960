import { SvyButton } from '@/components/savory/SvyButton';
import styled from '@emotion/styled';

export const HelpContainer = styled.div<{ isSidebarCollapsed: boolean }>`
    padding: ${({ theme, isSidebarCollapsed }) => (isSidebarCollapsed ? 0 : theme.spacing[2])};
    button {
        padding: 0;
    }
`;

export const StyledSvyButton = styled(SvyButton)`
    width: 100%;

    &:not(:disabled) {
        background-color: ${({ theme }) => theme.color.io.primary50};
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

export const Area = styled.div`
    margin-bottom: 40px;
`;
