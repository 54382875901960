import { useRouteMatch } from 'react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    useLazyGetRestaurantChannelsQuery,
    useLazyGetBrandChannelsQuery,
    useLazyGetRestaurantKdsQuery,
} from '@/services/innovorder/channel/channel.endpoints';
import { GetChannelsResponse } from '@/services/innovorder/channel/channel.type';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon.interface';
import useChannels from '@/hooks/useChannels/useChannels';

type ChannelListViewModel = {
    restaurantId?: number;
    channelsData: ChannelData[];
};
export type ChannelData = {
    channelId: number;
    isInstalled: boolean;
    nom: string;
    description: string;
    icon: SVGIcons;
    shouldDisplay: boolean;
    shouldAllowConfig: boolean;
    redirectToConfigPage: (event: React.MouseEvent<HTMLElement>) => void;
    overrideActivateAction?: () => void;
    overrideDeactivateAction?: () => void;
};

const KDS_FOUND_RESPONSE_CODE = 'kitchen_display_found';

const useChannelListVM = (): ChannelListViewModel => {
    const { params } = useRouteMatch<{ brandId: string; restaurantId: string }>();

    const ChannelsInformation = useChannels();

    const brandId = Number.parseInt(params.brandId, 10);
    const restaurantId = params.restaurantId?.length ? Number.parseInt(params.restaurantId, 10) : undefined;
    const [hasKds, setHasKds] = useState<boolean>(false);

    const [getRestaurantChannels, { data: restaurantChannels }] = useLazyGetRestaurantChannelsQuery();
    const [getBrandChannels, { data: brandChannels }] = useLazyGetBrandChannelsQuery();
    const [getRestaurantKds, { data: kdsData }] = useLazyGetRestaurantKdsQuery();

    useEffect(() => {
        getBrandChannels({ brandId: String(brandId) });
        if (restaurantId) {
            getRestaurantKds({ restaurantId: String(restaurantId) });
            getRestaurantChannels({ restaurantId: String(restaurantId) });
        }
    }, [restaurantId]);

    useEffect(() => {
        if (kdsData && kdsData.code === KDS_FOUND_RESPONSE_CODE) {
            setHasKds(true);
        } else {
            setHasKds(false);
        }
    }, [kdsData]);

    const mapReceivedChannels = useCallback(
        (channels: GetChannelsResponse): ChannelData[] => {
            return Object.entries(ChannelsInformation).map(([channelId]) => {
                const fullChannelsInfo = ChannelsInformation[channelId as unknown as number];
                return {
                    channelId: Number(channelId),
                    ...fullChannelsInfo,
                    shouldDisplay:
                        !restaurantId || fullChannelsInfo.nom === 'channels.kds.name'
                            ? fullChannelsInfo.shouldDisplay
                            : Object.keys(brandChannels || []).includes(channelId) && fullChannelsInfo.shouldDisplay,
                    isInstalled:
                        fullChannelsInfo.nom === 'channels.kds.name'
                            ? hasKds
                            : Object.keys(channels).includes(channelId),
                };
            });
        },
        [hasKds, brandChannels],
    );

    const channelsData: ChannelData[] = useMemo(() => {
        if (restaurantId) {
            return mapReceivedChannels(restaurantChannels || []);
        }

        return mapReceivedChannels(brandChannels || []);
    }, [restaurantChannels, brandChannels, hasKds]);

    return {
        restaurantId,
        channelsData,
    };
};

export default useChannelListVM;
