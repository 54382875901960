import React, { useState } from 'react';
import { SavedReportFilter } from '@/services/innovorder/report/report.type';
import MultiSelect, { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { useIntl } from 'react-intl';
import DeleteModal from '@/components/atoms/DeleteModal';
import { getOption } from './Option';

interface SavedFiltersDropdownProps {
    savedFilters: SavedReportFilter[];
    selectedSavedFilter: SavedReportFilter | null;
    onSelectSavedReportFilter: (filterId?: number) => void;
    onDeleteReportFilter: (filterId: number) => void;
}

const SavedFiltersDropdown: React.FunctionComponent<SavedFiltersDropdownProps> = ({
    savedFilters,
    selectedSavedFilter,
    onSelectSavedReportFilter,
    onDeleteReportFilter,
}) => {
    const intl = useIntl();
    const [filterIdToDelete, setFilterIdToDelete] = useState<number | null>(null);
    const handleShowModal = (filterId: number): void => {
        setFilterIdToDelete(filterId);
    };
    const handleCloseModal = (): void => setFilterIdToDelete(null);
    const handleConfirmDelete = (): void => {
        filterIdToDelete && onDeleteReportFilter(filterIdToDelete);
        handleCloseModal();
    };

    const onChange = (newSelectedValue: unknown) => {
        const newValue = newSelectedValue as SelectOptionInterface<number> | undefined;
        onSelectSavedReportFilter(newValue?.value);
    };

    const mapFilterToValue = (filter: SavedReportFilter | null) => {
        if (!filter) return [];
        return [{ value: filter.reportFilterId, label: filter.name }];
    };

    return (
        <>
            <MultiSelect<number>
                isMulti={false}
                value={mapFilterToValue(selectedSavedFilter)}
                placeholder={intl.formatMessage({ id: 'report.savedFiltersDropdown.placeholder' })}
                onChange={onChange}
                options={savedFilters.map(({ reportFilterId, name }) => ({ value: reportFilterId, label: name }))}
                isClearable
                components={{ Option: getOption(handleShowModal) }}
            />
            <DeleteModal
                title="report.savedFiltersDropdown.deleteModal.title"
                description="report.savedFiltersDropdown.deleteModal.description"
                isOpen={!!filterIdToDelete}
                onHide={handleCloseModal}
                onSubmit={handleConfirmDelete}
            />
        </>
    );
};

export default SavedFiltersDropdown;
