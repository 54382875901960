import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    GetReportGenerationsPayload,
    GetReportGenerationUrlPayload,
    ReportGeneration,
    ReportGenerationUrl,
} from './reportGeneration.type';

const reportGenerationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getReportGenerations: build.query<ReportGeneration[], ApiRequest<GetReportGenerationsPayload>>({
            query: ({ token }) => ({
                url: '/report_generation',
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.ReportGeneration],
            transformResponse: (response: ApiResponse<ReportGeneration[]>) =>
                response.data.sort((a, b) => b.reportGenerationId - a.reportGenerationId),
            extraOptions: { maxRetries: 3 },
        }),
        getFileSignedUrl: build.mutation<ReportGenerationUrl, ApiRequest<GetReportGenerationUrlPayload>>({
            query: ({ token, reportGenerationId }) => ({
                url: `/report_generation/${reportGenerationId}/signed-url`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.ReportGeneration],
            transformResponse: (response: ApiResponse<ReportGenerationUrl>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetReportGenerationsQuery, useGetFileSignedUrlMutation } = reportGenerationApi;
