import { Theme as ThemeType } from '@emotion/react';
import { SAVORY_THEME } from '@innovorder/savory';

const family = "'Roboto', sans-serif";

export const theme: ThemeType = {
    savory: SAVORY_THEME,
    color: {
        primary: '#F96D6D',
        slate100: '#21232F',
        slate200: '#2A2D39',
        slate300: '#313443',
        black: '#333333',
        black300: '#999999',
        black200: '#666666',
        black400: '#4b4646',
        white100: '#FFFFFF',
        white200: '#FBFBFB',
        grey100: '#F6F6F6',
        grey200: '#ECECEC',
        grey300: '#F3F3F3',
        grey400: '#CBCBCB',
        grey500: '#B7B7B7',
        palePink: '#FFD5D5',
        palePink100: '#feebeb',
        emerald: '#3EC978',
        bloodyMary: '#F45959',
        mint: '#7EB4AF',
        electricBlue: '#2880EB',
        teal100: '#00CCCC',
        lightBlue100: '#4FC3F7',
        blue100: '#99C2FF',
        blue200: '#66A3FF',
        purple100: '#AD93FB',
        purple200: '#AB47BC',
        pink100: '#FF9EFA',
        pink200: '#FF6BF8',
        red100: '#FFA8B7',
        red200: '#FF758C',
        red300: '#EF5350',
        orange100: '#FFB999',
        orange200: '#FF9D70',
        yellow200: '#FDD835',
        green100: '#10EF99',
        green200: '#4CAF50',
        neutral500: '#A4A4A4',
        neutral900: '#1A1A1A',
        io: {
            opacity20: {
                red200: '#FCEEF0',
                green200: '#E4F1E7',
            },
            opacity50: {
                red200: '#EEA9B4',
                green200: '#A5CBAE',
            },
            primary50: '#F2F7FF',
            destructive100: '#FEE1E1',
            destructive400: '#FD6B6B',
            destructive500: '#EF4343',
            destructive900: '#811D1D',
            green100: '#DEFCE9',
            green500: '#1AC057',
            green900: '#114C29',
            orange100: '#FFEDD6',
            orange500: '#F67416',
            warning100: '#FEF9C3',
            warning500: '#E7B008',
        },
    },
    radius: {
        main: '4px',
    },
    spacing: {
        1: '5px',
        2: '10px',
        3: '15px',
        4: '20px',
        5: '25px',
        6: '30px',
        7: '35px',
        8: '40px',
        9: '45px',
        10: '50px',
    },
    shadow: {
        legacy: '0px 2px 5px rgba(51, 51, 51, 0.05)',
        outside: {
            100: '0.0px 2.0px 8.0px 0px rgba(0, 0, 0, 0.04)',
            200: '0.0px 4.0px 16.0px 0px rgba(0, 0, 0, 0.08)',
            300: '0.0px 8.0px 32.0px 0px rgba(0, 0, 0, 0.16)',
        },
        inside: {
            100: '0.0px 0.0px 8.0px 0px rgba(0, 0, 0, 0.04) inset',
            200: '0.0px 0.0px 16.0px 0px rgba(0, 0, 0, 0.08) inset',
            300: '0.0px 0.0px 32.0px 0px rgba(0, 0, 0, 0.16) inset',
        },
    },
    font: {
        family,

        text: {
            bold: `
                font-family: ${family};
                font-weight: 700;
                font-size: 12px;
            `,
            regular: `
                font-family: ${family};
                font-weight: 400;
                font-size: 14px;
            `,
        },
        caption: {
            bold: `
                font-family: ${family};
                font-weight: 700;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 1px;
            `,
            regular: `
                font-family: ${family};
                font-weight: 400;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 1px;
            `,
        },
        size: {
            huge: '24px',
            large: '20px',
            medium: '16px',
            normal: '14px',
            small: '12px',
            tiny: '10px',
        },
        weight: {
            light: 300,
            regular: 400,
            medium: 500,
            bold: 700,
        },
    },
    opacity: {
        disabled: 0.4,
        visible: 1,
    },
    breakpoints: {
        mobile: '@media (max-width : 480px)',
        tablet: '@media (max-width : 600px)',
        tabletLandscape: '@media (max-width : 768px)',
        desktop: '@media (min-width : 1024px)',
        sidebar: '240px',
    },
};

export const mobileTheme: ThemeType = {
    ...theme,
    font: {
        ...theme.font,
        size: {
            ...theme.font.size,
            normal: '16px',
            large: '24px',
        },
    },
};
