/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Theme } from '@emotion/react';
import { IntlShape } from 'react-intl';
import { Filters, SortingRule } from 'react-table';
import { TableColumn, TableRow } from '@/components/atoms/Table/Table.type';
import { filterTypes } from '@/components/atoms/Table/Table.utils';
import { computeCurrency, computeText } from '@/locales/utils';
import { formatDate } from '@/utils/date';
import { assertUnreachable } from '@/utils/typescript';
import {
    GetTransfersFilters,
    GetTransfersSort,
    TransferResponse,
    TransferStatus,
} from '@/services/innovorder/io-pay/transfers/transfer.type';
import { SvyTooltip } from '@/components/savory/SvyTooltip';
import { SvyIcon } from '@/components/savory/SvyIcon';

export const convertTableFiltersToQueryFilters = (columnFilters?: Filters<TableRow>): GetTransfersFilters => {
    if (!columnFilters?.length) return {};
    const filters = columnFilters.reduce((acc: GetTransfersFilters, column) => {
        const value = Array.isArray(column.value) ? column.value.join() : column.value;

        switch (column.id) {
            case 'status':
            case 'ref':
                acc[column.id] = value;
                return acc;
            case 'amount':
                acc.min_amount = value.from;
                acc.max_amount = value.to;
                return acc;
            case 'executedAt':
                acc.executed_from = value.startDate;
                acc.executed_to = value.endDate;
                return acc;
            default:
                throw new Error(`Filter column not managed: ${column.id}`);
        }
    }, {});
    return filters;
};

export const convertTableSortToQuerySort = (sortBy?: SortingRule<TableRow>[]): GetTransfersSort[] => {
    if (!sortBy?.length) return [];

    const sortOrder = sortBy
        .map((sort) => {
            if (sort.id === 'executedAt') return sort.desc ? '-executed_at' : 'executed_at';
            if (sort.id === 'amount') return sort.desc ? '-amount' : 'amount';
            if (sort.id === 'transactionCount') return sort.desc ? '-transactionCount' : 'transactionCount';
            return false;
        })
        .filter((sort) => sort) as GetTransfersSort[];
    return sortOrder;
};

export const getTransferStatusColor = (status: TransferStatus, theme: Theme): string => {
    if (status === TransferStatus.SUCCESS) return theme.color.electricBlue;
    if (status === TransferStatus.SENT_OUT) return theme.color.emerald;
    if (status === TransferStatus.FAILED) return theme.color.bloodyMary;
    if (status === TransferStatus.PENDING) return theme.color.mint;
    return assertUnreachable(status);
};

export const getTransfersTableColumns = (intl: IntlShape): readonly TableColumn[] => [
    {
        Header: computeText(intl, 'transfer.field.id'),
        accessor: 'id',
        minWidth: 100,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'transfer.field.ref'),
        accessor: 'ref',
        minWidth: 100,
        disableFilters: false,
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'transfer.field.configurationName'),
        accessor: 'configurationName',
        minWidth: 100,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: () => (
            <>
                {computeText(intl, 'transfer.field.amount')}
                <SvyTooltip tooltipContent={computeText(intl, 'transaction.field.amount.tooltip')}>
                    <SvyIcon icon="information-line" />
                </SvyTooltip>
            </>
        ),
        label: computeText(intl, 'transfer.field.amount'),
        accessor: 'amount',
        minWidth: 100,
        filter: filterTypes.number,
        disableSortBy: false,
    },
    {
        Header: computeText(intl, 'transfer.field.transactionCount'),
        accessor: 'transactionCount',
        minWidth: 100,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'transfer.field.status'),
        accessor: 'status',
        minWidth: 100,
        disableFilters: false,
        disableSortBy: true,
        filter: filterTypes.list,
        // @ts-ignore
        Filter: [
            { value: TransferStatus.PENDING, label: computeText(intl, 'transfer.status.PENDING') },
            { value: TransferStatus.SUCCESS, label: computeText(intl, 'transfer.status.SUCCESS') },
            { value: TransferStatus.SENT_OUT, label: computeText(intl, 'transfer.status.SENT_OUT') },
            { value: TransferStatus.FAILED, label: computeText(intl, 'transfer.status.FAILED') },
        ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
        Header: computeText(intl, 'transfer.field.executedAt'),
        accessor: 'executedAt',
        minWidth: 100,
        filter: filterTypes.datePicker,
        disableSortBy: false,
    },
];

export const getTransfersTableData = (
    transfers: TransferResponse[] | undefined,
    intl: IntlShape,
    theme: Theme,
): TableRow[] => {
    return !transfers
        ? []
        : transfers.map((transfer) => {
              const { configuration } = transfer;
              return {
                  id: {
                      type: 'number',
                      value: transfer.id,
                  },
                  ref: {
                      type: 'string',
                      value: {
                          isTruncated: true,
                          text: transfer.reference,
                      },
                  },
                  configurationName: {
                      type: 'string',
                      value: {
                          text: `${configuration?.name ?? ''}`,
                      },
                  },
                  amount: {
                      type: 'string',
                      value: {
                          text: computeCurrency({ intl, amount: transfer.amount }),
                      },
                  },
                  transactionCount: {
                      type: 'number',
                      value: transfer.transactions.length,
                  },
                  status: {
                      type: 'badge',
                      value: {
                          text: `transfer.status.${TransferStatus[transfer.status]}`,
                          backgroundColor: getTransferStatusColor(transfer.status, theme),
                      },
                  },
                  executedAt: {
                      type: 'string',
                      value: {
                          text: formatDate(transfer.executedAt, 'DD/MM/YYYY HH:mm'),
                      },
                  },
                  linkTo: {
                      type: 'linkTo',
                      value: { transferId: String(transfer.id) },
                  },
              };
          });
};
