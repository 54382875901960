import React from 'react';
import { useIntl } from 'react-intl';

import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Text } from '@/components/atoms/Text';
import { computeText } from '@/locales/utils';
import TextFieldInput from '@/pages/Customer/CustomerFormInputs/TextFieldInput';
import PricingConfigurationSection from '@/pages/Customer/PricingConfigurationSection';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { UpdateCustomerType } from '@/services/innovorder/customer/customer.types';
import { Flex, FlexContainer, SectionTitle, StyledSelectionContainer } from './UpdateCustomerForm.style';
import { ActiveStudentEntities } from '@/services/innovorder/brand/brand.type';

type UpdateCustomerFormProps = {
    guestGroups: GuestGroupWithGuestsCount[];
    customerForm: UseFormReturn<UpdateCustomerType>;
    activeStudentEntities?: ActiveStudentEntities;
};

const UpdateCustomerForm: React.FunctionComponent<React.PropsWithChildren<UpdateCustomerFormProps>> = ({
    guestGroups,
    customerForm,
    activeStudentEntities,
}) => {
    const intl = useIntl();

    return (
        <FormProvider {...customerForm}>
            <FlexContainer>
                <SectionTitle>
                    <Text text={'customer.form.clientInfoSection'} weight="bold" />
                </SectionTitle>

                <Flex>
                    <TextFieldInput
                        rules={{
                            required: computeText(intl, 'field.required'),
                        }}
                        fieldName={'badgeNumber'}
                    />
                    <TextFieldInput fieldName={'tariffCode'} />
                </Flex>
                <Flex>
                    <TextFieldInput fieldName={'studentNumber'} />
                </Flex>
                <StyledSelectionContainer>
                    <PricingConfigurationSection
                        guestGroups={guestGroups}
                        activeStudentEntities={activeStudentEntities}
                    />
                </StyledSelectionContainer>
            </FlexContainer>
        </FormProvider>
    );
};

export default UpdateCustomerForm;
