import { generateQueryStringFromParams } from '@/utils/history';
import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    PostMenusRevaluationsPayload,
    GetRevaluatedMenusResponse,
    GetMenusPayload,
    PostDuplicateMenuByIdResponse,
    PostDuplicateMenuByIdPayload,
    DeleteMenuByIdPayload,
    PostCopyMenuByIdResponse,
    PostCopyMenuByIdPayload,
    GetExportMenuByIdPayload,
    PutMenuByIdPayload,
    PutMenuByIdResponse,
    PostMenuResponse,
    PostMenuPayload,
    PostImportMenuFromExcelPayload,
    PostImportMenuFromExcelResponse,
    GetMenuOperationsPayload,
    GetMenuOperationsResponse,
    GetMenusResponse,
    GetMenuHashMapPayload,
    GetMenuHashMapResponse,
    PostPublishMenuOnChannelPayload,
    PostPublishMenuOnChannelResponse,
    GetMenuByIdPayload,
    GetMenuByIdResponse,
    CopyProductsToRestaurantsPayload,
    CopyProductsToRestaurantsResponse,
    ScanMenuPayload,
    ScanMenuResponse,
} from './menu.type';

const menuApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        postMenusRevaluations: build.mutation<GetRevaluatedMenusResponse, ApiRequest<PostMenusRevaluationsPayload>>({
            query: ({ menuIds, revaluationRate, useOptions, useExceedings, token }) => ({
                url: `/menus/pricing_revaluation`,
                method: 'POST',
                body: {
                    menuIds,
                    revaluationRate,
                    useOptions,
                    useExceedings,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<GetRevaluatedMenusResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        postDuplicateMenuById: build.mutation<PostDuplicateMenuByIdResponse, ApiRequest<PostDuplicateMenuByIdPayload>>({
            query: ({ menuId, token, ...body }) => ({
                url: `/menus/${menuId}/duplicate`,
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body,
            }),
            invalidatesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<PostDuplicateMenuByIdResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getMenus: build.query<GetMenusResponse, GetMenusPayload>({
            query: ({ restaurantId, brandId }) => {
                const queryParameters = generateQueryStringFromParams({ restaurantId, brandId });

                return {
                    url: `/v2/menus?${queryParameters}`,
                    method: 'GET',
                };
            },
            providesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<GetMenusResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getMenuHashMap: build.query<GetMenuHashMapResponse, ApiRequest<GetMenuHashMapPayload>>({
            query: ({ menuId, token }) => {
                return {
                    url: `/menus/${menuId}/hash_map?channel=pos`,
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            providesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<GetMenuHashMapResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getMenuById: build.query<GetMenuByIdResponse, GetMenuByIdPayload>({
            query: ({ menuId }) => {
                return {
                    url: `/menus/${menuId}`,
                    method: 'GET',
                };
            },
            providesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<GetMenuByIdResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getMenuOperations: build.mutation<GetMenuOperationsResponse, ApiRequest<GetMenuOperationsPayload>>({
            query: ({ brandId, restaurantId, limit, offset, token }) => ({
                url: `/v2/menus/operations?brandId=${brandId}&restaurantId=${restaurantId}&limit=${limit}&offset=${offset}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetMenuOperationsResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        postMenu: build.mutation<PostMenuResponse, ApiRequest<PostMenuPayload>>({
            query: ({ token, ...body }) => ({
                url: `/v2/menus`,
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body,
            }),
            invalidatesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<PostMenuResponse>) => response.data,
        }),
        putMenuById: build.mutation<PutMenuByIdResponse, ApiRequest<PutMenuByIdPayload>>({
            query: ({ menuId, token, ...body }) => ({
                url: `/v2/menus/${menuId}`,
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body,
            }),
            invalidatesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<PutMenuByIdResponse>) => response.data,
        }),
        deleteMenuById: build.mutation<void, ApiRequest<DeleteMenuByIdPayload>>({
            query: ({ menuId, token }) => ({
                url: `/menus/${menuId}`,
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<void>) => response.data,
        }),
        postCopyMenuById: build.mutation<PostCopyMenuByIdResponse, ApiRequest<PostCopyMenuByIdPayload>>({
            query: ({ menuId, restaurantList, name, token }) => ({
                url: `/menus/${menuId}/duplicate`,
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body: {
                    menuId,
                    restaurantList,
                    name,
                },
            }),
            invalidatesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<PostCopyMenuByIdResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getMenuExportById: build.mutation<string, ApiRequest<GetExportMenuByIdPayload>>({
            query: ({ menuId, token }) => ({
                url: `/menus/${menuId}/export`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<string>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        importMenuFromExcel: build.mutation<
            PostImportMenuFromExcelResponse,
            ApiRequest<PostImportMenuFromExcelPayload>
        >({
            query: ({ restaurantId, brandId, name, formData }) => {
                const queryParameters = generateQueryStringFromParams({ restaurantId, name, brandId });
                return {
                    url: `/menus/import?${queryParameters}`,
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: [ApiTagTypes.Menu],
            transformResponse: (response: ApiResponse<PostImportMenuFromExcelResponse>) => response.data,
        }),
        scanMenu: build.mutation<ScanMenuResponse, ApiRequest<ScanMenuPayload>>({
            query: ({ formData }) => {
                return {
                    url: '/menus/ai_scan',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        publishMenuOnChannel: build.mutation<
            PostPublishMenuOnChannelResponse,
            ApiRequest<PostPublishMenuOnChannelPayload>
        >({
            query: ({ menuId, channelId, token }) => ({
                url: `/v2/menus/${menuId}/channels/${channelId}/publish`,
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<PostPublishMenuOnChannelResponse>) => response.data,
        }),
        copyProductsToRestaurants: build.mutation<CopyProductsToRestaurantsResponse, CopyProductsToRestaurantsPayload>({
            query: ({ menuChannels, productIds, restaurantIds, publishAfterCopy, updateIgnoreAttributes }) => {
                return {
                    url: '/menus/copy_product',
                    method: 'POST',
                    body: {
                        menuChannels,
                        productIds,
                        restaurantIds,
                        publishAfterCopy,
                        updateIgnoreAttributes,
                    },
                };
            },
            transformResponse: (response: ApiResponse<CopyProductsToRestaurantsResponse>) => response.data,
        }),
    }),
});

export const {
    useDeleteMenuByIdMutation,
    useGetMenuExportByIdMutation,
    useGetMenuByIdQuery,
    useGetMenusQuery,
    useCopyProductsToRestaurantsMutation,
    useGetMenuOperationsMutation,
    useImportMenuFromExcelMutation,
    usePostMenuMutation,
    usePostCopyMenuByIdMutation,
    usePostDuplicateMenuByIdMutation,
    usePostMenusRevaluationsMutation,
    usePutMenuByIdMutation,
    usePublishMenuOnChannelMutation,
    useScanMenuMutation,
} = menuApi;
