import { RouterState } from 'connected-react-router';
import { enumValues } from '@/utils/typescript';
import { RootState } from '@/redux/store';
import { Routes } from '@/core/router/routes.types';

export const getRouterState = (state: RootState): RouterState => state.router;

export const getRouterPathName = (state: RootState): string => getRouterState(state).location.pathname;

export const getActualRoute = (state: RootState): Routes => {
    const route = getRouterPathName(state);

    return enumValues(Routes).includes(route) ? (route as Routes) : Routes.Home;
};
