import { useGetUserQuery, useUpdateUserMutation } from '@/services/innovorder/user/user.endpoints';
import { useRouteMatch } from 'react-router';
import { AccessFlags } from '@/services/innovorder/auth/auth.type';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface useAccessFlagsControlVM {
    handleSave: () => void;
    displayedFlags: Record<string, boolean> | null;
    handleSwitch: (accessFlag: string) => void;
    isButtonDisabled: boolean;
    isButtonLoading: boolean;
}

const useAccessFlagsControlVM = (): useAccessFlagsControlVM => {
    const { params } = useRouteMatch<{ userId: string }>();
    const { data } = useGetUserQuery({ userId: Number(params.userId) });
    const [displayedFlags, setDisplayedFlags] = useState<Record<string, boolean> | null>(null);
    const [changes, setChanges] = useState<string[]>([]);
    const [updateUser, { isLoading }] = useUpdateUserMutation();

    const handleSave = useCallback(() => {
        data && displayedFlags && updateUser({ ...data, accessFlags: displayedFlags });
        setChanges([]);
    }, [data, displayedFlags]);

    const handleSwitch = useCallback(
        (accessFlag: string) => {
            displayedFlags &&
                setDisplayedFlags((prev) => ({
                    ...prev,
                    [accessFlag]: !displayedFlags[accessFlag],
                }));
            setChanges((currentChangesList) => {
                const flagDoesNotExist = currentChangesList.indexOf(accessFlag) === -1;
                return flagDoesNotExist
                    ? [...currentChangesList, accessFlag]
                    : currentChangesList.filter((flag) => flag != accessFlag);
            });
        },
        [displayedFlags],
    );
    const isButtonDisabled = useMemo(() => !changes.length, [changes]);

    useEffect(() => {
        if (!data) return;
        const receivedAccessFlagsState = Object.keys(AccessFlags).reduce(
            (acc, currentFlag) => ({ ...acc, [currentFlag]: Object.keys(data.accessFlags).includes(currentFlag) }),
            {},
        );

        setDisplayedFlags(receivedAccessFlagsState);
    }, [data]);
    return { handleSave, displayedFlags, handleSwitch, isButtonDisabled, isButtonLoading: isLoading };
};

export default useAccessFlagsControlVM;
