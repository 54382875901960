import { TableRow } from '@/components/atoms/Table/Table.type';
import { SortingRule } from 'react-table';

export const customerSortByMapper = {
    id: 'customer_id',
    name: 'first_name',
    email: 'email',
    phone: 'phone',
    totalSpentAmount: 'totalSpent',
    orderCount: 'totalOrders',
    averageCart: 'averagePurchase',
    lastOrder: 'lastOrder',
    frequency: 'averageOrderPerWeek',
    status: 'disabled_at',
    ewalletBalance: 'balance',
    archivedAt: 'archived_at',
};

export const convertTableSortToQuerySort = (sortBy?: SortingRule<TableRow>[]): string | undefined => {
    if (!sortBy?.length) {
        return undefined;
    }

    const sortByKey = sortBy[0].id as keyof typeof customerSortByMapper;

    return `${customerSortByMapper[sortByKey]} ${sortBy[0].desc ? 'DESC' : 'ASC'}`;
};
