import { PricingRuleGrant } from '@/services/innovorder/pricingRule/pricingRule.type';

export const sortPricingRuleGrants = (grants: PricingRuleGrant[]): PricingRuleGrant[] => {
    const sortedGrants = grants.slice().sort((a, b) => {
        if (a.position === null && b.position === null) {
            return 0;
        }

        if (a.position === null && b.position !== null) {
            return 1;
        }

        if (a.position !== null && b.position === null) {
            return -1;
        }

        if (a.position !== null && b.position !== null) {
            return a.position - b.position;
        }

        return 0;
    });

    return sortedGrants;
};
