import '@szhsin/react-menu/dist/core.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import {
    ClickEvent,
    FocusableItem,
    MenuChangeEvent,
    MenuGroup,
    MenuHeader,
    MenuRadioGroup,
    RadioChangeEvent,
} from '@szhsin/react-menu';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { computeText } from '@/locales/utils';
import { SVGIcon, SVGIcons } from '../Icon/SVGIcon';
import { ClearFilter, StyledInput, StyledMenu, StyledMenuButton, StyledMenuItem } from './DropDown.style';
import type {
    DropDownText,
    DropDownItem,
    DropDownCheckbox,
    DropDownRadio,
    DropDownClickableItem,
} from './DropDown.type';

export const DropDown = <T extends unknown>({
    items,
    selected,
    align = 'start',
    direction,
    button,
    isUnstyledButton,
    onChange,
    headerLabel,
    filterPlaceholder,
    allEntriesLabel,
    allEntriesValue,
    clearLabel,
    type = 'text',
    withPortal = false,
    ...otherProps
}: DropDownText<T> | DropDownRadio<T> | DropDownCheckbox<T>): React.ReactElement => {
    const intl = useIntl();
    const [filter, setFilter] = useState('');

    const applyInputFilter = (item: DropDownClickableItem<T> | DropDownItem<T>): boolean =>
        item.label.toUpperCase().includes(filter.trim().toUpperCase());

    const filteredItems = filter ? items.filter(applyInputFilter) : items;

    const isSelected = (value: T | typeof allEntriesValue): boolean => {
        if (allEntriesValue === value) {
            return Boolean(
                selected && Array.isArray(selected) && filteredItems.every((item) => selected.includes(item.value)),
            );
        }
        return Boolean(selected && Array.isArray(selected) && selected.find((entry) => entry === value));
    };

    const handleRadioChange = ({ value }: RadioChangeEvent): void => {
        setFilter('');
        if (onChange) {
            onChange(value);
        }
    };

    const handleCheckboxChange = (event: ClickEvent, value: T | typeof allEntriesValue): void => {
        if (['text', 'radio'].includes(type) || !Array.isArray(selected)) return;

        event.keepOpen = true;
        const onChangeMultiple = onChange as DropDownCheckbox<T>['onChange'];

        if (value === undefined || value === allEntriesValue) {
            if (isSelected(value)) {
                onChangeMultiple([]);
            } else {
                onChangeMultiple(filteredItems.map((item) => item.value));
            }
        } else if (isSelected(value)) {
            onChangeMultiple(selected.filter((entry) => entry !== value));
        } else {
            onChangeMultiple([...selected, value]);
        }
    };

    const handleClose = ({ open }: MenuChangeEvent): void => {
        if (!open) setFilter('');
    };

    return (
        <StyledMenu
            overflow="auto"
            setDownOverflow
            align={align}
            direction={direction}
            offsetY={5}
            menuButton={({ open }) =>
                isUnstyledButton ? (
                    <div aria-expanded={open} {...otherProps}>
                        {button}
                    </div>
                ) : (
                    <StyledMenuButton aria-expanded={open} {...otherProps}>
                        {button}
                    </StyledMenuButton>
                )
            }
            onMenuChange={handleClose}
            position="anchor"
            transition
            portal={withPortal}
        >
            {headerLabel && <MenuHeader>{computeText(intl, headerLabel)}</MenuHeader>}
            {filterPlaceholder && (
                <FocusableItem>
                    {({ ref }: { ref: React.RefObject<HTMLInputElement> }) => (
                        <StyledInput
                            ref={ref}
                            type="text"
                            placeholder={filterPlaceholder}
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                    )}
                </FocusableItem>
            )}
            <MenuGroup takeOverflow>
                {type === 'text' &&
                    filteredItems.map((item) => (
                        <StyledMenuItem
                            key={item.value as React.Key}
                            value={item.value}
                            onClick={() => (item as DropDownClickableItem<T>).onClick(item.value)}
                        >
                            {item.label}
                        </StyledMenuItem>
                    ))}
                {type === 'checkbox' && (
                    <>
                        {clearLabel && (
                            <StyledMenuItem onClick={() => onChange([])}>
                                <ClearFilter>
                                    <SVGIcon icon={SVGIcons.CROSS_CIRCLED} size={15} />
                                    <FormattedMessage id={clearLabel} />
                                </ClearFilter>
                            </StyledMenuItem>
                        )}
                        {allEntriesLabel && (
                            <StyledMenuItem
                                type="checkbox"
                                value={allEntriesValue}
                                checked={isSelected(allEntriesValue)}
                                onClick={(event) => handleCheckboxChange(event, allEntriesValue)}
                                data-notlastselected={isSelected(allEntriesValue)}
                            >
                                <FormattedMessage id={allEntriesLabel} />
                            </StyledMenuItem>
                        )}
                        {filteredItems.map((item, index) => (
                            <StyledMenuItem
                                type="checkbox"
                                key={item.value as React.Key}
                                value={item.value}
                                checked={isSelected(item.value)}
                                onClick={(event) => handleCheckboxChange(event, item.value)}
                                data-notlastselected={
                                    isSelected(item.value) &&
                                    filteredItems.length > index + 1 &&
                                    isSelected(filteredItems[index + 1].value)
                                }
                            >
                                {item.label}
                            </StyledMenuItem>
                        ))}
                    </>
                )}
                {type === 'radio' && (
                    <MenuRadioGroup value={selected} onRadioChange={handleRadioChange}>
                        {allEntriesLabel && (
                            <StyledMenuItem type="radio" value={allEntriesValue}>
                                <FormattedMessage id={allEntriesLabel} />
                            </StyledMenuItem>
                        )}
                        {filteredItems.map((item) => (
                            <StyledMenuItem type="radio" key={item.value as React.Key} value={item.value}>
                                {item.label}
                            </StyledMenuItem>
                        ))}
                    </MenuRadioGroup>
                )}
            </MenuGroup>
        </StyledMenu>
    );
};
