import styled from '@emotion/styled';

export const StyledModalContainer = styled.div`
    width: 480px;
    height: 580px;
    color: ${({ theme }) => theme.color.black200};
    font-family: ${({ theme }): string => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: ${({ theme }) => theme.font.size.small};
`;

export const StyledClientUpdateForm = styled.div`
    margin-top: 20px;
    padding-bottom: 20px;
`;
