import styled from '@emotion/styled';
import { SVGIcon } from '../../Icon/SVGIcon';

export const SortIcon = styled(SVGIcon)`
    height: 50px;
    padding-right: ${({ theme }) => theme.spacing[2]};
`;

export const TableHeaderContainer = styled.th`
    position: relative;
`;

export const TableHeaderContent = styled.div`
    display: flex;
    align-items: center;
`;
