import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useHistory, useParams } from 'react-router';

import { injectDataInUri } from '@/utils/routes';
import { ENV } from '@/utils/env';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { useRoutesAttributes } from '@/hooks/useNavigationAttributes/useRoutesAttributes';
import { useDynamiqueBreadCrumb } from '@/hooks/useDynamiqueBreadCrumb';
import { routes } from '@/core/router/routes';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { NavigationContext } from '@/core/router/routes.types';
import { useResolveRoutesPathAndUriFromParams } from '@/hooks/routes/useResolveRoutesPathAndUriFromParams';
import { generateBreadCrumbFromRoute } from './utils';
import { Container, Crumb, StyledBreadcrumb } from './NavigationBar.style';

export const NavigationBar: React.FunctionComponent<React.PropsWithChildren> = () => {
    const theme = useTheme();
    const { populateBreadCrumb } = useDynamiqueBreadCrumb();
    const { isChildren, actualRoute, backToV1 } = useRoutesAttributes();
    const history = useHistory();

    const currentUriParams = useParams<NavigationContext>();

    const breadCrumbRoutes = actualRoute ? populateBreadCrumb(generateBreadCrumbFromRoute(actualRoute, routes)) : [];
    const resolvedBreadcrumbRoutes = useResolveRoutesPathAndUriFromParams(breadCrumbRoutes);

    if (!actualRoute) {
        return null;
    }
    const handleOnClick =
        (route: (typeof resolvedBreadcrumbRoutes)[0], ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => () => {
            const shouldOpenInNewTab = ev.ctrlKey || ev.button === 1;
            if (route.resolvedUri) {
                return shouldOpenInNewTab ? window.open(route.resolvedUri) : window.location.replace(route.resolvedUri);
            }
            if (route.resolvedPath) {
                return shouldOpenInNewTab ? window.open(route.resolvedPath) : history.push(route.resolvedPath);
            }
            throw new Error('Cannot find a way to navigate to this route');
        };

    return (
        <CSSTransition in={isChildren || Boolean(backToV1)} timeout={300} classNames="navigation-bar" unmountOnExit>
            <Container data-testid="navigation-bar">
                {isChildren && (
                    <>
                        <Button buttonType="secondary" onClick={history.goBack}>
                            <SVGIcon icon={SVGIcons.BACK} color={theme.color.black200} />
                            <Text text="navigation.disabled" />
                        </Button>
                        <StyledBreadcrumb icon={SVGIcons.BRAND}>
                            {resolvedBreadcrumbRoutes.map((route, index) => {
                                return (
                                    <Crumb
                                        key={route.name}
                                        onClick={
                                            resolvedBreadcrumbRoutes.length - 1 !== index
                                                ? (ev) => handleOnClick(route, ev)()
                                                : undefined
                                        }
                                        onAuxClick={
                                            resolvedBreadcrumbRoutes.length - 1 !== index
                                                ? (ev) => handleOnClick(route, ev)()
                                                : undefined
                                        }
                                    >
                                        <FormattedMessage id={route.name} />
                                    </Crumb>
                                );
                            })}
                        </StyledBreadcrumb>
                    </>
                )}
                {backToV1 && (
                    <a
                        href={`${injectDataInUri({
                            uri: backToV1,
                            backofficeUrl: ENV.BACKOFFICE_URL,
                            ...currentUriParams,
                        })}`}
                    >
                        <Button buttonType="secondary">
                            <Text text="navigation.backToV1" />
                            <SVGIcon icon={SVGIcons.BACK} rotate={180} color={theme.color.black200} />
                        </Button>
                    </a>
                )}
            </Container>
        </CSSTransition>
    );
};
