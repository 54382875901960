import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Section } from '@/components/atoms/Section';
import { PerceivedAmountCode } from '@/services/innovorder/autoBilling/autoBilling.type';
import { Button } from '@/components/atoms/Button';
import { FormattedMessage } from 'react-intl';
import PerceivedAmountCodesTable from './PerceivedAmountCodesTable';
import { PerceivedAmountCodeRow } from './PerceivedAmountCodesTable/utils';

type PerceivedAmountCodesFormProps = {
    perceivedAmountCodes: PerceivedAmountCode[];
    onUpdate: (perceivedAmountCodes: PerceivedAmountCode[]) => void;
    onIsValidChange: (value: boolean) => void;
    disabled?: boolean;
};

const PerceivedAmountCodesForm: React.FunctionComponent<React.PropsWithChildren<PerceivedAmountCodesFormProps>> = ({
    perceivedAmountCodes,
    onUpdate,
    onIsValidChange,
    disabled = false,
}) => {
    const [perceivedAmountCodeRows, setPerceivedAmountCodeRows] = useState<PerceivedAmountCodeRow[]>([]);

    const addPerceivedAmountCode = useCallback(() => {
        setPerceivedAmountCodeRows([...perceivedAmountCodeRows, { code: { value: '' }, vat: { value: '0' } }]);
    }, [perceivedAmountCodeRows]);

    const deletePerceivedAmountCode = useCallback(
        (row: PerceivedAmountCodeRow) => {
            setPerceivedAmountCodeRows(perceivedAmountCodeRows.filter((elem) => elem !== row));
        },
        [perceivedAmountCodeRows],
    );
    const actionOnClick = useMemo(
        () => (disabled ? undefined : addPerceivedAmountCode),
        [addPerceivedAmountCode, disabled],
    );

    const handlePerceivedAmountCodesUpdate = useCallback(
        (rows: PerceivedAmountCodeRow[]) => {
            setPerceivedAmountCodeRows(rows);
            onUpdate(rows.map((row) => ({ code: row.code.value as string, vat: Number(row.vat.value) })));
        },
        [onUpdate],
    );
    useEffect(() => {
        setPerceivedAmountCodeRows(
            perceivedAmountCodes.map((perceivedAmountCode) => ({
                code: {
                    value: perceivedAmountCode.code,
                },
                vat: {
                    value: perceivedAmountCode.vat.toString(),
                },
            })),
        );
    }, [perceivedAmountCodes]);

    return (
        <div data-testid="perceived-amount-codes">
            <Section
                title="auto.billing.referential.serviceCodes.perceivedAmountCodes.card.title"
                subtitle=""
                actions={
                    <Button buttonType="primary" onClick={actionOnClick}>
                        <FormattedMessage id="auto.billing.referential.serviceCodes.perceivedAmountCodes.add" />
                    </Button>
                }
            >
                <PerceivedAmountCodesTable
                    perceivedAmountCodes={perceivedAmountCodeRows}
                    onDelete={deletePerceivedAmountCode}
                    readOnly={disabled}
                    onUpdate={handlePerceivedAmountCodesUpdate}
                    onIsValidChange={onIsValidChange}
                />
            </Section>
        </div>
    );
};

export default PerceivedAmountCodesForm;
