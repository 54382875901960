import React from 'react';
import { FormProvider } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import RawModal from '@/components/atoms/RawModal';
import Wizard from '@/components/atoms/Wizard';
import { useEditConfigurationModalVM } from './EditConfigurationModal.viewmodel';

export type EditConfigurationModalProps = {
    show: boolean;
    hide: () => void;
};

export const EditConfigurationModal: React.FunctionComponent<React.PropsWithChildren<EditConfigurationModalProps>> = ({
    show,
    hide,
}) => {
    const intl = useIntl();
    const { form, currentStep, steps, isLoading, errorMessage, onFormValidate } = useEditConfigurationModalVM({
        show,
        hide,
    });

    if (show) {
        return (
            <RawModal data-testid="select-entity-modal" isOpen={true}>
                <FormProvider {...form}>
                    <Wizard
                        selectedStep={currentStep}
                        steps={steps}
                        validateLabel={computeText(intl, 'button.save')}
                        onValidate={onFormValidate}
                        showStepper={false}
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                        isForm={true}
                        canGoBack={false}
                    />
                </FormProvider>
            </RawModal>
        );
    }
    return null;
};
