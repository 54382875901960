import qs from 'query-string';
import { History } from 'history';

export const getParamFromQueryString = (queryString: string, key: string): string | string[] | undefined => {
    const params = qs.parse(queryString) || {};
    return params[key] as string | string[] | undefined;
};

export const replaceParamInUrl = (history: History, key: string, newValue?: string | string[]): void => {
    const { [key]: actualValue, ...otherQueryParams } = qs.parse(history.location?.search);

    if (newValue === actualValue) return;

    if (newValue === undefined) {
        if (actualValue === undefined) return;
        history.replace({
            search: qs.stringify({ ...otherQueryParams }),
        });
    }

    history.replace({
        search: qs.stringify({ ...otherQueryParams, [key]: newValue }),
    });
};

export const generateQueryStringFromParams = (params: Record<string, string | number | undefined>): string => {
    const reducedParams = Object.entries(params).reduce<Record<string, string | number>>((curr, [key, value]) => {
        if (typeof key === 'string' && !!value) {
            curr[key] = value;
        }
        return curr;
    }, {});

    return qs.stringify(reducedParams);
};
