import styled from '@emotion/styled';

export const ConfigContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
    ${({ theme }) => theme.breakpoints.desktop} {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: ${({ theme }) => theme.spacing[10]};
    }
    background: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    margin: ${({ theme }) => theme.spacing[4]};
    flex: 1;
`;
