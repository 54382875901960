import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[1]};
    height: 40px;
    margin: ${({ theme }) => theme.spacing[1]};

    svg {
        margin-left: ${({ theme }) => theme.spacing[2]};
        color: ${({ theme }): string => theme.color.white100};
    }

    p {
        color: ${({ theme }): string => theme.color.white100};
    }

    &:hover {
        background-color: #222222;

        .indicator {
            background-color: #111111;
        }
    }
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: ${({ theme }) => theme.spacing[3]};
`;

export const LineText = styled.div`
    display: flex;
    align-items: center;
`;

export const PageText = styled.p`
    font-size: ${({ theme }) => theme.font.size.small};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.white100} !important;
`;

export const ParentText = styled.p`
    font-size: ${({ theme }) => theme.font.size.small};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    color: #999999 !important;
`;

export const ContextText = styled.p`
    font-style: italic;
    font-size: ${({ theme }) => theme.font.size.small};
    font-family: ${({ theme }) => theme.font.family};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    color: #555555 !important;
    margin-left: ${({ theme }) => theme.spacing[1]};
`;

export const Cta = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }): string => theme.color.white100};
    ${({ theme }) => theme.font.caption.bold}
    padding: ${({ theme }) => theme.spacing[2]};
    border-radius: ${({ theme }) => theme.radius.main};
    max-height: 32px;
`;
