import React from 'react';
import { ConfigContainer } from '../common/style/Common.style';
import ExternalPosPanel from './ExternalPosPanel';
import ExternalPosConfigurationPanel from './ExternalPosConfigurationPanel';

const ExternalPosConfig: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <>
            <ConfigContainer>
                <ExternalPosPanel />
                <ExternalPosConfigurationPanel />
            </ConfigContainer>
        </>
    );
};

export default ExternalPosConfig;
