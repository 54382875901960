import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    GetUserPayload,
    User,
    UpdateUserPayload,
    GetBrandsUsersListPayload,
    UserRoles,
    GetBrandsUsersListResponse,
    CreateManagerPayload,
} from './user.type';

const userApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query<User, ApiRequest<GetUserPayload>>({
            query: ({ userId }) => ({
                url: `/users/${userId}`,
            }),
            transformResponse: (response: ApiResponse<User>) => response.data,
            providesTags: (user) => [{ type: ApiTagTypes.User, id: user?.userId }],
        }),
        updateUser: build.mutation<User, ApiRequest<UpdateUserPayload>>({
            query: (user) => ({
                url: `/users/${user.userId}`,
                body: user,
                method: 'PUT',
            }),
            transformResponse: (response: ApiResponse<User>) => response.data,
            invalidatesTags: (user) => [{ type: ApiTagTypes.User, id: user?.userId }],
        }),
        getBrandUsersList: build.query<GetBrandsUsersListResponse, ApiRequest<GetBrandsUsersListPayload>>({
            query: ({ brandId, search, orderByLastConnection }) => ({
                url: `/users`,
                params: {
                    brandId,
                    role: UserRoles.brand,
                    search,
                    orderByLastConnection,
                },
            }),
            transformResponse: (response: ApiResponse<GetBrandsUsersListResponse>) => response.data,
            providesTags: [ApiTagTypes.BrandUsers],
        }),
        createManager: build.mutation<User, ApiRequest<CreateManagerPayload>>({
            query: (body) => ({
                url: `/users/manager`,
                body,
                method: 'POST',
            }),
            invalidatesTags: [ApiTagTypes.BrandUsers],
            transformResponse: (response: ApiResponse<User>) => response.data,
        }),
    }),
});

export const { useGetUserQuery, useUpdateUserMutation, useLazyGetBrandUsersListQuery, useCreateManagerMutation } =
    userApi;
