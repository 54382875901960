import { OrderList, OrderListProps } from '@/pages/Order/OrderList/OrderList';
import { getUserRole, getUserToken } from '@/redux/user';
import { Brand } from '@/services/innovorder/brand/brand.type';
import { useGetOrdersMutation } from '@/services/innovorder/orders/order.endpoints';
import { useTheme } from '@emotion/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

export type CustomerOrdersProps = {
    brand?: Brand | null;
};
const withOrderListParams = (WrappedComponent: React.ComponentType<OrderListProps>) => {
    return ({ brand }: CustomerOrdersProps) => {
        const theme = useTheme();
        const token = useSelector(getUserToken);

        const { customerId } = useParams<{ customerId: string }>();
        const brandId = brand?.brandId;
        const brandType = brand?.brandType;

        const userRole = useSelector(getUserRole);
        const [fetchOrders, { data, isLoading }] = useGetOrdersMutation();

        return (
            <WrappedComponent
                token={token}
                brandId={brandId}
                userRole={userRole}
                customerId={Number(customerId)}
                fetchOrders={fetchOrders}
                data={data}
                isLoading={isLoading}
                brandType={brandType}
                theme={theme}
                headerProps={{
                    title: 'customer.ordersList.title',
                    subTitle: 'customer.ordersList.subtitle',
                    showRestaurantSelector: false,
                }}
            />
        );
    };
};
export default withOrderListParams(OrderList);
