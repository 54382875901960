import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    margin: ${({ theme }) => theme.spacing[4]};
`;
