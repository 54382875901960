import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
import { ApiRequest, ApiResponse } from '../../request.types';
import {
    GetSingleTransactionPayload,
    GetTransactionsPayload,
    GetTransactionsResponse,
    TransactionResponse,
} from './transaction.type';

const transactionApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getTransactions: build.mutation<GetTransactionsResponse, ApiRequest<GetTransactionsPayload>>({
            query: ({ restaurantIds, customerId, search, filters, ...otherParams }) => ({
                url: new URL('/v1/transactions', ENV.PAYMENT_SERVICE_URL).href,
                params: {
                    customerId,
                    restaurantIds,
                    search,
                    ...filters,
                    ...otherParams,
                },
                method: 'GET',
            }),
            transformResponse: (response: ApiResponse<GetTransactionsResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getTransactionById: build.query<TransactionResponse, ApiRequest<GetSingleTransactionPayload>>({
            query: ({ brandId, transactionId }) => {
                return {
                    url: new URL(`/v1/transactions/${transactionId}`, ENV.PAYMENT_SERVICE_URL).href,
                    method: 'GET',
                    params: {
                        brandId,
                    },
                };
            },
            transformResponse: (response: ApiResponse<TransactionResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetTransactionsMutation, useGetTransactionByIdQuery } = transactionApi;
