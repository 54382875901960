import React, { PropsWithChildren, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';

import { theme } from '@/core/theme';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { StaticImage } from '@/components/atoms/StaticImage/StaticImage';
import { Container, ExpandIcon, Header, IconContainer, StaticContainer, StyledBadge, StyledTitle } from './Card.style';

export type CardProps = Partial<React.HTMLAttributes<HTMLElement>> &
    PropsWithChildren<{
        titleId: string;
        isExpandable?: boolean;
        isInitialOpened?: boolean;
        iconUrl?: string;
        badgeLabel?: string;
        badgeColor?: string;
        onClick?: () => void;
    }>;

const Card: React.FunctionComponent<React.PropsWithChildren<CardProps>> = ({
    titleId,
    onClick,
    isExpandable = false,
    isInitialOpened = !isExpandable,
    iconUrl,
    badgeLabel,
    badgeColor = theme.color.primary,
    children,
    ...otherProps
}) => {
    const [isOpen, setIsOpen] = useState(isInitialOpened);
    const intl = useIntl();
    const hasHeader = iconUrl || badgeLabel;

    const toggleCard = useCallback(() => {
        setIsOpen((state) => !state);
    }, []);

    return (
        <Container onClick={onClick} canBeClicked={!!onClick} {...otherProps}>
            {hasHeader && (
                <Header>
                    {!!iconUrl && (
                        <IconContainer>
                            <StaticImage ressourceUrl={iconUrl} alt={computeText(intl, titleId)} />
                        </IconContainer>
                    )}
                    {!!badgeLabel && (
                        <StyledBadge backgroundColor={badgeColor} text={badgeLabel} textColor={theme.color.slate100} />
                    )}
                </Header>
            )}
            <StaticContainer>
                <StyledTitle text={titleId} weight="medium" />
                {isExpandable && (
                    <ExpandIcon
                        isOpen={isOpen}
                        icon={SVGIcons.CHEVRON}
                        size={24}
                        color={theme.color.black}
                        onClick={toggleCard}
                    />
                )}
            </StaticContainer>
            {isOpen && children}
        </Container>
    );
};

export default Card;
