import React from 'react';
import { Container } from './Badge.style';

export type BadgeProps = {
    backgroundColor: string;
    text: string;
    textColor?: string;
    fontWeight?: 'normal' | 'bold';
};

export const Badge: React.FunctionComponent<React.PropsWithChildren<BadgeProps>> = ({
    backgroundColor,
    text,
    textColor,
    fontWeight,
    ...otherProps
}) =>
    text ? (
        <Container
            backgroundColor={backgroundColor}
            textColor={textColor}
            text={text}
            fontWeight={fontWeight}
            {...otherProps}
        />
    ) : null;
