import styled from '@emotion/styled';

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
    padding: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    margin-top: ${({ theme }) => theme.spacing[3]};
`;

export const GeneralInformationContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: ${({ theme }) => theme.spacing[5]};
`;

export const FormContainer = styled.div`
    padding: ${({ theme }) => theme.spacing[4]};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
    margin-top: ${({ theme }) => theme.spacing[3]};
`;
