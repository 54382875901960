import React, { useMemo } from 'react';
import { Table } from '@/components/atoms/Table';
import { ReportMetadata } from '@/services/innovorder/report/report.type';
import ExportResultModal from '@/components/organisms/ExportResultModal';
import { useHandleReportExport } from '@/pages/Reporting/common/hooks';
import { useGetReportTableData, useHandleDateRange, useHandleFilters } from './hooks';
import SelectDateRangeButton from './SelectDateRangeButton';
import CalendarModal from './CalendarModal';
import ExportButton from './ExportButton';
import { getReportParams } from './ReportTable.utils';

interface ReportTableProps {
    metadata: ReportMetadata;
}

const ReportTable: React.FunctionComponent<ReportTableProps> = ({
    metadata: { dataUrl, filtersUrl, exportUrl, name },
}) => {
    const { dates, isDateSelectorOpen, maxRangeInDays, openDateSelector, closeDateSelector, submitDateRange } =
        useHandleDateRange();

    const { filters, filtersButton, savedFiltersDropdown, filtersModal } = useHandleFilters({ filtersUrl });

    const params = useMemo(() => getReportParams(dates, filters), [dates, filters]);

    const { isTableLoading, columns, rows } = useGetReportTableData({ dataUrl, params });

    const { isExportResultOpen, closeExportResult, exportReport, isProcessingExport, isExportRequestCreated } =
        useHandleReportExport({ exportUrl, params });

    const isDefined = (reactElement?: React.ReactElement): reactElement is React.ReactElement =>
        reactElement !== undefined;
    const toolbarElements = [
        savedFiltersDropdown,
        filtersButton,
        <SelectDateRangeButton key={2} selectedDateRange={dates} openDateRangeSelector={openDateSelector} />,
        <ExportButton
            key={3}
            handleExport={exportReport}
            isProcessingExport={isProcessingExport}
            isTableLoading={isTableLoading}
        />,
    ].filter(isDefined);

    return (
        <>
            <Table
                rows={rows}
                columns={columns}
                emptyMessageId={'report.emptyMessage'}
                isSortable={false}
                initialPageSize={40}
                isLoading={isTableLoading}
                toolbarButtons={toolbarElements}
                hasFooter
                hasPagination
                title={`report.title.${name}`}
            />
            {isDateSelectorOpen && (
                <CalendarModal
                    isOpen={isDateSelectorOpen}
                    initialDateRange={dates}
                    submitDates={submitDateRange}
                    close={closeDateSelector}
                    maxRangeInDays={maxRangeInDays}
                />
            )}
            {filtersModal}
            <ExportResultModal
                title="report.exportResultModal.title"
                isOpen={isExportResultOpen}
                close={closeExportResult}
                isSuccess={isExportRequestCreated}
            />
        </>
    );
};

export default ReportTable;
