import React from 'react';
import { useIntl } from 'react-intl';
import { FormProvider } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import Wizard from '@/components/atoms/Wizard';
import RawModal from '@/components/atoms/RawModal';
import { useUploadModalVM } from './UploadModal.viewmodel';
import { UploadModalContainer } from './UploadModal.style';

type UploadModalProps = {
    isOpen: boolean;
    onHide: () => void;
    brandId: string;
};

export const UploadModal: React.FunctionComponent<React.PropsWithChildren<UploadModalProps>> = ({
    isOpen,
    onHide,
    brandId,
}) => {
    const intl = useIntl();
    const { form, currentStep, steps, canValidate, isLoading, onFormValidate, handleHide } = useUploadModalVM({
        onHide,
        brandId,
    });

    return (
        <RawModal isOpen={isOpen} onHide={handleHide}>
            <FormProvider {...form}>
                <UploadModalContainer>
                    <Wizard
                        width={600}
                        selectedStep={currentStep}
                        steps={steps}
                        validateLabel={computeText(intl, 'button.save')}
                        onValidate={onFormValidate}
                        isLoading={isLoading}
                        canValidate={canValidate}
                    />
                </UploadModalContainer>
            </FormProvider>
        </RawModal>
    );
};
