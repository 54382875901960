import React, { useCallback, useEffect } from 'react';
import { Routes } from '@/core/router/routes.types';
import { useUpdatePosAccessUserMutation } from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.endpoints';
import { PostPosAccessUserBody } from '@/services/innovorder/pos_access/pos_access_user/pos_access_user.type';
import { useParams, useHistory, generatePath } from 'react-router';
import { stringifyError } from '@/utils/errors';
import { PosAccessBase } from '../../components/PosAcess';
import PosUserForm from '../../components/PosUsers/forms/PosUserForm';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
    userId: string;
};

const EditPosAccessUser: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { brandId, restaurantId, userId } = useParams<ParamTypes>();
    const history = useHistory();

    const [updatePosAccessUser, { isLoading, isSuccess, error }] = useUpdatePosAccessUserMutation();

    const handleSuccess = useCallback(() => {
        if (isSuccess) history.push(generatePath(Routes.PosAccessUsers, { brandId, restaurantId }));
    }, [brandId, history, isSuccess, restaurantId]);

    useEffect(() => {
        handleSuccess();
    }, [handleSuccess]);

    const onSubmit = useCallback(
        async (data: PostPosAccessUserBody) => {
            await updatePosAccessUser({
                ...data,
                restaurantId: Number.parseInt(restaurantId, 10),
                userId: +userId,
            });
        },
        [restaurantId, updatePosAccessUser, userId],
    );
    return (
        <PosAccessBase>
            <PosUserForm error={stringifyError(error)} loading={isLoading} onSubmit={onSubmit} />
        </PosAccessBase>
    );
};

export { EditPosAccessUser };
