import { RouteDeclaration } from '@/core/router/routes.types';
import { CrossSellingRoutes } from './CrossSelling.interface';
import CrossSellingList from './List/CrossSellingList';
import EditCrossSelling from './Edit/EditCrossSelling';
import CreateCrossSelling from './Create/CreateCrossSelling';

export const CROSS_SELLING_ROUTES: RouteDeclaration<CrossSellingRoutes>[] = [
    {
        name: 'route.crossSelling',
        path: CrossSellingRoutes.List,
        component: CrossSellingList,
        exact: true,
    },
    {
        name: 'route.crossSelling',
        path: CrossSellingRoutes.Edit,
        component: EditCrossSelling,
    },
    {
        name: 'route.crossSelling',
        path: CrossSellingRoutes.New,
        component: CreateCrossSelling,
    },
];
