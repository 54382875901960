import styled from '@emotion/styled';

export const Container = styled.div`
    width: 280px;
    height: 244px;
    border: 1px solid rgba(212, 212, 216, 1);
    border-radius: ${({ theme }) => theme.spacing[3]};
    padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[3]}`};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Title = styled.h2`
    font-size: ${({ theme }) => theme.font.size.medium};
`;
export const Description = styled.p`
    font-size: ${({ theme }) => theme.font.size.small};
    color: ${({ theme }) => theme.color.black300};
    font-weight: ${({ theme }) => theme.font.weight.light};
`;

export const InstalledBadge = styled.span`
    background-color: rgba(222, 252, 233, 1);
    color: rgba(17, 76, 41, 1);
    font-size: ${({ theme }) => theme.font.size.small};
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
    border-radius: ${({ theme }) => theme.radius.main};
    height: fit-content;
    display: flex;
    gap: ${({ theme }) => theme.spacing[1]};
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ImageWrapper = styled.span`
    border: 1px solid rgba(212, 212, 216, 1);
    border-radius: ${({ theme }) => theme.radius.main};
    background-color: rgba(249, 249, 249, 1);
    padding: ${({ theme }) => theme.spacing[1]};
`;
