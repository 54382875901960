import styled from '@emotion/styled';

export const Container = styled.div`
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
    right: 0;
    bottom: 0;
    display: flex;
`;

export const ComponentContainer = styled.div`
    width: ${({ theme }): string => theme.breakpoints.sidebar};
    overflow: auto;
    background-color: ${({ theme }): string => theme.color.slate200};
`;

export const TransparentContainer = styled.div`
    flex: 1;
`;
