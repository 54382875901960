import React from 'react';
import { Container, HeaderContainer } from './BrandsList.style';
import { HeaderLeft, Header, HeaderRight } from '@/components/atoms/Section/Section.style';
import { Title } from '@/components/atoms/Title';
import { Table } from '@/components/atoms/Table/Table';
import useBrandsListVM from './BrandsList.viewmodel';
import { SvyButton } from '@/components/savory/SvyButton';
import { FormattedMessage } from 'react-intl';
import CreateBrandForm from './CreateBrandForm/CreateBrandForm';

const BrandsList = () => {
    const { rows, columns, fetchData, data, isLoading, handleRowClick, showModal, closeModal, openModal } =
        useBrandsListVM();
    return (
        <Container>
            <CreateBrandForm isOpen={showModal} onHide={closeModal} />
            <HeaderContainer>
                <Header>
                    <HeaderLeft>
                        <Title text="Enseignes" />
                    </HeaderLeft>
                    <HeaderRight>
                        <SvyButton size="s" onClick={openModal}>
                            <FormattedMessage id="brands.create" />
                        </SvyButton>
                    </HeaderRight>
                </Header>
                <div>
                    <Table
                        rows={rows}
                        emptyMessageId="table.noRowsAfterFilter"
                        columns={columns}
                        hasPagination
                        isSearchable
                        hasUrlNavigation
                        isFilterable
                        isSortable
                        fetchData={fetchData}
                        fetchDataTotalCount={data?.brandsTotal}
                        isLoading={isLoading}
                        columnSelectorId="transferList"
                        onRowClick={handleRowClick}
                    />
                </div>
            </HeaderContainer>
        </Container>
    );
};

export default BrandsList;
