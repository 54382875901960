import { Menu } from '@/services/innovorder/menu/menu.type';

export const compareIsActive = (a: Menu, b: Menu): number => {
    if (a.isActive && !b.isActive) {
        return -1;
    }

    if (!a.isActive && b.isActive) {
        return 1;
    }

    return 0;
};
