import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from '@/components/atoms/Modal';
import { Button } from '@/components/atoms/Button';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { useTheme } from '@emotion/react';
import { Description } from './DeleteModal.style';

interface DeleteModalProps {
    title: string;
    description?: string;
    isOpen: boolean;
    onHide: () => void;
    onSubmit: () => void;
    isLoading?: boolean;
}

export const DeleteModal: React.FunctionComponent<React.PropsWithChildren<DeleteModalProps>> = ({
    title,
    description,
    isOpen,
    onHide,
    onSubmit,
    isLoading,
    children,
}) => {
    const theme = useTheme();
    const deleteButton = (
        <Button onClick={onSubmit} buttonType="primary" disabled={isLoading}>
            <FormattedMessage id="button.delete" />
        </Button>
    );

    return (
        <Modal
            isOpen={isOpen}
            onHide={onHide}
            width={328}
            title={title}
            icon={SVGIcons.INFO}
            iconColor={theme.color.bloodyMary}
            buttons={deleteButton}
        >
            <Description>
                <FormattedMessage id={description} />
            </Description>
            {children}
        </Modal>
    );
};

export default DeleteModal;
