import React, { useEffect } from 'react';
import { ENV } from '@/utils/env';
import { camelCase } from 'lodash';
import { AppEnv } from '@/utils/types';
import { useSelector, useDispatch } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import useScript from '@/hooks/useScript';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { updateLDFlags } from '@/redux/launchDarkly';
import { getBrand } from '@/redux/brand';
import { getUser } from '@/redux/user';
import { hotjarTrackingScrpit } from './scritps';

export const InitialiseThirdParties: React.FunctionComponent<React.PropsWithChildren> = () => {
    const user = useSelector(getUser);
    const currentBrand = useSelector(getBrand);
    const ldClient = useLDClient();
    const dispatch = useDispatch();
    const shouldInitThirdParties = ENV.DASHBOARD_ENV === AppEnv.Production && !!user && user?.role !== UserRoles.admin;

    useEffect(() => {
        Sentry.init({
            dsn: ENV.SENTRY_DSN,
            integrations: [new Integrations.BrowserTracing(), new Sentry.Integrations.Breadcrumbs()],
            tracesSampleRate: 1.0,
            environment: ENV.DASHBOARD_ENV,
        });

        Sentry.setUser({ email: user?.email, id: user?.userId.toString() });
        Sentry.setContext('brandId', { brandId: user?.brandId });
    });

    useEffect(() => {
        const setFlags = async () => {
            if (user !== null && ldClient !== undefined) {
                const { firstName, lastName, email, userId, brandId, restaurants, role } = user;
                await ldClient.identify({
                    key: userId.toString(),
                    firstName,
                    lastName,
                    email,
                    custom: {
                        role,
                        restaurantId: restaurants[0].restaurantId,
                        ...(brandId !== null ? { brandId } : {}),
                        ...(currentBrand && currentBrand.brandType ? { brandType: currentBrand.brandType } : {}),
                    },
                });
                dispatch(
                    updateLDFlags(
                        Object.fromEntries(
                            // Local values are in camel case
                            Object.entries(ldClient.allFlags()).map(([key, value]) => [camelCase(key), value]),
                        ),
                    ),
                );
            }
        };
        setFlags();
    }, [ldClient, dispatch, user, currentBrand]);

    useScript(hotjarTrackingScrpit, shouldInitThirdParties);

    return null;
};

export default InitialiseThirdParties;
