import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState, useCallback, useEffect, ChangeEvent } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DraggableEvent, DraggableData } from 'react-draggable';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TableUpdateAttributes, TableUpdateAttributesWithIncludes } from '@/services/innovorder/table/table.type';
import {
    useGetRoomQuery,
    useCreateRoomMutation,
    useUpdateRoomMutation,
} from '@/services/innovorder/room/room.endpoint';
import { computeText } from '@/locales/utils';
import { getUser } from '@/redux/user';
import { CreateOrEditRoomModalProps } from './CreateOrEditRoomModal';
import { computeAddMultipleTables, computeAddOneTable } from './utils';

export const useCreateOrEditRoomModalVM = ({ onHide, roomId, roomName, tableLayoutId }: CreateOrEditRoomModalProps) => {
    const intl = useIntl();
    const [newTableName, setNewTableName] = useState<string | null>(null);
    const [start, setStart] = useState<number | null>(null);
    const [end, setEnd] = useState<number | null>(null);
    const [addTableError, setAddTableError] = useState<{ single?: string; multi?: string } | null>(null);
    const user = useSelector(getUser);
    const canEdit = user?.accessFlags.CAN_EDIT_TABLE_LAYOUT ?? false;

    const { data: room, isLoading: isRoomLoading } = useGetRoomQuery(
        roomId
            ? {
                  roomId,
              }
            : skipToken,
        { refetchOnMountOrArgChange: true },
    );
    const [roomTables, setRoomTables] = useState<TableUpdateAttributes[]>(room?.tables || []);

    const [postCreateRoom, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, error: createError }] =
        useCreateRoomMutation();

    const [postEditRoom, { isSuccess: isEditSuccess, isLoading: isEditLoading, error: editError }] =
        useUpdateRoomMutation();
    const { control, getValues, setError } = useForm<{
        name: string | null;
        tables: TableUpdateAttributesWithIncludes[];
    }>({
        defaultValues: {
            name: roomName,
        },
    });

    const onCreateValidate = useCallback(() => {
        const { name } = getValues();
        setAddTableError(null);

        if (!name) {
            return setError('name', { message: computeText(intl, 'room.modal.error.name') });
        }

        if (roomId) {
            return postEditRoom({
                roomId,
                name,
                tables: roomTables.map((roomForUpdate) => ({
                    tableId: roomForUpdate.tableId,
                    roomId,
                    name: roomForUpdate.name,
                    positionX: roomForUpdate.positionX,
                    positionY: roomForUpdate.positionY,
                    height: roomForUpdate.height,
                    width: roomForUpdate.width,
                })),
            });
        }

        if (!tableLayoutId) {
            return null;
        }

        return postCreateRoom({
            name,
            tableLayoutId,
            tables: roomTables,
        });
    }, [setError, intl, getValues, postEditRoom, postCreateRoom, tableLayoutId, roomTables, roomId]);

    useEffect(() => {
        if (isEditSuccess || isCreateSuccess) {
            setAddTableError(null);
            onHide();
        }
    }, [isEditSuccess, isCreateSuccess, onHide]);

    const handleOnChangeNewTableName = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNewTableName(value);
    };

    const handleOnChangeStart = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setStart(Number(value));
    };

    const handleOnChangeEnd = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEnd(Number(value));
    };

    const handleDelete = (tableName: string) => {
        setRoomTables((a) => a.filter(({ name }) => name !== tableName));
    };

    const handleDrag = (tableName: string) => (e: DraggableEvent, data: DraggableData) => {
        const tableFound = roomTables.findIndex(({ name }) => name === tableName);
        const newRoomTables = [...roomTables];

        if (data.deltaX || data.deltaY) {
            newRoomTables[tableFound] = { ...newRoomTables[tableFound], positionX: data.x, positionY: data.y };
            setRoomTables(newRoomTables);
        }
    };

    /**
     * @TODO remove when scroll is implemented in table layout interface of the POS
     */
    const MAX_COUNT_TABLE = 60;

    const handleAddOneTable = () => {
        setAddTableError(null);

        if (!newTableName) {
            setAddTableError({
                single: computeText(intl, 'room.modal.error.new_table_name'),
            });
            return;
        }

        if (roomTables.length >= MAX_COUNT_TABLE) {
            setAddTableError({
                single: computeText(intl, 'room.modal.error.max_count_table', {
                    maxCount: MAX_COUNT_TABLE,
                }),
            });
            return;
        }

        const newRoomTables = computeAddOneTable(roomId, newTableName, roomTables);
        newRoomTables && setRoomTables(newRoomTables);
        setAddTableError(null);
    };

    const handleAddMultipleTables = () => {
        setAddTableError(null);

        if (!start || !end || end < start) {
            setAddTableError({
                multi: computeText(intl, 'room.modal.error.fill_start_and_end'),
            });
            return;
        }

        const newTableCount = end - start + roomTables.length;

        if (newTableCount >= MAX_COUNT_TABLE) {
            setAddTableError({
                multi: computeText(intl, 'room.modal.error.max_count_table', {
                    maxCount: MAX_COUNT_TABLE,
                }),
            });
            return;
        }

        const newRoomTables = computeAddMultipleTables(roomId, start, end, roomTables);
        newRoomTables && setRoomTables(newRoomTables);
        setAddTableError(null);
    };

    useEffect(() => {
        if (room) {
            setRoomTables(room.tables);
        }
    }, [room]);

    let titleI18n = 'room.modal.create.title';
    if (!canEdit) titleI18n = 'room.modal.view.title';
    else if (roomId) titleI18n = 'room.modal.edit.title';

    const title = computeText(intl, titleI18n, {
        roomName: roomName ?? '',
    });

    return {
        title,
        addTableError,
        intl,
        roomTables,
        isLoading: isRoomLoading || isEditLoading || isCreateLoading,
        serverError: createError || editError,
        control,
        canEdit,
        onCreateValidate,
        handleOnChangeNewTableName,
        handleOnChangeStart,
        handleOnChangeEnd,
        handleDelete,
        handleAddOneTable,
        handleAddMultipleTables,
        handleDrag,
        onHide,
    };
};
