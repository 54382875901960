import React, { useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import type { TableRow } from '@/components/atoms/Table/Table.type';
import { getUser } from '@/redux/user';
import { CrossSellingWithProducts } from '@/services/innovorder/crossSelling/crossSelling.type';
import {
    useGetCrossSellingListQuery,
    useUpdateCrossSellingStatusMutation,
} from '@/services/innovorder/crossSelling/crossSelling.endpoint';
import { Routes } from '@/core/router/routes.types';
import { CrossSellingAction, CrossSellingActionTypes } from './types';
import { CrossSellingRoutes } from '../CrossSelling.interface';

const defaultCrossSellingAction: CrossSellingAction = {
    crossSellingId: null,
    name: null,
    type: null,
};

export function useCrossSellingTableVM() {
    const intl = useIntl();
    const history = useHistory();
    const { params } = useRouteMatch<{ brandId: string; restaurantId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);
    const restaurantId = params.restaurantId?.length ? Number.parseInt(params.restaurantId, 10) : undefined;
    const user = useSelector(getUser);
    const userAccessFlags = user?.accessFlags;

    const { data: crossSellingList, isLoading: isCrossSellingLoading } = useGetCrossSellingListQuery({ brandId });
    const [updateCrossSellingStatus] = useUpdateCrossSellingStatusMutation();
    const [action, setAction] = useState<CrossSellingAction>(defaultCrossSellingAction);

    const handleAction = React.useCallback(
        (actionType: CrossSellingActionTypes, crossSelling: CrossSellingWithProducts) => {
            setAction({
                crossSellingId: crossSelling.crossSellingId,
                name: crossSelling.name,
                type: actionType || null,
            });
        },
        [],
    );
    const handleToggleCrossSellingPage = React.useCallback(
        ({ crossSellingId }: CrossSellingWithProducts) => {
            updateCrossSellingStatus({ crossSellingId });
        },
        [updateCrossSellingStatus],
    );

    const handleCreate = () => {
        history.push(generatePath(`${Routes.BrandCrossSelling}${CrossSellingRoutes.New}`, { brandId }));
    };

    const headers = React.useMemo(
        () => [
            {
                Header: computeText(intl, 'status.status'),
                accessor: 'activate',
                disableSortBy: true,
            },
            {
                Header: computeText(intl, 'name.name'),
                accessor: 'name',
                width: restaurantId ? '25%' : '100%',
            },
            {
                Header: computeText(intl, 'action.action'),
                accessor: 'action',
                width: '10%',
                disableSortBy: true,
            },
        ],
        [intl, restaurantId],
    );

    const rows: TableRow[] = React.useMemo(
        () =>
            [...(crossSellingList ?? [])].map((crossSelling) => {
                const onClickHandler = (actions: CrossSellingActionTypes) => () => handleAction(actions, crossSelling);

                return {
                    activate: {
                        type: 'switch',
                        value: {
                            onChange: () => handleToggleCrossSellingPage(crossSelling),
                            value: crossSelling.status === 'enabled',
                            disabled: false,
                        },
                    },
                    name: {
                        type: 'string',
                        value: {
                            text: crossSelling.name,
                        },
                    },
                    action: {
                        type: 'dropdownText',
                        value: {
                            button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                            align: 'end',
                            withPortal: true,
                            items: [
                                {
                                    value: 1,
                                    label: computeText(intl, 'action.duplicate'),
                                    onClick: onClickHandler('showDuplicateModal'),
                                },
                                {
                                    value: 2,
                                    label: computeText(intl, 'action.delete'),
                                    onClick: onClickHandler('showDeleteModal'),
                                },
                            ],
                            type: 'text',
                        },
                    },
                    linkTo: {
                        type: 'linkTo',
                        value: { brandId: String(brandId), crossSellingId: String(crossSelling.crossSellingId) },
                    },
                };
            }),
        [intl, brandId, handleAction, crossSellingList, handleToggleCrossSellingPage],
    );
    return {
        headers,
        rows,
        action,
        brandId,
        restaurantId,
        clearAction: () => setAction(defaultCrossSellingAction),
        userAccessFlags,
        isCrossSellingLoading,
        handleCreate,
        onRowClickPath: `${Routes.BrandCrossSelling}${CrossSellingRoutes.Edit}`,
    };
}
