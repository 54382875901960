import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accept } from 'react-dropzone';
import { computeText } from '@/locales/utils';
import { Text } from '@/components/atoms/Text';
import { FileInput } from '@/components/form/FileInput';
import {
    BackgroundContainer,
    Container,
    DescriptionItem,
    DescriptionList,
    DropzoneContainer,
    TextContainer,
    Link,
} from './UploadStep.style';
import { useUploadStepVM } from './UploadStep.viewmodel';

const ALLOWED_FILE_TYPES: Accept = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
};

export const UploadStep: React.FunctionComponent<{ brandId: string }> = () => {
    const intl = useIntl();
    const { onDownload } = useUploadStepVM();

    return (
        <Container>
            <BackgroundContainer
                ressourceUrl={'dashboard/images/excel_modal_background.png'}
                alt={'excel-background-img'}
            />
            <TextContainer>
                <Text text={'group.modal.import.title'} type="large" weight="light" />
                <DescriptionList>
                    <DescriptionItem>
                        <FormattedMessage
                            id={'group.modal.import.firstPart'}
                            values={{
                                fileTemplate: (
                                    <Link onClick={onDownload}>
                                        <FormattedMessage id="file.fileTemplate" />
                                    </Link>
                                ),
                            }}
                        />
                    </DescriptionItem>
                    <DescriptionItem>
                        <FormattedMessage id={`group.modal.import.secondPart`} />
                    </DescriptionItem>
                    <DescriptionItem>
                        <FormattedMessage id={`group.modal.import.thirdPart`} />
                    </DescriptionItem>
                    <DescriptionItem>
                        <FormattedMessage id={`group.modal.import.warning`} />
                    </DescriptionItem>
                </DescriptionList>
            </TextContainer>
            <DropzoneContainer>
                <React.Suspense>
                    <FileInput
                        mode="compact"
                        accept={ALLOWED_FILE_TYPES}
                        name="file"
                        rules={{
                            required: computeText(intl, 'field.required'),
                            validate: (file) => file,
                        }}
                        transform={{
                            output: (file) => file[0],
                            input: (file: File) => (file ? [file] : []),
                        }}
                    />
                </React.Suspense>
            </DropzoneContainer>
        </Container>
    );
};
