import React from 'react';
import { useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { Select } from '@/components/form/Select';
import { hasLength, isInteger } from '@/utils/form/validate';
import { getBrand } from '@/redux/brand';
import { useSelector } from 'react-redux';
import { withTheme, withTooltip } from '@innovorder/savory';
import { getUserRole } from '@/redux/user';
import { UserRestaurant, UserRoles } from '@/services/innovorder/user/user.type';
import { EditConfigurationFormValues } from '../CreateLegalEntityModal/CreateLegalEntityModal.types';
import {
    ConecsContainer,
    ConecsTitleContainer,
    ModalInputContainer,
    ModalInputFullContainer,
    ModalInputTitle,
    ModalPartsContainer,
    ModalTextInput,
    ModalTitleContainer,
    OptionalText,
    PanelTitle,
    TitleContainer,
} from './AddRestaurantStep.style';

type EditChannelStepProps = {
    unconfiguredRestaurants?: UserRestaurant[];
    hasEwalletConfigured?: boolean;
    isLoading?: boolean;
};

export const AddRestaurantStep: React.FunctionComponent<React.PropsWithChildren<EditChannelStepProps>> = ({
    isLoading = false,
    unconfiguredRestaurants = [],
    hasEwalletConfigured = false,
}) => {
    const intl = useIntl();
    const brand = useSelector(getBrand);
    const userRole = useSelector(getUserRole);
    const hasEducationMode = brand?.options?.EDUCATION_MODE?.isActivated;
    const { control, getValues } = useFormContext<EditConfigurationFormValues>();
    const ConecsContainerWithTooltip = withTheme(withTooltip(ConecsContainer));

    const unconfiguredRestaurantsOptions = unconfiguredRestaurants.map((restaurant) => ({
        value: restaurant.restaurantId,
        label: restaurant.name,
    }));

    if (!hasEwalletConfigured && userRole === UserRoles.brand) {
        unconfiguredRestaurantsOptions.push({
            value: 0,
            label: computeText(intl, 'legalEntity.wizard.step2.ewallet'),
        });
    }

    return (
        <>
            <ModalPartsContainer>
                <TitleContainer>
                    <PanelTitle
                        text={computeText(
                            intl,
                            `legalEntity.wizard.step2.chooseRestaurant${hasEducationMode ? 'MassCatering' : ''}`,
                        )}
                    />
                </TitleContainer>
                <Controller
                    control={control}
                    name="restaurantId"
                    rules={{ required: computeText(intl, 'field.required') }}
                    render={() => (
                        <ModalInputFullContainer>
                            <ModalTitleContainer>
                                <ModalInputTitle
                                    type="small"
                                    weight="medium"
                                    text="legalEntity.wizard.step2.selectRestaurant"
                                />
                            </ModalTitleContainer>
                            <Select
                                name="restaurantId"
                                options={unconfiguredRestaurantsOptions}
                                searchable={true}
                                showValidation={false}
                                placeholder={computeText(intl, 'legalEntity.wizard.step2.selectRestaurant.placeholder')}
                            />
                        </ModalInputFullContainer>
                    )}
                />
            </ModalPartsContainer>
            <ModalPartsContainer>
                <TitleContainer>
                    <PanelTitle text={computeText(intl, 'legalEntity.wizard.step2.completeFieldsTitle')} />
                </TitleContainer>
                <Controller
                    control={control}
                    name="restaurantPhoneNumber"
                    rules={{
                        required: computeText(intl, 'field.required'),
                        validate: (value = '') => hasLength(10)(intl)(value ?? '') ?? isInteger(intl)(value ?? ''),
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <ModalInputFullContainer>
                            <ModalTitleContainer>
                                <ModalInputTitle
                                    type="small"
                                    weight="medium"
                                    text="legalEntity.wizard.step2.phoneNumber"
                                />
                            </ModalTitleContainer>
                            <ModalTextInput
                                type="string"
                                placeholder={computeText(intl, 'legalEntity.wizard.step2.phoneNumber.placeholder')}
                                error={error?.message}
                                onChange={onChange}
                                value={value?.toString()}
                                disabled={isLoading}
                                required={true}
                            />
                        </ModalInputFullContainer>
                    )}
                />
                <Controller
                    control={control}
                    name="restaurantShopperstatement"
                    rules={{
                        required: computeText(intl, 'field.required'),
                        validate: (value) => !!value?.length,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <ModalInputFullContainer>
                            <ModalTitleContainer>
                                <ModalInputTitle
                                    type="small"
                                    weight="medium"
                                    text="legalEntity.wizard.step2.shopperStatement"
                                    tooltip={computeText(intl, 'legalEntity.wizard.step2.shopperStatement.tooltip')}
                                />
                            </ModalTitleContainer>
                            <ModalTextInput
                                type="string"
                                placeholder={computeText(intl, 'legalEntity.wizard.step2.shopperStatement.placeholder')}
                                error={error?.message}
                                onChange={onChange}
                                value={value?.toString()}
                                disabled={isLoading}
                                required={true}
                            />
                        </ModalInputFullContainer>
                    )}
                />
                <Controller
                    control={control}
                    name="restaurantAddressStreet"
                    rules={{
                        required: computeText(intl, 'field.required'),
                        validate: (value = '') => !!value?.length,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <ModalInputContainer>
                            <ModalTitleContainer>
                                <ModalInputTitle
                                    type="small"
                                    weight="medium"
                                    text="legalEntity.wizard.step2.restaurantAddressStreet"
                                />
                            </ModalTitleContainer>
                            <ModalTextInput
                                type="string"
                                placeholder={computeText(
                                    intl,
                                    'legalEntity.wizard.step2.restaurantAddressStreet.placeholder',
                                )}
                                error={error?.message}
                                onChange={onChange}
                                value={value?.toString()}
                                disabled={isLoading}
                                required={true}
                            />
                        </ModalInputContainer>
                    )}
                />
                <Controller
                    control={control}
                    name="restaurantAddressStreet2"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <ModalInputContainer>
                            <ModalTitleContainer>
                                <ModalInputTitle
                                    type="small"
                                    weight="medium"
                                    text="legalEntity.wizard.step2.restaurantAddressStreet2"
                                />
                            </ModalTitleContainer>
                            <ModalTextInput
                                type="string"
                                placeholder={computeText(
                                    intl,
                                    'legalEntity.wizard.step2.restaurantAddressStreet2.placeholder',
                                )}
                                error={error?.message}
                                onChange={onChange}
                                value={value?.toString()}
                                disabled={isLoading}
                            />
                        </ModalInputContainer>
                    )}
                />
                <Controller
                    control={control}
                    name="restaurantAddressZipcode"
                    rules={{
                        required: computeText(intl, 'field.required'),
                        validate: (value = '') => hasLength(5)(intl)(value ?? '') ?? isInteger(intl)(value ?? ''),
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <ModalInputContainer>
                            <ModalTitleContainer>
                                <ModalInputTitle
                                    type="small"
                                    weight="medium"
                                    text="legalEntity.wizard.step2.restaurantAddressZipcode"
                                />
                            </ModalTitleContainer>
                            <ModalTextInput
                                type="string"
                                placeholder={computeText(
                                    intl,
                                    'legalEntity.wizard.step2.restaurantAddressZipcode.placeholder',
                                )}
                                error={error?.message}
                                onChange={onChange}
                                value={value?.toString()}
                                disabled={isLoading}
                                required={true}
                            />
                        </ModalInputContainer>
                    )}
                />
                <Controller
                    control={control}
                    name="restaurantAddressCity"
                    rules={{
                        required: computeText(intl, 'field.required'),
                        validate: (value = '') => !!value?.length,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <ModalInputContainer>
                            <ModalTitleContainer>
                                <ModalInputTitle
                                    type="small"
                                    weight="medium"
                                    text="legalEntity.wizard.step2.restaurantAddressCity"
                                />
                            </ModalTitleContainer>
                            <ModalTextInput
                                type="string"
                                placeholder={computeText(
                                    intl,
                                    'legalEntity.wizard.step2.restaurantAddressCity.placeholder',
                                )}
                                error={error?.message}
                                onChange={onChange}
                                value={value?.toString()}
                                disabled={isLoading}
                                required={true}
                            />
                        </ModalInputContainer>
                    )}
                />
                <Controller
                    control={control}
                    name="restaurantIban"
                    rules={{
                        required: computeText(intl, 'field.required'),
                        validate: (value = '') => hasLength(27)(intl)(value ?? ''),
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <ModalInputFullContainer>
                            <ModalTitleContainer>
                                <ModalInputTitle
                                    type="small"
                                    weight="medium"
                                    text="legalEntity.wizard.step2.restaurantIban"
                                    tooltip={computeText(intl, 'legalEntity.wizard.step2.restaurantIban.tooltip')}
                                />
                            </ModalTitleContainer>
                            <ModalTextInput
                                type="string"
                                placeholder={computeText(intl, 'legalEntity.wizard.step2.restaurantIban.placeholder')}
                                error={error?.message}
                                onChange={onChange}
                                value={value?.toString()}
                                disabled={isLoading}
                                required={true}
                            />
                        </ModalInputFullContainer>
                    )}
                />
            </ModalPartsContainer>
            {getValues().restaurantId !== 0 && (
                <>
                    <ConecsTitleContainer>
                        <ConecsContainerWithTooltip
                            tooltip={computeText(intl, 'legalEntity.wizard.step2.conecsTitleOptional.tooltip')}
                        >
                            <PanelTitle text={computeText(intl, 'legalEntity.wizard.step2.conecsTitle')} />
                            <OptionalText text={computeText(intl, 'legalEntity.wizard.step2.conecsTitleOptional')} />
                        </ConecsContainerWithTooltip>
                    </ConecsTitleContainer>
                    <Controller
                        control={control}
                        name="restaurantConecsId"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <ModalInputFullContainer>
                                <ModalTitleContainer>
                                    <ModalInputTitle
                                        type="small"
                                        weight="medium"
                                        text="legalEntity.wizard.step2.conecsId"
                                        tooltip={computeText(intl, 'legalEntity.wizard.step2.conecsId.tooltip')}
                                    />
                                </ModalTitleContainer>
                                <ModalTextInput
                                    type="string"
                                    placeholder={computeText(intl, 'legalEntity.wizard.step2.conecsId.placeholder')}
                                    error={error?.message}
                                    onChange={onChange}
                                    value={value?.toString()}
                                    disabled={isLoading}
                                    required={false}
                                />
                            </ModalInputFullContainer>
                        )}
                    />
                    <Controller
                        control={control}
                        name="restaurantConecsAssociatedSiret"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <ModalInputFullContainer>
                                <ModalTitleContainer>
                                    <ModalInputTitle
                                        type="small"
                                        weight="medium"
                                        text="legalEntity.wizard.step2.conecsAssociatedSiret"
                                    />
                                </ModalTitleContainer>
                                <ModalTextInput
                                    type="string"
                                    placeholder={computeText(
                                        intl,
                                        'legalEntity.wizard.step2.conecsAssociatedSiret.placeholder',
                                    )}
                                    error={error?.message}
                                    onChange={onChange}
                                    value={value?.toString()}
                                    disabled={isLoading}
                                    required={false}
                                />
                            </ModalInputFullContainer>
                        )}
                    />
                </>
            )}
        </>
    );
};
