import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import React, { ReactNode } from 'react';
import { TableLine } from '@/pages/Brand/AutoBilling/AutoBillingRequest/AutoBillingRequestPreview/AutoBillingRequestPreview';
import { computeCurrency, computeText } from '@/locales/utils';
import { TableRow } from '@/components/atoms/Table/Table.type';
import {
    HeaderCell,
    HeaderElementTile,
    HeaderElementValue,
    InvoiceTotalLine,
    InvoiceTotalLineLeftElement,
    InvoiceTotalLineRightElement,
} from '@/pages/Brand/AutoBilling/AutoBillingRequest/AutoBillingRequestPreview/AutoBillingInvoices/AutoBillingInvoices.style';
import { Item } from '@/services/innovorder/autoBilling/autoBilling.type';

export const getTableLines = (items: Item[], intl: IntlShape): TableLine[] =>
    items.map((item): TableLine => {
        const sign = item.type === 'LR' ? '-' : '';
        return {
            quantity: item.quantity.toString(),
            unitPriceWithTaxExcluded:
                sign +
                computeCurrency({
                    intl,
                    amount: item.unitPriceWithTaxExcluded,
                    isFromCents: false,
                    maximumFractionDigits: 3,
                }),
            totalTax:
                sign + computeCurrency({ intl, amount: item.totalTax, isFromCents: false, maximumFractionDigits: 3 }),
            unitPriceWithTaxIncluded:
                sign +
                computeCurrency({
                    intl,
                    amount: item.unitPriceWithTaxIncluded,
                    isFromCents: false,
                    maximumFractionDigits: 3,
                }),
            totalPriceWithTaxIncluded:
                sign +
                computeCurrency({
                    intl,
                    amount: item.totalPriceWithTaxIncluded,
                    isFromCents: false,
                    maximumFractionDigits: 3,
                }),
            totalPriceWithTaxExcluded:
                sign +
                computeCurrency({
                    intl,
                    amount: item.totalPriceWithTaxExcluded,
                    isFromCents: false,
                    maximumFractionDigits: 3,
                }),
            label: item.label,
            code: item.code,
        };
    });

export const getTableRows = (items: Item[], intl: IntlShape): TableRow[] =>
    getTableLines(items, intl).map((tableLine) => {
        const tableRow: TableRow = {};
        Object.entries(tableLine).forEach((entry) => {
            tableRow[entry[0]] = {
                type: 'string',
                value: {
                    text: entry[1]?.toString() ?? '',
                },
            };
        });
        return tableRow;
    });

export const getBillingTableCols = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'auto.billing.request.preview.code'),
        accessor: 'code',
        width: '110px',
    },
    {
        Header: computeText(intl, 'auto.billing.request.preview.label'),
        accessor: 'label',
        width: '220px',
    },
    {
        Header: computeText(intl, 'auto.billing.request.preview.quantity'),
        accessor: 'quantity',
        width: '100px',
    },
    {
        Header: computeText(intl, 'auto.billing.request.preview.unitPriceWithTaxExcluded'),
        accessor: 'unitPriceWithTaxExcluded',
        width: '200px',
    },
    {
        Header: computeText(intl, 'auto.billing.request.preview.totalPriceWithTaxExcluded'),
        accessor: 'totalPriceWithTaxExcluded',
        width: '210px',
    },
    {
        Header: computeText(intl, 'auto.billing.request.preview.totalTax'),
        accessor: 'totalTax',
        width: '125px',
    },
    {
        Header: computeText(intl, 'auto.billing.request.preview.totalPriceWithTaxIncluded'),
        accessor: 'totalPriceWithTaxIncluded',
        width: '210px',
    },
];

export const renderHeaderCell = (title: string, children: ReactNode | string, testId: string, intl: IntlShape) => (
    <HeaderCell>
        <HeaderElementTile>{computeText(intl, title)}</HeaderElementTile>
        <HeaderElementValue data-testid={testId}>{children}</HeaderElementValue>
    </HeaderCell>
);
export const renderInvoiceTotalLine = (
    lineText: string,
    value: number,
    testId: string,
    intl: IntlShape,
    border?: boolean,
) => (
    <InvoiceTotalLine border={border}>
        <InvoiceTotalLineLeftElement>{computeText(intl, lineText)}</InvoiceTotalLineLeftElement>
        <InvoiceTotalLineRightElement data-testid={testId}>
            {computeCurrency({ intl, amount: value, isFromCents: false, maximumFractionDigits: 3 })}
        </InvoiceTotalLineRightElement>
    </InvoiceTotalLine>
);
