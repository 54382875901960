import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse, ApiResponseBase } from '../request.types';
import {
    DeleteGrantPayload,
    GetGrantPayload,
    GetGrantsPayload,
    Grant,
    GrantCreateAttributes,
    Grants,
    PutGrantPayload,
} from './grant.type';

const grantApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getGrants: build.query<Grants, ApiRequest<GetGrantsPayload>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/grants/list`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.Grant],
            transformResponse: (response: ApiResponse<Grants>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getGrant: build.query<Grant, ApiRequest<GetGrantPayload>>({
            query: ({ grantId, token }) => ({
                url: `/grants/${grantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.Grant],
            transformResponse: (response: ApiResponse<Grant>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        putGrant: build.mutation<Grant, ApiRequest<PutGrantPayload>>({
            query: ({
                brandId,
                code,
                type,
                labelTicket,
                amount,
                strategy,
                dailyLimit,
                restaurants,
                token,
                grantId,
                ceiling,
                threshold,
                tag,
            }) => ({
                url: `/grants/${grantId}`,
                method: 'PUT',
                body: {
                    brandId,
                    grantId,
                    code,
                    labelTicket,
                    amount,
                    strategy,
                    dailyLimit,
                    restaurants,
                    type,
                    ceiling,
                    threshold,
                    tag,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.Grant],
            transformResponse: (response: ApiResponse<Grant>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        postGrant: build.mutation<Grant, ApiRequest<GrantCreateAttributes>>({
            query: ({
                brandId,
                code,
                type,
                labelTicket,
                amount,
                strategy,
                dailyLimit,
                restaurants,
                token,
                ceiling,
                threshold,
                tag,
            }) => ({
                url: `/grants`,
                method: 'POST',
                body: {
                    brandId,
                    code,
                    labelTicket,
                    amount,
                    strategy,
                    dailyLimit,
                    restaurants,
                    type,
                    ceiling,
                    threshold,
                    tag,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.Grant],
            transformResponse: (response: ApiResponse<Grant>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteGrant: build.mutation<ApiResponseBase, ApiRequest<DeleteGrantPayload>>({
            query: ({ grantId, brandId, token }) => ({
                url: `/grants/${grantId}`,
                method: 'DELETE',
                params: { brandId },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: [ApiTagTypes.PricingRule, ApiTagTypes.Grant],
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetGrantQuery,
    useLazyGetGrantsQuery,
    usePutGrantMutation,
    usePostGrantMutation,
    useDeleteGrantMutation,
} = grantApi;
