import { routes } from '@/core/router/routes';
import { Routes } from '@/core/router/routes.types';
import { computeText } from '@/locales/utils';
import {
    useGetCrossSellingQuery,
    useUpdateCrossSellingMutation,
} from '@/services/innovorder/crossSelling/crossSelling.endpoint';
import { CrossSellingWithProducts } from '@/services/innovorder/crossSelling/crossSelling.type';
import { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';

type UpdateCrossSellingParams = {
    crossSellingId: string;
};

export const useEditCrossSellingVM = () => {
    const intl = useIntl();
    const history = useHistory();
    const { crossSellingId } = useParams<UpdateCrossSellingParams>();
    const { params } = useRouteMatch<{ brandId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);

    const tabRoutes = useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    const { isLoading: isLoadingGet, data: defaultValue } = useGetCrossSellingQuery({
        crossSellingId: parseInt(crossSellingId, 10),
    });
    const [updateCrossSelling, { isLoading: isLoadingUpdate }] = useUpdateCrossSellingMutation();

    const handleCancel = () => {
        history.push(generatePath(Routes.BrandCrossSelling, { brandId }));
    };

    const handleSubmit = useCallback(
        async ({ name, status, products }: CrossSellingWithProducts) => {
            updateCrossSelling({
                crossSellingId: parseInt(crossSellingId, 10),
                brandId,
                name,
                status,
                crossSellingProducts: products.map(({ productId, crossSellingProduct: { position } }) => ({
                    productId,
                    position,
                })),
            });
        },
        [crossSellingId, brandId, updateCrossSelling],
    );

    return {
        title: computeText(intl, 'crossSelling.page.update.title', {
            crossSellingName: defaultValue?.name || '',
        }),
        tabRoutes,
        isLoadingGet,
        isLoadingUpdate,
        defaultValue,
        handleSubmit,
        handleCancel,
    };
};
