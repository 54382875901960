import { Paginated } from '../request.types';

export const EntityTypes = [
    'GRANT',
    'ENTRANCE_FEE',
    'PRICING_RULE',
    'PRODUCT',
    'STEP',
    'CATEGORY',
    'MENU',
    'MODULE',
    'CUSTOMER',
    'CUSTOMERS_IMPORT',
    'CUSTOMERS_UPDATE',
    'USER',
    'CHANNEL',
    'CONSUMPTION_MODE',
    'ORDER',
    'BRAND',
    'RESTAURANT',
] as const;
export type EntityType = (typeof EntityTypes)[number];

export const ActionTypes = [
    'CREATE',
    'UPDATE',
    'DELETE',
    'OPEN',
    'CLOSE',
    'ACTIVATE',
    'DEACTIVATE',
    'CANCEL',
    'IMPORT',
    'DUPLICATE',
] as const;
export type ActionType = (typeof ActionTypes)[number];

export type GetHistoryFilters = Record<string, string>;

export type GetHistoryListPayload = Paginated<{
    brandId: number;
    restaurantId?: string;
    userId?: number;
    search?: string;
    orderBy?: string;
    filters?: GetHistoryFilters;
}>;
// export type GetHistoryListPayload = Paginated<{ brandId: number }>;
export type GetAuditPayload = { auditId: string };

export type GetHistoryListResponse = {
    count: number;
    data: History[];
};

export type GetAuditResponse = {
    auditName: string;
    auditType: EntityType;
    action: ActionType;
    auditDate: string;
    diff: AuditDiff[];
    user: SimplifiedUser;
};

export type AuditDiff = {
    fieldName: string;
    oldValue: string | boolean | undefined;
    newValue: string | boolean | undefined;
};

export type StringifiedAuditDiff = {
    fieldName: string;
    oldValue: string;
    newValue: string;
};

export type SimplifiedUser = {
    mail: string;
    id: string;
};

export type History = {
    historyId: string;
    entityId: number;
    entityName: string;
    entityType: EntityType;
    brandId: number;
    userId: number;
    userEmail: string;
    restaurantId?: string;
    action: ActionType;
    eventDate: Date;
};
