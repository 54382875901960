import styled from '@emotion/styled';

export const Wrapper = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};

    h2 {
        margin-bottom: 10px;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;
