import React from 'react';

import { Container, FieldContainer, StyledLabel, StyledValue } from './OrderDetailsCustomer.style';
import { BalanceInformation } from '@/services/innovorder/orders/order.type';
import { computeCurrency } from '@/locales/utils';
import { useIntl } from 'react-intl';

type OrderDetailsCustomerProps = {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    badgeNumber?: string;
    pricingRuleCode?: string;
    group?: string;
    section?: string;
    subSection?: string;
    balanceInformation?: BalanceInformation;
};

const OrderDetailsCustomer: React.FunctionComponent<React.PropsWithChildren<OrderDetailsCustomerProps>> = ({
    firstName,
    lastName,
    email,
    phone,
    badgeNumber,
    pricingRuleCode,
    group,
    section,
    subSection,
    balanceInformation,
}) => {
    const intl = useIntl();

    return (
        <Container>
            <FieldContainer>
                <StyledLabel text="orderDetail.customer.name" />
                <StyledValue>{`${firstName} ${lastName}`}</StyledValue>
            </FieldContainer>
            {email && (
                <FieldContainer>
                    <StyledLabel text="orderDetail.customer.email" />
                    <StyledValue>{email}</StyledValue>
                </FieldContainer>
            )}
            {phone && (
                <FieldContainer>
                    <StyledLabel text="orderDetail.customer.phone" />
                    <StyledValue>{phone}</StyledValue>
                </FieldContainer>
            )}
            {badgeNumber && (
                <FieldContainer>
                    <StyledLabel text="orderDetail.customer.badgeNumber" />
                    <StyledValue>{badgeNumber}</StyledValue>
                </FieldContainer>
            )}
            {balanceInformation && (
                <>
                    <FieldContainer>
                        <StyledLabel text="orderDetail.customer.balanceInformation.ewalletBalanceBeforeOrder" />
                        <StyledValue>
                            {computeCurrency({ intl, amount: balanceInformation.ewalletBalanceBeforeOrder })}
                        </StyledValue>
                    </FieldContainer>
                    <FieldContainer>
                        <StyledLabel text="orderDetail.customer.balanceInformation.ewalletBalanceAfterOrder" />
                        <StyledValue>
                            {computeCurrency({ intl, amount: balanceInformation.ewalletBalanceAfterOrder })}
                        </StyledValue>
                    </FieldContainer>
                </>
            )}
            {pricingRuleCode && (
                <FieldContainer>
                    <StyledLabel text="orderDetail.customer.pricingRuleAssigned" />
                    <StyledValue>{pricingRuleCode}</StyledValue>
                </FieldContainer>
            )}
            {group && (
                <FieldContainer>
                    <StyledLabel text="orderDetail.customer.group" />
                    <StyledValue>{group}</StyledValue>
                </FieldContainer>
            )}
            {section && (
                <FieldContainer>
                    <StyledLabel text="orderDetail.customer.section" />
                    <StyledValue>{section}</StyledValue>
                </FieldContainer>
            )}
            {subSection && (
                <FieldContainer>
                    <StyledLabel text="orderDetail.customer.subSection" />
                    <StyledValue>{subSection}</StyledValue>
                </FieldContainer>
            )}
        </Container>
    );
};

export default OrderDetailsCustomer;
