import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
import { ApiRequest } from '../../request.types';
import { GetBrandLegalEntities, LegalEntityResponse } from './legalEntity.type';

const legalEntityApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getBrandLegalEntities: build.query<LegalEntityResponse[], ApiRequest<GetBrandLegalEntities>>({
            query: ({ brandId }) => ({
                url: new URL(`/v1/adyen/legal-entity/brand/${brandId}`, ENV.PAYMENT_SERVICE_URL).href,
                method: 'GET',
            }),
            transformResponse: (response: LegalEntityResponse[]) => response,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetBrandLegalEntitiesQuery } = legalEntityApi;
