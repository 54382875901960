import React from 'react';
import { getLocale, SupportedLanguages } from '@/locales';
/**
 * Why eslint-disable-next-line import/no-duplicates ?
 * Because eslint prefers import { format, Locale, enGB, fr } from 'date-fns';
 * But typescript says TS2305: Module '"date-fns"' has no exported member 'enGB'.
 * */
// eslint-disable-next-line import/no-duplicates
import { format, Locale } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB, fr } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { Button } from '@/components/atoms/Button';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { useTheme } from '@emotion/react';
import { DateRange } from '@/pages/Reporting/Report/ReportTable/types';
import { FormattedMessage } from 'react-intl';

interface SelectDateRangeButtonProps {
    selectedDateRange: DateRange;
    openDateRangeSelector: () => void;
}

function getDateFnsLocale(locale: SupportedLanguages): Locale {
    return locale === SupportedLanguages.en ? enGB : fr;
}

function formatDate(startDate: Date, locale: SupportedLanguages): string {
    return format(startDate, 'P', { locale: getDateFnsLocale(locale) });
}

const SelectDateRangeButton: React.FunctionComponent<SelectDateRangeButtonProps> = ({
    selectedDateRange: { startDate, endDate },
    openDateRangeSelector,
}) => {
    const theme = useTheme();
    const locale = useSelector(getLocale);

    return (
        <Button buttonType="secondary" onClick={openDateRangeSelector}>
            <SVGIcon icon={SVGIcons.CALENDAR} size={16} color={theme.color.black200} />
            <span>
                <FormattedMessage
                    id={'report.selectDateRangeButton.text'}
                    values={{ startDate: formatDate(startDate, locale), endDate: formatDate(endDate, locale) }}
                />
            </span>
        </Button>
    );
};

export default SelectDateRangeButton;
