import { innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import { GetModulesPayload, GetModuleTpePayload, Module, ModuleTpe } from './modules.type';

const modulesApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getModuleStripes: build.query<Module, ApiRequest<GetModulesPayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/module_stripes/restaurant/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<Module>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getModuleIngenico: build.query<Module, ApiRequest<GetModulesPayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/modules_ingenico/restaurant/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<Module>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getModulePaygreen: build.query<Module, ApiRequest<GetModulesPayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/module_paygreens/restaurant/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<Module>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getModuleNepting: build.query<Module, ApiRequest<GetModulesPayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/module_nepting/restaurants/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<Module>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getModuleIngenicoTpe: build.query<ModuleTpe[], ApiRequest<GetModuleTpePayload>>({
            query: ({ moduleId, token }) => ({
                url: `/ingenico_tpe/module_ingenico/${moduleId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [],
            transformResponse: (response: ApiResponse<ModuleTpe[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useGetModuleStripesQuery,
    useGetModuleIngenicoQuery,
    useGetModulePaygreenQuery,
    useGetModuleNeptingQuery,
    useLazyGetModuleIngenicoTpeQuery,
} = modulesApi;
