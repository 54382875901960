import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { routes as declaredRoutes } from '@/core/router/routes';
import { NavigationContext, RouteDeclaration } from '@/core/router/routes.types';
import { useAuthCookieHandler } from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { useGetBrandQuery } from '@/services/innovorder/brand/brand.endpoints';
import { setBrandId, setRestaurantId } from '@/redux/app';
import { updateBrand } from '@/redux/brand';

export const getRouteParams = (pathName: string, routes: RouteDeclaration[]): NavigationContext => {
    const params = routes.reduce<Record<string, string>>(
        (paramMap, { path }) =>
            matchPath(pathName, {
                path,
                exact: true,
                strict: false,
            })?.params ?? paramMap,
        {},
    );

    return {
        restaurantId: params?.restaurantId,
        brandId: params?.brandId,
    };
};

export const ContextGate: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
    const { pathname } = useLocation();
    const { brandId, restaurantId } = getRouteParams(pathname, declaredRoutes);
    const { token } = useAuthCookieHandler();
    const dispatch = useDispatch();

    const { data: brand } = useGetBrandQuery(brandId ? { brandId: parseInt(brandId, 10), token } : skipToken, {
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        dispatch(setRestaurantId(restaurantId));
    }, [restaurantId, dispatch]);

    useEffect(() => {
        dispatch(setBrandId(brandId));
    }, [brandId, dispatch]);

    useEffect(() => {
        dispatch(updateBrand(brand || null));
    }, [dispatch, brand]);

    return <>{children}</>;
};
