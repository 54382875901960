import React, { useEffect } from 'react';
import { AppEnv } from '@/utils/types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@emotion/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/react';
import { IntlProvider } from 'react-intl';
import { ENV } from '@/utils/env';
import { computeBreakpoint } from '@/utils/style';
import Error from '@/pages/Error';
import { useViewport } from '@/hooks/useViewport';
import { useLocaleHandler } from '@/hooks/useLocaleHandler/useLocaleHandler';
import { history } from '@/redux/store';
import RootRouter from './router/RootRouter';
import { theme, mobileTheme } from './theme';
import './reset.css';
import { AsyncActionsProvider } from './asyncActions/AsyncActionsProvider';
import { useBeamer } from '@/hooks/useBeamer/useBeamer';
declare global {
    interface Window {
        beamer_config: any;
    }
}

const HtmlHeader: React.FunctionComponent<React.PropsWithChildren> = () => {
    return (
        <Helmet>
            <meta name="description" content="Innovorder's back-office" />
            {ENV.DASHBOARD_ENV === AppEnv.Production && (
                <script
                    id="ze-snippet"
                    src="https://static.zdassets.com/ekr/snippet.js?key=8acd6640-5d0a-40fe-b354-0029eb9f436d"
                ></script>
            )}
        </Helmet>
    );
};

const App: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { width } = useViewport();
    const { currentLocale, messages } = useLocaleHandler();
    useBeamer();

    return (
        <IntlProvider key={currentLocale} locale={currentLocale} messages={messages}>
            <HelmetProvider>
                <ThemeProvider theme={computeBreakpoint(theme.breakpoints.mobile) >= width ? mobileTheme : theme}>
                    <AsyncActionsProvider>
                        <Sentry.ErrorBoundary fallback={<Error />} showDialog>
                            <HtmlHeader />
                            <RootRouter history={history} />
                        </Sentry.ErrorBoundary>
                    </AsyncActionsProvider>
                </ThemeProvider>
            </HelmetProvider>
        </IntlProvider>
    );
};

export default withLDProvider({
    clientSideID: ENV.LAUNCH_DARKLY_CLIENT_ID,
})(App);
