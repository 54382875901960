import React, { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import Wizard from '@/components/atoms/Wizard';
import { Input } from '@/components/atoms/Form/Input';
import RawModal from '@/components/atoms/RawModal';
import { stringifyError } from '@/utils/errors';
import { useUpdateTableLayoutMutation } from '@/services/innovorder/tableLayout/tableLayout.endpoint';
import { SelectPosDevice } from '@/components/form/SelectPosDevice';
import { TableLayoutPosDevice } from '@/services/innovorder/tableLayout/tableLayout.type';
import { TableLayoutModalInputContainer, TableLayoutModalTextInputLabel } from '../TableLayoutModal.style';

export type EditTableLayoutModalProps = {
    brandId: number;
    restaurantId: number;
    tableLayoutId: string | null;
    tableLayoutName: string | null;
    posDevices: TableLayoutPosDevice[];
    onHide: () => void;
};

export const EditTableLayoutModal: React.FunctionComponent<React.PropsWithChildren<EditTableLayoutModalProps>> = ({
    brandId,
    restaurantId,
    tableLayoutId,
    tableLayoutName,
    posDevices,
    onHide,
}) => {
    const intl = useIntl();
    const [postEditTableLayout, { isSuccess: isEditSuccess, isLoading: isEditLoading, error: editError }] =
        useUpdateTableLayoutMutation();
    const form = useForm<{ name: string | null; posDevices: TableLayoutPosDevice[] }>({
        defaultValues: {
            name: tableLayoutName,
            posDevices,
        },
    });

    const { control, getValues, setError } = form;

    const onEditValidate = useCallback(() => {
        const { name, posDevices: formPosDevices } = getValues();

        if (!name) {
            return setError('name', { message: computeText(intl, 'tableLayout.modal.error.name') });
        }

        if (!tableLayoutId) {
            return null;
        }

        const posDeviceIds = formPosDevices.map(({ posDeviceId }) => posDeviceId);

        return postEditTableLayout({
            tableLayoutId,
            name,
            posDevices: posDeviceIds,
        });
    }, [setError, intl, getValues, postEditTableLayout, tableLayoutId]);

    useEffect(() => {
        if (isEditSuccess) {
            onHide();
        }
    }, [isEditSuccess, onHide]);

    return (
        <RawModal isOpen={true} onHide={onHide}>
            <FormProvider {...form}>
                <Wizard
                    width={480}
                    isLoading={isEditLoading}
                    steps={[
                        {
                            title: computeText(intl, 'tableLayout.modal.edit.title', {
                                tableLayoutName: tableLayoutName ?? '',
                            }),
                            onCancel: onHide,
                            component: (
                                <TableLayoutModalInputContainer>
                                    <TableLayoutModalTextInputLabel
                                        type="small"
                                        weight="medium"
                                        text="tableLayout.name"
                                    />
                                    <Controller
                                        control={control}
                                        name="name"
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input
                                                data-testid="table-layout-modal-name-input"
                                                value={value ?? ''}
                                                isTouched={!!value && value.length > 0}
                                                onChange={onChange}
                                                error={error?.message}
                                            />
                                        )}
                                    />
                                    <SelectPosDevice
                                        brandId={brandId}
                                        restaurantId={restaurantId}
                                        lastSupportedVersion="9.2.0"
                                        posDeviceRoleNotSupported={[]}
                                        name="posDevices"
                                    />
                                </TableLayoutModalInputContainer>
                            ),
                        },
                    ]}
                    errorMessage={stringifyError(editError)}
                    onValidate={onEditValidate}
                />
            </FormProvider>
        </RawModal>
    );
};
