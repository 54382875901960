import React from 'react';
import Tab from '@/components/organisms/Tabs/Tab';
import { routes } from '@/core/router/routes';
import { useResolveRoutesPathAndUriFromParams } from '@/hooks/routes/useResolveRoutesPathAndUriFromParams';
import { useFilterRoutes } from '@/hooks/routes/useFilterRoutes';
import { Tabs } from './IOPayTabs.style';

const IOPayTabs: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
    const iopayRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'iopay'), []);
    const resolvedTabRoutes = useResolveRoutesPathAndUriFromParams(iopayRoutes);
    const filteredTabRoutes = useFilterRoutes(resolvedTabRoutes);
    return (
        <>
            <Tabs data-testid="io-pay.container">
                {filteredTabRoutes.map((route) => (
                    <Tab title={route.name} route={route} key={route.name} />
                ))}
            </Tabs>
            {children}
        </>
    );
};

export default IOPayTabs;
