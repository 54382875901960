import fileSaver from 'file-saver';
import dayjs from 'dayjs';

const stringToBlob = (stream: string): Blob => {
    const byteCharacters = atob(stream);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'base64' });
};

export const saveStreamAsZipFile = (stream: string) => {
    const fileName = `invoices-request-${dayjs().format('DD-MM-YYYY-HH-mm-ss')}.zip`;
    const blob = stringToBlob(stream);
    fileSaver.saveAs(blob, fileName);
};
