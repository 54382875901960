import { Routes } from '@/core/router/routes.types';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { getErrorCode } from '@/utils/errors';
import { usePostGuestGroupMutation } from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { useGetMinimalPricingRulesQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { getUserToken } from '@/redux/user';
import { routes } from '@/core/router/routes';
import GroupForm from '../GroupForm';

type CreateGroupProps = {};

type ParamTypes = {
    brandId: string;
};

const CreateGroup: React.FunctionComponent<React.PropsWithChildren<CreateGroupProps>> = () => {
    const history = useHistory();
    const { brandId } = useParams<ParamTypes>();
    const token = useSelector(getUserToken);
    const { data: pricingRulesData } = useGetMinimalPricingRulesQuery({
        brandId: parseInt(brandId, 10),
        token,
    });
    const [postGroup, { isSuccess, error, data, isLoading }] = usePostGuestGroupMutation();
    if (isSuccess && data?.name) {
        history.push(generatePath(Routes.Groups, { brandId }));
    }

    const post = (name: string, pricingRules: number[]) => {
        postGroup({
            brandId: parseInt(brandId, 10),
            name,
            pricingRules,
            token,
        });
    };

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <GroupForm
                error={getErrorCode(error)}
                onSubmit={post}
                loading={isLoading}
                pricingRules={pricingRulesData || []}
            />
        </RouterNavigationTabs>
    );
};

export default CreateGroup;
