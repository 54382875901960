import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};

    background: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
    margin: ${({ theme }) => theme.spacing[4]};
    flex: 1;
`;

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.desktop} {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    gap: ${({ theme }) => theme.spacing[4]};
`;

export const MainDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
`;

export const SideDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
`;

export const ActionBarButton = styled.div`
    margin-right: 64px;
`;
