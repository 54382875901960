import React from 'react';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';

import Tab from '../../../components/organisms/Tabs/Tab';
import AutoBillingRequest from './AutoBillingRequest';
import { Container } from './AutoBilling.style';
import AutoBillingReferential from './AutoBillingReferential';

const AutoBilling: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    return (
        <Container>
            <Tab title={computeText(intl, 'auto.billing.buttons.billing')} component={<AutoBillingRequest />} />
            <Tab title={computeText(intl, 'auto.billing.buttons.referential')} component={<AutoBillingReferential />} />
        </Container>
    );
};

export default AutoBilling;
