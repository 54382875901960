import React from 'react';

import RadioButton from '@/components/atoms/RadioButton';
import { computeText } from '@/locales/utils';
import Loader from '@/components/atoms/Loader';
import { Text } from '@/components/atoms/Text';
import { ModalDeviceList, ModalDisclaimerText, PosLabel, ModalButton, LoadingContainer } from './SelectPosDevice.style';
import { SelectPosDeviceProps } from './SelectPosDevice.interface';
import { useSelectPosDeviceVm } from './SelectPosDevice.viewmodel';

export const SelectPosDevice: React.FunctionComponent<SelectPosDeviceProps> = (props) => {
    const { value, isPosDevicesLoading, posDevices, onPosDeviceChange, theme, intl, disableStrategy, disclaimers } =
        useSelectPosDeviceVm(props);

    if (isPosDevicesLoading) {
        return (
            <LoadingContainer>
                <Loader size={32} color={theme.color.bloodyMary} />
            </LoadingContainer>
        );
    }

    if (posDevices.length < 1) {
        return <Text type="normal" text="menu.modal.channels.pos.notFound" />;
    }

    return (
        <>
            {disclaimers &&
                disclaimers.map(({ text, values }, index) => (
                    <ModalDisclaimerText
                        key={index}
                        text={computeText(intl, text, {
                            values,
                        })}
                        type="small"
                    />
                ))}
            <ModalDeviceList>
                {posDevices.map((posDevice) => (
                    <ModalButton key={`menu-modal-channels-pos-devices-${posDevice.deviceId}`}>
                        <RadioButton
                            data-testid={`menu-modal-channels-pos-devices-${posDevice.deviceId}`}
                            label={posDevice.deviceName}
                            onChange={onPosDeviceChange}
                            checked={value.some(({ posDeviceId }) => posDeviceId === posDevice.posDeviceId)}
                            value={posDevice.posDeviceId}
                            disabled={disableStrategy(posDevice)}
                        >
                            <PosLabel
                                text={`${computeText(intl, `pos_device.role.${posDevice.role}`)} -  version ${
                                    posDevice.lastKnownVersion
                                }`}
                                type="small"
                            />
                        </RadioButton>
                    </ModalButton>
                ))}
            </ModalDeviceList>
        </>
    );
};
